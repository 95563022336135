import App from './App.svelte'
import util from 'util';
import { EventEmitter } from 'events';
// import { register, init, getLocaleFromNavigator } from 'svelte-i18n'

// register('en', () => import('../locales/en.json'))
// register('jp', () => import('../locales/jp.json'))

// init({
//   fallbackLocale: 'en',
//   initialLocale: getLocaleFromNavigator(),
// })




const app = new App({
    target: document.body,
})

export default app
