import PIXI from '../../lib/PixiProjectionExport'
import * as particles from '@pixi/particle-emitter'
import { GameSettings } from '../../settings/GameSettings'

let container = new PIXI.ParticleContainer()

//---Base Settings
const baseParticleSpeed = 45
const baseParticleMinLifetime = 0.25
const baseParticleMaxLifetime = 0.49

const gravelSettings = {
    lifetime: {
        min: 0.3,
        max: 0.55,
    },
    frequency: 0.06,
    emitterLifetime: 0,
    maxParticles: 100,
    addAtBack: false,
    pos: {
        x: 0,
        y: 0,
    },
    behaviors: [
        {
            type: 'scale',
            config: {
                scale: {
                    list: [
                        {
                            value: 0.025,
                            time: 0,
                        },
                        {
                            value: 0.75,
                            time: 0.8,
                        },
                        {
                            value: 0.75,
                            time: 1,
                        },
                    ],
                    isStepped: false,
                },
                minMult: 1,
            },
        },
        {
            type: 'alpha',
            config: {
                alpha: {
                    list: [
                        {
                            value: 1,
                            time: 0,
                        },
                        {
                            value: 0,
                            time: 0.8,
                        },
                        {
                            value: 0,
                            time: 1,
                        },
                    ],
                    isStepped: false,
                },
            },
        },
        {
            type: 'color',
            config: {
                color: {
                    list: [
                        {
                            value: '939693',
                            time: 0,
                        },
                        {
                            // "value": "9A9D9A",
                            value: '000000',
                            time: 2,
                        },
                    ],
                    isStepped: false,
                },
            },
        },
        {
            type: 'textureRandom',
            config: {
                textures: ['assets/particles/smoke.png'],
            },
        },
        {
            type: 'spawnShape',
            config: {
                type: 'rect',
                data: {
                    x: -33,
                    y: -5,
                    w: 66,
                    h: 10,
                },
            },
        },
        {
            type: 'moveSpeed',
            config: {
                speed: {
                    list: [
                        {
                            time: 0,
                            value: 5,
                        },
                        {
                            time: 1,
                            value: baseParticleSpeed,
                        },
                    ],
                },
            },
        },
        {
            type: 'rotation',
            config: {
                accel: 0,
                minSpeed: 0,
                maxSpeed: 0,
                minStart: 65,
                maxStart: 115,
            },
        },
    ],
}
let smokeSettings = {
    lifetime: {
        min: baseParticleMinLifetime,
        max: baseParticleMaxLifetime,
    },
    frequency: 0.0,
    emitterLifetime: 1,
    maxParticles: 0,
    addAtBack: false,
    pos: {
        x: 0,
        y: 0,
    },
    behaviors: [
        {
            type: 'alpha',
            config: {
                alpha: {
                    list: [
                        {
                            value: 1,
                            time: 0,
                        },
                        {
                            value: 0,
                            time: 1,
                        },
                    ],
                    isStepped: false,
                },
            },
        },
        {
            type: 'scale',
            config: {
                scale: {
                    list: [
                        {
                            value: 0.05,
                            time: 0,
                        },
                        {
                            value: 2,
                            time: 1.25,
                        },
                    ],
                    isStepped: false,
                },
                minMult: 1,
            },
        },
        {
            type: 'color',
            config: {
                color: {
                    list: [
                        {
                            value: 'ffffff',
                            time: 0,
                        },
                        {
                            value: 'fffffe',
                            time: 2,
                        },
                    ],
                    isStepped: false,
                },
            },
        },
        {
            type: 'textureRandom',
            config: {
                textures: ['assets/particles/smoke.png'],
            },
        },
        {
            type: 'spawnShape',
            config: {
                type: 'torus',
                data: {
                    x: 0,
                    y: 0,
                    radius: 5,
                    innerRadius: 0,
                    affectRotation: false,
                },
            },
        },
        {
            type: 'moveSpeed',
            config: {
                speed: {
                    list: [
                        {
                            time: 0,
                            value: 5,
                        },
                        {
                            time: 1,
                            value: baseParticleSpeed,
                        },
                    ],
                },
            },
        },
        {
            type: 'rotation',
            config: {
                accel: 0,
                minSpeed: 0,
                maxSpeed: 0,
                minStart: 90,
                maxStart: 90,
            },
        },
    ],
}

//---Emitter objects
let rightGas
let leftGas
let rightBrake
let leftBrake
let rightBoost
let leftBoost

let gravelParticlesEmmiter

//---Setting objects
let gasParticleSettings = JSON.parse(JSON.stringify(smokeSettings))
let brakeParticleSettings = JSON.parse(JSON.stringify(smokeSettings))
let boostParticleSettings = smokeSettings //JSON.parse(JSON.stringify(smokeSettings))

export function setParticlePos(x, y) {
    const modifier = 2
    container.position.set(x / modifier, (y / modifier) * 1.7)
}

export function setState(state, speed) {
    //TODO instead of cutting all others off, left them play out by settings spawnrate to 0
    rightGas.emit = false
    leftGas.emit = false
    rightBrake.emit = false
    leftBrake.emit = false
    rightBoost.emit = false
    leftBoost.emit = false

    switch (state) {
        case 'idle':
            break
        case 'gas':
            rightGas.emit = true
            leftGas.emit = true
            break
        case 'brake':
            rightBrake.emit = true
            leftBrake.emit = true
            break
        case 'brakeEnd':
            rightBrake.emit = false
            leftBrake.emit = false
            break
        case 'boost':
            rightBoost.emit = true
            leftBoost.emit = true
            break
    }
}
export function updateParticles(deltaTime, speed) {
    if (speed < 40) {
        gravelParticlesEmmiter.emit = false
        rightBrake.emit = false
        leftBrake.emit = false
    } else {
        gravelParticlesEmmiter.emit = true
        gravelParticlesEmmiter.updateBehaviors[4].list.first.value = speed / 1.5

        leftBrake.updateBehaviors[4].list.first.value = speed / 2
        rightBrake.updateBehaviors[4].list.first.value = speed / 2
    }

    rightGas.update(deltaTime)
    leftGas.update(deltaTime)
    rightBrake.update(deltaTime)
    leftBrake.update(deltaTime)
    rightBoost.update(deltaTime)
    leftBoost.update(deltaTime)

    gravelParticlesEmmiter.update(deltaTime)
}

export function initParticles() {
    gasParticleSettings.behaviors[2].config.color.list[0].value = 'fefefe'
    gasParticleSettings.behaviors[4].config.data.radius = 5
    gasParticleSettings.frequency = 0.06
    gasParticleSettings.maxParticles = 100

    brakeParticleSettings.behaviors[2].config.color.list[0].value = 'ffffff'
    brakeParticleSettings.behaviors[4].config.data.radius = 15
    brakeParticleSettings.frequency = 0.004
    brakeParticleSettings.maxParticles = 250
    brakeParticleSettings.emitterLifetime = 0
    brakeParticleSettings.behaviors[5].config.speed.list[1].value = 55

    boostParticleSettings.behaviors[2].config.color.list[0].value = 'FFDAA4'
    boostParticleSettings.behaviors[4].config.data.radius = 4
    boostParticleSettings.frequency = 0.001
    boostParticleSettings.maxParticles = 250
    boostParticleSettings.behaviors[5].config.speed.list[1].value =
        GameSettings.drsMaxSpeed

    rightGas = new particles.Emitter(container, gasParticleSettings)
    leftGas = new particles.Emitter(container, gasParticleSettings)
    rightBrake = new particles.Emitter(container, brakeParticleSettings)
    leftBrake = new particles.Emitter(container, brakeParticleSettings)
    rightBoost = new particles.Emitter(container, boostParticleSettings)
    leftBoost = new particles.Emitter(container, boostParticleSettings)

    gravelParticlesEmmiter = new particles.Emitter(container, gravelSettings)

    const xOffset = 22
    rightGas.spawnPos = new PIXI.Point(xOffset, 0)
    rightBrake.spawnPos = new PIXI.Point(xOffset, 0)
    rightBoost.spawnPos = new PIXI.Point(xOffset, -8)

    leftGas.spawnPos = new PIXI.Point(-xOffset, 0)
    leftBrake.spawnPos = new PIXI.Point(-xOffset, 0)
    leftBoost.spawnPos = new PIXI.Point(-xOffset, -8)

    return container
}
