export let GlobalData = {
    customerId: '',
    isUser: false,
    gamesAvailable: 0,
    drsAvailable: 0,
    prizeImageUrl: '',
    discountText: '',
    discountProductText: '',
    personalRecord: 0,
    bestTime: 0
}
