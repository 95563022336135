import PIXI from '../../lib/PixiProjectionExport.js'
import { GameSettings } from '../../settings/GameSettings'
import { createButtonWithText } from '../GameUI/ButtonWithText'
import * as TutorialOverlay from './TutorialOverlay'
import * as SpriteSheets from '../TexturePackerLoader/TexturePackerLoader'
import { sendInternalEvent } from '../../helperfunctions/HelperFunctions.js'
import { textData } from '../../../data/TextData'
import { gsap } from 'gsap'

let tutorialButton
const buttonBotOffset = 20

let overlayContainer
let onPlayCallback
let parentContainer

let close
let gas
let brake
let drs
let drsNumber

let startSprite

let app
let uiContainer

let onTutorialCallback
let onPauseCallback
let onResumeCallback

let pixelAnimation
let allowDrsFunction
let showLapFunction
let resetPauseSpeedFunction

/*
let targetX = app.renderer.screen.height / 2 - 56 / 2;
let targetY = app.renderer.screen.width / 2 - 56 / 2;
let tutorialTargetY = app.renderer.screen.height - tutorialButton.height - buttonBotOffset;
tutorialButton.y = tutorialTargetY - 20;
tutorialButton.alpha = 0;*/

export function createUI(
    container,
    appRef,
    soundEngineRef,
    parent,
    closeSprite,
    gasSprite,
    brakeSprite,
    drsSprite,
    drsNumberSprite,
    showCallback,
    playCallback,
    tutorialCallback,
    pause,
    resume,
    allowDrs,
    pixelAnimate,
    showLap,
    resetPauseSpeed
) {
    uiContainer = container
    app = appRef
    parentContainer = parent
    onPlayCallback = playCallback
    onTutorialCallback = tutorialCallback
    onPauseCallback = pause
    onResumeCallback = resume
    allowDrsFunction = allowDrs
    pixelAnimation = pixelAnimate
    showLapFunction = showLap
    resetPauseSpeedFunction = resetPauseSpeed

    close = closeSprite
    gas = gasSprite
    brake = brakeSprite
    drs = drsSprite
    drsNumber = drsNumberSprite

    showCallback()

    if (overlayContainer) {
        show()
        return
    }

    const startTexture = SpriteSheets.textureSheet['play.png']
    startSprite = new PIXI.Sprite(startTexture)

    tutorialButton = createButtonWithText(textData.buttons.tutorial)

    tutorialButton.x = GameSettings.viewportWidth / 2 - tutorialButton.width / 2
    tutorialButton.y =
        GameSettings.viewportHeight - tutorialButton.height - buttonBotOffset
    tutorialButton.interactive = true

    // startSprite.width = 56;
    // startSprite.height = 56;

    startSprite.x = GameSettings.viewportWidth / 2 - startSprite.width / 2
    startSprite.y = GameSettings.viewportHeight / 2 - startSprite.height / 2

    startSprite.interactive = true

    startSprite.on('click', onStartClick)
    startSprite.on('tap', onStartClick)

    tutorialButton.on('click', onTutorialClick)
    tutorialButton.on('tap', onTutorialClick)

    overlayContainer = new PIXI.Container()
    parentContainer.addChild(overlayContainer)

    overlayContainer.addChild(startSprite)
    overlayContainer.addChild(tutorialButton)

    parentContainer.setChildIndex(
        closeSprite,
        parentContainer.children.length - 1
    )

    // resetUIPositions(GameSettings.isPortrait);


}


export function show() {
    overlayContainer.alpha = 1
    tutorialButton.interactive = true
    startSprite.interactive = true

    animateTutorialAndStartButtons()
}

export function hide() {
    overlayContainer.alpha = 0
    tutorialButton.interactive = false
    startSprite.interactive = false
}

export function onStartClick() {
    hide()
    sendInternalEvent('start')
    onPlayCallback()
}

export function onTutorialClick() {
    hide()
    // sendInternalEvent('start')
    TutorialOverlay.show(
        app,
        parentContainer,
        close,
        gas,
        brake,
        drs,
        drsNumber,
        onPauseCallback,
        onResumeCallback,
        allowDrsFunction,
        pixelAnimation,
        showLapFunction
    )
    onTutorialCallback()
    resetPauseSpeedFunction()
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
    }
}

function resetUIPositions(swap = false) {
    let width = app.renderer.screen.width / 2
    let height = app.renderer.screen.height / 2

    if (swap) {
        width = app.renderer.screen.height / 2
        height = app.renderer.screen.width / 2
    }
    
    if (startSprite) {

        let startSpriteTargetSize = 56;

        let startSpriteTargetX = width - startSpriteTargetSize / 2;
        let startSpriteTargetY = height - startSpriteTargetSize / 2;
        let tutorialButtonSpriteTargetX = width - tutorialButton.width / 2;
        let tutorialButtonSpriteTargetY = GameSettings.viewportHeight - tutorialButton.height - buttonBotOffset

        let dur = (startSprite.x - startSpriteTargetX) / 10
        gsap.to(startSprite, {
            y:startSpriteTargetY,
            x:startSpriteTargetX,
            ease: 'power2.in',
            duration: 0.5,
            delay: 0,
        })

        gsap.to(tutorialButton, {
            alpha: 1,
            x: tutorialButtonSpriteTargetX,
            y: tutorialButtonSpriteTargetY,
            delay: 0.3,
            duration: 0.3
        })
        
    }
}

function animateTutorialAndStartButtons() {
    let width = app.renderer.screen.width / 2
    let height = app.renderer.screen.height / 2

    if (GameSettings.isPortrait) {
        width = app.renderer.screen.height / 2
        height = app.renderer.screen.width / 2
    }

    let startSpriteTargetSize = 56;
    startSprite.alpha = 0;
    startSprite.height = 200;
    startSprite.width = 200;
    startSprite.x = width - startSpriteTargetSize / 2;
    startSprite.y = height - startSpriteTargetSize / 2;;
    tutorialButton.y = app.renderer.screen.height - tutorialButton.height + 20;
    tutorialButton.x = width - tutorialButton.width / 2;
    tutorialButton.alpha = 0;

    gsap.to(startSprite, {
        alpha: 1,
        height: startSpriteTargetSize,
        width: startSpriteTargetSize,

        ease: 'power2.in',
        duration: 0.5,
        delay: 1.1,
        onComplete:() => {
            startSprite.alpha = 1;
            resetUIPositions(GameSettings.isPortrait);

        }
    })

    resetUIPositions(GameSettings.isPortrait);
    

    /*
    let targetX = app.renderer.screen.height / 2 - 56 / 2;
    let targetY = app.renderer.screen.width / 2 - 56 / 2;
    let tutorialTargetY = app.renderer.screen.height - tutorialButton.height - buttonBotOffset;
    tutorialButton.y = tutorialTargetY - 20;
    tutorialButton.alpha = 0;
    */

    /*
    gsap.to(tutorialButton, {
        alpha: 1,
        y: tutorialTargetY,
        delay: 1,
    })
    

    gsap.to(startSprite, {
        alpha: 1,
        height: 56,
        width: 56,
        // y: startSprite.y - (200) / 2,
        // x: startSprite.x - (200) / 2,
        y:targetY,
        x:targetX,
        ease: 'power2.in',
        duration: 0.5,
        delay: 1.1,
        onComplete:() => {
            startSprite.alpha = 1;
            resetUIPositions(GameSettings.isPortrait);

        }
    })
        */
}
