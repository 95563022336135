import PIXI from '../lib/PixiProjectionExport'
import { GameSettings } from '../settings/GameSettings'
import * as CommunicationManager from '../components/GameInstance/CommunicationManager'
import { GlobalData } from '../../data/GlobalData'
import Replay from '../components/GameInstance/Replay'

export const deg2Rad = Math.PI / 180
export const rad2Deg = 180 / Math.PI

let _pauseNextFrame = false

//---Utility functions
//Get element in array, with looping index if overflow or underflow
export function getElementAt(array, index) {
    if (index < 0) {
        index += array.length
    } else if (index >= array.length) {
        index -= array.length
    }

    return array[index]
}

export function randomSeeded(seed) {
    var x = Math.sin(seed) * 10000
    return x - Math.floor(x)
}

export function randomItemFromArray(array) {
    var random = array[Math.floor(Math.random() * array.length)]
    return random
}

export function moveTowards(from, to, maxStep) {
    let dif = Math.abs(to - from)
    let t = maxStep / dif

    if (t > 1) return to

    let toReturn = lerp(from, to, t)

    return toReturn
}

export const average = (array) => array.reduce((a, b) => a + b) / array.length

//Gets game world scale from texture and width in meters in real world
export function getScale(texture, width) {
    let largestSide =
        texture.height > texture.width ? texture.height : texture.width
    let scale = (1 / largestSide) * width

    return scale
}

//Used in minimap, only pause when next frame is drawn to see new minimap changes
export function pauseNextFrame() {
    _pauseNextFrame = true
}

//Called every frame
export function checkOnDemandDebugger() {
    if (_pauseNextFrame) {
        _pauseNextFrame = false
        debugger
    }
}

//---Point related functions
//Rotate Point by rotation in radians, negative is to right
export function rotateVector(vector, rotation) {
    let newX = vector.x * Math.cos(rotation) - vector.y * Math.sin(rotation)
    let newY = vector.x * Math.sin(rotation) + vector.y * Math.cos(rotation)
    return new PIXI.Point(newX, newY)
}

export function getPointDist(pointA, pointB) {
    let dist = pointA.subtract(pointB).magnitude()
    return dist
}
export function getPointDistSquared(pointA, pointB) {
    let dist = pointA.subtract(pointB).magnitudeSquared()
    return dist
}

//Vector from angle in degrees
export function getVectorFromAngle(angle) {
    angle = angle * deg2Rad
    let vector = new PIXI.Point(Math.cos(angle), Math.sin(angle))

    return vector
}

//Returns angle in Radians, (1,0) == 0, range from -180 to 180
export function getAngleFromVector(vector) {
    let angle = Math.atan2(vector.y, vector.x)

    return angle
}

//Is point C left of line A-B
export function isLeft(a, b, c) {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) > 0
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) < 0
}

//Get angle between direction vectors in degrees
export function getInnerProduct(lastPoint, currentpoint, nextPoint) {
    let dx21 = currentpoint.x - lastPoint.x
    let dx31 = nextPoint.x - lastPoint.x
    let dy21 = currentpoint.y - lastPoint.y
    let dy31 = nextPoint.y - lastPoint.y
    let m12 = Math.sqrt(dx21 * dx21 + dy21 * dy21)
    let m13 = Math.sqrt(dx31 * dx31 + dy31 * dy31)
    let theta = Math.acos((dx21 * dx31 + dy21 * dy31) / (m12 * m13))

    return (theta * 180) / Math.PI
}

//Get intersect between lines A-B and C-D
export function getLinesIntersect(a, b, c, d) {
    var det, gamma, lambda
    det = (b.x - a.x) * (d.y - c.y) - (d.x - c.x) * (b.y - a.y)
    if (det === 0) {
        return false
    } else {
        lambda = ((d.y - c.y) * (d.x - a.x) + (c.x - d.x) * (d.y - a.y)) / det
        gamma = ((a.y - b.y) * (d.x - a.x) + (b.x - a.x) * (d.y - a.y)) / det
        return {
            doesIntersect: 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1,
            x: a.x + lambda * (b.x - a.x),
            y: a.y + lambda * (b.y - a.y),
        }
    }
}

//Gets the 2d top down position of both sides of given sprite
export function getSpriteWorldCorners(sprite) {
    //Get center
    let center = new PIXI.Point(sprite.position3d.x, sprite.position3d.y)

    //Get dist to corner as vector based on size
    let halfWidth =
        Math.abs(sprite.scale3d.x / (1 / sprite.texture.width) / 2) +
        GameSettings.billboardCollisionBuffer
    let forward = getVectorFromAngle(sprite.euler.y * rad2Deg * -1 + 90)

    forward = forward.multiplyScalar(halfWidth)
    let centerOffset
    if (sprite.colliderAnchor < 0.5) {
        centerOffset = rotateVector(forward, 90 * deg2Rad)
        let scalar = 1 - sprite.colliderAnchor * 2
        centerOffset = centerOffset.multiplyScalar(scalar)
        center = center.add(centerOffset)
    } else {
        centerOffset = rotateVector(forward, -90 * deg2Rad)
        let scalar = sprite.colliderAnchor * 2 - 1
        centerOffset = centerOffset.multiplyScalar(scalar)
        center = center.add(centerOffset)
    }

    //--Scale halfwidth to custom collider scale
    forward = forward.multiplyScalar(sprite.colliderScale)

    //Rotate forward vector left and right to get corners
    let left = rotateVector(forward, 90 * deg2Rad)
    let right = rotateVector(forward, -90 * deg2Rad)
    left = center.add(left)
    right = center.add(right)

    return {
        left: left,
        right: right,
        center: center,
        centerOffset: centerOffset,
    }
}

//---Generic math functions
//Standard lerp
export function lerp(a, b, t) {
    return a + (b - a) * t
}

export function iOS() {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
}

//Standard map function, map from 1 to 2 eg: 10, 5, 15, 0, 1 == 0.5
export function mapRange(value, low1, high1, low2, high2) {
    return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export function getCentralAngle(radius, arcLength) {
    if (radius == 0) {
        return 0
    }
    let angle = arcLength / radius
    return angle * rad2Deg
}

//---This one is the right one, however old one is already used
export function getCentralAngle2(radius, arcLength) {
    if (radius == 0) {
        return 0
    }
    let angle = (arcLength * 360) / (2 * Math.PI * radius)
    return angle
}

//---Gets signed angle between 2 given angles, handles overflow (-180 to 180)
export function AngleDifference(angle1, angle2) {
    let diff = ((angle2 - angle1 + 180) % 360) - 180
    return diff < -180 ? diff + 360 : diff
}

//---Doesnt work
export function rainbowLerp(t) {
    const red = 0xff0000
    const green = 0x00ff00
    const blue = 0x0000ff

    return lerp(blue, red, t)
    if (t < 0.33) {
        t *= 3
        return lerp(red, green, t)
    }
    if (t < 0.66) {
        t -= 0.33
        t *= 3
        return lerp(green, blue, t)
    }
    t -= 0.66
    t *= 3
    return lerp(blue, red, t)
}

//---Cubic formula solving funcs
function cuberoot(x) {
    var y = Math.pow(Math.abs(x), 1 / 3)
    return x < 0 ? -y : y
}

export function solveCubic(a, b, c, d) {
    if (Math.abs(a) < 1e-8) {
        // Quadratic case, ax^2+bx+c=0
        a = b
        b = c
        c = d
        if (Math.abs(a) < 1e-8) {
            // Linear case, ax+b=0
            a = b
            b = c
            if (Math.abs(a) < 1e-8)
                // Degenerate case
                return []
            return [-b / a]
        }

        var D = b * b - 4 * a * c
        if (Math.abs(D) < 1e-8) return [-b / (2 * a)]
        else if (D > 0)
            return [
                (-b + Math.sqrt(D)) / (2 * a),
                (-b - Math.sqrt(D)) / (2 * a),
            ]
        return []
    }

    // Convert to depressed cubic t^3+pt+q = 0 (subst x = t - b/3a)
    var p = (3 * a * c - b * b) / (3 * a * a)
    var q = (2 * b * b * b - 9 * a * b * c + 27 * a * a * d) / (27 * a * a * a)
    var roots

    if (Math.abs(p) < 1e-8) {
        // p = 0 -> t^3 = -q -> t = -q^1/3
        roots = [cuberoot(-q)]
    } else if (Math.abs(q) < 1e-8) {
        // q = 0 -> t^3 + pt = 0 -> t(t^2+p)=0
        roots = [0].concat(p < 0 ? [Math.sqrt(-p), -Math.sqrt(-p)] : [])
    } else {
        var D = (q * q) / 4 + (p * p * p) / 27
        if (Math.abs(D) < 1e-8) {
            // D = 0 -> two roots
            roots = [(-1.5 * q) / p, (3 * q) / p]
        } else if (D > 0) {
            // Only one real root
            var u = cuberoot(-q / 2 - Math.sqrt(D))
            roots = [u - p / (3 * u)]
        } else {
            // D < 0, three roots, but needs to use complex numbers/trigonometric solution
            var u = 2 * Math.sqrt(-p / 3)
            var t = Math.acos((3 * q) / p / u) / 3 // D < 0 implies p < 0 and acos argument in [-1..1]
            var k = (2 * Math.PI) / 3
            roots = [
                u * Math.cos(t),
                u * Math.cos(t - k),
                u * Math.cos(t - 2 * k),
            ]
        }
    }

    // Convert back from depressed cubic
    for (var i = 0; i < roots.length; i++) roots[i] -= b / (3 * a)

    return roots
}

//--- These are for sending and receiving events
export function sendInternalEvent(name, data) {
    if (!data) {
        data = {}
    }
    data.name = 'internalEvent'
    data.eventName = name
    window.postMessage(data, '*')
}

export function send(name, data) {
    // console.log('send', name, data)
    // if (!GameSettings.debug) {
    //     window.postMessage(data, '*')
    // }
    if (GameSettings.debug || GameSettings.noIframe) {
        switch (name) {
            //TODO JENNY: Waarom is die user check alleen in debug? zit nergens anders? Dit moet in receive
            case 'allLoaded':
                receive('game-info', CommunicationManager.debugGameInfo)
                break
            case 'game-end':
                receive('prize', CommunicationManager.debugPrize)
                break
            case 'game-start':
                //console.log('Game started')
                break
            case 'close':
                //console.log('Game closed')
                break
        }
    }

    if (!data) {
        data = {}
    }
    data.eventName = name
    //window.postMessage(data, '*')

    if (window.parent && window.parent.postMessage) {
        if (GameSettings.noIframe) {
            window.postMessage(data, '*');
        } else {
            window.parent.postMessage(data, '*');
            window.postMessage(data, '*');
            //when the game in in wrapper we pass the event to the both: to wrapper and to itself because it'is used for communication before different parts 
        }
        
    } else {

        console.log("no parent");
    }

    if (GameSettings.debug === false) {
        //window.postMessage(data, '*');
    } 
}

export function receive(name, data) {
    console.log('<--receive', name, data)
    if (!data) {
        data = {}
    }

    switch (name) {
        case 'game-info':
            GlobalData.customerId = data.customerId;
            GlobalData.bestTime = 1000000;
            GlobalData.drsAvailable = 7
            console.log('event: game-info');
            if (data.customerId === '') {
                //console.log('isUser: false')
                GlobalData.isUser = false;
            } else {
                //console.log('isUser: true')
                GlobalData.isUser = true;
            }
            GlobalData.bestTimeGameReplay = Replay.hist;
            break
        case 'prize':
            GlobalData.drsAvailable = data.prize.drs
            break
    }

    sendInternalEvent(name, data)
    //console.log('send internalEvent', data)
}

export function autoSizeText(text, pixiText, style, maxLines) {
    let textMetrics = new PIXI.TextMetrics.measureText(text, style)

    while (textMetrics.lines.length > maxLines) {
        textMetrics.style.fontSize -= 1
        pixiText.updateText()
        textMetrics = new PIXI.TextMetrics.measureText(text, style)
    }
}

export function autoSizeBitmapText(bitmapText, maxWidth, maxLines) {
    // console.log("textHeight: ", bitmapText.textHeight)
    // console.log("maxLineHeight: ", bitmapText.maxLineHeight)
    // console.log("textWidth", bitmapText.textWidth, " maxUntilWrap:", maxWidth)

    while (
        bitmapText.textWidth > maxWidth ||
        Math.round(bitmapText.textHeight / bitmapText.maxLineHeight) > maxLines
    ) {
        bitmapText.fontSize -= 1
    }

    // console.log("after", bitmapText.fontSize, " textWidth: ", bitmapText.textWidth)
    // console.log("Lines: ", lines)
}
//---Sets given text to given bitmapText object, if it exceeds given width it adds a line break
//---If text exceeds maxLines it shrinks the fontSize by 1 and tries same loop again, untill it crashes
export function autoLineBreakBitmapText(
    bitmapText,
    text,
    maxWidth,
    maxLines = 2,
    maxHeight = 26,
    originalFontSize = 0
) {
    if (!bitmapText) return
    originalFontSize =
        originalFontSize == 0 ? bitmapText.fontSize : originalFontSize
    let wordsArray = text.split(' ')
    let combinedString = ''
    let currentLine = 0

    if (wordsArray.length == 0) {
        bitmapText.text = text
    }

    for (let i = 0; i < wordsArray.length; i++) {
        combinedString += wordsArray[i]
        bitmapText.text = combinedString

        //If exceeded mad width, add line break
        if (bitmapText.textWidth > maxWidth) {
            currentLine++

            //If exceeded max number of lines
            if (currentLine == maxLines) {
                //If can fit another line in maxHeight
                if (
                    (bitmapText.textHeight / maxLines) * (maxLines + 1) <
                    maxHeight
                ) {
                    maxLines++
                    bitmapText.fontSize = originalFontSize
                    autoLineBreakBitmapText(
                        bitmapText,
                        text,
                        maxWidth,
                        maxLines,
                        maxHeight,
                        originalFontSize
                    )
                    return
                }

                bitmapText.fontSize -= 1
                autoLineBreakBitmapText(
                    bitmapText,
                    text,
                    maxWidth,
                    maxLines,
                    maxHeight,
                    originalFontSize
                )
                return
            }

            let indexToReplace = combinedString.lastIndexOf(' ')
            let splitString = combinedString.split('')
            splitString[indexToReplace] = '\n'
            combinedString = splitString.join('')
        }

        if (i != wordsArray.length - 1) {
            combinedString += ' '
        }
    }

    bitmapText.text = combinedString
}

export function msToMinutesAndSeconds(ms) {
    Number(ms)
    let minutes = Math.floor(ms / 60000)
    let seconds = ((ms % 60000) / 1000).toFixed(3) // Three digits after the comma
    return minutes + "'" + (seconds < 10 ? '0' : '') + seconds.replace('.', '"')
}

export function getTextStyle(
    fontName = 'RetroGamingRegular',
    fontSize = 16,
    tint = 0xffffff
) {
    return {
        fontName: fontName,
        fontSize: fontSize,
        trim: true,
        tint: tint,
        align: 'center',
        wordWrap: true,
        wordWrapWidth: 200,
        breakWords: true,
    }
}

export function getCurrentDate() {
    const today = new Date()
    return (
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()
    )
}

export function getCurrentTime() {
    const today = new Date()
    return (
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    )
}
