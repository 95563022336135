import PIXI from '../../lib/PixiProjectionExport.js'
import { GameSettings } from '../../settings/GameSettings'
import { textureSheet } from '../TexturePackerLoader/TexturePackerLoader'
import * as TexturePackerLoader from '../TexturePackerLoader/TexturePackerLoader'

const backgroundImageHeight = 200
const backgroundImageWidth = 800
const backgroundHorizonHeight = 210
const horizonHeight = 131

const rotationSpeed = 200

let background
let scale
let gameWindowWidth

export function initBackground(stage) {
    let backgroundTexture = TexturePackerLoader.textureSheet['background.png']
    background = new PIXI.TilingSprite(
        backgroundTexture,
        GameSettings.viewportWidth,
        GameSettings.viewportHeight
    )
    background.tilePosition = new PIXI.Point(
        0,
        -(backgroundHorizonHeight - horizonHeight)
    )

    let topHeight = GameSettings.viewportHeight - horizonHeight
    let topBackgroundHeight = backgroundImageHeight - backgroundHorizonHeight
    let bottomBackgroundHeight = backgroundImageHeight - topBackgroundHeight

    let topScale = topHeight / topBackgroundHeight
    let botScale =
        (GameSettings.viewportHeight - topHeight) / bottomBackgroundHeight
    scale = Math.max(topScale, botScale)
    background.tileScale = new PIXI.Point(scale, scale)

    let scaledHeight = scale * backgroundImageHeight
    let topOffset = GameSettings.viewportHeight - scaledHeight

    background.tilePosition = new PIXI.Point(0, topOffset)

    // background.position.x = 500
    // background.anchor.x = 0.5
    // background.anchor.y = 0

    stage.addChild(background)
}

export function updateBackground(angle) {
    let scaledWidth = scale * backgroundImageWidth
    let lookRotation = -1 * scaledWidth * (angle / Math.PI)
    background.tilePosition = new PIXI.Point(
        lookRotation,
        background.tilePosition.y
    )
    //background.rotation = tilt
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        gameWindowWidth =
            GameSettings.viewportHeight *
            (window.innerWidth / window.innerHeight)
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        gameWindowWidth =
            GameSettings.viewportHeight *
            (window.innerHeight / window.innerWidth)
        resetUIPositions()
    }
}

function resetUIPositions() {
    // +2 for Anti-Aliasing
    background.width = gameWindowWidth + 2
}
