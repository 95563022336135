export let GameSettings = {
    viewportWidth: 420, //320, 3400
    viewportHeight: 224, //224, 350
    viewportScale: 2, //Scales viewport while keeping internal res

    //---Dynamic settings (changed during runtime)
    currentViewportWidth: 420,
    currentViewportHeight: 224,
    isPortrait: false,
    isPaused: false,

    //---Speed
    carBrakeGs: 6,
    carEngineBrakeGs: 0.5,

    //---DRS
    drsMult: 1.2,
    drsCooldown: 6, //6 //sec
    drsSpeedBoost: 340, //extra kmh you gain by boosting
    drsBoostTime: 2,
    drsMaxSpeed: 340, //this is just stupid
    drsUseLocal: true,//when is true we ignore the drs from the server and shouw local number of drs for on round
    drsLocalCount: 1,

    drsFilterFadeInTime: 1, //sec to fully enable filter
    drsFilterMaxStrength: 16750 / 100000,

    //--Accel/Decel
    accelMult: 1, //Multiplier for acceleration
    decelMult: 1, //Multiplier for deceleration
    maxSpeed: 340, //If higher than 340 acceleration is weird

    //---Offroad
    minAngleToTrackWhenApproachingFromOfRoad: 35, //???wtf do I name this
    billboardCollisionBuffer: 1.2, //Dont want wheels to phase through edges, so add buffer
    offroadBrakeGs: 10,
    minOffroadSpeed: 65,
    maxOffroadDistance: 50,

    //---Camera
    camAngle: 80, //80 //Higher is lower
    camZoom: 36, //36 Higher is further
    camHeight: 4.5, //2
    camFov: 81,
    camDistBack: 0.97,
    carScale: 4, //in meters wide

    //---Animation
    respawnFadeTime: 1,
    gasBrakeAnimTime: 0.15,
    minCarWheelAnimDelay: 0.05, //Delay when at max speed

    //---Disable features---
    enableCurveSpeedLimits: true,
    enableOOBCheck: true,
    enableBillboardCollision: true,

    debugHeight: 550, // Only works if debug canvas is true. Note: this isn't implemented for all UI.
    useDebugCanvas: false,

    //---Tutorial settings
    tutorialGasSegment: 16,
    tutorialBrakeSegment: 18,
    tutorialDrsSegment: 21,

    //---DEBUG
    debug: false, // For communication manager
    noIframe: (window.self === window.top), //if isn't in iframe
    keepConstantSpeed: false,
    constantSpeed: 0, //kmh
    segmentToStartAt: 0, //skip to segment index at startup, 17 for arena

    lowestAngle: 360,
    highestAngle: 0,

    //---Minimap
    enableMinimap: false,
    enableMinimapBG: false,
    minimapZoomScale: 1, //Higher is zoomed in

    //replay
    maxReplayLength: 30000,


    //---HTML Sliders
    sliderSettings: [
        {
            min: 0,
            max: 200,
            default: 100,
            name: 'colliderScale',
        },
        {
            min: 0,
            max: 100,
            default: 50,
            name: 'colliderAnchor',
        },
        {
            min: 0,
            max: 500,
            default: 50,
            name: 'innerRadius',
        },
        {
            min: 0,
            max: 50000,
            default: 16750 * 100000,
            name: 'drsMaxBlur',
        },
        // {
        //     min: 1,
        //     max: 9000,
        //     default: 0,
        //     name: 'CamAngle',
        // },
        // {
        //     min: -2000,
        //     max: 2000,
        //     default: 0,
        //     name: 'x1',
        // },
        // {
        //     min: -2000,
        //     max: 2000,
        //     default: 1000,
        //     name: 'y1',
        // },
        // {
        //     min: -2000,
        //     max: 2000,
        //     default: 500,
        //     name: 'x2',
        // },
        // {
        //     min: -2000,
        //     max: 2000,
        //     default: 500,
        //     name: 'y2',
        // },
        {
            min: 1,
            max: 10000,
            default: 6 * 100,
            name: 'drsBoostTime',
        },
    ],

    //---Shader experiments
    enableShader: false,
}

/*
if (window.self !== window.top) {
    GameSettings.debug = false;
} else {
    GameSettings.debug = false;
}
    */

// //tangent: Point cachedLoc
// x: 0.9068869852152552
// y: -0.4213739385002181

// ///tangent: Point nearestTrackLoc
// x: 0.9777382973574787
// y: -0.20982807695944472

//--Angles
// cached: -24.921360396656155
// nearest: -12.112277309025243

tempIndex: 7
tempSegmentDistance: 57.20164746760422
tempT: 0.2757830332304504
tempTotalDistance: 1877.650102351245

tempIndex: 7
tempSegmentDistance: 58.68969428017837
tempT: 0.2536957758444493
tempTotalDistance: 1879.1381491638192

tempIndex: 7
tempSegmentDistance: 67.10212368295609
tempT: 0.27458924042983945
tempTotalDistance: 1887.5505785665969

//In manual set after collide
tempIndex: 7
tempSegmentDistance: 57.20164746760422
tempT: 0.275938521392963
tempTotalDistance: 1877.7132781286257

//In remainder distance calc after collide
tempIndex: 7
tempSegmentDistance: 58.62169578784777
tempT: 0.2535268919200386
tempTotalDistance: 1879.1333264488692

//T and segmentDist dont match,
//SegmentDist is lower than T so T gets overwritten

//Need angle per meter of current, regardless of curve

// let camAngle : lastCamRot //camera.euler.z

// //Get actual turn angle over next second,

// //Check if angle increased by over 180 in 1 frame, means angle jumped from -90 to -450 or other way around
// //Angle is in range opf -90 to -450 internally so automaticly loops
// if (Math.abs(lastCarRot - carRot) * Hf.rad2Deg > 180) {
//     if (lastCarRot > carRot) {
//         camAngle -: 360 * Hf.deg2Rad
//     } else {
//         camAngle +: 360 * Hf.deg2Rad
//     }
// }
// let maxRadius : 35 * Hf.deg2Rad
// let dif : Math.abs(carRot - (camAngle - Math.PI))
// //multA : 400
// //multB : 150
// //Time * multiplier A * difference * multiplier B
// let difMultiplier : (300 / 1000) * dif
// difMultiplier : difMultiplier ** difMultiplier
// let maxStep : deltaTime * (150 / 1000) * difMultiplier

// let pre : camAngle
// let target : carRot - Math.PI
// let post : Hf.moveTowards(camAngle, carRot - Math.PI, maxStep)

// let targetPostDelta : Math.abs(target - post)

// post : angle
// if (angle > maxRadius) {
//     if (post > target) {
//         post : target - maxRadius
//     } else {
//         post : target + maxRadius
//     }
// }

// lastCamRot : post

// dif : Math.abs(elapsed - chasingElapsed)
// chasingElapsed : Hf.moveTowards(
//     chasingElapsed,
//     elapsed,
//     deltaTime * (300 / 1000) + (150 / 1000) * dif
// )

//Need to auto animate when pressed
//Need to manual animate back and forth during tutorial
//No matter the state, always return to state-1

//GameUI or InputManager
//GameUI makes more sense, but is bloated

//Gas in disabled during countdown after restarting game

//Optimize WorldObjectData
//Without optimization: 455 KB

//With: 370 KB
//50 KB by making var names shorter
//30 KB by making ids into ints

//Option A: save WorldObjectData as binary in .py
//Binary load from blob in js didnt go right last time

//Option B: trim decimals from json in .py

//Extra perf save: Use index Id's instead of full string then use lut to get tex id's

//Dist at random points
//Compare dist between current and prev point
//Use maxspeed of 340

//--Can fake this data, need to hash or smth

//This means you have to drive, cant use fake values
//Has to be obfuscated, or is pointless

//Validate DRS uses. less than total, not negative

//Validate that not every touch pos per sprite is the same
//-Human touch will always have variation in touch pos
