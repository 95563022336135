class Replay{}

Replay.hist = [];
Replay.hist.push({time:0, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.01669999999999709, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.033400000000001456, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.049, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.0666999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.08329999999999564, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.0996999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.11659999999999855, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.13240000000000146, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.14900000000000002, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.1666999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.18259999999999854, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.19940000000000146, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.2166999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.23329999999999562, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.25, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.2666999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.28329999999999567, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.29900000000000004, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.3159000000000015, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.3332999999999957, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.3495000000000001, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.36659999999999865, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.3830000000000001, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.40000000000000013, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.4164000000000016, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.4332999999999958, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.4500000000000002, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.46579999999999583, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.48329999999999584, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.4994000000000017, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.5166999999999973, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.5326999999999973, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.5500000000000002, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.5666999999999972, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.5834000000000016, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.6000000000000001, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.6166999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.6332999999999956, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.65, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.6656999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.6826999999999971, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7157999999999956, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.732699999999997, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7500999999999984, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7656999999999969, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7834000000000013, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.7999999999999998, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8156999999999969, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8332999999999954, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8499999999999998, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8666999999999968, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8822999999999953, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.8999999999999997, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9157999999999953, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9334000000000011, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9499999999999996, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9666999999999967, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9832999999999952, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:0.9999999999999996, x:738.9773559570312, y:1132.265048828125, frm:0});
Replay.hist.push({time:1.0166999999999966, x:738.981025363667, y:1132.2650664956584, frm:0});
Replay.hist.push({time:1.033400000000001, x:738.9883580622356, y:1132.26510180136, frm:0});
Replay.hist.push({time:1.0499999999999996, x:738.9992606100728, y:1132.2651542954675, frm:0});
Replay.hist.push({time:1.0656999999999968, x:739.0127997695104, y:1132.265219484768, frm:0});
Replay.hist.push({time:1.0827999999999953, x:739.031369589706, y:1132.265308896607, frm:0});
Replay.hist.push({time:1.1000999999999983, x:739.0540641935416, y:1132.265418169748, frm:0});
Replay.hist.push({time:1.116699999999997, x:739.0794333783399, y:1132.2655403219783, frm:0});
Replay.hist.push({time:1.1324000000000012, x:739.1066369558042, y:1132.265671308124, frm:0});
Replay.hist.push({time:1.1500999999999983, x:739.1413801273382, y:1132.2658385996945, frm:0});
Replay.hist.push({time:1.1656999999999968, x:739.1751628734863, y:1132.2660012689082, frm:0});
Replay.hist.push({time:1.1834000000000011, x:739.2175578768878, y:1132.266205410535, frm:0});
Replay.hist.push({time:1.1989999999999996, x:739.258077873193, y:1132.2664005267477, frm:0});
Replay.hist.push({time:1.2166999999999968, x:739.3081084329149, y:1132.2666414435269, frm:0});
Replay.hist.push({time:1.2329000000000012, x:739.3572946440802, y:1132.266878299063, frm:0});
Replay.hist.push({time:1.2499999999999998, x:739.4129923217776, y:1132.2671465160272, frm:0});
Replay.hist.push({time:1.2666999999999968, x:739.4709888651861, y:1132.267425809654, frm:0});
Replay.hist.push({time:1.2829999999999997, x:739.5310249566683, y:1132.267714931882, frm:0});
Replay.hist.push({time:1.2992999999999952, x:739.5944871185458, y:1132.2680205608995, frm:0});
Replay.hist.push({time:1.3166999999999967, x:739.6661326393453, y:1132.268365609641, frm:0});
Replay.hist.push({time:1.332400000000001, x:739.7339540802864, y:1132.2686922504977, frm:0});
Replay.hist.push({time:1.3489999999999995, x:739.8092095803789, y:1132.2690547055658, frm:0});
Replay.hist.push({time:1.3656999999999966, x:739.8885064228654, y:1132.269436636906, frm:0});
Replay.hist.push({time:1.3834000000000009, x:739.9765792910059, y:1132.2698608520577, frm:0});
Replay.hist.push({time:1.3999999999999995, x:740.0627222775291, y:1132.2702757862667, frm:0});
Replay.hist.push({time:1.4166999999999965, x:740.1529680534574, y:1132.2707104983613, frm:0});
Replay.hist.push({time:1.432299999999995, x:740.2403975288706, y:1132.2711316596042, frm:0});
Replay.hist.push({time:1.4499999999999993, x:740.3436172415912, y:1132.2716289041896, frm:0});
Replay.hist.push({time:1.4666999999999963, x:740.4445886527029, y:1132.2721153382724, frm:0});
Replay.hist.push({time:1.483299999999995, x:740.5484942491212, y:1132.2726159289923, frm:0});
Replay.hist.push({time:1.4999999999999993, x:740.6566068321813, y:1132.2731368106806, frm:0});
Replay.hist.push({time:1.516599999999998, x:740.7676107272682, y:1132.2736716468487, frm:0});
Replay.hist.push({time:1.5324000000000009, x:740.8764724800038, y:1132.2741961857723, frm:0});
Replay.hist.push({time:1.5495999999999979, x:740.9987765822822, y:1132.2747855236762, frm:0});
Replay.hist.push({time:1.5656999999999963, x:741.1165910379874, y:1132.275353256212, frm:0});
Replay.hist.push({time:1.5824999999999991, x:741.2431523552518, y:1132.275963170099, frm:0});
Replay.hist.push({time:1.5999999999999992, x:741.378920874463, y:1132.276617490855, frm:0});
Replay.hist.push({time:1.6156999999999964, x:741.5038980945438, y:1132.277219837527, frm:0});
Replay.hist.push({time:1.6334000000000006, x:741.6488187768266, y:1132.2779183449436, frm:0});
Replay.hist.push({time:1.6500999999999977, x:741.7891430176247, y:1132.278594739171, frm:0});
Replay.hist.push({time:1.6656999999999962, x:741.9233601150132, y:1132.2792417338305, frm:0});
Replay.hist.push({time:1.6834000000000005, x:742.0796719466815, y:1132.2799952836856, frm:0});
Replay.hist.push({time:1.7000999999999975, x:742.2307497614742, y:1132.280723649979, frm:0});
Replay.hist.push({time:1.7166999999999961, x:742.3844761835289, y:1132.281464834891, frm:0});
Replay.hist.push({time:1.732499999999999, x:742.5340178210697, y:1132.2821858910347, frm:0});
Replay.hist.push({time:1.7500999999999975, x:742.7045873317037, y:1132.2830083969102, frm:0});
Replay.hist.push({time:1.7666999999999962, x:742.8690299249945, y:1132.2838014169602, frm:0});
Replay.hist.push({time:1.7834000000000005, x:743.0380689231441, y:1132.2846166636155, frm:0});
Replay.hist.push({time:1.7990999999999977, x:743.2001800400704, y:1132.2853985562374, frm:0});
Replay.hist.push({time:1.8167999999999949, x:743.3869900162367, y:1132.2862996468984, frm:0});
Replay.hist.push({time:1.8334000000000006, x:743.5657682983408, y:1132.287162067471, frm:0});
Replay.hist.push({time:1.8499999999999992, x:743.7481225904196, y:1132.2880418108682, frm:0});
Replay.hist.push({time:1.8666999999999963, x:743.9351977233993, y:1132.2889444055754, frm:0});
Replay.hist.push({time:1.8834000000000006, x:744.1258995104319, y:1132.2898645779858, frm:0});
Replay.hist.push({time:1.8999999999999992, x:744.3190471939622, y:1132.2907966350222, frm:0});
Replay.hist.push({time:1.9166999999999963, x:744.5169925158358, y:1132.2917519304754, frm:0});
Replay.hist.push({time:1.9324999999999992, x:744.7075325973285, y:1132.2926715713108, frm:0});
Replay.hist.push({time:1.9500999999999977, x:744.9238141888031, y:1132.2937155532372, frm:0});
Replay.hist.push({time:1.9666999999999963, x:745.1314178772932, y:1132.294717747692, frm:0});
Replay.hist.push({time:1.9826999999999964, x:745.3348744379618, y:1132.2957000180902, frm:0});
Replay.hist.push({time:1.9989999999999992, x:745.5456278366914, y:1132.2967176177035, frm:0});
Replay.hist.push({time:2.016699999999996, x:745.7785858818543, y:1132.2978425499614, frm:0});
Replay.hist.push({time:2.0324000000000004, x:745.9884762345364, y:1132.2988561990098, frm:0});
Replay.hist.push({time:2.0500999999999974, x:746.229213452427, y:1132.300018947948, frm:0});
Replay.hist.push({time:2.066699999999996, x:746.458632330013, y:1132.301127157237, frm:0});
Replay.hist.push({time:2.0834, x:746.6931159803497, y:1132.3022599611666, frm:0});
Replay.hist.push({time:2.0999999999999988, x:746.9298414935547, y:1132.3034037289567, frm:0});
Replay.hist.push({time:2.115699999999996, x:747.1570053418612, y:1132.304501425061, frm:0});
Replay.hist.push({time:2.1329000000000002, x:747.4097845847585, y:1132.3057230459656, frm:0});
Replay.hist.push({time:2.1500999999999975, x:747.6664958129918, y:1132.306963828249, frm:0});
Replay.hist.push({time:2.165699999999996, x:747.9025807012589, y:1132.3081050579574, frm:0});
Replay.hist.push({time:2.1834000000000007, x:748.1746027427733, y:1132.3094201772412, frm:0});
Replay.hist.push({time:2.2000999999999977, x:748.4349917131412, y:1132.3106792268245, frm:0});
Replay.hist.push({time:2.215799999999995, x:748.6830964488217, y:1132.311879035981, frm:0});
Replay.hist.push({time:2.2326999999999964, x:748.9539806281444, y:1132.3131891802898, frm:0});
Replay.hist.push({time:2.249999999999999, x:749.2352911189228, y:1132.3145499476766, frm:0});
Replay.hist.push({time:2.2667999999999946, x:749.5122740585592, y:1132.315889977668, frm:0});
Replay.hist.push({time:2.2824000000000004, x:749.7727651828412, y:1132.3171503995852, frm:0});
Replay.hist.push({time:2.298999999999999, x:750.0536641997983, y:1132.3185097629264, frm:0});
Replay.hist.push({time:2.315699999999996, x:750.3400272965916, y:1132.3198957783122, frm:0});
Replay.hist.push({time:2.3332999999999946, x:750.6460108340291, y:1132.3213769930894, frm:0});
Replay.hist.push({time:2.3499999999999988, x:750.9401465230014, y:1132.3228010848995, frm:0});
Replay.hist.push({time:2.366699999999996, x:751.2380778717069, y:1132.3242437855383, frm:0});
Replay.hist.push({time:2.3832999999999944, x:751.5379842973554, y:1132.3256962871837, frm:0});
Replay.hist.push({time:2.3994000000000004, x:751.832405557681, y:1132.327122455729, frm:0});
Replay.hist.push({time:2.415699999999996, x:752.134119323891, y:1132.3285841893976, frm:0});
Replay.hist.push({time:2.4324000000000003, x:752.4470578746777, y:1132.3301005631613, frm:0});
Replay.hist.push({time:2.4500999999999973, x:752.7830278992218, y:1132.3317288329915, frm:0});
Replay.hist.push({time:2.4664999999999986, x:753.0980458782223, y:1132.3332558382876, frm:0});
Replay.hist.push({time:2.4834999999999985, x:753.4285705202625, y:1132.3348583019679, frm:0});
Replay.hist.push({time:2.500099999999997, x:753.7551369379552, y:1132.3364418706094, frm:0});
Replay.hist.push({time:2.5157999999999943, x:754.0674285350551, y:1132.3379564947597, frm:0});
Replay.hist.push({time:2.5334, x:754.4217875163579, y:1132.339675476006, frm:0});
Replay.hist.push({time:2.5499999999999985, x:754.7598657264058, y:1132.3413158077356, frm:0});
Replay.hist.push({time:2.5666999999999955, x:755.1038718416977, y:1132.3429852317838, frm:0});
Replay.hist.push({time:2.582599999999997, x:755.434947413392, y:1132.3445922219432, frm:0});
Replay.hist.push({time:2.5999999999999988, x:755.8014756854438, y:1132.3463716583788, frm:0});
Replay.hist.push({time:2.6161999999999956, x:756.135702552851, y:1132.3479946121856, frm:0});
Replay.hist.push({time:2.6334, x:756.4778703775621, y:1132.3496564594723, frm:0});
Replay.hist.push({time:2.650099999999997, x:756.8136949004652, y:1132.3512878282475, frm:0});
Replay.hist.push({time:2.6666999999999956, x:757.1510703844241, y:1132.352927061952, frm:0});
Replay.hist.push({time:2.6834, x:757.494087525113, y:1132.3545940487859, frm:0});
Replay.hist.push({time:2.700099999999997, x:757.8407229166286, y:1132.3562789711743, frm:0});
Replay.hist.push({time:2.716799999999994, x:758.1909840768753, y:1132.3579818785956, frm:0});
Replay.hist.push({time:2.7333999999999996, x:758.542739401983, y:1132.3596924173846, frm:0});
Replay.hist.push({time:2.749799999999994, x:758.8937711580753, y:1132.3613998060691, frm:0});
Replay.hist.push({time:2.7666999999999953, x:759.2592331798169, y:1132.3631777754777, frm:0});
Replay.hist.push({time:2.7833999999999994, x:759.6240298665236, y:1132.3649529100267, frm:0});
Replay.hist.push({time:2.8000999999999965, x:759.9924906565475, y:1132.3667462842093, frm:0});
Replay.hist.push({time:2.816699999999995, x:760.3623749018608, y:1132.3685470029297, frm:0});
Replay.hist.push({time:2.8333999999999993, x:760.7381654024439, y:1132.3703769045298, frm:0});
Replay.hist.push({time:2.8500999999999963, x:761.1176436324091, y:1132.3722252046857, frm:0});
Replay.hist.push({time:2.865699999999995, x:761.4753696279263, y:1132.373967965628, frm:0});
Replay.hist.push({time:2.8833999999999995, x:761.8853704029366, y:1132.3759658869733, frm:0});
Replay.hist.push({time:2.8998999999999997, x:762.2712199448438, y:1132.377846599862, frm:0});
Replay.hist.push({time:2.9157999999999937, x:762.6464212202025, y:1132.379675859152, frm:0});
Replay.hist.push({time:2.9323999999999995, x:763.0418105137172, y:1132.3816040239199, frm:0});
Replay.hist.push({time:2.9497999999999935, x:763.4602940708149, y:1132.383645350625, frm:0});
Replay.hist.push({time:2.966699999999995, x:763.8705967109398, y:1132.385647313228, frm:0});
Replay.hist.push({time:2.983399999999999, x:764.2798003255594, y:1132.387644450252, frm:0});
Replay.hist.push({time:3.000099999999996, x:764.6927648792163, y:1132.3896604888464, frm:0});
Replay.hist.push({time:3.016699999999995, x:765.1069828849417, y:1132.3916832003847, frm:0});
Replay.hist.push({time:3.033399999999999, x:765.5274716951297, y:1132.3937371034876, frm:0});
Replay.hist.push({time:3.049099999999996, x:765.9261473545864, y:1132.3956849916924, frm:0});
Replay.hist.push({time:3.0661999999999945, x:766.3643210606792, y:1132.397826464243, frm:0});
Replay.hist.push({time:3.082399999999999, x:766.7830300500798, y:1132.399873398194, frm:0});
Replay.hist.push({time:3.100099999999996, x:767.2447546419731, y:1132.4021312940795, frm:0});
Replay.hist.push({time:3.116799999999993, x:767.684235048473, y:1132.4042810710946, frm:0});
Replay.hist.push({time:3.1323999999999987, x:768.0981311602401, y:1132.4063062888092, frm:0});
Replay.hist.push({time:3.1499999999999972, x:768.569304177153, y:1132.4086124651408, frm:0});
Replay.hist.push({time:3.1666999999999943, x:769.0202491441742, y:1132.4108203349688, frm:0});
Replay.hist.push({time:3.1833999999999985, x:769.4750480548513, y:1132.413047771254, frm:0});
Replay.hist.push({time:3.1990999999999956, x:769.9060493045196, y:1132.4151593044242, frm:0});
Replay.hist.push({time:3.216699999999994, x:770.3934615607789, y:1132.4175479670698, frm:0});
Replay.hist.push({time:3.2333999999999983, x:770.8598507544125, y:1132.419834364871, frm:0});
Replay.hist.push({time:3.249999999999997, x:771.32729159041, y:1132.422126670189, frm:0});
Replay.hist.push({time:3.266699999999994, x:771.8014428991752, y:1132.4244526558296, frm:0});
Replay.hist.push({time:3.283399999999998, x:772.2794999095138, y:1132.4267985922843, frm:0});
Replay.hist.push({time:3.300099999999995, x:772.7614713057917, y:1132.4291645451515, frm:0});
Replay.hist.push({time:3.3166999999999938, x:773.2444353976887, y:1132.4315361879871, frm:0});
Replay.hist.push({time:3.333399999999998, x:773.7342380637954, y:1132.433942251102, frm:0});
Replay.hist.push({time:3.350099999999995, x:774.227981159116, y:1132.436368529373, frm:0});
Replay.hist.push({time:3.366799999999992, x:774.706526322469, y:1132.43872095028, frm:0});
Replay.hist.push({time:3.3833999999999977, x:775.1720789836193, y:1132.441010286431, frm:0});
Replay.hist.push({time:3.3999999999999964, x:775.6412359109406, y:1132.4433181311822, frm:0});
Replay.hist.push({time:3.4166999999999934, x:776.1168718427324, y:1132.445658654319, frm:0});
Replay.hist.push({time:3.4323999999999977, x:776.5672859047727, y:1132.4478758171176, frm:0});
Replay.hist.push({time:3.449999999999996, x:777.0762360135902, y:1132.4503820074406, frm:0});
Replay.hist.push({time:3.4666999999999932, x:777.5628592905745, y:1132.452779135548, frm:0});
Replay.hist.push({time:3.4834999999999963, x:778.056122697333, y:1132.4552098549689, frm:0});
Replay.hist.push({time:3.500099999999995, x:778.5471665746489, y:1132.4576305219944, frm:0});
Replay.hist.push({time:3.5166999999999935, x:779.041865364466, y:1132.46007010273, frm:0});
Replay.hist.push({time:3.5333999999999977, x:779.5432481097919, y:1132.4625435664307, frm:0});
Replay.hist.push({time:3.549099999999995, x:780.0179141421889, y:1132.4648860868626, frm:0});
Replay.hist.push({time:3.5667999999999918, x:780.5571721017753, y:1132.4675483905771, frm:0});
Replay.hist.push({time:3.5833999999999975, x:781.0666291581962, y:1132.4700645645814, frm:0});
Replay.hist.push({time:3.6000999999999945, x:781.5828881857605, y:1132.4726153245533, frm:0});
Replay.hist.push({time:3.6167999999999916, x:782.1028900886133, y:1132.4751855901488, frm:0});
Replay.hist.push({time:3.6333999999999973, x:782.623486236948, y:1132.477759814701, frm:0});
Replay.hist.push({time:3.649999999999996, x:783.1477948909727, y:1132.4803534336986, frm:0});
Replay.hist.push({time:3.666699999999993, x:783.6790238991044, y:1132.482982351059, frm:0});
Replay.hist.push({time:3.683399999999997, x:784.2140247448265, y:1132.4856310217472, frm:0});
Replay.hist.push({time:3.6990999999999943, x:784.7203556153248, y:1132.4881387623946, frm:0});
Replay.hist.push({time:3.716699999999993, x:785.292111215193, y:1132.490971720075, frm:0});
Replay.hist.push({time:3.733399999999997, x:785.8384481384161, y:1132.493679909098, frm:0});
Replay.hist.push({time:3.750099999999994, x:786.3885856661717, y:1132.4964081039388, frm:0});
Replay.hist.push({time:3.7666999999999926, x:786.9391940927558, y:1132.4991398098805, frm:0});
Replay.hist.push({time:3.7834999999999956, x:787.500291228175, y:1132.5019247673079, frm:0});
Replay.hist.push({time:3.8000999999999943, x:788.0584907984509, y:1132.5046965634601, frm:0});
Replay.hist.push({time:3.816699999999993, x:788.620473700148, y:1132.507488380116, frm:0});
Replay.hist.push({time:3.8327999999999913, x:789.1691090106495, y:1132.5102150870582, frm:0});
Replay.hist.push({time:3.8499999999999956, x:789.7592714108354, y:1132.5131495091211, frm:0});
Replay.hist.push({time:3.866799999999991, x:790.3396175854526, y:1132.5160364677336, frm:0});
Replay.hist.push({time:3.883399999999997, x:790.9168719851718, y:1132.5189093737965, frm:0});
Replay.hist.push({time:3.900099999999994, x:791.5014648369904, y:1132.521820156142, frm:0});
Replay.hist.push({time:3.9166999999999925, x:792.086384966633, y:1132.5247339362004, frm:0});
Replay.hist.push({time:3.9333999999999967, x:792.6787034834718, y:1132.5276859705277, frm:0});
Replay.hist.push({time:3.9500999999999937, x:793.2749067490539, y:1132.5306587924456, frm:0});
Replay.hist.push({time:3.9667999999999908, x:793.8750016075759, y:1132.533652468806, frm:0});
Replay.hist.push({time:3.9823999999999966, x:794.4237150897526, y:1132.5363910975352, frm:0});
Replay.hist.push({time:4.0000999999999935, x:795.0306634701809, y:1132.5394218022477, frm:0});
Replay.hist.push({time:4.016799999999991, x:795.606976483045, y:1132.5423009241404, frm:0});
Replay.hist.push({time:4.032399999999996, x:796.1485281256788, y:1132.5450076244526, frm:0});
Replay.hist.push({time:4.050099999999993, x:796.7670035605773, y:1132.5481002649283, frm:0});
Replay.hist.push({time:4.066799999999991, x:797.3542102016582, y:1132.5510380045207, frm:0});
Replay.hist.push({time:4.083399999999997, x:797.9415098361031, y:1132.55397763262, frm:0});
Replay.hist.push({time:4.099999999999995, x:798.5324213141398, y:1132.5569367799712, frm:0});
Replay.hist.push({time:4.116399999999997, x:799.1197499261682, y:1132.5598794214352, frm:0});
Replay.hist.push({time:4.133399999999997, x:799.7323474337861, y:1132.5629501955673, frm:0});
Replay.hist.push({time:4.150099999999995, x:800.3378156859789, y:1132.5659867724733, frm:0});
Replay.hist.push({time:4.166699999999993, x:800.9432952484664, y:1132.5690249412862, frm:0});
Replay.hist.push({time:4.183399999999998, x:801.5561024644534, y:1132.5721014464273, frm:0});
Replay.hist.push({time:4.2000999999999955, x:802.1725983730986, y:1132.5751980660857, frm:0});
Replay.hist.push({time:4.215899999999999, x:802.7592028472069, y:1132.578146033421, frm:0});
Replay.hist.push({time:4.232399999999998, x:803.3753857436885, y:1132.5812442160222, frm:0});
Replay.hist.push({time:4.250099999999995, x:804.040503706959, y:1132.5845902553012, frm:0});
Replay.hist.push({time:4.266499999999997, x:804.6603893443272, y:1132.587710438871, frm:0});
Replay.hist.push({time:4.2833999999999985, x:805.3029627333834, y:1132.5909465536822, frm:0});
Replay.hist.push({time:4.299999999999997, x:805.9378151103546, y:1132.594145521271, frm:0});
Replay.hist.push({time:4.316799999999993, x:806.5840803351199, y:1132.5974037757605, frm:0});
Replay.hist.push({time:4.332399999999998, x:807.1874753312655, y:1132.6004475170066, frm:0});
Replay.hist.push({time:4.350099999999995, x:807.8762210232692, y:1132.6039237201544, frm:0});
Replay.hist.push({time:4.365999999999996, x:808.4983716853469, y:1132.6070655746173, frm:0});
Replay.hist.push({time:4.382699999999994, x:809.1555450765652, y:1132.6103861195124, frm:0});
Replay.hist.push({time:4.400099999999995, x:809.8443124474447, y:1132.613868320455, frm:0});
Replay.hist.push({time:4.416799999999992, x:810.5091497956412, y:1132.6172315037857, frm:0});
Replay.hist.push({time:4.432399999999998, x:811.1335111617249, y:1132.6203916957386, frm:0});
Replay.hist.push({time:4.449099999999995, x:811.805626400282, y:1132.6237955062152, frm:0});
Replay.hist.push({time:4.466799999999992, x:812.5221874976149, y:1132.6274265958057, frm:0});
Replay.hist.push({time:4.483399999999998, x:813.1979834096815, y:1132.630853189941, frm:0});
Replay.hist.push({time:4.500099999999995, x:813.8816265879814, y:1132.634321630727, frm:0});
Replay.hist.push({time:4.5159999999999965, x:814.5359766847004, y:1132.6376433973571, frm:0});
Replay.hist.push({time:4.533399999999998, x:815.2494005512161, y:1132.6412672199, frm:0});
Replay.hist.push({time:4.550099999999995, x:815.9092412541588, y:1132.6446208897098, frm:0});
Replay.hist.push({time:4.566799999999993, x:816.5726321276422, y:1132.6479945672222, frm:0});
Replay.hist.push({time:4.583399999999998, x:817.2355653089583, y:1132.6513678891145, frm:0});
Replay.hist.push({time:4.600099999999996, x:817.9060467286135, y:1132.6547816301777, frm:0});
Replay.hist.push({time:4.616799999999993, x:818.580089736691, y:1132.6582155477079, frm:0});
Replay.hist.push({time:4.633399999999999, x:819.2536224567402, y:1132.6616489164733, frm:0});
Replay.hist.push({time:4.649599999999996, x:819.9142963048075, y:1132.6650187331427, frm:0});
Replay.hist.push({time:4.666799999999993, x:820.6195090133663, y:1132.6686179109254, frm:0});
Replay.hist.push({time:4.683399999999999, x:821.3036739984173, y:1132.67211183178, frm:0});
Replay.hist.push({time:4.700099999999996, x:821.9955368793051, y:1132.6756472371196, frm:0});
Replay.hist.push({time:4.716699999999995, x:822.6868004587626, y:1132.6791817672756, frm:0});
Replay.hist.push({time:4.7334, x:823.3858115492262, y:1132.6827581402003, frm:0});
Replay.hist.push({time:4.750099999999997, x:824.0884125601676, y:1132.6863551442166, frm:0});
Replay.hist.push({time:4.766799999999995, x:824.7946068138338, y:1132.6899728367302, frm:0});
Replay.hist.push({time:4.783499999999999, x:825.50439757985, y:1132.693611275241, frm:0});
Replay.hist.push({time:4.800099999999998, x:826.2134981511845, y:1132.697248505692, frm:0});
Replay.hist.push({time:4.816699999999996, x:826.9261585686879, y:1132.7009063479, frm:0});
Replay.hist.push({time:4.832699999999996, x:827.6163894238271, y:1132.7044513199548, frm:0});
Replay.hist.push({time:4.850099999999998, x:828.3708841597166, y:1132.7083288887657, frm:0});
Replay.hist.push({time:4.866799999999995, x:829.0986613562669, y:1132.712071670569, frm:0});
Replay.hist.push({time:4.882999999999999, x:829.8080669866393, y:1132.7157223613895, frm:0});
Replay.hist.push({time:4.899099999999998, x:830.5164592834179, y:1132.7193701969306, frm:0});
Replay.hist.push({time:4.916799999999995, x:831.2992599392313, y:1132.7234039439325, frm:0});
Replay.hist.push({time:4.9334999999999996, x:832.041492339762, y:1132.7272313159474, frm:0});
Replay.hist.push({time:4.950099999999998, x:832.7828664936916, y:1132.731056865026, frm:0});
Replay.hist.push({time:4.965999999999999, x:833.4962903180389, y:1132.7347406498639, frm:0});
Replay.hist.push({time:4.983099999999998, x:834.2673221130659, y:1132.7387246162166, frm:0});
Replay.hist.push({time:5.000099999999998, x:835.0376140892057, y:1132.7427075891733, frm:0});
Replay.hist.push({time:5.016699999999997, x:835.7933883802011, y:1132.7466182501614, frm:0});
Replay.hist.push({time:5.033400000000001, x:836.5573507796933, y:1132.7505740594816, frm:0});
Replay.hist.push({time:5.050099999999999, x:837.3188363961948, y:1132.7545198318974, frm:0});
Replay.hist.push({time:5.066699999999997, x:838.0515895242181, y:1132.7583193557612, frm:0});
Replay.hist.push({time:5.0835, x:838.7966405805604, y:1132.7621853024327, frm:0});
Replay.hist.push({time:5.1000999999999985, x:839.5362241412735, y:1132.7660255325295, frm:0});
Replay.hist.push({time:5.116699999999997, x:840.2792052310353, y:1132.7698860718301, frm:0});
Replay.hist.push({time:5.133400000000002, x:841.0300992255094, y:1132.7737904495425, frm:0});
Replay.hist.push({time:5.150099999999999, x:841.7844353474504, y:1132.777715487374, frm:0});
Replay.hist.push({time:5.166799999999997, x:842.542215285611, y:1132.7816612382674, frm:0});
Replay.hist.push({time:5.1832999999999965, x:843.2942899369942, y:1132.785580056644, frm:0});
Replay.hist.push({time:5.200099999999999, x:844.0635171065537, y:1132.7895911172875, frm:0});
Replay.hist.push({time:5.216699999999998, x:844.827000752296, y:1132.7935751012221, frm:0});
Replay.hist.push({time:5.232799999999997, x:845.5707104376402, y:1132.7974586587977, frm:0});
Replay.hist.push({time:5.249699999999998, x:846.3548821436635, y:1132.8015564577845, frm:0});
Replay.hist.push({time:5.266799999999996, x:847.1519472276888, y:1132.8057247471431, frm:0});
Replay.hist.push({time:5.283400000000002, x:847.9291357392163, y:1132.8097921209865, frm:0});
Replay.hist.push({time:5.3001, x:848.7144579624257, y:1132.8139051078028, frm:0});
Replay.hist.push({time:5.316799999999997, x:849.5032365622789, y:1132.8180392853603, frm:0});
Replay.hist.push({time:5.333500000000002, x:850.295472638748, y:1132.8221947070524, frm:0});
Replay.hist.push({time:5.3501, x:851.0863853699412, y:1132.8263463161516, frm:0});
Replay.hist.push({time:5.366799999999998, x:851.8855187254919, y:1132.8305442583583, frm:0});
Replay.hist.push({time:5.3834000000000035, x:852.6832893146687, y:1132.8347382390532, frm:0});
Replay.hist.push({time:5.3992999999999975, x:853.4505792605744, y:1132.838774999516, frm:0});
Replay.hist.push({time:5.416699999999999, x:854.2939599456748, y:1132.8432155023147, frm:0});
Replay.hist.push({time:5.433400000000003, x:855.106898280696, y:1132.8474991240626, frm:0});
Replay.hist.push({time:5.450100000000001, x:855.9233000390864, y:1132.851804363724, frm:0});
Replay.hist.push({time:5.4666999999999994, x:856.7382392430728, y:1132.8561052643306, frm:0});
Replay.hist.push({time:5.483400000000004, x:857.5615488222379, y:1132.8604537704914, frm:0});
Replay.hist.push({time:5.5001000000000015, x:858.3883234234871, y:1132.8648240553005, frm:0});
Replay.hist.push({time:5.516799999999999, x:859.2185634506495, y:1132.869216172492, frm:0});
Replay.hist.push({time:5.533500000000004, x:860.0522692417375, y:1132.873630175827, frm:0});
Replay.hist.push({time:5.5493, x:860.8220227420366, y:1132.8777087545668, frm:0});
Replay.hist.push({time:5.5668, x:861.6689822582247, y:1132.8821999342483, frm:0});
Replay.hist.push({time:5.582900000000006, x:862.4512865725816, y:1132.8863515504947, frm:0});
Replay.hist.push({time:5.5992000000000015, x:863.2464371339231, y:1132.8905745795128, frm:0});
Replay.hist.push({time:5.616200000000002, x:864.079121681084, y:1132.895000458326, frm:0});
Replay.hist.push({time:5.633500000000005, x:864.9300213892868, y:1132.8995268692565, frm:0});
Replay.hist.push({time:5.649800000000001, x:865.7349021241255, y:1132.9038119431057, frm:0});
Replay.hist.push({time:5.666800000000001, x:866.5777341420934, y:1132.9083026813416, frm:0});
Replay.hist.push({time:5.682400000000007, x:867.3540695218093, y:1132.9124423960536, frm:0});
Replay.hist.push({time:5.700200000000002, x:868.2435483437848, y:1132.9171893100547, frm:0});
Replay.hist.push({time:5.716800000000001, x:869.0763508527868, y:1132.9216375171493, frm:0});
Replay.hist.push({time:5.733500000000006, x:869.9174553242503, y:1132.9261337656594, frm:0});
Replay.hist.push({time:5.750000000000005, x:870.7517027305018, y:1132.9305970381258, frm:0});
Replay.hist.push({time:5.765800000000001, x:871.5535220640415, y:1132.9348902792417, frm:0});
Replay.hist.push({time:5.783500000000005, x:872.4553885750811, y:1132.9397232725753, frm:0});
Replay.hist.push({time:5.799100000000004, x:873.2531858743029, y:1132.9440021641108, frm:0});
Replay.hist.push({time:5.816700000000003, x:874.156844262513, y:1132.948852911501, frm:0});
Replay.hist.push({time:5.833500000000005, x:875.0227776465703, y:1132.9535052322797, frm:0});
Replay.hist.push({time:5.8491000000000035, x:875.8297608226396, y:1132.9578444366925, frm:0});
Replay.hist.push({time:5.8668000000000005, x:876.7489901913218, y:1132.962791437202, frm:0});
Replay.hist.push({time:5.883500000000005, x:877.61960030393, y:1132.9674809576245, frm:0});
Replay.hist.push({time:5.900100000000004, x:878.4882417054514, y:1132.972163928756, frm:0});
Replay.hist.push({time:5.916800000000001, x:879.365391822173, y:1132.976896890759, frm:0});
Replay.hist.push({time:5.933400000000007, x:880.2405317393934, y:1132.981623140005, frm:0});
Replay.hist.push({time:5.949100000000004, x:881.0711485034401, y:1132.986112766695, frm:0});
Replay.hist.push({time:5.966800000000001, x:882.011185345448, y:1132.9911983341653, frm:0});
Replay.hist.push({time:5.982700000000002, x:882.858650094939, y:1132.9957871998595, frm:0});
Replay.hist.push({time:6.000100000000003, x:883.7895649907599, y:1133.0008324345417, frm:0});
Replay.hist.push({time:6.016800000000001, x:884.6725202710925, y:1133.0056221093666, frm:0});
Replay.hist.push({time:6.0334000000000065, x:885.5373444134132, y:1133.0103175582917, frm:0});
Replay.hist.push({time:6.050100000000004, x:886.410498460326, y:1133.0150623867546, frm:0});
Replay.hist.push({time:6.0668000000000015, x:887.2867741057383, y:1133.0198283830207, frm:0});
Replay.hist.push({time:6.082400000000007, x:888.1080855000841, y:1133.0242992650274, frm:0});
Replay.hist.push({time:6.099100000000004, x:888.990393768633, y:1133.0291063289358, frm:0});
Replay.hist.push({time:6.116800000000001, x:889.9290025740046, y:1133.034224851449, frm:0});
Replay.hist.push({time:6.133500000000006, x:890.8177283352487, y:1133.039075838639, frm:0});
Replay.hist.push({time:6.149400000000007, x:891.6667256511939, y:1133.0437140633742, frm:0});
Replay.hist.push({time:6.165800000000001, x:892.5454054498898, y:1133.0485186660069, frm:0});
Replay.hist.push({time:6.183500000000005, x:893.4971855363339, y:1133.053727829654, frm:0});
Replay.hist.push({time:6.2001000000000035, x:894.3929188622078, y:1133.0586348623556, frm:0});
Replay.hist.push({time:6.215800000000001, x:895.2428582909448, y:1133.0632951741393, frm:0});
Replay.hist.push({time:6.232400000000006, x:896.14455727781, y:1133.0682437148855, frm:0});
Replay.hist.push({time:6.250200000000002, x:897.1149207091528, y:1133.0735741884585, frm:0});
Replay.hist.push({time:6.266800000000001, x:898.0229638665386, y:1133.078567119872, frm:0});
Replay.hist.push({time:6.2834000000000065, x:898.9340647450272, y:1133.0835815391936, frm:0});
Replay.hist.push({time:6.300100000000004, x:899.853743222335, y:1133.0886479263618, frm:0});
Replay.hist.push({time:6.316700000000003, x:900.7709704541577, y:1133.0937055829165, frm:0});
Replay.hist.push({time:6.333500000000005, x:901.702366359377, y:1133.0988462548837, frm:0});
Replay.hist.push({time:6.350100000000004, x:902.6257278474122, y:1133.1039474552629, frm:0});
Replay.hist.push({time:6.366800000000001, x:903.5577301024407, y:1133.1091013233956, frm:0});
Replay.hist.push({time:6.383400000000007, x:904.4871964922928, y:1133.1142461131058, frm:0});
Replay.hist.push({time:6.4002000000000026, x:905.4309676786029, y:1133.1194751465096, frm:0});
Replay.hist.push({time:6.4164000000000065, x:906.3439424583928, y:1133.1245384161593, frm:0});
Replay.hist.push({time:6.433400000000007, x:907.3051580818798, y:1133.129874407588, frm:0});
Replay.hist.push({time:6.450100000000004, x:908.2524871039901, y:1133.1351385234525, frm:0});
Replay.hist.push({time:6.466800000000002, x:909.199980112736, y:1133.1404087386702, frm:0});
Replay.hist.push({time:6.4835000000000065, x:910.1261863943948, y:1133.1455655774437, frm:0});
Replay.hist.push({time:6.500200000000004, x:911.055333601913, y:1133.1507437941575, frm:0});
Replay.hist.push({time:6.516300000000003, x:911.9538457325299, y:1133.1557560558304, frm:0});
Replay.hist.push({time:6.532500000000007, x:912.8606974002581, y:1133.160819611401, frm:0});
Replay.hist.push({time:6.5491000000000055, x:913.792825272508, y:1133.1660293085338, frm:0});
Replay.hist.push({time:6.566800000000002, x:914.7899757801638, y:1133.1716080521685, frm:0});
Replay.hist.push({time:6.582500000000007, x:915.6770937759118, y:1133.1765761034394, frm:0});
Replay.hist.push({time:6.599200000000004, x:916.6236081206586, y:1133.181881891042, frm:0});
Replay.hist.push({time:6.616800000000003, x:917.6243451841053, y:1133.1874973734314, frm:0});
Replay.hist.push({time:6.632500000000007, x:918.5196757502832, y:1133.192526398084, frm:0});
Replay.hist.push({time:6.650100000000006, x:919.5265326826933, y:1133.1981875308095, frm:0});
Replay.hist.push({time:6.666800000000004, x:920.4848354608453, y:1133.2035812520141, frm:0});
Replay.hist.push({time:6.683500000000008, x:921.4460410804561, y:1133.208996798913, frm:0});
Replay.hist.push({time:6.700100000000007, x:922.4043582561704, y:1133.2144015551546, frm:0});
Replay.hist.push({time:6.7168000000000045, x:923.3713406460564, y:1133.219860743765, frm:0});
Replay.hist.push({time:6.73340000000001, x:924.3353922431784, y:1133.2253089604806, frm:0});
Replay.hist.push({time:6.750100000000008, x:925.3081356393617, y:1133.230811951823, frm:0});
Replay.hist.push({time:6.766800000000005, x:926.2837621004303, y:1133.2363369708567, frm:0});
Replay.hist.push({time:6.783200000000007, x:927.2446472563632, y:1133.2417841189008, frm:0});
Replay.hist.push({time:6.800200000000007, x:928.2436481206133, y:1133.2474532567267, frm:0});
Replay.hist.push({time:6.816700000000007, x:929.2160829038615, y:1133.2529774432355, frm:0});
Replay.hist.push({time:6.83350000000001, x:930.2090902025324, y:1133.258624422239, frm:0});
Replay.hist.push({time:6.850200000000007, x:931.1990513077001, y:1133.2642600511815, frm:0});
Replay.hist.push({time:6.86650000000001, x:932.1680339492494, y:1133.2697820431802, frm:0});
Replay.hist.push({time:6.882700000000007, x:933.1337592321572, y:1133.2752911832954, frm:0});
Replay.hist.push({time:6.900100000000008, x:934.1740761773901, y:1133.2812322377167, frm:0});
Replay.hist.push({time:6.916800000000006, x:935.1629568823631, y:1133.286885711673, frm:0});
Replay.hist.push({time:6.933300000000005, x:936.1310421880901, y:1133.2924261289838, frm:0});
Replay.hist.push({time:6.9492000000000065, x:937.0664265917459, y:1133.2977848897901, frm:0});
Replay.hist.push({time:6.966800000000005, x:938.1048144388593, y:1133.3037400887322, frm:0});
Replay.hist.push({time:6.98250000000001, x:939.0335680367103, y:1133.3090721928384, frm:0});
Replay.hist.push({time:7.00000000000001, x:940.0717496792131, y:1133.3150388823208, frm:0});
Replay.hist.push({time:7.015800000000006, x:941.0115613001747, y:1133.3204460027541, frm:0});
Replay.hist.push({time:7.03350000000001, x:942.0673899427907, y:1133.326527192751, frm:0});
Replay.hist.push({time:7.049100000000008, x:943.0003720467809, y:1133.331906635737, frm:0});
Replay.hist.push({time:7.066800000000005, x:944.0619331958128, y:1133.3380340866718, frm:0});
Replay.hist.push({time:7.0824000000000105, x:944.9999587579064, y:1133.343454371337, frm:0});
Replay.hist.push({time:7.100200000000006, x:946.0732754953817, y:1133.349663223605, frm:0});
Replay.hist.push({time:7.116800000000005, x:947.0769277931495, y:1133.3554756686333, frm:0});
Replay.hist.push({time:7.133400000000011, x:948.0832375157534, y:1133.361309906621, frm:0});
Replay.hist.push({time:7.150200000000006, x:949.1043827265909, y:1133.3672367252982, frm:0});
Replay.hist.push({time:7.166800000000005, x:950.1160236340099, y:1133.37311492137, frm:0});
Replay.hist.push({time:7.183500000000009, x:951.1364297832266, y:1133.3790506597577, frm:0});
Replay.hist.push({time:7.200100000000008, x:952.153364641983, y:1133.3849728274909, frm:0});
Replay.hist.push({time:7.216800000000005, x:953.1790857549145, y:1133.3909528751924, frm:0});
Replay.hist.push({time:7.23350000000001, x:954.2074640478385, y:1133.3969551988291, frm:0});
Replay.hist.push({time:7.249100000000008, x:955.1704451329489, y:1133.4025819929036, frm:0});
Replay.hist.push({time:7.266800000000005, x:956.2659737110678, y:1133.4089905530257, frm:0});
Replay.hist.push({time:7.282800000000005, x:957.258747266608, y:1133.4148047296133, frm:0});
Replay.hist.push({time:7.3002000000000065, x:958.3412113680778, y:1133.421151464073, frm:0});
Replay.hist.push({time:7.316800000000005, x:959.3765243880976, y:1133.427228869367, frm:0});
Replay.hist.push({time:7.33350000000001, x:960.4206943489147, y:1133.4333653430374, frm:0});
Replay.hist.push({time:7.349600000000009, x:961.4182239986169, y:1133.4392343710701, frm:0});
Replay.hist.push({time:7.3658000000000055, x:962.4155593991233, y:1133.4451087724594, frm:0});
Replay.hist.push({time:7.3835000000000095, x:963.5080513679501, y:1133.4515511513314, frm:0});
Replay.hist.push({time:7.400200000000007, x:964.5413746003613, y:1133.457651844901, frm:0});
Replay.hist.push({time:7.416800000000006, x:965.5710065466408, y:1133.4637377542756, frm:0});
Replay.hist.push({time:7.43250000000001, x:966.5470607977694, y:1133.4695134541673, frm:0});
Replay.hist.push({time:7.450200000000007, x:967.6502250876724, y:1133.4760489205648, frm:0});
Replay.hist.push({time:7.466800000000005, x:968.6873329659784, y:1133.482200428482, frm:0});
Replay.hist.push({time:7.48350000000001, x:969.7331858917369, y:1133.488411067235, frm:0});
Replay.hist.push({time:7.5002000000000075, x:970.7815323507806, y:1133.4946438488464, frm:0});
Replay.hist.push({time:7.516900000000012, x:971.8323660558224, y:1133.5008988069167, frm:0});
Replay.hist.push({time:7.532800000000006, x:972.8351270621779, y:1133.506874533186, frm:0});
Replay.hist.push({time:7.550100000000009, x:973.9288037235476, y:1133.513399769476, frm:0});
Replay.hist.push({time:7.5658000000000065, x:974.9235471798073, y:1133.519341748779, frm:0});
Replay.hist.push({time:7.582500000000011, x:975.9840891463236, y:1133.5256841425855, frm:0});
Replay.hist.push({time:7.59910000000001, x:977.0407089385114, y:1133.5320106685704, frm:0});
Replay.hist.push({time:7.616800000000007, x:978.1700700241979, y:1133.5387811302198, frm:0});
Replay.hist.push({time:7.632500000000011, x:979.1740203282379, y:1133.5448070634807, frm:0});
Replay.hist.push({time:7.650200000000008, x:980.3085504069115, y:1133.5516250602298, frm:0});
Replay.hist.push({time:7.665800000000006, x:981.3106380437988, y:1133.5576544782953, frm:0});
Replay.hist.push({time:7.68350000000001, x:982.4502929198126, y:1133.5645200072104, frm:0});
Replay.hist.push({time:7.699300000000006, x:983.4698130714135, y:1133.570669403185, frm:0});
Replay.hist.push({time:7.715800000000006, x:984.5368371134274, y:1133.5771130177154, frm:0});
Replay.hist.push({time:7.733400000000012, x:985.6776372418436, y:1133.5840108802201, frm:0});
Replay.hist.push({time:7.7501000000000095, x:986.7625140566777, y:1133.5905789878052, frm:0});
Replay.hist.push({time:7.766800000000007, x:987.8454861644448, y:1133.5971437398498, frm:0});
Replay.hist.push({time:7.783500000000012, x:988.9174151774416, y:1133.603649616681, frm:0});
Replay.hist.push({time:7.800200000000009, x:989.9916661479439, y:1133.6101776580988, frm:0});
Replay.hist.push({time:7.815800000000007, x:990.9971993264547, y:1133.616295453947, frm:0});
Replay.hist.push({time:7.833200000000009, x:992.1212228423444, y:1133.6231425850256, frm:0});
Replay.hist.push({time:7.850000000000011, x:993.2088276748773, y:1133.6297763552848, frm:0});
Replay.hist.push({time:7.86660000000001, x:994.2857550220665, y:1133.6363532472433, frm:0});
Replay.hist.push({time:7.883500000000011, x:995.3844801768332, y:1133.6430717417343, frm:0});
Replay.hist.push({time:7.900200000000009, x:996.4724855654856, y:1133.6497331511769, frm:0});
Replay.hist.push({time:7.916800000000007, x:997.556222322714, y:1133.6563768216165, frm:0});
Replay.hist.push({time:7.933500000000012, x:998.6487497511221, y:1133.6630828867073, frm:0});
Replay.hist.push({time:7.9502000000000095, x:999.743533800495, y:1133.6698113923214, frm:0});
Replay.hist.push({time:7.966800000000008, x:1000.8339864218226, y:1133.6765218475769, frm:0});
Replay.hist.push({time:7.983300000000008, x:1001.9200601278835, y:1133.6832138810676, frm:0});
Replay.hist.push({time:8.000200000000008, x:1003.0347428644135, y:1133.6900910658521, frm:0});
Replay.hist.push({time:8.015800000000008, x:1004.0656465905553, y:1133.6964593857772, frm:0});
Replay.hist.push({time:8.033500000000013, x:1005.2377760612569, y:1133.7037095058577, frm:0});
Replay.hist.push({time:8.049500000000013, x:1006.299388029319, y:1133.7102846751864, frm:0});
Replay.hist.push({time:8.065800000000008, x:1007.3829985584849, y:1133.7170045944347, frm:0});
Replay.hist.push({time:8.083500000000013, x:1008.5621183968137, y:1133.724326590203, frm:0});
Replay.hist.push({time:8.100000000000014, x:1009.6634545576005, y:1133.7311748077311, frm:0});
Replay.hist.push({time:8.116900000000015, x:1010.7937137811587, y:1133.738212174166, frm:0});
Replay.hist.push({time:8.132500000000014, x:1011.8389462490051, y:1133.7447285457554, frm:0});
Replay.hist.push({time:8.150100000000013, x:1013.0205474269724, y:1133.7521048502736, frm:0});
Replay.hist.push({time:8.16580000000001, x:1014.0765254883845, y:1133.7587057238798, frm:0});
Replay.hist.push({time:8.183500000000015, x:1015.2629818390859, y:1133.7661321363387, frm:0});
Replay.hist.push({time:8.200200000000011, x:1016.3764558903376, y:1133.7731113095658, frm:0});
Replay.hist.push({time:8.21580000000001, x:1017.4184335925888, y:1133.7796507752232, frm:0});
Replay.hist.push({time:8.233000000000015, x:1018.5694680909583, y:1133.786884180126, frm:0});
Replay.hist.push({time:8.249200000000013, x:1019.6555551624142, y:1133.7937186058343, frm:0});
Replay.hist.push({time:8.266500000000015, x:1020.8175931696472, y:1133.8010408542991, frm:0});
Replay.hist.push({time:8.282600000000013, x:1021.9009637640256, y:1133.807876634768, frm:0});
Replay.hist.push({time:8.300100000000013, x:1023.080773143329, y:1133.8153310819496, frm:0});
Replay.hist.push({time:8.316500000000014, x:1024.188413650003, y:1133.8223392135353, frm:0});
Replay.hist.push({time:8.333500000000013, x:1025.3386794526932, y:1133.8296269835573, frm:0});
Replay.hist.push({time:8.35020000000001, x:1026.4706801996572, y:1133.836808952864, frm:0});
Replay.hist.push({time:8.366800000000008, x:1027.597900367924, y:1133.8439704010423, frm:0});
Replay.hist.push({time:8.38320000000001, x:1028.7134828314372, y:1133.8510675785449, frm:0});
Replay.hist.push({time:8.39920000000001, x:1029.8037007440118, y:1133.8580127078872, frm:0});
Replay.hist.push({time:8.416900000000014, x:1031.0119679925174, y:1133.8657206519588, frm:0});
Replay.hist.push({time:8.433000000000012, x:1032.11288189878, y:1133.8727536620386, frm:0});
Replay.hist.push({time:8.45020000000001, x:1033.2910939698509, y:1133.8802909780911, frm:0});
Replay.hist.push({time:8.466900000000013, x:1034.4370308799605, y:1133.8876322668061, frm:0});
Replay.hist.push({time:8.483500000000012, x:1035.5780428785174, y:1133.894952269802, frm:0});
Replay.hist.push({time:8.500200000000008, x:1036.7278769976012, y:1133.902339263634, frm:0});
Replay.hist.push({time:8.516800000000007, x:1037.8727450372628, y:1133.9097047514483, frm:0});
Replay.hist.push({time:8.532900000000012, x:1038.9849313956502, y:1133.916869948033, frm:0});
Replay.hist.push({time:8.55010000000001, x:1040.1751299303778, y:1133.924548646176, frm:0});
Replay.hist.push({time:8.565800000000007, x:1041.2632425273246, y:1133.931578627893, frm:0});
Replay.hist.push({time:8.583500000000011, x:1042.4901420957785, y:1133.9395166532456, frm:0});
Replay.hist.push({time:8.59960000000001, x:1043.5996289476452, y:1133.9467054387378, frm:0});
Replay.hist.push({time:8.616900000000012, x:1044.7937961725597, y:1133.954453992836, frm:0});
Replay.hist.push({time:8.633400000000012, x:1045.9345632991442, y:1133.961866825234, frm:0});
Replay.hist.push({time:8.649200000000008, x:1047.028595424057, y:1133.96898588845, frm:0});
Replay.hist.push({time:8.666800000000006, x:1048.2492818640294, y:1133.9769406144128, frm:0});
Replay.hist.push({time:8.68350000000001, x:1049.4093855703597, y:1133.9845118241576, frm:0});
Replay.hist.push({time:8.700100000000008, x:1050.5643404542457, y:1133.9920603835674, frm:0});
Replay.hist.push({time:8.71640000000001, x:1051.7001495210363, y:1133.9994945013127, frm:0});
Replay.hist.push({time:8.73340000000001, x:1052.886592375354, y:1134.0072713861957, frm:0});
Replay.hist.push({time:8.750200000000007, x:1054.060890848646, y:1134.0149801320313, frm:0});
Replay.hist.push({time:8.766800000000005, x:1055.222970870369, y:1134.022619934151, frm:0});
Replay.hist.push({time:8.783400000000011, x:1056.3868004713966, y:1134.030282506193, frm:0});
Replay.hist.push({time:8.800200000000007, x:1057.5664321463526, y:1134.038060662317, frm:0});
Replay.hist.push({time:8.816800000000006, x:1058.73375303429, y:1134.0457691228369, frm:0});
Replay.hist.push({time:8.83360000000001, x:1059.9168983304226, y:1134.0535937701882, frm:0});
Replay.hist.push({time:8.85010000000001, x:1061.0806094236198, y:1134.0613014084192, frm:0});
Replay.hist.push({time:8.866800000000007, x:1062.2601458805102, y:1134.069125557969, frm:0});
Replay.hist.push({time:8.882500000000011, x:1063.370573689928, y:1134.0765020873218, frm:0});
Replay.hist.push({time:8.900100000000009, x:1064.6172559264623, y:1134.0847962650662, frm:0});
Replay.hist.push({time:8.915800000000006, x:1065.7308653758105, y:1134.0922163407506, frm:0});
Replay.hist.push({time:8.93300000000001, x:1066.9526408695133, y:1134.1003693695673, frm:0});
Replay.hist.push({time:8.950200000000008, x:1068.1761898082766, y:1134.1085471039967, frm:0});
Replay.hist.push({time:8.966800000000006, x:1069.3587036324739, y:1134.1164628574172, frm:0});
Replay.hist.push({time:8.98250000000001, x:1070.4761856940286, y:1134.1239544200907, frm:0});
Replay.hist.push({time:9.000100000000009, x:1071.7264919129882, y:1134.1323492974925, frm:0});
Replay.hist.push({time:9.015800000000006, x:1072.8432685423682, y:1134.1398591497427, frm:0});
Replay.hist.push({time:9.03350000000001, x:1074.1041020549674, y:1134.1483508409715, frm:0});
Replay.hist.push({time:9.050200000000007, x:1075.2953094790616, y:1134.1563864263815, frm:0});
Replay.hist.push({time:9.065800000000007, x:1076.4094488337387, y:1134.1639134461311, frm:0});
Replay.hist.push({time:9.08250000000001, x:1077.6037208964294, y:1134.1719940221342, frm:0});
Replay.hist.push({time:9.100100000000008, x:1078.8640909859546, y:1134.1805355485524, frm:0});
Replay.hist.push({time:9.116800000000005, x:1080.0615747048464, y:1134.1886639967051, frm:0});
Replay.hist.push({time:9.133500000000009, x:1081.2606060521227, y:1134.1968157877916, frm:0});
Replay.hist.push({time:9.150100000000007, x:1082.4539769184264, y:1134.204941895228, frm:0});
Replay.hist.push({time:9.166800000000004, x:1083.6560626241296, y:1134.213140300649, frm:0});
Replay.hist.push({time:9.183500000000008, x:1084.8596641405127, y:1134.2213621173428, frm:0});
Replay.hist.push({time:9.200200000000004, x:1086.0647707481257, y:1134.229607367975, frm:0});
Replay.hist.push({time:9.216900000000008, x:1087.271371681445, y:1134.2378760751308, frm:0});
Replay.hist.push({time:9.233500000000006, x:1088.472213805299, y:1134.2461185108548, frm:0});
Replay.hist.push({time:9.250200000000003, x:1089.681762123192, y:1134.2544340576537, frm:0});
Replay.hist.push({time:9.266900000000007, x:1090.8927722601557, y:1134.2627731281182, frm:0});
Replay.hist.push({time:9.283500000000005, x:1092.0979648652585, y:1134.2710855721978, frm:0});
Replay.hist.push({time:9.300200000000002, x:1093.311857159303, y:1134.279471615467, frm:0});
Replay.hist.push({time:9.316500000000005, x:1094.4980371661309, y:1134.2876794401084, frm:0});
Replay.hist.push({time:9.333500000000004, x:1095.7366239927794, y:1134.2962639024727, frm:0});
Replay.hist.push({time:9.3493, x:1096.8890447870813, y:1134.3042640578092, frm:0});
Replay.hist.push({time:9.3668, x:1098.1665727092864, y:1134.3131472962293, frm:0});
Replay.hist.push({time:9.382500000000004, x:1099.311963268826, y:1134.321124807889, frm:0});
Replay.hist.push({time:9.400200000000002, x:1100.6047966594606, y:1134.330144160622, frm:0});
Replay.hist.push({time:9.416900000000005, x:1101.825950431978, y:1134.338678029978, frm:0});
Replay.hist.push({time:9.433000000000003, x:1103.004484538212, y:1134.3469275433654, frm:0});
Replay.hist.push({time:9.449600000000002, x:1104.22093862677, y:1134.355456433481, frm:0});
Replay.hist.push({time:9.4668, x:1105.482765458709, y:1134.364318467845, frm:0});
Replay.hist.push({time:9.483500000000003, x:1106.7092258558655, y:1134.372946835046, frm:0});
Replay.hist.push({time:9.4992, x:1107.8633980547283, y:1134.3810799428188, frm:0});
Replay.hist.push({time:9.5163, x:1109.121841808742, y:1134.3899625741503, frm:0});
Replay.hist.push({time:9.532500000000004, x:1110.3152568009932, y:1134.398400476418, frm:0});
Replay.hist.push({time:9.550200000000002, x:1111.6205961080736, y:1134.40764569765, frm:0});
Replay.hist.push({time:9.566900000000006, x:1112.8534440352041, y:1134.416392872139, frm:0});
Replay.hist.push({time:9.583500000000004, x:1114.080139008107, y:1134.4251112806119, frm:0});
Replay.hist.push({time:9.599400000000006, x:1115.2562233628187, y:1134.4334839870166, frm:0});
Replay.hist.push({time:9.616900000000006, x:1116.5519968039189, y:1134.4427247061435, frm:0});
Replay.hist.push({time:9.632500000000006, x:1117.708140819753, y:1134.4509838203094, frm:0});
Replay.hist.push({time:9.650100000000004, x:1119.013839723607, y:1134.4603274178603, frm:0});
Replay.hist.push({time:9.6658, x:1120.1796288269916, y:1134.4686843171535, frm:0});
Replay.hist.push({time:9.683500000000006, x:1121.4952468867687, y:1134.4781317511583, frm:0});
Replay.hist.push({time:9.700200000000002, x:1122.7376963140086, y:1134.4870698839027, frm:0});
Replay.hist.push({time:9.716800000000001, x:1123.973841338712, y:1134.495978267982, frm:0});
Replay.hist.push({time:9.733600000000004, x:1125.226030948099, y:1134.5050182122682, frm:0});
Replay.hist.push({time:9.750200000000003, x:1126.4651603367029, y:1134.513979720769, frm:0});
Replay.hist.push({time:9.766600000000004, x:1127.6914255578768, y:1134.522863779212, frm:0});
Replay.hist.push({time:9.783500000000005, x:1128.9562071325645, y:1134.5320431889854, frm:0});
Replay.hist.push({time:9.799200000000003, x:1130.1321420023621, y:1134.5405926910053, frm:0});
Replay.hist.push({time:9.815900000000006, x:1131.3840585985993, y:1134.5497104688723, frm:0});
Replay.hist.push({time:9.833300000000001, x:1132.6896103010245, y:1134.559236367376, frm:0});
Replay.hist.push({time:9.850200000000003, x:1133.9587227518207, y:1134.5685135800713, frm:0});
Replay.hist.push({time:9.866900000000006, x:1135.2138558047177, y:1134.5777053432143, frm:0});
Replay.hist.push({time:9.883600000000003, x:1136.4700168132995, y:1134.5869213719689, frm:0});
Replay.hist.push({time:9.900200000000002, x:1137.7196587650747, y:1134.5961062564572, frm:0});
Replay.hist.push({time:9.916900000000005, x:1138.977832054833, y:1134.6053707283106, frm:0});
Replay.hist.push({time:9.933600000000002, x:1140.2369956301693, y:1134.6146595230043, frm:0});
Replay.hist.push({time:9.9502, x:1141.4895847462492, y:1134.6239167942722, frm:0});
Replay.hist.push({time:9.9668, x:1142.7431273436723, y:1134.6331981352018, frm:0});
Replay.hist.push({time:9.983600000000003, x:1144.0127377711358, y:1134.6426158761687, frm:0});
Replay.hist.push({time:10.000200000000001, x:1145.268160845059, y:1134.6519457023269, frm:0});
Replay.hist.push({time:10.0168, x:1146.524499574864, y:1134.6612996534714, frm:0});
Replay.hist.push({time:10.033400000000006, x:1147.7817413230064, y:1134.6706777478078, frm:0});
Replay.hist.push({time:10.050200000000002, x:1149.055043842773, y:1134.6801934821628, frm:0});
Replay.hist.push({time:10.0668, x:1150.3140640521342, y:1134.6896202086882, frm:0});
Replay.hist.push({time:10.082600000000003, x:1151.5131864307116, y:1134.698614919399, frm:0});
Replay.hist.push({time:10.099900000000005, x:1152.8270873535466, y:1134.708489111941, frm:0});
Replay.hist.push({time:10.115900000000005, x:1154.0438282861712, y:1134.7176504749336, frm:0});
Replay.hist.push({time:10.133500000000003, x:1155.3888190443997, y:1134.7277969816596, frm:0});
Replay.hist.push({time:10.1502, x:1156.6658495029828, y:1134.7374498326049, frm:0});
Replay.hist.push({time:10.166799999999999, x:1157.9360345625587, y:1134.7470694137257, frm:0});
Replay.hist.push({time:10.183500000000002, x:1159.2146710203142, y:1134.7567716921765, frm:0});
Replay.hist.push({time:10.200199999999999, x:1160.4940928134356, y:1134.766498785612, frm:0});
Replay.hist.push({time:10.216799999999997, x:1161.766615090362, y:1134.7761922181767, frm:0});
Replay.hist.push({time:10.233500000000001, x:1163.0475626250272, y:1134.7859688463586, frm:0});
Replay.hist.push({time:10.249699999999999, x:1164.2908544158743, y:1134.795476401644, frm:0});
Replay.hist.push({time:10.266900000000003, x:1165.6116797706648, y:1134.805596732718, frm:0});
Replay.hist.push({time:10.283500000000002, x:1166.8871324108784, y:1134.8153889301848, frm:0});
Replay.hist.push({time:10.299900000000003, x:1168.1478950236644, y:1134.82508728035, frm:0});
Replay.hist.push({time:10.316799999999997, x:1169.4478086844742, y:1134.835106599938, frm:0});
Replay.hist.push({time:10.3326, x:1170.6637060772005, y:1134.8444966299994, frm:0});
Replay.hist.push({time:10.349199999999998, x:1171.9418334253205, y:1134.8543863825876, frm:0});
Replay.hist.push({time:10.366799999999996, x:1173.2976914078868, y:1134.8648991330538, frm:0});
Replay.hist.push({time:10.382599999999998, x:1174.5154291340127, y:1134.874359950893, frm:0});
Replay.hist.push({time:10.400199999999996, x:1175.8726123834626, y:1134.8849254273096, frm:0});
Replay.hist.push({time:10.4169, x:1177.160989725708, y:1134.894976115825, frm:0});
Replay.hist.push({time:10.433499999999999, x:1178.4422374220137, y:1134.9049914291436, frm:0});
Replay.hist.push({time:10.450099999999997, x:1179.7240579192755, y:1134.915031517576, frm:0});
Replay.hist.push({time:10.466799999999994, x:1181.0141674171123, y:1134.925157129181, frm:0});
Replay.hist.push({time:10.483499999999998, x:1182.3100708464992, y:1134.935349119982, frm:0});
Replay.hist.push({time:10.500199999999994, x:1183.6121069559435, y:1134.9456105465547, frm:0});
Replay.hist.push({time:10.516799999999993, x:1184.9068619391433, y:1134.955835769354, frm:0});
Replay.hist.push({time:10.532799999999993, x:1186.155276601438, y:1134.9657151201156, frm:0});
Replay.hist.push({time:10.550299999999993, x:1187.5212990305874, y:1134.9765478806385, frm:0});
Replay.hist.push({time:10.566899999999999, x:1188.8175280645444, y:1134.98684923089, frm:0});
Replay.hist.push({time:10.582799999999994, x:1190.0595073650575, y:1134.996739703584, frm:0});
Replay.hist.push({time:10.599199999999994, x:1191.3409862870976, y:1135.0069656094627, frm:0});
Replay.hist.push({time:10.6169, x:1192.7245605753396, y:1135.0180301399462, frm:0});
Replay.hist.push({time:10.633499999999998, x:1194.0225467256637, y:1135.0284329186477, frm:0});
Replay.hist.push({time:10.6494, x:1195.266155046681, y:1135.0384206040546, frm:0});
Replay.hist.push({time:10.666699999999995, x:1196.619711473444, y:1135.0493144691095, frm:0});
Replay.hist.push({time:10.682599999999997, x:1197.8640483749039, y:1135.0593507065535, frm:0});
Replay.hist.push({time:10.700199999999995, x:1199.2418650246514, y:1135.0704875785304, frm:0});
Replay.hist.push({time:10.716499999999998, x:1200.5182172587263, y:1135.0808269815748, frm:0});
Replay.hist.push({time:10.733499999999998, x:1201.8497410854204, y:1135.0916366720262, frm:0});
Replay.hist.push({time:10.750199999999994, x:1203.1580788680699, y:1135.102281483706, frm:0});
Replay.hist.push({time:10.766099999999996, x:1204.4040004224225, y:1135.1124400971303, frm:0});
Replay.hist.push({time:10.783499999999998, x:1205.7678071264768, y:1135.123584202427, frm:0});
Replay.hist.push({time:10.800199999999995, x:1207.0770056446904, y:1135.134306120976, frm:0});
Replay.hist.push({time:10.816799999999994, x:1208.3786167973594, y:1135.144989365746, frm:0});
Replay.hist.push({time:10.833599999999997, x:1209.7015109952367, y:1135.1558713964464, frm:0});
Replay.hist.push({time:10.850199999999996, x:1211.0192404810134, y:1135.1667352264046, frm:0});
Replay.hist.push({time:10.8669, x:1212.345115385016, y:1135.1776908041056, frm:0});
Replay.hist.push({time:10.883499999999998, x:1213.6632355876325, y:1135.1886068920971, frm:0});
Replay.hist.push({time:10.900099999999997, x:1214.9815273273248, y:1135.199549053766, frm:0});
Replay.hist.push({time:10.917699999999995, x:1216.3794350384856, y:1135.2111791318825, frm:0});
Replay.hist.push({time:10.933499999999997, x:1217.6344594782013, y:1135.2216443533482, frm:0});
Replay.hist.push({time:10.949199999999994, x:1218.8816500431071, y:1135.2320667548304, frm:0});
Replay.hist.push({time:10.966799999999992, x:1220.2799486733381, y:1135.243778742424, frm:0});
Replay.hist.push({time:10.982799999999992, x:1221.5511766457169, y:1135.2544511413041, frm:0});
Replay.hist.push({time:11.000199999999994, x:1222.9337609545494, y:1135.2660853172858, frm:0});
Replay.hist.push({time:11.016899999999998, x:1224.260768058488, y:1135.2772783340336, frm:0});
Replay.hist.push({time:11.032899999999998, x:1225.5321768642002, y:1135.2880268881488, frm:0});
Replay.hist.push({time:11.049299999999992, x:1226.8354115777981, y:1135.2990695191197, frm:0});
Replay.hist.push({time:11.065899999999997, x:1228.1545599666792, y:1135.310272928709, frm:0});
Replay.hist.push({time:11.083599999999995, x:1229.5611533193141, y:1135.3222479149447, frm:0});
Replay.hist.push({time:11.100199999999994, x:1230.8802826153885, y:1135.333505553637, frm:0});
Replay.hist.push({time:11.116899999999998, x:1232.2073273023934, y:1135.3448575378693, frm:0});
Replay.hist.push({time:11.133499999999996, x:1233.5263730530633, y:1135.356167882611, frm:0});
Replay.hist.push({time:11.150199999999993, x:1234.853301339938, y:1135.3675729190022, frm:0});
Replay.hist.push({time:11.166799999999991, x:1236.1721992058262, y:1135.378936026635, frm:0});
Replay.hist.push({time:11.183599999999995, x:1237.5380226725692, y:1135.3907151770427, frm:0});
Replay.hist.push({time:11.200199999999993, x:1238.895308979192, y:1135.4023939078756, frm:0});
Replay.hist.push({time:11.216899999999997, x:1240.2584456942075, y:1135.4140943879656, frm:0});
Replay.hist.push({time:11.232499999999996, x:1241.5296522663596, y:1135.4249798703872, frm:0});
Replay.hist.push({time:11.250199999999994, x:1242.969566817439, y:1135.4372796913879, frm:0});
Replay.hist.push({time:11.266899999999998, x:1244.3256829796098, y:1135.4488341331414, frm:0});
Replay.hist.push({time:11.283599999999995, x:1245.679445399455, y:1135.4603397996082, frm:0});
Replay.hist.push({time:11.299799999999992, x:1246.9904096124424, y:1135.4714542691231, frm:0});
Replay.hist.push({time:11.316799999999992, x:1248.363744524849, y:1135.4830684405583, frm:0});
Replay.hist.push({time:11.333499999999995, x:1249.7104481338256, y:1135.4944283843715, frm:0});
Replay.hist.push({time:11.350299999999992, x:1251.062826345414, y:1135.5058071763308, frm:0});
Replay.hist.push({time:11.366499999999997, x:1252.364613578473, y:1135.5167327262907, frm:0});
Replay.hist.push({time:11.383499999999996, x:1253.728300921493, y:1135.5281486673332, frm:0});
Replay.hist.push({time:11.400199999999993, x:1255.065510729934, y:1135.5393139097284, frm:0});
Replay.hist.push({time:11.416899999999996, x:1256.4003333924402, y:1135.5504304253218, frm:0});
Replay.hist.push({time:11.433499999999995, x:1257.7247908593943, y:1135.5614320740694, frm:0});
Replay.hist.push({time:11.449199999999992, x:1258.97524118771, y:1135.571792786932, frm:0});
Replay.hist.push({time:11.465899999999996, x:1260.3030370493905, y:1135.582766398121, frm:0});
Replay.hist.push({time:11.482999999999995, x:1261.6601573967534, y:1135.5939524946193, frm:0});
Replay.hist.push({time:11.500199999999992, x:1263.0282987589312, y:1135.6051987487556, frm:0});
Replay.hist.push({time:11.516799999999991, x:1264.4053709686616, y:1135.616487181197, frm:0});
Replay.hist.push({time:11.533599999999995, x:1265.796322975993, y:1135.6278574524863, frm:0});
Replay.hist.push({time:11.550199999999993, x:1267.1680228725106, y:1135.6390387845106, frm:0});
Replay.hist.push({time:11.566899999999997, x:1268.5452891372897, y:1135.6502338275086, frm:0});
Replay.hist.push({time:11.582799999999992, x:1269.8540395590217, y:1135.6608424266844, frm:0});
Replay.hist.push({time:11.600199999999994, x:1271.2834777178166, y:1135.6723963111765, frm:0});
Replay.hist.push({time:11.616899999999998, x:1272.6526170355744, y:1135.6834303778799, frm:0});
Replay.hist.push({time:11.632599999999995, x:1273.937266622364, y:1135.6937545571245, frm:0});
Replay.hist.push({time:11.649999999999997, x:1275.3582505571324, y:1135.705141590178, frm:0});
Replay.hist.push({time:11.666899999999998, x:1276.7355544053353, y:1135.716145572631, frm:0});
Replay.hist.push({time:11.682999999999996, x:1278.0450403087054, y:1135.7265774556795, frm:0});
Replay.hist.push({time:11.700399999999998, x:1279.4574382468504, y:1135.7377959691191, frm:0});
Replay.hist.push({time:11.716899999999999, x:1280.794014795268, y:1135.7483803755745, frm:0});
Replay.hist.push({time:11.733499999999998, x:1282.1359956580702, y:1135.758976275564, frm:0});
Replay.hist.push({time:11.750199999999994, x:1283.483329040188, y:1135.769582755503, frm:0});
Replay.hist.push({time:11.766899999999998, x:1284.8279172089644, y:1135.7801358480565, frm:0});
Replay.hist.push({time:11.783499999999997, x:1286.1617281888168, y:1135.7905728644014, frm:0});
Replay.hist.push({time:11.799999999999997, x:1287.4848084470123, y:1135.8008948015818, frm:0});
Replay.hist.push({time:11.8159, x:1288.790936306799, y:1135.8110539706477, frm:0});
Replay.hist.push({time:11.833599999999997, x:1290.2750544920218, y:1135.8225606125231, frm:0});
Replay.hist.push({time:11.850199999999996, x:1291.6637652126944, y:1135.8332917932062, frm:0});
Replay.hist.push({time:11.8669, x:1293.057768266407, y:1135.8440289589907, frm:0});
Replay.hist.push({time:11.8829, x:1294.390427765157, y:1135.8542607924826, frm:0});
Replay.hist.push({time:11.900299999999994, x:1295.836511535633, y:1135.8653269993497, frm:0});
Replay.hist.push({time:11.9159, x:1297.130106383897, y:1135.8751939792435, frm:0});
Replay.hist.push({time:11.933599999999998, x:1298.594623520251, y:1135.8863277073153, frm:0});
Replay.hist.push({time:11.950299999999995, x:1299.9731871899833, y:1135.8967719619689, frm:0});
Replay.hist.push({time:11.9669, x:1301.3404237616187, y:1135.9070957416998, frm:0});
Replay.hist.push({time:11.982799999999996, x:1302.6471186133672, y:1135.9169299888233, frm:0});
Replay.hist.push({time:12.000199999999998, x:1304.073901655257, y:1135.9276317154415, frm:0});
Replay.hist.push({time:12.016299999999996, x:1305.3910561461985, y:1135.9374773728232, frm:0});
Replay.hist.push({time:12.0335, x:1306.7950406698842, y:1135.9479362083405, frm:0});
Replay.hist.push({time:12.049199999999997, x:1308.0736755016094, y:1135.9574289239858, frm:0});
Replay.hist.push({time:12.066099999999999, x:1309.4469983936137, y:1135.9675901298845, frm:0});
Replay.hist.push({time:12.0835, x:1310.8576366955253, y:1135.9779900908145, frm:0});
Replay.hist.push({time:12.100000000000001, x:1312.198996345422, y:1135.9878440420562, frm:0});
Replay.hist.push({time:12.116000000000001, x:1313.5557512791709, y:1135.9977759823862, frm:0});
Replay.hist.push({time:12.1336, x:1315.0432356373733, y:1136.0086241554952, frm:0});
Replay.hist.push({time:12.150199999999998, x:1316.4414983540487, y:1136.018782595849, frm:0});
Replay.hist.push({time:12.166900000000002, x:1317.8435750578647, y:1136.0289305830645, frm:0});
Replay.hist.push({time:12.183599999999998, x:1319.2410420334018, y:1136.039007011603, frm:0});
Replay.hist.push({time:12.200199999999997, x:1320.625585783645, y:1136.0489524867107, frm:0});
Replay.hist.push({time:12.2169, x:1322.0138974347583, y:1136.0588871118216, frm:0});
Replay.hist.push({time:12.233599999999997, x:1323.397635969036, y:1136.0687510655816, frm:0});
Replay.hist.push({time:12.250199999999996, x:1324.7685697116974, y:1136.0784862162052, frm:0});
Replay.hist.push({time:12.2669, x:1326.1432257877589, y:1136.0882101322065, frm:0});
Replay.hist.push({time:12.283599999999996, x:1327.5133452206014, y:1136.097864260503, frm:0});
Replay.hist.push({time:12.300299999999993, x:1328.8789401617512, y:1136.1074488954132, frm:0});
Replay.hist.push({time:12.316899999999999, x:1330.2318870706106, y:1136.1169075653206, frm:0});
Replay.hist.push({time:12.333099999999996, x:1331.5479499391108, y:1136.1260726994255, frm:0});
Replay.hist.push({time:12.350199999999996, x:1332.9325350271847, y:1136.1356768847525, frm:0});
Replay.hist.push({time:12.3669, x:1334.2802067381292, y:1136.144987297498, frm:0});
Replay.hist.push({time:12.382599999999996, x:1335.5777669586369, y:1136.1539161981989, frm:0});
Replay.hist.push({time:12.400199999999995, x:1337.0615067067238, y:1136.1640836061006, frm:0});
Replay.hist.push({time:12.416899999999998, x:1338.4643618229832, y:1136.1736547066282, frm:0});
Replay.hist.push({time:12.433599999999995, x:1339.8623407113985, y:1136.1831516997363, frm:0});
Replay.hist.push({time:12.450199999999993, x:1341.2471307248716, y:1136.192518720554, frm:0});
Replay.hist.push({time:12.466599999999994, x:1342.6105340752606, y:1136.2017016425152, frm:0});
Replay.hist.push({time:12.483599999999994, x:1344.0188890850948, y:1136.2111460568922, frm:0});
Replay.hist.push({time:12.499199999999993, x:1345.3068806242882, y:1136.219746436327, frm:0});
Replay.hist.push({time:12.516599999999995, x:1346.73851569486, y:1136.2292644429883, frm:0});
Replay.hist.push({time:12.533599999999995, x:1348.1322177036864, y:1136.23848806553, frm:0});
Replay.hist.push({time:12.550199999999993, x:1349.4883509992633, y:1136.247422915072, frm:0});
Replay.hist.push({time:12.565899999999997, x:1350.7666345777338, y:1136.2558084455495, frm:0});
Replay.hist.push({time:12.583899999999998, x:1352.2269855063748, y:1136.2653449027323, frm:0});
Replay.hist.push({time:12.599199999999994, x:1353.463981915703, y:1136.2733864045592, frm:0});
Replay.hist.push({time:12.6169, x:1354.890039482136, y:1136.2826153490967, frm:0});
Replay.hist.push({time:12.633499999999998, x:1356.2226735585843, y:1136.2911992302393, frm:0});
Replay.hist.push({time:12.650199999999995, x:1357.6107208144501, y:1136.3000982924611, frm:0});
Replay.hist.push({time:12.665899999999999, x:1358.9278612718285, y:1136.3085031878152, frm:0});
Replay.hist.push({time:12.683499999999997, x:1360.398962265538, y:1136.3178447704142, frm:0});
Replay.hist.push({time:12.700199999999993, x:1361.7895700788263, y:1136.3266306140729, frm:0});
Replay.hist.push({time:12.716899999999997, x:1363.1750553043273, y:1136.335340781857, frm:0});
Replay.hist.push({time:12.733599999999994, x:1364.5554334535025, y:1136.3439756517844, frm:0});
Replay.hist.push({time:12.749499999999996, x:1365.864962589215, y:1136.3521273007143, frm:0});
Replay.hist.push({time:12.765899999999997, x:1367.2108449409527, y:1136.3604644484478, frm:0});
Replay.hist.push({time:12.782999999999996, x:1368.6089671390964, y:1136.3690812088746, frm:0});
Replay.hist.push({time:12.800299999999991, x:1370.0180557344702, y:1136.3777199900549, frm:0});
Replay.hist.push({time:12.816299999999991, x:1371.3164680708505, y:1136.385639560689, frm:0});
Replay.hist.push({time:12.833599999999993, x:1372.7151818792847, y:1136.3941270569637, frm:0});
Replay.hist.push({time:12.850199999999992, x:1374.0522642250291, y:1136.4021978530993, frm:0});
Replay.hist.push({time:12.866899999999996, x:1375.392431125257, y:1136.410245178957, frm:0});
Replay.hist.push({time:12.883499999999994, x:1376.7196500237599, y:1136.4181730410053, frm:0});
Replay.hist.push({time:12.900199999999991, x:1378.0963867897233, y:1136.4263526249074, frm:0});
Replay.hist.push({time:12.916899999999995, x:1379.4920234044457, y:1136.4345984903036, frm:0});
Replay.hist.push({time:12.933599999999991, x:1380.8822205862007, y:1136.4427659521402, frm:0});
Replay.hist.push({time:12.95019999999999, x:1382.2587211044488, y:1136.4508072383153, frm:0});
Replay.hist.push({time:12.966899999999994, x:1383.6381244776735, y:1136.4588196456323, frm:0});
Replay.hist.push({time:12.98359999999999, x:1385.0121410188651, y:1136.4667549351693, frm:0});
Replay.hist.push({time:12.999999999999991, x:1386.3562523861742, y:1136.4744730558364, frm:0});
Replay.hist.push({time:13.016899999999993, x:1387.7359371624293, y:1136.4823495043015, frm:0});
Replay.hist.push({time:13.03359999999999, x:1389.0939301311494, y:1136.490056459933, frm:0});
Replay.hist.push({time:13.050199999999988, x:1390.4385231628048, y:1136.4976425362627, frm:0});
Replay.hist.push({time:13.066899999999992, x:1391.785931258388, y:1136.505199542639, frm:0});
Replay.hist.push({time:13.083599999999988, x:1393.1280570933118, y:1136.5126819859076, frm:0});
Replay.hist.push({time:13.100199999999987, x:1394.4569296355255, y:1136.5200461553259, frm:0});
Replay.hist.push({time:13.116099999999989, x:1395.724910938356, y:1136.527031527755, frm:0});
Replay.hist.push({time:13.133599999999989, x:1397.1349295335308, y:1136.534751749981, frm:0});
Replay.hist.push({time:13.149699999999987, x:1398.4773702182902, y:1136.542055137045, frm:0});
Replay.hist.push({time:13.166899999999991, x:1399.9056124484894, y:1136.5497749313517, frm:0});
Replay.hist.push({time:13.183599999999988, x:1401.286525554021, y:1136.5571892852245, frm:0});
Replay.hist.push({time:13.200299999999984, x:1402.6617283297649, y:1136.5645242693197, frm:0});
Replay.hist.push({time:13.21689999999999, x:1404.0230580546256, y:1136.571737162056, frm:0});
Replay.hist.push({time:13.232599999999987, x:1405.3054345486862, y:1136.5784877618464, frm:0});
Replay.hist.push({time:13.249599999999987, x:1406.6883475833156, y:1136.5857195852764, frm:0});
Replay.hist.push({time:13.265999999999988, x:1408.0169277809184, y:1136.5926201441764, frm:0});
Replay.hist.push({time:13.282599999999986, x:1409.356196099312, y:1136.5995292560892, frm:0});
Replay.hist.push({time:13.300299999999984, x:1410.7781112498617, y:1136.6068128956258, frm:0});
Replay.hist.push({time:13.31589999999999, x:1412.02615671353, y:1136.6131617121523, frm:0});
Replay.hist.push({time:13.333599999999988, x:1413.436316126663, y:1136.6202852688118, frm:0});
Replay.hist.push({time:13.350299999999985, x:1414.7611182608268, y:1136.6269291560504, frm:0});
Replay.hist.push({time:13.365899999999991, x:1416.0309764424962, y:1136.6332532202848, frm:0});
Replay.hist.push({time:13.383599999999989, x:1417.4995437177288, y:1136.6405125961614, frm:0});
Replay.hist.push({time:13.40009999999999, x:1418.862423076969, y:1136.6471972137886, frm:0});
Replay.hist.push({time:13.416899999999993, x:1420.244016817399, y:1136.6539219701954, frm:0});
Replay.hist.push({time:13.433499999999992, x:1421.603180196594, y:1136.6604865785052, frm:0});
Replay.hist.push({time:13.450299999999988, x:1422.9726823691235, y:1136.66704976162, frm:0});
Replay.hist.push({time:13.466999999999992, x:1424.3280401871727, y:1136.673494175749, frm:0});
Replay.hist.push({time:13.48359999999999, x:1425.6693833747413, y:1136.6798217989863, frm:0});
Replay.hist.push({time:13.500299999999987, x:1427.012892886154, y:1136.686109409184, frm:0});
Replay.hist.push({time:13.515899999999993, x:1428.262581591041, y:1136.6919126190342, frm:0});
Replay.hist.push({time:13.533599999999991, x:1429.6742526425867, y:1136.6984152654704, frm:0});
Replay.hist.push({time:13.550299999999988, x:1431.0001401552356, y:1136.7044715694801, frm:0});
Replay.hist.push({time:13.566899999999993, x:1432.312300759114, y:1136.7104161409732, frm:0});
Replay.hist.push({time:13.583499999999992, x:1433.6576901208796, y:1136.7164603932126, frm:0});
Replay.hist.push({time:13.600199999999989, x:1435.0383625013492, y:1136.7226093927425, frm:0});
Replay.hist.push({time:13.616899999999992, x:1436.4126266267517, y:1136.728675539306, frm:0});
Replay.hist.push({time:13.63259999999999, x:1437.6987893484547, y:1136.7343035036058, frm:0});
Replay.hist.push({time:13.650199999999987, x:1439.1338998379097, y:1136.7405267325669, frm:0});
Replay.hist.push({time:13.666899999999991, x:1440.4891326410707, y:1136.7463486426498, frm:0});
Replay.hist.push({time:13.683599999999988, x:1441.838060653954, y:1136.7520902524545, frm:0});
Replay.hist.push({time:13.700199999999986, x:1443.1726896342373, y:1136.757718535326, frm:0});
Replay.hist.push({time:13.71699999999999, x:1444.5171055130481, y:1136.763335116884, frm:0});
Replay.hist.push({time:13.733299999999986, x:1445.8154905382903, y:1136.768708726539, frm:0});
Replay.hist.push({time:13.750199999999987, x:1447.1554233115764, y:1136.77420188249, frm:0});
Replay.hist.push({time:13.76689999999999, x:1448.4732876496294, y:1136.7795524411863, frm:0});
Replay.hist.push({time:13.783599999999987, x:1449.7957537890568, y:1136.7848695176297, frm:0});
Replay.hist.push({time:13.800299999999984, x:1451.1742530834522, y:1136.790356039352, frm:0});
Replay.hist.push({time:13.81689999999999, x:1452.5377810096816, y:1136.7957266827714, frm:0});
Replay.hist.push({time:13.833599999999986, x:1453.9027929002475, y:1136.8010468782509, frm:0});
Replay.hist.push({time:13.849499999999988, x:1455.1961789571021, y:1136.806035745545, frm:0});
Replay.hist.push({time:13.86689999999999, x:1456.6046147727268, y:1136.8114104364183, frm:0});
Replay.hist.push({time:13.883599999999987, x:1457.9495970451717, y:1136.816486381861, frm:0});
Replay.hist.push({time:13.900199999999986, x:1459.2799552732247, y:1136.8214525372111, frm:0});
Replay.hist.push({time:13.91689999999999, x:1460.6117449958192, y:1136.826369454462, frm:0});
Replay.hist.push({time:13.933599999999986, x:1461.9369628144068, y:1136.8312077118803, frm:0});
Replay.hist.push({time:13.950199999999985, x:1463.2477624429016, y:1136.8359397675915, frm:0});
Replay.hist.push({time:13.966999999999988, x:1464.567800497717, y:1136.8406511604585, frm:0});
Replay.hist.push({time:13.983499999999989, x:1465.8844893767784, y:1136.845296424584, frm:0});
Replay.hist.push({time:14.000299999999985, x:1467.2651406377647, y:1136.8501090438165, frm:0});
Replay.hist.push({time:14.01689999999999, x:1468.6222523061092, y:1136.8547812524714, frm:0});
Replay.hist.push({time:14.033599999999987, x:1469.9804452671792, y:1136.8593990731408, frm:0});
Replay.hist.push({time:14.049199999999987, x:1471.2427914954799, y:1136.8636387337033, frm:0});
Replay.hist.push({time:14.06699999999999, x:1472.6756230755173, y:1136.8683897587468, frm:0});
Replay.hist.push({time:14.083599999999988, x:1474.004693263998, y:1136.872738365521, frm:0});
Replay.hist.push({time:14.100199999999987, x:1475.3268684198592, y:1136.8770085168665, frm:0});
Replay.hist.push({time:14.11689999999999, x:1476.6500835789675, y:1136.8812260587174, frm:0});
Replay.hist.push({time:14.133599999999987, x:1477.9663879657207, y:1136.8853658746211, frm:0});
Replay.hist.push({time:14.150099999999988, x:1479.2601763746154, y:1136.8893806043397, frm:0});
Replay.hist.push({time:14.16589999999999, x:1480.5017760211076, y:1136.8931826744958, frm:0});
Replay.hist.push({time:14.182599999999987, x:1481.8716130007242, y:1136.8973196736754, frm:0});
Replay.hist.push({time:14.200299999999984, x:1483.315225071182, y:1136.901613743129, frm:0});
Replay.hist.push({time:14.21589999999999, x:1484.5805912168107, y:1136.9053220122926, frm:0});
Replay.hist.push({time:14.233599999999988, x:1486.0083779068973, y:1136.909443764287, frm:0});
Replay.hist.push({time:14.250299999999985, x:1487.3478587739644, y:1136.9132502235252, frm:0});
Replay.hist.push({time:14.26649999999999, x:1488.6401800428753, y:1136.9168671720063, frm:0});
Replay.hist.push({time:14.28359999999999, x:1489.9967826032744, y:1136.9206052982247, frm:0});
Replay.hist.push({time:14.300299999999986, x:1491.3142573289704, y:1136.9241779391186, frm:0});
Replay.hist.push({time:14.316899999999992, x:1492.616637104689, y:1136.9276537170767, frm:0});
Replay.hist.push({time:14.333599999999988, x:1493.9196497639914, y:1136.9310754678893, frm:0});
Replay.hist.push({time:14.34939999999999, x:1495.1840025612664, y:1136.9343423658484, frm:0});
Replay.hist.push({time:14.366999999999988, x:1496.6196788528057, y:1136.9379881914417, frm:0});
Replay.hist.push({time:14.382599999999988, x:1497.8848666355584, y:1136.941144833235, frm:0});
Replay.hist.push({time:14.400199999999986, x:1499.3039725273222, y:1136.944622770482, frm:0});
Replay.hist.push({time:14.41689999999999, x:1500.642468790333, y:1136.947842342817, frm:0});
Replay.hist.push({time:14.433599999999986, x:1501.9731702997956, y:1136.9509846384453, frm:0});
Replay.hist.push({time:14.450199999999985, x:1503.288224452726, y:1136.954032653442, frm:0});
Replay.hist.push({time:14.46639999999999, x:1504.5642547434336, y:1136.956935750429, frm:0});
Replay.hist.push({time:14.482599999999987, x:1505.8330757918384, y:1136.9597692730144, frm:0});
Replay.hist.push({time:14.499299999999984, x:1507.1335494160808, y:1136.962618494341, frm:0});
Replay.hist.push({time:14.515999999999988, x:1508.4592450915397, y:1136.9654657304047, frm:0});
Replay.hist.push({time:14.533599999999986, x:1509.8910627256535, y:1136.9684760451894, frm:0});
Replay.hist.push({time:14.550199999999984, x:1511.2330500843264, y:1136.9712364536576, frm:0});
Replay.hist.push({time:14.566899999999988, x:1512.574858702686, y:1136.9739375452116, frm:0});
Replay.hist.push({time:14.583599999999985, x:1513.9084329401098, y:1136.9765637768105, frm:0});
Replay.hist.push({time:14.599199999999984, x:1515.1467861297374, y:1136.9789505785348, frm:0});
Replay.hist.push({time:14.616899999999989, x:1516.5432233104614, y:1136.981582264904, frm:0});
Replay.hist.push({time:14.633599999999985, x:1517.8524560839753, y:1136.9839922011145, frm:0});
Replay.hist.push({time:14.649299999999982, x:1519.0759817183389, y:1136.9861943216727, frm:0});
Replay.hist.push({time:14.666699999999985, x:1520.4237362922722, y:1136.9885642645559, frm:0});
Replay.hist.push({time:14.683599999999986, x:1521.794443946122, y:1136.9909148544657, frm:0});
Replay.hist.push({time:14.700299999999983, x:1523.1446998470774, y:1136.9931717725551, frm:0});
Replay.hist.push({time:14.716899999999988, x:1524.478234122942, y:1136.9953439534727, frm:0});
Replay.hist.push({time:14.733599999999985, x:1525.8111715194377, y:1136.9974590842407, frm:0});
Replay.hist.push({time:14.750299999999982, x:1527.135514396215, y:1136.999505392676, frm:0});
Replay.hist.push({time:14.766899999999987, x:1528.4434705408848, y:1137.0014727498337, frm:0});
Replay.hist.push({time:14.783599999999984, x:1529.7508562911135, y:1137.0033863677734, frm:0});
Replay.hist.push({time:14.80029999999998, x:1531.049828332033, y:1137.0052357140853, frm:0});
Replay.hist.push({time:14.816899999999986, x:1532.3327444531908, y:1137.0070117907826, frm:0});
Replay.hist.push({time:14.832599999999983, x:1533.599810525168, y:1137.0087171879836, frm:0});
Replay.hist.push({time:14.850299999999981, x:1535.0271845238567, y:1137.0105809120132, frm:0});
Replay.hist.push({time:14.866899999999987, x:1536.3565650331248, y:1137.0122625463034, frm:0});
Replay.hist.push({time:14.882599999999984, x:1537.6056617866807, y:1137.0137955970283, frm:0});
Replay.hist.push({time:14.900199999999982, x:1538.9964753530348, y:1137.0154496390237, frm:0});
Replay.hist.push({time:14.916399999999987, x:1540.2679338893963, y:1137.0169135740903, frm:0});
Replay.hist.push({time:14.933599999999984, x:1541.6087721670087, y:1137.018408281954, frm:0});
Replay.hist.push({time:14.950199999999983, x:1542.8940321656355, y:1137.019794435842, frm:0});
Replay.hist.push({time:14.966999999999986, x:1544.20906286716, y:1137.0211662768725, frm:0});
Replay.hist.push({time:14.983599999999985, x:1545.5488208713396, y:1137.0225164977549, frm:0});
Replay.hist.push({time:15.000199999999984, x:1546.8749948973038, y:1137.023806821203, frm:0});
Replay.hist.push({time:15.016999999999987, x:1548.2034564739452, y:1137.0250542593649, frm:0});
Replay.hist.push({time:15.033599999999986, x:1549.5027940198802, y:1137.0262316674193, frm:0});
Replay.hist.push({time:15.050299999999982, x:1550.796731540986, y:1137.027363259062, frm:0});
Replay.hist.push({time:15.066899999999988, x:1552.069959133919, y:1137.0284379137595, frm:0});
Replay.hist.push({time:15.083599999999985, x:1553.3379488039325, y:1137.029470941661, frm:0});
Replay.hist.push({time:15.100299999999981, x:1554.5991914794722, y:1137.030462744533, frm:0});
Replay.hist.push({time:15.116899999999987, x:1555.903491859452, y:1137.0314521582318, frm:0});
Replay.hist.push({time:15.133499999999986, x:1557.1943166472033, y:1137.0323963653314, frm:0});
Replay.hist.push({time:15.150199999999982, x:1558.4794793357878, y:1137.0333032198407, frm:0});
Replay.hist.push({time:15.16679999999998, x:1559.7437862704378, y:1137.0341644030586, frm:0});
Replay.hist.push({time:15.183499999999984, x:1561.0026122406816, y:1137.0349927679717, frm:0});
Replay.hist.push({time:15.200199999999981, x:1562.2484834149877, y:1137.0357854788576, frm:0});
Replay.hist.push({time:15.21679999999998, x:1563.474233480855, y:1137.0365405055304, frm:0});
Replay.hist.push({time:15.233399999999985, x:1564.691137418307, y:1137.0372671509538, frm:0});
Replay.hist.push({time:15.250099999999982, x:1565.965666745164, y:1137.0380053771491, frm:0});
Replay.hist.push({time:15.266799999999979, x:1567.226761351439, y:1137.0387146092557, frm:0});
Replay.hist.push({time:15.283399999999984, x:1568.4671828843195, y:1137.0393934464382, frm:0});
Replay.hist.push({time:15.299499999999982, x:1569.6579641988255, y:1137.0400293390612, frm:0});
Replay.hist.push({time:15.316799999999978, x:1570.92396514403, y:1137.040690397509, frm:0});
Replay.hist.push({time:15.333499999999981, x:1572.1331846942498, y:1137.0413093127709, frm:0});
Replay.hist.push({time:15.35009999999998, x:1573.3227362634655, y:1137.0419081832963, frm:1});
Replay.hist.push({time:15.366799999999976, x:1574.5160255256883, y:1137.042085954891, frm:1});
Replay.hist.push({time:15.383399999999982, x:1575.7220451678406, y:1137.0319306576162, frm:1});
Replay.hist.push({time:15.400099999999979, x:1576.9285435866707, y:1137.00716802985, frm:2});
Replay.hist.push({time:15.416799999999975, x:1578.1281200671324, y:1136.9680046207354, frm:2});
Replay.hist.push({time:15.433099999999978, x:1579.2922216688912, y:1136.9160692038583, frm:2});
Replay.hist.push({time:15.450199999999978, x:1580.507628055739, y:1136.8471255261659, frm:3});
Replay.hist.push({time:15.466799999999976, x:1581.6881759008897, y:1136.76567934022, frm:3});
Replay.hist.push({time:15.482399999999982, x:1582.7909908693337, y:1136.6766246072218, frm:3});
Replay.hist.push({time:15.50009999999998, x:1584.0339259189118, y:1136.5611331613698, frm:3});
Replay.hist.push({time:15.516799999999977, x:1585.1988296367585, y:1136.4382376543986, frm:3});
Replay.hist.push({time:15.533399999999983, x:1586.3492933550574, y:1136.302836770585, frm:3});
Replay.hist.push({time:15.55009999999998, x:1587.507296210275, y:1136.1523517993523, frm:3});
Replay.hist.push({time:15.566799999999976, x:1588.657176304258, y:1135.988703097026, frm:3});
Replay.hist.push({time:15.583399999999981, x:1589.792033552721, y:1135.8131684980958, frm:3});
Replay.hist.push({time:15.600099999999978, x:1590.9254158087058, y:1135.6238192949625, frm:3});
Replay.hist.push({time:15.615799999999975, x:1591.9833810951054, y:1135.4342762863957, frm:3});
Replay.hist.push({time:15.63349999999998, x:1593.1735161961467, y:1135.2061276773218, frm:3});
Replay.hist.push({time:15.649199999999977, x:1594.222809876933, y:1134.991721607051, frm:3});
Replay.hist.push({time:15.666799999999975, x:1595.3895970813892, y:1134.7385418121096, frm:3});
Replay.hist.push({time:15.68349999999998, x:1596.48773731909, y:1134.4858767026712, frm:3});
Replay.hist.push({time:15.700199999999976, x:1597.5769308221277, y:1134.2213123712543, frm:3});
Replay.hist.push({time:15.716799999999974, x:1598.6546868197083, y:1133.9456588609737, frm:3});
Replay.hist.push({time:15.733499999999978, x:1599.7327358556151, y:1133.6559369077124, frm:3});
Replay.hist.push({time:15.750199999999975, x:1600.8015184343803, y:1133.3546886012903, frm:3});
Replay.hist.push({time:15.765799999999974, x:1601.7916017887724, y:1133.0629861697328, frm:3});
Replay.hist.push({time:15.783499999999979, x:1602.9047364379962, y:1132.720291114548, frm:3});
Replay.hist.push({time:15.800199999999975, x:1603.9467293027071, y:1132.385133760589, frm:3});
Replay.hist.push({time:15.816799999999974, x:1604.9774956057108, y:1132.0396896753798, frm:3});
Replay.hist.push({time:15.833499999999978, x:1606.004806534768, y:1131.6814150611951, frm:3});
Replay.hist.push({time:15.850099999999976, x:1607.016326832396, y:1131.3147624565659, frm:3});
Replay.hist.push({time:15.866799999999973, x:1608.024162475344, y:1130.9354946675694, frm:3});
Replay.hist.push({time:15.882499999999977, x:1608.9627880585406, y:1130.569519605805, frm:3});
Replay.hist.push({time:15.900099999999975, x:1610.0079981540448, y:1130.147236513812, frm:3});
Replay.hist.push({time:15.916799999999972, x:1610.9907559095145, y:1129.7357289966837, frm:3});
Replay.hist.push({time:15.932699999999974, x:1611.9171790466855, y:1129.3347208024193, frm:3});
Replay.hist.push({time:15.949999999999976, x:1612.9145868233793, y:1128.8884923586845, frm:3});
Replay.hist.push({time:15.966899999999978, x:1613.8784709473055, y:1128.4426788541668, frm:3});
Replay.hist.push({time:15.983499999999976, x:1614.815956800471, y:1127.9950211712935, frm:3});
Replay.hist.push({time:16.000099999999975, x:1615.7456997341574, y:1127.5370681505456, frm:3});
Replay.hist.push({time:16.01689999999998, x:1616.676250835916, y:1127.0644464278462, frm:3});
Replay.hist.push({time:16.033499999999975, x:1617.5854727241388, y:1126.58854662929, frm:3});
Replay.hist.push({time:16.050199999999972, x:1618.4898260875877, y:1126.1010293595882, frm:3});
Replay.hist.push({time:16.06679999999997, x:1619.3784796383895, y:1125.6078796173756, frm:3});
Replay.hist.push({time:16.083399999999976, x:1620.2578616993328, y:1125.105779552154, frm:3});
Replay.hist.push({time:16.100199999999973, x:1621.137987368626, y:1124.5888595553165, frm:3});
Replay.hist.push({time:16.11679999999997, x:1621.9972391157457, y:1124.0699465138414, frm:3});
Replay.hist.push({time:16.132499999999975, x:1622.800458769318, y:1123.5718075369355, frm:3});
Replay.hist.push({time:16.149099999999972, x:1623.6395156655667, y:1123.037606806622, frm:3});
Replay.hist.push({time:16.16679999999997, x:1624.5225891455584, y:1122.4596930921505, frm:3});
Replay.hist.push({time:16.183499999999974, x:1625.3451383357335, y:1121.9065021373065, frm:3});
Replay.hist.push({time:16.19919999999997, x:1626.1089501250528, y:1121.3795902328957, frm:3});
Replay.hist.push({time:16.215799999999966, x:1626.906313652215, y:1120.815561255573, frm:3});
Replay.hist.push({time:16.23349999999997, x:1627.7449116485968, y:1120.206511596216, frm:3});
Replay.hist.push({time:16.250199999999968, x:1628.5253851216628, y:1119.62462646912, frm:3});
Replay.hist.push({time:16.265799999999967, x:1629.2450411204027, y:1119.074833004152, frm:3});
Replay.hist.push({time:16.28349999999997, x:1630.04892325675, y:1118.4451925930705, frm:3});
Replay.hist.push({time:16.299199999999967, x:1630.7520442030332, y:1117.8806465896917, frm:3});
Replay.hist.push({time:16.316899999999972, x:1631.5334010166603, y:1117.2376503615992, frm:3});
Replay.hist.push({time:16.33289999999997, x:1632.2297616172411, y:1116.6502815788222, frm:3});
Replay.hist.push({time:16.349199999999964, x:1632.9292773071911, y:1116.0462132069679, frm:3});
Replay.hist.push({time:16.366799999999962, x:1633.6732736174306, y:1115.387771464891, frm:3});
Replay.hist.push({time:16.383499999999966, x:1634.3663932035124, y:1114.759036263721, frm:3});
Replay.hist.push({time:16.400099999999963, x:1635.0443145780446, y:1114.1292846139136, frm:3});
Replay.hist.push({time:16.41679999999996, x:1635.7160352162098, y:1113.4903430294075, frm:3});
Replay.hist.push({time:16.433399999999967, x:1636.373553363572, y:1112.849980442693, frm:3});
Replay.hist.push({time:16.450199999999963, x:1637.0286556971776, y:1112.1967426482915, frm:3});
Replay.hist.push({time:16.46679999999996, x:1637.6658098583039, y:1111.5462789582555, frm:3});
Replay.hist.push({time:16.483399999999968, x:1638.2911959894757, y:1110.892779543685, frm:3});
Replay.hist.push({time:16.499399999999966, x:1638.8819488066883, y:1110.2612614679067, frm:3});
Replay.hist.push({time:16.51579999999996, x:1639.4778906327424, y:1109.6096787214533, frm:3});
Replay.hist.push({time:16.532499999999963, x:1640.0748120395647, y:1108.9418440371574, frm:3});
Replay.hist.push({time:16.55009999999996, x:1640.6930518641993, y:1108.2334929205329, frm:3});
Replay.hist.push({time:16.566899999999965, x:1641.2729950668727, y:1107.5529664616631, frm:3});
Replay.hist.push({time:16.58319999999996, x:1641.8261730058898, y:1106.8887605577215, frm:3});
Replay.hist.push({time:16.599199999999957, x:1642.3560304316272, y:1106.238177613971, frm:3});
Replay.hist.push({time:16.61589999999996, x:1642.8981888733124, y:1105.5573258300992, frm:3});
Replay.hist.push({time:16.632499999999958, x:1643.4276156539559, y:1104.8770357360618, frm:3});
Replay.hist.push({time:16.649099999999954, x:1643.9476209108614, y:1104.1933702060724, frm:3});
Replay.hist.push({time:16.665899999999958, x:1644.4643284343192, y:1103.4981735323831, frm:3});
Replay.hist.push({time:16.683499999999956, x:1644.9953256866975, y:1102.7665196837918, frm:3});
Replay.hist.push({time:16.700199999999953, x:1645.4895972529625, y:1102.0690435379636, frm:3});
Replay.hist.push({time:16.716899999999956, x:1645.9688859789885, y:1101.3768744127724, frm:2});
Replay.hist.push({time:16.733499999999953, x:1646.43550855224, y:1100.6873204462545, frm:2});
Replay.hist.push({time:16.75009999999995, x:1646.8932124610296, y:1099.995213753133, frm:2});
Replay.hist.push({time:16.766899999999954, x:1647.349664446752, y:1099.2887404149533, frm:2});
Replay.hist.push({time:16.78359999999995, x:1647.7966106384988, y:1098.5804762129555, frm:2});
Replay.hist.push({time:16.800199999999947, x:1648.2341288778075, y:1097.8705451441492, frm:2});
Replay.hist.push({time:16.81689999999995, x:1648.6674455094271, y:1097.1504124415508, frm:2});
Replay.hist.push({time:16.833499999999948, x:1649.0866531764664, y:1096.4367742297138, frm:2});
Replay.hist.push({time:16.849999999999948, x:1649.494353244845, y:1095.7258939892015, frm:2});
Replay.hist.push({time:16.866799999999945, x:1649.9026873886307, y:1094.9963822902905, frm:2});
Replay.hist.push({time:16.883499999999948, x:1650.301773281787, y:1094.2655184974424, frm:2});
Replay.hist.push({time:16.900099999999945, x:1650.6916923841081, y:1093.5334203716118, frm:2});
Replay.hist.push({time:16.91689999999995, x:1651.079387297913, y:1092.7868254333484, frm:2});
Replay.hist.push({time:16.933499999999945, x:1651.4555888802097, y:1092.0435214945358, frm:2});
Replay.hist.push({time:16.950199999999942, x:1651.82498048778, y:1091.2945323227464, frm:2});
Replay.hist.push({time:16.96679999999994, x:1652.1800993427523, y:1090.5555164289485, frm:2});
Replay.hist.push({time:16.983499999999943, x:1652.530616384969, y:1089.8067152050235, frm:2});
Replay.hist.push({time:17.00019999999994, x:1652.8743350489508, y:1089.052578504707, frm:2});
Replay.hist.push({time:17.01669999999994, x:1653.2072248045802, y:1088.3022459706501, frm:2});
Replay.hist.push({time:17.033499999999943, x:1653.539272233896, y:1087.5329527645708, frm:2});
Replay.hist.push({time:17.04959999999994, x:1653.8509255480863, y:1086.7906818889173, frm:2});
Replay.hist.push({time:17.06579999999994, x:1654.1579978120333, y:1086.038851863442, frm:2});
Replay.hist.push({time:17.083499999999944, x:1654.4806728430663, y:1085.2253654882716, frm:2});
Replay.hist.push({time:17.10019999999994, x:1654.7759065954403, y:1084.4584540026658, frm:2});
Replay.hist.push({time:17.116799999999937, x:1655.0627058723285, y:1083.6912011620143, frm:2});
Replay.hist.push({time:17.13349999999994, x:1655.344491593563, y:1082.914378971714, frm:2});
Replay.hist.push({time:17.150099999999938, x:1655.6178565280002, y:1082.1373023375434, frm:2});
Replay.hist.push({time:17.166799999999935, x:1655.8860599981135, y:1081.350624892139, frm:2});
Replay.hist.push({time:17.18249999999994, x:1656.131949585787, y:1080.6065550074666, frm:2});
Replay.hist.push({time:17.20019999999994, x:1656.401847810732, y:1079.762520403072, frm:2});
Replay.hist.push({time:17.216799999999935, x:1656.6415029861582, y:1078.9870045814619, frm:2});
Replay.hist.push({time:17.23359999999994, x:1656.8771969563693, y:1078.1982085283164, frm:2});
Replay.hist.push({time:17.25009999999994, x:1657.1021622771718, y:1077.4189681184375, frm:2});
Replay.hist.push({time:17.266899999999943, x:1657.3245474217158, y:1076.6209668149777, frm:2});
Replay.hist.push({time:17.282499999999942, x:1657.5249989250956, y:1075.8758121159442, frm:2});
Replay.hist.push({time:17.30019999999994, x:1657.7453384574617, y:1075.025557125277, frm:2});
Replay.hist.push({time:17.316799999999937, x:1657.9451090245107, y:1074.2234919129537, frm:2});
Replay.hist.push({time:17.33299999999994, x:1658.1336189435392, y:1073.4364323985408, frm:2});
Replay.hist.push({time:17.350199999999937, x:1658.3213918061313, y:1072.6200044410182, frm:2});
Replay.hist.push({time:17.36689999999994, x:1658.4967700658872, y:1071.824816886558, frm:2});
Replay.hist.push({time:17.383499999999938, x:1658.66471077964, y:1071.030215370293, frm:2});
Replay.hist.push({time:17.400199999999934, x:1658.8272105824333, y:1070.226640321715, frm:2});
Replay.hist.push({time:17.41679999999993, x:1658.9822978369748, y:1069.4237248063118, frm:2});
Replay.hist.push({time:17.433499999999935, x:1659.1318145380599, y:1068.6118090743414, frm:2});
Replay.hist.push({time:17.45019999999993, x:1659.2747810319377, y:1067.7957280598105, frm:1});
Replay.hist.push({time:17.466799999999928, x:1659.4103743774574, y:1066.9804162719763, frm:1});
Replay.hist.push({time:17.483499999999932, x:1659.5401376364694, y:1066.1731320400638, frm:1});
Replay.hist.push({time:17.50019999999993, x:1659.6741171414953, y:1065.367540207417, frm:1});
Replay.hist.push({time:17.516899999999932, x:1659.8150905752616, y:1064.5544843606026, frm:1});
Replay.hist.push({time:17.53349999999993, x:1659.959727559636, y:1063.755395227806, frm:1});
Replay.hist.push({time:17.550099999999926, x:1660.1102700675858, y:1062.9591965244447, frm:2});
Replay.hist.push({time:17.566799999999922, x:1660.2698527785917, y:1062.1520543398476, frm:2});
Replay.hist.push({time:17.583599999999926, x:1660.436602815425, y:1061.3461594593127, frm:2});
Replay.hist.push({time:17.600199999999923, x:1660.6076235174664, y:1060.5562447245031, frm:2});
Replay.hist.push({time:17.61679999999992, x:1660.7876737615818, y:1059.7613424788203, frm:2});
Replay.hist.push({time:17.632999999999925, x:1660.9712329587796, y:1058.9864002113702, frm:2});
Replay.hist.push({time:17.649199999999922, x:1661.1599934719281, y:1058.2235878876777, frm:2});
Replay.hist.push({time:17.666899999999927, x:1661.3769266684144, y:1057.3857727142474, frm:2});
Replay.hist.push({time:17.683499999999924, x:1661.5909781733956, y:1056.5960793888255, frm:2});
Replay.hist.push({time:17.70019999999992, x:1661.8112224798638, y:1055.818337035296, frm:3});
Replay.hist.push({time:17.716499999999925, x:1662.0361320472202, y:1055.057256564871, frm:3});
Replay.hist.push({time:17.733499999999925, x:1662.2818902504182, y:1054.260441399453, frm:3});
Replay.hist.push({time:17.75019999999992, x:1662.531226766685, y:1053.4858260405667, frm:3});
Replay.hist.push({time:17.766799999999918, x:1662.7870212567293, y:1052.7233447727162, frm:3});
Replay.hist.push({time:17.783499999999922, x:1663.0558632547973, y:1051.954007430443, frm:3});
Replay.hist.push({time:17.80019999999992, x:1663.3366023986005, y:1051.182593022462, frm:3});
Replay.hist.push({time:17.816799999999915, x:1663.6197468723483, y:1050.4347871694395, frm:3});
Replay.hist.push({time:17.83359999999992, x:1663.9182982170125, y:1049.6763509253685, frm:3});
Replay.hist.push({time:17.850199999999916, x:1664.2254740543656, y:1048.9255083669402, frm:3});
Replay.hist.push({time:17.865799999999915, x:1664.522633325472, y:1048.225306129094, frm:3});
Replay.hist.push({time:17.88249999999992, x:1664.8465602298613, y:1047.4889930290667, frm:3});
Replay.hist.push({time:17.900199999999916, x:1665.2034819858686, y:1046.7076373264288, frm:3});
Replay.hist.push({time:17.91689999999992, x:1665.5533890020683, y:1045.9696708801857, frm:3});
Replay.hist.push({time:17.932599999999915, x:1665.8879426941205, y:1045.2880197417414, frm:3});
Replay.hist.push({time:17.950299999999913, x:1666.274079901913, y:1044.5281357137433, frm:3});
Replay.hist.push({time:17.966799999999914, x:1666.6470224319935, y:1043.819513953604, frm:3});
Replay.hist.push({time:17.983199999999915, x:1667.0303751228835, y:1043.1150907947504, frm:3});
Replay.hist.push({time:18.000199999999914, x:1667.4312977875843, y:1042.4025129734287, frm:3});
Replay.hist.push({time:18.016899999999918, x:1667.8349831347273, y:1041.7081854105043, frm:3});
Replay.hist.push({time:18.03329999999991, x:1668.2440741747546, y:1041.0266240453316, frm:3});
Replay.hist.push({time:18.050099999999915, x:1668.676382120502, y:1040.3288733600382, frm:3});
Replay.hist.push({time:18.066799999999912, x:1669.1077518158224, y:1039.6541325258559, frm:3});
Replay.hist.push({time:18.083499999999916, x:1669.5485355196138, y:1038.9853868837959, frm:3});
Replay.hist.push({time:18.100099999999912, x:1669.9995594686006, y:1038.321394404723, frm:3});
Replay.hist.push({time:18.11679999999991, x:1670.4664673138834, y:1037.6542691424088, frm:3});
Replay.hist.push({time:18.133499999999913, x:1670.9354469949371, y:1037.003634341134, frm:3});
Replay.hist.push({time:18.149199999999908, x:1671.3818509463767, y:1036.4013477138103, frm:3});
Replay.hist.push({time:18.166899999999913, x:1671.8986887457186, y:1035.723543355874, frm:3});
Replay.hist.push({time:18.182499999999912, x:1672.3663246792037, y:1035.1272728755605, frm:3});
Replay.hist.push({time:18.20019999999991, x:1672.9041107053977, y:1034.4603581656504, frm:3});
Replay.hist.push({time:18.216799999999907, x:1673.4072256203985, y:1033.853627190096, frm:3});
Replay.hist.push({time:18.23349999999991, x:1673.9258192336324, y:1033.2446442132134, frm:3});
Replay.hist.push({time:18.24989999999991, x:1674.4474074049308, y:1032.6480512508363, frm:3});
Replay.hist.push({time:18.26689999999991, x:1675.0011125788806, y:1032.031244861266, frm:3});
Replay.hist.push({time:18.282799999999906, x:1675.512427688189, y:1031.4759914112387, frm:3});
Replay.hist.push({time:18.300199999999908, x:1676.0801378789954, y:1030.8747911167168, frm:3});
Replay.hist.push({time:18.316799999999905, x:1676.6340577006144, y:1030.3029354860817, frm:3});
Replay.hist.push({time:18.33349999999991, x:1677.203578932772, y:1029.7293963557738, frm:3});
Replay.hist.push({time:18.350199999999905, x:1677.7823007465618, y:1029.1608424637643, frm:3});
Replay.hist.push({time:18.36689999999991, x:1678.3495895416372, y:1028.61679934592, frm:3});
Replay.hist.push({time:18.383499999999906, x:1678.924952163427, y:1028.077819018054, frm:3});
Replay.hist.push({time:18.399399999999908, x:1679.4868890400758, y:1027.5633085857946, frm:3});
Replay.hist.push({time:18.416899999999906, x:1680.1177340173958, y:1026.9990797379792, frm:3});
Replay.hist.push({time:18.433499999999903, x:1680.7229594508312, y:1026.4704713712692, frm:3});
Replay.hist.push({time:18.4501999999999, x:1681.3188197621228, y:1025.9616634940458, frm:3});
Replay.hist.push({time:18.466799999999896, x:1681.9220226874604, y:1025.4578349422825, frm:3});
Replay.hist.push({time:18.482499999999902, x:1682.502627234257, y:1024.9831386782369, frm:3});
Replay.hist.push({time:18.5000999999999, x:1683.1652535081685, y:1024.4531728839622, frm:2});
Replay.hist.push({time:18.516799999999897, x:1683.8056145385362, y:1023.9524714741441, frm:2});
Replay.hist.push({time:18.5334999999999, x:1684.4252440024402, y:1023.4782666205435, frm:2});
Replay.hist.push({time:18.549799999999895, x:1685.0392209580696, y:1023.0179830868374, frm:2});
Replay.hist.push({time:18.5668999999999, x:1685.6940194988265, y:1022.5372239830278, frm:2});
Replay.hist.push({time:18.583499999999898, x:1686.3402359548836, y:1022.0726311749896, frm:2});
Replay.hist.push({time:18.600199999999894, x:1687.000925151357, y:1021.6073861971561, frm:2});
Replay.hist.push({time:18.61679999999989, x:1687.6512167780295, y:1021.158741395287, frm:2});
Replay.hist.push({time:18.633499999999895, x:1688.2971772561286, y:1020.7218732155316, frm:2});
Replay.hist.push({time:18.65019999999989, x:1688.9528717339272, y:1020.2870688931084, frm:2});
Replay.hist.push({time:18.665999999999894, x:1689.5822615615884, y:1019.8776266965492, frm:2});
Replay.hist.push({time:18.683499999999892, x:1690.2896665427784, y:1019.4263773960321, frm:2});
Replay.hist.push({time:18.699199999999887, x:1690.9336139365178, y:1019.0235666624376, frm:2});
Replay.hist.push({time:18.716799999999886, x:1691.6405920372486, y:1018.5897706731465, frm:2});
Replay.hist.push({time:18.73309999999989, x:1692.291188807484, y:1018.1981278078106, frm:2});
Replay.hist.push({time:18.75019999999989, x:1692.9829842384345, y:1017.789376445823, frm:2});
Replay.hist.push({time:18.766899999999893, x:1693.667825679881, y:1017.3923003523141, frm:2});
Replay.hist.push({time:18.78349999999989, x:1694.3576574185709, y:1016.9997054263622, frm:2});
Replay.hist.push({time:18.800199999999887, x:1695.0608448947596, y:1016.6068942693003, frm:2});
Replay.hist.push({time:18.81689999999989, x:1695.752634932731, y:1016.2275062718389, frm:2});
Replay.hist.push({time:18.833499999999887, x:1696.429988993401, y:1015.8626173699787, frm:2});
Replay.hist.push({time:18.849499999999885, x:1697.0906880043515, y:1015.51279797254, frm:2});
Replay.hist.push({time:18.866899999999887, x:1697.8179467582324, y:1015.1345031854356, frm:2});
Replay.hist.push({time:18.883499999999884, x:1698.5203333389643, y:1014.7756883625221, frm:2});
Replay.hist.push({time:18.90019999999988, x:1699.2354278921316, y:1014.4167993959265, frm:2});
Replay.hist.push({time:18.916899999999885, x:1699.9590713654059, y:1014.0600295175448, frm:2});
Replay.hist.push({time:18.93259999999988, x:1700.6393881801382, y:1013.7230819928725, frm:2});
Replay.hist.push({time:18.950199999999878, x:1701.3925318065776, y:1013.3388453997838, frm:2});
Replay.hist.push({time:18.96689999999988, x:1702.0979560680332, y:1012.9679434280831, frm:2});
Replay.hist.push({time:18.98359999999988, x:1702.794457108701, y:1012.5909654637927, frm:2});
Replay.hist.push({time:19.000199999999875, x:1703.4779943393885, y:1012.21028828638, frm:2});
Replay.hist.push({time:19.01589999999988, x:1704.116457046856, y:1011.8448402409798, frm:2});
Replay.hist.push({time:19.033199999999876, x:1704.8109870522608, y:1011.4361401237697, frm:2});
Replay.hist.push({time:19.050199999999876, x:1705.4843530021403, y:1011.0284472644452, frm:3});
Replay.hist.push({time:19.065899999999882, x:1706.0982504650774, y:1010.6466322718866, frm:3});
Replay.hist.push({time:19.08249999999988, x:1706.747528506927, y:1010.2319730829416, frm:3});
Replay.hist.push({time:19.100199999999877, x:1707.4543477847774, y:1009.7674852823402, frm:3});
Replay.hist.push({time:19.115899999999883, x:1708.07250308619, y:1009.3497133747069, frm:3});
Replay.hist.push({time:19.13249999999988, x:1708.7171250004253, y:1008.9021838710839, frm:3});
Replay.hist.push({time:19.150199999999877, x:1709.394366615457, y:1008.4185035110257, frm:3});
Replay.hist.push({time:19.166799999999874, x:1710.0201287827558, y:1007.9588531505375, frm:3});
Replay.hist.push({time:19.183499999999878, x:1710.6405337102651, y:1007.4906278631703, frm:3});
Replay.hist.push({time:19.200199999999874, x:1711.2518403076838, y:1007.0166521250369, frm:3});
Replay.hist.push({time:19.216899999999878, x:1711.8541036009642, y:1006.5369994931966, frm:3});
Replay.hist.push({time:19.233599999999875, x:1712.4493982580118, y:1006.0500691421223, frm:3});
Replay.hist.push({time:19.249199999999874, x:1713.015468079289, y:1005.5747829432999, frm:3});
Replay.hist.push({time:19.26689999999988, x:1713.6476459448054, y:1005.0293245183619, frm:3});
Replay.hist.push({time:19.282499999999878, x:1714.1960124429268, y:1004.5431686936405, frm:3});
Replay.hist.push({time:19.300199999999876, x:1714.8082312609977, y:1003.9855478353821, frm:3});
Replay.hist.push({time:19.316599999999877, x:1715.3661171155215, y:1003.4632234180693, frm:3});
Replay.hist.push({time:19.333499999999876, x:1715.9316312503045, y:1002.9193761183436, frm:3});
Replay.hist.push({time:19.350199999999873, x:1716.4811657119176, y:1002.376457703327, frm:3});
Replay.hist.push({time:19.366499999999878, x:1717.0086934679625, y:1001.841338970593, frm:3});
Replay.hist.push({time:19.382599999999876, x:1717.5222629694763, y:1001.3067183824255, frm:3});
Replay.hist.push({time:19.400199999999874, x:1718.0862784373073, y:1000.7033888834001, frm:3});
Replay.hist.push({time:19.41589999999988, x:1718.580573446716, y:1000.16009673053, frm:3});
Replay.hist.push({time:19.432699999999876, x:1719.1003151158845, y:999.5735266100098, frm:3});
Replay.hist.push({time:19.450199999999874, x:1719.6316861993935, y:998.956871961728, frm:3});
Replay.hist.push({time:19.46679999999987, x:1720.1263507383646, y:998.3666814401927, frm:3});
Replay.hist.push({time:19.483299999999872, x:1720.6090425397763, y:997.7750709347353, frm:3});
Replay.hist.push({time:19.499199999999874, x:1721.0657591082925, y:997.200351853686, frm:3});
Replay.hist.push({time:19.516799999999872, x:1721.5617158975108, y:996.5590051904743, frm:3});
Replay.hist.push({time:19.533499999999876, x:1722.0252977023345, y:995.9424890536313, frm:3});
Replay.hist.push({time:19.550299999999872, x:1722.4864930753156, y:995.3119813103485, frm:3});
Replay.hist.push({time:19.56589999999988, x:1722.9064939354348, y:994.722152975749, frm:3});
Replay.hist.push({time:19.583399999999877, x:1723.3682365125198, y:994.0556128326452, frm:3});
Replay.hist.push({time:19.600199999999873, x:1723.8022295123187, y:993.4109490322918, frm:3});
Replay.hist.push({time:19.615799999999872, x:1724.1971437635466, y:992.8082075048015, frm:3});
Replay.hist.push({time:19.633599999999873, x:1724.638293265121, y:992.1157377252472, frm:3});
Replay.hist.push({time:19.650099999999874, x:1725.038306473294, y:991.4693947534969, frm:3});
Replay.hist.push({time:19.66579999999987, x:1725.411014020789, y:990.8504977160829, frm:3});
Replay.hist.push({time:19.68359999999987, x:1725.825503821677, y:990.1422461328416, frm:3});
Replay.hist.push({time:19.700299999999867, x:1726.2061071504925, y:989.4722869091062, frm:3});
Replay.hist.push({time:19.716899999999875, x:1726.575886381703, y:988.8023041848011, frm:3});
Replay.hist.push({time:19.73359999999987, x:1726.9393588138114, y:988.1243124112107, frm:3});
Replay.hist.push({time:19.749299999999867, x:1727.27332186422, y:987.4833499565186, frm:3});
Replay.hist.push({time:19.766799999999865, x:1727.6367782573668, y:986.7649425446947, frm:3});
Replay.hist.push({time:19.78359999999987, x:1727.977051486664, y:986.0714059112308, frm:3});
Replay.hist.push({time:19.800199999999865, x:1728.305016965348, y:985.3824960508686, frm:3});
Replay.hist.push({time:19.81689999999987, x:1728.6267374820438, y:984.6858821893438, frm:3});
Replay.hist.push({time:19.833599999999866, x:1728.939757260057, y:983.9869616037256, frm:3});
Replay.hist.push({time:19.850299999999862, x:1729.2446708931623, y:983.2846844447349, frm:3});
Replay.hist.push({time:19.86689999999987, x:1729.53980058155, y:982.583336832574, frm:3});
Replay.hist.push({time:19.883499999999867, x:1729.8270564549466, y:981.8788055153849, frm:3});
Replay.hist.push({time:19.899199999999862, x:1730.0915513961581, y:981.2096062022631, frm:3});
Replay.hist.push({time:19.916899999999867, x:1730.3814140052452, y:980.4519376979733, frm:3});
Replay.hist.push({time:19.932499999999866, x:1730.6296414134401, y:979.7813656733507, frm:2});
Replay.hist.push({time:19.95029999999986, x:1730.9046324581675, y:979.0131630846101, frm:2});
Replay.hist.push({time:19.966899999999868, x:1731.1529164083788, y:978.294816059421, frm:2});
Replay.hist.push({time:19.983499999999864, x:1731.3926563880707, y:977.5769831098415, frm:2});
Replay.hist.push({time:20.00029999999986, x:1731.6277838783622, y:976.8478674275152, frm:2});
Replay.hist.push({time:20.01689999999987, x:1731.8527611420511, y:976.1249032264135, frm:2});
Replay.hist.push({time:20.033099999999866, x:1732.065328854354, y:975.4170089829482, frm:2});
Replay.hist.push({time:20.049199999999864, x:1732.2697980083049, y:974.7112584764201, frm:2});
Replay.hist.push({time:20.066799999999862, x:1732.4864437807003, y:973.9343559014278, frm:2});
Replay.hist.push({time:20.082499999999868, x:1732.6735649912664, y:973.2368342290048, frm:2});
Replay.hist.push({time:20.099199999999865, x:1732.8662895311868, y:972.4900975326165, frm:2});
Replay.hist.push({time:20.11589999999987, x:1733.0519551103428, y:971.7406667304884, frm:2});
Replay.hist.push({time:20.133599999999866, x:1733.2407322616805, y:970.9450108147115, frm:2});
Replay.hist.push({time:20.149199999999865, x:1733.401050940185, y:970.2395751702979, frm:2});
Replay.hist.push({time:20.16589999999987, x:1733.566416096856, y:969.4799012331505, frm:2});
Replay.hist.push({time:20.182599999999866, x:1733.7252785394633, y:968.7157169425143, frm:2});
Replay.hist.push({time:20.199199999999863, x:1733.8767312086343, y:967.9517084971521, frm:2});
Replay.hist.push({time:20.21679999999986, x:1734.03027665759, y:967.1368755807757, frm:2});
Replay.hist.push({time:20.233499999999864, x:1734.1692432389698, y:966.3593427315307, frm:2});
Replay.hist.push({time:20.25029999999986, x:1734.3024348894767, y:965.5728651034639, frm:2});
Replay.hist.push({time:20.26689999999987, x:1734.4268140518666, y:964.7961491007732, frm:2});
Replay.hist.push({time:20.283599999999865, x:1734.5452569267898, y:964.012171869679, frm:2});
Replay.hist.push({time:20.299699999999863, x:1734.6533122331427, y:963.2526242548772, frm:2});
Replay.hist.push({time:20.31689999999987, x:1734.7621090127702, y:962.4370821445591, frm:2});
Replay.hist.push({time:20.333499999999866, x:1734.8605830357074, y:961.6461628238786, frm:2});
Replay.hist.push({time:20.350199999999862, x:1734.953182037805, y:960.8467144635827, frm:2});
Replay.hist.push({time:20.366899999999866, x:1735.0392886062223, y:960.0435732285453, frm:2});
Replay.hist.push({time:20.38329999999986, x:1735.1175250438573, y:959.2513609149856, frm:2});
Replay.hist.push({time:20.399199999999862, x:1735.1873196712181, y:958.4809035738685, frm:2});
Replay.hist.push({time:20.41609999999986, x:1735.254735931149, y:957.6627095942438, frm:2});
Replay.hist.push({time:20.43359999999986, x:1735.3176220983191, y:956.811809107695, frm:2});
Replay.hist.push({time:20.450199999999857, x:1735.3707571700286, y:956.001414373481, frm:2});
Replay.hist.push({time:20.46689999999986, x:1735.4178163399297, y:955.1829581410826, frm:2});
Replay.hist.push({time:20.482599999999856, x:1735.456205840802, y:954.4107479303386, frm:2});
Replay.hist.push({time:20.49979999999985, x:1735.4917648947255, y:953.5615975072408, frm:2});
Replay.hist.push({time:20.51639999999986, x:1735.5196378716864, y:952.7391635458914, frm:2});
Replay.hist.push({time:20.533499999999858, x:1735.5417383164342, y:951.8889915599575, frm:2});
Replay.hist.push({time:20.550199999999855, x:1735.5568144640913, y:951.0584270077893, frm:2});
Replay.hist.push({time:20.56689999999986, x:1735.5655379811374, y:950.22567093697, frm:2});
Replay.hist.push({time:20.583499999999855, x:1735.5679311574265, y:949.3953797492492, frm:2});
Replay.hist.push({time:20.60019999999985, x:1735.564031583525, y:948.5576299997699, frm:2});
Replay.hist.push({time:20.616399999999857, x:1735.5542135629883, y:947.7427364247637, frm:2});
Replay.hist.push({time:20.63279999999985, x:1735.5382300676722, y:946.915595265345, frm:2});
Replay.hist.push({time:20.65029999999985, x:1735.5144778062252, y:946.0305909903149, frm:2});
Replay.hist.push({time:20.666899999999856, x:1735.4855736046097, y:945.1890598980519, frm:2});
Replay.hist.push({time:20.683599999999853, x:1735.4502420884717, y:944.3404063844653, frm:2});
Replay.hist.push({time:20.700099999999853, x:1735.4091890293573, y:943.5000474444206, frm:2});
Replay.hist.push({time:20.716899999999857, x:1735.3611301631167, y:942.6426228201922, frm:1});
Replay.hist.push({time:20.733599999999853, x:1735.307115779926, y:941.7886474307471, frm:1});
Replay.hist.push({time:20.75019999999985, x:1735.2472752089154, y:940.9382570178007, frm:1});
Replay.hist.push({time:20.766799999999847, x:1735.1813213483372, y:940.0864429222662, frm:1});
Replay.hist.push({time:20.78349999999985, x:1735.1088203754105, y:939.2281636706643, frm:1});
Replay.hist.push({time:20.800199999999847, x:1735.0301721180008, y:938.3686644794248, frm:1});
Replay.hist.push({time:20.81689999999985, x:1734.9451628759139, y:937.5057521225931, frm:1});
Replay.hist.push({time:20.833599999999848, x:1734.853833009833, y:936.6401471799636, frm:1});
Replay.hist.push({time:20.850299999999844, x:1734.756368238411, y:935.7736684786456, frm:1});
Replay.hist.push({time:20.866899999999852, x:1734.6534328020498, y:934.9116364892336, frm:1});
Replay.hist.push({time:20.88359999999985, x:1734.5438110150076, y:934.0437726016135, frm:1});
Replay.hist.push({time:20.900299999999845, x:1734.428132623575, y:933.1753962010212, frm:1});
Replay.hist.push({time:20.916899999999853, x:1734.307524291197, y:932.3142859796867, frm:1});
Replay.hist.push({time:20.93359999999985, x:1734.1806096991809, y:931.4501732900652, frm:1});
Replay.hist.push({time:20.949299999999845, x:1734.0553870524627, y:930.634327583512, frm:1});
Replay.hist.push({time:20.96689999999985, x:1733.9087649821522, y:929.7195857307995, frm:1});
Replay.hist.push({time:20.983499999999847, x:1733.7648989936006, y:928.8593989406957, frm:1});
Replay.hist.push({time:21.000299999999843, x:1733.6138721342822, y:927.9915219177196, frm:1});
Replay.hist.push({time:21.016999999999847, x:1733.4584106605598, y:927.1315794650052, frm:1});
Replay.hist.push({time:21.032599999999846, x:1733.3084523830382, y:926.3308568898328, frm:1});
Replay.hist.push({time:21.049299999999842, x:1733.1429275014902, y:925.476556217443, frm:1});
Replay.hist.push({time:21.06679999999984, x:1732.9640158840257, y:924.5846379124182, frm:1});
Replay.hist.push({time:21.083599999999844, x:1732.7844575949039, y:923.719214691095, frm:1});
Replay.hist.push({time:21.10019999999984, x:1732.6017738605465, y:922.866454294893, frm:1});
Replay.hist.push({time:21.116899999999845, x:1732.412997458711, y:922.0120442884346, frm:1});
Replay.hist.push({time:21.13359999999984, x:1732.2192939427464, y:921.1612402152768, frm:1});
Replay.hist.push({time:21.149499999999843, x:1732.0303622985118, y:920.3546354669315, frm:1});
Replay.hist.push({time:21.166899999999846, x:1731.8186577526494, y:919.4759231481155, frm:1});
Replay.hist.push({time:21.183599999999842, x:1731.610681393698, y:918.6365658760362, frm:1});
Replay.hist.push({time:21.20019999999984, x:1731.398207917347, y:917.8016773729013, frm:1});
Replay.hist.push({time:21.21599999999984, x:1731.1882480548638, y:916.9975418870337, frm:1});
Replay.hist.push({time:21.23359999999984, x:1730.9494701749882, y:916.1064968926644, frm:1});
Replay.hist.push({time:21.250199999999836, x:1730.719605764123, y:915.2707046915311, frm:1});
Replay.hist.push({time:21.26689999999984, x:1730.4838867701037, y:914.4345360659947, frm:1});
Replay.hist.push({time:21.283199999999834, x:1730.2495688924755, y:913.6230050252392, frm:1});
Replay.hist.push({time:21.300299999999833, x:1729.999333275482, y:912.7766686380203, frm:1});
Replay.hist.push({time:21.31689999999984, x:1729.7521671657987, y:911.9600997545093, frm:1});
Replay.hist.push({time:21.33339999999984, x:1729.497132492285, y:911.1365612647317, frm:1});
Replay.hist.push({time:21.350199999999838, x:1729.2308891617504, y:910.2962853164568, frm:1});
Replay.hist.push({time:21.36689999999984, x:1728.9619922225797, y:909.4666969209259, frm:1});
Replay.hist.push({time:21.383599999999838, x:1728.6889602778224, y:908.6429016652805, frm:1});
Replay.hist.push({time:21.400199999999835, x:1728.4135539518034, y:907.8299006433131, frm:1});
Replay.hist.push({time:21.41689999999984, x:1728.1325451372618, y:907.018022387273, frm:1});
Replay.hist.push({time:21.432599999999834, x:1727.8648321059322, y:906.2603642642149, frm:1});
Replay.hist.push({time:21.450199999999832, x:1727.5567793146108, y:905.406728674771, frm:1});
Replay.hist.push({time:21.466899999999836, x:1727.2531440080536, y:904.58350541293, frm:1});
Replay.hist.push({time:21.483599999999832, x:1726.9456279968963, y:903.7673060124761, frm:1});
Replay.hist.push({time:21.49969999999983, x:1726.645576627025, y:902.9872013419746, frm:1});
Replay.hist.push({time:21.51669999999983, x:1726.325038752443, y:902.1708538758929, frm:1});
Replay.hist.push({time:21.533499999999833, x:1726.0046198176708, y:901.3716676661486, frm:1});
Replay.hist.push({time:21.55029999999983, x:1725.6806716612246, y:900.5801330794143, frm:1});
Replay.hist.push({time:21.566299999999828, x:1725.3654112565416, y:899.8251124032286, frm:1});
Replay.hist.push({time:21.583499999999834, x:1725.0106728965304, y:898.9929048630269, frm:1});
Replay.hist.push({time:21.60019999999983, x:1724.6626829636562, y:898.1937432477058, frm:1});
Replay.hist.push({time:21.616799999999827, x:1724.3134103653383, y:897.4081691405912, frm:1});
Replay.hist.push({time:21.63349999999983, x:1723.9587587192707, y:896.6268716965344, frm:1});
Replay.hist.push({time:21.650299999999827, x:1723.5987810902834, y:895.85016671534, frm:1});
Replay.hist.push({time:21.66699999999983, x:1723.2378733129246, y:895.0874511197029, frm:1});
Replay.hist.push({time:21.68349999999983, x:1722.891781810083, y:894.3695434362146, frm:1});
Replay.hist.push({time:21.700299999999828, x:1722.5442817003639, y:893.6533998602288, frm:1});
Replay.hist.push({time:21.716899999999836, x:1722.1879319049713, y:892.9218274914687, frm:1});
Replay.hist.push({time:21.733599999999832, x:1721.81651979709, y:892.1620405968683, frm:0});
Replay.hist.push({time:21.75019999999983, x:1721.434599804495, y:891.3833499013014, frm:0});
Replay.hist.push({time:21.765899999999835, x:1721.0617987189348, y:890.6255452526971, frm:0});
Replay.hist.push({time:21.783599999999833, x:1720.6281295899153, y:889.7465978457493, frm:0});
Replay.hist.push({time:21.80019999999983, x:1720.2541286541934, y:888.9906240138796, frm:0});
Replay.hist.push({time:21.81659999999983, x:1719.9042588644193, y:888.2850004412018, frm:0});
Replay.hist.push({time:21.83359999999983, x:1719.533075701466, y:887.5379361007149, frm:0});
Replay.hist.push({time:21.850199999999827, x:1719.1623208290312, y:886.7932142585124, frm:0});
Replay.hist.push({time:21.86599999999983, x:1718.801864873059, y:886.0705064690651, frm:0});
Replay.hist.push({time:21.883599999999827, x:1718.3917323828116, y:885.2496884665719, frm:0});
Replay.hist.push({time:21.900299999999824, x:1717.9942302227369, y:884.4555687397497, frm:0});
Replay.hist.push({time:21.916999999999828, x:1717.5886718578786, y:883.6467079211009, frm:0});
Replay.hist.push({time:21.932599999999827, x:1717.2150903365894, y:882.9027642762845, frm:0});
Replay.hist.push({time:21.950299999999825, x:1716.8329570402964, y:882.1428629315385, frm:0});
Replay.hist.push({time:21.966899999999832, x:1716.468669934147, y:881.4194099695734, frm:0});
Replay.hist.push({time:21.98359999999983, x:1716.0964695507753, y:880.6811637311189, frm:0});
Replay.hist.push({time:22.000299999999825, x:1715.7185722568752, y:879.9325282686683, frm:0});
Replay.hist.push({time:22.016899999999833, x:1715.337330549726, y:879.1781558743126, frm:0});
Replay.hist.push({time:22.03359999999983, x:1714.9481914119497, y:878.4090373692218, frm:0});
Replay.hist.push({time:22.04919999999983, x:1714.5796382026315, y:877.6813927669808, frm:0});
Replay.hist.push({time:22.066899999999833, x:1714.1556291424881, y:876.845167852229, frm:0});
Replay.hist.push({time:22.08349999999983, x:1713.7523491584789, y:876.050689514838, frm:0});
Replay.hist.push({time:22.100199999999827, x:1713.3657547658763, y:875.2898430478994, frm:0});
Replay.hist.push({time:22.11689999999983, x:1712.999097488468, y:874.5688972896147, frm:0});
Replay.hist.push({time:22.133599999999827, x:1712.6282708504698, y:873.8403875838172, frm:0});
Replay.hist.push({time:22.14939999999983, x:1712.2736108809365, y:873.1442156675364, frm:0});
Replay.hist.push({time:22.166199999999826, x:1711.892467870699, y:872.3966690132279, frm:0});
Replay.hist.push({time:22.18299999999983, x:1711.5071867748952, y:871.641628144961, frm:0});
Replay.hist.push({time:22.200299999999825, x:1711.1061461596753, y:870.8563465150653, frm:0});
Replay.hist.push({time:22.216899999999832, x:1710.7172590764073, y:870.0954704404132, frm:0});
Replay.hist.push({time:22.23359999999983, x:1710.3220350076551, y:869.322790378645, frm:0});
Replay.hist.push({time:22.250199999999825, x:1709.925232700022, y:868.5476103761881, frm:0});
Replay.hist.push({time:22.26689999999983, x:1709.5220992088957, y:867.7606457683464, frm:0});
Replay.hist.push({time:22.283599999999826, x:1709.130502299092, y:866.9967498164015, frm:0});
Replay.hist.push({time:22.300299999999822, x:1708.7610699610875, y:866.2765727275347, frm:0});
Replay.hist.push({time:22.31689999999983, x:1708.3907561082933, y:865.5551351921503, frm:0});
Replay.hist.push({time:22.333199999999824, x:1708.0241512671678, y:864.8413644815545, frm:0});
Replay.hist.push({time:22.350199999999823, x:1707.6386785087807, y:864.0913205904227, frm:0});
Replay.hist.push({time:22.36589999999983, x:1707.2798580399528, y:863.3935522889957, frm:0});
Replay.hist.push({time:22.383599999999827, x:1706.8721140716248, y:862.601122516558, frm:0});
Replay.hist.push({time:22.399999999999828, x:1706.4912797785144, y:861.8614375558767, frm:0});
Replay.hist.push({time:22.416899999999828, x:1706.0958073592108, y:861.0937683281669, frm:0});
Replay.hist.push({time:22.433599999999824, x:1705.702012602943, y:860.3297985111662, frm:0});
Replay.hist.push({time:22.45019999999982, x:1705.3076379445206, y:859.5651371518584, frm:0});
Replay.hist.push({time:22.466899999999825, x:1704.9079523528626, y:858.790611734742, frm:0});
Replay.hist.push({time:22.48359999999982, x:1704.5053416435064, y:858.0108500487489, frm:0});
Replay.hist.push({time:22.49919999999982, x:1704.1572993233103, y:857.3371172439621, frm:0});
Replay.hist.push({time:22.516899999999826, x:1703.7620885216709, y:856.5724549744504, frm:0});
Replay.hist.push({time:22.53279999999982, x:1703.404776567666, y:855.8814603938337, frm:0});
Replay.hist.push({time:22.550199999999823, x:1703.0113032754534, y:855.1209016074405, frm:0});
Replay.hist.push({time:22.566899999999826, x:1702.6312527065377, y:854.3866471120608, frm:0});
Replay.hist.push({time:22.583599999999823, x:1702.2488635441773, y:853.6482244032475, frm:0});
Replay.hist.push({time:22.60029999999982, x:1701.864150716743, y:852.9056628116807, frm:0});
Replay.hist.push({time:22.616899999999827, x:1701.4794529324488, y:852.1634738501365, frm:0});
Replay.hist.push({time:22.632499999999826, x:1701.1158585870724, y:851.4623101286393, frm:0});
Replay.hist.push({time:22.64979999999982, x:1700.7103249091385, y:850.6806203665029, frm:0});
Replay.hist.push({time:22.666599999999825, x:1700.3141829250706, y:849.9173851928517, frm:0});
Replay.hist.push({time:22.683599999999824, x:1699.9110083272376, y:849.1409516087056, frm:0});
Replay.hist.push({time:22.70029999999982, x:1699.5126918425133, y:848.3742162824617, frm:0});
Replay.hist.push({time:22.71689999999983, x:1699.1222649007389, y:847.6229933021408, frm:0});
Replay.hist.push({time:22.732699999999824, x:1698.7696725347023, y:846.9448410664957, frm:0});
Replay.hist.push({time:22.749599999999823, x:1698.3906887522296, y:846.2162143819528, frm:0});
Replay.hist.push({time:22.766899999999826, x:1698.0007694568951, y:845.4668674199762, frm:0});
Replay.hist.push({time:22.783599999999822, x:1697.6224942017418, y:844.7401888361414, frm:0});
Replay.hist.push({time:22.80019999999982, x:1697.244668829286, y:844.0146567180487, frm:0});
Replay.hist.push({time:22.816899999999823, x:1696.8627539216484, y:843.2815545606412, frm:0});
Replay.hist.push({time:22.83299999999982, x:1696.492845821827, y:842.5717679256667, frm:0});
Replay.hist.push({time:22.84919999999982, x:1696.1189553150177, y:841.8546044789558, frm:0});
Replay.hist.push({time:22.866899999999823, x:1695.7085336328532, y:841.0676728766945, frm:0});
Replay.hist.push({time:22.88259999999982, x:1695.3428101148581, y:840.3667081023675, frm:0});
Replay.hist.push({time:22.899299999999815, x:1694.9520883584426, y:839.6181018025671, frm:0});
Replay.hist.push({time:22.91689999999982, x:1694.5384205584364, y:838.8258338751913, frm:0});
Replay.hist.push({time:22.933599999999817, x:1694.1441160462277, y:838.0709368499162, frm:0});
Replay.hist.push({time:22.949299999999813, x:1693.7718424761883, y:837.358471255908, frm:0});
Replay.hist.push({time:22.96589999999982, x:1693.3848777993655, y:836.6181472881437, frm:0});
Replay.hist.push({time:22.983599999999818, x:1692.9889564627142, y:835.8609569338845, frm:0});
Replay.hist.push({time:22.999199999999817, x:1692.6386283103618, y:835.1911871446916, frm:0});
Replay.hist.push({time:23.01649999999982, x:1692.2486406577375, y:834.4458405318717, frm:0});
Replay.hist.push({time:23.03359999999982, x:1691.8616287029295, y:833.7064344100501, frm:0});
Replay.hist.push({time:23.050299999999815, x:1691.4822074716276, y:832.9817733172764, frm:0});
Replay.hist.push({time:23.06699999999982, x:1691.101352904557, y:832.2546138885054, frm:0});
Replay.hist.push({time:23.083599999999816, x:1690.721367196585, y:831.5293499643531, frm:0});
Replay.hist.push({time:23.099599999999814, x:1690.3537899214573, y:830.8279923732607, frm:0});
Replay.hist.push({time:23.116999999999816, x:1689.9525930708603, y:830.0627346386576, frm:0});
Replay.hist.push({time:23.132899999999818, x:1689.5846492664095, y:829.3611304015591, frm:0});
Replay.hist.push({time:23.149699999999815, x:1689.1945173514696, y:828.6174508082648, frm:0});
Replay.hist.push({time:23.16689999999982, x:1688.7936544843753, y:827.853563735186, frm:0});
Replay.hist.push({time:23.183599999999817, x:1688.403053262746, y:827.1094711525003, frm:0});
Replay.hist.push({time:23.200199999999814, x:1688.0134437605282, y:826.3675015925629, frm:0});
Replay.hist.push({time:23.216899999999818, x:1687.6201421626313, y:825.6187356965456, frm:0});
Replay.hist.push({time:23.23329999999981, x:1687.2404621433343, y:824.8961245444589, frm:0});
Replay.hist.push({time:23.25029999999981, x:1686.8600779683604, y:824.1723902437943, frm:0});
Replay.hist.push({time:23.266999999999815, x:1686.4852256775016, y:823.459392001089, frm:0});
Replay.hist.push({time:23.28359999999981, x:1686.1114670022077, y:822.7486807568663, frm:0});
Replay.hist.push({time:23.300299999999808, x:1685.734307615132, y:822.0317108267016, frm:0});
Replay.hist.push({time:23.316899999999816, x:1685.3582710299468, y:821.3170816775112, frm:0});
Replay.hist.push({time:23.333599999999812, x:1684.978835447585, y:820.5962003651962, frm:0});
Replay.hist.push({time:23.35029999999981, x:1684.5982699420283, y:819.8733801093879, frm:0});
Replay.hist.push({time:23.366899999999816, x:1684.2188705866017, y:819.1529804554249, frm:0});
Replay.hist.push({time:23.383599999999813, x:1683.836075026361, y:818.4263388509839, frm:0});
Replay.hist.push({time:23.40029999999981, x:1683.4521726052328, y:817.6978033002621, frm:0});
Replay.hist.push({time:23.416999999999813, x:1683.0671709916621, y:816.9673887635186, frm:0});
Replay.hist.push({time:23.43359999999981, x:1682.683392472032, y:816.2394995750853, frm:0});
Replay.hist.push({time:23.450299999999807, x:1682.296221876272, y:815.505382835164, frm:0});
Replay.hist.push({time:23.46699999999981, x:1681.907974944884, y:814.7694316949708, frm:0});
Replay.hist.push({time:23.483599999999807, x:1681.5209931364734, y:814.0360831319072, frm:0});
Replay.hist.push({time:23.500299999999804, x:1681.1306226465772, y:813.2965183865522, frm:0});
Replay.hist.push({time:23.51589999999981, x:1680.7691584816457, y:812.6119002309179, frm:0});
Replay.hist.push({time:23.533599999999808, x:1680.3736148968537, y:811.8629356077237, frm:0});
Replay.hist.push({time:23.550199999999805, x:1680.001696548511, y:811.1588955200106, frm:0});
Replay.hist.push({time:23.56689999999981, x:1679.6266161524975, y:810.4490551270098, frm:0});
Replay.hist.push({time:23.582599999999804, x:1679.2731530995493, y:809.7802951266221, frm:0});
Replay.hist.push({time:23.6002999999998, x:1678.8737074492387, y:809.024731952224, frm:0});
Replay.hist.push({time:23.61689999999981, x:1678.4981560510669, y:808.3145550332029, frm:0});
Replay.hist.push({time:23.633699999999806, x:1678.1159309015047, y:807.5919455419332, frm:0});
Replay.hist.push({time:23.650299999999802, x:1677.7361435111948, y:806.8741312555799, frm:0});
Replay.hist.push({time:23.66689999999981, x:1677.3542484934721, y:806.1525200064731, frm:0});
Replay.hist.push({time:23.683599999999807, x:1676.9679215824335, y:805.4227238317251, frm:0});
Replay.hist.push({time:23.700299999999803, x:1676.579458734011, y:804.6890838356094, frm:0});
Replay.hist.push({time:23.71689999999981, x:1676.1912070842004, y:803.9560332384178, frm:0});
Replay.hist.push({time:23.732799999999806, x:1675.8173674781365, y:803.2503729015854, frm:0});
Replay.hist.push({time:23.750299999999804, x:1675.403613738873, y:802.4695745115808, frm:0});
Replay.hist.push({time:23.76689999999981, x:1675.0089946641735, y:801.7250840180856, frm:0});
Replay.hist.push({time:23.783599999999808, x:1674.6098600604582, y:800.9722709100006, frm:0});
Replay.hist.push({time:23.799299999999803, x:1674.2327017278974, y:800.2610882349882, frm:0});
Replay.hist.push({time:23.81699999999981, x:1673.812014061748, y:799.4680313678402, frm:0});
Replay.hist.push({time:23.833299999999802, x:1673.4330745864854, y:798.7538612071415, frm:0});
Replay.hist.push({time:23.849399999999807, x:1673.056911529128, y:798.0450966422372, frm:0});
Replay.hist.push({time:23.866499999999807, x:1672.6553075781173, y:797.288585938074, frm:0});
Replay.hist.push({time:23.883599999999806, x:1672.2515957713908, y:796.528301008383, frm:0});
Replay.hist.push({time:23.900199999999803, x:1671.8576867548925, y:795.7866660636544, frm:0});
Replay.hist.push({time:23.916999999999806, x:1671.4570024822044, y:795.0324657154222, frm:0});
Replay.hist.push({time:23.933599999999803, x:1671.059094842733, y:794.2836814086639, frm:0});
Replay.hist.push({time:23.9502999999998, x:1670.656781426092, y:793.5267976716285, frm:0});
Replay.hist.push({time:23.966499999999805, x:1670.264605671642, y:792.7891707268323, frm:0});
Replay.hist.push({time:23.982999999999805, x:1669.8632122287156, y:792.034394550345, frm:0});
Replay.hist.push({time:24.0002999999998, x:1669.4402228746703, y:791.2392146079478, frm:0});
Replay.hist.push({time:24.016999999999804, x:1669.0298739255509, y:790.4679976876234, frm:0});
Replay.hist.push({time:24.032599999999803, x:1668.6447679849725, y:789.74440166636, frm:0});
Replay.hist.push({time:24.049999999999805, x:1668.213099016964, y:788.933520904937, frm:0});
Replay.hist.push({time:24.066899999999805, x:1667.791759670217, y:788.1422523596884, frm:0});
Replay.hist.push({time:24.0835999999998, x:1667.3733899410322, y:787.3567635486011, frm:0});
Replay.hist.push({time:24.099299999999797, x:1666.9782677010626, y:786.6151068456095, frm:0});
Replay.hist.push({time:24.116899999999802, x:1666.5411872856032, y:785.794901965867, frm:0});
Replay.hist.push({time:24.1328999999998, x:1666.149660311522, y:785.0603660447628, frm:0});
Replay.hist.push({time:24.150299999999795, x:1665.72185305122, y:784.257965162202, frm:0});
Replay.hist.push({time:24.166899999999803, x:1665.3118172335721, y:783.489091814959, frm:0});
Replay.hist.push({time:24.1835999999998, x:1664.897416164223, y:782.7122265374526, frm:0});
Replay.hist.push({time:24.1999999999998, x:1664.4886217630763, y:781.9460619618659, frm:0});
Replay.hist.push({time:24.2164999999998, x:1664.0754863116344, y:781.1719525861778, frm:0});
Replay.hist.push({time:24.2335999999998, x:1663.6453573652423, y:780.3662054483144, frm:0});
Replay.hist.push({time:24.250299999999797, x:1663.2233846133802, y:779.5759383857418, frm:0});
Replay.hist.push({time:24.2669999999998, x:1662.7995186200556, y:778.7823258652852, frm:0});
Replay.hist.push({time:24.2825999999998, x:1662.401892827445, y:778.0380252886731, frm:0});
Replay.hist.push({time:24.300299999999798, x:1661.9486771300367, y:777.1898822630438, frm:0});
Replay.hist.push({time:24.3169999999998, x:1661.5191499618584, y:776.3862797431325, frm:0});
Replay.hist.push({time:24.333599999999798, x:1661.0903264780395, y:775.584197121935, frm:0});
Replay.hist.push({time:24.349599999999796, x:1660.6752551395548, y:774.8080299327266, frm:0});
Replay.hist.push({time:24.3668999999998, x:1660.2244727033626, y:773.9652989868434, frm:0});
Replay.hist.push({time:24.382599999999794, x:1659.8136755400383, y:773.1975140463464, frm:0});
Replay.hist.push({time:24.400299999999792, x:1659.3484908194837, y:772.3283010825937, frm:0});
Replay.hist.push({time:24.4168999999998, x:1658.92057550349, y:771.5289358832679, frm:0});
Replay.hist.push({time:24.433599999999796, x:1658.4919549556037, y:770.7284532699948, frm:0});
Replay.hist.push({time:24.450299999999793, x:1658.0626697962944, y:769.9269296364486, frm:0});
Replay.hist.push({time:24.466999999999796, x:1657.632726155526, y:769.1243769366661, frm:0});
Replay.hist.push({time:24.482599999999795, x:1657.2305072327094, y:768.3737576835806, frm:0});
Replay.hist.push({time:24.500299999999793, x:1656.7734722573223, y:767.5210531292298, frm:0});
Replay.hist.push({time:24.5168999999998, x:1656.3441810981465, y:766.7203162762587, frm:0});
Replay.hist.push({time:24.533599999999797, x:1655.911672128067, y:765.9137780137681, frm:0});
Replay.hist.push({time:24.550199999999794, x:1655.4811300171045, y:765.1111071682319, frm:0});
Replay.hist.push({time:24.566999999999798, x:1655.0447759482345, y:764.2978038133962, frm:0});
Replay.hist.push({time:24.583599999999795, x:1654.6130031574792, y:763.4932400090123, frm:0});
Replay.hist.push({time:24.60029999999979, x:1654.178021684392, y:762.6828986769096, frm:0});
Replay.hist.push({time:24.615999999999797, x:1653.768530975305, y:761.9202294689965, frm:0});
Replay.hist.push({time:24.633599999999795, x:1653.3088664154245, y:761.0643252210576, frm:0});
Replay.hist.push({time:24.650299999999792, x:1652.8720944444242, y:760.2512557137179, frm:0});
Replay.hist.push({time:24.6668999999998, x:1652.4373564980442, y:759.4421738915934, frm:0});
Replay.hist.push({time:24.683599999999796, x:1651.9994217887747, y:758.6273453632315, frm:0});
Replay.hist.push({time:24.700299999999793, x:1651.5609128771864, y:757.8116520496482, frm:0});
Replay.hist.push({time:24.7168999999998, x:1651.1330545625096, y:757.0159665639777, frm:0});
Replay.hist.push({time:24.732699999999795, x:1650.728346962168, y:756.2635123475743, frm:0});
Replay.hist.push({time:24.750199999999793, x:1650.2795585363951, y:755.429302817934, frm:0});
Replay.hist.push({time:24.766999999999797, x:1649.8481838054956, y:754.6276620570137, frm:0});
Replay.hist.push({time:24.782599999999796, x:1649.4471520005673, y:753.8825845193467, frm:0});
Replay.hist.push({time:24.800299999999794, x:1648.9916122373745, y:753.0364417960711, frm:0});
Replay.hist.push({time:24.816999999999798, x:1648.5612881272955, y:752.237336195747, frm:0});
Replay.hist.push({time:24.833599999999795, x:1648.1330495091397, y:751.4422963008924, frm:0});
Replay.hist.push({time:24.85029999999979, x:1647.7017433286123, y:750.6417557666801, frm:0});
Replay.hist.push({time:24.866199999999793, x:1647.2906441476734, y:749.8789025546164, frm:0});
Replay.hist.push({time:24.88369999999979, x:1646.8376834408316, y:749.038574172849, frm:0});
Replay.hist.push({time:24.90029999999979, x:1646.40753219819, y:748.2407603672993, frm:0});
Replay.hist.push({time:24.916899999999796, x:1645.9769181670556, y:747.4422819167892, frm:0});
Replay.hist.push({time:24.933599999999792, x:1645.5432489428815, y:746.6383340145314, frm:0});
Replay.hist.push({time:24.95029999999979, x:1645.1091219766363, y:745.8337341293343, frm:0});
Replay.hist.push({time:24.966899999999796, x:1644.6771456520776, y:745.0333152950412, frm:0});
Replay.hist.push({time:24.983699999999793, x:1644.2395160815001, y:744.2226197659598, frm:0});
Replay.hist.push({time:24.99969999999979, x:1643.8223060500786, y:743.4499365765193, frm:0});
Replay.hist.push({time:25.016899999999797, x:1643.3741672214455, y:742.6201743725005, frm:0});
Replay.hist.push({time:25.033599999999794, x:1642.9476749214432, y:741.8306864045517, frm:0});
Replay.hist.push({time:25.05029999999979, x:1642.5207794451808, y:741.040641571671, frm:0});
Replay.hist.push({time:25.066999999999794, x:1642.093485710028, y:740.2500494273971, frm:0});
Replay.hist.push({time:25.08359999999979, x:1641.6683602498892, y:739.4636574022846, frm:0});
Replay.hist.push({time:25.100299999999788, x:1641.2402870165888, y:738.6720023431012, frm:0});
Replay.hist.push({time:25.116899999999795, x:1640.814396388601, y:737.884572538025, frm:0});
Replay.hist.push({time:25.13359999999979, x:1640.385563080261, y:737.0918922918313, frm:0});
Replay.hist.push({time:25.15019999999979, x:1639.9589265487011, y:736.3034621236487, frm:0});
Replay.hist.push({time:25.166899999999792, x:1639.5293525028644, y:735.5097942470636, frm:0});
Replay.hist.push({time:25.18359999999979, x:1639.0994142302247, y:734.7156451593539, frm:0});
Replay.hist.push({time:25.200299999999785, x:1638.6691165620089, y:733.921024241618, frm:0});
Replay.hist.push({time:25.216799999999786, x:1638.2436228368074, y:733.1354635294666, frm:0});
Replay.hist.push({time:25.23289999999979, x:1637.8281128001322, y:732.3685162778545, frm:0});
Replay.hist.push({time:25.250299999999786, x:1637.3786990141243, y:731.5391911796003, frm:0});
Replay.hist.push({time:25.266299999999784, x:1636.9651115846987, y:730.7761633119751, frm:0});
Replay.hist.push({time:25.282699999999785, x:1636.540866908354, y:729.9936582299579, frm:0});
Replay.hist.push({time:25.300299999999783, x:1636.085231915461, y:729.153462702518, frm:0});
Replay.hist.push({time:25.31599999999979, x:1635.6784693676025, y:728.4035704228577, frm:0});
Replay.hist.push({time:25.333599999999787, x:1635.22215413288, y:727.562528976617, frm:0});
Replay.hist.push({time:25.350299999999784, x:1634.7956791941813, y:726.7766819794707, frm:0});
Replay.hist.push({time:25.36589999999979, x:1634.398081394303, y:726.0442156855688, frm:0});
Replay.hist.push({time:25.383699999999784, x:1633.9441126653066, y:725.208101891908, frm:0});
Replay.hist.push({time:25.40029999999978, x:1633.520446954228, y:724.4279928695212, frm:0});
Replay.hist.push({time:25.416999999999785, x:1633.0939466311715, y:723.6428527109995, frm:0});
Replay.hist.push({time:25.432599999999784, x:1632.695281127574, y:722.9091243014943, frm:0});
Replay.hist.push({time:25.450299999999782, x:1632.2426705119021, y:722.0763122487352, frm:0});
Replay.hist.push({time:25.46689999999979, x:1631.8179060552666, y:721.2949314120033, frm:0});
Replay.hist.push({time:25.483599999999786, x:1631.3903179630524, y:720.5085458835498, frm:0});
Replay.hist.push({time:25.49989999999979, x:1630.9727169160928, y:719.7407113818319, frm:0});
Replay.hist.push({time:25.51699999999979, x:1630.53436098999, y:718.934910642176, frm:0});
Replay.hist.push({time:25.533299999999784, x:1630.1162616984257, y:718.1665328429737, frm:0});
Replay.hist.push({time:25.550299999999783, x:1629.6799587261369, y:717.3648948043113, frm:0});
Replay.hist.push({time:25.566999999999787, x:1629.2511070773455, y:716.5771408559572, frm:0});
Replay.hist.push({time:25.583599999999784, x:1628.8245850647163, y:715.7938565380624, frm:0});
Replay.hist.push({time:25.600399999999787, x:1628.39268831416, y:715.0008953532063, frm:0});
Replay.hist.push({time:25.616999999999784, x:1627.9657022059364, y:714.2171416638852, frm:0});
Replay.hist.push({time:25.63369999999978, x:1627.5359181541655, y:713.4284446940464, frm:0});
Replay.hist.push({time:25.64969999999978, x:1627.1239364136154, y:712.6725981571989, frm:0});
Replay.hist.push({time:25.66699999999978, x:1626.6789643299485, y:711.8564251550636, frm:0});
Replay.hist.push({time:25.683699999999778, x:1626.2547487348847, y:711.0785171045272, frm:0});
Replay.hist.push({time:25.700299999999775, x:1625.8328733630206, y:710.3050875869915, frm:0});
Replay.hist.push({time:25.71699999999978, x:1625.4082605363872, y:709.5268280454233, frm:0});
Replay.hist.push({time:25.733599999999775, x:1624.9859983950892, y:708.7530647842336, frm:0});
Replay.hist.push({time:25.74929999999977, x:1624.5864534586349, y:708.0211016380665, frm:0});
Replay.hist.push({time:25.766899999999776, x:1624.138372372685, y:707.2004204346489, frm:0});
Replay.hist.push({time:25.78259999999977, x:1623.7384842343135, y:706.4681853658489, frm:0});
Replay.hist.push({time:25.80029999999977, x:1623.2874788951688, y:705.6425518357012, frm:0});
Replay.hist.push({time:25.816999999999773, x:1622.8617705395977, y:704.863425132341, frm:0});
Replay.hist.push({time:25.83359999999977, x:1622.4384434815388, y:704.0888464313427, frm:0});
Replay.hist.push({time:25.850299999999766, x:1622.0124024856477, y:703.309493190541, frm:0});
Replay.hist.push({time:25.86699999999977, x:1621.5862006801233, y:702.5300379628878, frm:0});
Replay.hist.push({time:25.883599999999767, x:1621.1612157160698, y:701.752999742399, frm:0});
Replay.hist.push({time:25.89949999999977, x:1620.7529240602166, y:701.0066637901195, frm:0});
Replay.hist.push({time:25.91599999999977, x:1620.3279196120156, y:700.229965706958, frm:0});
Replay.hist.push({time:25.933599999999768, x:1619.8731048930545, y:699.3990018282152, frm:0});
Replay.hist.push({time:25.949299999999763, x:1619.466190687788, y:698.6557405760597, frm:0});
Replay.hist.push({time:25.966999999999768, x:1619.0059642618412, y:697.8153128851222, frm:0});
Replay.hist.push({time:25.983699999999764, x:1618.5704007183244, y:697.020130620476, frm:0});
Replay.hist.push({time:26.00029999999976, x:1618.136148530149, y:696.2275441322852, frm:0});
Replay.hist.push({time:26.01689999999977, x:1617.7052221774084, y:695.4412272350555, frm:0});
Replay.hist.push({time:26.033299999999763, x:1617.2782322333283, y:694.6622892867013, frm:0});
Replay.hist.push({time:26.050299999999762, x:1616.834283335122, y:693.8526211998337, frm:0});
Replay.hist.push({time:26.066999999999766, x:1616.3968738628437, y:693.055086598275, frm:0});
Replay.hist.push({time:26.083699999999762, x:1615.9581761550446, y:692.2554097695393, frm:0});
Replay.hist.push({time:26.09929999999976, x:1615.5472446113386, y:691.5065342489314, frm:0});
Replay.hist.push({time:26.11619999999976, x:1615.1007694198527, y:690.6930909000932, frm:0});
Replay.hist.push({time:26.13369999999976, x:1614.6370471758946, y:689.8484526456848, frm:0});
Replay.hist.push({time:26.150399999999763, x:1614.193244474402, y:689.0403142703803, frm:0});
Replay.hist.push({time:26.16699999999976, x:1613.750845686614, y:688.2349446789826, frm:0});
Replay.hist.push({time:26.183699999999757, x:1613.3045190931452, y:687.4226398447206, frm:0});
Replay.hist.push({time:26.200299999999753, x:1612.8596199309409, y:686.6131483361038, frm:0});
Replay.hist.push({time:26.216999999999757, x:1612.4107864743087, y:685.7967167036718, frm:0});
Replay.hist.push({time:26.233599999999754, x:1611.9634040619515, y:684.9831430948593, frm:0});
Replay.hist.push({time:26.25029999999975, x:1611.512081168758, y:684.1626251182877, frm:0});
Replay.hist.push({time:26.266999999999754, x:1611.059516504988, y:683.3400733139706, frm:0});
Replay.hist.push({time:26.28359999999975, x:1610.6084385162333, y:682.5204469334826, frm:0});
Replay.hist.push({time:26.300299999999748, x:1610.1534113324751, y:681.6938710227736, frm:0});
Replay.hist.push({time:26.31699999999975, x:1609.6971561035784, y:680.8652929484383, frm:0});
Replay.hist.push({time:26.332699999999747, x:1609.2682094835222, y:680.0865175916904, frm:0});
Replay.hist.push({time:26.349299999999744, x:1608.8155197277315, y:679.2648555736507, frm:0});
Replay.hist.push({time:26.36699999999975, x:1608.3314891390705, y:678.386558557159, frm:0});
Replay.hist.push({time:26.383599999999745, x:1607.876346606928, y:677.560917136041, frm:0});
Replay.hist.push({time:26.400299999999742, x:1607.4172689178404, y:676.7283702048369, frm:0});
Replay.hist.push({time:26.41689999999975, x:1606.9597644150754, y:675.8989095159407, frm:0});
Replay.hist.push({time:26.433599999999746, x:1606.4983202424346, y:675.0625424873037, frm:0});
Replay.hist.push({time:26.450299999999743, x:1606.0356966699467, y:674.2242765317143, frm:0});
Replay.hist.push({time:26.46599999999975, x:1605.5997303596291, y:673.4345322950534, frm:0});
Replay.hist.push({time:26.482599999999746, x:1605.1376230198912, y:672.5976668138046, frm:0});
Replay.hist.push({time:26.499299999999742, x:1604.6715680366099, y:671.7538953061999, frm:0});
Replay.hist.push({time:26.515999999999746, x:1604.2043536448964, y:670.9082702785707, frm:0});
Replay.hist.push({time:26.532699999999743, x:1603.735985051117, y:670.0608035208863, frm:0});
Replay.hist.push({time:26.55019999999974, x:1603.243923623796, y:669.170734224454, frm:0});
Replay.hist.push({time:26.566099999999743, x:1602.795804157419, y:668.3603885393942, frm:0});
Replay.hist.push({time:26.58359999999974, x:1602.301351858944, y:667.4665228482008, frm:0});
Replay.hist.push({time:26.600399999999745, x:1601.8255269597041, y:666.6065945154569, frm:0});
Replay.hist.push({time:26.61699999999974, x:1601.3542517405576, y:665.7551433451224, frm:0});
Replay.hist.push({time:26.63359999999974, x:1600.8818682785948, y:664.9019450047205, frm:0});
Replay.hist.push({time:26.650299999999735, x:1600.4062046807396, y:664.0430808997083, frm:0});
Replay.hist.push({time:26.66699999999974, x:1599.9303533455093, y:663.1841381679106, frm:0});
Replay.hist.push({time:26.683599999999736, x:1599.4562634951312, y:662.3286346303787, frm:0});
Replay.hist.push({time:26.700299999999732, x:1598.978223398119, y:661.4662658219805, frm:0});
Replay.hist.push({time:26.716999999999736, x:1598.4990941743722, y:660.6021979469128, frm:0});
Replay.hist.push({time:26.73329999999973, x:1598.030406888569, y:659.7572192051549, frm:0});
Replay.hist.push({time:26.75029999999973, x:1597.5404789858853, y:658.8742200289996, frm:0});
Replay.hist.push({time:26.766899999999737, x:1597.0610178391553, y:658.0103564827765, frm:0});
Replay.hist.push({time:26.783699999999733, x:1596.5747024729847, y:657.1344183629092, frm:0});
Replay.hist.push({time:26.800399999999737, x:1596.0902215593255, y:656.2620604725968, frm:0});
Replay.hist.push({time:26.815999999999736, x:1595.6367280582176, y:655.4457489519874, frm:0});
Replay.hist.push({time:26.833699999999734, x:1595.1210205053023, y:654.5177439162377, frm:0});
Replay.hist.push({time:26.850399999999738, x:1594.63340281658, y:653.6405755270322, frm:0});
Replay.hist.push({time:26.866999999999734, x:1594.147681503667, y:652.7670990768457, frm:0});
Replay.hist.push({time:26.88359999999973, x:1593.6609432615685, y:651.8920755816079, frm:0});
Replay.hist.push({time:26.900299999999728, x:1593.1702503109848, y:651.0102288586836, frm:0});
Replay.hist.push({time:26.915999999999734, x:1592.7080377985312, y:650.1798292670518, frm:0});
Replay.hist.push({time:26.932899999999734, x:1592.209466725939, y:649.2843963385673, frm:0});
Replay.hist.push({time:26.95029999999973, x:1591.6960207178695, y:648.3625604687876, frm:0});
Replay.hist.push({time:26.966999999999732, x:1591.2006509911587, y:647.4733501006839, frm:0});
Replay.hist.push({time:26.98359999999973, x:1590.7087525795305, y:646.5904331763095, frm:0});
Replay.hist.push({time:27.000299999999726, x:1590.2144037734822, y:645.7031794841447, frm:0});
Replay.hist.push({time:27.01699999999973, x:1589.7205724653822, y:644.8169150956131, frm:0});
Replay.hist.push({time:27.033699999999726, x:1589.2272623205442, y:643.9316453555562, frm:0});
Replay.hist.push({time:27.05039999999973, x:1588.734476995315, y:643.047375595376, frm:0});
Replay.hist.push({time:27.066399999999728, x:1588.2628392822912, y:642.2011072701035, frm:0});
Replay.hist.push({time:27.083699999999723, x:1587.7534379812082, y:641.287136724428, frm:0});
Replay.hist.push({time:27.10029999999972, x:1587.2651848641012, y:640.4111655495667, frm:0});
Replay.hist.push({time:27.116999999999724, x:1586.7745287216437, y:639.5309364950092, frm:0});
Replay.hist.push({time:27.13369999999972, x:1586.284415503161, y:638.6517338132704, frm:0});
Replay.hist.push({time:27.150299999999717, x:1585.79777816609, y:637.7788171998185, frm:0});
Replay.hist.push({time:27.16699999999972, x:1585.3087582907779, y:636.9016767804687, frm:0});
Replay.hist.push({time:27.18319999999972, x:1584.8349060872517, y:636.051788927727, frm:0});
Replay.hist.push({time:27.200299999999718, x:1584.3353026986274, y:635.1557632730052, frm:0});
Replay.hist.push({time:27.21699999999972, x:1583.8479512276012, y:634.281758322695, frm:0});
Replay.hist.push({time:27.23359999999972, x:1583.3640767811094, y:633.4140340817539, frm:0});
Replay.hist.push({time:27.250299999999715, x:1582.87785417906, y:632.5421431605679, frm:0});
Replay.hist.push({time:27.26699999999972, x:1582.3848052424987, y:631.6580555167837, frm:0});
Replay.hist.push({time:27.283699999999715, x:1581.8923435366194, y:630.7750642010598, frm:0});
Replay.hist.push({time:27.300299999999712, x:1581.403418366845, y:629.8984557480333, frm:0});
Replay.hist.push({time:27.316999999999716, x:1580.9121438482623, y:629.0176759495106, frm:0});
Replay.hist.push({time:27.332599999999715, x:1580.4537657634603, y:628.1959103978744, frm:0});
Replay.hist.push({time:27.350399999999716, x:1579.9314019288936, y:627.2594744455029, frm:0});
Replay.hist.push({time:27.366999999999713, x:1579.4448695804763, y:626.3873115778338, frm:0});
Replay.hist.push({time:27.38359999999971, x:1578.958941758643, y:625.5162682382052, frm:0});
Replay.hist.push({time:27.400399999999713, x:1578.4677796474505, y:624.6358775608143, frm:0});
Replay.hist.push({time:27.41699999999971, x:1577.983078980532, y:623.7671025247754, frm:0});
Replay.hist.push({time:27.433699999999707, x:1577.4960798238085, y:622.8942403511464, frm:0});
Replay.hist.push({time:27.449799999999705, x:1577.0271673665497, y:622.0538253987493, frm:0});
Replay.hist.push({time:27.466799999999704, x:1576.5326828737905, y:621.1676093195355, frm:0});
Replay.hist.push({time:27.483699999999704, x:1576.0417571653586, y:620.2878013745083, frm:0});
Replay.hist.push({time:27.500399999999708, x:1575.557280990143, y:619.4195801712934, frm:0});
Replay.hist.push({time:27.516999999999705, x:1575.0763402122273, y:618.5577213859386, frm:0});
Replay.hist.push({time:27.5335999999997, x:1574.596035926747, y:617.6970286825873, frm:0});
Replay.hist.push({time:27.550299999999698, x:1574.1127405780446, y:616.8310006765395, frm:0});
Replay.hist.push({time:27.5669999999997, x:1573.6222053445322, y:615.9520234643369, frm:0});
Replay.hist.push({time:27.5830999999997, x:1573.1499301368167, y:615.1057877883222, frm:0});
Replay.hist.push({time:27.599399999999704, x:1572.672431995058, y:614.2502142608804, frm:0});
Replay.hist.push({time:27.616999999999702, x:1572.1575889223593, y:613.3277488187341, frm:0});
Replay.hist.push({time:27.6336999999997, x:1571.6697728369063, y:612.4537287269025, frm:0});
Replay.hist.push({time:27.650299999999696, x:1571.1855611379463, y:611.5861846814831, frm:0});
Replay.hist.push({time:27.6669999999997, x:1570.699124736905, y:610.7146716883822, frm:0});
Replay.hist.push({time:27.683699999999696, x:1570.2133857697324, y:609.8444240461906, frm:0});
Replay.hist.push({time:27.700299999999693, x:1569.7312496852965, y:608.980645781193, frm:0});
Replay.hist.push({time:27.716999999999697, x:1569.2469123705152, y:608.1129372211783, frm:0});
Replay.hist.push({time:27.732599999999696, x:1568.795111714588, y:607.303530345843, frm:0});
Replay.hist.push({time:27.750299999999694, x:1568.2832563815573, y:606.3865463512227, frm:0});
Replay.hist.push({time:27.766999999999697, x:1567.8010509242438, y:605.5226899268679, frm:0});
Replay.hist.push({time:27.783599999999694, x:1567.322445219341, y:604.6652908507413, frm:0});
Replay.hist.push({time:27.80029999999969, x:1566.841677635294, y:603.8040262267567, frm:0});
Replay.hist.push({time:27.816999999999695, x:1566.3616365532882, y:602.9440692613769, frm:0});
Replay.hist.push({time:27.83369999999969, x:1565.8823255724285, y:602.0854251911248, frm:0});
Replay.hist.push({time:27.850099999999692, x:1565.4088001690188, y:601.2371491999269, frm:0});
Replay.hist.push({time:27.86609999999969, x:1564.9414610892516, y:600.3999579160213, frm:0});
Replay.hist.push({time:27.88369999999969, x:1564.4282118281756, y:599.4805246474912, frm:0});
Replay.hist.push({time:27.899399999999694, x:1563.9710835988776, y:598.6616266045053, frm:0});
Replay.hist.push({time:27.916299999999687, x:1563.479788501767, y:597.7815210619511, frm:0});
Replay.hist.push({time:27.93369999999969, x:1562.9747948689776, y:596.8768734457074, frm:0});
Replay.hist.push({time:27.950299999999686, x:1562.4938074104393, y:596.0152268860446, frm:0});
Replay.hist.push({time:27.96699999999969, x:1562.0107076529284, y:595.1497914016933, frm:0});
Replay.hist.push({time:27.98349999999969, x:1561.5341690228179, y:594.2961035711561, frm:0});
Replay.hist.push({time:28.000299999999687, x:1561.0497645713044, y:593.4283171422868, frm:0});
Replay.hist.push({time:28.015999999999693, x:1560.5978014776404, y:592.6186398196306, frm:0});
Replay.hist.push({time:28.03369999999969, x:1560.089125061222, y:591.70735182755, frm:0});
Replay.hist.push({time:28.050299999999687, x:1559.6128775763045, y:590.8541484165816, frm:0});
Replay.hist.push({time:28.06699999999969, x:1559.1345685920546, y:589.9972393060733, frm:0});
Replay.hist.push({time:28.083699999999688, x:1558.657072625018, y:589.1417729493394, frm:0});
Replay.hist.push({time:28.09949999999969, x:1558.2060571005188, y:588.3337342511162, frm:0});
Replay.hist.push({time:28.11699999999969, x:1557.7073813951536, y:587.4402906159601, frm:0});
Replay.hist.push({time:28.13289999999969, x:1557.2550749683858, y:586.6299069858986, frm:0});
Replay.hist.push({time:28.150299999999685, x:1556.7509030229692, y:585.7265773108707, frm:0});
Replay.hist.push({time:28.16699999999969, x:1556.266283148051, y:584.8582577781387, frm:0});
Replay.hist.push({time:28.182699999999684, x:1555.8114712968797, y:584.0433264306113, frm:0});
Replay.hist.push({time:28.200299999999682, x:1555.302548752676, y:583.1314146170442, frm:0});
Replay.hist.push({time:28.216999999999686, x:1554.8205478628831, y:582.2677165018871, frm:0});
Replay.hist.push({time:28.233399999999687, x:1554.3480628869634, y:581.421044169022, frm:0});
Replay.hist.push({time:28.25029999999968, x:1553.8620699828623, y:580.5501381125086, frm:0});
Replay.hist.push({time:28.266999999999683, x:1553.3827229069007, y:579.6911120849721, frm:0});
Replay.hist.push({time:28.28359999999968, x:1552.9071316835798, y:578.838786530967, frm:0});
Replay.hist.push({time:28.300299999999677, x:1552.4295712302198, y:577.9829000151099, frm:0});
Replay.hist.push({time:28.31699999999968, x:1551.952912513211, y:577.1285963199721, frm:0});
Replay.hist.push({time:28.333699999999677, x:1551.4771593547916, y:576.2758810034778, frm:0});
Replay.hist.push({time:28.349299999999676, x:1551.033558995799, y:575.4807622141677, frm:0});
Replay.hist.push({time:28.36699999999968, x:1550.5312203106093, y:574.5803201647603, frm:0});
Replay.hist.push({time:28.383699999999678, x:1550.0582011542479, y:573.7323935472552, frm:0});
Replay.hist.push({time:28.400299999999675, x:1549.588926362787, y:572.8911393322855, frm:0});
Replay.hist.push({time:28.41699999999968, x:1549.1177468705391, y:572.0464293444277, frm:0});
Replay.hist.push({time:28.433699999999675, x:1548.6384207044944, y:571.187070346961, frm:0});
Replay.hist.push({time:28.45029999999967, x:1548.1593336634367, y:570.3280940999429, frm:0});
Replay.hist.push({time:28.466999999999675, x:1547.6783402638941, y:569.4656519822917, frm:0});
Replay.hist.push({time:28.48279999999967, x:1547.224171703198, y:568.6512624297935, frm:0});
Replay.hist.push({time:28.50029999999967, x:1546.7221812836597, y:567.7510681927772, frm:0});
Replay.hist.push({time:28.516999999999673, x:1546.2441524934213, y:566.8937894834753, frm:0});
Replay.hist.push({time:28.53359999999967, x:1545.769974884874, y:566.0433639183959, frm:0});
Replay.hist.push({time:28.550299999999666, x:1545.2939405844909, y:565.1895532138988, frm:0});
Replay.hist.push({time:28.56699999999967, x:1544.8189125279719, y:564.3374904496873, frm:0});
Replay.hist.push({time:28.583699999999666, x:1544.3448947115116, y:563.4871814360126, frm:0});
Replay.hist.push({time:28.599799999999664, x:1543.8888644621554, y:562.669082514279, frm:0});
Replay.hist.push({time:28.61699999999967, x:1543.4027286000262, y:561.7969124893106, frm:0});
Replay.hist.push({time:28.633699999999667, x:1542.9317592370915, y:560.9518887996333, frm:0});
Replay.hist.push({time:28.650299999999664, x:1542.4646263885002, y:560.1136850048453, frm:0});
Replay.hist.push({time:28.666999999999668, x:1541.995706966029, y:559.2722099316469, frm:0});
Replay.hist.push({time:28.683699999999664, x:1541.5278214789978, y:558.4325230502494, frm:0});
Replay.hist.push({time:28.700399999999668, x:1541.060973845571, y:557.5946300539368, frm:0});
Replay.hist.push({time:28.717099999999665, x:1540.5827099337598, y:556.7361742117332, frm:0});
Replay.hist.push({time:28.73369999999966, x:1540.1066760380324, y:555.8816457895729, frm:0});
Replay.hist.push({time:28.75029999999966, x:1539.6317379895643, y:555.0290076772444, frm:0});
Replay.hist.push({time:28.766999999999662, x:1539.1550494375874, y:554.1731477873693, frm:0});
Replay.hist.push({time:28.78379999999966, x:1538.6766346837298, y:553.3141068565047, frm:0});
Replay.hist.push({time:28.800399999999666, x:1538.205029369394, y:552.4672107599205, frm:0});
Replay.hist.push({time:28.816999999999663, x:1537.7345366886232, y:551.6222293858622, frm:0});
Replay.hist.push({time:28.83369999999966, x:1537.2623371695063, y:550.7740969133713, frm:0});
Replay.hist.push({time:28.850399999999663, x:1536.7912721368912, y:549.9279145250164, frm:0});
Replay.hist.push({time:28.865999999999662, x:1536.352258245274, y:549.1392255631503, frm:0});
Replay.hist.push({time:28.88279999999966, x:1535.8805958404678, y:548.2917948641643, frm:0});
Replay.hist.push({time:28.900299999999657, x:1535.3905190382536, y:547.4111796988199, frm:0});
Replay.hist.push({time:28.916399999999662, x:1534.9407573061642, y:546.602915054498, frm:0});
Replay.hist.push({time:28.933699999999657, x:1534.4586765379008, y:545.7364707480525, frm:0});
Replay.hist.push({time:28.949799999999655, x:1534.0111462753512, y:544.9320301250075, frm:0});
Replay.hist.push({time:28.966399999999663, x:1533.5508525433588, y:544.1045505384815, frm:0});
Replay.hist.push({time:28.983599999999658, x:1533.0685004566917, y:543.2373088945327, frm:0});
Replay.hist.push({time:28.99999999999966, x:1532.6005804721499, y:542.3959080988325, frm:0});
Replay.hist.push({time:29.01699999999966, x:1532.1168190746012, y:541.5259081825774, frm:0});
Replay.hist.push({time:29.032699999999654, x:1531.671200568263, y:540.724400376116, frm:0});
Replay.hist.push({time:29.050299999999652, x:1531.1729895754775, y:539.8281764933672, frm:0});
Replay.hist.push({time:29.067099999999655, x:1530.698730990218, y:538.9749189057229, frm:0});
Replay.hist.push({time:29.083699999999652, x:1530.2313794918573, y:538.1339698518356, frm:0});
Replay.hist.push({time:29.10029999999965, x:1529.7652871342746, y:537.2951670619564, frm:0});
Replay.hist.push({time:29.116999999999653, x:1529.2976625437766, y:536.4534844170519, frm:0});
Replay.hist.push({time:29.13299999999965, x:1528.8508389723665, y:535.6491248786373, frm:0});
Replay.hist.push({time:29.14999999999965, x:1528.3773896297635, y:534.7967071574985, frm:0});
Replay.hist.push({time:29.16709999999965, x:1527.902506635452, y:533.9415740432435, frm:0});
Replay.hist.push({time:29.183699999999646, x:1527.4428068017157, y:533.1136509404499, frm:0});
Replay.hist.push({time:29.199699999999645, x:1527.0009361022935, y:532.3177146451974, frm:0});
Replay.hist.push({time:29.216999999999647, x:1526.5245189905181, y:531.4594118464091, frm:0});
Replay.hist.push({time:29.233699999999644, x:1526.0659550561172, y:530.6331345227877, frm:0});
Replay.hist.push({time:29.25029999999964, x:1525.604115636538, y:529.8008151488306, frm:0});
Replay.hist.push({time:29.266999999999644, x:1525.130670134198, y:528.9474306893607, frm:0});
Replay.hist.push({time:29.28369999999964, x:1524.6586444601953, y:528.0964524629768, frm:0});
Replay.hist.push({time:29.30019999999964, x:1524.1936699283613, y:527.2580337959937, frm:0});
Replay.hist.push({time:29.315999999999644, x:1523.7497258639564, y:526.4573922770489, frm:0});
Replay.hist.push({time:29.33369999999964, x:1523.2539321795505, y:525.563071794358, frm:0});
Replay.hist.push({time:29.349999999999646, x:1522.7987762658215, y:524.7418937420532, frm:0});
Replay.hist.push({time:29.366999999999646, x:1522.3255434901619, y:523.8879356373102, frm:0});
Replay.hist.push({time:29.383699999999642, x:1521.862122307719, y:523.0515151566588, frm:0});
Replay.hist.push({time:29.399899999999647, x:1521.413962596243, y:522.2424789945758, frm:0});
Replay.hist.push({time:29.417099999999643, x:1520.9396467375122, y:521.3860490551851, frm:0});
Replay.hist.push({time:29.43369999999964, x:1520.4833456814767, y:520.561973062395, frm:0});
Replay.hist.push({time:29.450399999999643, x:1520.0257603164673, y:519.7354031255146, frm:0});
Replay.hist.push({time:29.46709999999964, x:1519.5696480633571, y:518.91131692024, frm:0});
Replay.hist.push({time:29.483699999999637, x:1519.117731103569, y:518.0946327095603, frm:0});
Replay.hist.push({time:29.50039999999964, x:1518.6645704643802, y:517.2755197523713, frm:0});
Replay.hist.push({time:29.516999999999637, x:1518.1978998267368, y:516.4317933858941, frm:0});
Replay.hist.push({time:29.533099999999635, x:1517.7452121874264, y:515.6131567791853, frm:0});
Replay.hist.push({time:29.550399999999637, x:1517.2604700858692, y:514.7363403841546, frm:0});
Replay.hist.push({time:29.567099999999634, x:1516.7941921538713, y:513.8927108075259, frm:0});
Replay.hist.push({time:29.58369999999963, x:1516.3323230060182, y:513.0568492012259, frm:0});
Replay.hist.push({time:29.599999999999635, x:1515.880373335302, y:512.2387341777427, frm:0});
Replay.hist.push({time:29.617099999999635, x:1515.4079284853199, y:511.38329788252065, frm:0});
Replay.hist.push({time:29.63369999999963, x:1514.950947776059, y:510.5556426409454, frm:0});
Replay.hist.push({time:29.650399999999635, x:1514.4928629820736, y:509.72576713431613, frm:0});
Replay.hist.push({time:29.667099999999632, x:1514.0364366907916, y:508.89867224340617, frm:0});
Replay.hist.push({time:29.683599999999633, x:1513.5871095084906, y:508.0842191218232, frm:0});
Replay.hist.push({time:29.700399999999636, x:1513.131288377978, y:507.2577649866789, frm:0});
Replay.hist.push({time:29.716599999999634, x:1512.693348071427, y:506.46350834027265, frm:0});
Replay.hist.push({time:29.733699999999633, x:1512.2327972597257, y:505.6280051631827, frm:0});
Replay.hist.push({time:29.749999999999638, x:1511.7954327616978, y:504.83433333838775, frm:0});
Replay.hist.push({time:29.767099999999637, x:1511.317683895356, y:503.9671140439802, frm:0});
Replay.hist.push({time:29.783699999999634, x:1510.8543906001732, y:503.1258671015476, frm:0});
Replay.hist.push({time:29.79989999999964, x:1510.404030971066, y:502.30784735601924, frm:0});
Replay.hist.push({time:29.817099999999634, x:1509.9277992345615, y:501.4425523265959, frm:0});
Replay.hist.push({time:29.83369999999963, x:1509.470060271873, y:500.61057929174524, frm:0});
Replay.hist.push({time:29.850399999999635, x:1509.011437100578, y:499.7767196249328, frm:0});
Replay.hist.push({time:29.866499999999633, x:1508.5710721341911, y:498.975788131102, frm:0});
Replay.hist.push({time:29.883699999999628, x:1508.1025672720639, y:498.1233814220222, frm:0});
Replay.hist.push({time:29.90039999999963, x:1507.649603494893, y:497.2989558284211, frm:0});
Replay.hist.push({time:29.91699999999963, x:1507.2012365750588, y:496.48260577243946, frm:0});
Replay.hist.push({time:29.933699999999625, x:1506.7520714674363, y:495.66450624997645, frm:0});
Replay.hist.push({time:29.94989999999963, x:1506.3181805247818, y:494.87393964913576, frm:0});
Replay.hist.push({time:29.96699999999963, x:1505.8621492501097, y:494.0427222559361, frm:0});
Replay.hist.push({time:29.983799999999626, x:1505.4160829486545, y:493.22935441894356, frm:0});
Replay.hist.push({time:30.000399999999633, x:1504.9627468176695, y:492.4024061008306, frm:0});
Replay.hist.push({time:30.01709999999963, x:1504.4994551545178, y:491.5569524981806, frm:0});
Replay.hist.push({time:30.03269999999963, x:1504.0686163033356, y:490.770402087139, frm:0});
Replay.hist.push({time:30.050399999999634, x:1503.5820715511582, y:489.8817755654638, frm:0});
Replay.hist.push({time:30.06709999999963, x:1503.125238260743, y:489.0470413793664, frm:0});
Replay.hist.push({time:30.083699999999627, x:1502.673291305256, y:488.2208726183348, frm:0});
Replay.hist.push({time:30.099799999999625, x:1502.237009903696, y:487.4229911247293, frm:0});
Replay.hist.push({time:30.11609999999963, x:1501.797378021018, y:486.61862740276626, frm:0});
Replay.hist.push({time:30.133699999999628, x:1501.3250345534764, y:485.7540081860317, frm:0});
Replay.hist.push({time:30.15039999999963, x:1500.8790971918697, y:484.93733128258174, frm:0});
Replay.hist.push({time:30.166299999999627, x:1500.4565667433442, y:484.1631606556531, frm:0});
Replay.hist.push({time:30.18369999999963, x:1499.9964804357148, y:483.31977175471536, frm:0});
Replay.hist.push({time:30.200399999999632, x:1499.5571641096185, y:482.51405143660793, frm:0});
Replay.hist.push({time:30.21709999999963, x:1499.1200725959102, y:481.7120101273846, frm:0});
Replay.hist.push({time:30.233699999999626, x:1498.6635737511538, y:480.87392046347327, frm:0});
Replay.hist.push({time:30.25039999999963, x:1498.2047024051171, y:480.03101509397834, frm:0});
Replay.hist.push({time:30.266999999999626, x:1497.7510727320787, y:479.19727440713973, frm:0});
Replay.hist.push({time:30.283699999999623, x:1497.2972284436637, y:478.36266708676027, frm:0});
Replay.hist.push({time:30.300399999999627, x:1496.8459172741345, y:477.5322388376492, frm:0});
Replay.hist.push({time:30.316999999999624, x:1496.3998263614146, y:476.71093539797175, frm:0});
Replay.hist.push({time:30.33369999999962, x:1495.9535901530373, y:475.88887515675344, frm:0});
Replay.hist.push({time:30.35019999999962, x:1495.5152082741886, y:475.0807965294843, frm:0});
Replay.hist.push({time:30.365999999999623, x:1495.097767502059, y:474.310859407084, frm:0});
Replay.hist.push({time:30.38369999999962, x:1494.6328738004845, y:473.45285873211753, frm:0});
Replay.hist.push({time:30.400399999999625, x:1494.1968977438291, y:472.6476983198961, frm:0});
Replay.hist.push({time:30.41709999999962, x:1493.763510260395, y:471.8467979403437, frm:0});
Replay.hist.push({time:30.43319999999962, x:1493.3481486253445, y:471.0787107186054, frm:0});
Replay.hist.push({time:30.45029999999962, x:1492.881946436806, y:470.2160135182476, frm:0});
Replay.hist.push({time:30.467099999999622, x:1492.424507542561, y:469.3689048623957, frm:0});
Replay.hist.push({time:30.48369999999962, x:1491.9754779720802, y:468.536748424221, frm:0});
Replay.hist.push({time:30.500299999999616, x:1491.5294049263807, y:467.7094464353222, frm:0});
Replay.hist.push({time:30.51709999999962, x:1491.0809784604203, y:466.87713569024885, frm:0});
Replay.hist.push({time:30.533399999999624, x:1490.648808277317, y:466.0743706873158, frm:0});
Replay.hist.push({time:30.550299999999616, x:1490.2037721419267, y:465.24704662465285, frm:0});
Replay.hist.push({time:30.56699999999962, x:1489.7670502975807, y:464.43451022125305, frm:0});
Replay.hist.push({time:30.582699999999615, x:1489.3592467971791, y:463.67516361432035, frm:0});
Replay.hist.push({time:30.600299999999613, x:1488.9053059713058, y:462.82919147404976, frm:0});
Replay.hist.push({time:30.616099999999616, x:1488.500675793959, y:462.0744627657099, frm:0});
Replay.hist.push({time:30.632699999999613, x:1488.0785206045605, y:461.2863721631298, frm:0});
Replay.hist.push({time:30.65029999999961, x:1487.6097211708054, y:460.410376081423, frm:0});
Replay.hist.push({time:30.666999999999614, x:1487.1588258004153, y:459.56698843654704, frm:0});
Replay.hist.push({time:30.68369999999961, x:1486.7115193863829, y:458.7294682859639, frm:0});
Replay.hist.push({time:30.700399999999615, x:1486.2678139432328, y:457.89783324231485, frm:0});
Replay.hist.push({time:30.71699999999961, x:1485.830345421044, y:457.0770267611214, frm:0});
Replay.hist.push({time:30.732699999999607, x:1485.4198858952423, y:456.3060953388282, frm:0});
Replay.hist.push({time:30.750399999999612, x:1484.9610040756236, y:455.4432700206275, frm:0});
Replay.hist.push({time:30.76699999999961, x:1484.534358696224, y:454.64013399334874, frm:0});
Replay.hist.push({time:30.782799999999604, x:1484.1316298142888, y:453.88117672629824, frm:0});
Replay.hist.push({time:30.80039999999961, x:1483.6869001028226, y:453.0420864387804, frm:0});
Replay.hist.push({time:30.816999999999606, x:1483.271183232247, y:452.2567752671633, frm:0});
Replay.hist.push({time:30.833699999999602, x:1482.8422976303482, y:451.44558267505136, frm:0});
Replay.hist.push({time:30.850399999999606, x:1482.3948506054198, y:450.59815847562754, frm:0});
Replay.hist.push({time:30.867099999999603, x:1481.9518066480991, y:449.75790200956976, frm:0});
Replay.hist.push({time:30.8836999999996, x:1481.5157940229865, y:448.92980198008695, frm:0});
Replay.hist.push({time:30.8992999999996, x:1481.1100349993098, y:448.1580734528881, frm:0});
Replay.hist.push({time:30.9170999999996, x:1480.6518080137553, y:447.28524686759715, frm:0});
Replay.hist.push({time:30.933799999999597, x:1480.2265038580933, y:446.4738445132035, frm:0});
Replay.hist.push({time:30.950399999999604, x:1479.808185620904, y:445.6745151534211, frm:0});
Replay.hist.push({time:30.9670999999996, x:1479.3918295228939, y:444.87765256613994, frm:0});
Replay.hist.push({time:30.983699999999597, x:1478.982435834701, y:444.092820077895, frm:0});
Replay.hist.push({time:31.0003999999996, x:1478.5750881839194, y:443.31058594843546, frm:0});
Replay.hist.push({time:31.017099999999598, x:1478.134064985618, y:442.4621351462688, frm:0});
Replay.hist.push({time:31.0335999999996, x:1477.7003207109403, y:441.6260504314673, frm:0});
Replay.hist.push({time:31.050299999999595, x:1477.2668431971388, y:440.78878615591054, frm:0});
Replay.hist.push({time:31.0669999999996, x:1476.8389428470823, y:439.96055902073, frm:0});
Replay.hist.push({time:31.083699999999595, x:1476.4166403953673, y:439.1413995629365, frm:0});
Replay.hist.push({time:31.0993999999996, x:1476.0247442948703, y:438.37957886023713, frm:0});
Replay.hist.push({time:31.1170999999996, x:1475.5889124050534, y:437.53040651968706, frm:0});
Replay.hist.push({time:31.133799999999596, x:1475.1835273955003, y:436.73863232337584, frm:0});
Replay.hist.push({time:31.150299999999596, x:1474.7885747960665, y:435.9653628637335, frm:0});
Replay.hist.push({time:31.1669999999996, x:1474.3759848352472, y:435.1554913801725, frm:0});
Replay.hist.push({time:31.182699999999596, x:1473.966741658493, y:434.34999234692384, frm:0});
Replay.hist.push({time:31.2003999999996, x:1473.5130200415365, y:433.45424802157004, frm:0});
Replay.hist.push({time:31.2165999999996, x:1473.104870662033, y:432.6459083509527, frm:0});
Replay.hist.push({time:31.233799999999594, x:1472.6790206259382, y:431.7997671476819, frm:0});
Replay.hist.push({time:31.2503999999996, x:1472.2753660446626, y:430.9949835272517, frm:0});
Replay.hist.push({time:31.266999999999598, x:1471.8789535910419, y:430.20187983571765, frm:0});
Replay.hist.push({time:31.283799999999594, x:1471.4851689603877, y:429.41115785694365, frm:0});
Replay.hist.push({time:31.2993999999996, x:1471.1262006176573, y:428.6876942895948, frm:0});
Replay.hist.push({time:31.316099999999597, x:1470.7048321104528, y:427.8350270413225, frm:0});
Replay.hist.push({time:31.333699999999595, x:1470.2603528470331, y:426.93127181257773, frm:0});
Replay.hist.push({time:31.3493999999996, x:1469.8730351749268, y:426.1398436445656, frm:0});
Replay.hist.push({time:31.3669999999996, x:1469.4492021050075, y:425.2692999665422, frm:0});
Replay.hist.push({time:31.383099999999597, x:1469.071106179803, y:424.4884055131712, frm:0});
Replay.hist.push({time:31.4003999999996, x:1468.6751297972953, y:423.6658651907977, frm:0});
Replay.hist.push({time:31.416199999999595, x:1468.3228386623803, y:422.9296651703617, frm:0});
Replay.hist.push({time:31.433799999999593, x:1467.9143879374342, y:422.07042778810046, frm:0});
Replay.hist.push({time:31.450299999999594, x:1467.506130664271, y:421.2048899649568, frm:0});
Replay.hist.push({time:31.4665999999996, x:1467.1162905281326, y:420.3714419975921, frm:1});
Replay.hist.push({time:31.482699999999596, x:1466.7444437134313, y:419.56939609207944, frm:1});
Replay.hist.push({time:31.5003999999996, x:1466.3508830129358, y:418.71206891752774, frm:1});
Replay.hist.push({time:31.5159999999996, x:1466.0173020014374, y:417.9777350334462, frm:1});
Replay.hist.push({time:31.533799999999594, x:1465.648802028217, y:417.1571967199224, frm:1});
Replay.hist.push({time:31.5499999999996, x:1465.2865458860351, y:416.3340422413677, frm:1});
Replay.hist.push({time:31.5669999999996, x:1464.9178339738553, y:415.4733200083978, frm:2});
Replay.hist.push({time:31.583699999999595, x:1464.5685281454266, y:414.63474429612444, frm:2});
Replay.hist.push({time:31.600099999999596, x:1464.2377097496606, y:413.8179276438739, frm:2});
Replay.hist.push({time:31.617099999999596, x:1463.9073701411364, y:412.9783292069003, frm:2});
Replay.hist.push({time:31.633699999999592, x:1463.5968820042826, y:412.1653194458054, frm:2});
Replay.hist.push({time:31.650399999999596, x:1463.2956576633521, y:411.3523381564955, frm:2});
Replay.hist.push({time:31.667099999999593, x:1462.9923717113927, y:410.507278625858, frm:2});
Replay.hist.push({time:31.68369999999959, x:1462.7033150124516, y:409.6745213939429, frm:2});
Replay.hist.push({time:31.700399999999593, x:1462.42476911312, y:408.8440396272121, frm:2});
Replay.hist.push({time:31.71699999999959, x:1462.1598214541123, y:408.0257332139788, frm:2});
Replay.hist.push({time:31.733699999999587, x:1461.9050497904975, y:407.2097481137011, frm:2});
Replay.hist.push({time:31.75039999999959, x:1461.6618452091056, y:406.40100115984427, frm:2});
Replay.hist.push({time:31.766999999999587, x:1461.430005135204, y:405.59957887430136, frm:2});
Replay.hist.push({time:31.783699999999584, x:1461.198900626499, y:404.7672410235609, frm:2});
Replay.hist.push({time:31.800399999999588, x:1460.9796755290147, y:403.94256373490765, frm:2});
Replay.hist.push({time:31.817099999999584, x:1460.772080270917, y:403.12552227511077, frm:2});
Replay.hist.push({time:31.83369999999958, x:1460.577007140881, y:402.32090248462606, frm:3});
Replay.hist.push({time:31.850399999999585, x:1460.3918707597554, y:401.5190108468545, frm:3});
Replay.hist.push({time:31.865999999999584, x:1460.2287543015375, y:400.77663217812506, frm:3});
Replay.hist.push({time:31.883699999999582, x:1460.0531055902309, y:399.9330983308769, frm:3});
Replay.hist.push({time:31.900399999999586, x:1459.8934244153938, y:399.11913521210175, frm:3});
Replay.hist.push({time:31.916999999999582, x:1459.7456883409868, y:398.31791674009554, frm:3});
Replay.hist.push({time:31.93369999999958, x:1459.6078735399792, y:397.519786841298, frm:3});
Replay.hist.push({time:31.950399999999583, x:1459.480653852796, y:396.72955166900846, frm:3});
Replay.hist.push({time:31.96709999999958, x:1459.3637863497254, y:395.94717996435105, frm:3});
Replay.hist.push({time:31.983699999999576, x:1459.257639519408, y:395.1772414111933, frm:3});
Replay.hist.push({time:32.00039999999958, x:1459.1589682967283, y:394.39606445337654, frm:3});
Replay.hist.push({time:32.01709999999958, x:1459.0684685796657, y:393.60605569800686, frm:3});
Replay.hist.push({time:32.03309999999958, x:1458.991302323588, y:392.8567549563963, frm:3});
Replay.hist.push({time:32.04959999999958, x:1458.9212859796107, y:392.09194987339754, frm:3});
Replay.hist.push({time:32.06709999999958, x:1458.857377722229, y:391.28959885051773, frm:3});
Replay.hist.push({time:32.08379999999958, x:1458.8060585577769, y:390.53209630076424, frm:3});
Replay.hist.push({time:32.10039999999958, x:1458.76417422675, y:389.7870908606861, frm:3});
Replay.hist.push({time:32.11709999999958, x:1458.7302888155198, y:389.02755792580473, frm:3});
Replay.hist.push({time:32.13369999999958, x:1458.7053316216648, y:388.2671586981156, frm:3});
Replay.hist.push({time:32.150399999999586, x:1458.689394137182, y:387.5063816678431, frm:3});
Replay.hist.push({time:32.167099999999586, x:1458.682559627162, y:386.7498411412522, frm:3});
Replay.hist.push({time:32.18369999999958, x:1458.6846939492445, y:386.00205004623024, frm:3});
Replay.hist.push({time:32.20029999999958, x:1458.695636619673, y:385.2584883151404, frm:3});
Replay.hist.push({time:32.21709999999958, x:1458.715584844018, y:384.5102983729449, frm:3});
Replay.hist.push({time:32.23379999999958, x:1458.7452160900987, y:383.7475147325102, frm:3});
Replay.hist.push({time:32.25039999999959, x:1458.783857607249, y:382.9924340385352, frm:3});
Replay.hist.push({time:32.26709999999959, x:1458.8318250407292, y:382.2374475996793, frm:3});
Replay.hist.push({time:32.283699999999584, x:1458.8884431329382, y:381.491618667512, frm:3});
Replay.hist.push({time:32.30039999999959, x:1458.9542923772285, y:380.74598748050386, frm:3});
Replay.hist.push({time:32.31699999999959, x:1459.0284839560252, y:380.0095051019597, frm:3});
Replay.hist.push({time:32.33369999999959, x:1459.1129240657563, y:379.26400840021233, frm:3});
Replay.hist.push({time:32.350399999999595, x:1459.2078859106316, y:378.51064799345943, frm:3});
Replay.hist.push({time:32.367099999999596, x:1459.3118792554985, y:377.7623700139648, frm:3});
Replay.hist.push({time:32.38369999999959, x:1459.4240922075337, y:377.02363416173534, frm:3});
Replay.hist.push({time:32.4003999999996, x:1459.5457676353662, y:376.285567981054, frm:3});
Replay.hist.push({time:32.416099999999595, x:1459.6680990482428, y:375.59639213214837, frm:3});
Replay.hist.push({time:32.43269999999959, x:1459.8057690005028, y:374.8723952739016, frm:3});
Replay.hist.push({time:32.4493999999996, x:1459.9567602388638, y:374.1301111427301, frm:3});
Replay.hist.push({time:32.4663999999996, x:1460.1195461086456, y:373.3801574250722, frm:3});
Replay.hist.push({time:32.4836999999996, x:1460.294485517256, y:372.6228665526581, frm:3});
Replay.hist.push({time:32.499899999999606, x:1460.466679399303, y:371.9191328230922, frm:3});
Replay.hist.push({time:32.5170999999996, x:1460.6582444423857, y:371.1777334521786, frm:3});
Replay.hist.push({time:32.5336999999996, x:1460.8515535272545, y:370.46784677663936, frm:3});
Replay.hist.push({time:32.550399999999605, x:1461.058646627363, y:369.74436154136856, frm:3});
Replay.hist.push({time:32.567099999999606, x:1461.2748704669057, y:369.0247183825105, frm:3});
Replay.hist.push({time:32.5836999999996, x:1461.4981450478303, y:368.3153062664594, frm:3});
Replay.hist.push({time:32.599399999999605, x:1461.7168643482132, y:367.6498066583109, frm:3});
Replay.hist.push({time:32.6170999999996, x:1461.97210730934, y:366.905948109886, frm:3});
Replay.hist.push({time:32.6326999999996, x:1462.2045812932151, y:366.2559691127916, frm:3});
Replay.hist.push({time:32.65039999999961, x:1462.4813162372443, y:365.5128868979692, frm:3});
Replay.hist.push({time:32.666999999999604, x:1462.7504970548691, y:364.8188986345575, frm:3});
Replay.hist.push({time:32.683099999999605, x:1463.0191535971048, y:364.1518622886774, frm:3});
Replay.hist.push({time:32.70039999999961, x:1463.3160173969475, y:363.4417978532686, frm:3});
Replay.hist.push({time:32.71599999999961, x:1463.5908751186876, y:362.8074491676599, frm:3});
Replay.hist.push({time:32.733799999999604, x:1463.9126388155967, y:362.0905986294896, frm:3});
Replay.hist.push({time:32.74959999999961, x:1464.2104109217348, y:361.44992762904866, frm:3});
Replay.hist.push({time:32.76709999999961, x:1464.5492520826824, y:360.7452880787267, frm:3});
Replay.hist.push({time:32.78369999999961, x:1464.878346852374, y:360.08383537912897, frm:3});
Replay.hist.push({time:32.80039999999961, x:1465.2168292048195, y:359.4252665309802, frm:3});
Replay.hist.push({time:32.8169999999996, x:1465.5605152222613, y:358.777497987249, frm:3});
Replay.hist.push({time:32.833799999999606, x:1465.9155466083266, y:358.1289201222914, frm:3});
Replay.hist.push({time:32.85039999999961, x:1466.2800258041884, y:357.4833390338484, frm:3});
Replay.hist.push({time:32.86709999999961, x:1466.6538978388578, y:356.84101500004806, frm:3});
Replay.hist.push({time:32.88379999999961, x:1467.0347914145923, y:356.20599747920585, frm:3});
Replay.hist.push({time:32.89999999999961, x:1467.4108623625489, y:355.5970008439222, frm:3});
Replay.hist.push({time:32.917099999999614, x:1467.8147083861804, y:354.96170625248607, frm:3});
Replay.hist.push({time:32.933399999999615, x:1468.207537209177, y:354.36121375445833, frm:3});
Replay.hist.push({time:32.94939999999961, x:1468.6048097728592, y:353.77045197426065, frm:3});
Replay.hist.push({time:32.96709999999961, x:1469.0513057020935, y:353.1252105749208, frm:3});
Replay.hist.push({time:32.98279999999961, x:1469.4534047979143, y:352.56016414031905, frm:3});
Replay.hist.push({time:33.000399999999615, x:1469.9107524014935, y:351.93495477818766, frm:3});
Replay.hist.push({time:33.016999999999605, x:1470.3483695483783, y:351.3532374253617, frm:3});
Replay.hist.push({time:33.03379999999961, x:1470.801521600827, y:350.7670301192659, frm:3});
Replay.hist.push({time:33.05039999999961, x:1471.2589625240284, y:350.1911410584967, frm:3});
Replay.hist.push({time:33.06709999999961, x:1471.7250555795304, y:349.6199786629052, frm:3});
Replay.hist.push({time:33.08379999999961, x:1472.196915208644, y:349.0570715199791, frm:3});
Replay.hist.push({time:33.09949999999961, x:1472.6456532758102, y:348.5354237557753, frm:3});
Replay.hist.push({time:33.11609999999961, x:1473.1253733914036, y:347.9918828175902, frm:3});
Replay.hist.push({time:33.13369999999962, x:1473.6478451211774, y:347.4158111706219, frm:3});
Replay.hist.push({time:33.15009999999962, x:1474.1411287882129, y:346.88651705758986, frm:3});
Replay.hist.push({time:33.16709999999962, x:1474.6577623505073, y:346.3467525722158, frm:3});
Replay.hist.push({time:33.183699999999625, x:1475.1673033087836, y:345.8284539203237, frm:3});
Replay.hist.push({time:33.200399999999625, x:1475.6848048637983, y:345.31581354082925, frm:3});
Replay.hist.push({time:33.21719999999963, x:1476.2142200108133, y:344.8052016922591, frm:3});
Replay.hist.push({time:33.23379999999962, x:1476.7477210300208, y:344.30430650152493, frm:3});
Replay.hist.push({time:33.25039999999962, x:1477.2857840353013, y:343.8125465682307, frm:3});
Replay.hist.push({time:33.26609999999962, x:1477.798737873712, y:343.3558819910395, frm:3});
Replay.hist.push({time:33.283799999999616, x:1478.3815774220584, y:342.8509392884913, frm:3});
Replay.hist.push({time:33.30039999999962, x:1478.9324338296442, y:342.3869235792096, frm:3});
Replay.hist.push({time:33.316099999999615, x:1479.4670038223458, y:341.948550153885, frm:3});
Replay.hist.push({time:33.33379999999961, x:1480.0742319550357, y:341.46443998745053, frm:3});
Replay.hist.push({time:33.35039999999962, x:1480.6476258854766, y:341.0204567282296, frm:3});
Replay.hist.push({time:33.36609999999961, x:1481.193256525368, y:340.6095254691295, frm:3});
Replay.hist.push({time:33.38369999999962, x:1481.8085602393958, y:340.1593047870599, frm:3});
Replay.hist.push({time:33.40039999999962, x:1482.403421777632, y:339.73701215134884, frm:3});
Replay.hist.push({time:33.41699999999961, x:1483.0025370844492, y:339.324289610105, frm:3});
Replay.hist.push({time:33.433799999999614, x:1483.6119993881262, y:338.91712056878333, frm:3});
Replay.hist.push({time:33.44949999999961, x:1484.1842569657983, y:338.5462135363698, frm:3});
Replay.hist.push({time:33.46709999999962, x:1484.8286576476019, y:338.1415124545857, frm:3});
Replay.hist.push({time:33.48369999999962, x:1485.4441172444654, y:337.7675738136236, frm:3});
Replay.hist.push({time:33.50039999999962, x:1486.0743460656543, y:337.39717924543453, frm:3});
Replay.hist.push({time:33.51609999999962, x:1486.6689932464785, y:337.0591229921044, frm:3});
Replay.hist.push({time:33.53369999999963, x:1487.3378662429232, y:336.69192409086594, frm:3});
Replay.hist.push({time:33.550499999999616, x:1487.9784050848152, y:336.3530512883756, frm:3});
Replay.hist.push({time:33.56689999999963, x:1488.6082044587092, y:336.0313793608412, frm:3});
Replay.hist.push({time:33.58269999999963, x:1489.2217654153587, y:335.71502349795134, frm:3});
Replay.hist.push({time:33.59989999999963, x:1489.882796441135, y:335.3634591623829, frm:3});
Replay.hist.push({time:33.617099999999624, x:1490.5365642132165, y:335.004446380953, frm:3});
Replay.hist.push({time:33.63369999999963, x:1491.1605579841364, y:334.65092989565085, frm:3});
Replay.hist.push({time:33.65049999999962, x:1491.7850077486264, y:334.2861694641399, frm:3});
Replay.hist.push({time:33.66609999999962, x:1492.358431046504, y:333.9411979152427, frm:3});
Replay.hist.push({time:33.68369999999963, x:1493.0063565912833, y:333.53944091035044, frm:3});
Replay.hist.push({time:33.70049999999962, x:1493.6209402885552, y:333.14618050368875, frm:3});
Replay.hist.push({time:33.71699999999962, x:1494.2170245890045, y:332.7529785305127, frm:3});
Replay.hist.push({time:33.73369999999964, x:1494.8126492135198, y:332.3480257471541, frm:3});
Replay.hist.push({time:33.750499999999626, x:1495.4039525017831, y:331.9335974283083, frm:3});
Replay.hist.push({time:33.76709999999963, x:1495.9803617214936, y:331.51719425730215, frm:3});
Replay.hist.push({time:33.783699999999634, x:1496.5520457301068, y:331.0915682971861, frm:3});
Replay.hist.push({time:33.800399999999634, x:1497.1257060426517, y:330.65124877692114, frm:4});
Replay.hist.push({time:33.817099999999634, x:1497.6909174523591, y:330.2038533004449, frm:4});
Replay.hist.push({time:33.83279999999963, x:1498.2145057415223, y:329.7768248379938, frm:4});
Replay.hist.push({time:33.85039999999964, x:1498.792374647958, y:329.29078267816305, frm:4});
Replay.hist.push({time:33.86709999999964, x:1499.3317481100084, y:328.8224471756586, frm:4});
Replay.hist.push({time:33.88299999999963, x:1499.8371041660498, y:328.37011697174626, frm:4});
Replay.hist.push({time:33.90039999999964, x:1500.3882378742157, y:327.8610784604617, frm:4});
Replay.hist.push({time:33.91639999999964, x:1500.8863950662212, y:327.386064382366, frm:4});
Replay.hist.push({time:33.933799999999636, x:1501.4184061973203, y:326.86221893841144, frm:4});
Replay.hist.push({time:33.95039999999964, x:1501.916427541, y:326.3554251464421, frm:4});
Replay.hist.push({time:33.96709999999964, x:1502.4079592124185, y:325.8386977915462, frm:4});
Replay.hist.push({time:33.98349999999964, x:1502.8813118988064, y:325.3245736499051, frm:4});
Replay.hist.push({time:33.999499999999635, x:1503.337208827444, y:324.8131129809111, frm:4});
Replay.hist.push({time:34.017099999999644, x:1503.829278471936, y:324.2419121078217, frm:4});
Replay.hist.push({time:34.033799999999644, x:1504.2858575687342, y:323.69286403233576, frm:4});
Replay.hist.push({time:34.05039999999965, x:1504.7296361657486, y:323.1403433558086, frm:4});
Replay.hist.push({time:34.06629999999964, x:1505.1452101981076, y:322.60483536438977, frm:4});
Replay.hist.push({time:34.082799999999644, x:1505.5665516577105, y:322.0426670610012, frm:4});
Replay.hist.push({time:34.10039999999965, x:1506.005445761619, y:321.4348447477718, frm:4});
Replay.hist.push({time:34.11709999999965, x:1506.411608996694, y:320.8504345971763, frm:4});
Replay.hist.push({time:34.13379999999965, x:1506.8070903008604, y:320.25937373349694, frm:4});
Replay.hist.push({time:34.15039999999966, x:1507.1895267756765, y:319.66530178681296, frm:4});
Replay.hist.push({time:34.16689999999966, x:1507.5590214248698, y:319.06837765853385, frm:4});
Replay.hist.push({time:34.18379999999965, x:1507.9263835834731, y:318.4503834143223, frm:4});
Replay.hist.push({time:34.199399999999656, x:1508.2549715615885, y:317.8748832577834, frm:4});
Replay.hist.push({time:34.217099999999654, x:1508.614943818421, y:317.21720967194625, frm:4});
Replay.hist.push({time:34.233799999999654, x:1508.9431271796036, y:316.59015226060234, frm:4});
Replay.hist.push({time:34.24939999999966, x:1509.2395697583952, y:315.99869644242517, frm:4});
Replay.hist.push({time:34.26709999999966, x:1509.563965261459, y:315.32103029703205, frm:4});
Replay.hist.push({time:34.28379999999966, x:1509.8583085957139, y:314.6752356317938, frm:4});
Replay.hist.push({time:34.30039999999966, x:1510.1391192657907, y:314.0281196343269, frm:4});
Replay.hist.push({time:34.31709999999966, x:1510.4069893629264, y:313.3788049408649, frm:4});
Replay.hist.push({time:34.33279999999966, x:1510.6484769228732, y:312.7629511927847, frm:4});
Replay.hist.push({time:34.35039999999967, x:1510.907173308226, y:312.0663959641832, frm:4});
Replay.hist.push({time:34.36709999999967, x:1511.1408238370773, y:311.39943791980215, frm:4});
Replay.hist.push({time:34.38379999999967, x:1511.3628798174022, y:310.7266644836102, frm:4});
Replay.hist.push({time:34.39949999999966, x:1511.5609947588516, y:310.0888976148479, frm:4});
Replay.hist.push({time:34.416099999999666, x:1511.7561681909426, y:309.41968845647, frm:4});
Replay.hist.push({time:34.433799999999664, x:1511.9504552012677, y:308.7052200594712, frm:4});
Replay.hist.push({time:34.44949999999966, x:1512.1120895060226, y:308.066381267397, frm:4});
Replay.hist.push({time:34.46709999999967, x:1512.2812344905165, y:307.3446127113632, frm:4});
Replay.hist.push({time:34.48369999999967, x:1512.429028201297, y:306.65841566895904, frm:4});
Replay.hist.push({time:34.50039999999967, x:1512.5661331501528, y:305.96280613105256, frm:4});
Replay.hist.push({time:34.51709999999967, x:1512.690715352853, y:305.2668479563031, frm:4});
Replay.hist.push({time:34.533699999999676, x:1512.8003892303561, y:304.58743822021074, frm:4});
Replay.hist.push({time:34.55039999999968, x:1512.899663637114, y:303.8990975858117, frm:4});
Replay.hist.push({time:34.56649999999967, x:1512.9848078544567, y:303.23091621385134, frm:4});
Replay.hist.push({time:34.58379999999968, x:1513.0646690790206, y:302.5079849989389, frm:4});
Replay.hist.push({time:34.60049999999968, x:1513.1302687433595, y:301.80527710259946, frm:4});
Replay.hist.push({time:34.61709999999968, x:1513.1842216904038, y:301.10209349841574, frm:4});
Replay.hist.push({time:34.632999999999676, x:1513.2246982135862, y:300.43595745112617, frm:4});
Replay.hist.push({time:34.650399999999685, x:1513.256833559053, y:299.719814726421, frm:4});
Replay.hist.push({time:34.667099999999685, x:1513.2768279480676, y:299.0281382526715, frm:4});
Replay.hist.push({time:34.683799999999685, x:1513.2861396167973, y:298.3322433022566, frm:4});
Replay.hist.push({time:34.69949999999968, x:1513.2851013535883, y:297.6741861544671, frm:4});
Replay.hist.push({time:34.71689999999969, x:1513.2728067012406, y:296.9405965034553, frm:4});
Replay.hist.push({time:34.73369999999969, x:1513.249761756069, y:296.22803725144956, frm:4});
Replay.hist.push({time:34.75039999999969, x:1513.2166167637051, y:295.5283409231072, frm:4});
Replay.hist.push({time:34.767099999999694, x:1513.1744264848808, y:294.84673930570676, frm:4});
Replay.hist.push({time:34.783799999999694, x:1513.122269768723, y:294.16148762174464, frm:3});
Replay.hist.push({time:34.8003999999997, x:1513.0605025293364, y:293.4767448671917, frm:3});
Replay.hist.push({time:34.8170999999997, x:1512.9883350725374, y:292.78428755775667, frm:3});
Replay.hist.push({time:34.8337999999997, x:1512.9060636257775, y:292.08825293437286, frm:3});
Replay.hist.push({time:34.849799999999696, x:1512.8177198982821, y:291.4180525453613, frm:3});
Replay.hist.push({time:34.8670999999997, x:1512.7116715553047, y:290.6897685577061, frm:3});
Replay.hist.push({time:34.8837999999997, x:1512.6047054861688, y:290.0182478791222, frm:3});
Replay.hist.push({time:34.8999999999997, x:1512.4927515704026, y:289.3676686416814, frm:3});
Replay.hist.push({time:34.91709999999971, x:1512.365173401565, y:288.67782342288865, frm:3});
Replay.hist.push({time:34.93379999999971, x:1512.231217286144, y:288.00102568994384, frm:3});
Replay.hist.push({time:34.95039999999971, x:1512.0888588114817, y:287.3252788609464, frm:3});
Replay.hist.push({time:34.967199999999714, x:1511.9354058633326, y:286.63836728868847, frm:3});
Replay.hist.push({time:34.983799999999704, x:1511.7744750755592, y:285.956661688718, frm:3});
Replay.hist.push({time:35.000499999999704, x:1511.6032068806035, y:285.26789248334916, frm:3});
Replay.hist.push({time:35.01709999999971, x:1511.4342929476381, y:284.62030346437393, frm:3});
Replay.hist.push({time:35.03369999999971, x:1511.25751941184, y:283.9717251447118, frm:3});
Replay.hist.push({time:35.05039999999971, x:1511.0713247351473, y:283.316701405461, frm:3});
Replay.hist.push({time:35.06709999999971, x:1510.8767198719984, y:282.65914753755317, frm:3});
Replay.hist.push({time:35.08379999999971, x:1510.6736763320364, y:281.99908042204737, frm:2});
Replay.hist.push({time:35.100399999999716, x:1510.4634571887705, y:281.34049065132626, frm:2});
Replay.hist.push({time:35.11719999999972, x:1510.2421599281063, y:280.67147377264547, frm:2});
Replay.hist.push({time:35.13379999999971, x:1510.0150242197149, y:280.0079710155309, frm:2});
Replay.hist.push({time:35.14989999999972, x:1509.7934266764776, y:279.3809967625202, frm:2});
Replay.hist.push({time:35.167099999999714, x:1509.557369719103, y:278.73310723071717, frm:2});
Replay.hist.push({time:35.18319999999972, x:1509.3291707782505, y:278.1246299465491, frm:2});
Replay.hist.push({time:35.200499999999714, x:1509.0761482475436, y:277.46865990609825, frm:2});
Replay.hist.push({time:35.21709999999972, x:1508.8257231600849, y:276.8371414231662, frm:2});
Replay.hist.push({time:35.23359999999972, x:1508.5693724406851, y:276.2074179150792, frm:2});
Replay.hist.push({time:35.25019999999972, x:1508.3039686805387, y:275.57187190771936, frm:2});
Replay.hist.push({time:35.267099999999715, x:1508.0260205977538, y:274.9227892552932, frm:2});
Replay.hist.push({time:35.283799999999715, x:1507.7436591185988, y:274.2793638717095, frm:2});
Replay.hist.push({time:35.29959999999972, x:1507.475465295629, y:273.6820325873248, frm:2});
Replay.hist.push({time:35.31709999999972, x:1507.1849351790393, y:273.04906369452743, frm:2});
Replay.hist.push({time:35.33319999999973, x:1506.9111331642252, y:272.4650758089322, frm:2});
Replay.hist.push({time:35.35039999999972, x:1506.6117221266559, y:271.83947173002514, frm:2});
Replay.hist.push({time:35.36709999999972, x:1506.3141687106854, y:271.2303523065341, frm:2});
Replay.hist.push({time:35.383799999999724, x:1506.0098562686098, y:270.6195727312076, frm:2});
Replay.hist.push({time:35.400499999999724, x:1505.6987688788367, y:270.0071441580651, frm:2});
Replay.hist.push({time:35.41709999999973, x:1505.3828138758856, y:269.39675859121525, frm:2});
Replay.hist.push({time:35.43379999999973, x:1505.0581699595618, y:268.7810750776749, frm:2});
Replay.hist.push({time:35.44969999999974, x:1504.7427341015286, y:268.1933758439433, frm:2});
Replay.hist.push({time:35.46709999999973, x:1504.4030769090273, y:267.5714970768127, frm:2});
Replay.hist.push({time:35.483699999999736, x:1504.0839296382787, y:266.9969622124353, frm:1});
Replay.hist.push({time:35.49939999999973, x:1503.7766732404407, y:266.45233850565216, frm:1});
Replay.hist.push({time:35.51709999999973, x:1503.4239711166929, y:265.836932073935, frm:1});
Replay.hist.push({time:35.53349999999973, x:1503.0911797604758, y:265.2653740900075, frm:1});
Replay.hist.push({time:35.55049999999973, x:1502.7401261471718, y:264.67156177516864, frm:1});
Replay.hist.push({time:35.56709999999973, x:1502.3913360812214, y:264.0904008381568, frm:1});
Replay.hist.push({time:35.58369999999974, x:1502.03661179413, y:263.50794765662863, frm:1});
Replay.hist.push({time:35.60049999999973, x:1501.6715616485667, y:262.9171722399203, frm:1});
Replay.hist.push({time:35.61719999999974, x:1501.30263721573, y:262.3286184928936, frm:1});
Replay.hist.push({time:35.63369999999974, x:1500.9321980909658, y:261.7458531345546, frm:1});
Replay.hist.push({time:35.64949999999973, x:1500.5954847977664, y:261.2229752842436, frm:1});
Replay.hist.push({time:35.66709999999974, x:1500.216529141945, y:260.6419575499656, frm:1});
Replay.hist.push({time:35.683699999999746, x:1499.8537578930068, y:260.0928568888297, frm:1});
Replay.hist.push({time:35.700399999999746, x:1499.483561601753, y:259.539392802935, frm:1});
Replay.hist.push({time:35.717099999999746, x:1499.1081011431345, y:258.98487626671255, frm:1});
Replay.hist.push({time:35.733799999999746, x:1498.727368405948, y:258.4293145164101, frm:1});
Replay.hist.push({time:35.75049999999975, x:1498.3413553323069, y:257.87271477032573, frm:1});
Replay.hist.push({time:35.76709999999975, x:1497.952412133562, y:257.3184254514698, frm:1});
Replay.hist.push({time:35.78379999999975, x:1497.5558461548287, y:256.75977740287476, frm:1});
Replay.hist.push({time:35.80049999999975, x:1497.1539760321734, y:256.20011286309034, frm:1});
Replay.hist.push({time:35.817099999999755, x:1496.7492472720937, y:255.6427983547946, frm:1});
Replay.hist.push({time:35.83309999999975, x:1496.3672470098372, y:255.122406895774, frm:1});
Replay.hist.push({time:35.85039999999976, x:1495.9648077488885, y:254.57988066499826, frm:1});
Replay.hist.push({time:35.86709999999976, x:1495.5716319067012, y:254.0553135410022, frm:1});
Replay.hist.push({time:35.88379999999976, x:1495.1738430698933, y:253.52991583285302, frm:1});
Replay.hist.push({time:35.90049999999976, x:1494.771435682494, y:253.0036933685475, frm:1});
Replay.hist.push({time:35.91709999999976, x:1494.3668546272452, y:252.4798094350952, frm:1});
Replay.hist.push({time:35.93369999999977, x:1493.9576993289727, y:251.95512202950243, frm:1});
Replay.hist.push({time:35.95039999999977, x:1493.5414588981448, y:251.42646975217167, frm:1});
Replay.hist.push({time:35.96709999999977, x:1493.1205781768272, y:250.89701581815225, frm:1});
Replay.hist.push({time:35.98379999999977, x:1492.6950518180895, y:250.36676598395104, frm:1});
Replay.hist.push({time:35.99949999999976, x:1492.2907577350065, y:249.86753843611083, frm:1});
Replay.hist.push({time:36.01709999999977, x:1491.832658732537, y:249.30708854617274, frm:1});
Replay.hist.push({time:36.03359999999977, x:1491.4016617164152, y:248.78470912678713, frm:1});
Replay.hist.push({time:36.050499999999765, x:1490.9832598972423, y:248.28202108469077, frm:1});
Replay.hist.push({time:36.06709999999977, x:1490.5682595103722, y:247.78760928813705, frm:1});
Replay.hist.push({time:36.08379999999977, x:1490.1467324232726, y:247.2895799415092, frm:1});
Replay.hist.push({time:36.10049999999977, x:1489.7211622557425, y:246.7909133313524, frm:1});
Replay.hist.push({time:36.11709999999977, x:1489.294129326489, y:246.29460496760055, frm:1});
Replay.hist.push({time:36.13379999999977, x:1488.860486622561, y:245.79468156968602, frm:1});
Replay.hist.push({time:36.150499999999774, x:1488.4227902938278, y:245.29413485210347, frm:1});
Replay.hist.push({time:36.16719999999979, x:1487.9810368842257, y:244.79296944922095, frm:1});
Replay.hist.push({time:36.18379999999978, x:1487.5379038444044, y:244.29419561588136, frm:1});
Replay.hist.push({time:36.19949999999977, x:1487.1150948866368, y:243.82190226656294, frm:1});
Replay.hist.push({time:36.21709999999978, x:1486.636859934946, y:243.29183395432662, frm:1});
Replay.hist.push({time:36.23379999999978, x:1486.1788930593896, y:242.7882470858028, frm:1});
Replay.hist.push({time:36.25049999999978, x:1485.7168523533683, y:242.28406447790124, frm:1});
Replay.hist.push({time:36.267099999999786, x:1485.2524619506146, y:241.78113546183187, frm:1});
Replay.hist.push({time:36.28289999999979, x:1484.8098502759378, y:241.30526076039408, frm:1});
Replay.hist.push({time:36.300499999999786, x:1484.317255580331, y:240.77961157836572, frm:1});
Replay.hist.push({time:36.3171999999998, x:1483.8502637140411, y:240.2851293748849, frm:1});
Replay.hist.push({time:36.3335999999998, x:1483.3920624399523, y:239.8035836933216, frm:1});
Replay.hist.push({time:36.35049999999979, x:1482.9203198113848, y:239.31155157481425, frm:1});
Replay.hist.push({time:36.367199999999805, x:1482.4545866205772, y:238.8295045860241, frm:1});
Replay.hist.push({time:36.383799999999795, x:1481.9920686946339, y:238.35443118472418, frm:1});
Replay.hist.push({time:36.400499999999795, x:1481.527200439365, y:237.88059508143613, frm:1});
Replay.hist.push({time:36.4170999999998, x:1481.0655527935428, y:237.41365531928804, frm:1});
Replay.hist.push({time:36.4337999999998, x:1480.6015706089884, y:236.9479736376601, frm:1});
Replay.hist.push({time:36.4504999999998, x:1480.1380406997537, y:236.48636022330237, frm:1});
Replay.hist.push({time:36.4670999999998, x:1479.6777390455582, y:236.0315280208098, frm:1});
Replay.hist.push({time:36.4837999999998, x:1479.2151266560584, y:235.57798469360463, frm:1});
Replay.hist.push({time:36.5004999999998, x:1478.7529822278914, y:235.128467473765, frm:1});
Replay.hist.push({time:36.51609999999981, x:1478.3216975804933, y:234.71217594380568, frm:1});
Replay.hist.push({time:36.53279999999981, x:1477.8604729961044, y:234.2704060517968, frm:1});
Replay.hist.push({time:36.55039999999982, x:1477.3749225931704, y:233.80914807081044, frm:1});
Replay.hist.push({time:36.56709999999982, x:1476.9146959483373, y:233.37554789952756, frm:1});
Replay.hist.push({time:36.58379999999982, x:1476.4476948469505, y:232.93913969464893, frm:1});
Replay.hist.push({time:36.60039999999982, x:1475.9512346931033, y:232.47914214996428, frm:1});
Replay.hist.push({time:36.617199999999826, x:1475.449369609511, y:232.01825445461472, frm:1});
Replay.hist.push({time:36.633799999999816, x:1474.9540518310764, y:231.56742999123625, frm:1});
Replay.hist.push({time:36.65039999999982, x:1474.4593115036132, y:231.12113934434586, frm:1});
Replay.hist.push({time:36.66719999999982, x:1473.959206657276, y:230.67407223092175, frm:1});
Replay.hist.push({time:36.68379999999981, x:1473.4656475507286, y:230.23685317306385, frm:1});
Replay.hist.push({time:36.70049999999981, x:1472.969718125214, y:229.8015251215208, frm:1});
Replay.hist.push({time:36.716699999999825, x:1472.4892158063258, y:229.3835471643449, frm:1});
Replay.hist.push({time:36.73379999999982, x:1471.982656386926, y:228.9469532796515, frm:1});
Replay.hist.push({time:36.75049999999982, x:1471.4885733445756, y:228.52511016121363, frm:1});
Replay.hist.push({time:36.76639999999983, x:1471.0187372358478, y:228.12762407774017, frm:1});
Replay.hist.push({time:36.783899999999825, x:1470.5023025982234, y:227.69481874940467, frm:1});
Replay.hist.push({time:36.79949999999982, x:1470.0425169466982, y:227.31309894864316, frm:1});
Replay.hist.push({time:36.81719999999983, x:1469.5215412290777, y:226.88468082418962, frm:1});
Replay.hist.push({time:36.83389999999983, x:1469.0306646884906, y:226.48499577820485, frm:1});
Replay.hist.push({time:36.85049999999982, x:1468.543381636435, y:226.09205104010823, frm:1});
Replay.hist.push({time:36.86709999999982, x:1468.0567577074346, y:225.7034246053602, frm:1});
Replay.hist.push({time:36.88329999999982, x:1467.5824976506512, y:225.32830797465846, frm:1});
Replay.hist.push({time:36.900499999999816, x:1467.0365366954998, y:224.90091667945367, frm:1});
Replay.hist.push({time:36.91719999999983, x:1466.5065648040638, y:224.49057772515707, frm:1});
Replay.hist.push({time:36.93379999999982, x:1465.9805502753768, y:224.08771461026552, frm:1});
Replay.hist.push({time:36.950399999999824, x:1465.45532598837, y:223.6898358487008, frm:1});
Replay.hist.push({time:36.967099999999824, x:1464.9277438591466, y:223.29457025269997, frm:1});
Replay.hist.push({time:36.983799999999825, x:1464.4009772239403, y:222.90430881534948, frm:1});
Replay.hist.push({time:37.000499999999825, x:1463.8750339132143, y:222.51903134011798, frm:1});
Replay.hist.push({time:37.01709999999983, x:1463.3530627040986, y:222.14097960982647, frm:1});
Replay.hist.push({time:37.03369999999983, x:1462.8319203292353, y:221.76781269434113, frm:1});
Replay.hist.push({time:37.05039999999983, x:1462.308483489708, y:221.39730755992022, frm:1});
Replay.hist.push({time:37.067199999999836, x:1461.7827751808604, y:221.02953245050907, frm:1});
Replay.hist.push({time:37.08349999999982, x:1461.2735421610869, y:220.6774241199933, frm:1});
Replay.hist.push({time:37.09959999999983, x:1460.7713687178684, y:220.33418424674284, frm:1});
Replay.hist.push({time:37.116599999999835, x:1460.2420165578133, y:219.97665036799376, frm:1});
Replay.hist.push({time:37.13279999999983, x:1459.738420747271, y:219.64058966663305, frm:1});
Replay.hist.push({time:37.14949999999983, x:1459.2201650279137, y:219.29889348164053, frm:1});
Replay.hist.push({time:37.167199999999845, x:1458.6658799684255, y:218.9380949606561, frm:1});
Replay.hist.push({time:37.183799999999835, x:1458.1086754149196, y:218.58023973434553, frm:1});
Replay.hist.push({time:37.200499999999835, x:1457.5491627005658, y:218.22578575628617, frm:1});
Replay.hist.push({time:37.216199999999844, x:1457.0241111853795, y:217.89761161855995, frm:1});
Replay.hist.push({time:37.23379999999984, x:1456.4366621257159, y:217.53553994419693, frm:1});
Replay.hist.push({time:37.25049999999984, x:1455.8803524156785, y:217.19762707807112, frm:1});
Replay.hist.push({time:37.26719999999985, x:1455.3251315605437, y:216.86519077320386, frm:1});
Replay.hist.push({time:37.282799999999845, x:1454.8074640340617, y:216.55957165655087, frm:1});
Replay.hist.push({time:37.30049999999984, x:1454.2213027532746, y:216.21856135962827, frm:1});
Replay.hist.push({time:37.31719999999986, x:1453.669398456044, y:215.9023785233551, frm:1});
Replay.hist.push({time:37.33379999999985, x:1453.121914365267, y:215.5934212128517, frm:1});
Replay.hist.push({time:37.349399999999854, x:1452.608424761485, y:215.30789580632114, frm:1});
Replay.hist.push({time:37.366099999999854, x:1452.0598452741576, y:215.00740214371956, frm:1});
Replay.hist.push({time:37.38379999999985, x:1451.4796866345528, y:214.69471789215294, frm:1});
Replay.hist.push({time:37.39979999999985, x:1450.9563579459382, y:214.4171680106293, frm:1});
Replay.hist.push({time:37.41609999999985, x:1450.424326250404, y:214.1393840590563, frm:1});
Replay.hist.push({time:37.43379999999985, x:1449.8286554981305, y:213.8336169394321, frm:1});
Replay.hist.push({time:37.449499999999844, x:1449.2764666198655, y:213.55512016488223, frm:1});
Replay.hist.push({time:37.467199999999856, x:1448.6554180754317, y:213.2475881235815, frm:1});
Replay.hist.push({time:37.48289999999985, x:1448.1058310847363, y:212.98047359220482, frm:1});
Replay.hist.push({time:37.50039999999985, x:1447.4947133879075, y:212.68900450635735, frm:2});
Replay.hist.push({time:37.51709999999985, x:1446.9129706436966, y:212.4169808240702, frm:2});
Replay.hist.push({time:37.53379999999985, x:1446.3326522136367, y:212.15091002762983, frm:2});
Replay.hist.push({time:37.550399999999854, x:1445.7572305461695, y:211.8923034042748, frm:2});
Replay.hist.push({time:37.567099999999854, x:1445.1797862362364, y:211.6380179165535, frm:2});
Replay.hist.push({time:37.583799999999854, x:1444.603800268106, y:211.3896003742598, frm:2});
Replay.hist.push({time:37.600299999999855, x:1444.0361535715074, y:211.14989238164563, frm:2});
Replay.hist.push({time:37.61709999999986, x:1443.4596743207499, y:210.9116563967189, frm:2});
Replay.hist.push({time:37.63379999999986, x:1442.8881218580796, y:210.68063906481132, frm:2});
Replay.hist.push({time:37.65049999999986, x:1442.3180724792942, y:210.45537735635952, frm:2});
Replay.hist.push({time:37.66709999999986, x:1441.7529361276888, y:210.23714052089394, frm:2});
Replay.hist.push({time:37.68279999999986, x:1441.1877647781255, y:210.02396091113897, frm:2});
Replay.hist.push({time:37.70039999999987, x:1440.5457937425626, y:209.78797604379227, frm:2});
Replay.hist.push({time:37.71709999999987, x:1439.9384575214506, y:209.57076964254884, frm:2});
Replay.hist.push({time:37.73289999999987, x:1439.3654839721798, y:209.37125424560537, frm:2});
Replay.hist.push({time:37.75039999999987, x:1438.7327578138156, y:209.15703971389786, frm:2});
Replay.hist.push({time:37.767199999999875, x:1438.1272031762023, y:208.9580419572374, frm:2});
Replay.hist.push({time:37.783899999999875, x:1437.5270815630115, y:208.7666512839271, frm:2});
Replay.hist.push({time:37.79959999999987, x:1436.9645609631893, y:208.5925282162729, frm:2});
Replay.hist.push({time:37.81709999999987, x:1436.3394960221824, y:208.40504995754623, frm:2});
Replay.hist.push({time:37.83359999999987, x:1435.7520135305779, y:208.2346228967653, frm:2});
Replay.hist.push({time:37.85049999999986, x:1435.1521938975354, y:208.06641197321318, frm:2});
Replay.hist.push({time:37.86699999999986, x:1434.5684356077838, y:207.90834497262193, frm:2});
Replay.hist.push({time:37.883799999999866, x:1433.9759770943033, y:207.75362886343015, frm:2});
Replay.hist.push({time:37.900499999999866, x:1433.385177197563, y:207.60508993401956, frm:2});
Replay.hist.push({time:37.91699999999987, x:1432.7666204891593, y:207.45574086388226, frm:2});
Replay.hist.push({time:37.93379999999987, x:1432.139028385141, y:207.3106835858245, frm:2});
Replay.hist.push({time:37.95049999999987, x:1431.5173947870558, y:207.17345693282306, frm:2});
Replay.hist.push({time:37.967199999999885, x:1430.897994752374, y:207.04313792961247, frm:2});
Replay.hist.push({time:37.983799999999874, x:1430.2845320736278, y:206.92040638819262, frm:2});
Replay.hist.push({time:38.000499999999874, x:1429.669633860159, y:206.80374553442996, frm:2});
Replay.hist.push({time:38.01719999999989, x:1429.0570172823175, y:206.6938763903301, frm:2});
Replay.hist.push({time:38.03299999999988, x:1428.4795213901361, y:206.5961444992552, frm:2});
Replay.hist.push({time:38.05049999999988, x:1427.8423263277273, y:206.4949165888993, frm:2});
Replay.hist.push({time:38.06719999999989, x:1427.236635897572, y:206.40515202211472, frm:2});
Replay.hist.push({time:38.08379999999988, x:1426.6368950130018, y:206.32250387997001, frm:2});
Replay.hist.push({time:38.09949999999988, x:1426.0718065905107, y:206.25033716127973, frm:2});
Replay.hist.push({time:38.11649999999988, x:1425.4570906315867, y:206.17815349946383, frm:2});
Replay.hist.push({time:38.13379999999989, x:1424.801984965539, y:206.1085188883847, frm:2});
Replay.hist.push({time:38.15039999999989, x:1424.1760877967602, y:206.0490601649558, frm:2});
Replay.hist.push({time:38.16709999999989, x:1423.5491175291086, y:205.99647137027398, frm:2});
Replay.hist.push({time:38.18349999999989, x:1422.9360565781155, y:205.95184005077374, frm:3});
Replay.hist.push({time:38.20049999999989, x:1422.3033639072935, y:205.91286708165543, frm:3});
Replay.hist.push({time:38.217099999999895, x:1421.6883129117384, y:205.88192894180258, frm:3});
Replay.hist.push({time:38.2338999999999, x:1421.0686483924696, y:205.85773492134746, frm:3});
Replay.hist.push({time:38.24949999999989, x:1420.4957662632512, y:205.841640689302, frm:3});
Replay.hist.push({time:38.2670999999999, x:1419.8524090221044, y:205.83080459819894, frm:3});
Replay.hist.push({time:38.2838999999999, x:1419.2412201400136, y:205.82765678716373, frm:3});
Replay.hist.push({time:38.30049999999989, x:1418.6401379848348, y:205.8314063338741, frm:3});
Replay.hist.push({time:38.3162999999999, x:1418.0706470074924, y:205.84127035762455, frm:3});
Replay.hist.push({time:38.3332999999999, x:1417.4346800225742, y:205.8596035218825, frm:3});
Replay.hist.push({time:38.3495999999999, x:1416.8242650267316, y:205.8845237392984, frm:3});
Replay.hist.push({time:38.3667999999999, x:1416.183460643249, y:205.9184726361275, frm:3});
Replay.hist.push({time:38.383899999999905, x:1415.5497655063295, y:205.9599629818604, frm:3});
Replay.hist.push({time:38.400499999999894, x:1414.937843013408, y:206.00757239064637, frm:3});
Replay.hist.push({time:38.4170999999999, x:1414.3291417448875, y:206.0623570314802, frm:3});
Replay.hist.push({time:38.43319999999991, x:1413.741865384025, y:206.12230172169325, frm:3});
Replay.hist.push({time:38.4504999999999, x:1413.114249635163, y:206.19413696208258, frm:3});
Replay.hist.push({time:38.46719999999991, x:1412.5117740615078, y:206.27072822028913, frm:3});
Replay.hist.push({time:38.4837999999999, x:1411.9162155260105, y:206.35386823193582, frm:3});
Replay.hist.push({time:38.5004999999999, x:1411.3204206390474, y:206.44451102769017, frm:3});
Replay.hist.push({time:38.51719999999992, x:1410.7248185986805, y:206.54267512976156, frm:3});
Replay.hist.push({time:38.53279999999991, x:1410.1544869683173, y:206.6438297363905, frm:3});
Replay.hist.push({time:38.55049999999991, x:1409.5112726653274, y:206.76641021246544, frm:3});
Replay.hist.push({time:38.56709999999991, x:1408.9117781871453, y:206.88887026598314, frm:3});
Replay.hist.push({time:38.58379999999991, x:1408.3123664447821, y:207.01933590446689, frm:3});
Replay.hist.push({time:38.59949999999991, x:1407.7522346630653, y:207.1485939114803, frm:3});
Replay.hist.push({time:38.617099999999915, x:1407.1282755443394, y:207.30104221892518, frm:3});
Replay.hist.push({time:38.63389999999992, x:1406.5365739217323, y:207.45395773921098, frm:3});
Replay.hist.push({time:38.649799999999914, x:1405.9800941893122, y:207.60528868888696, frm:3});
Replay.hist.push({time:38.66719999999992, x:1405.3750927431045, y:207.77819892896713, frm:3});
Replay.hist.push({time:38.68379999999991, x:1404.8017769557894, y:207.9502285835298, frm:3});
Replay.hist.push({time:38.69979999999991, x:1404.252781096253, y:208.12252597193174, frm:3});
Replay.hist.push({time:38.71719999999992, x:1403.6505740325385, y:208.32016288371028, frm:3});
Replay.hist.push({time:38.73379999999991, x:1403.075921948476, y:208.51731466079076, frm:3});
Replay.hist.push({time:38.75049999999991, x:1402.5018894143677, y:208.72273230508836, frm:3});
Replay.hist.push({time:38.767099999999914, x:1401.935371036459, y:208.93390386513084, frm:3});
Replay.hist.push({time:38.783799999999914, x:1401.3695656083692, y:209.1533198872278, frm:3});
Replay.hist.push({time:38.800499999999914, x:1400.807919343277, y:209.37967669082877, frm:3});
Replay.hist.push({time:38.81709999999992, x:1400.2537789068717, y:209.6115048059081, frm:3});
Replay.hist.push({time:38.83379999999992, x:1399.7004918667517, y:209.8515440421789, frm:3});
Replay.hist.push({time:38.85049999999992, x:1399.15142977393, y:210.09836631784947, frm:3});
Replay.hist.push({time:38.86719999999993, x:1398.6066143899582, y:210.3519192603051, frm:3});
Replay.hist.push({time:38.88379999999992, x:1398.069290425154, y:210.61057310280222, frm:3});
Replay.hist.push({time:38.90049999999992, x:1397.5306372043146, y:210.87859017888493, frm:3});
Replay.hist.push({time:38.91719999999994, x:1396.9933343989373, y:211.15480708841272, frm:3});
Replay.hist.push({time:38.93379999999993, x:1396.463625803843, y:211.43596750706843, frm:3});
Replay.hist.push({time:38.95039999999993, x:1395.9383035188866, y:211.7236529072662, frm:3});
Replay.hist.push({time:38.967199999999934, x:1395.4111418798573, y:212.02139354258625, frm:3});
Replay.hist.push({time:38.983799999999924, x:1394.8947099934899, y:212.32205027331557, frm:3});
Replay.hist.push({time:38.99989999999993, x:1394.3980799917547, y:212.61973856677395, frm:3});
Replay.hist.push({time:39.01719999999994, x:1393.8691344657807, y:212.94623137184095, frm:3});
Replay.hist.push({time:39.03329999999993, x:1393.3812511801132, y:213.25619893380886, frm:3});
Replay.hist.push({time:39.05049999999993, x:1392.864736194867, y:213.59379631545195, frm:3});
Replay.hist.push({time:39.06719999999994, x:1392.3678897091181, y:213.92791440479803, frm:3});
Replay.hist.push({time:39.082799999999935, x:1391.9080498031442, y:214.24553320443266, frm:3});
Replay.hist.push({time:39.10049999999993, x:1391.3919648138062, y:214.61183779443823, frm:3});
Replay.hist.push({time:39.11709999999994, x:1390.912675927569, y:214.96157393416104, frm:3});
Replay.hist.push({time:39.13379999999994, x:1390.435144524973, y:215.31940929920881, frm:3});
Replay.hist.push({time:39.149799999999935, x:1389.9820114123195, y:215.66784165809094, frm:3});
Replay.hist.push({time:39.167199999999944, x:1389.4941376652855, y:216.05290960399867, frm:3});
Replay.hist.push({time:39.183799999999934, x:1389.033462610873, y:216.4262081193482, frm:3});
Replay.hist.push({time:39.200499999999934, x:1388.5747384254507, y:216.8075460062352, frm:3});
Replay.hist.push({time:39.21719999999995, x:1388.120773674357, y:217.19464240470313, frm:3});
Replay.hist.push({time:39.232899999999944, x:1387.6983428054032, y:217.5637732834777, frm:3});
Replay.hist.push({time:39.25019999999995, x:1387.2377875917568, y:217.97630306534154, frm:3});
Replay.hist.push({time:39.26709999999994, x:1386.7928707040194, y:218.3851109747759, frm:3});
Replay.hist.push({time:39.28359999999994, x:1386.3676913583108, y:218.78551679784417, frm:3});
Replay.hist.push({time:39.30019999999995, x:1385.9452552585622, y:219.19304793825685, frm:3});
Replay.hist.push({time:39.31719999999995, x:1385.5175234414505, y:219.6158544784089, frm:3});
Replay.hist.push({time:39.33379999999994, x:1385.1046367447323, y:220.03399827719957, frm:3});
Replay.hist.push({time:39.35049999999994, x:1384.6940520360617, y:220.45987957489194, frm:3});
Replay.hist.push({time:39.367199999999954, x:1384.2882885751424, y:220.89094870210778, frm:3});
Replay.hist.push({time:39.383799999999944, x:1383.8897502527716, y:221.324532103546, frm:3});
Replay.hist.push({time:39.39949999999994, x:1383.5172291916024, y:221.73924717726902, frm:3});
Replay.hist.push({time:39.41609999999994, x:1383.1280466319827, y:222.1825817352172, frm:3});
Replay.hist.push({time:39.43289999999995, x:1382.739098762697, y:222.63628916662185, frm:3});
Replay.hist.push({time:39.44949999999994, x:1382.3596611082626, y:223.08952416270284, frm:3});
Replay.hist.push({time:39.467099999999945, x:1381.9651436994411, y:223.57231360578507, frm:3});
Replay.hist.push({time:39.48389999999995, x:1381.5987793021548, y:224.03158975271035, frm:3});
Replay.hist.push({time:39.50049999999994, x:1381.2415178077704, y:224.4899912108451, frm:3});
Replay.hist.push({time:39.51719999999995, x:1380.8868782320196, y:224.95571486891754, frm:3});
Replay.hist.push({time:39.53379999999994, x:1380.5391224453792, y:225.4231422291474, frm:3});
Replay.hist.push({time:39.550599999999946, x:1380.1920271429376, y:225.90071529429815, frm:3});
Replay.hist.push({time:39.56719999999995, x:1379.8538697973, y:226.3770242269258, frm:3});
Replay.hist.push({time:39.58379999999994, x:1379.5205066366652, y:226.8576838325295, frm:3});
Replay.hist.push({time:39.60049999999994, x:1379.18998927954, y:227.3455856222236, frm:3});
Replay.hist.push({time:39.61619999999995, x:1378.88371100921, y:227.80821948889368, frm:3});
Replay.hist.push({time:39.633799999999944, x:1378.5455274212363, y:228.3313152051109, frm:2});
Replay.hist.push({time:39.64949999999994, x:1378.2484475219117, y:228.80192720093473, frm:2});
Replay.hist.push({time:39.666199999999954, x:1377.9404870659641, y:229.3012334257968, frm:2});
Replay.hist.push({time:39.68379999999995, x:1377.6263286033573, y:229.82316708994097, frm:2});
Replay.hist.push({time:39.70059999999995, x:1377.3313198900612, y:230.3254094746914, frm:2});
Replay.hist.push({time:39.717199999999956, x:1377.0445125648826, y:230.8254972134871, frm:2});
Replay.hist.push({time:39.73329999999995, x:1376.7708081063467, y:231.31412045800022, frm:2});
Replay.hist.push({time:39.750499999999946, x:1376.4832830141718, y:231.8399868291981, frm:2});
Replay.hist.push({time:39.76719999999996, x:1376.208944283681, y:232.35435777376642, frm:2});
Replay.hist.push({time:39.78389999999996, x:1375.9393787734393, y:232.87242062790605, frm:2});
Replay.hist.push({time:39.80049999999995, x:1375.6761706415703, y:233.39100338801774, frm:2});
Replay.hist.push({time:39.817199999999964, x:1375.4161630287726, y:233.91631414540117, frm:2});
Replay.hist.push({time:39.833899999999964, x:1375.1609686237225, y:234.44520290031198, frm:2});
Replay.hist.push({time:39.849499999999956, x:1374.9257691086166, y:234.94497876484837, frm:2});
Replay.hist.push({time:39.86739999999997, x:1374.6610975731312, y:235.52235568174873, frm:2});
Replay.hist.push({time:39.88389999999997, x:1374.4271211728624, y:236.04677154323088, frm:2});
Replay.hist.push({time:39.90059999999997, x:1374.1937761978345, y:236.58367654991574, frm:2});
Replay.hist.push({time:39.91719999999997, x:1373.9653816120954, y:237.12347546383995, frm:2});
Replay.hist.push({time:39.93389999999997, x:1373.739267185305, y:237.67270308909306, frm:2});
Replay.hist.push({time:39.95059999999997, x:1373.5169117724613, y:238.22813433556178, frm:2});
Replay.hist.push({time:39.96719999999998, x:1373.2997047627564, y:238.7863948833222, frm:2});
Replay.hist.push({time:39.98279999999997, x:1373.0991543073972, y:239.31656671408382, frm:2});
Replay.hist.push({time:40.00049999999997, x:1372.8757994171688, y:239.9248773700738, frm:2});
Replay.hist.push({time:40.01719999999998, x:1372.669334409212, y:240.50519007120587, frm:2});
Replay.hist.push({time:40.03389999999998, x:1372.4670831031262, y:241.0917735809623, frm:2});
Replay.hist.push({time:40.05059999999998, x:1372.2691388491912, y:241.6846391321303, frm:2});
Replay.hist.push({time:40.06699999999998, x:1372.0790404809961, y:242.27295536322916, frm:2});
Replay.hist.push({time:40.08349999999998, x:1371.8981491451561, y:242.85149599563613, frm:2});
Replay.hist.push({time:40.10049999999998, x:1371.7166771294023, y:243.4518583970195, frm:2});
Replay.hist.push({time:40.1172, x:1371.542783000455, y:244.04762639652694, frm:2});
Replay.hist.push({time:40.13379999999999, x:1371.3743120853935, y:244.64574280896684, frm:2});
Replay.hist.push({time:40.15059999999999, x:1371.2083437856638, y:245.2571007554874, frm:2});
Replay.hist.push({time:40.166299999999985, x:1371.0574697701545, y:245.83382990336705, frm:2});
Replay.hist.push({time:40.183799999999984, x:1370.8941460845685, y:246.48307122185878, frm:2});
Replay.hist.push({time:40.200499999999984, x:1370.74320074747, y:247.1087163208638, frm:2});
Replay.hist.push({time:40.2172, x:1370.5971316339933, y:247.7403666307259, frm:2});
Replay.hist.push({time:40.2339, x:1370.4560323465548, y:248.37802557318037, frm:2});
Replay.hist.push({time:40.2506, x:1370.319997182878, y:249.0216958125279, frm:2});
Replay.hist.push({time:40.2672, x:1370.1898908157104, y:249.667463240879, frm:2});
Replay.hist.push({time:40.2839, x:1370.068404063598, y:250.30083302523448, frm:2});
Replay.hist.push({time:40.3006, x:1369.9524365503846, y:250.93684562185456, frm:2});
Replay.hist.push({time:40.3168, x:1369.8447389361502, y:251.5591861178848, frm:2});
Replay.hist.push({time:40.33390000000001, x:1369.7362567485104, y:252.2219478506047, frm:2});
Replay.hist.push({time:40.3505, x:1369.6361616601598, y:252.87096740407404, frm:2});
Replay.hist.push({time:40.36720000000001, x:1369.540731465139, y:253.529543886229, frm:2});
Replay.hist.push({time:40.38390000000001, x:1369.4506773814412, y:254.19377473608517, frm:2});
Replay.hist.push({time:40.40060000000001, x:1369.366091434364, y:254.86365709305818, frm:2});
Replay.hist.push({time:40.417200000000015, x:1369.2875236242485, y:255.53511780343948, frm:2});
Replay.hist.push({time:40.433900000000015, x:1369.2141183917568, y:256.21625786194414, frm:2});
Replay.hist.push({time:40.450500000000005, x:1369.1468492335944, y:256.89889956315625, frm:2});
Replay.hist.push({time:40.46630000000001, x:1369.0881867083967, y:257.55375376483033, frm:2});
Replay.hist.push({time:40.48390000000002, x:1369.0311124799093, y:258.2624790083096, frm:2});
Replay.hist.push({time:40.50060000000002, x:1368.9829227199214, y:258.93709598802116, frm:2});
Replay.hist.push({time:40.51710000000002, x:1368.9408365696804, y:259.60878931740916, frm:2});
Replay.hist.push({time:40.53380000000002, x:1368.9039193745705, y:260.2938655683151, frm:2});
Replay.hist.push({time:40.550600000000024, x:1368.8726361234808, y:260.9883490458121, frm:2});
Replay.hist.push({time:40.56660000000002, x:1368.8483877270041, y:261.6546361467652, frm:2});
Replay.hist.push({time:40.58390000000003, x:1368.8283438644132, y:262.3805653396992, frm:2});
Replay.hist.push({time:40.60000000000002, x:1368.8155453307197, y:263.06108958916957, frm:2});
Replay.hist.push({time:40.616200000000035, x:1368.8084466390944, y:263.75073847071985, frm:1});
Replay.hist.push({time:40.63380000000003, x:1368.8074029859517, y:264.5056468071659, frm:1});
Replay.hist.push({time:40.65060000000003, x:1368.8129827563287, y:265.2315581547709, frm:1});
Replay.hist.push({time:40.66710000000003, x:1368.8248029058793, y:265.94940479112745, frm:1});
Replay.hist.push({time:40.68380000000003, x:1368.8400886542972, y:266.66746193337735, frm:1});
Replay.hist.push({time:40.70060000000004, x:1368.8563585051425, y:267.39190068013505, frm:1});
Replay.hist.push({time:40.71720000000004, x:1368.8733168259323, y:268.1097312064412, frm:1});
Replay.hist.push({time:40.733100000000036, x:1368.8903842682228, y:268.7991234943229, frm:0});
Replay.hist.push({time:40.750600000000034, x:1368.9101093840695, y:269.56012687887323, frm:0});
Replay.hist.push({time:40.76720000000004, x:1368.929728974178, y:270.2839612095705, frm:0});
Replay.hist.push({time:40.78390000000004, x:1368.9503645480384, y:271.01414880997123, frm:0});
Replay.hist.push({time:40.80060000000004, x:1368.9719032770317, y:271.7463147647211, frm:0});
Replay.hist.push({time:40.81710000000004, x:1368.9940733066794, y:272.47162792688806, frm:0});
Replay.hist.push({time:40.83390000000004, x:1369.0175583714042, y:273.21210853731395, frm:0});
Replay.hist.push({time:40.85060000000004, x:1369.04181725523, y:273.95011888833267, frm:0});
Replay.hist.push({time:40.86720000000005, x:1369.0668359960505, y:274.68561107859654, frm:0});
Replay.hist.push({time:40.88390000000005, x:1369.0929188468492, y:275.42744763795514, frm:0});
Replay.hist.push({time:40.89950000000004, x:1369.1181103004005, y:276.12206220464134, frm:0});
Replay.hist.push({time:40.91720000000005, x:1369.1476711907346, y:276.9123352836877, frm:0});
Replay.hist.push({time:40.93390000000005, x:1369.1765091535246, y:277.6598163430936, frm:0});
Replay.hist.push({time:40.95040000000005, x:1369.205986589197, y:278.4020878317601, frm:0});
Replay.hist.push({time:40.967200000000055, x:1369.2371961723736, y:279.16605595139106, frm:0});
Replay.hist.push({time:40.98290000000005, x:1369.2672253874257, y:279.88161589877444, frm:0});
Replay.hist.push({time:41.000200000000056, x:1369.3012907323955, y:280.6720922230429, frm:0});
Replay.hist.push({time:41.01720000000006, x:1369.3357575038567, y:281.4507382859293, frm:0});
Replay.hist.push({time:41.03390000000006, x:1369.3705749009177, y:282.2174407146468, frm:0});
Replay.hist.push({time:41.05050000000005, x:1369.406127396, y:282.98131709802095, frm:0});
Replay.hist.push({time:41.06620000000006, x:1369.440616340691, y:283.70533150358125, frm:0});
Replay.hist.push({time:41.08380000000005, x:1369.4802888228032, y:284.5189560053875, frm:0});
Replay.hist.push({time:41.10000000000005, x:1369.5177386475004, y:285.2694779553499, frm:0});
Replay.hist.push({time:41.11620000000006, x:1369.5560880421417, y:286.02162875742005, frm:0});
Replay.hist.push({time:41.13390000000006, x:1369.5990231853696, y:286.84538068334047, frm:0});
Replay.hist.push({time:41.15050000000005, x:1369.6402631088922, y:287.61959925054174, frm:0});
Replay.hist.push({time:41.167200000000065, x:1369.6827064543463, y:288.40017008992317, frm:0});
Replay.hist.push({time:41.183900000000065, x:1369.726107524509, y:289.18241251381505, frm:0});
Replay.hist.push({time:41.19960000000006, x:1369.767779543009, y:289.9192602330679, frm:0});
Replay.hist.push({time:41.21720000000007, x:1369.8157777747897, y:290.75175975217064, frm:0});
Replay.hist.push({time:41.23370000000007, x:1369.8620512114016, y:291.5389121187445, frm:0});
Replay.hist.push({time:41.25050000000006, x:1369.9101492366667, y:292.3420250913619, frm:0});
Replay.hist.push({time:41.267200000000074, x:1369.9589421091073, y:293.141962962262, frm:0});
Replay.hist.push({time:41.283900000000074, x:1370.0087129996832, y:293.9434926733271, frm:0});
Replay.hist.push({time:41.300200000000075, x:1370.0580701680137, y:294.7247690085461, frm:0});
Replay.hist.push({time:41.31730000000007, x:1370.1106663867245, y:295.54325242184626, frm:0});
Replay.hist.push({time:41.33390000000007, x:1370.1625167366085, y:296.3367005021316, frm:0});
Replay.hist.push({time:41.35050000000006, x:1370.215138346979, y:297.12905295158924, frm:0});
Replay.hist.push({time:41.367200000000075, x:1370.2688464686776, y:297.9250685702554, frm:0});
Replay.hist.push({time:41.383900000000075, x:1370.3233178154594, y:298.7199685947563, frm:0});
Replay.hist.push({time:41.400600000000075, x:1370.3785437176384, y:299.5137496704488, frm:0});
Replay.hist.push({time:41.41620000000008, x:1370.4308068095377, y:300.2542435913248, frm:0});
Replay.hist.push({time:41.433800000000076, x:1370.4905412049882, y:301.08846974214066, frm:0});
Replay.hist.push({time:41.45060000000008, x:1370.548316282284, y:301.88361600324436, frm:0});
Replay.hist.push({time:41.46730000000008, x:1370.6067893868592, y:302.67720170950406, frm:0});
Replay.hist.push({time:41.48380000000008, x:1370.6657375271504, y:303.4663907636824, frm:0});
Replay.hist.push({time:41.500600000000084, x:1370.7264817409414, y:304.2687571938854, frm:0});
Replay.hist.push({time:41.51720000000009, x:1370.7872122909582, y:305.0604128856033, frm:0});
Replay.hist.push({time:41.53380000000008, x:1370.848639200207, y:305.85091114679545, frm:0});
Replay.hist.push({time:41.55050000000008, x:1370.911129982119, y:306.6449990292146, frm:0});
Replay.hist.push({time:41.56730000000008, x:1370.9746882745126, y:307.44265200585494, frm:0});
Replay.hist.push({time:41.583900000000085, x:1371.0381644999989, y:308.22963689502075, frm:0});
Replay.hist.push({time:41.60030000000008, x:1371.1015260038405, y:309.00599259408625, frm:0});
Replay.hist.push({time:41.617300000000085, x:1371.167877999676, y:309.8095350680678, frm:0});
Replay.hist.push({time:41.633800000000086, x:1371.2329254687031, y:310.588268727893, frm:0});
Replay.hist.push({time:41.64950000000008, x:1371.2954034476381, y:311.32817118328416, frm:0});
Replay.hist.push({time:41.66730000000008, x:1371.3669153593487, y:312.1657353823816, frm:0});
Replay.hist.push({time:41.68290000000009, x:1371.4301762471378, y:312.8986813683163, frm:0});
Replay.hist.push({time:41.70060000000009, x:1371.5026039506258, y:313.7289950981749, frm:0});
Replay.hist.push({time:41.71720000000009, x:1371.5716034853288, y:314.5115613680428, frm:0});
Replay.hist.push({time:41.73390000000009, x:1371.6422115524163, y:315.30416402882724, frm:0});
Replay.hist.push({time:41.74950000000008, x:1371.7087214486182, y:316.0434460204536, frm:0});
Replay.hist.push({time:41.767200000000095, x:1371.7848181742575, y:316.8809018687879, frm:0});
Replay.hist.push({time:41.783800000000085, x:1371.8567920966498, y:317.6650467289465, frm:0});
Replay.hist.push({time:41.80060000000009, x:1371.9302203604861, y:318.45737376426234, frm:0});
Replay.hist.push({time:41.81720000000009, x:1372.0033463475374, y:319.2390192219394, frm:0});
Replay.hist.push({time:41.83390000000009, x:1372.0774773631417, y:320.02411377765543, frm:0});
Replay.hist.push({time:41.85060000000009, x:1372.1521659398993, y:320.807942749116, frm:0});
Replay.hist.push({time:41.86730000000009, x:1372.2274033329727, y:321.590502812817, frm:0});
Replay.hist.push({time:41.8839000000001, x:1372.3027255823608, y:322.36711720125794, frm:0});
Replay.hist.push({time:41.89960000000009, x:1372.3744483447367, y:323.10047594431666, frm:0});
Replay.hist.push({time:41.9172000000001, x:1372.4553982136206, y:323.92121896530665, frm:0});
Replay.hist.push({time:41.9339000000001, x:1372.5327377828123, y:324.6986857889777, frm:0});
Replay.hist.push({time:41.95050000000009, x:1372.6101153814752, y:325.47022447018037, frm:0});
Replay.hist.push({time:41.967200000000105, x:1372.6892136204474, y:326.2526043063213, frm:0});
Replay.hist.push({time:41.983900000000105, x:1372.7693519842364, y:327.0389679847916, frm:0});
Replay.hist.push({time:42.000600000000105, x:1372.849986591431, y:327.8239997354173, frm:0});
Replay.hist.push({time:42.01720000000011, x:1372.9306214129062, y:328.60300848031744, frm:0});
Replay.hist.push({time:42.03390000000011, x:1373.012218831888, y:329.38537423052134, frm:0});
Replay.hist.push({time:42.05060000000011, x:1373.0942857578686, y:330.1663979298128, frm:0});
Replay.hist.push({time:42.06720000000011, x:1373.1763178981585, y:330.9414126262291, frm:0});
Replay.hist.push({time:42.08290000000011, x:1373.2543144567114, y:331.67319185975725, frm:0});
Replay.hist.push({time:42.0998000000001, x:1373.3387089404862, y:332.459554174085, frm:0});
Replay.hist.push({time:42.11720000000011, x:1373.4260645847696, y:333.2677256786067, frm:0});
Replay.hist.push({time:42.13390000000011, x:1373.5103412568442, y:334.04200535392573, frm:0});
Replay.hist.push({time:42.15060000000011, x:1373.5950346094826, y:334.81492308578987, frm:0});
Replay.hist.push({time:42.167200000000115, x:1373.679625242393, y:335.5818607084032, frm:0});
Replay.hist.push({time:42.18340000000011, x:1373.7625584659506, y:336.3290186347787, frm:0});
Replay.hist.push({time:42.200500000000105, x:1373.8504967938452, y:337.11627467617336, frm:0});
Replay.hist.push({time:42.216200000000114, x:1373.9328701395953, y:337.8491792011158, frm:0});
Replay.hist.push({time:42.23390000000011, x:1374.0263735553413, y:338.67595278573356, frm:0});
Replay.hist.push({time:42.25060000000011, x:1374.1149857402738, y:339.4545593102574, frm:0});
Replay.hist.push({time:42.26630000000011, x:1374.1986302395655, y:340.18525032241536, frm:0});
Replay.hist.push({time:42.283900000000116, x:1374.2927737542543, y:341.0028413445978, frm:0});
Replay.hist.push({time:42.30060000000012, x:1374.3824655029819, y:341.7771597011065, frm:0});
Replay.hist.push({time:42.31730000000012, x:1374.4724998423335, y:342.55003907439584, frm:0});
Replay.hist.push({time:42.333300000000115, x:1374.559074236223, y:343.2891770005234, frm:0});
Replay.hist.push({time:42.34950000000011, x:1374.647034525993, y:344.03620006201066, frm:0});
Replay.hist.push({time:42.36690000000012, x:1374.7418401555603, y:344.8370254827544, frm:0});
Replay.hist.push({time:42.38290000000012, x:1374.8293140500557, y:345.5720405196432, frm:0});
Replay.hist.push({time:42.40060000000012, x:1374.9263986907379, y:346.38357418893054, frm:0});
Replay.hist.push({time:42.41650000000011, x:1375.013890002583, y:347.1112010781046, frm:0});
Replay.hist.push({time:42.43390000000012, x:1375.1099246183821, y:347.90593573470557, frm:0});
Replay.hist.push({time:42.44980000000012, x:1375.1984546279468, y:348.6350080942546, frm:0});
Replay.hist.push({time:42.46620000000013, x:1375.2913266687774, y:349.3962694404686, frm:0});
Replay.hist.push({time:42.483900000000126, x:1375.3918460504426, y:350.2162098480464, frm:0});
Replay.hist.push({time:42.500500000000116, x:1375.4863816065624, y:350.98364663955914, frm:0});
Replay.hist.push({time:42.51730000000012, x:1375.5823042280826, y:351.7587871690885, frm:0});
Replay.hist.push({time:42.53390000000012, x:1375.6773214812129, y:352.52317682532413, frm:0});
Replay.hist.push({time:42.55050000000011, x:1375.7725645692321, y:353.2860469931886, frm:0});
Replay.hist.push({time:42.56720000000013, x:1375.8686000716068, y:354.05197502440944, frm:0});
Replay.hist.push({time:42.58330000000012, x:1375.9613852822029, y:354.788928885677, frm:0});
Replay.hist.push({time:42.59960000000012, x:1376.0555136747662, y:355.5335696989485, frm:0});
Replay.hist.push({time:42.61720000000013, x:1376.1573532162258, y:356.33592439405214, frm:0});
Replay.hist.push({time:42.63390000000013, x:1376.254174665566, y:357.0956632605627, frm:0});
Replay.hist.push({time:42.649500000000124, x:1376.3447776857531, y:357.8039636241288, frm:0});
Replay.hist.push({time:42.667200000000136, x:1376.4477489006445, y:358.60593752063255, frm:0});
Replay.hist.push({time:42.683900000000136, x:1376.545065153575, y:359.3610020620222, frm:0});
Replay.hist.push({time:42.70020000000014, x:1376.642212114863, y:360.1120548568332, frm:0});
Replay.hist.push({time:42.71730000000013, x:1376.744375656307, y:360.8990671545852, frm:0});
Replay.hist.push({time:42.73380000000013, x:1376.843090794562, y:361.656844903249, frm:0});
Replay.hist.push({time:42.749600000000136, x:1376.937735116881, y:362.3809813115855, frm:0});
Replay.hist.push({time:42.767300000000134, x:1377.043882642089, y:363.1904273050594, frm:0});
Replay.hist.push({time:42.78390000000014, x:1377.1435453998884, y:363.94789974179264, frm:0});
Replay.hist.push({time:42.80050000000013, x:1377.243305560068, y:364.7037412463552, frm:0});
Replay.hist.push({time:42.81720000000014, x:1377.343755540155, y:365.4624857760192, frm:0});
Replay.hist.push({time:42.83290000000014, x:1377.4382650567334, y:366.17429555226624, frm:0});
Replay.hist.push({time:42.850600000000135, x:1377.5448859455687, y:366.97499765936186, frm:0});
Replay.hist.push({time:42.86620000000014, x:1377.6389173927878, y:367.6791733432285, frm:0});
Replay.hist.push({time:42.88290000000014, x:1377.7396284712318, y:368.43137752034653, frm:0});
Replay.hist.push({time:42.900500000000136, x:1377.845812654498, y:369.22230071737556, frm:0});
Replay.hist.push({time:42.916200000000146, x:1377.940568550513, y:369.9262908547364, frm:0});
Replay.hist.push({time:42.93390000000014, x:1378.04939201789, y:370.7327662271826, frm:0});
Replay.hist.push({time:42.95030000000014, x:1378.150835023842, y:371.482660841102, frm:0});
Replay.hist.push({time:42.967300000000144, x:1378.2559962912555, y:372.2581935364812, frm:0});
Replay.hist.push({time:42.98390000000015, x:1378.3586817869787, y:373.0137208501744, frm:0});
Replay.hist.push({time:43.00060000000015, x:1378.4619739857508, y:373.7720379681524, frm:0});
Replay.hist.push({time:43.01730000000015, x:1378.5652447420741, y:374.52858582189276, frm:0});
Replay.hist.push({time:43.03360000000015, x:1378.6660126225408, y:375.26530757608043, frm:0});
Replay.hist.push({time:43.04970000000016, x:1378.7655065276401, y:375.99133081075513, frm:0});
Replay.hist.push({time:43.067200000000156, x:1378.873597049988, y:376.7785938659045, frm:0});
Replay.hist.push({time:43.083900000000156, x:1378.976686423451, y:377.52805080949287, frm:0});
Replay.hist.push({time:43.100600000000156, x:1379.0797064187443, y:378.27572099497706, frm:0});
Replay.hist.push({time:43.11720000000016, x:1379.1820315047776, y:379.0171410589896, frm:0});
Replay.hist.push({time:43.13390000000016, x:1379.284884757298, y:379.76123811221123, frm:0});
Replay.hist.push({time:43.15050000000015, x:1379.3870253623381, y:380.49909964435165, frm:0});
Replay.hist.push({time:43.167200000000165, x:1379.4921176354999, y:381.2572246988936, frm:0});
Replay.hist.push({time:43.183900000000165, x:1379.597403088077, y:382.0157313340163, frm:0});
Replay.hist.push({time:43.200600000000165, x:1379.7025566077882, y:382.772342218074, frm:0});
Replay.hist.push({time:43.21720000000017, x:1379.8069398526852, y:383.5225412006332, frm:0});
Replay.hist.push({time:43.23390000000017, x:1379.911800087465, y:384.2753608855421, frm:0});
Replay.hist.push({time:43.25060000000017, x:1380.0164981508824, y:385.0262737732522, frm:0});
Replay.hist.push({time:43.26720000000017, x:1380.120398789839, y:385.7707979751064, frm:0});
Replay.hist.push({time:43.28310000000017, x:1380.2197504933085, y:386.4821608349488, frm:0});
Replay.hist.push({time:43.30050000000016, x:1380.3282731431316, y:387.25862381667844, frm:0});
Replay.hist.push({time:43.316700000000175, x:1380.429116382411, y:387.97967585119636, frm:0});
Replay.hist.push({time:43.33390000000017, x:1380.5359647195517, y:388.74324329071857, frm:0});
Replay.hist.push({time:43.35050000000016, x:1380.6388637046657, y:389.47824151955314, frm:0});
Replay.hist.push({time:43.367200000000174, x:1380.7421514605974, y:390.2157401375507, frm:0});
Replay.hist.push({time:43.383900000000175, x:1380.8459493012792, y:390.95666718471904, frm:0});
Replay.hist.push({time:43.400500000000164, x:1380.9511407021325, y:391.7073912517243, frm:0});
Replay.hist.push({time:43.41720000000018, x:1381.0566910411094, y:392.4605949018678, frm:0});
Replay.hist.push({time:43.43390000000018, x:1381.1619553607525, y:393.2117468379205, frm:0});
Replay.hist.push({time:43.45060000000018, x:1381.2669230631084, y:393.96084319652925, frm:0});
Replay.hist.push({time:43.46730000000018, x:1381.3715835832222, y:394.7078801324362, frm:0});
Replay.hist.push({time:43.482900000000186, x:1381.46906511402, y:395.4038588253658, frm:0});
Replay.hist.push({time:43.50060000000018, x:1381.579319086395, y:396.19131769852856, frm:0});
Replay.hist.push({time:43.51730000000018, x:1381.6829970586443, y:396.9321658877518, frm:0});
Replay.hist.push({time:43.53290000000019, x:1381.7795324366184, y:397.62235314181464, frm:0});
Replay.hist.push({time:43.54960000000019, x:1381.8825258978957, y:398.35918536060683, frm:0});
Replay.hist.push({time:43.566200000000194, x:1381.9845369782843, y:399.0895433392493, frm:0});
Replay.hist.push({time:43.582900000000194, x:1382.0867841293225, y:399.8222206388667, frm:0});
Replay.hist.push({time:43.60050000000019, x:1382.1941181523575, y:400.59211178036185, frm:0});
Replay.hist.push({time:43.6172000000002, x:1382.2985137006685, y:401.34176113017486, frm:0});
Replay.hist.push({time:43.6339000000002, x:1382.402791596006, y:402.0914756105956, frm:0});
Replay.hist.push({time:43.6506000000002, x:1382.506621825974, y:402.8389634181208, frm:0});
Replay.hist.push({time:43.6673000000002, x:1382.6099931789452, y:403.58422046778395, frm:0});
Replay.hist.push({time:43.68390000000021, x:1382.712279880027, y:404.3228012420536, frm:0});
Replay.hist.push({time:43.7005000000002, x:1382.8140911979717, y:405.0591698980966, frm:0});
Replay.hist.push({time:43.7163000000002, x:1382.9105455818426, y:405.75800103313367, frm:0});
Replay.hist.push({time:43.73390000000021, x:1383.0174553521001, y:406.5340566383553, frm:0});
Replay.hist.push({time:43.75040000000021, x:1383.1171664305375, y:407.2593505772578, frm:0});
Replay.hist.push({time:43.767200000000216, x:1383.2181632090658, y:407.99556680234696, frm:0});
Replay.hist.push({time:43.784000000000205, x:1383.3186173845695, y:408.7294971672293, frm:0});
Replay.hist.push({time:43.79980000000021, x:1383.4125885098201, y:409.41766231495205, frm:0});
Replay.hist.push({time:43.81730000000021, x:1383.516084949032, y:410.1774827804507, frm:0});
Replay.hist.push({time:43.83390000000021, x:1383.6167947744134, y:410.918871584502, frm:0});
Replay.hist.push({time:43.85060000000021, x:1383.7178377096968, y:411.66484190176925, frm:0});
Replay.hist.push({time:43.86720000000022, x:1383.8176494354034, y:412.40393868528895, frm:0});
Replay.hist.push({time:43.88390000000022, x:1383.917420287119, y:413.1450600934295, frm:0});
Replay.hist.push({time:43.90060000000022, x:1384.0165353111852, y:413.8837432535346, frm:0});
Replay.hist.push({time:43.916200000000224, x:1384.1085203901603, y:414.57157577657006, frm:0});
Replay.hist.push({time:43.93390000000022, x:1384.2121669193648, y:415.3493905111664, frm:0});
Replay.hist.push({time:43.95060000000022, x:1384.3092474547575, y:416.08074776539814, frm:0});
Replay.hist.push({time:43.96730000000022, x:1384.4056249083578, y:416.80964963284515, frm:0});
Replay.hist.push({time:43.98400000000022, x:1384.5012875458365, y:417.53609187564405, frm:0});
Replay.hist.push({time:44.000600000000226, x:1384.5956575216549, y:418.25574352343085, frm:0});
Replay.hist.push({time:44.01690000000023, x:1384.6876128685813, y:418.9600198470634, frm:0});
Replay.hist.push({time:44.03390000000023, x:1384.783663629348, y:419.69902871764225, frm:0});
Replay.hist.push({time:44.049600000000225, x:1384.874198094665, y:420.39890382127516, frm:0});
Replay.hist.push({time:44.06620000000023, x:1384.9691211707927, y:421.1363298033906, frm:0});
Replay.hist.push({time:44.08390000000023, x:1385.069412824388, y:421.9197053304389, frm:0});
Replay.hist.push({time:44.10060000000023, x:1385.1631558499976, y:422.6560820740334, frm:0});
Replay.hist.push({time:44.11630000000022, x:1385.2504924789926, y:423.3459342292052, frm:0});
Replay.hist.push({time:44.13380000000022, x:1385.3469189119119, y:424.1120637071741, frm:0});
Replay.hist.push({time:44.14960000000023, x:1385.4331350070515, y:424.80125404252266, frm:0});
Replay.hist.push({time:44.16640000000023, x:1385.5239138108554, y:425.53141116335166, frm:0});
Replay.hist.push({time:44.18390000000023, x:1385.6174821449415, y:426.2890832867554, frm:0});
Replay.hist.push({time:44.200100000000226, x:1385.7031867840487, y:426.9878431322135, frm:0});
Replay.hist.push({time:44.21730000000022, x:1385.7932047903837, y:427.72693979093117, frm:0});
Replay.hist.push({time:44.233900000000226, x:1385.8791198148983, y:428.4375514755694, frm:0});
Replay.hist.push({time:44.250600000000226, x:1385.968192191342, y:429.1799374246119, frm:0});
Replay.hist.push({time:44.26720000000023, x:1386.0556719056594, y:429.91496787477786, frm:0});
Replay.hist.push({time:44.28300000000022, x:1386.1379428954351, y:430.6118830771191, frm:0});
Replay.hist.push({time:44.30060000000023, x:1386.2284279144778, y:431.3850653205028, frm:0});
Replay.hist.push({time:44.31620000000024, x:1386.3076016891398, y:432.0676652899273, frm:0});
Replay.hist.push({time:44.333900000000234, x:1386.3962418360547, y:432.8390026098011, frm:0});
Replay.hist.push({time:44.35070000000024, x:1386.4791926014584, y:433.56805047436205, frm:0});
Replay.hist.push({time:44.36730000000023, x:1386.5600099358044, y:434.2854707540389, frm:0});
Replay.hist.push({time:44.38390000000023, x:1386.639674285105, y:434.99995301868006, frm:0});
Replay.hist.push({time:44.40030000000023, x:1386.7172335603145, y:435.70293927553587, frm:0});
Replay.hist.push({time:44.41730000000023, x:1386.7964138369148, y:436.42860064422916, frm:0});
Replay.hist.push({time:44.43380000000023, x:1386.8726657631905, y:437.1355298552792, frm:0});
Replay.hist.push({time:44.450600000000236, x:1386.9519534032293, y:437.8795917502626, frm:0});
Replay.hist.push({time:44.46640000000024, x:1387.0252746464441, y:438.5763591673962, frm:0});
Replay.hist.push({time:44.48390000000024, x:1387.1050543949325, y:439.34465653098863, frm:0});
Replay.hist.push({time:44.499600000000235, x:1387.1753383708824, y:440.0308855820567, frm:0});
Replay.hist.push({time:44.51730000000023, x:1387.2530913407072, y:440.8010282775313, frm:0});
Replay.hist.push({time:44.53320000000024, x:1387.3215853845404, y:441.4897206215004, frm:0});
Replay.hist.push({time:44.54960000000024, x:1387.3908757838917, y:442.1969354107622, frm:0});
Replay.hist.push({time:44.56730000000024, x:1387.464094456535, y:442.9566231380237, frm:0});
Replay.hist.push({time:44.582900000000244, x:1387.5272690586662, y:443.623121406142, frm:0});
Replay.hist.push({time:44.60050000000024, x:1387.5969956359986, y:444.37157537775, frm:0});
Replay.hist.push({time:44.616300000000244, x:1387.6581832934553, y:445.04036342734776, frm:0});
Replay.hist.push({time:44.63390000000025, x:1387.727301736912, y:445.8105062091624, frm:0});
Replay.hist.push({time:44.65060000000025, x:1387.7919286983265, y:446.54595565614216, frm:0});
Replay.hist.push({time:44.66630000000025, x:1387.8511407735436, y:447.2340372501137, frm:0});
Replay.hist.push({time:44.68390000000026, x:1387.9157171393572, y:448.0015120458938, frm:0});
Replay.hist.push({time:44.69960000000025, x:1387.971702585952, y:448.68271009919795, frm:0});
Replay.hist.push({time:44.71730000000025, x:1388.0329658640176, y:449.44675860297474, frm:0});
Replay.hist.push({time:44.733900000000254, x:1388.088621322102, y:450.1595712609495, frm:0});
Replay.hist.push({time:44.750600000000254, x:1388.1428348969848, y:450.872992990237, frm:0});
Replay.hist.push({time:44.767300000000255, x:1388.195248871003, y:451.5827133759034, frm:0});
Replay.hist.push({time:44.78350000000025, x:1388.2443581147268, y:452.267647194734, frm:0});
Replay.hist.push({time:44.80060000000026, x:1388.2943229521302, y:452.9868310297272, frm:0});
Replay.hist.push({time:44.81730000000026, x:1388.3436025087376, y:453.7211549964576, frm:0});
Replay.hist.push({time:44.83400000000026, x:1388.3909960080407, y:454.45418566126, frm:0});
Replay.hist.push({time:44.85050000000026, x:1388.4357598117122, y:455.17437657266805, frm:0});
Replay.hist.push({time:44.86620000000027, x:1388.4764328933081, y:455.855901176545, frm:0});
Replay.hist.push({time:44.884000000000256, x:1388.5202578793223, y:456.6241204801996, frm:0});
Replay.hist.push({time:44.90060000000026, x:1388.5589187562186, y:457.33630454177177, frm:0});
Replay.hist.push({time:44.91690000000026, x:1388.5947860367294, y:458.03161426829195, frm:0});
Replay.hist.push({time:44.933900000000264, x:1388.6299616969422, y:458.75253862025784, frm:0});
Replay.hist.push({time:44.950600000000264, x:1388.662278523707, y:459.45652412991006, frm:0});
Replay.hist.push({time:44.967300000000265, x:1388.692357334431, y:460.1563197177057, frm:0});
Replay.hist.push({time:44.984000000000265, x:1388.72107583784, y:460.8752650462123, frm:0});
Replay.hist.push({time:45.000500000000265, x:1388.7476956462322, y:461.59923927969584, frm:1});
Replay.hist.push({time:45.01630000000027, x:1388.770824235332, y:462.2881962534398, frm:1});
Replay.hist.push({time:45.03400000000027, x:1388.793964257082, y:463.05496104651456, frm:1});
Replay.hist.push({time:45.05050000000027, x:1388.812877203412, y:463.7649705543782, frm:1});
Replay.hist.push({time:45.067200000000284, x:1388.8293838287352, y:464.47887361649816, frm:1});
Replay.hist.push({time:45.083900000000284, x:1388.8432162005615, y:465.18802908163406, frm:1});
Replay.hist.push({time:45.100600000000284, x:1388.854351438224, y:465.8924280697107, frm:1});
Replay.hist.push({time:45.117300000000284, x:1388.8627667453047, y:466.5920617474704, frm:1});
Replay.hist.push({time:45.13390000000029, x:1388.8684136554393, y:467.2827758153377, frm:2});
Replay.hist.push({time:45.15050000000028, x:1388.8696839818588, y:467.9795725336488, frm:2});
Replay.hist.push({time:45.16730000000028, x:1388.861807802733, y:468.69042490112474, frm:2});
Replay.hist.push({time:45.183900000000286, x:1388.8446949811894, y:469.39509517213537, frm:2});
Replay.hist.push({time:45.200600000000286, x:1388.8181174524811, y:470.1061792529706, frm:2});
Replay.hist.push({time:45.217300000000286, x:1388.782147052186, y:470.81932491930684, frm:2});
Replay.hist.push({time:45.23390000000029, x:1388.7370802478756, y:471.53013100942275, frm:2});
Replay.hist.push({time:45.25060000000029, x:1388.6825385412087, y:472.2450230587554, frm:3});
Replay.hist.push({time:45.26730000000029, x:1388.6202432474274, y:472.9449054292386, frm:3});
Replay.hist.push({time:45.283900000000294, x:1388.549480841083, y:473.6421008005815, frm:3});
Replay.hist.push({time:45.300600000000294, x:1388.4693967172389, y:474.3449026165899, frm:3});
Replay.hist.push({time:45.317300000000294, x:1388.3803881001793, y:475.04901201386286, frm:3});
Replay.hist.push({time:45.3339000000003, x:1388.2830656425563, y:475.7500991904112, frm:3});
Replay.hist.push({time:45.3506000000003, x:1388.1762549609537, y:476.45650543933044, frm:3});
Replay.hist.push({time:45.3673000000003, x:1388.0614282361637, y:477.15851954044814, frm:3});
Replay.hist.push({time:45.382900000000305, x:1387.948408914443, y:477.8029921974078, frm:3});
Replay.hist.push({time:45.4006000000003, x:1387.8112232178314, y:478.53498475244993, frm:3});
Replay.hist.push({time:45.41620000000031, x:1387.6824141907402, y:479.18076143687585, frm:3});
Replay.hist.push({time:45.434000000000296, x:1387.5264077293937, y:479.9181772434158, frm:3});
Replay.hist.push({time:45.4506000000003, x:1387.3722338913065, y:480.6063673346472, frm:3});
Replay.hist.push({time:45.467200000000304, x:1387.20967962029, y:481.2949208775061, frm:3});
Replay.hist.push({time:45.48350000000029, x:1387.0430071625242, y:481.96696402852007, frm:3});
Replay.hist.push({time:45.5006000000003, x:1386.8632331359854, y:482.65844396487313, frm:3});
Replay.hist.push({time:45.5172000000003, x:1386.6806390180577, y:483.32978921646736, frm:3});
Replay.hist.push({time:45.5339000000003, x:1386.4889170138113, y:484.00516977449485, frm:3});
Replay.hist.push({time:45.54970000000031, x:1386.3001108173216, y:484.6440774969726, frm:3});
Replay.hist.push({time:45.5673000000003, x:1386.0813135809399, y:485.35556510594233, frm:3});
Replay.hist.push({time:45.583900000000305, x:1385.8667468584524, y:486.02637186307777, frm:3});
Replay.hist.push({time:45.600500000000295, x:1385.644381214797, y:486.6963622926971, frm:2});
Replay.hist.push({time:45.6173000000003, x:1385.4171683833997, y:487.35706637936414, frm:2});
Replay.hist.push({time:45.63320000000031, x:1385.194998739896, y:487.9819229536676, frm:2});
Replay.hist.push({time:45.6506000000003, x:1384.9439345868486, y:488.6651165127447, frm:2});
Replay.hist.push({time:45.6668000000003, x:1384.7027204736503, y:489.3005720317691, frm:2});
Replay.hist.push({time:45.68290000000031, x:1384.4558675270334, y:489.93142941338436, frm:2});
Replay.hist.push({time:45.700500000000304, x:1384.1778935238729, y:490.6201925435105, frm:2});
Replay.hist.push({time:45.71630000000031, x:1383.9211085964916, y:491.23770154136355, frm:2});
Replay.hist.push({time:45.73290000000031, x:1383.6477002233407, y:491.8769144186716, frm:2});
Replay.hist.push({time:45.749600000000314, x:1383.3688386960584, y:492.51087893817254, frm:2});
Replay.hist.push({time:45.76730000000031, x:1383.0653685107725, y:493.18164246096717, frm:2});
Replay.hist.push({time:45.783900000000315, x:1382.7733516007518, y:493.8095815180935, frm:2});
Replay.hist.push({time:45.79960000000031, x:1382.490571197973, y:494.4023971192071, frm:2});
Replay.hist.push({time:45.81670000000032, x:1382.1752918158782, y:495.04678924065587, frm:2});
Replay.hist.push({time:45.833000000000304, x:1381.8676829068886, y:495.6597422386309, frm:2});
Replay.hist.push({time:45.85060000000031, x:1381.5277944384684, y:496.32006719854905, frm:2});
Replay.hist.push({time:45.86720000000032, x:1381.2075658087156, y:496.9269128293096, frm:2});
Replay.hist.push({time:45.884000000000306, x:1380.8774999101033, y:497.537808159651, frm:2});
Replay.hist.push({time:45.90060000000031, x:1380.544535892779, y:498.13991058850553, frm:2});
Replay.hist.push({time:45.91730000000031, x:1380.2027205910902, y:498.7440497047737, frm:2});
Replay.hist.push({time:45.933900000000314, x:1379.8561474505145, y:499.34293055025853, frm:2});
Replay.hist.push({time:45.950600000000314, x:1379.5006430270437, y:499.94370457654946, frm:2});
Replay.hist.push({time:45.967300000000314, x:1379.1382750202538, y:500.54269792199216, frm:2});
Replay.hist.push({time:45.98350000000031, x:1378.7801959798494, y:501.12200200568293, frm:2});
Replay.hist.push({time:46.00060000000032, x:1378.4050420176754, y:501.7161584130616, frm:2});
Replay.hist.push({time:46.01730000000032, x:1378.0326629021185, y:502.2936151318547, frm:2});
Replay.hist.push({time:46.032900000000325, x:1377.6789418683807, y:502.8313077784174, frm:2});
Replay.hist.push({time:46.05060000000032, x:1377.2707612088955, y:503.43926918975194, frm:2});
Replay.hist.push({time:46.06720000000033, x:1376.8813180105033, y:504.0073801704619, frm:2});
Replay.hist.push({time:46.08390000000033, x:1376.4830623756186, y:504.57682649329604, frm:2});
Replay.hist.push({time:46.10060000000033, x:1376.0783217344608, y:505.1441249428688, frm:2});
Replay.hist.push({time:46.11730000000033, x:1375.6670975608306, y:505.7092200633313, frm:2});
Replay.hist.push({time:46.13390000000033, x:1375.2610785032814, y:506.25645765484086, frm:2});
Replay.hist.push({time:46.15060000000033, x:1374.848936033676, y:506.8015068442087, frm:2});
Replay.hist.push({time:46.167200000000335, x:1374.4331979445676, y:507.3410691519368, frm:2});
Replay.hist.push({time:46.184000000000324, x:1374.0062997875705, y:507.88482237652784, frm:2});
Replay.hist.push({time:46.19990000000033, x:1373.5965657504055, y:508.3972690827453, frm:2});
Replay.hist.push({time:46.21730000000033, x:1373.1418391528634, y:508.955559767697, frm:2});
Replay.hist.push({time:46.23400000000033, x:1372.6991624507486, y:509.4889025837794, frm:2});
Replay.hist.push({time:46.25060000000033, x:1372.253081744566, y:510.0165819638351, frm:2});
Replay.hist.push({time:46.26730000000033, x:1371.8027242998537, y:510.53972671470035, frm:2});
Replay.hist.push({time:46.28390000000034, x:1371.3579846295813, y:511.0472096284657, frm:2});
Replay.hist.push({time:46.30060000000034, x:1370.904837028318, y:511.5552609791625, frm:2});
Replay.hist.push({time:46.31720000000034, x:1370.4487089421386, y:512.0577540016998, frm:2});
Replay.hist.push({time:46.33390000000034, x:1369.9841084285233, y:512.5606969679383, frm:2});
Replay.hist.push({time:46.35050000000033, x:1369.5166000954757, y:513.0580245867739, frm:2});
Replay.hist.push({time:46.36630000000034, x:1369.06634959409, y:513.5289391596929, frm:2});
Replay.hist.push({time:46.38300000000034, x:1368.5848771622848, y:514.0240293004384, frm:2});
Replay.hist.push({time:46.400600000000345, x:1368.0712587305575, y:514.5428080850473, frm:2});
Replay.hist.push({time:46.41620000000035, x:1367.61865745708, y:514.9921805838313, frm:2});
Replay.hist.push({time:46.43340000000035, x:1367.1209325464029, y:515.4781905155062, frm:2});
Replay.hist.push({time:46.45060000000034, x:1366.6175066057572, y:515.9613145008402, frm:2});
Replay.hist.push({time:46.46640000000035, x:1366.150024555807, y:516.4025448272503, frm:2});
Replay.hist.push({time:46.48400000000034, x:1365.623641559054, y:516.8910769895507, frm:2});
Replay.hist.push({time:46.500300000000344, x:1365.130811228365, y:517.340722720628, frm:2});
Replay.hist.push({time:46.51730000000035, x:1364.611377344476, y:517.8067526534616, frm:2});
Replay.hist.push({time:46.53390000000035, x:1364.0987995735527, y:518.2589044798665, frm:2});
Replay.hist.push({time:46.550700000000354, x:1363.5746555641917, y:518.713530149369, frm:2});
Replay.hist.push({time:46.567200000000355, x:1363.0635277384213, y:519.1495316694245, frm:2});
Replay.hist.push({time:46.583900000000355, x:1362.5492201466845, y:519.5811183453292, frm:2});
Replay.hist.push({time:46.600600000000355, x:1362.0299098977146, y:520.0098257095206, frm:2});
Replay.hist.push({time:46.617300000000355, x:1361.5055995275811, y:520.4356195381279, frm:2});
Replay.hist.push({time:46.634000000000356, x:1360.9762915885904, y:520.8584657342816, frm:2});
Replay.hist.push({time:46.65070000000037, x:1360.4419886492562, y:521.2783303279332, frm:2});
Replay.hist.push({time:46.66730000000036, x:1359.9059365578933, y:521.6926931059547, frm:2});
Replay.hist.push({time:46.68400000000036, x:1359.3616812506357, y:522.1065114446649, frm:2});
Replay.hist.push({time:46.700600000000364, x:1358.8157415241517, y:522.5147976575266, frm:2});
Replay.hist.push({time:46.717300000000364, x:1358.2636928581032, y:522.9208688559092, frm:2});
Replay.hist.push({time:46.73390000000037, x:1357.7270697602753, y:523.3091975015444, frm:2});
Replay.hist.push({time:46.75070000000037, x:1357.1793228093336, y:523.6992119915576, frm:2});
Replay.hist.push({time:46.76650000000036, x:1356.659895571923, y:524.0632445157619, frm:2});
Replay.hist.push({time:46.78400000000036, x:1356.079762715048, y:524.4632655367838, frm:2});
Replay.hist.push({time:46.80030000000036, x:1355.5348316413347, y:524.8328401499017, frm:2});
Replay.hist.push({time:46.817300000000365, x:1354.9618146338037, y:525.215144265399, frm:2});
Replay.hist.push({time:46.834000000000366, x:1354.394246357794, y:525.5875554213486, frm:2});
Replay.hist.push({time:46.85070000000038, x:1353.8220618540745, y:525.9568170305995, frm:2});
Replay.hist.push({time:46.86730000000037, x:1353.2487305395791, y:526.320718141307, frm:2});
Replay.hist.push({time:46.88400000000037, x:1352.6739469366632, y:526.6795291997283, frm:2});
Replay.hist.push({time:46.899600000000376, x:1352.1467691124533, y:527.0034274614494, frm:2});
Replay.hist.push({time:46.916300000000376, x:1351.5783057030203, y:527.3472228132831, frm:2});
Replay.hist.push({time:46.934000000000374, x:1350.9711616915683, y:527.7082575529022, frm:2});
Replay.hist.push({time:46.94960000000038, x:1350.432067006583, y:528.0235949236, frm:2});
Replay.hist.push({time:46.96730000000038, x:1349.815927291025, y:528.3780809813613, frm:1});
Replay.hist.push({time:46.983000000000374, x:1349.265391628885, y:528.6895745289596, frm:1});
Replay.hist.push({time:46.99970000000039, x:1348.6756776684156, y:529.0178365137924, frm:1});
Replay.hist.push({time:47.01730000000038, x:1348.0495962922612, y:529.3603361908395, frm:1});
Replay.hist.push({time:47.03290000000039, x:1347.4907012081278, y:529.6609447384084, frm:1});
Replay.hist.push({time:47.04960000000039, x:1346.8886335593124, y:529.979444283535, frm:1});
Replay.hist.push({time:47.06730000000039, x:1346.2705082255272, y:530.3007822421436, frm:1});
Replay.hist.push({time:47.08400000000039, x:1345.683277668875, y:530.6008387052025, frm:1});
Replay.hist.push({time:47.09960000000039, x:1345.1311952763854, y:530.8783697126516, frm:1});
Replay.hist.push({time:47.11730000000039, x:1344.5007005526688, y:531.1899909362962, frm:1});
Replay.hist.push({time:47.13400000000039, x:1343.901808183471, y:531.480813600021, frm:1});
Replay.hist.push({time:47.150700000000406, x:1343.2990265973701, y:531.7685097401575, frm:1});
Replay.hist.push({time:47.167300000000395, x:1342.6960020014803, y:532.051364257429, frm:1});
Replay.hist.push({time:47.184000000000395, x:1342.0854740392201, y:532.3327631538926, frm:1});
Replay.hist.push({time:47.20070000000041, x:1341.4710656834116, y:532.6109725968017, frm:1});
Replay.hist.push({time:47.2163000000004, x:1340.8936136644938, y:532.8679620006667, frm:1});
Replay.hist.push({time:47.23390000000041, x:1340.2473697540204, y:533.1504876273631, frm:1});
Replay.hist.push({time:47.249600000000406, x:1339.683127364548, y:533.3928387237949, frm:1});
Replay.hist.push({time:47.267300000000404, x:1339.0432716127054, y:533.6628581151094, frm:1});
Replay.hist.push({time:47.284000000000404, x:1338.4359075970406, y:533.9145032108693, frm:1});
Replay.hist.push({time:47.29960000000041, x:1337.8653417655833, y:534.1468216588758, frm:1});
Replay.hist.push({time:47.31630000000041, x:1337.248380827496, y:534.393642147028, frm:1});
Replay.hist.push({time:47.33320000000042, x:1336.6175667805223, y:534.641352530329, frm:1});
Replay.hist.push({time:47.35060000000041, x:1335.9612242177634, y:534.8941630179264, frm:1});
Replay.hist.push({time:47.36630000000041, x:1335.3631364807247, y:535.1202202774273, frm:1});
Replay.hist.push({time:47.38300000000041, x:1334.7206053202735, y:535.3585526234244, frm:1});
Replay.hist.push({time:47.400600000000416, x:1334.0363260557974, y:535.6072876262906, frm:1});
Replay.hist.push({time:47.41730000000042, x:1333.3875506089046, y:535.838340610954, frm:1});
Replay.hist.push({time:47.43400000000042, x:1332.753573844669, y:536.0596915037067, frm:1});
Replay.hist.push({time:47.45060000000042, x:1332.1172623795233, y:536.2775089878247, frm:1});
Replay.hist.push({time:47.46730000000042, x:1331.4708944893773, y:536.4943647064712, frm:1});
Replay.hist.push({time:47.48320000000043, x:1330.8497131071542, y:536.6986393740123, frm:1});
Replay.hist.push({time:47.49970000000043, x:1330.1989950001525, y:536.908338221198, frm:1});
Replay.hist.push({time:47.517300000000425, x:1329.4979710864727, y:537.1293991839242, frm:1});
Replay.hist.push({time:47.53390000000043, x:1328.830335175658, y:537.3353143403012, frm:1});
Replay.hist.push({time:47.55060000000043, x:1328.152246541359, y:537.5399005649508, frm:1});
Replay.hist.push({time:47.56730000000043, x:1327.4676730050796, y:537.7418466023441, frm:1});
Replay.hist.push({time:47.58400000000043, x:1326.7765723612956, y:537.9410909661365, frm:1});
Replay.hist.push({time:47.60060000000043, x:1326.085621652589, y:538.135701436026, frm:1});
Replay.hist.push({time:47.61630000000043, x:1325.4536426311477, y:538.3097303168253, frm:1});
Replay.hist.push({time:47.63400000000043, x:1324.7344638816394, y:538.5032096603513, frm:1});
Replay.hist.push({time:47.65070000000044, x:1324.049588853476, y:538.6829984967636, frm:1});
Replay.hist.push({time:47.66730000000043, x:1323.3626264046288, y:538.8590141335781, frm:1});
Replay.hist.push({time:47.68400000000043, x:1322.6652470918134, y:539.0333231603175, frm:1});
Replay.hist.push({time:47.700600000000435, x:1321.9657785944587, y:539.2037767630462, frm:1});
Replay.hist.push({time:47.717300000000435, x:1321.2557404863717, y:539.3723734175431, frm:1});
Replay.hist.push({time:47.734000000000435, x:1320.5392955198765, y:539.5380165391443, frm:1});
Replay.hist.push({time:47.75060000000044, x:1319.8207596877678, y:539.6996798572484, frm:1});
Replay.hist.push({time:47.76720000000044, x:1319.0958162843554, y:539.8583064424065, frm:1});
Replay.hist.push({time:47.78400000000043, x:1318.355560549693, y:540.0156943046437, frm:1});
Replay.hist.push({time:47.80030000000043, x:1317.6592967242775, y:540.1595437414346, frm:1});
Replay.hist.push({time:47.81730000000044, x:1316.9327865816856, y:540.3053642776779, frm:1});
Replay.hist.push({time:47.83400000000044, x:1316.2129189012817, y:540.445588721652, frm:1});
Replay.hist.push({time:47.850000000000435, x:1315.517476232461, y:540.5770683107754, frm:1});
Replay.hist.push({time:47.86730000000044, x:1314.759014672679, y:540.7160427415795, frm:1});
Replay.hist.push({time:47.88360000000044, x:1314.0383261311626, y:540.843868618925, frm:1});
Replay.hist.push({time:47.900600000000445, x:1313.280256297968, y:540.9739268132632, frm:1});
Replay.hist.push({time:47.917300000000445, x:1312.5292054169388, y:541.0983810619883, frm:1});
Replay.hist.push({time:47.934000000000445, x:1311.7717949295736, y:541.2195010653794, frm:1});
Replay.hist.push({time:47.95070000000046, x:1311.0079885676384, y:541.3372281617228, frm:1});
Replay.hist.push({time:47.96730000000045, x:1310.24238856726, y:541.4508283212601, frm:1});
Replay.hist.push({time:47.98400000000045, x:1309.4832794262838, y:541.5591577195953, frm:1});
Replay.hist.push({time:48.000700000000464, x:1308.7381599158768, y:541.6613615682176, frm:1});
Replay.hist.push({time:48.01730000000045, x:1307.9915287434483, y:541.7597103941354, frm:1});
Replay.hist.push({time:48.034000000000454, x:1307.2343438854853, y:541.8553371288532, frm:1});
Replay.hist.push({time:48.04960000000046, x:1306.5215950974361, y:541.941604518009, frm:1});
Replay.hist.push({time:48.066900000000466, x:1305.7247954999439, y:542.033784524554, frm:1});
Replay.hist.push({time:48.08400000000046, x:1304.9307198945676, y:542.1212184396213, frm:1});
Replay.hist.push({time:48.099800000000464, x:1304.1913224730229, y:542.1986937405985, frm:1});
Replay.hist.push({time:48.11730000000046, x:1303.3657343222628, y:542.2807566225457, frm:1});
Replay.hist.push({time:48.13320000000047, x:1302.6097715857195, y:542.3518260208964, frm:1});
Replay.hist.push({time:48.15070000000047, x:1301.7710192360078, y:542.4261669985248, frm:1});
Replay.hist.push({time:48.16630000000046, x:1301.017596453498, y:542.4889369901737, frm:1});
Replay.hist.push({time:48.18400000000046, x:1300.153565679266, y:542.5622314408328, frm:1});
Replay.hist.push({time:48.200700000000474, x:1299.34108077116, y:542.6405473145095, frm:1});
Replay.hist.push({time:48.21730000000046, x:1298.539588146291, y:542.7269357466065, frm:2});
Replay.hist.push({time:48.234000000000464, x:1297.7393751400218, y:542.8223482211488, frm:2});
Replay.hist.push({time:48.25070000000048, x:1296.9452287454897, y:542.9262008470821, frm:2});
Replay.hist.push({time:48.26640000000047, x:1296.2041058210764, y:543.0314538718923, frm:2});
Replay.hist.push({time:48.28400000000047, x:1295.3795684940499, y:543.1581246553116, frm:2});
Replay.hist.push({time:48.30070000000048, x:1294.6032368548774, y:543.2867154446693, frm:2});
Replay.hist.push({time:48.31730000000047, x:1293.8373347680629, y:543.4225498168498, frm:2});
Replay.hist.push({time:48.33400000000047, x:1293.0725791558177, y:543.5671761941121, frm:3});
Replay.hist.push({time:48.350600000000476, x:1292.3180696432476, y:543.7187761125662, frm:3});
Replay.hist.push({time:48.36740000000048, x:1291.552099939941, y:543.8818372790823, frm:3});
Replay.hist.push({time:48.38390000000048, x:1290.7730676317549, y:544.057253909686, frm:3});
Replay.hist.push({time:48.400700000000484, x:1289.9860385370678, y:544.2443904859954, frm:3});
Replay.hist.push({time:48.41730000000047, x:1289.214419677416, y:544.437654427157, frm:3});
Replay.hist.push({time:48.434000000000474, x:1288.4441487380425, y:544.6403612518462, frm:3});
Replay.hist.push({time:48.45070000000049, x:1287.679824852995, y:544.8512711284466, frm:3});
Replay.hist.push({time:48.46730000000048, x:1286.9259051257277, y:545.0689483445734, frm:3});
Replay.hist.push({time:48.48400000000048, x:1286.173242849612, y:545.2959149820058, frm:3});
Replay.hist.push({time:48.499600000000484, x:1285.4753416878723, y:545.5150760301124, frm:3});
Replay.hist.push({time:48.51710000000048, x:1284.6983662117154, y:545.7690287402494, frm:3});
Replay.hist.push({time:48.533900000000486, x:1283.9582693717875, y:546.0207868796955, frm:3});
Replay.hist.push({time:48.55070000000049, x:1283.2237397471722, y:546.2802605883915, frm:3});
Replay.hist.push({time:48.56650000000048, x:1282.5152353569124, y:546.5397006300298, frm:3});
Replay.hist.push({time:48.58400000000048, x:1281.736557320671, y:546.8353144140648, frm:3});
Replay.hist.push({time:48.60020000000049, x:1281.021298903805, y:547.116612948831, frm:3});
Replay.hist.push({time:48.61730000000048, x:1280.2720692201667, y:547.4213915178366, frm:3});
Replay.hist.push({time:48.63350000000048, x:1279.5676485141773, y:547.7174726317253, frm:3});
Replay.hist.push({time:48.65060000000049, x:1278.8297147328224, y:548.0376356053238, frm:3});
Replay.hist.push({time:48.66710000000049, x:1278.1230613320959, y:548.3538969427927, frm:3});
Replay.hist.push({time:48.68400000000048, x:1277.404701568918, y:548.6851766570408, frm:3});
Replay.hist.push({time:48.700700000000495, x:1276.7001590312234, y:549.0197393247307, frm:3});
Replay.hist.push({time:48.71630000000049, x:1276.0467266907267, y:549.3386434156191, frm:3});
Replay.hist.push({time:48.734000000000485, x:1275.3096228310162, y:549.7084055557028, frm:3});
Replay.hist.push({time:48.7507000000005, x:1274.610021365375, y:550.0692549650204, frm:2});
Replay.hist.push({time:48.76730000000049, x:1273.9197181895781, y:550.434826038254, frm:2});
Replay.hist.push({time:48.78400000000049, x:1273.230326624509, y:550.8094127110513, frm:2});
Replay.hist.push({time:48.80060000000049, x:1272.5500298396435, y:551.1884216923153, frm:2});
Replay.hist.push({time:48.81700000000049, x:1271.8827220110002, y:551.5692852755309, frm:2});
Replay.hist.push({time:48.8339000000005, x:1271.1999881129282, y:551.968323720789, frm:2});
Replay.hist.push({time:48.8497000000005, x:1270.5661286737695, y:552.3473245686035, frm:2});
Replay.hist.push({time:48.8673000000005, x:1269.8650593535763, y:552.7761228288185, frm:2});
Replay.hist.push({time:48.8840000000005, x:1269.204614619911, y:553.1893497153378, frm:2});
Replay.hist.push({time:48.90070000000051, x:1268.548755494182, y:553.6086505878525, frm:2});
Replay.hist.push({time:48.91660000000051, x:1267.928120356864, y:554.0136690504636, frm:2});
Replay.hist.push({time:48.93320000000051, x:1267.2835429941188, y:554.4428183348583, frm:2});
Replay.hist.push({time:48.950600000000506, x:1266.612570261734, y:554.898774599591, frm:2});
Replay.hist.push({time:48.966800000000504, x:1265.992079980421, y:555.3288284905148, frm:2});
Replay.hist.push({time:48.9840000000005, x:1265.3376771960054, y:555.7911524300696, frm:2});
Replay.hist.push({time:49.00070000000051, x:1264.7065380772685, y:556.2455784479994, frm:2});
Replay.hist.push({time:49.017400000000514, x:1264.0795107376384, y:556.7053536142216, frm:2});
Replay.hist.push({time:49.0340000000005, x:1263.4602431285246, y:557.1675723989654, frm:2});
Replay.hist.push({time:49.04970000000051, x:1262.8781558296712, y:557.6094095355984, frm:2});
Replay.hist.push({time:49.06730000000051, x:1262.2297462455042, y:558.1099931202568, frm:2});
Replay.hist.push({time:49.08390000000051, x:1261.6220738203574, y:558.5871583338228, frm:2});
Replay.hist.push({time:49.10060000000051, x:1261.0162945197428, y:559.0705590819776, frm:2});
Replay.hist.push({time:49.11730000000051, x:1260.4159180033357, y:559.5572391899478, frm:2});
Replay.hist.push({time:49.13300000000051, x:1259.8547583827626, y:560.0189431252184, frm:2});
Replay.hist.push({time:49.150600000000516, x:1259.2294073697947, y:560.5411925556235, frm:2});
Replay.hist.push({time:49.16740000000052, x:1258.6360555211897, y:561.0442230555221, frm:1});
Replay.hist.push({time:49.183100000000515, x:1258.0846458694596, y:561.5182168095936, frm:1});
Replay.hist.push({time:49.20060000000051, x:1257.4734948002701, y:562.0508658867326, frm:1});
Replay.hist.push({time:49.21730000000051, x:1256.8936099540626, y:562.5633205726137, frm:1});
Replay.hist.push({time:49.23400000000051, x:1256.3169145122306, y:563.0797248467568, frm:1});
Replay.hist.push({time:49.25060000000052, x:1255.746766927976, y:563.5968521471957, frm:1});
Replay.hist.push({time:49.26730000000052, x:1255.1762340455841, y:564.1208313908705, frm:1});
Replay.hist.push({time:49.28380000000052, x:1254.6183857809885, y:564.6393951563163, frm:1});
Replay.hist.push({time:49.29970000000053, x:1254.0856040291058, y:565.1403583466756, frm:1});
Replay.hist.push({time:49.317200000000526, x:1253.502171841088, y:565.6952701928388, frm:1});
Replay.hist.push({time:49.33300000000052, x:1252.9779985365724, y:566.1993951422539, frm:1});
Replay.hist.push({time:49.35070000000053, x:1252.3936650800504, y:566.7675230794392, frm:1});
Replay.hist.push({time:49.36740000000053, x:1251.8450394689412, y:567.3067524808445, frm:1});
Replay.hist.push({time:49.38300000000052, x:1251.3348565193437, y:567.8131845746915, frm:1});
Replay.hist.push({time:49.39990000000053, x:1250.7846305397895, y:568.3646730996326, frm:1});
Replay.hist.push({time:49.41740000000053, x:1250.2175055238763, y:568.9387726047934, frm:1});
Replay.hist.push({time:49.434000000000516, x:1249.681948863711, y:569.4861113216275, frm:1});
Replay.hist.push({time:49.45060000000052, x:1249.1486755138621, y:570.0360395446585, frm:1});
Replay.hist.push({time:49.466300000000516, x:1248.6483315711182, y:570.5563930015837, frm:1});
Replay.hist.push({time:49.483000000000516, x:1248.120881376992, y:571.1094397776185, frm:1});
Replay.hist.push({time:49.49960000000052, x:1247.5986647372065, y:571.6614563955353, frm:1});
Replay.hist.push({time:49.51730000000052, x:1247.0440660176976, y:572.2524464270042, frm:1});
Replay.hist.push({time:49.53400000000052, x:1246.5228247759646, y:572.8122344551572, frm:1});
Replay.hist.push({time:49.55070000000053, x:1246.0034957060368, y:573.3740455533211, frm:1});
Replay.hist.push({time:49.56670000000053, x:1245.5076684647556, y:573.9141272346212, frm:0});
Replay.hist.push({time:49.58340000000053, x:1244.9919117613217, y:574.4796377815383, frm:0});
Replay.hist.push({time:49.60030000000052, x:1244.4717479485778, y:575.0537065647266, frm:0});
Replay.hist.push({time:49.617300000000526, x:1243.9502399777073, y:575.63289167257, frm:0});
Replay.hist.push({time:49.633500000000524, x:1243.4548283553427, y:576.1863468435876, frm:0});
Replay.hist.push({time:49.65070000000053, x:1242.9311521090544, y:576.7746963515049, frm:0});
Replay.hist.push({time:49.66660000000053, x:1242.4508464769854, y:577.3172022372735, frm:0});
Replay.hist.push({time:49.68400000000052, x:1241.926723586943, y:577.9122160542142, frm:0});
Replay.hist.push({time:49.70070000000054, x:1241.4250801995538, y:578.4845284184457, frm:0});
Replay.hist.push({time:49.71740000000054, x:1240.9247498538477, y:579.0579577035422, frm:0});
Replay.hist.push({time:49.73400000000053, x:1240.4286600218184, y:579.6289756876303, frm:0});
Replay.hist.push({time:49.75070000000054, x:1239.9307790270827, y:580.2043747718657, frm:0});
Replay.hist.push({time:49.76730000000053, x:1239.437012467204, y:580.7771809714999, frm:0});
Replay.hist.push({time:49.78400000000053, x:1238.9413569790986, y:581.3542087261911, frm:0});
Replay.hist.push({time:49.800600000000536, x:1238.449691837254, y:581.9284650489132, frm:0});
Replay.hist.push({time:49.81650000000053, x:1237.9796602702995, y:582.4790725950265, frm:0});
Replay.hist.push({time:49.83390000000054, x:1237.4662555333841, y:583.0821521001265, frm:0});
Replay.hist.push({time:49.85060000000054, x:1236.9752515213406, y:583.6603998219092, frm:0});
Replay.hist.push({time:49.86710000000054, x:1236.4911113467751, y:584.2318423504098, frm:0});
Replay.hist.push({time:49.88400000000053, x:1235.9959996452394, y:584.8174038723952, frm:0});
Replay.hist.push({time:49.90060000000054, x:1235.5103713034543, y:585.3927559271243, frm:0});
Replay.hist.push({time:49.91730000000054, x:1235.0224604012772, y:585.9716726992013, frm:0});
Replay.hist.push({time:49.93300000000053, x:1234.5642978045146, y:586.5159492698222, frm:0});
Replay.hist.push({time:49.950700000000545, x:1234.0483550413696, y:587.1294746437079, frm:0});
Replay.hist.push({time:49.967200000000545, x:1233.5678714831697, y:587.701268204834, frm:0});
Replay.hist.push({time:49.984000000000535, x:1233.0790836102565, y:588.2832257256753, frm:0});
Replay.hist.push({time:49.99960000000054, x:1232.6255415806893, y:588.8233447889153, frm:0});
Replay.hist.push({time:50.016400000000544, x:1232.137436061874, y:589.4046214871469, frm:0});
Replay.hist.push({time:50.03400000000054, x:1231.6259537891074, y:590.0135759162453, frm:0});
Replay.hist.push({time:50.05070000000055, x:1231.1399692168918, y:590.5918684252595, frm:0});
Replay.hist.push({time:50.06730000000054, x:1230.6570545526763, y:591.1660670778847, frm:0});
Replay.hist.push({time:50.08340000000055, x:1230.1887852934883, y:591.7222974062931, frm:0});
Replay.hist.push({time:50.10060000000055, x:1229.6885888552051, y:592.3157014679637, frm:0});
Replay.hist.push({time:50.11730000000055, x:1229.2029330926496, y:592.8909677890306, frm:0});
Replay.hist.push({time:50.13400000000055, x:1228.7172276922527, y:593.4652740381238, frm:0});
Replay.hist.push({time:50.15070000000056, x:1228.2314200134742, y:594.0385400116891, frm:0});
Replay.hist.push({time:50.16730000000055, x:1227.7483673641038, y:594.6072640806564, frm:0});
Replay.hist.push({time:50.18400000000055, x:1227.2621992436132, y:595.1782172946716, frm:0});
Replay.hist.push({time:50.200600000000556, x:1226.7786850751354, y:595.7444851656459, frm:0});
Replay.hist.push({time:50.217300000000556, x:1226.2919491181071, y:596.3128096416974, frm:0});
Replay.hist.push({time:50.234000000000556, x:1225.8019148637102, y:596.8831082774695, frm:0});
Replay.hist.push({time:50.25070000000057, x:1225.3107926780085, y:597.4526464724303, frm:0});
Replay.hist.push({time:50.26730000000056, x:1224.822139844122, y:598.0171732555278, frm:0});
Replay.hist.push({time:50.28400000000056, x:1224.3300182136452, y:598.5834088162337, frm:0});
Replay.hist.push({time:50.300700000000575, x:1223.837316902144, y:599.1478685692093, frm:0});
Replay.hist.push({time:50.317300000000564, x:1223.3469390440098, y:599.7071106354525, frm:0});
Replay.hist.push({time:50.334000000000565, x:1222.8529261984086, y:600.2677922858222, frm:0});
Replay.hist.push({time:50.35070000000058, x:1222.3581778853818, y:600.8264607916435, frm:0});
Replay.hist.push({time:50.36690000000058, x:1221.8774870487018, y:601.3664086372337, frm:0});
Replay.hist.push({time:50.38400000000057, x:1221.3692425080599, y:601.9341309637509, frm:0});
Replay.hist.push({time:50.40030000000057, x:1220.8839018887813, y:602.4731065654337, frm:0});
Replay.hist.push({time:50.41670000000058, x:1220.3931350958233, y:603.0148539184879, frm:0});
Replay.hist.push({time:50.43400000000057, x:1219.8701913330947, y:603.5883974403879, frm:0});
Replay.hist.push({time:50.45070000000059, x:1219.364291098931, y:604.1394742681872, frm:0});
Replay.hist.push({time:50.46740000000059, x:1218.8572683049165, y:604.6879355924252, frm:0});
Replay.hist.push({time:50.48370000000059, x:1218.3612541128643, y:605.2206648037117, frm:0});
Replay.hist.push({time:50.50070000000059, x:1217.8426993868009, y:605.7734507245381, frm:1});
Replay.hist.push({time:50.51740000000059, x:1217.332003353211, y:606.3136054733448, frm:1});
Replay.hist.push({time:50.53330000000059, x:1216.8445300659055, y:606.8251668636217, frm:1});
Replay.hist.push({time:50.550700000000596, x:1216.3096460330705, y:607.38184941855, frm:1});
Replay.hist.push({time:50.567400000000596, x:1215.7948120244037, y:607.9129896390986, frm:1});
Replay.hist.push({time:50.584000000000586, x:1215.281588628109, y:608.437811331489, frm:1});
Replay.hist.push({time:50.6007000000006, x:1214.7637430796808, y:608.9625584470102, frm:1});
Replay.hist.push({time:50.61630000000059, x:1214.2729368807436, y:609.4553770166992, frm:1});
Replay.hist.push({time:50.63400000000059, x:1213.713304560564, y:610.0118359480696, frm:1});
Replay.hist.push({time:50.649600000000596, x:1213.218436297987, y:610.4989692097627, frm:1});
Replay.hist.push({time:50.6664000000006, x:1212.6837652880924, y:611.0200066626376, frm:1});
Replay.hist.push({time:50.684000000000594, x:1212.1216420601645, y:611.5618047983708, frm:1});
Replay.hist.push({time:50.6998000000006, x:1211.6152030781682, y:612.0446047770764, frm:1});
Replay.hist.push({time:50.71740000000061, x:1211.049032485658, y:612.5782967052047, frm:1});
Replay.hist.push({time:50.7340000000006, x:1210.512988241661, y:613.077637034181, frm:1});
Replay.hist.push({time:50.75070000000061, x:1209.9716705564072, y:613.5759513391266, frm:1});
Replay.hist.push({time:50.76740000000061, x:1209.4282501288326, y:614.0701417449253, frm:1});
Replay.hist.push({time:50.78360000000061, x:1208.8990377643368, y:614.5455250031894, frm:1});
Replay.hist.push({time:50.80070000000062, x:1208.3346173590105, y:615.0460826111163, frm:1});
Replay.hist.push({time:50.81730000000061, x:1207.7809846964794, y:615.5305547794663, frm:1});
Replay.hist.push({time:50.83400000000061, x:1207.221654772342, y:616.013412556638, frm:1});
Replay.hist.push({time:50.850000000000605, x:1206.683492130411, y:616.4716975926684, frm:1});
Replay.hist.push({time:50.86730000000061, x:1206.0990597569505, y:616.9623491336762, frm:1});
Replay.hist.push({time:50.88400000000061, x:1205.5323192876572, y:617.4311178320065, frm:1});
Replay.hist.push({time:50.900700000000626, x:1204.962999125853, y:617.8950215025017, frm:2});
Replay.hist.push({time:50.917400000000626, x:1204.3910471960805, y:618.353980828514, frm:2});
Replay.hist.push({time:50.934000000000616, x:1203.8198597299001, y:618.8052143797725, frm:2});
Replay.hist.push({time:50.95070000000063, x:1203.242505257966, y:619.2540789026502, frm:2});
Replay.hist.push({time:50.966400000000625, x:1202.6971740232884, y:619.6713489346932, frm:2});
Replay.hist.push({time:50.98380000000062, x:1202.0888977038485, y:620.129094790432, frm:2});
Replay.hist.push({time:50.99980000000062, x:1201.5234197084658, y:620.5473554363701, frm:2});
Replay.hist.push({time:51.01740000000063, x:1200.898243211533, y:621.0016066074685, frm:2});
Replay.hist.push({time:51.03370000000063, x:1200.3162229260947, y:621.4167931845362, frm:2});
Replay.hist.push({time:51.04960000000062, x:1199.745645586183, y:621.8166064948905, frm:2});
Replay.hist.push({time:51.067400000000625, x:1199.103526481515, y:622.2580211931904, frm:2});
Replay.hist.push({time:51.084000000000614, x:1198.501415975733, y:622.6637387138439, frm:2});
Replay.hist.push({time:51.09990000000062, x:1197.921683164866, y:623.0468977636847, frm:2});
Replay.hist.push({time:51.116400000000624, x:1197.3169212209866, y:623.4387963393822, frm:2});
Replay.hist.push({time:51.13400000000062, x:1196.668252859751, y:623.850314541332, frm:2});
Replay.hist.push({time:51.15070000000063, x:1196.0492574505688, y:624.2345103680104, frm:2});
Replay.hist.push({time:51.16740000000063, x:1195.4268130105893, y:624.6125081672835, frm:2});
Replay.hist.push({time:51.18400000000062, x:1194.8062433030213, y:624.981075213308, frm:2});
Replay.hist.push({time:51.20070000000064, x:1194.178454934604, y:625.3455400742855, frm:2});
Replay.hist.push({time:51.21740000000064, x:1193.547087044286, y:625.7036113756674, frm:2});
Replay.hist.push({time:51.23400000000063, x:1192.9159023397401, y:626.0531281618082, frm:2});
Replay.hist.push({time:51.25040000000064, x:1192.2887516040505, y:626.3920858995714, frm:2});
Replay.hist.push({time:51.26740000000064, x:1191.634868340611, y:626.7367040051413, frm:2});
Replay.hist.push({time:51.28400000000063, x:1190.9925905810478, y:627.0665228639582, frm:2});
Replay.hist.push({time:51.29970000000064, x:1190.3816496191391, y:627.3723126838829, frm:2});
Replay.hist.push({time:51.317300000000635, x:1189.6927243041207, y:627.7079116630672, frm:2});
Replay.hist.push({time:51.334000000000636, x:1189.0349920337317, y:628.0192536759, frm:2});
Replay.hist.push({time:51.35070000000065, x:1188.373289265175, y:628.3236067918505, frm:2});
Replay.hist.push({time:51.36730000000064, x:1187.7190488982123, y:628.6158458452165, frm:3});
Replay.hist.push({time:51.38380000000064, x:1187.0695335939681, y:628.8974986455303, frm:3});
Replay.hist.push({time:51.39990000000065, x:1186.4320372513587, y:629.1657896275321, frm:3});
Replay.hist.push({time:51.417300000000644, x:1185.7388987308811, y:629.4484083357864, frm:3});
Replay.hist.push({time:51.43410000000065, x:1185.0654872473824, y:629.7139887617179, frm:3});
Replay.hist.push({time:51.45070000000065, x:1184.3960233559187, y:629.969299777202, frm:3});
Replay.hist.push({time:51.46740000000065, x:1183.7184038388332, y:630.2189508371496, frm:3});
Replay.hist.push({time:51.48410000000065, x:1183.0366038792208, y:630.4613152944464, frm:3});
Replay.hist.push({time:51.49990000000066, x:1182.3876498387, y:630.6838522950928, frm:3});
Replay.hist.push({time:51.51730000000065, x:1181.6685769828741, y:630.921234350732, frm:3});
Replay.hist.push({time:51.53400000000065, x:1180.9740228967466, y:631.1414261214077, frm:3});
Replay.hist.push({time:51.55070000000067, x:1180.2751129536264, y:631.3540642695343, frm:3});
Replay.hist.push({time:51.56740000000067, x:1179.5980999898063, y:631.5515775700817, frm:3});
Replay.hist.push({time:51.58400000000066, x:1178.921676564131, y:631.7406824545271, frm:3});
Replay.hist.push({time:51.600000000000655, x:1178.265831153108, y:631.9162491408931, frm:3});
Replay.hist.push({time:51.617600000000664, x:1177.5399973967906, y:632.101704730876, frm:3});
Replay.hist.push({time:51.63400000000066, x:1176.8594319015563, y:632.2672357285853, frm:3});
Replay.hist.push({time:51.65050000000066, x:1176.1705842934641, y:632.4266287576703, frm:3});
Replay.hist.push({time:51.666700000000674, x:1175.4901892783707, y:632.5760966099151, frm:3});
Replay.hist.push({time:51.683200000000674, x:1174.7930179538482, y:632.7211193986178, frm:3});
Replay.hist.push({time:51.70070000000067, x:1174.04895643769, y:632.8669148901029, frm:3});
Replay.hist.push({time:51.71730000000066, x:1173.3386896098207, y:632.9975317400092, frm:3});
Replay.hist.push({time:51.734100000000666, x:1172.6154073402863, y:633.1220485774659, frm:3});
Replay.hist.push({time:51.75070000000067, x:1171.8962893914977, y:633.2374456514674, frm:3});
Replay.hist.push({time:51.76730000000066, x:1171.2071480144587, y:633.3402568801575, frm:3});
Replay.hist.push({time:51.78410000000066, x:1170.5143564416753, y:633.4360256682489, frm:3});
Replay.hist.push({time:51.80070000000067, x:1169.8258066804908, y:633.5237519948143, frm:2});
Replay.hist.push({time:51.81740000000067, x:1169.129064705205, y:633.6050378276668, frm:2});
Replay.hist.push({time:51.83300000000066, x:1168.4745126414066, y:633.6746163801456, frm:2});
Replay.hist.push({time:51.85070000000067, x:1167.727522976008, y:633.7460782323402, frm:2});
Replay.hist.push({time:51.86740000000067, x:1167.0184549381968, y:633.806166534172, frm:2});
Replay.hist.push({time:51.88410000000067, x:1166.3052109788684, y:633.8590851172175, frm:2});
Replay.hist.push({time:51.900700000000676, x:1165.5920692690995, y:633.9045372795441, frm:2});
Replay.hist.push({time:51.917400000000676, x:1164.8704109264243, y:633.9430262363294, frm:2});
Replay.hist.push({time:51.934000000000665, x:1164.1488475129129, y:633.9740474320483, frm:2});
Replay.hist.push({time:51.95070000000068, x:1163.4186589336264, y:633.9979306039589, frm:2});
Replay.hist.push({time:51.96730000000067, x:1162.6885591018242, y:634.0143464095077, frm:2});
Replay.hist.push({time:51.98400000000067, x:1161.9618271820443, y:634.0238018533151, frm:2});
Replay.hist.push({time:52.000700000000684, x:1161.2429827699202, y:634.0274458901238, frm:2});
Replay.hist.push({time:52.016500000000676, x:1160.565111916703, y:634.0256997075029, frm:2});
Replay.hist.push({time:52.034100000000684, x:1159.8123352459709, y:634.0178438830221, frm:2});
Replay.hist.push({time:52.05070000000069, x:1159.1047583333334, y:634.0047645975817, frm:2});
Replay.hist.push({time:52.06730000000068, x:1158.399442411557, y:633.9862193392767, frm:2});
Replay.hist.push({time:52.08400000000068, x:1157.6816968865446, y:633.9616894064657, frm:2});
Replay.hist.push({time:52.10080000000068, x:1156.943663496895, y:633.9305041270497, frm:2});
Replay.hist.push({time:52.11690000000069, x:1156.2387929340068, y:633.8950696199436, frm:2});
Replay.hist.push({time:52.134100000000686, x:1155.4881951824, y:633.8512631358298, frm:2});
Replay.hist.push({time:52.15000000000068, x:1154.7966938387285, y:633.8053620135436, frm:2});
Replay.hist.push({time:52.16730000000069, x:1154.0466406132525, y:633.7495675593499, frm:2});
Replay.hist.push({time:52.18410000000069, x:1153.3119892953214, y:633.6888676244474, frm:2});
Replay.hist.push({time:52.199800000000685, x:1152.6119948439737, y:633.625471582707, frm:2});
Replay.hist.push({time:52.217300000000684, x:1151.8341899118818, y:633.5486837764979, frm:2});
Replay.hist.push({time:52.234000000000684, x:1151.0944807508645, y:633.4694842624796, frm:2});
Replay.hist.push({time:52.2507000000007, x:1150.357135024274, y:633.3845774372293, frm:2});
Replay.hist.push({time:52.2674000000007, x:1149.622092372569, y:633.29404619336, frm:2});
Replay.hist.push({time:52.283600000000696, x:1148.8980545443576, y:633.199159153796, frm:2});
Replay.hist.push({time:52.3007000000007, x:1148.127635479098, y:633.0920175403829, frm:2});
Replay.hist.push({time:52.3174000000007, x:1147.3775986168555, y:632.9816477630374, frm:2});
Replay.hist.push({time:52.3341000000007, x:1146.629788332213, y:632.8657119500342, frm:2});
Replay.hist.push({time:52.35070000000071, x:1145.8885908632244, y:632.7450606407939, frm:2});
Replay.hist.push({time:52.36740000000071, x:1145.1415586211644, y:632.6177505887727, frm:2});
Replay.hist.push({time:52.3831000000007, x:1144.4271487746746, y:632.4907137655446, frm:2});
Replay.hist.push({time:52.40070000000071, x:1143.6282907033606, y:632.342636991837, frm:1});
Replay.hist.push({time:52.41710000000071, x:1142.8859317568222, y:632.1994323630325, frm:1});
Replay.hist.push({time:52.4340000000007, x:1142.1227694964348, y:632.0467016998437, frm:1});
Replay.hist.push({time:52.450700000000715, x:1141.370408791516, y:631.8907782839698, frm:1});
Replay.hist.push({time:52.46710000000071, x:1140.6191234358153, y:631.7298972397886, frm:1});
Replay.hist.push({time:52.483900000000716, x:1139.8504824304084, y:631.56007998266, frm:1});
Replay.hist.push({time:52.50080000000071, x:1139.078811555293, y:631.3844335669439, frm:1});
Replay.hist.push({time:52.51740000000071, x:1138.3222536449932, y:631.2073641737655, frm:1});
Replay.hist.push({time:52.5340000000007, x:1137.5669549518661, y:631.0259488214963, frm:1});
Replay.hist.push({time:52.55070000000072, x:1136.7975023076572, y:630.8365415031753, frm:1});
Replay.hist.push({time:52.56740000000072, x:1136.0280835830706, y:630.6426953669776, frm:1});
Replay.hist.push({time:52.58300000000071, x:1135.3102694447944, y:630.4580149982751, frm:1});
Replay.hist.push({time:52.60060000000072, x:1134.501129950969, y:630.2456141065451, frm:1});
Replay.hist.push({time:52.61740000000072, x:1133.729333453019, y:630.0390654845858, frm:1});
Replay.hist.push({time:52.63390000000072, x:1132.9632562980582, y:629.8304601473772, frm:1});
Replay.hist.push({time:52.650700000000725, x:1132.1836199249553, y:629.6147310855246, frm:1});
Replay.hist.push({time:52.66680000000072, x:1131.4367311817691, y:629.405047245651, frm:1});
Replay.hist.push({time:52.684000000000715, x:1130.6388057706565, y:629.1780348866004, frm:0});
Replay.hist.push({time:52.70070000000073, x:1129.8625044342477, y:628.9544656014928, frm:0});
Replay.hist.push({time:52.71740000000073, x:1129.0812935987472, y:628.7270606339689, frm:0});
Replay.hist.push({time:52.73410000000073, x:1128.2996439469464, y:628.4973777602621, frm:0});
Replay.hist.push({time:52.749700000000736, x:1127.568990176452, y:628.2809895599902, frm:0});
Replay.hist.push({time:52.766400000000736, x:1126.7859736749326, y:628.0475593601461, frm:0});
Replay.hist.push({time:52.783100000000736, x:1126.000147547085, y:627.811990349519, frm:0});
Replay.hist.push({time:52.800700000000745, x:1125.1688209772515, y:627.5617027326689, frm:0});
Replay.hist.push({time:52.81640000000074, x:1124.4260667789697, y:627.3374373994727, frm:0});
Replay.hist.push({time:52.834000000000735, x:1123.5916376831149, y:627.0851073983964, frm:0});
Replay.hist.push({time:52.85070000000075, x:1122.7981051364657, y:626.8450978495578, frm:0});
Replay.hist.push({time:52.866400000000745, x:1122.0494306150006, y:626.6189116098305, frm:0});
Replay.hist.push({time:52.883100000000745, x:1121.2508492840436, y:626.3782348091335, frm:0});
Replay.hist.push({time:52.900700000000754, x:1120.4065554121908, y:626.1247987449315, frm:0});
Replay.hist.push({time:52.917400000000754, x:1119.602782593192, y:625.8848463961809, frm:0});
Replay.hist.push({time:52.933700000000755, x:1118.8162461580728, y:625.6516111475107, frm:0});
Replay.hist.push({time:52.95070000000076, x:1117.993726663679, y:625.4097052204194, frm:0});
Replay.hist.push({time:52.966900000000756, x:1117.2067740771815, y:625.1804889332802, frm:0});
Replay.hist.push({time:52.98410000000075, x:1116.3675324610306, y:624.9387863910259, frm:0});
Replay.hist.push({time:53.000700000000755, x:1115.553806380257, y:624.7074659160728, frm:0});
Replay.hist.push({time:53.017400000000755, x:1114.73560048291, y:624.4782069272735, frm:1});
Replay.hist.push({time:53.034000000000745, x:1113.9207410470399, y:624.2535203385576, frm:1});
Replay.hist.push({time:53.050100000000754, x:1113.1285582551823, y:624.0388504986671, frm:1});
Replay.hist.push({time:53.06740000000076, x:1112.2750692040322, y:623.8120311248922, frm:1});
Replay.hist.push({time:53.08410000000076, x:1111.4524375589863, y:623.5980895842572, frm:1});
Replay.hist.push({time:53.100700000000764, x:1110.6362008030233, y:623.3906275887622, frm:1});
Replay.hist.push({time:53.117400000000764, x:1109.8122502082401, y:623.1863375488813, frm:1});
Replay.hist.push({time:53.13400000000075, x:1108.9902386754309, y:622.987922028639, frm:1});
Replay.hist.push({time:53.15070000000077, x:1108.1600509580217, y:622.7932488918534, frm:1});
Replay.hist.push({time:53.16740000000077, x:1107.337100128746, y:622.6061753700624, frm:1});
Replay.hist.push({time:53.18400000000076, x:1106.5162550153082, y:622.4256534762524, frm:1});
Replay.hist.push({time:53.20080000000076, x:1105.6817404443586, y:622.2485541638085, frm:1});
Replay.hist.push({time:53.217400000000765, x:1104.8532192150374, y:622.0793370909453, frm:2});
Replay.hist.push({time:53.234000000000755, x:1104.0255839898105, y:621.917061501536, frm:2});
Replay.hist.push({time:53.25070000000077, x:1103.1990411325032, y:621.7619142051267, frm:2});
Replay.hist.push({time:53.26730000000076, x:1102.3730941097604, y:621.6139381733195, frm:2});
Replay.hist.push({time:53.28410000000076, x:1101.5325883467585, y:621.4707476526291, frm:2});
Replay.hist.push({time:53.30070000000077, x:1100.697342859331, y:621.33597868502, frm:2});
Replay.hist.push({time:53.317300000000756, x:1099.8694259052131, y:621.2099178374301, frm:2});
Replay.hist.push({time:53.333500000000754, x:1099.0639902097155, y:621.0945739918675, frm:2});
Replay.hist.push({time:53.349700000000766, x:1098.2537829050411, y:620.9858959687942, frm:2});
Replay.hist.push({time:53.366400000000766, x:1097.4134111606245, y:620.8810457644681, frm:2});
Replay.hist.push({time:53.38400000000076, x:1096.5218969092693, y:620.7786661707653, frm:2});
Replay.hist.push({time:53.400700000000775, x:1095.689694820861, y:620.6913913749008, frm:2});
Replay.hist.push({time:53.417400000000775, x:1094.857058744312, y:620.6121421012302, frm:2});
Replay.hist.push({time:53.434100000000775, x:1094.0188334423965, y:620.5405613475416, frm:2});
Replay.hist.push({time:53.45070000000078, x:1093.1799310304623, y:620.4771972932258, frm:2});
Replay.hist.push({time:53.46730000000077, x:1092.335195470877, y:620.4217849294974, frm:2});
Replay.hist.push({time:53.48410000000077, x:1091.4982125223146, y:620.3752057089312, frm:2});
Replay.hist.push({time:53.500700000000776, x:1090.6708673124588, y:620.3373167448634, frm:2});
Replay.hist.push({time:53.516900000000774, x:1089.857813532875, y:620.3079826946858, frm:2});
Replay.hist.push({time:53.53410000000077, x:1088.98834122493, y:620.2852739814113, frm:2});
Replay.hist.push({time:53.550700000000774, x:1088.1429562783596, y:620.2717651843466, frm:2});
Replay.hist.push({time:53.567400000000774, x:1087.3082462896741, y:620.266699789282, frm:2});
Replay.hist.push({time:53.58400000000076, x:1086.484515543585, y:620.2697364809326, frm:2});
Replay.hist.push({time:53.600500000000764, x:1085.6597952825855, y:620.2807450948769, frm:2});
Replay.hist.push({time:53.61660000000077, x:1084.8492325377788, y:620.2993014951982, frm:2});
Replay.hist.push({time:53.63370000000078, x:1083.9819058501603, y:620.3276107451223, frm:3});
Replay.hist.push({time:53.65070000000078, x:1083.107350681147, y:620.3650275358895, frm:3});
Replay.hist.push({time:53.66740000000078, x:1082.2333166580604, y:620.4128824128587, frm:3});
Replay.hist.push({time:53.68390000000078, x:1081.3767481458942, y:620.4706968927651, frm:3});
Replay.hist.push({time:53.700700000000786, x:1080.511702753436, y:620.5402331043169, frm:3});
Replay.hist.push({time:53.71740000000079, x:1079.6588435074425, y:620.6199411522358, frm:3});
Replay.hist.push({time:53.733600000000784, x:1078.838166270807, y:620.7072675327584, frm:3});
Replay.hist.push({time:53.74970000000079, x:1078.0289948831319, y:620.8037388471797, frm:3});
Replay.hist.push({time:53.76640000000079, x:1077.1964065109191, y:620.913923356256, frm:3});
Replay.hist.push({time:53.78310000000079, x:1076.3706186643906, y:621.0343282700074, frm:3});
Replay.hist.push({time:53.80030000000079, x:1075.5271635532097, y:621.1689321768829, frm:3});
Replay.hist.push({time:53.817400000000795, x:1074.695645503039, y:621.3133212654311, frm:3});
Replay.hist.push({time:53.834100000000795, x:1073.8902873860893, y:621.4644172310751, frm:3});
Replay.hist.push({time:53.850800000000795, x:1073.0915087071114, y:621.6253931813712, frm:3});
Replay.hist.push({time:53.867300000000796, x:1072.308702798432, y:621.7940633039244, frm:3});
Replay.hist.push({time:53.8841000000008, x:1071.518158042741, y:621.9755420859102, frm:3});
Replay.hist.push({time:53.9008000000008, x:1070.7387546696855, y:622.1656005273799, frm:3});
Replay.hist.push({time:53.9175000000008, x:1069.9288320409355, y:622.3750095721867, frm:3});
Replay.hist.push({time:53.934100000000804, x:1069.1144539493303, y:622.5980117829364, frm:3});
Replay.hist.push({time:53.95070000000081, x:1068.3071112387158, y:622.8316099554181, frm:3});
Replay.hist.push({time:53.96720000000081, x:1067.5115400711409, y:623.0742049598127, frm:3});
Replay.hist.push({time:53.9840000000008, x:1066.7085200692602, y:623.3317673951096, frm:3});
Replay.hist.push({time:53.9998000000008, x:1065.9596870979437, y:623.583633036516, frm:3});
Replay.hist.push({time:54.01670000000081, x:1065.1655343247858, y:623.8632657436494, frm:3});
Replay.hist.push({time:54.0340000000008, x:1064.359804048986, y:624.1603699671053, frm:3});
Replay.hist.push({time:54.050700000000816, x:1063.5888693671895, y:624.4574875530834, frm:3});
Replay.hist.push({time:54.067400000000816, x:1062.8246067819337, y:624.7646390444797, frm:3});
Replay.hist.push({time:54.084000000000806, x:1062.071468987188, y:625.0797988903373, frm:3});
Replay.hist.push({time:54.10080000000081, x:1061.3158437288366, y:625.4086524322167, frm:3});
Replay.hist.push({time:54.11750000000081, x:1060.5712165436078, y:625.7453192736397, frm:3});
Replay.hist.push({time:54.13400000000081, x:1059.841809139195, y:626.0874267454438, frm:3});
Replay.hist.push({time:54.150700000000825, x:1059.1094733765703, y:626.4433719328771, frm:3});
Replay.hist.push({time:54.16680000000082, x:1058.3955808304227, y:626.8025640856408, frm:3});
Replay.hist.push({time:54.184100000000825, x:1057.6352048249178, y:627.1986152146892, frm:3});
Replay.hist.push({time:54.20070000000083, x:1056.912059843942, y:627.588372063048, frm:3});
Replay.hist.push({time:54.21740000000083, x:1056.190888207954, y:627.9899865517982, frm:3});
Replay.hist.push({time:54.23360000000083, x:1055.4973069586063, y:628.3885983259288, frm:3});
Replay.hist.push({time:54.249800000000825, x:1054.8095770980435, y:628.7959987001991, frm:3});
Replay.hist.push({time:54.26740000000083, x:1054.0689899799713, y:629.2484508769584, frm:3});
Replay.hist.push({time:54.28310000000083, x:1053.4140416494215, y:629.6606355693305, frm:3});
Replay.hist.push({time:54.30060000000083, x:1052.6902942661973, y:630.1294789698368, frm:3});
Replay.hist.push({time:54.316800000000825, x:1052.0261301115463, y:630.5722504448752, frm:3});
Replay.hist.push({time:54.33400000000082, x:1051.327045898323, y:631.0514482656273, frm:3});
Replay.hist.push({time:54.35040000000083, x:1050.6662266950402, y:631.5169949374174, frm:3});
Replay.hist.push({time:54.367400000000835, x:1049.987102326638, y:632.0083620677487, frm:3});
Replay.hist.push({time:54.384100000000835, x:1049.325708423119, y:632.4996745983168, frm:3});
Replay.hist.push({time:54.400800000000835, x:1048.6761307705988, y:632.9946538814808, frm:3});
Replay.hist.push({time:54.41700000000083, x:1048.0556962614883, y:633.479099638267, frm:3});
Replay.hist.push({time:54.433200000000845, x:1047.4402791748341, y:633.971046523916, frm:3});
Replay.hist.push({time:54.45070000000084, x:1046.7810620935759, y:634.5107903973005, frm:3});
Replay.hist.push({time:54.46740000000084, x:1046.1573087030729, y:635.0338363384519, frm:3});
Replay.hist.push({time:54.48310000000084, x:1045.5755944287232, y:635.5325832615342, frm:3});
Replay.hist.push({time:54.50070000000085, x:1044.9288467615784, y:636.0996571640318, frm:3});
Replay.hist.push({time:54.51640000000084, x:1044.356618761071, y:636.6125633450308, frm:3});
Replay.hist.push({time:54.53300000000083, x:1043.756374064547, y:637.1619935213648, frm:2});
Replay.hist.push({time:54.550700000000845, x:1043.1217094596732, y:637.7557938423255, frm:2});
Replay.hist.push({time:54.567300000000834, x:1042.5314296351933, y:638.3200802327098, frm:2});
Replay.hist.push({time:54.58410000000084, x:1041.9388581632427, y:638.8983428467121, frm:2});
Replay.hist.push({time:54.60070000000084, x:1041.3580416617117, y:639.4767258934796, frm:2});
Replay.hist.push({time:54.61730000000083, x:1040.7818407239602, y:640.0619798929163, frm:2});
Replay.hist.push({time:54.634100000000835, x:1040.20333915244, y:640.6611876436111, frm:2});
Replay.hist.push({time:54.65070000000084, x:1039.652194035942, y:641.2430031481077, frm:2});
Replay.hist.push({time:54.666400000000834, x:1039.1394198872056, y:641.7939987474307, frm:2});
Replay.hist.push({time:54.683100000000834, x:1038.5979980743018, y:642.3860101831344, frm:2});
Replay.hist.push({time:54.70070000000084, x:1038.0318171145086, y:643.0164457303955, frm:2});
Replay.hist.push({time:54.71740000000084, x:1037.4987136345333, y:643.6207597328062, frm:2});
Replay.hist.push({time:54.73320000000085, x:1036.9979899274645, y:644.1979157342498, frm:2});
Replay.hist.push({time:54.750800000000844, x:1036.444366032128, y:644.8469074882382, frm:2});
Replay.hist.push({time:54.76740000000085, x:1035.9261268740088, y:645.464843206884, frm:2});
Replay.hist.push({time:54.78410000000085, x:1035.4085696910709, y:646.0921108269074, frm:2});
Replay.hist.push({time:54.80080000000085, x:1034.8947755093661, y:646.724926072075, frm:2});
Replay.hist.push({time:54.81740000000085, x:1034.3877355091229, y:647.3593722214774, frm:2});
Replay.hist.push({time:54.83370000000085, x:1033.8933762898819, y:647.9875371866162, frm:2});
Replay.hist.push({time:54.850700000000856, x:1033.381455360884, y:648.6480625086845, frm:2});
Replay.hist.push({time:54.867400000000856, x:1032.8821566654863, y:649.3022137178531, frm:1});
Replay.hist.push({time:54.884100000000856, x:1032.3863651343534, y:649.961518325439, frm:1});
Replay.hist.push({time:54.90070000000086, x:1031.9029043333073, y:650.6138366870254, frm:1});
Replay.hist.push({time:54.91750000000085, x:1031.4322866047044, y:651.2578023189199, frm:1});
Replay.hist.push({time:54.93410000000085, x:1030.970333753741, y:651.8985684489772, frm:1});
Replay.hist.push({time:54.95070000000086, x:1030.511382391747, y:652.5437053512698, frm:1});
Replay.hist.push({time:54.96640000000085, x:1030.0800310090892, y:653.1578339395935, frm:1});
Replay.hist.push({time:54.98310000000085, x:1029.6240744892327, y:653.8152317745702, frm:1});
Replay.hist.push({time:55.00070000000086, x:1029.1466981599049, y:654.512619480542, frm:1});
Replay.hist.push({time:55.01740000000086, x:1028.6966671987407, y:655.1786193024253, frm:1});
Replay.hist.push({time:55.03410000000086, x:1028.2494543445584, y:655.8487040742441, frm:1});
Replay.hist.push({time:55.050700000000866, x:1027.8076684232979, y:656.5187590187434, frm:1});
Replay.hist.push({time:55.067400000000866, x:1027.365944608474, y:657.1967883345998, frm:1});
Replay.hist.push({time:55.084100000000866, x:1026.9269084346806, y:657.8787014459763, frm:1});
Replay.hist.push({time:55.10070000000087, x:1026.4931212850445, y:658.5603150876391, frm:1});
Replay.hist.push({time:55.11740000000087, x:1026.0593150240115, y:659.2497732063636, frm:1});
Replay.hist.push({time:55.13390000000087, x:1025.63321544386, y:659.9345948370799, frm:1});
Replay.hist.push({time:55.150800000000864, x:1025.1993341310529, y:660.6396757223448, frm:1});
Replay.hist.push({time:55.16740000000087, x:1024.7756181618208, y:661.3357847811034, frm:1});
Replay.hist.push({time:55.183600000000865, x:1024.3807932249942, y:661.9911369847827, frm:1});
Replay.hist.push({time:55.20070000000087, x:1023.9667260223453, y:662.6853712597645, frm:1});
Replay.hist.push({time:55.21740000000087, x:1023.5645362996775, y:663.366485055728, frm:1});
Replay.hist.push({time:55.23410000000087, x:1023.1644767480632, y:664.0506186706003, frm:1});
Replay.hist.push({time:55.25080000000087, x:1022.7665099088283, y:664.7377143175065, frm:1});
Replay.hist.push({time:55.267200000000884, x:1022.377691041505, y:665.4152967601825, frm:1});
Replay.hist.push({time:55.28310000000088, x:1022.0025828609614, y:666.0748486637458, frm:1});
Replay.hist.push({time:55.300800000000876, x:1021.5871335557745, y:666.8120241622876, frm:1});
Replay.hist.push({time:55.31740000000088, x:1021.1994823937888, y:667.5061881487352, frm:1});
Replay.hist.push({time:55.33410000000088, x:1020.8113981126392, y:668.2072008004318, frm:1});
Replay.hist.push({time:55.350700000000884, x:1020.4274909452282, y:668.9066125990718, frm:1});
Replay.hist.push({time:55.367400000000885, x:1020.043100022911, y:669.6127917512958, frm:0});
Replay.hist.push({time:55.38310000000088, x:1019.6833611516378, y:670.2789808834801, frm:0});
Replay.hist.push({time:55.40080000000088, x:1019.2796741248276, y:671.0326111510784, frm:0});
Replay.hist.push({time:55.416400000000884, x:1018.9254788276828, y:671.6990789654972, frm:0});
Replay.hist.push({time:55.43410000000088, x:1018.5253999223224, y:672.4577123328036, frm:0});
Replay.hist.push({time:55.450700000000886, x:1018.1518657849052, y:673.1715406559282, frm:0});
Replay.hist.push({time:55.467500000000875, x:1017.7859940604131, y:673.875849501743, frm:0});
Replay.hist.push({time:55.48410000000088, x:1017.4279361983042, y:674.5699736421724, frm:0});
Replay.hist.push({time:55.50080000000088, x:1017.0691676909217, y:675.2702405055501, frm:0});
Replay.hist.push({time:55.51700000000088, x:1016.7224933086791, y:675.9513794856392, frm:0});
Replay.hist.push({time:55.53410000000088, x:1016.3579786454231, y:676.6722580252869, frm:0});
Replay.hist.push({time:55.55030000000088, x:1016.0139539186265, y:677.3569641167794, frm:0});
Replay.hist.push({time:55.56740000000089, x:1015.6521704378583, y:678.0815065433791, frm:0});
Replay.hist.push({time:55.58310000000088, x:1015.3211910329227, y:678.7483319306871, frm:0});
Replay.hist.push({time:55.60070000000089, x:1014.951488523636, y:679.4975915879519, frm:0});
Replay.hist.push({time:55.61740000000089, x:1014.6019457124193, y:680.2102117101931, frm:0});
Replay.hist.push({time:55.63410000000089, x:1014.2535964293778, y:680.9244037892307, frm:0});
Replay.hist.push({time:55.64980000000089, x:1013.9271617818674, y:681.5972292893574, frm:0});
Replay.hist.push({time:55.667400000000896, x:1013.5624204241018, y:682.3530088660191, frm:0});
Replay.hist.push({time:55.684100000000896, x:1013.2174550136048, y:683.0716147163926, frm:0});
Replay.hist.push({time:55.7007000000009, x:1012.8756124596, y:683.7872832095151, frm:0});
Replay.hist.push({time:55.71750000000089, x:1012.5306943557725, y:684.5129085656058, frm:0});
Replay.hist.push({time:55.7336000000009, x:1012.2010988040882, y:685.209522739075, frm:0});
Replay.hist.push({time:55.750700000000904, x:1011.852027572863, y:685.9506487316751, frm:0});
Replay.hist.push({time:55.767500000000894, x:1011.5177110170966, y:686.6635938204352, frm:0});
Replay.hist.push({time:55.783800000000895, x:1011.1944624373241, y:687.355781872953, frm:0});
Replay.hist.push({time:55.8008000000009, x:1010.8581930342283, y:688.0787365076335, frm:0});
Replay.hist.push({time:55.8174000000009, x:1010.5306502522528, y:688.7856727513494, frm:0});
Replay.hist.push({time:55.8337000000009, x:1010.2097828631449, y:689.4807437167125, frm:0});
Replay.hist.push({time:55.850800000000895, x:1009.873948560733, y:690.2108416306328, frm:0});
Replay.hist.push({time:55.8664000000009, x:1009.5682354842985, y:690.8776938429155, frm:0});
Replay.hist.push({time:55.8841000000009, x:1009.2221215855641, y:691.6351502000033, frm:0});
Replay.hist.push({time:55.9003000000009, x:1008.9059965581309, y:692.3291876099055, frm:0});
Replay.hist.push({time:55.91750000000089, x:1008.5710273679244, y:693.0668023315051, frm:0});
Replay.hist.push({time:55.934100000000896, x:1008.248361358643, y:693.7793774203558, frm:0});
Replay.hist.push({time:55.9499000000009, x:1007.9417829812998, y:694.4582047922992, frm:0});
Replay.hist.push({time:55.9674000000009, x:1007.6028133089278, y:695.2106732615172, frm:0});
Replay.hist.push({time:55.9841000000009, x:1007.2798819705254, y:695.9293161059879, frm:0});
Replay.hist.push({time:56.000700000000904, x:1006.9593845885445, y:696.6441598438706, frm:0});
Replay.hist.push({time:56.017500000000894, x:1006.6355069721533, y:697.3680814695946, frm:0});
Replay.hist.push({time:56.0341000000009, x:1006.3159310900668, y:698.0838063379862, frm:0});
Replay.hist.push({time:56.0507000000009, x:1005.9967722048423, y:698.7999057888211, frm:0});
Replay.hist.push({time:56.0674000000009, x:1005.6780267546552, y:699.51628493788, frm:0});
Replay.hist.push({time:56.0841000000009, x:1005.3615554551561, y:700.2286494217893, frm:0});
Replay.hist.push({time:56.1008000000009, x:1005.0454179741541, y:700.9412562000825, frm:0});
Replay.hist.push({time:56.117400000000906, x:1004.7314777901605, y:701.6497966672346, frm:0});
Replay.hist.push({time:56.134000000000896, x:1004.4178155000227, y:702.358496258629, frm:0});
Replay.hist.push({time:56.1508000000009, x:1004.1006316529608, y:703.0758535883426, frm:0});
Replay.hist.push({time:56.1674000000009, x:1003.7874504565686, y:703.7847527145751, frm:0});
Replay.hist.push({time:56.183900000000904, x:1003.4763541525119, y:704.4894215602105, frm:0});
Replay.hist.push({time:56.20070000000091, x:1003.1597804897701, y:705.2068993686407, frm:0});
Replay.hist.push({time:56.21740000000091, x:1002.8452423204516, y:705.9200673066102, frm:0});
Replay.hist.push({time:56.23410000000091, x:1002.5308293624894, y:706.6331550840357, frm:0});
Replay.hist.push({time:56.2498000000009, x:1002.2353303474524, y:707.3034440364067, frm:0});
Replay.hist.push({time:56.26740000000091, x:1001.9041575259444, y:708.0546629246436, frm:0});
Replay.hist.push({time:56.28410000000091, x:1001.589965528762, y:708.7672727389864, frm:0});
Replay.hist.push({time:56.300700000000916, x:1001.2776769527994, y:709.475379947467, frm:0});
Replay.hist.push({time:56.317400000000916, x:1000.963504837817, y:710.18747466019, frm:0});
Replay.hist.push({time:56.334100000000916, x:1000.6493046055316, y:710.8992521584763, frm:0});
Replay.hist.push({time:56.350800000000916, x:1000.3350509129123, y:711.6106732568372, frm:0});
Replay.hist.push({time:56.36740000000092, x:1000.0226005482206, y:712.317443535579, frm:0});
Replay.hist.push({time:56.38410000000092, x:999.708251121545, y:713.0278427072077, frm:0});
Replay.hist.push({time:56.40080000000092, x:999.3938193492341, y:713.7376642769598, frm:0});
Replay.hist.push({time:56.41750000000092, x:999.0792338554137, y:714.4469743950281, frm:0});
Replay.hist.push({time:56.434100000000925, x:998.7663546390214, y:715.1514931713808, frm:0});
Replay.hist.push({time:56.45070000000093, x:998.4532744758994, y:715.8554307335729, frm:0});
Replay.hist.push({time:56.46750000000092, x:998.1361937139419, y:716.5672171295338, frm:0});
Replay.hist.push({time:56.483100000000924, x:997.8415277636583, y:717.2275716990098, frm:0});
Replay.hist.push({time:56.49970000000093, x:997.5277127107016, y:717.9295744334206, frm:0});
Replay.hist.push({time:56.517400000000926, x:997.1927806379292, y:718.677278951103, frm:0});
Replay.hist.push({time:56.534100000000926, x:996.8764310821986, y:719.3819497989355, frm:0});
Replay.hist.push({time:56.550300000000924, x:996.569215924614, y:720.0647482818862, frm:0});
Replay.hist.push({time:56.56740000000093, x:996.2445549708602, y:720.7845993238852, frm:0});
Replay.hist.push({time:56.58360000000093, x:995.9365917038164, y:721.4657113407906, frm:0});
Replay.hist.push({time:56.60080000000092, x:995.6091873767759, y:722.1879007148336, frm:0});
Replay.hist.push({time:56.61750000000092, x:995.290844314836, y:722.8881214624914, frm:0});
Replay.hist.push({time:56.63410000000093, x:994.9739397473951, y:723.5831563111846, frm:0});
Replay.hist.push({time:56.65070000000093, x:994.6565456998461, y:724.27716364895, frm:0});
Replay.hist.push({time:56.666600000000926, x:994.3520519243004, y:724.940915467388, frm:0});
Replay.hist.push({time:56.68320000000093, x:994.0336316317811, y:725.6328060276658, frm:0});
Replay.hist.push({time:56.700800000000925, x:993.6932726815771, y:726.3697747777086, frm:0});
Replay.hist.push({time:56.71740000000093, x:993.3701782199728, y:727.0668038440939, frm:0});
Replay.hist.push({time:56.73410000000093, x:993.0445101445555, y:727.7667874678317, frm:0});
Replay.hist.push({time:56.75080000000093, x:992.7181881568569, y:728.465486411793, frm:0});
Replay.hist.push({time:56.76670000000094, x:992.406864804622, y:729.1294944528935, frm:0});
Replay.hist.push({time:56.78410000000093, x:992.0654497566027, y:729.8547169343962, frm:0});
Replay.hist.push({time:56.80080000000093, x:991.7370252020743, y:730.5493455822391, frm:0});
Replay.hist.push({time:56.81750000000093, x:991.407850294558, y:731.2425403338369, frm:0});
Replay.hist.push({time:56.83410000000094, x:991.0798786822845, y:731.9301275281024, frm:0});
Replay.hist.push({time:56.85080000000094, x:990.7491361142073, y:732.6203525174482, frm:0});
Replay.hist.push({time:56.866900000000946, x:990.4294958229242, y:733.2843230268318, frm:0});
Replay.hist.push({time:56.88410000000094, x:990.0871543746531, y:733.9920231602143, frm:0});
Replay.hist.push({time:56.90080000000094, x:989.7538803512145, y:734.6775141985054, frm:0});
Replay.hist.push({time:56.91750000000094, x:989.419713617946, y:735.3613510456456, frm:0});
Replay.hist.push({time:56.934100000000946, x:989.086639421929, y:736.0394187142932, frm:0});
Replay.hist.push({time:56.95070000000095, x:988.7526369173563, y:736.7157803514357, frm:0});
Replay.hist.push({time:56.96650000000094, x:988.4338438545824, y:737.357936750153, frm:0});
Replay.hist.push({time:56.98410000000095, x:988.0777034014404, y:738.0713361309764, frm:0});
Replay.hist.push({time:56.999800000000945, x:987.7590557447072, y:738.7060205342216, frm:0});
Replay.hist.push({time:57.01750000000094, x:987.3943558039354, y:739.4281941662446, frm:0});
Replay.hist.push({time:57.03410000000095, x:987.0481077802025, y:740.1095951519726, frm:0});
Replay.hist.push({time:57.050200000000956, x:986.7112207747866, y:740.7685716287118, frm:0});
Replay.hist.push({time:57.066900000000956, x:986.3606497128505, y:741.4500810944827, frm:0});
Replay.hist.push({time:57.08410000000095, x:985.9983543833598, y:742.1498012495589, frm:0});
Replay.hist.push({time:57.10080000000095, x:985.6453682905707, y:742.827022296317, frm:0});
Replay.hist.push({time:57.11750000000095, x:985.291155746963, y:743.502073886822, frm:0});
Replay.hist.push({time:57.134100000000956, x:984.9378243663971, y:744.1708972773451, frm:0});
Replay.hist.push({time:57.150800000000956, x:984.5810942603298, y:744.8415114929301, frm:0});
Replay.hist.push({time:57.16740000000096, x:984.2252129001821, y:745.5058505660318, frm:0});
Replay.hist.push({time:57.18410000000096, x:983.8658698375028, y:746.1718797410928, frm:0});
Replay.hist.push({time:57.20080000000096, x:983.5051806511425, y:746.8355542120169, frm:0});
Replay.hist.push({time:57.21750000000096, x:983.1431215810874, y:747.4968371955963, frm:0});
Replay.hist.push({time:57.234100000000964, x:982.7818489575313, y:748.1517550700728, frm:0});
Replay.hist.push({time:57.250800000000964, x:982.4169877027457, y:748.8081600664602, frm:0});
Replay.hist.push({time:57.267500000000965, x:982.0506858880253, y:749.4620641529264, frm:0});
Replay.hist.push({time:57.28410000000097, x:981.6851261036535, y:750.1095392132793, frm:0});
Replay.hist.push({time:57.30070000000097, x:981.3180968248547, y:750.7544717923713, frm:0});
Replay.hist.push({time:57.31660000000097, x:980.9651419467505, y:751.3697982193612, frm:0});
Replay.hist.push({time:57.33400000000096, x:980.568483587773, y:752.0555988725246, frm:0});
Replay.hist.push({time:57.350400000000974, x:980.1905104447674, y:752.7034546752, frm:1});
Replay.hist.push({time:57.367500000000966, x:979.7946819583848, y:753.3760073038628, frm:1});
Replay.hist.push({time:57.38410000000097, x:979.4087132247648, y:754.0259811906382, frm:1});
Replay.hist.push({time:57.40080000000097, x:979.0186936497239, y:754.6769295260149, frm:1});
Replay.hist.push({time:57.417400000000974, x:978.6292682784808, y:755.3210202740818, frm:1});
Replay.hist.push({time:57.434100000000974, x:978.2357216310036, y:755.9659732219912, frm:1});
Replay.hist.push({time:57.45010000000097, x:977.8569731732528, y:756.5810248766909, frm:1});
Replay.hist.push({time:57.46750000000097, x:977.4431857938139, y:757.2466472835083, frm:1});
Replay.hist.push({time:57.48410000000097, x:977.0465412560294, y:757.8784965672918, frm:1});
Replay.hist.push({time:57.500700000000975, x:976.6480392518936, y:758.5072051219385, frm:1});
Replay.hist.push({time:57.51660000000097, x:976.264573592651, y:759.1064289019967, frm:1});
Replay.hist.push({time:57.53410000000097, x:975.8405097906805, y:759.7625265035717, frm:1});
Replay.hist.push({time:57.55080000000097, x:975.4338274589425, y:760.385268555568, frm:1});
Replay.hist.push({time:57.56750000000097, x:975.0251678449597, y:761.0046811407157, frm:1});
Replay.hist.push({time:57.58410000000097, x:974.6169712042781, y:761.6170489445057, frm:1});
Replay.hist.push({time:57.59990000000098, x:974.2265833974823, y:762.1967884898024, frm:1});
Replay.hist.push({time:57.61670000000098, x:973.8094822907506, y:762.8098333873743, frm:1});
Replay.hist.push({time:57.63410000000098, x:973.3698927563134, y:763.4488413609731, frm:1});
Replay.hist.push({time:57.65080000000098, x:972.936911264411, y:764.0711603927534, frm:1});
Replay.hist.push({time:57.66740000000098, x:972.5026543745955, y:764.6882833886172, frm:1});
Replay.hist.push({time:57.68320000000099, x:972.0857249610397, y:765.2741890945285, frm:1});
Replay.hist.push({time:57.70080000000098, x:971.6169903899449, y:765.9252216584831, frm:1});
Replay.hist.push({time:57.71720000000099, x:971.176203137928, y:766.5300653746494, frm:1});
Replay.hist.push({time:57.73310000000099, x:970.7450848027593, y:767.1147670602264, frm:1});
Replay.hist.push({time:57.750800000000986, x:970.2606320333114, y:767.7637383802476, frm:1});
Replay.hist.push({time:57.767500000000986, x:969.7992400349231, y:768.3739308351583, frm:1});
Replay.hist.push({time:57.784200000001, x:969.3335657934579, y:768.9820348826811, frm:1});
Replay.hist.push({time:57.80000000000099, x:968.889031869154, y:769.5553143088002, frm:1});
Replay.hist.push({time:57.81750000000099, x:968.3920002937793, y:770.1880036028086, frm:1});
Replay.hist.push({time:57.834100000000994, x:967.9160522574167, y:770.7857073392662, frm:1});
Replay.hist.push({time:57.850800000000994, x:967.432736551981, y:771.3845668718477, frm:1});
Replay.hist.push({time:57.867400000001, x:966.9477923463999, y:771.9773057394973, frm:1});
Replay.hist.push({time:57.884200000001, x:966.4523417138498, y:772.5745329421245, frm:1});
Replay.hist.push({time:57.90080000000099, x:965.9581394149034, y:773.1619115347626, frm:1});
Replay.hist.push({time:57.917400000000995, x:965.4592467682276, y:773.746482006665, frm:1});
Replay.hist.push({time:57.934100000000996, x:964.9385789123962, y:774.3476619497583, frm:2});
Replay.hist.push({time:57.94980000000099, x:964.4430647747274, y:774.9114247662, frm:2});
Replay.hist.push({time:57.96650000000099, x:963.9109091420362, y:775.5078628937642, frm:2});
Replay.hist.push({time:57.983500000000994, x:963.363780726763, y:776.1114408479683, frm:2});
Replay.hist.push({time:58.000900000001, x:962.7980429905735, y:776.7253693881983, frm:2});
Replay.hist.push({time:58.017400000001004, x:962.2561972638763, y:777.3037653223623, frm:2});
Replay.hist.push({time:58.03420000000101, x:961.6990011897666, y:777.8888456634854, frm:2});
Replay.hist.push({time:58.05090000000101, x:961.1395711322361, y:778.4664773380055, frm:2});
Replay.hist.push({time:58.067500000001, x:960.5779356492989, y:779.0366177559108, frm:2});
Replay.hist.push({time:58.084100000001, x:960.0106856552566, y:779.6026288057102, frm:2});
Replay.hist.push({time:58.100500000001, x:959.4446959839152, y:780.1576437888499, frm:2});
Replay.hist.push({time:58.117500000001, x:958.8520366541421, y:780.7285009414503, frm:2});
Replay.hist.push({time:58.134200000001016, x:958.2638948782435, y:781.284697304453, frm:2});
Replay.hist.push({time:58.14980000000101, x:957.7091447185142, y:781.8000103588718, frm:2});
Replay.hist.push({time:58.16740000000102, x:957.076828417668, y:782.3764872794548, frm:2});
Replay.hist.push({time:58.18310000000101, x:956.5071195224605, y:782.8860671233178, frm:2});
Replay.hist.push({time:58.20080000000101, x:955.8502902699797, y:783.462163975295, frm:2});
Replay.hist.push({time:58.21700000000101, x:955.2400907834495, y:783.9865481334731, frm:2});
Replay.hist.push({time:58.234100000001014, x:954.5892568920954, y:784.5345195720072, frm:2});
Replay.hist.push({time:58.250800000001014, x:953.9469551058552, y:785.06398538861, frm:2});
Replay.hist.push({time:58.26740000000102, x:953.3018474546666, y:785.5845909777864, frm:2});
Replay.hist.push({time:58.28410000000102, x:952.646067061499, y:786.1024833696924, frm:2});
Replay.hist.push({time:58.30080000000102, x:951.9833998358486, y:786.6143692925648, frm:2});
Replay.hist.push({time:58.31740000000102, x:951.3177957648666, y:787.1170982397339, frm:2});
Replay.hist.push({time:58.33410000000102, x:950.6411385652978, y:787.6165979890733, frm:3});
Replay.hist.push({time:58.35080000000102, x:949.95733463645, y:788.1096784530042, frm:3});
Replay.hist.push({time:58.36750000000102, x:949.266295632328, y:788.5961994160346, frm:3});
Replay.hist.push({time:58.38410000000103, x:948.5721416261094, y:789.0731628723493, frm:3});
Replay.hist.push({time:58.40070000000103, x:947.8706626638923, y:789.543365567322, frm:3});
Replay.hist.push({time:58.41740000000103, x:947.1574711151954, y:790.0094396512062, frm:3});
Replay.hist.push({time:58.43410000000103, x:946.4388544676831, y:790.467019723071, frm:3});
Replay.hist.push({time:58.45080000000103, x:945.7186083947397, y:790.9136889226893, frm:3});
Replay.hist.push({time:58.467400000001035, x:945.0003946642302, y:791.3473611366189, frm:3});
Replay.hist.push({time:58.484100000001035, x:944.2724254816078, y:791.7751399856364, frm:3});
Replay.hist.push({time:58.500800000001036, x:943.5389682047518, y:792.1943232557926, frm:3});
Replay.hist.push({time:58.517500000001036, x:942.7999809154486, y:792.6048462383385, frm:3});
Replay.hist.push({time:58.53420000000105, x:942.0554218251523, y:793.0066444229926, frm:3});
Replay.hist.push({time:58.55080000000104, x:941.3097571123388, y:793.3973269512212, frm:3});
Replay.hist.push({time:58.567400000001044, x:940.5585051608009, y:793.7792623201752, frm:3});
Replay.hist.push({time:58.584100000001044, x:939.7970496792431, y:794.1546081768088, frm:3});
Replay.hist.push({time:58.59980000000104, x:939.0759492436335, y:794.4992943568998, frm:3});
Replay.hist.push({time:58.616900000001046, x:938.2847524984219, y:794.8656180082578, frm:3});
Replay.hist.push({time:58.63310000000104, x:937.5295625381456, y:795.2038610537443, frm:3});
Replay.hist.push({time:58.65080000000104, x:936.6981617717598, y:795.5635533862394, frm:3});
Replay.hist.push({time:58.66750000000104, x:935.9076457569763, y:795.8934209697181, frm:3});
Replay.hist.push({time:58.684100000001045, x:935.1159742837906, y:796.2121017969316, frm:3});
Replay.hist.push({time:58.69980000000104, x:934.3725979976743, y:796.5008727503241, frm:3});
Replay.hist.push({time:58.716400000001045, x:933.6060238452346, y:796.7881949517116, frm:3});
Replay.hist.push({time:58.73320000000105, x:932.8246784645808, y:797.0702860772266, frm:3});
Replay.hist.push({time:58.75080000000104, x:932.0001220392788, y:797.3563695507494, frm:3});
Replay.hist.push({time:58.76750000000104, x:931.2119959340833, y:797.6188424222612, frm:3});
Replay.hist.push({time:58.78410000000105, x:930.423028646467, y:797.8710200148744, frm:3});
Replay.hist.push({time:58.80080000000105, x:929.623682634031, y:798.1158873095112, frm:3});
Replay.hist.push({time:58.816800000001045, x:928.8525093766202, y:798.3421375476112, frm:3});
Replay.hist.push({time:58.834200000001054, x:928.007923506218, y:798.57884452968, frm:3});
Replay.hist.push({time:58.850800000001044, x:927.196347039882, y:798.7955516285049, frm:3});
Replay.hist.push({time:58.86740000000105, x:926.3790601790888, y:799.0033019061595, frm:3});
Replay.hist.push({time:58.88410000000105, x:925.5510554914056, y:799.203213829911, frm:3});
Replay.hist.push({time:58.899900000001054, x:924.7622824467126, y:799.383914283809, frm:3});
Replay.hist.push({time:58.91750000000105, x:923.8774745195631, y:799.575479819457, frm:3});
Replay.hist.push({time:58.93420000000106, x:923.0318303437083, y:799.7477308845077, frm:3});
Replay.hist.push({time:58.95080000000105, x:922.1853556817443, y:799.9097111393678, frm:3});
Replay.hist.push({time:58.96750000000105, x:921.3510767979258, y:800.0592928645973, frm:3});
Replay.hist.push({time:58.98310000000106, x:920.6111699111877, y:800.1837153061826, frm:3});
Replay.hist.push({time:59.00080000000106, x:919.7663376670965, y:800.3164482786044, frm:3});
Replay.hist.push({time:59.01740000000106, x:918.9688073908565, y:800.4327471207384, frm:3});
Replay.hist.push({time:59.03410000000106, x:918.1613797560675, y:800.5417113394003, frm:3});
Replay.hist.push({time:59.05020000000107, x:917.3780948533549, y:800.6390958303227, frm:2});
Replay.hist.push({time:59.06750000000106, x:916.5310987873659, y:800.7353102097724, frm:2});
Replay.hist.push({time:59.084100000001065, x:915.7131379527672, y:800.8193873086383, frm:2});
Replay.hist.push({time:59.10090000000107, x:914.8800861137669, y:800.8962174156788, frm:2});
Replay.hist.push({time:59.11750000000106, x:914.0517492949928, y:800.9639343859883, frm:2});
Replay.hist.push({time:59.13410000000106, x:913.2182197354686, y:801.0234648196382, frm:2});
Replay.hist.push({time:59.15080000000106, x:912.3744063786708, y:801.0750560916664, frm:2});
Replay.hist.push({time:59.167100000001064, x:911.5456853554455, y:801.1173503671846, frm:2});
Replay.hist.push({time:59.18410000000107, x:910.675976741454, y:801.1529389132149, frm:2});
Replay.hist.push({time:59.19980000000106, x:909.8678362871708, y:801.1780449736909, frm:2});
Replay.hist.push({time:59.21750000000106, x:908.9510767201058, y:801.1973718860703, frm:2});
Replay.hist.push({time:59.234200000001074, x:908.0805400540792, y:801.206846260421, frm:1});
Replay.hist.push({time:59.250800000001064, x:907.2098415087952, y:801.20779701517, frm:1});
Replay.hist.push({time:59.26740000000107, x:906.3789620268526, y:801.2026874092194, frm:1});
Replay.hist.push({time:59.28410000000107, x:905.5583937179096, y:801.1968717046086, frm:1});
Replay.hist.push({time:59.30080000000107, x:904.736978912077, y:801.190570568687, frm:1});
Replay.hist.push({time:59.316500000001064, x:903.963976362256, y:801.1842064838349, frm:0});
Replay.hist.push({time:59.33320000000108, x:903.1409456796076, y:801.1769716062257, frm:0});
Replay.hist.push({time:59.35010000000107, x:902.3072266072652, y:801.1691643761742, frm:0});
Replay.hist.push({time:59.367500000001066, x:901.4479806358064, y:801.1606187673493, frm:0});
Replay.hist.push({time:59.38370000000108, x:900.6471985891518, y:801.1522023156689, frm:0});
Replay.hist.push({time:59.40080000000107, x:899.80113123065, y:801.1428399994326, frm:0});
Replay.hist.push({time:59.41750000000107, x:898.9740588482244, y:801.1332252340703, frm:0});
Replay.hist.push({time:59.434100000001074, x:898.1511717962696, y:801.1232091566467, frm:0});
Replay.hist.push({time:59.45030000000107, x:897.3473811190597, y:801.112995896692, frm:0});
Replay.hist.push({time:59.46750000000107, x:896.4932079100225, y:801.1016811324154, frm:0});
Replay.hist.push({time:59.48310000000107, x:895.7177912394152, y:801.0910014554123, frm:0});
Replay.hist.push({time:59.501100000001074, x:894.8223101810354, y:801.0781890573842, frm:0});
Replay.hist.push({time:59.51750000000107, x:894.005673292651, y:801.0660608872641, frm:0});
Replay.hist.push({time:59.534200000001086, x:893.1733890911141, y:801.0532684647455, frm:0});
Replay.hist.push({time:59.550800000001075, x:892.3453840430681, y:801.0401128667024, frm:0});
Replay.hist.push({time:59.56740000000108, x:891.5166864753302, y:801.0265215214886, frm:0});
Replay.hist.push({time:59.58420000000108, x:890.6773107988471, y:801.0123255512021, frm:0});
Replay.hist.push({time:59.60080000000107, x:889.8472464262418, y:800.9978655793482, frm:0});
Replay.hist.push({time:59.61750000000107, x:889.0115106589743, y:800.9828869617952, frm:0});
Replay.hist.push({time:59.63410000000108, x:888.1801187779024, y:800.9675717181091, frm:0});
Replay.hist.push({time:59.65080000000108, x:887.3664940953405, y:800.9521868633008, frm:0});
Replay.hist.push({time:59.66750000000108, x:886.5524813346422, y:800.9364050119271, frm:0});
Replay.hist.push({time:59.68410000000108, x:885.7427539429191, y:800.9203227180387, frm:0});
Replay.hist.push({time:59.700000000001076, x:884.966622020364, y:800.904551514417, frm:0});
Replay.hist.push({time:59.717200000001085, x:884.1264587288288, y:800.8870894055129, frm:0});
Replay.hist.push({time:59.73420000000109, x:883.2954692794922, y:800.8694226073032, frm:0});
Replay.hist.push({time:59.75080000000108, x:882.4834671018948, y:800.8517827859869, frm:0});
Replay.hist.push({time:59.76740000000108, x:881.6709186117844, y:800.8337614077258, frm:0});
Replay.hist.push({time:59.784200000001086, x:880.8480344743815, y:800.8151369398596, frm:0});
Replay.hist.push({time:59.800800000001075, x:880.034410591854, y:800.7963551489631, frm:0});
Replay.hist.push({time:59.817500000001075, x:879.2153590986518, y:800.7770825431338, frm:0});
Replay.hist.push({time:59.83410000000108, x:878.4006945860314, y:800.7575522846803, frm:0});
Replay.hist.push({time:59.85090000000108, x:877.5757009231792, y:800.7374105653535, frm:0});
Replay.hist.push({time:59.866500000001075, x:876.8091646460747, y:800.7183704914712, frm:0});
Replay.hist.push({time:59.884100000001084, x:875.9438573085803, y:800.6965032334161, frm:0});
Replay.hist.push({time:59.900800000001084, x:875.1222855243639, y:800.6753772695606, frm:0});
Replay.hist.push({time:59.91740000000109, x:874.3051548228584, y:800.6540167148397, frm:0});
Replay.hist.push({time:59.93420000000109, x:873.4639035220393, y:800.6316651454744, frm:0});
Replay.hist.push({time:59.95080000000108, x:872.6186845346091, y:800.6088428221727, frm:0});
Replay.hist.push({time:59.96750000000108, x:871.7541955881378, y:800.5851244816381, frm:0});
Replay.hist.push({time:59.984100000001085, x:870.8808344970736, y:800.5607801310823, frm:0});
Replay.hist.push({time:60.000800000001085, x:869.9879646717086, y:800.5354978222643, frm:0});
Replay.hist.push({time:60.017500000001085, x:869.0808098360505, y:800.5094063871926, frm:0});
Replay.hist.push({time:60.03330000000109, x:868.224079734427, y:800.4843939743745, frm:0});
Replay.hist.push({time:60.050100000001095, x:867.3052097887751, y:800.457169952715, frm:0});
Replay.hist.push({time:60.06750000000109, x:866.3383110233509, y:800.4280826885796, frm:0});
Replay.hist.push({time:60.084100000001094, x:865.4019486301697, y:800.399487610234, frm:0});
Replay.hist.push({time:60.100800000001094, x:864.445853233505, y:800.3698608953179, frm:0});
Replay.hist.push({time:60.117500000001094, x:863.4756302854545, y:800.339357284226, frm:0});
Replay.hist.push({time:60.13420000000111, x:862.4912478019706, y:800.3079605962965, frm:0});
Replay.hist.push({time:60.1508000000011, x:861.498734945108, y:800.2758520977475, frm:0});
Replay.hist.push({time:60.16690000000111, x:860.5228830522117, y:800.2438437479577, frm:0});
Replay.hist.push({time:60.18420000000111, x:859.4590425020052, y:800.2084583252172, frm:0});
Replay.hist.push({time:60.2008000000011, x:858.4241107493592, y:800.1735477253507, frm:0});
Replay.hist.push({time:60.2168000000011, x:857.4134278729031, y:800.1389963313907, frm:0});
Replay.hist.push({time:60.234100000001106, x:856.3052810547247, y:800.1005971724283, frm:0});
Replay.hist.push({time:60.25090000000111, x:855.2145982085624, y:800.0622815916632, frm:0});
Replay.hist.push({time:60.2675000000011, x:854.1226626181395, y:800.0234090213853, frm:0});
Replay.hist.push({time:60.283300000001105, x:853.0704072648258, y:799.9854683389924, frm:0});
Replay.hist.push({time:60.300800000001104, x:851.889123631789, y:799.9423190413405, frm:0});
Replay.hist.push({time:60.317500000001104, x:850.7473273564252, y:799.9000587525313, frm:0});
Replay.hist.push({time:60.33420000000112, x:849.5910197006658, y:799.856713016879, frm:0});
Replay.hist.push({time:60.35080000000111, x:848.4384203461418, y:799.8129630430726, frm:0});
Replay.hist.push({time:60.36750000000111, x:847.2750168717654, y:799.7682588986171, frm:0});
Replay.hist.push({time:60.38420000000112, x:846.0973191538328, y:799.7224548293555, frm:0});
Replay.hist.push({time:60.40050000000111, x:844.9341759379205, y:799.6766789640246, frm:0});
Replay.hist.push({time:60.41750000000111, x:843.72453275439, y:799.6285123466191, frm:0});
Replay.hist.push({time:60.433900000001124, x:842.5607797813948, y:799.58163936812, frm:0});
Replay.hist.push({time:60.45080000000112, x:841.3650140038008, y:799.5329379208472, frm:0});
Replay.hist.push({time:60.46750000000112, x:840.1867921402855, y:799.484422467737, frm:0});
Replay.hist.push({time:60.48420000000113, x:839.0120007394542, y:799.4355316966039, frm:0});
Replay.hist.push({time:60.50080000000112, x:837.8476600616887, y:799.3865724645179, frm:0});
Replay.hist.push({time:60.51750000000112, x:836.6797987990223, y:799.3369672842581, frm:0});
Replay.hist.push({time:60.534200000001135, x:835.5154571388216, y:799.2870205973127, frm:0});
Replay.hist.push({time:60.55000000000113, x:834.4170142840802, y:799.2394561491603, frm:0});
Replay.hist.push({time:60.567500000001125, x:833.2043646421353, y:799.1864504633386, frm:0});
Replay.hist.push({time:60.58410000000113, x:832.0576152260213, y:799.1358517442534, frm:0});
Replay.hist.push({time:60.60080000000113, x:830.9075927206338, y:799.0846513551613, frm:0});
Replay.hist.push({time:60.61750000000113, x:829.7612295338985, y:799.0331630736334, frm:0});
Replay.hist.push({time:60.634200000001144, x:828.6220061697678, y:798.9815543753958, frm:0});
Replay.hist.push({time:60.65050000000113, x:827.5257177027974, y:798.9314799271906, frm:0});
Replay.hist.push({time:60.66650000000113, x:826.4529924810217, y:798.88209582652, frm:0});
Replay.hist.push({time:60.683600000001135, x:825.3104460654313, y:798.8290819789216, frm:0});
Replay.hist.push({time:60.699900000001136, x:824.2249013499026, y:798.7783201133299, frm:0});
Replay.hist.push({time:60.71750000000113, x:823.0569972710111, y:798.7232840658554, frm:0});
Replay.hist.push({time:60.73340000000114, x:822.0053036986773, y:798.6733530941781, frm:0});
Replay.hist.push({time:60.750800000001135, x:820.8585700991146, y:798.6185133173136, frm:0});
Replay.hist.push({time:60.76650000000113, x:819.827231906774, y:798.5688422667785, frm:0});
Replay.hist.push({time:60.78410000000114, x:818.6754110697573, y:798.5129811481355, frm:0});
Replay.hist.push({time:60.799800000001134, x:817.6513296814517, y:798.4629753528619, frm:0});
Replay.hist.push({time:60.81750000000113, x:816.501218625735, y:798.4064382740305, frm:0});
Replay.hist.push({time:60.83320000000114, x:815.484496705967, y:798.3561293393975, frm:0});
Replay.hist.push({time:60.850800000001136, x:814.3491512348164, y:798.2995897259126, frm:0});
Replay.hist.push({time:60.86650000000113, x:813.3398446437726, y:798.2490102103134, frm:0});
Replay.hist.push({time:60.88340000000114, x:812.2574964696174, y:798.1944427033028, frm:0});
Replay.hist.push({time:60.900800000001134, x:811.147483387927, y:798.1381318897112, frm:0});
Replay.hist.push({time:60.917500000001134, x:810.0861399957067, y:798.0839634841295, frm:0});
Replay.hist.push({time:60.93410000000114, x:809.035146683436, y:798.0300119957783, frm:0});
Replay.hist.push({time:60.950500000001135, x:808.004761322176, y:797.976820727745, frm:0});
Replay.hist.push({time:60.96750000000114, x:806.9469205254509, y:797.9219086853459, frm:0});
Replay.hist.push({time:60.98420000000115, x:805.9118080879354, y:797.8678819112675, frm:0});
Replay.hist.push({time:61.00080000000114, x:804.88692593176, y:797.8141049998575, frm:0});
Replay.hist.push({time:61.01750000000114, x:803.8599700984403, y:797.7599386290148, frm:0});
Replay.hist.push({time:61.03320000000115, x:802.8981338104093, y:797.7089547327863, frm:0});
Replay.hist.push({time:61.050800000001146, x:801.824510383281, y:797.6517601428449, frm:0});
Replay.hist.push({time:61.066700000001156, x:800.8583294876715, y:797.600034648754, frm:0});
Replay.hist.push({time:61.084200000001154, x:799.799513686025, y:797.5430757073694, frm:0});
Replay.hist.push({time:61.10080000000114, x:798.7992701768493, y:797.489007011775, frm:0});
Replay.hist.push({time:61.117500000001144, x:797.7930254188195, y:797.4343610495894, frm:0});
Replay.hist.push({time:61.13410000000115, x:796.7928384379647, y:797.3797952983897, frm:0});
Replay.hist.push({time:61.15080000000115, x:795.7866715674758, y:797.3246556396064, frm:0});
Replay.hist.push({time:61.16650000000114, x:794.8407933553917, y:797.272595666961, frm:0});
Replay.hist.push({time:61.184200000001155, x:793.7745142436246, y:797.2136511241694, frm:0});
Replay.hist.push({time:61.200900000001155, x:792.7685434351372, y:797.1577925435739, frm:0});
Replay.hist.push({time:61.21650000000115, x:791.8289002630555, y:797.1054017990574, frm:0});
Replay.hist.push({time:61.23310000000115, x:790.8291271276964, y:797.0494326185839, frm:0});
Replay.hist.push({time:61.24980000000115, x:789.8234385423596, y:796.9928998089739, frm:0});
Replay.hist.push({time:61.26650000000115, x:788.8178667982116, y:796.9361429301356, frm:0});
Replay.hist.push({time:61.28410000000116, x:787.7584175539517, y:796.8760983065174, frm:0});
Replay.hist.push({time:61.30080000000116, x:786.7583624896873, y:796.8191900966234, frm:0});
Replay.hist.push({time:61.317400000001165, x:785.764442135019, y:796.7624123127465, frm:0});
Replay.hist.push({time:61.333700000001166, x:784.7886336148588, y:796.7064593698913, frm:0});
Replay.hist.push({time:61.35080000000116, x:783.7651149528268, y:796.6475497900948, frm:0});
Replay.hist.push({time:61.36750000000116, x:782.7657149329239, y:796.5898125023064, frm:0});
Replay.hist.push({time:61.38410000000116, x:781.772485736461, y:796.5322227269086, frm:0});
Replay.hist.push({time:61.39990000000117, x:780.827297128794, y:796.4772270945998, frm:0});
Replay.hist.push({time:61.41750000000116, x:779.7746706805565, y:796.4157630134417, frm:0});
Replay.hist.push({time:61.434100000001166, x:778.7820642680795, y:796.357596251136, frm:0});
Replay.hist.push({time:61.450800000001166, x:777.783707983115, y:796.2988918920842, frm:0});
Replay.hist.push({time:61.46720000000118, x:776.8035156197628, y:796.2410620288132, frm:0});
Replay.hist.push({time:61.48400000000117, x:775.7996697162206, y:796.181640132973, frm:0});
Replay.hist.push({time:61.500900000001174, x:774.7901141014855, y:796.1216820213452, frm:0});
Replay.hist.push({time:61.517500000001164, x:773.7987442973707, y:796.0626127809586, frm:0});
Replay.hist.push({time:61.53410000000117, x:772.807649944167, y:796.0033727964262, frm:0});
Replay.hist.push({time:61.55080000000117, x:771.8108740124336, y:795.9436066906054, frm:0});
Replay.hist.push({time:61.56660000000117, x:770.8680814367646, y:795.8869072733073, frm:0});
Replay.hist.push({time:61.583300000001174, x:769.8718952849512, y:795.826819190502, frm:0});
Replay.hist.push({time:61.60080000000117, x:768.8283379984605, y:795.7636804381899, frm:0});
Replay.hist.push({time:61.61750000000117, x:767.8328125219901, y:795.7032656858618, frm:0});
Replay.hist.push({time:61.63380000000117, x:766.8616335290931, y:795.6441592281179, frm:0});
Replay.hist.push({time:61.65090000000118, x:765.843920898319, y:795.5820436237957, frm:0});
Replay.hist.push({time:61.66750000000117, x:764.8563178364751, y:795.5215946422562, frm:0});
Replay.hist.push({time:61.684200000001184, x:763.8631315892535, y:795.460636122603, frm:0});
Replay.hist.push({time:61.700900000001184, x:762.8703211462933, y:795.399534626858, frm:0});
Replay.hist.push({time:61.71680000000118, x:761.9254198608044, y:795.3412294161857, frm:0});
Replay.hist.push({time:61.73420000000119, x:760.8918048260925, y:795.2772822864714, frm:0});
Replay.hist.push({time:61.75080000000118, x:759.9061124531758, y:795.2161387956659, frm:0});
Replay.hist.push({time:61.76750000000118, x:758.9148960835561, y:795.1544960602168, frm:0});
Replay.hist.push({time:61.78410000000118, x:757.930033714765, y:795.0930950308873, frm:0});
Replay.hist.push({time:61.80080000000118, x:756.939671256759, y:795.0311989491145, frm:0});
Replay.hist.push({time:61.81750000000118, x:755.9497512359933, y:794.9691801276272, frm:0});
Replay.hist.push({time:61.834200000001196, x:754.9602831084701, y:794.9070414396505, frm:0});
Replay.hist.push({time:61.85050000000118, x:753.9949552005256, y:794.8462779728516, frm:0});
Replay.hist.push({time:61.867500000001186, x:752.9886572415983, y:794.782789670886, frm:0});
Replay.hist.push({time:61.883200000001196, x:752.0597392633706, y:794.7240529684266, frm:0});
Replay.hist.push({time:61.900900000001194, x:751.013030376296, y:794.6577203975557, frm:0});
Replay.hist.push({time:61.91730000000119, x:750.043683788839, y:794.5961528313964, frm:0});
Replay.hist.push({time:61.9342000000012, x:749.0453022744731, y:794.5326048519292, frm:0});
Replay.hist.push({time:61.95080000000119, x:748.0651558777055, y:794.4700850488729, frm:0});
Replay.hist.push({time:61.96760000000119, x:747.0737325487567, y:794.406714413513, frm:0});
Replay.hist.push({time:61.984200000001195, x:746.0939443981194, y:794.3439595329738, frm:0});
Replay.hist.push({time:61.99990000000119, x:745.1653184628523, y:794.2843659567493, frm:0});
Replay.hist.push({time:62.017500000001185, x:744.1249186375819, y:794.2174678890341, frm:0});
Replay.hist.push({time:62.0342000000012, x:743.1382919564087, y:794.1539011208844, frm:0});
Replay.hist.push({time:62.0509000000012, x:742.1522405720341, y:794.0902505906507, frm:0});
Replay.hist.push({time:62.06750000000119, x:741.1726718421263, y:794.0269008999435, frm:0});
Replay.hist.push({time:62.0842000000012, x:740.187795183407, y:793.9630917398856, frm:0});
Replay.hist.push({time:62.1001000000012, x:739.2506497430574, y:793.9022686747861, frm:0});
Replay.hist.push({time:62.11750000000119, x:738.2257477678645, y:793.8356333084847, frm:0});
Replay.hist.push({time:62.1332000000012, x:737.3015402198723, y:793.7754420043387, frm:0});
Replay.hist.push({time:62.1508000000012, x:736.2661707249647, y:793.7078974126046, frm:0});
Replay.hist.push({time:62.167700000001204, x:735.272640888614, y:793.642971531713, frm:0});
Replay.hist.push({time:62.184200000001205, x:734.3032628276135, y:793.5795213351885, frm:0});
Replay.hist.push({time:62.200800000001195, x:733.3286586465704, y:793.5156288338145, frm:0});
Replay.hist.push({time:62.217500000001195, x:732.3488490696604, y:793.4512957134718, frm:0});
Replay.hist.push({time:62.23420000000121, x:731.3697150794296, y:793.3869093729802, frm:0});
Replay.hist.push({time:62.2508000000012, x:730.3971214810239, y:793.3228584775163, frm:0});
Replay.hist.push({time:62.2675000000012, x:729.4193614857643, y:793.2583742042689, frm:0});
Replay.hist.push({time:62.2841000000012, x:728.4481513920857, y:793.1942313692607, frm:0});
Replay.hist.push({time:62.30090000000121, x:727.4659579252124, y:793.1292733286823, frm:0});
Replay.hist.push({time:62.31760000000121, x:726.4903322110605, y:793.064662172979, frm:0});
Replay.hist.push({time:62.33420000000121, x:725.5204738958123, y:793.0003484687254, frm:0});
Replay.hist.push({time:62.3508000000012, x:724.5446687478557, y:792.9355573178311, frm:0});
Replay.hist.push({time:62.3675000000012, x:723.5637397537156, y:792.8703438974701, frm:0});
Replay.hist.push({time:62.384200000001215, x:722.5835754082905, y:792.8051010969566, frm:0});
Replay.hist.push({time:62.400800000001205, x:721.6100458991475, y:792.7402225018169, frm:0});
Replay.hist.push({time:62.41760000000121, x:720.6255767500784, y:792.6745383589135, frm:0});
Replay.hist.push({time:62.43420000000121, x:719.6536120897532, y:792.6096149925676, frm:0});
Replay.hist.push({time:62.4508000000012, x:718.6824384303793, y:792.5446734207833, frm:0});
Replay.hist.push({time:62.46720000000121, x:717.7237487759725, y:792.4804988701937, frm:0});
Replay.hist.push({time:62.484100000001206, x:716.7366621303496, y:792.414355031476, frm:0});
Replay.hist.push({time:62.500300000001204, x:715.7912481286347, y:792.3509404280055, frm:0});
Replay.hist.push({time:62.5175000000012, x:714.7883479306366, y:792.2836042853444, frm:0});
Replay.hist.push({time:62.53420000000121, x:713.815453612709, y:792.2182202231661, frm:0});
Replay.hist.push({time:62.54990000000121, x:712.9015842222067, y:792.1567486128905, frm:0});
Replay.hist.push({time:62.5675000000012, x:711.8780479686069, y:792.0878397544252, frm:0});
Replay.hist.push({time:62.58410000000121, x:710.9135425579329, y:792.0228485851019, frm:0});
Replay.hist.push({time:62.60090000000121, x:709.9383041768127, y:791.957080419036, frm:0});
Replay.hist.push({time:62.6175000000012, x:708.9755569605137, y:791.8921035114939, frm:0});
Replay.hist.push({time:62.634200000001215, x:708.0079059808023, y:791.8267463914547, frm:0});
Replay.hist.push({time:62.650800000001205, x:707.0469462116945, y:791.7617942516382, frm:0});
Replay.hist.push({time:62.667500000001205, x:706.0811107175515, y:791.696467328254, frm:0});
Replay.hist.push({time:62.68420000000122, x:705.1161986601023, y:791.6311595134016, frm:0});
Replay.hist.push({time:62.70080000000121, x:704.146404220264, y:791.5654795389524, frm:0});
Replay.hist.push({time:62.71750000000121, x:703.171612556583, y:791.4994209720438, frm:0});
Replay.hist.push({time:62.73420000000122, x:702.1977890089339, y:791.4333898134948, frm:0});
Replay.hist.push({time:62.75010000000122, x:701.2715148123968, y:791.3705491923085, frm:0});
Replay.hist.push({time:62.76750000000121, x:700.2588980277841, y:791.3018154820527, frm:0});
Replay.hist.push({time:62.78420000000123, x:699.2880261314128, y:791.2358826160653, frm:0});
Replay.hist.push({time:62.80090000000123, x:698.3181577481312, y:791.1699879799512, frm:0});
Replay.hist.push({time:62.81760000000123, x:697.3493016851074, y:791.1041342672396, frm:0});
Replay.hist.push({time:62.83420000000123, x:696.3872579615756, y:791.038718025727, frm:0});
Replay.hist.push({time:62.85080000000122, x:695.4262317592152, y:790.9733475070946, frm:0});
Replay.hist.push({time:62.86660000000123, x:694.5124644848178, y:790.911171662438, frm:0});
Replay.hist.push({time:62.884200000001236, x:693.4957221440136, y:790.8419682899366, frm:0});
Replay.hist.push({time:62.900800000001226, x:692.5378125474427, y:790.7767513373053, frm:0});
Replay.hist.push({time:62.91660000000123, x:691.6270355984143, y:790.7147289734637, frm:0});
Replay.hist.push({time:62.93410000000123, x:690.6194048762018, y:790.646096853687, frm:0});
Replay.hist.push({time:62.95080000000123, x:689.6589357679238, y:790.5806652120447, frm:0});
Replay.hist.push({time:62.96750000000123, x:688.699557045395, y:790.515298387159, frm:0});
Replay.hist.push({time:62.98320000000124, x:687.7986176229748, y:790.453906482877, frm:0});
Replay.hist.push({time:63.000800000001234, x:686.7898334368698, y:790.3851600902749, frm:0});
Replay.hist.push({time:63.017500000001235, x:685.8337705998217, y:790.3200030297521, frm:0});
Replay.hist.push({time:63.03420000000125, x:684.8788323158124, y:790.2549212541635, frm:0});
Replay.hist.push({time:63.05080000000124, x:683.9154135424219, y:790.1892621954339, frm:0});
Replay.hist.push({time:63.06760000000124, x:682.9408785014939, y:790.1228483681804, frm:0});
Replay.hist.push({time:63.084200000001246, x:681.9791186983413, y:790.0573100663925, frm:0});
Replay.hist.push({time:63.100900000001246, x:681.0127546055484, y:789.9914650417127, frm:0});
Replay.hist.push({time:63.117500000001236, x:680.0533659249093, y:789.9261043819713, frm:0});
Replay.hist.push({time:63.13330000000124, x:679.1413141544379, y:789.8639789783765, frm:0});
Replay.hist.push({time:63.15060000000125, x:678.14394342333, y:789.7960556724074, frm:0});
Replay.hist.push({time:63.16750000000124, x:677.1708999883158, y:789.7298051174164, frm:0});
Replay.hist.push({time:63.184300000001244, x:676.2048674387806, y:789.6640497633584, frm:0});
Replay.hist.push({time:63.20090000000125, x:675.2515701615577, y:789.5991808599475, frm:0});
Replay.hist.push({time:63.21760000000125, x:674.2937808333822, y:789.5340280394532, frm:0});
Replay.hist.push({time:63.23420000000125, x:673.3429763209583, y:789.4693740558674, frm:0});
Replay.hist.push({time:63.249800000001244, x:672.4505827604229, y:789.408715359661, frm:0});
Replay.hist.push({time:63.26680000000125, x:671.4793894868908, y:789.3427282601599, frm:0});
Replay.hist.push({time:63.284200000001256, x:670.486731025487, y:789.2753150515678, frm:0});
Replay.hist.push({time:63.300900000001256, x:669.5353227234817, y:789.2107361589494, frm:0});
Replay.hist.push({time:63.317500000001246, x:668.5909037181677, y:789.1466657621131, frm:0});
Replay.hist.push({time:63.33420000000126, x:667.6421062471555, y:789.0823346980497, frm:0});
Replay.hist.push({time:63.349900000001256, x:666.7513180186182, y:789.0219719592399, frm:0});
Replay.hist.push({time:63.36750000000125, x:665.7541478951921, y:788.9544431123081, frm:0});
Replay.hist.push({time:63.384300000001254, x:664.8014448683415, y:788.8899700075899, frm:0});
Replay.hist.push({time:63.40030000000125, x:663.8777818515192, y:788.8275057664634, frm:0});
Replay.hist.push({time:63.41750000000125, x:662.8862993863505, y:788.7605053087784, frm:0});
Replay.hist.push({time:63.43420000000126, x:661.9250712008056, y:788.6956013233838, frm:0});
Replay.hist.push({time:63.45090000000126, x:660.965268741704, y:788.6308470976674, frm:0});
Replay.hist.push({time:63.46750000000125, x:660.0126342919082, y:788.5666316859847, frm:0});
Replay.hist.push({time:63.484200000001266, x:659.0557015178554, y:788.5021842955334, frm:0});
Replay.hist.push({time:63.500900000001266, x:658.100221271238, y:788.4378948882717, frm:0});
Replay.hist.push({time:63.517500000001256, x:657.1519096463052, y:788.3741496416633, frm:0});
Replay.hist.push({time:63.53380000000126, x:656.2221463689413, y:788.3117133027038, frm:0});
Replay.hist.push({time:63.55090000000126, x:655.2482743118635, y:788.2463833305948, frm:0});
Replay.hist.push({time:63.56750000000125, x:654.3043646376948, y:788.1831325402001, frm:0});
Replay.hist.push({time:63.58350000000125, x:653.395965199502, y:788.1223278777711, frm:0});
Replay.hist.push({time:63.60080000000126, x:652.4153283140142, y:788.0567637779297, frm:0});
Replay.hist.push({time:63.61760000000126, x:651.4645829394644, y:787.9932758971963, frm:0});
Replay.hist.push({time:63.63320000000127, x:650.583115192331, y:787.9344847322595, frm:0});
Replay.hist.push({time:63.64980000000126, x:649.6466217743746, y:787.87210023446, frm:0});
Replay.hist.push({time:63.667500000001255, x:648.6497608357054, y:787.8057838842878, frm:0});
Replay.hist.push({time:63.68420000000127, x:647.7108036151319, y:787.7434066397288, frm:0});
Replay.hist.push({time:63.70080000000126, x:646.7790113986377, y:787.6815913918813, frm:0});
Replay.hist.push({time:63.71750000000126, x:645.843168596868, y:787.6195961659599, frm:0});
Replay.hist.push({time:63.73420000000127, x:644.8956339043839, y:787.5569195951801, frm:0});
Replay.hist.push({time:63.75080000000126, x:643.9434310528309, y:787.494031363266, frm:0});
Replay.hist.push({time:63.76750000000126, x:642.9871575387782, y:787.4309750027902, frm:0});
Replay.hist.push({time:63.78340000000127, x:642.0782445930711, y:787.3711376672561, frm:0});
Replay.hist.push({time:63.79990000000127, x:641.1366572505433, y:787.3092506129299, frm:0});
Replay.hist.push({time:63.81760000000127, x:640.1284467961848, y:787.2431020598156, frm:0});
Replay.hist.push({time:63.834200000001275, x:639.1846225671893, y:787.181290801859, frm:0});
Replay.hist.push({time:63.850900000001275, x:638.2368240958853, y:787.1193320183215, frm:0});
Replay.hist.push({time:63.867500000001264, x:637.2964090325745, y:787.0579703594949, frm:0});
Replay.hist.push({time:63.88370000000128, x:636.3803016343934, y:786.9983070729389, frm:0});
Replay.hist.push({time:63.90090000000127, x:635.4094509266835, y:786.935202427662, frm:0});
Replay.hist.push({time:63.91760000000127, x:634.4685949103906, y:786.8741719833357, frm:0});
Replay.hist.push({time:63.934200000001276, x:633.5351167197296, y:786.813744169957, frm:0});
Replay.hist.push({time:63.950900000001276, x:632.597780874531, y:786.7531938513561, frm:0});
Replay.hist.push({time:63.967500000001266, x:631.6678195947736, y:786.6932487701845, frm:0});
Replay.hist.push({time:63.98420000000128, x:630.7340397161254, y:786.6331895909422, frm:0});
Replay.hist.push({time:64.00090000000128, x:629.8020565737584, y:786.5733808473902, frm:0});
Replay.hist.push({time:64.01650000000127, x:628.9330810149655, y:786.5177395282856, frm:0});
Replay.hist.push({time:64.03420000000128, x:627.9490701866268, y:786.4548799973406, frm:0});
Replay.hist.push({time:64.04980000000127, x:627.0834822752213, y:786.3997177515794, frm:0});
Replay.hist.push({time:64.06750000000126, x:626.0934943436158, y:786.3367828481037, frm:0});
Replay.hist.push({time:64.08420000000127, x:625.1403437833328, y:786.2763494584939, frm:0});
Replay.hist.push({time:64.10090000000127, x:624.1891543558685, y:786.2161999977154, frm:0});
Replay.hist.push({time:64.11750000000127, x:623.2456127129158, y:786.156694983361, frm:0});
Replay.hist.push({time:64.13420000000129, x:622.2983628373859, y:786.0971206005675, frm:0});
Replay.hist.push({time:64.15090000000129, x:621.3531031548674, y:786.037839077866, frm:0});
Replay.hist.push({time:64.16740000000128, x:620.4211257164138, y:785.9795578954026, frm:0});
Replay.hist.push({time:64.18400000000128, x:619.4854804706135, y:785.9212178665368, frm:0});
Replay.hist.push({time:64.20000000000128, x:618.5855350228333, y:785.8652681919627, frm:0});
Replay.hist.push({time:64.21750000000128, x:617.6033694477551, y:785.8043944861965, frm:0});
Replay.hist.push({time:64.23400000000127, x:616.6793660234531, y:785.7473078812673, frm:0});
Replay.hist.push({time:64.25080000000128, x:615.740619574433, y:785.6894949672526, frm:0});
Replay.hist.push({time:64.26750000000128, x:614.8095229168209, y:785.6323404544937, frm:0});
Replay.hist.push({time:64.28320000000129, x:613.9360543408849, y:785.5788961484523, frm:0});
Replay.hist.push({time:64.29990000000129, x:613.0089755053707, y:785.5223580001887, frm:0});
Replay.hist.push({time:64.31760000000128, x:612.0286708157411, y:785.4627865699742, frm:0});
Replay.hist.push({time:64.33420000000129, x:611.1114147562371, y:785.4072482829829, frm:0});
Replay.hist.push({time:64.35090000000129, x:610.1907318865874, y:785.3517024022376, frm:0});
Replay.hist.push({time:64.36750000000129, x:609.2776553270814, y:785.2968168501155, frm:0});
Replay.hist.push({time:64.38430000000129, x:608.3557153944673, y:785.2416057748529, frm:0});
Replay.hist.push({time:64.4009000000013, x:607.4249274595139, y:785.1860799704348, frm:0});
Replay.hist.push({time:64.4175000000013, x:606.4827889409062, y:785.1301012487165, frm:0});
Replay.hist.push({time:64.43420000000131, x:605.5372766298965, y:785.0741530365256, frm:0});
Replay.hist.push({time:64.45090000000131, x:604.5940821951879, y:785.0185767813323, frm:0});
Replay.hist.push({time:64.46760000000131, x:603.6532160104177, y:784.9633756718629, frm:0});
Replay.hist.push({time:64.48420000000132, x:602.7203002269067, y:784.9088799698122, frm:0});
Replay.hist.push({time:64.50090000000132, x:601.7841074487477, y:784.854436389931, frm:0});
Replay.hist.push({time:64.51750000000132, x:600.8558574433088, y:784.8006999409301, frm:0});
Replay.hist.push({time:64.53320000000133, x:599.9800785458285, y:784.7502288598185, frm:0});
Replay.hist.push({time:64.55060000000132, x:599.0119469795021, y:784.6946974818876, frm:0});
Replay.hist.push({time:64.56660000000133, x:598.1239849554365, y:784.6440106784978, frm:0});
Replay.hist.push({time:64.58420000000133, x:597.1497898982177, y:784.588677287481, frm:0});
Replay.hist.push({time:64.60090000000133, x:596.2278761685859, y:784.5365838816383, frm:0});
Replay.hist.push({time:64.61760000000133, x:595.3083825167741, y:784.4848938755028, frm:0});
Replay.hist.push({time:64.63420000000134, x:594.396802047146, y:784.4339161804501, frm:0});
Replay.hist.push({time:64.65080000000134, x:593.4876325826774, y:784.383343173857, frm:0});
Replay.hist.push({time:64.66750000000134, x:592.5754300907316, y:784.3328769889374, frm:0});
Replay.hist.push({time:64.68420000000135, x:591.6656876740392, y:784.2828265261567, frm:0});
Replay.hist.push({time:64.69990000000135, x:590.8126628138211, y:784.2361543963326, frm:0});
Replay.hist.push({time:64.71750000000134, x:589.8386567499439, y:784.1831734867071, frm:0});
Replay.hist.push({time:64.73420000000135, x:588.8947789377157, y:784.1321528222815, frm:0});
Replay.hist.push({time:64.74990000000135, x:588.0098891894269, y:784.0846131234927, frm:0});
Replay.hist.push({time:64.76760000000134, x:587.0151925877199, y:784.0315180081527, frm:0});
Replay.hist.push({time:64.78420000000135, x:586.0850990146644, y:783.9822063432418, frm:0});
Replay.hist.push({time:64.79980000000134, x:585.2135033995864, y:783.9362951957064, frm:0});
Replay.hist.push({time:64.81760000000135, x:584.2219632766976, y:783.8844240148924, frm:0});
Replay.hist.push({time:64.83420000000136, x:583.3000959883805, y:783.836545523393, frm:0});
Replay.hist.push({time:64.85090000000136, x:582.3754546616999, y:783.7888656685577, frm:0});
Replay.hist.push({time:64.86750000000136, x:581.4591216283761, y:783.741958746758, frm:0});
Replay.hist.push({time:64.88340000000136, x:580.5840236871004, y:783.6974882776827, frm:0});
Replay.hist.push({time:64.90090000000136, x:579.6238394108693, y:783.6490663190236, frm:0});
Replay.hist.push({time:64.91760000000136, x:578.7104389031381, y:783.6033715931492, frm:0});
Replay.hist.push({time:64.93420000000137, x:577.8053233562321, y:783.5584514417219, frm:0});
Replay.hist.push({time:64.95090000000137, x:576.8976009798675, y:783.5137681458598, frm:0});
Replay.hist.push({time:64.96750000000137, x:575.9981513207176, y:783.4698600649808, frm:0});
Replay.hist.push({time:64.98420000000138, x:575.0961507734428, y:783.426201652298, frm:0});
Replay.hist.push({time:65.00090000000138, x:574.1970360994105, y:783.3830622609667, frm:0});
Replay.hist.push({time:65.01750000000138, x:573.3027559868726, y:783.3405369744121, frm:0});
Replay.hist.push({time:65.03420000000139, x:572.3611325632569, y:783.296179509013, frm:0});
Replay.hist.push({time:65.05090000000139, x:571.422731959833, y:783.2524091880625, frm:0});
Replay.hist.push({time:65.06760000000139, x:570.4875670053469, y:783.209229945634, frm:0});
Replay.hist.push({time:65.0842000000014, x:569.5612199892118, y:783.1668988694382, frm:0});
Replay.hist.push({time:65.1008000000014, x:568.6380951548466, y:783.125159533467, frm:0});
Replay.hist.push({time:65.1176000000014, x:567.7071440496538, y:783.0835238097748, frm:0});
Replay.hist.push({time:65.13420000000141, x:566.7946856405797, y:783.0431689808505, frm:0});
Replay.hist.push({time:65.15090000000141, x:565.8841768162013, y:783.0033562571393, frm:0});
Replay.hist.push({time:65.16750000000141, x:564.9864675862641, y:782.9645569796392, frm:0});
Replay.hist.push({time:65.18430000000141, x:564.0854141566838, y:782.9260743075836, frm:0});
Replay.hist.push({time:65.20090000000143, x:563.2023883992567, y:782.8888177196462, frm:0});
Replay.hist.push({time:65.21730000000142, x:562.3371007536717, y:782.8527552370481, frm:0});
Replay.hist.push({time:65.23430000000141, x:561.4476856272403, y:782.816155050672, frm:0});
Replay.hist.push({time:65.25090000000142, x:560.5864310929966, y:782.7811735773753, frm:0});
Replay.hist.push({time:65.26710000000142, x:559.7527923995096, y:782.7477521783812, frm:0});
Replay.hist.push({time:65.28340000000142, x:558.9208876364453, y:782.7148375095858, frm:0});
Replay.hist.push({time:65.30080000000142, x:558.040564306955, y:782.6804909412259, frm:0});
Replay.hist.push({time:65.31760000000142, x:557.1913212936083, y:782.6478366303112, frm:0});
Replay.hist.push({time:65.33420000000143, x:556.3135580616513, y:782.614589482001, frm:0});
Replay.hist.push({time:65.35060000000142, x:555.4538566737274, y:782.5825316683525, frm:0});
Replay.hist.push({time:65.36760000000142, x:554.5706374796188, y:782.5501269260601, frm:0});
Replay.hist.push({time:65.38330000000141, x:553.761855806627, y:782.5209327426138, frm:0});
Replay.hist.push({time:65.40090000000141, x:552.8635061627501, y:782.4890524980649, frm:0});
Replay.hist.push({time:65.41760000000141, x:552.0187856598508, y:782.4596099825794, frm:0});
Replay.hist.push({time:65.43420000000143, x:551.1866178104356, y:782.4311207693696, frm:0});
Replay.hist.push({time:65.45090000000143, x:550.3569698911364, y:782.4032364414038, frm:0});
Replay.hist.push({time:65.46660000000142, x:549.5837204816953, y:782.3777219369746, frm:0});
Replay.hist.push({time:65.48320000000143, x:548.7734518473354, y:782.3514853589179, frm:0});
Replay.hist.push({time:65.50090000000142, x:547.9177458726535, y:782.3243421326633, frm:0});
Replay.hist.push({time:65.51660000000142, x:547.1654363265144, y:782.3009661557572, frm:0});
Replay.hist.push({time:65.53420000000142, x:546.3300953428458, y:782.2755541193649, frm:0});
Replay.hist.push({time:65.5500000000014, x:545.5868966450987, y:782.2534344905498, frm:0});
Replay.hist.push({time:65.5675000000014, x:544.771596378341, y:782.2297082352445, frm:0});
Replay.hist.push({time:65.58420000000142, x:544.0008957379837, y:782.2078076265926, frm:0});
Replay.hist.push({time:65.60090000000142, x:543.2374205540259, y:782.1866268895661, frm:0});
Replay.hist.push({time:65.61760000000142, x:542.4811347665787, y:782.166159176191, frm:0});
Replay.hist.push({time:65.63430000000142, x:541.6983544696112, y:782.1455220837893, frm:0});
Replay.hist.push({time:65.65090000000143, x:540.906240065909, y:782.1252160217674, frm:0});
Replay.hist.push({time:65.66760000000143, x:540.1171658901081, y:782.1055755137266, frm:0});
Replay.hist.push({time:65.68420000000144, x:539.3405132706454, y:782.0868272814376, frm:0});
Replay.hist.push({time:65.70100000000143, x:538.5623165185663, y:782.0686325033759, frm:0});
Replay.hist.push({time:65.71750000000142, x:537.8055572938281, y:782.0515162805534, frm:0});
Replay.hist.push({time:65.73330000000142, x:537.0878169908979, y:782.0358178818087, frm:0});
Replay.hist.push({time:65.75090000000142, x:536.2965952972321, y:782.0191270243913, frm:0});
Replay.hist.push({time:65.76760000000142, x:535.5534861912013, y:782.0040486926655, frm:0});
Replay.hist.push({time:65.78420000000143, x:534.822275387148, y:781.9897870872596, frm:0});
Replay.hist.push({time:65.80080000000143, x:534.0984627065067, y:781.976241793628, frm:0});
Replay.hist.push({time:65.81750000000143, x:533.3777261691724, y:781.9633295798347, frm:0});
Replay.hist.push({time:65.83420000000145, x:532.6643922696857, y:781.9511254762178, frm:0});
Replay.hist.push({time:65.85090000000145, x:531.9584183278167, y:781.9396209600363, frm:0});
Replay.hist.push({time:65.86750000000144, x:531.2639101711169, y:781.9288699694699, frm:0});
Replay.hist.push({time:65.88430000000145, x:530.5683792626942, y:781.9186763479611, frm:0});
Replay.hist.push({time:65.89990000000145, x:529.9289342334221, y:781.9098195118837, frm:0});
Replay.hist.push({time:65.91760000000144, x:529.2113019672981, y:781.9004773122349, frm:0});
Replay.hist.push({time:65.93420000000145, x:528.5454185814452, y:781.8923839207174, frm:0});
Replay.hist.push({time:65.95090000000145, x:527.8687994569525, y:781.8849055001285, frm:0});
Replay.hist.push({time:65.96760000000145, x:527.1724939689773, y:781.8800386593784, frm:0});
Replay.hist.push({time:65.98330000000145, x:526.5236999226406, y:781.8786206226722, frm:0});
Replay.hist.push({time:65.99990000000146, x:525.8440696429733, y:781.8803760520121, frm:0});
Replay.hist.push({time:66.01760000000145, x:525.1265787681127, y:781.8858463135149, frm:1});
Replay.hist.push({time:66.03420000000146, x:524.4600670072929, y:781.8942739590664, frm:1});
Replay.hist.push({time:66.05090000000146, x:523.7959032346174, y:781.9058946070135, frm:1});
Replay.hist.push({time:66.06760000000146, x:523.1380707935136, y:781.9205919564401, frm:1});
Replay.hist.push({time:66.08420000000147, x:522.4904020178788, y:781.9381772068067, frm:1});
Replay.hist.push({time:66.10060000000146, x:521.8565820257909, y:781.9583939554451, frm:1});
Replay.hist.push({time:66.11720000000147, x:521.221170377733, y:781.9816627995822, frm:1});
Replay.hist.push({time:66.13370000000147, x:520.595630678091, y:782.0075203120335, frm:1});
Replay.hist.push({time:66.15090000000147, x:519.9500414915635, y:782.0372915328954, frm:1});
Replay.hist.push({time:66.16760000000147, x:519.3293581745, y:782.0688838313459, frm:1});
Replay.hist.push({time:66.18430000000147, x:518.7147538235547, y:782.1030498661675, frm:1});
Replay.hist.push({time:66.20090000000148, x:518.0932981386223, y:782.1405286095786, frm:1});
Replay.hist.push({time:66.21650000000147, x:517.5099481869464, y:782.1784051038537, frm:1});
Replay.hist.push({time:66.23330000000148, x:516.8879879339878, y:782.2216784560414, frm:1});
Replay.hist.push({time:66.25090000000148, x:516.2432667341492, y:782.2696997537439, frm:1});
Replay.hist.push({time:66.26760000000148, x:515.6377296521486, y:782.3177512521988, frm:1});
Replay.hist.push({time:66.28420000000149, x:515.0418860691138, y:782.3678361093433, frm:1});
Replay.hist.push({time:66.30090000000149, x:514.4485487586953, y:782.4204867217028, frm:1});
Replay.hist.push({time:66.31760000000149, x:513.8612761971457, y:782.475341159712, frm:1});
Replay.hist.push({time:66.33430000000149, x:513.2800330700962, y:782.5323319597994, frm:1});
Replay.hist.push({time:66.3509000000015, x:512.7041127700008, y:782.5914629602004, frm:1});
Replay.hist.push({time:66.3667000000015, x:512.1576434557771, y:782.6500322793572, frm:1});
Replay.hist.push({time:66.3842000000015, x:511.55433354201034, y:782.7174921470031, frm:1});
Replay.hist.push({time:66.4009000000015, x:510.9804893860831, y:782.7843961913462, frm:1});
Replay.hist.push({time:66.4175000000015, x:510.41191102585645, y:782.8533335437166, frm:1});
Replay.hist.push({time:66.43420000000151, x:509.8417471513058, y:782.925122609524, frm:1});
Replay.hist.push({time:66.45090000000151, x:509.2693824038561, y:782.9998810459167, frm:1});
Replay.hist.push({time:66.46760000000151, x:508.68492892676755, y:783.0790163287444, frm:1});
Replay.hist.push({time:66.48430000000151, x:508.10241618517415, y:783.1607166446363, frm:1});
Replay.hist.push({time:66.50070000000152, x:507.5322498476307, y:783.2434336984234, frm:1});
Replay.hist.push({time:66.51760000000151, x:506.94665735889737, y:783.331233995256, frm:1});
Replay.hist.push({time:66.53420000000152, x:506.3733813385394, y:783.4199953951464, frm:1});
Replay.hist.push({time:66.5505000000015, x:505.81231525887665, y:783.509570396729, frm:1});
Replay.hist.push({time:66.56740000000151, x:505.23253840957614, y:783.6049578508506, frm:1});
Replay.hist.push({time:66.58420000000152, x:504.6581402598883, y:783.7023073183769, frm:1});
Replay.hist.push({time:66.60090000000152, x:504.0890826983216, y:783.8015610883515, frm:1});
Replay.hist.push({time:66.61760000000152, x:503.52193866797705, y:783.903278396664, frm:1});
Replay.hist.push({time:66.63430000000152, x:502.9567047760252, y:784.0074466775405, frm:1});
Replay.hist.push({time:66.65090000000153, x:502.3967440812232, y:784.1134080134226, frm:1});
Replay.hist.push({time:66.66760000000153, x:501.8353089168964, y:784.2224261900759, frm:1});
Replay.hist.push({time:66.68320000000153, x:501.312559662351, y:784.326446373106, frm:1});
Replay.hist.push({time:66.69990000000153, x:500.75479632770305, y:784.4401214877009, frm:1});
Replay.hist.push({time:66.71710000000154, x:500.1709029242327, y:784.5621110352914, frm:1});
Replay.hist.push({time:66.73430000000154, x:499.58481673332045, y:784.6876509408048, frm:1});
Replay.hist.push({time:66.75060000000154, x:499.03132760402457, y:784.809069237848, frm:1});
Replay.hist.push({time:66.76750000000153, x:498.4594608404889, y:784.937454574885, frm:1});
Replay.hist.push({time:66.78430000000154, x:497.8929805786555, y:785.0675889149653, frm:1});
Replay.hist.push({time:66.80090000000155, x:497.33520069523706, y:785.1986179572747, frm:1});
Replay.hist.push({time:66.81760000000155, x:496.77602293931454, y:785.3328737810501, frm:1});
Replay.hist.push({time:66.83360000000155, x:496.24211817654, y:785.4637853375986, frm:1});
Replay.hist.push({time:66.85090000000156, x:495.66687284009026, y:785.6078283680617, frm:1});
Replay.hist.push({time:66.86670000000156, x:495.14332312285416, y:785.7416423807562, frm:1});
Replay.hist.push({time:66.88430000000155, x:494.5622074922785, y:785.8932179625864, frm:1});
Replay.hist.push({time:66.90100000000155, x:494.01280006846906, y:786.0394896148321, frm:1});
Replay.hist.push({time:66.91770000000156, x:493.4653384579121, y:786.188128552952, frm:1});
Replay.hist.push({time:66.93420000000155, x:492.9263386795802, y:786.3373003403449, frm:1});
Replay.hist.push({time:66.95090000000155, x:492.3827349255816, y:786.4906073714964, frm:1});
Replay.hist.push({time:66.96760000000155, x:491.83366425904035, y:786.6483901968878, frm:1});
Replay.hist.push({time:66.98430000000155, x:491.2815249211451, y:786.8100462066794, frm:1});
Replay.hist.push({time:67.00090000000156, x:490.73468237931365, y:786.973126039025, frm:1});
Replay.hist.push({time:67.01760000000156, x:490.1865467006464, y:787.1395804647809, frm:1});
Replay.hist.push({time:67.03420000000158, x:489.6436765779938, y:787.3074034298936, frm:1});
Replay.hist.push({time:67.05090000000158, x:489.09952993339584, y:787.4786029048695, frm:1});
Replay.hist.push({time:67.06760000000158, x:488.55737829675763, y:787.6521620259592, frm:1});
Replay.hist.push({time:67.08430000000158, x:488.01721800112733, y:787.8280674817835, frm:1});
Replay.hist.push({time:67.10090000000159, x:487.48226099563635, y:788.0052321413824, frm:1});
Replay.hist.push({time:67.11760000000159, x:486.94606053004196, y:788.1857766327149, frm:1});
Replay.hist.push({time:67.1342000000016, x:486.41503242168824, y:788.3675264349189, frm:1});
Replay.hist.push({time:67.1509000000016, x:485.8827772509489, y:788.5526574719199, frm:1});
Replay.hist.push({time:67.1666000000016, x:485.3841831331234, y:788.7287863553952, frm:1});
Replay.hist.push({time:67.1833000000016, x:484.855752732186, y:788.9183299128936, frm:1});
Replay.hist.push({time:67.20100000000159, x:484.29783706996744, y:789.1216807012514, frm:1});
Replay.hist.push({time:67.2177000000016, x:483.7675163914861, y:789.3180703090635, frm:1});
Replay.hist.push({time:67.2342000000016, x:483.24172306358815, y:789.5157825494811, frm:1});
Replay.hist.push({time:67.25100000000158, x:482.70840105657624, y:789.7193964358112, frm:1});
Replay.hist.push({time:67.2672000000016, x:482.19605442025284, y:789.917933581322, frm:1});
Replay.hist.push({time:67.28420000000159, x:481.660453756736, y:790.1285730167319, frm:1});
Replay.hist.push({time:67.30090000000159, x:481.1363304588724, y:790.3377777777972, frm:1});
Replay.hist.push({time:67.31760000000159, x:480.6142140995118, y:790.5492299561765, frm:1});
Replay.hist.push({time:67.3342000000016, x:480.09720837660467, y:790.7616304004171, frm:1});
Replay.hist.push({time:67.3509000000016, x:479.5790826772535, y:790.9775239892376, frm:1});
Replay.hist.push({time:67.3677000000016, x:479.05986914210473, y:791.1969371486426, frm:1});
Replay.hist.push({time:67.3833000000016, x:478.5795357308806, y:791.402671398044, frm:1});
Replay.hist.push({time:67.4009000000016, x:478.03972595149827, y:791.6370572906952, frm:1});
Replay.hist.push({time:67.4169000000016, x:477.55088672033463, y:791.8522320172241, frm:1});
Replay.hist.push({time:67.43420000000161, x:477.02438909155404, y:792.0871090179484, frm:1});
Replay.hist.push({time:67.4510000000016, x:476.5151335935122, y:792.3173995555816, frm:1});
Replay.hist.push({time:67.46760000000161, x:476.00854079984714, y:792.5495369128827, frm:1});
Replay.hist.push({time:67.48420000000162, x:475.5020484632546, y:792.7846912923004, frm:1});
Replay.hist.push({time:67.50090000000162, x:474.9945182371502, y:793.0234210962967, frm:1});
Replay.hist.push({time:67.51760000000162, x:474.48900246655, y:793.2643032292327, frm:1});
Replay.hist.push({time:67.53420000000163, x:473.9885053357106, y:793.5058631769248, frm:1});
Replay.hist.push({time:67.55090000000163, x:473.486995012831, y:793.7509969606235, frm:1});
Replay.hist.push({time:67.56760000000163, x:472.9874877151498, y:793.998242906581, frm:1});
Replay.hist.push({time:67.58420000000164, x:472.4929517724711, y:794.246088882713, frm:1});
Replay.hist.push({time:67.60000000000163, x:472.02407266480924, y:794.483908710753, frm:1});
Replay.hist.push({time:67.61660000000164, x:471.5333814884835, y:794.7357647324037, frm:1});
Replay.hist.push({time:67.63430000000163, x:471.0123501429343, y:795.0065456671879, frm:1});
Replay.hist.push({time:67.65080000000162, x:470.52863834193676, y:795.2610449329314, frm:1});
Replay.hist.push({time:67.66670000000163, x:470.0643393953507, y:795.5081705029243, frm:1});
Replay.hist.push({time:67.68370000000162, x:469.5699123213152, y:795.7744125349172, frm:1});
Replay.hist.push({time:67.70000000000161, x:469.0977570904922, y:796.0316487412789, frm:1});
Replay.hist.push({time:67.71760000000161, x:468.5853114859734, y:796.3141629739414, frm:1});
Replay.hist.push({time:67.73320000000162, x:468.132391039808, y:796.5667651460584, frm:1});
Replay.hist.push({time:67.74990000000162, x:467.64947882934337, y:796.8391187273178, frm:1});
Replay.hist.push({time:67.76760000000161, x:467.1398390215942, y:797.1299568281786, frm:1});
Replay.hist.push({time:67.7840000000016, x:466.6696150381313, y:797.4014317259994, frm:1});
Replay.hist.push({time:67.80050000000159, x:466.1984598002455, y:797.6764806761023, frm:1});
Replay.hist.push({time:67.81750000000159, x:465.71505818624877, y:797.9618615991882, frm:1});
Replay.hist.push({time:67.83430000000159, x:465.23935791017743, y:798.2458670937982, frm:1});
Replay.hist.push({time:67.85100000000159, x:464.7684707838259, y:798.5301210039565, frm:1});
Replay.hist.push({time:67.8676000000016, x:464.3023575283612, y:798.814575686356, frm:1});
Replay.hist.push({time:67.8843000000016, x:463.83540045652046, y:799.1026441769332, frm:1});
Replay.hist.push({time:67.90090000000161, x:463.37318618007674, y:799.3908649332191, frm:1});
Replay.hist.push({time:67.91760000000161, x:462.9101439818974, y:799.6826964250848, frm:1});
Replay.hist.push({time:67.9340000000016, x:462.45732229277155, y:799.9711051052602, frm:1});
Replay.hist.push({time:67.95090000000161, x:461.99222535523654, y:800.2704638121861, frm:1});
Replay.hist.push({time:67.96760000000161, x:461.5317906870329, y:800.5699723358963, frm:1});
Replay.hist.push({time:67.98340000000161, x:461.0979770199863, y:800.8550547717147, frm:1});
Replay.hist.push({time:68.00090000000161, x:460.6195601696997, y:801.1727262322937, frm:1});
Replay.hist.push({time:68.01760000000161, x:460.16501734440493, y:801.477755754376, frm:1});
Replay.hist.push({time:68.03430000000161, x:459.7124347152162, y:801.7846034636523, frm:1});
Replay.hist.push({time:68.05090000000162, x:459.264500058706, y:802.091403564549, frm:1});
Replay.hist.push({time:68.06760000000162, x:458.8158146331632, y:802.401838393758, frm:1});
Replay.hist.push({time:68.08320000000163, x:458.39843426159223, y:802.6934401740775, frm:1});
Replay.hist.push({time:68.09990000000163, x:457.9535144871188, y:803.0073058518152, frm:1});
Replay.hist.push({time:68.11660000000163, x:457.5105360313145, y:803.3229266806111, frm:1});
Replay.hist.push({time:68.13430000000162, x:457.04315658447763, y:803.659341740672, frm:1});
Replay.hist.push({time:68.15090000000163, x:456.60678698251087, y:803.9766222114753, frm:1});
Replay.hist.push({time:68.16710000000162, x:456.18276707254563, y:804.2878953399119, frm:1});
Replay.hist.push({time:68.18370000000164, x:455.7501612096785, y:804.6085152110202, frm:1});
Replay.hist.push({time:68.20090000000164, x:455.30340814606336, y:804.9428738590296, frm:1});
Replay.hist.push({time:68.21760000000164, x:454.8703318278397, y:805.270179262114, frm:1});
Replay.hist.push({time:68.23430000000164, x:454.439184438698, y:805.5991658366257, frm:1});
Replay.hist.push({time:68.25100000000164, x:454.0099621562384, y:805.9298210413779, frm:1});
Replay.hist.push({time:68.26760000000165, x:453.58521324952846, y:806.2601382775863, frm:1});
Replay.hist.push({time:68.28330000000165, x:453.1852287177299, y:806.5740473317844, frm:1});
Replay.hist.push({time:68.30100000000164, x:452.73633802746673, y:806.929659132071, frm:1});
Replay.hist.push({time:68.31670000000165, x:452.33773080734494, y:807.2484056210843, frm:1});
Replay.hist.push({time:68.33430000000163, x:451.8901535561394, y:807.6096696617043, frm:1});
Replay.hist.push({time:68.35090000000164, x:451.4675227186827, y:807.9540883283646, frm:1});
Replay.hist.push({time:68.36660000000164, x:451.0673963814675, y:808.2831385932798, frm:1});
Replay.hist.push({time:68.38390000000165, x:450.6258924754459, y:808.6496002581379, frm:1});
Replay.hist.push({time:68.39990000000165, x:450.21721371720633, y:808.9920084329872, frm:1});
Replay.hist.push({time:68.41770000000166, x:449.7619806211211, y:809.3770692357867, frm:1});
Replay.hist.push({time:68.43330000000165, x:449.3627769422884, y:809.7179298306812, frm:1});
Replay.hist.push({time:68.44990000000166, x:448.93721437170836, y:810.0846129860902, frm:1});
Replay.hist.push({time:68.46760000000165, x:448.4826872329248, y:810.4800683453852, frm:1});
Replay.hist.push({time:68.48330000000165, x:448.0793545527063, y:810.8343141828695, frm:1});
Replay.hist.push({time:68.50100000000164, x:447.62422365408577, y:811.2378535934914, frm:1});
Replay.hist.push({time:68.51760000000165, x:447.1972009566473, y:811.6201700244985, frm:1});
Replay.hist.push({time:68.53430000000165, x:446.7673910959271, y:812.0086346541894, frm:1});
Replay.hist.push({time:68.55010000000165, x:446.3606311484099, y:812.3796749985668, frm:1});
Replay.hist.push({time:68.56760000000165, x:445.90984138419805, y:812.7947898755604, frm:1});
Replay.hist.push({time:68.58420000000166, x:445.4821683007427, y:813.1924573798776, frm:1});
Replay.hist.push({time:68.60000000000164, x:445.07506929024004, y:813.5745034448123, frm:1});
Replay.hist.push({time:68.61760000000164, x:444.6214166770671, y:814.0043124107175, frm:1});
Replay.hist.push({time:68.63430000000164, x:444.1909865334736, y:814.4161318467637, frm:1});
Replay.hist.push({time:68.65090000000166, x:443.76314192975155, y:814.829391386492, frm:1});
Replay.hist.push({time:68.66760000000166, x:443.3327466362122, y:815.2490940779422, frm:1});
Replay.hist.push({time:68.68430000000166, x:442.9025635539323, y:815.672621507416, frm:1});
Replay.hist.push({time:68.70090000000167, x:442.4750858465157, y:816.0975220449524, frm:1});
Replay.hist.push({time:68.71770000000167, x:442.0425700531157, y:816.5315711788201, frm:1});
Replay.hist.push({time:68.73420000000166, x:441.61794572233254, y:816.9617980989286, frm:1});
Replay.hist.push({time:68.75090000000166, x:441.1883458479757, y:817.401244198241, frm:1});
Replay.hist.push({time:68.76690000000167, x:440.776991227911, y:817.8260096805723, frm:1});
Replay.hist.push({time:68.78420000000168, x:440.33239136387954, y:818.2895364867629, frm:1});
Replay.hist.push({time:68.80100000000166, x:439.9009517615602, y:818.7437963817055, frm:1});
Replay.hist.push({time:68.81760000000168, x:439.4749634583129, y:819.1966721757492, frm:1});
Replay.hist.push({time:68.83430000000168, x:439.0467391271211, y:819.6563393806978, frm:1});
Replay.hist.push({time:68.85090000000169, x:438.6214482943054, y:820.117290963549, frm:1});
Replay.hist.push({time:68.86760000000169, x:438.1939867119283, y:820.5851016217254, frm:1});
Replay.hist.push({time:68.8837000000017, x:437.7823190927852, y:821.0399500565982, frm:1});
Replay.hist.push({time:68.9009000000017, x:437.34362394726446, y:821.5293872591169, frm:1});
Replay.hist.push({time:68.9176000000017, x:436.91834615549976, y:822.008568876816, frm:1});
Replay.hist.push({time:68.93420000000171, x:436.4961306338493, y:822.4889496013694, frm:1});
Replay.hist.push({time:68.95090000000171, x:436.07191057473625, y:822.9763368138033, frm:1});
Replay.hist.push({time:68.96760000000171, x:435.64826692406467, y:823.4678506281178, frm:1});
Replay.hist.push({time:68.98420000000172, x:435.227766715641, y:823.9605120878009, frm:1});
Replay.hist.push({time:69.0000000000017, x:434.828154394091, y:824.4331857026796, frm:1});
Replay.hist.push({time:69.0176000000017, x:434.385748816357, y:824.9616422983147, frm:1});
Replay.hist.push({time:69.03440000000171, x:433.96611417007904, y:825.4679834047217, frm:1});
Replay.hist.push({time:69.05100000000171, x:433.5540280174321, y:825.9701006784978, frm:1});
Replay.hist.push({time:69.06760000000172, x:433.1444761586864, y:826.4739911397795, frm:1});
Replay.hist.push({time:69.08430000000172, x:432.73501034277996, y:826.9826870437423, frm:1});
Replay.hist.push({time:69.10090000000173, x:432.3310371987038, y:827.4894382182538, frm:1});
Replay.hist.push({time:69.11760000000173, x:431.9278394744678, y:828.0001162385817, frm:1});
Replay.hist.push({time:69.13430000000173, x:431.5271735120283, y:828.512504443873, frm:1});
Replay.hist.push({time:69.15100000000173, x:431.1290345982769, y:829.0265846738913, frm:1});
Replay.hist.push({time:69.16680000000173, x:430.75466858002005, y:829.5145090000381, frm:1});
Replay.hist.push({time:69.18410000000173, x:430.3473569897985, y:830.0504416262556, frm:1});
Replay.hist.push({time:69.20100000000173, x:429.95206427093285, y:830.5756840495133, frm:1});
Replay.hist.push({time:69.21700000000173, x:429.58018402514824, y:831.0744920295452, frm:1});
Replay.hist.push({time:69.23430000000174, x:429.1806834808817, y:831.6154708790884, frm:1});
Replay.hist.push({time:69.25090000000175, x:428.79986420555935, y:832.136165504272, frm:1});
Replay.hist.push({time:69.26770000000175, x:428.4169672847685, y:832.6647077284201, frm:1});
Replay.hist.push({time:69.28430000000175, x:428.0411020200252, y:833.1884992810612, frm:1});
Replay.hist.push({time:69.30100000000175, x:427.66545065222704, y:833.7169719505739, frm:1});
Replay.hist.push({time:69.31760000000176, x:427.29577941284504, y:834.2419642911836, frm:1});
Replay.hist.push({time:69.33420000000177, x:426.9285607728971, y:834.768391256758, frm:1});
Replay.hist.push({time:69.35100000000176, x:426.55938827890384, y:835.302634986117, frm:1});
Replay.hist.push({time:69.36770000000178, x:426.19486977810107, y:835.8351540245167, frm:1});
Replay.hist.push({time:69.38430000000177, x:425.8349567102358, y:836.3659052247613, frm:1});
Replay.hist.push({time:69.40100000000177, x:425.47530946437496, y:836.9012641855709, frm:1});
Replay.hist.push({time:69.41670000000178, x:425.13941376929404, y:837.4058503629806, frm:1});
Replay.hist.push({time:69.43330000000178, x:424.7866060432376, y:837.9406820392217, frm:1});
Replay.hist.push({time:69.45100000000177, x:424.4130684360605, y:838.51242912253, frm:1});
Replay.hist.push({time:69.46760000000178, x:424.0652109882793, y:839.0500302007774, frm:1});
Replay.hist.push({time:69.48430000000178, x:423.71766912535634, y:839.5921955227554, frm:1});
Replay.hist.push({time:69.50050000000178, x:423.38283566651205, y:840.119388529127, frm:1});
Replay.hist.push({time:69.51740000000179, x:423.03642937768575, y:840.6698965845255, frm:1});
Replay.hist.push({time:69.53430000000178, x:422.69327350046325, y:841.2204305698492, frm:1});
Replay.hist.push({time:69.55100000000178, x:422.3565742693123, y:841.7657119284825, frm:1});
Replay.hist.push({time:69.56760000000179, x:422.0242473310465, y:842.3089546029846, frm:1});
Replay.hist.push({time:69.58430000000179, x:421.69228543441506, y:842.8566853671866, frm:1});
Replay.hist.push({time:69.60100000000179, x:421.3626925545155, y:843.4056199149122, frm:1});
Replay.hist.push({time:69.6176000000018, x:421.0374160282923, y:843.9524452102734, frm:1});
Replay.hist.push({time:69.6343000000018, x:420.7125335398408, y:844.5037313245509, frm:1});
Replay.hist.push({time:69.6510000000018, x:420.39000657674643, y:845.0561723808092, frm:1});
Replay.hist.push({time:69.66760000000181, x:420.0717403110534, y:845.6064349399879, frm:1});
Replay.hist.push({time:69.68430000000181, x:419.7538968784812, y:846.1611306193496, frm:1});
Replay.hist.push({time:69.70100000000181, x:419.43839552104123, y:846.7169328473127, frm:1});
Replay.hist.push({time:69.71670000000182, x:419.14391313122314, y:847.2404576279234, frm:1});
Replay.hist.push({time:69.73430000000181, x:418.81697765612716, y:847.8271459689831, frm:1});
Replay.hist.push({time:69.75090000000182, x:418.51137552652915, y:848.3808487286706, frm:1});
Replay.hist.push({time:69.76770000000182, x:418.2044148476535, y:848.9422610765591, frm:1});
Replay.hist.push({time:69.78430000000182, x:417.9033976454476, y:849.4980044308306, frm:1});
Replay.hist.push({time:69.80090000000183, x:417.6046526333676, y:850.0547387901504, frm:1});
Replay.hist.push({time:69.81760000000183, x:417.3063968557718, y:850.6158104433582, frm:1});
Replay.hist.push({time:69.83400000000182, x:417.01572690530486, y:851.1677518881775, frm:1});
Replay.hist.push({time:69.85000000000183, x:416.73426957473515, y:851.7071244634863, frm:1});
Replay.hist.push({time:69.86770000000183, x:416.4253573300997, y:852.3047938361813, frm:1});
Replay.hist.push({time:69.88390000000183, x:416.1448591138494, y:852.8527396572863, frm:1});
Replay.hist.push({time:69.90100000000182, x:415.85110315995576, y:853.4320469109341, frm:1});
Replay.hist.push({time:69.91700000000182, x:415.5783926933897, y:853.9749495168846, frm:1});
Replay.hist.push({time:69.93440000000183, x:415.2841869059693, y:854.5662579864255, frm:1});
Replay.hist.push({time:69.95100000000183, x:415.006466632872, y:855.1298846128291, frm:1});
Replay.hist.push({time:69.96670000000184, x:414.74600485299646, y:855.663391674765, frm:1});
Replay.hist.push({time:69.98330000000183, x:414.4727681768079, y:856.2282694921024, frm:1});
Replay.hist.push({time:70.00090000000183, x:414.18548730196096, y:856.8280435636211, frm:1});
Replay.hist.push({time:70.01760000000183, x:413.91518206293176, y:857.3979768322376, frm:1});
Replay.hist.push({time:70.03430000000183, x:413.64710190471584, y:857.9686937112065, frm:1});
Replay.hist.push({time:70.05100000000184, x:413.3812424964151, y:858.5401793962029, frm:1});
Replay.hist.push({time:70.06760000000185, x:413.11917115331937, y:859.1089913573503, frm:1});
Replay.hist.push({time:70.08430000000185, x:412.8577270403144, y:859.6819660491833, frm:1});
Replay.hist.push({time:70.10000000000184, x:412.61394741605125, y:860.2213018623288, frm:1});
Replay.hist.push({time:70.11670000000186, x:412.35678281936373, y:860.7956693720187, frm:1});
Replay.hist.push({time:70.13430000000184, x:412.08814376271573, y:861.4017344239219, frm:1});
Replay.hist.push({time:70.15100000000184, x:411.83549189629434, y:861.9775166329173, frm:0});
Replay.hist.push({time:70.16680000000184, x:411.5988932077605, y:862.5219107292452, frm:0});
Replay.hist.push({time:70.18430000000184, x:411.3392641844896, y:863.125207074272, frm:0});
Replay.hist.push({time:70.19990000000185, x:411.1098265568355, y:863.6636088095142, frm:0});
Replay.hist.push({time:70.21760000000184, x:410.85180392822315, y:864.2751198961902, frm:0});
Replay.hist.push({time:70.23380000000184, x:410.6177716527326, y:864.8354201698512, frm:0});
Replay.hist.push({time:70.25000000000183, x:410.3857715857986, y:865.3962776594838, frm:0});
Replay.hist.push({time:70.26760000000183, x:410.13602716222806, y:866.0062053013439, frm:0});
Replay.hist.push({time:70.28330000000183, x:409.9152523731641, y:866.5508360810452, frm:0});
Replay.hist.push({time:70.30100000000182, x:409.66863776128685, y:867.165407060282, frm:0});
Replay.hist.push({time:70.31770000000184, x:409.4381586260073, y:867.7458190461664, frm:0});
Replay.hist.push({time:70.33440000000184, x:409.20981772419725, y:868.3267527792515, frm:0});
Replay.hist.push({time:70.35100000000183, x:408.98495860701996, y:868.9047122732593, frm:0});
Replay.hist.push({time:70.36770000000185, x:408.7608687482451, y:869.4866450198697, frm:0});
Replay.hist.push({time:70.38430000000184, x:408.54042105344786, y:870.0650581575371, frm:0});
Replay.hist.push({time:70.40090000000185, x:408.32217776322625, y:870.6436330572398, frm:0});
Replay.hist.push({time:70.41750000000185, x:408.1060207424315, y:871.2226539343086, frm:0});
Replay.hist.push({time:70.43350000000186, x:407.89964503937887, y:871.781162087826, frm:0});
Replay.hist.push({time:70.44990000000186, x:407.6901151477494, y:872.3540334218605, frm:0});
Replay.hist.push({time:70.46760000000185, x:407.46625288873406, y:872.9727455985854, frm:0});
Replay.hist.push({time:70.48330000000185, x:407.26964802443416, y:873.5219409761023, frm:0});
Replay.hist.push({time:70.50090000000185, x:407.0514545772453, y:874.1379817936003, frm:0});
Replay.hist.push({time:70.51760000000185, x:406.8465580256644, y:874.7229110696059, frm:0});
Replay.hist.push({time:70.53430000000185, x:406.64374371479636, y:875.30819770203, frm:0});
Replay.hist.push({time:70.55060000000185, x:406.44778984057825, y:875.8798016159532, frm:1});
Replay.hist.push({time:70.56660000000186, x:406.2573631193394, y:876.4411952316349, frm:1});
Replay.hist.push({time:70.58350000000185, x:406.05829160842194, y:877.034474724491, frm:1});
Replay.hist.push({time:70.60040000000186, x:405.8601949606537, y:877.631631075728, frm:1});
Replay.hist.push({time:70.61770000000186, x:405.65811418846323, y:878.2495461180305, frm:1});
Replay.hist.push({time:70.63430000000186, x:405.4676888270295, y:878.8407032490114, frm:1});
Replay.hist.push({time:70.65090000000187, x:405.2806358776187, y:879.4301419035406, frm:1});
Replay.hist.push({time:70.66770000000187, x:405.0947264265118, y:880.024938756306, frm:1});
Replay.hist.push({time:70.68340000000187, x:404.9240416549763, y:880.5792258661224, frm:1});
Replay.hist.push({time:70.70100000000186, x:404.7361783677623, y:881.1987691010323, frm:1});
Replay.hist.push({time:70.71750000000185, x:404.5633484031644, y:881.7778815570806, frm:1});
Replay.hist.push({time:70.73430000000185, x:404.39061648781114, y:882.3658171630292, frm:1});
Replay.hist.push({time:70.75100000000185, x:404.2221154781473, y:882.9485595409278, frm:1});
Replay.hist.push({time:70.76690000000185, x:404.0646185016153, y:883.5018316585684, frm:1});
Replay.hist.push({time:70.78440000000185, x:403.89041871961246, y:884.1238825851532, frm:1});
Replay.hist.push({time:70.80090000000185, x:403.72739363633906, y:884.7160883428038, frm:1});
Replay.hist.push({time:70.81770000000185, x:403.5646918061603, y:885.3172833864578, frm:1});
Replay.hist.push({time:70.83430000000185, x:403.40714397608104, y:885.9095735465266, frm:1});
Replay.hist.push({time:70.85090000000186, x:403.2527554496287, y:886.5001329974074, frm:1});
Replay.hist.push({time:70.86760000000186, x:403.1005858091923, y:887.0925114108855, frm:1});
Replay.hist.push({time:70.88430000000186, x:402.9515349508622, y:887.6831559603271, frm:1});
Replay.hist.push({time:70.90050000000186, x:402.80988835375535, y:888.2544734641376, frm:1});
Replay.hist.push({time:70.91770000000187, x:402.6626327112761, y:888.859278580421, frm:1});
Replay.hist.push({time:70.93440000000187, x:402.522703097377, y:889.4447746360205, frm:1});
Replay.hist.push({time:70.95050000000187, x:402.3892504307621, y:890.0134556407168, frm:1});
Replay.hist.push({time:70.96770000000188, x:402.2464874683017, y:890.6335062737127, frm:1});
Replay.hist.push({time:70.98400000000187, x:402.11420996055915, y:891.2193746350888, frm:1});
Replay.hist.push({time:71.00090000000188, x:401.9801225778488, y:891.8250158516885, frm:1});
Replay.hist.push({time:71.01660000000187, x:401.8583048775295, y:892.3860448166313, frm:1});
Replay.hist.push({time:71.03430000000186, x:401.7241072776085, y:893.0166569247953, frm:1});
Replay.hist.push({time:71.05100000000186, x:401.60049053284945, y:893.609852808361, frm:1});
Replay.hist.push({time:71.06770000000188, x:401.47974533036313, y:894.2013110761495, frm:1});
Replay.hist.push({time:71.08430000000187, x:401.3625274996138, y:894.787516046515, frm:1});
Replay.hist.push({time:71.10100000000187, x:401.24738387214325, y:895.3755375613243, frm:1});
Replay.hist.push({time:71.11770000000189, x:401.1341465297502, y:895.9662834178753, frm:1});
Replay.hist.push({time:71.13430000000189, x:401.0217782283146, y:896.5654246803102, frm:1});
Replay.hist.push({time:71.15100000000189, x:400.9115540789403, y:897.1663938649763, frm:1});
Replay.hist.push({time:71.1677000000019, x:400.8041145702224, y:897.7655875414783, frm:1});
Replay.hist.push({time:71.1844000000019, x:400.69941507960846, y:898.3630157682367, frm:1});
Replay.hist.push({time:71.2010000000019, x:400.5980138158053, y:898.9551279881981, frm:1});
Replay.hist.push({time:71.21760000000191, x:400.499232395719, y:899.5455155629977, frm:1});
Replay.hist.push({time:71.23440000000191, x:400.4018842362068, y:900.1412680702455, frm:1});
Replay.hist.push({time:71.25100000000191, x:400.30824275927193, y:900.7282152231388, frm:1});
Replay.hist.push({time:71.26770000000192, x:400.21654943047366, y:901.3169865251265, frm:1});
Replay.hist.push({time:71.28330000000192, x:400.1322817635309, y:901.8711001598537, frm:1});
Replay.hist.push({time:71.3010000000019, x:400.03767833310263, y:902.5090235955399, frm:1});
Replay.hist.push({time:71.31770000000192, x:399.951017082114, y:903.1090927122988, frm:0});
Replay.hist.push({time:71.33430000000192, x:399.8673281831787, y:903.7038244913603, frm:0});
Replay.hist.push({time:71.35100000000192, x:399.7855571572101, y:904.3003925997743, frm:0});
Replay.hist.push({time:71.36760000000193, x:399.7066370236433, y:904.8916651249575, frm:0});
Replay.hist.push({time:71.38440000000193, x:399.62911667912334, y:905.4883192364948, frm:0});
Replay.hist.push({time:71.40090000000193, x:399.55523628651275, y:906.0726286522306, frm:0});
Replay.hist.push({time:71.41760000000193, x:399.4826915174248, y:906.6623198296429, frm:0});
Replay.hist.push({time:71.43400000000192, x:399.4135894954462, y:907.2397680440891, frm:0});
Replay.hist.push({time:71.45090000000192, x:399.343228585385, y:907.8446710068563, frm:0});
Replay.hist.push({time:71.46760000000192, x:399.2755051862292, y:908.444237045738, frm:0});
Replay.hist.push({time:71.48390000000192, x:399.2114924779003, y:909.0277648378562, frm:0});
Replay.hist.push({time:71.50100000000191, x:399.14650728493325, y:909.6381482436298, frm:0});
Replay.hist.push({time:71.51770000000192, x:399.0851354508536, y:910.232515581596, frm:0});
Replay.hist.push({time:71.53440000000192, x:399.02578378484, y:910.8251719244072, frm:0});
Replay.hist.push({time:71.55040000000193, x:398.9707695140749, y:911.3913990906503, frm:0});
Replay.hist.push({time:71.56660000000193, x:398.9168687547045, y:911.9631217149864, frm:0});
Replay.hist.push({time:71.58440000000193, x:398.8596837928427, y:912.5894700724507, frm:0});
Replay.hist.push({time:71.60030000000192, x:398.8100107173382, y:913.151383879249, frm:0});
Replay.hist.push({time:71.61750000000193, x:398.75730298219446, y:913.7672480010548, frm:0});
Replay.hist.push({time:71.63440000000193, x:398.7073891684379, y:914.3706017494638, frm:0});
Replay.hist.push({time:71.65100000000193, x:398.66011939322374, y:914.9615500232458, frm:0});
Replay.hist.push({time:71.66690000000193, x:398.6164302398087, y:915.5260184627872, frm:0});
Replay.hist.push({time:71.68430000000193, x:398.57034940310336, y:916.1419746125792, frm:0});
Replay.hist.push({time:71.70100000000193, x:398.5277697845248, y:916.7314540526806, frm:0});
Replay.hist.push({time:71.71770000000194, x:398.4867538589136, y:917.3192751063266, frm:0});
Replay.hist.push({time:71.73400000000193, x:398.44818057493836, y:917.8914324648719, frm:0});
Replay.hist.push({time:71.75000000000193, x:398.411675485226, y:918.4515464447584, frm:0});
Replay.hist.push({time:71.76770000000194, x:398.3719956569578, y:919.0825836984507, frm:0});
Replay.hist.push({time:71.78430000000193, x:398.3362308523489, y:919.6727898729413, frm:0});
Replay.hist.push({time:71.80100000000193, x:398.30161916333316, y:920.2648916530508, frm:0});
Replay.hist.push({time:71.81680000000193, x:398.2700891042967, y:920.8235723937211, frm:0});
Replay.hist.push({time:71.83440000000193, x:398.23630680597466, y:921.4441498375202, frm:0});
Replay.hist.push({time:71.85060000000193, x:398.20640776536266, y:922.0137794465434, frm:0});
Replay.hist.push({time:71.86760000000193, x:398.17621377660566, y:922.6098933068167, frm:0});
Replay.hist.push({time:71.88430000000193, x:398.14767881690426, y:923.1938708186804, frm:0});
Replay.hist.push({time:71.90100000000193, x:398.12020900706057, y:923.77625427687, frm:0});
Replay.hist.push({time:71.91770000000194, x:398.09328323500597, y:924.3675667453108, frm:0});
Replay.hist.push({time:71.93430000000194, x:398.06746431764697, y:924.9547378945613, frm:0});
Replay.hist.push({time:71.95100000000194, x:398.04243531166264, y:925.5438355911805, frm:0});
Replay.hist.push({time:71.96770000000195, x:398.01830151010887, y:926.1313309839361, frm:0});
Replay.hist.push({time:71.98430000000195, x:397.9951470003967, y:926.713733494864, frm:0});
Replay.hist.push({time:72.00030000000196, x:397.973568358819, y:927.273615650291, frm:0});
Replay.hist.push({time:72.01760000000196, x:397.9509994089437, y:927.8773548651183, frm:0});
Replay.hist.push({time:72.03440000000197, x:397.9297868924396, y:928.4620537764176, frm:0});
Replay.hist.push({time:72.05100000000196, x:397.9094560729776, y:929.038260649989, frm:0});
Replay.hist.push({time:72.06670000000197, x:397.8904533902997, y:929.5907609545707, frm:0});
Replay.hist.push({time:72.08430000000196, x:397.86968581004413, y:930.2096911445465, frm:0});
Replay.hist.push({time:72.10000000000196, x:397.8516244229885, y:930.7603661924499, frm:0});
Replay.hist.push({time:72.11690000000196, x:397.8326192600738, y:931.3515916583422, frm:0});
Replay.hist.push({time:72.13430000000196, x:397.8134668962492, y:931.9586636175668, frm:0});
Replay.hist.push({time:72.15100000000196, x:397.79542473705, y:932.5397694580116, frm:0});
Replay.hist.push({time:72.16760000000197, x:397.77776681997926, y:933.1159027982412, frm:0});
Replay.hist.push({time:72.18330000000196, x:397.7612719096721, y:933.6594498165816, frm:0});
Replay.hist.push({time:72.20000000000196, x:397.7438959783123, y:934.2361670391934, frm:0});
Replay.hist.push({time:72.21770000000197, x:397.725320057295, y:934.8555655607248, frm:0});
Replay.hist.push({time:72.23430000000197, x:397.7079499717955, y:935.4355701966506, frm:0});
Replay.hist.push({time:72.25100000000197, x:397.69048795569137, y:936.0175818322159, frm:0});
Replay.hist.push({time:72.26760000000198, x:397.67308868463283, y:936.5946449024807, frm:0});
Replay.hist.push({time:72.28430000000198, x:397.6554879925981, y:937.1737221244754, frm:0});
Replay.hist.push({time:72.30000000000197, x:397.63880374785543, y:937.7168094277033, frm:0});
Replay.hist.push({time:72.31770000000198, x:397.61977775111376, y:938.327528740998, frm:0});
Replay.hist.push({time:72.33440000000198, x:397.6015594800299, y:938.9022842580932, frm:0});
Replay.hist.push({time:72.35100000000197, x:397.5830837066719, y:939.473888901477, frm:0});
Replay.hist.push({time:72.36770000000199, x:397.56388349864415, y:940.0548282894268, frm:0});
Replay.hist.push({time:72.38430000000199, x:397.5443663728421, y:940.6308735403002, frm:0});
Replay.hist.push({time:72.40100000000199, x:397.5242415890057, y:941.2089753607288, frm:0});
Replay.hist.push({time:72.416700000002, x:397.5048234643423, y:941.7511903441324, frm:0});
Replay.hist.push({time:72.43440000000199, x:397.4822949363173, y:942.360978296367, frm:0});
Replay.hist.push({time:72.45000000000198, x:397.46182778742775, y:942.8971562269343, frm:0});
Replay.hist.push({time:72.46770000000198, x:397.43785432485237, y:943.5040428197226, frm:0});
Replay.hist.push({time:72.48390000000198, x:397.4151577436376, y:944.0581816107417, frm:0});
Replay.hist.push({time:72.50010000000198, x:397.39154425465455, y:944.6144061112097, frm:0});
Replay.hist.push({time:72.51770000000198, x:397.3647276473354, y:945.2220986606978, frm:0});
Replay.hist.push({time:72.53440000000198, x:397.3382987336719, y:945.7973413913252, frm:0});
Replay.hist.push({time:72.55100000000198, x:397.3110225427171, y:946.3678171524193, frm:0});
Replay.hist.push({time:72.56770000000199, x:397.2825147599318, y:946.940409626927, frm:0});
Replay.hist.push({time:72.58430000000199, x:397.25306155970986, y:947.5082762879758, frm:0});
Replay.hist.push({time:72.600300000002, x:397.22356827874177, y:948.0544108487976, frm:0});
Replay.hist.push({time:72.616700000002, x:397.19216132002015, y:948.6129701335954, frm:0});
Replay.hist.push({time:72.63430000000199, x:397.15707305274606, y:949.2110202456472, frm:0});
Replay.hist.push({time:72.65100000000199, x:397.12204199639604, y:949.7828724467255, frm:0});
Replay.hist.push({time:72.667700000002, x:397.0855010298711, y:950.354674144013, frm:0});
Replay.hist.push({time:72.684400000002, x:397.04746692258385, y:950.9252160949603, frm:0});
Replay.hist.push({time:72.701000000002, x:397.00812505198144, y:951.491107063928, frm:0});
Replay.hist.push({time:72.716700000002, x:396.9694570343802, y:952.0252046130006, frm:0});
Replay.hist.push({time:72.734300000002, x:396.9243671558745, y:952.6226319869419, frm:0});
Replay.hist.push({time:72.75000000000199, x:396.88253671037444, y:953.1544513231818, frm:0});
Replay.hist.push({time:72.767700000002, x:396.8335029608155, y:953.7527267000665, frm:0});
Replay.hist.push({time:72.784400000002, x:396.7852463908496, y:954.3172766300484, frm:0});
Replay.hist.push({time:72.800700000002, x:396.73595844891116, y:954.8711893609027, frm:0});
Replay.hist.push({time:72.81760000000199, x:396.68287119085954, y:955.444301008627, frm:1});
Replay.hist.push({time:72.83430000000199, x:396.6283700225076, y:956.0094617601015, frm:1});
Replay.hist.push({time:72.85100000000199, x:396.5717830181775, y:956.5734715962279, frm:1});
Replay.hist.push({time:72.867700000002, x:396.5130546047987, y:957.1363433154058, frm:1});
Replay.hist.push({time:72.884300000002, x:396.45250079120086, y:957.6947303639972, frm:1});
Replay.hist.push({time:72.901000000002, x:396.38933743376583, y:958.2553706830073, frm:1});
Replay.hist.push({time:72.91760000000201, x:396.3242665734268, y:958.8115647539205, frm:1});
Replay.hist.push({time:72.934000000002, x:396.25740685985124, y:959.3622878053005, frm:1});
Replay.hist.push({time:72.951100000002, x:396.18504076373426, y:959.9365843720634, frm:1});
Replay.hist.push({time:72.96770000000201, x:396.11228172093865, y:960.4930177682527, frm:1});
Replay.hist.push({time:72.98430000000201, x:396.03699714210586, y:961.0484032470291, frm:1});
Replay.hist.push({time:73.00100000000201, x:395.9586564516344, y:961.6060884595101, frm:1});
Replay.hist.push({time:73.01770000000202, x:395.87765082263354, y:962.1627382848689, frm:1});
Replay.hist.push({time:73.03420000000202, x:395.7949446544237, y:962.7117192909792, frm:1});
Replay.hist.push({time:73.05030000000201, x:395.7116342567909, y:963.2464450720532, frm:1});
Replay.hist.push({time:73.06770000000202, x:395.6186241605709, y:963.82342406, frm:1});
Replay.hist.push({time:73.08440000000202, x:395.5262519628377, y:964.3771442123011, frm:1});
Replay.hist.push({time:73.10100000000202, x:395.4315139520316, y:964.9265823543199, frm:1});
Replay.hist.push({time:73.11710000000203, x:395.3367989908163, y:965.4585666990695, frm:1});
Replay.hist.push({time:73.13440000000203, x:395.23186658901426, y:966.0292023400546, frm:1});
Replay.hist.push({time:73.15100000000203, x:395.1280473264786, y:966.5758087697103, frm:1});
Replay.hist.push({time:73.16770000000204, x:395.0204554349747, y:967.1247827220698, frm:1});
Replay.hist.push({time:73.18430000000204, x:394.9103272356681, y:967.6695639376284, frm:1});
Replay.hist.push({time:73.20100000000204, x:394.79628438122177, y:968.2167259299251, frm:1});
Replay.hist.push({time:73.21690000000204, x:394.6848202490979, y:968.735959530565, frm:1});
Replay.hist.push({time:73.23410000000204, x:394.5610304499617, y:969.2958204028109, frm:1});
Replay.hist.push({time:73.25100000000204, x:394.4358948763745, y:969.8450288383535, frm:1});
Replay.hist.push({time:73.26700000000204, x:394.3141723683245, y:970.3641965530071, frm:1});
Replay.hist.push({time:73.28440000000205, x:394.1781634633699, y:970.9279017566075, frm:1});
Replay.hist.push({time:73.30100000000205, x:394.04482041180773, y:971.4648617882232, frm:1});
Replay.hist.push({time:73.31760000000206, x:393.9079264367117, y:972.0010167392229, frm:1});
Replay.hist.push({time:73.33430000000206, x:393.766575549286, y:972.5395996810732, frm:1});
Replay.hist.push({time:73.35000000000205, x:393.63031901855754, y:973.0452204248394, frm:1});
Replay.hist.push({time:73.36770000000206, x:393.4740182753137, y:973.6098695869665, frm:1});
Replay.hist.push({time:73.38420000000205, x:393.32480189352736, y:974.1345667258897, frm:1});
Replay.hist.push({time:73.40100000000204, x:393.16909826625516, y:974.6680522731997, frm:1});
Replay.hist.push({time:73.41710000000205, x:393.01626148577475, y:975.1786185668618, frm:1});
Replay.hist.push({time:73.43330000000205, x:392.85885777943247, y:975.6916756799349, frm:1});
Replay.hist.push({time:73.45060000000205, x:392.6867148620656, y:976.2388165947613, frm:1});
Replay.hist.push({time:73.46770000000205, x:392.51239587940887, y:976.7788940025723, frm:1});
Replay.hist.push({time:73.48430000000205, x:392.3391634652272, y:977.3024921409116, frm:1});
Replay.hist.push({time:73.50010000000205, x:392.1706083936211, y:977.8001186982988, frm:2});
Replay.hist.push({time:73.51770000000205, x:391.9817910075741, y:978.3444873785589, frm:2});
Replay.hist.push({time:73.53440000000205, x:391.798525810311, y:978.8603688145815, frm:2});
Replay.hist.push({time:73.55100000000205, x:391.61235769583845, y:979.3725358246231, frm:2});
Replay.hist.push({time:73.56770000000206, x:391.4210018360821, y:979.8871671557745, frm:2});
Replay.hist.push({time:73.58430000000206, x:391.226707063751, y:980.3981117375567, frm:2});
Replay.hist.push({time:73.60060000000206, x:391.03191967046627, y:980.899247081794, frm:2});
Replay.hist.push({time:73.61670000000207, x:390.83558927276835, y:981.3936821961346, frm:2});
Replay.hist.push({time:73.63440000000206, x:390.61520028149585, y:981.9366118837896, frm:2});
Replay.hist.push({time:73.65000000000205, x:390.41695722081005, y:982.4146145148752, frm:2});
Replay.hist.push({time:73.66670000000207, x:390.19963521332767, y:982.9272395461087, frm:2});
Replay.hist.push({time:73.68370000000206, x:389.9747449227454, y:983.4451746991291, frm:2});
Replay.hist.push({time:73.70100000000205, x:389.7421331457967, y:983.9682026045473, frm:2});
Replay.hist.push({time:73.71770000000207, x:389.5140320991063, y:984.4692234593348, frm:2});
Replay.hist.push({time:73.73430000000207, x:389.2838735421425, y:984.9634702026874, frm:2});
Replay.hist.push({time:73.75070000000207, x:389.05317129739876, y:985.4480646050353, frm:2});
Replay.hist.push({time:73.76770000000207, x:388.8105904046263, y:985.9464966438408, frm:2});
Replay.hist.push({time:73.78340000000206, x:388.5834751377551, y:986.4033094613297, frm:2});
Replay.hist.push({time:73.80100000000205, x:388.32540883027247, y:986.9113672482985, frm:2});
Replay.hist.push({time:73.81770000000206, x:388.0771746093094, y:987.3895232620715, frm:2});
Replay.hist.push({time:73.83380000000206, x:387.8347928958504, y:987.8468786407046, frm:2});
Replay.hist.push({time:73.85110000000206, x:387.5658250541146, y:988.3438678845839, frm:2});
Replay.hist.push({time:73.86670000000207, x:387.3171483401306, y:988.7939284043766, frm:2});
Replay.hist.push({time:73.88360000000206, x:387.04446810062217, y:989.2774589776903, frm:2});
Replay.hist.push({time:73.90100000000206, x:386.7601925616145, y:989.7709157770194, frm:2});
Replay.hist.push({time:73.91770000000207, x:386.4840208469385, y:990.2403498809014, frm:2});
Replay.hist.push({time:73.93430000000207, x:386.20631017388433, y:990.7029145348748, frm:2});
Replay.hist.push({time:73.95100000000207, x:385.92375428413413, y:991.1641770059573, frm:2});
Replay.hist.push({time:73.96770000000208, x:385.63805448341157, y:991.6213356472248, frm:2});
Replay.hist.push({time:73.98360000000207, x:385.3631497623915, y:992.0527845868368, frm:2});
Replay.hist.push({time:74.00110000000207, x:385.057377085913, y:992.5233256397231, frm:2});
Replay.hist.push({time:74.01760000000206, x:384.7660237069456, y:992.9628492479504, frm:2});
Replay.hist.push({time:74.03430000000206, x:384.46416446771923, y:993.4094688898755, frm:2});
Replay.hist.push({time:74.05110000000207, x:384.1525654287455, y:993.8614908433234, frm:2});
Replay.hist.push({time:74.06770000000208, x:383.8415984854527, y:994.3037967143321, frm:2});
Replay.hist.push({time:74.08430000000207, x:383.5276140532053, y:994.7417877619592, frm:2});
Replay.hist.push({time:74.10100000000207, x:383.2087343688098, y:995.178058488094, frm:2});
Replay.hist.push({time:74.11770000000209, x:382.8868816282907, y:995.6099542530658, frm:2});
Replay.hist.push({time:74.13440000000209, x:382.5620961745317, y:996.0374711092865, frm:2});
Replay.hist.push({time:74.15070000000209, x:382.2422975606326, y:996.4505249502649, frm:2});
Replay.hist.push({time:74.16700000000208, x:381.9197805261482, y:996.8593997726022, frm:2});
Replay.hist.push({time:74.18430000000208, x:381.5745522453244, y:997.2887744793555, frm:2});
Replay.hist.push({time:74.20100000000208, x:381.2384693945242, y:997.6987897990024, frm:2});
Replay.hist.push({time:74.2177000000021, x:380.8948787049835, y:998.110068289662, frm:2});
Replay.hist.push({time:74.23330000000209, x:380.56739924451205, y:998.4948466299701, frm:2});
Replay.hist.push({time:74.25100000000208, x:380.19288984943785, y:998.9265051690408, frm:2});
Replay.hist.push({time:74.2677000000021, x:379.8366873324969, y:999.3290072396638, frm:2});
Replay.hist.push({time:74.28420000000209, x:379.48207888429084, y:999.7221307387375, frm:2});
Replay.hist.push({time:74.30100000000208, x:379.1183401959063, y:1000.1177386304307, frm:2});
Replay.hist.push({time:74.31700000000208, x:378.76943621822005, y:1000.4901385497024, frm:2});
Replay.hist.push({time:74.33440000000209, x:378.38731134236605, y:1000.890261597274, frm:2});
Replay.hist.push({time:74.35010000000209, x:378.0401286131199, y:1001.2469649203025, frm:2});
Replay.hist.push({time:74.36770000000209, x:377.64830647344206, y:1001.6419210958239, frm:2});
Replay.hist.push({time:74.38350000000207, x:377.2942049378554, y:1001.992082974001, frm:2});
Replay.hist.push({time:74.40100000000207, x:376.8922841484387, y:1002.3819368149941, frm:2});
Replay.hist.push({time:74.41760000000208, x:376.5063401108691, y:1002.748877415896, frm:2});
Replay.hist.push({time:74.43380000000208, x:376.12734227978393, y:1003.1023135923275, frm:2});
Replay.hist.push({time:74.45110000000209, x:375.7201010823162, y:1003.4746468846399, frm:2});
Replay.hist.push({time:74.46670000000209, x:375.3506729208841, y:1003.8058908985963, frm:2});
Replay.hist.push({time:74.48440000000208, x:374.9290622124458, y:1004.1765207976891, frm:2});
Replay.hist.push({time:74.50100000000208, x:374.5313007272481, y:1004.5191099410948, frm:2});
Replay.hist.push({time:74.5177000000021, x:374.12889860924923, y:1004.8588596081051, frm:2});
Replay.hist.push({time:74.53340000000209, x:373.74856830359107, y:1005.1737839344661, frm:2});
Replay.hist.push({time:74.55040000000209, x:373.33459575672487, y:1005.5098672044986, frm:2});
Replay.hist.push({time:74.56770000000209, x:372.9089539265649, y:1005.8482918090465, frm:2});
Replay.hist.push({time:74.58430000000209, x:372.4916939548055, y:1006.1731748481333, frm:2});
Replay.hist.push({time:74.60100000000209, x:372.069792547406, y:1006.4948859329625, frm:2});
Replay.hist.push({time:74.6177000000021, x:371.64580023978584, y:1006.8114492600926, frm:2});
Replay.hist.push({time:74.6343000000021, x:371.2223173831732, y:1007.1210121537233, frm:2});
Replay.hist.push({time:74.6510000000021, x:370.7942873566441, y:1007.4272990775667, frm:2});
Replay.hist.push({time:74.66770000000211, x:370.36429804814753, y:1007.7284266166038, frm:2});
Replay.hist.push({time:74.68440000000211, x:369.93239307495026, y:1008.0243909976512, frm:2});
Replay.hist.push({time:74.70100000000211, x:369.50121790084916, y:1008.3134632054727, frm:2});
Replay.hist.push({time:74.71770000000213, x:369.0656225836749, y:1008.5991211323915, frm:2});
Replay.hist.push({time:74.73440000000213, x:368.62824116918273, y:1008.8796049328967, frm:2});
Replay.hist.push({time:74.75000000000212, x:368.21181683168356, y:1009.1408323524355, frm:2});
Replay.hist.push({time:74.76770000000212, x:367.7358729545728, y:1009.432556069004, frm:2});
Replay.hist.push({time:74.78440000000212, x:367.2850308205531, y:1009.7022661502976, frm:2});
Replay.hist.push({time:74.80020000000212, x:366.8569291911321, y:1009.9524920608884, frm:2});
Replay.hist.push({time:74.81780000000211, x:366.37835012725543, y:1010.2255390641632, frm:2});
Replay.hist.push({time:74.83440000000212, x:365.92533231319936, y:1010.4775933196092, frm:2});
Replay.hist.push({time:74.85010000000212, x:365.4954594275428, y:1010.711084371506, frm:2});
Replay.hist.push({time:74.86730000000212, x:365.0230044912792, y:1010.9614013293619, frm:2});
Replay.hist.push({time:74.88430000000211, x:364.5545180802855, y:1011.2031811951431, frm:2});
Replay.hist.push({time:74.90110000000212, x:364.09009895186614, y:1011.4366177509486, frm:2});
Replay.hist.push({time:74.91770000000213, x:363.6260034643928, y:1011.663756410601, frm:2});
Replay.hist.push({time:74.93440000000213, x:363.1542475200544, y:1011.8884321911713, frm:2});
Replay.hist.push({time:74.95100000000213, x:362.683995082571, y:1012.1062296482056, frm:2});
Replay.hist.push({time:74.96770000000214, x:362.2096273250542, y:1012.3197683345144, frm:2});
Replay.hist.push({time:74.98440000000214, x:361.7340169482695, y:1012.5277164706318, frm:2});
Replay.hist.push({time:75.00110000000214, x:361.2572086017369, y:1012.7300706037512, frm:2});
Replay.hist.push({time:75.01770000000215, x:360.78211109843363, y:1012.9256662446489, frm:2});
Replay.hist.push({time:75.03440000000215, x:360.30304653483887, y:1013.1168557840926, frm:2});
Replay.hist.push({time:75.05000000000214, x:359.85456294954196, y:1013.2903937481972, frm:3});
Replay.hist.push({time:75.06770000000215, x:359.34464961631755, y:1013.4813585307735, frm:3});
Replay.hist.push({time:75.08440000000215, x:358.8606615298581, y:1013.6564230839807, frm:3});
Replay.hist.push({time:75.10100000000214, x:358.3744274082101, y:1013.8262783908601, frm:3});
Replay.hist.push({time:75.11770000000216, x:357.8843502761112, y:1013.9914160657961, frm:3});
Replay.hist.push({time:75.13440000000216, x:357.3934001527891, y:1014.1507927780501, frm:3});
Replay.hist.push({time:75.15110000000216, x:356.9016216630018, y:1014.3044053310273, frm:3});
Replay.hist.push({time:75.16720000000217, x:356.4267628644388, y:1014.447040557071, frm:3});
Replay.hist.push({time:75.18440000000217, x:355.9187124715724, y:1014.5934921002594, frm:3});
Replay.hist.push({time:75.20110000000217, x:355.42471879173877, y:1014.7298281686506, frm:3});
Replay.hist.push({time:75.21770000000218, x:354.9330358249952, y:1014.8596235921904, frm:3});
Replay.hist.push({time:75.23440000000218, x:354.4377853478693, y:1014.9844387090764, frm:3});
Replay.hist.push({time:75.25100000000218, x:353.94434830007737, y:1015.1029148685399, frm:3});
Replay.hist.push({time:75.2677000000022, x:353.44368515803103, y:1015.2171527395736, frm:3});
Replay.hist.push({time:75.2844000000022, x:352.9425319591767, y:1015.3255024078981, frm:3});
Replay.hist.push({time:75.30100000000219, x:352.44393649039256, y:1015.427365263434, frm:3});
Replay.hist.push({time:75.3177000000022, x:351.94193747025685, y:1015.5239653445068, frm:3});
Replay.hist.push({time:75.3344000000022, x:351.43957977070136, y:1015.6146688906028, frm:3});
Replay.hist.push({time:75.3510000000022, x:350.9399166495821, y:1015.6989831722434, frm:3});
Replay.hist.push({time:75.36770000000222, x:350.4369734104208, y:1015.7779210567114, frm:3});
Replay.hist.push({time:75.38340000000221, x:349.9639263488084, y:1015.8467490634152, frm:3});
Replay.hist.push({time:75.4010000000022, x:349.4334575826517, y:1015.9176969805796, frm:3});
Replay.hist.push({time:75.41770000000221, x:348.9299567351241, y:1015.9789498927726, frm:3});
Replay.hist.push({time:75.43340000000221, x:348.4546477642411, y:1016.0313390670818, frm:3});
Replay.hist.push({time:75.4500000000022, x:347.95201017526824, y:1016.0810039475957, frm:3});
Replay.hist.push({time:75.46770000000221, x:347.4160571762493, y:1016.1274682538104, frm:3});
Replay.hist.push({time:75.4836000000022, x:346.9346104923772, y:1016.1634953168084, frm:3});
Replay.hist.push({time:75.5010000000022, x:346.4078307105959, y:1016.1967207382584, frm:3});
Replay.hist.push({time:75.51770000000221, x:345.9023457051752, y:1016.2225151341621, frm:3});
Replay.hist.push({time:75.53440000000221, x:345.39701139793937, y:1016.24233761094, frm:3});
Replay.hist.push({time:75.5510000000022, x:344.8948925685974, y:1016.2561210253448, frm:3});
Replay.hist.push({time:75.56770000000222, x:344.38998323564186, y:1016.2640294241046, frm:3});
Replay.hist.push({time:75.58430000000222, x:343.8883688152447, y:1016.2659662490599, frm:3});
Replay.hist.push({time:75.60100000000222, x:343.3847341479536, y:1016.2619625002155, frm:3});
Replay.hist.push({time:75.61780000000222, x:342.8786274450427, y:1016.2519206622243, frm:3});
Replay.hist.push({time:75.63440000000223, x:342.37893842984346, y:1016.2360714503792, frm:3});
Replay.hist.push({time:75.65110000000223, x:341.87667675181933, y:1016.2141804426926, frm:3});
Replay.hist.push({time:75.66780000000223, x:341.37489294153534, y:1016.1863236522114, frm:3});
Replay.hist.push({time:75.68440000000224, x:340.87662592136417, y:1016.1527197748383, frm:3});
Replay.hist.push({time:75.70110000000224, x:340.3759144406296, y:1016.1129626398108, frm:3});
Replay.hist.push({time:75.71690000000224, x:339.90272700604226, y:1016.0698509725147, frm:3});
Replay.hist.push({time:75.73440000000224, x:339.3793117267552, y:1016.0158638439207, frm:3});
Replay.hist.push({time:75.75110000000224, x:338.88050645271085, y:1015.9582279772791, frm:3});
Replay.hist.push({time:75.76680000000223, x:338.41410449644656, y:1015.8988457936861, frm:3});
Replay.hist.push({time:75.78440000000224, x:337.8943592190376, y:1015.8263869668011, frm:3});
Replay.hist.push({time:75.80100000000223, x:337.40492902402946, y:1015.7520633811408, frm:3});
Replay.hist.push({time:75.81670000000224, x:336.94276918882895, y:1015.676425146054, frm:3});
Replay.hist.push({time:75.83440000000223, x:336.4226667218883, y:1015.5849262577547, frm:3});
Replay.hist.push({time:75.85110000000223, x:335.93285769881555, y:1015.4925407884227, frm:3});
Replay.hist.push({time:75.86690000000223, x:335.47029295480064, y:1015.3997200344992, frm:3});
Replay.hist.push({time:75.88440000000223, x:334.95898942821526, y:1015.2907735440425, frm:3});
Replay.hist.push({time:75.90010000000223, x:334.501196172927, y:1015.1875338189462, frm:3});
Replay.hist.push({time:75.91770000000223, x:333.98911703314786, y:1015.0656260739759, frm:3});
Replay.hist.push({time:75.93440000000223, x:333.50472533517245, y:1014.9440144558744, frm:3});
Replay.hist.push({time:75.95110000000223, x:333.02878613592424, y:1014.8185133392913, frm:3});
Replay.hist.push({time:75.96770000000224, x:332.55680451064495, y:1014.6881209138496, frm:3});
Replay.hist.push({time:75.98330000000223, x:332.11428294633527, y:1014.5604528791846, frm:3});
Replay.hist.push({time:76.00070000000224, x:331.6219423101602, y:1014.4121984794419, frm:3});
Replay.hist.push({time:76.01780000000223, x:331.13937700276773, y:1014.2604771071208, frm:3});
Replay.hist.push({time:76.03440000000224, x:330.67217418399855, y:1014.1074797931456, frm:3});
Replay.hist.push({time:76.05010000000223, x:330.2314627624165, y:1013.9575978120669, frm:3});
Replay.hist.push({time:76.06770000000223, x:329.7388258328694, y:1013.7836022000206, frm:3});
Replay.hist.push({time:76.08440000000223, x:329.27276091764054, y:1013.6126538334779, frm:3});
Replay.hist.push({time:76.10060000000223, x:328.82197009109683, y:1013.4413815408881, frm:3});
Replay.hist.push({time:76.11770000000223, x:328.3513789699474, y:1013.256300607583, frm:3});
Replay.hist.push({time:76.13340000000223, x:327.92769439957436, y:1013.0841146250666, frm:3});
Replay.hist.push({time:76.15060000000223, x:327.46498756947545, y:1012.8899902677942, frm:3});
Replay.hist.push({time:76.16770000000223, x:327.00649502186633, y:1012.691300393625, frm:3});
Replay.hist.push({time:76.18340000000222, x:326.5868929330391, y:1012.5038731095525, frm:3});
Replay.hist.push({time:76.20050000000221, x:326.13140724648605, y:1012.2942959181171, frm:3});
Replay.hist.push({time:76.21780000000221, x:325.6722389681324, y:1012.0764939806738, frm:3});
Replay.hist.push({time:76.23440000000222, x:325.2332261063484, y:1011.8620418820761, frm:3});
Replay.hist.push({time:76.25110000000222, x:324.7931639883166, y:1011.6409040706094, frm:3});
Replay.hist.push({time:76.26770000000224, x:324.35734957747246, y:1011.4157288435226, frm:3});
Replay.hist.push({time:76.28440000000224, x:323.92056301196453, y:1011.183805313944, frm:3});
Replay.hist.push({time:76.30100000000223, x:323.4918120555984, y:1010.9499834369109, frm:3});
Replay.hist.push({time:76.31770000000225, x:323.072615987572, y:1010.7153851635705, frm:3});
Replay.hist.push({time:76.33440000000225, x:322.65505993590716, y:1010.4757372441634, frm:3});
Replay.hist.push({time:76.35010000000224, x:322.2640166865548, y:1010.2458302712722, frm:2});
Replay.hist.push({time:76.36770000000224, x:321.8274454928618, y:1009.9828089870418, frm:2});
Replay.hist.push({time:76.38340000000224, x:321.4395947411557, y:1009.7434426926193, frm:2});
Replay.hist.push({time:76.40040000000224, x:321.0213765851663, y:1009.4792376104995, frm:2});
Replay.hist.push({time:76.41780000000223, x:320.595216905431, y:1009.2034081373201, frm:2});
Replay.hist.push({time:76.43440000000224, x:320.19045567685504, y:1008.9351556049367, frm:2});
Replay.hist.push({time:76.45100000000224, x:319.78748912332867, y:1008.6619248003528, frm:2});
Replay.hist.push({time:76.46770000000225, x:319.3839314707377, y:1008.3820270996108, frm:2});
Replay.hist.push({time:76.48350000000224, x:319.0038314206844, y:1008.112572787915, frm:2});
Replay.hist.push({time:76.50110000000224, x:318.5942694390326, y:1007.8158029840881, frm:2});
Replay.hist.push({time:76.51670000000225, x:318.23778081907585, y:1007.5519736335232, frm:2});
Replay.hist.push({time:76.53340000000225, x:317.8578976275495, y:1007.2650867398964, frm:2});
Replay.hist.push({time:76.55100000000223, x:317.4595118214481, y:1006.9577548073794, frm:2});
Replay.hist.push({time:76.56780000000224, x:317.0811274179943, y:1006.6596117326359, frm:2});
Replay.hist.push({time:76.58440000000225, x:316.7090898703618, y:1006.3604375719829, frm:2});
Replay.hist.push({time:76.60100000000224, x:316.3389057698647, y:1006.0567140730379, frm:2});
Replay.hist.push({time:76.61770000000226, x:315.96838322266774, y:1005.7465734466414, frm:2});
Replay.hist.push({time:76.63440000000226, x:315.5997775164358, y:1005.4318332268692, frm:2});
Replay.hist.push({time:76.65110000000226, x:315.2331090465446, y:1005.1124958209195, frm:2});
Replay.hist.push({time:76.66780000000226, x:314.86839809652065, y:1004.7885636698977, frm:2});
Replay.hist.push({time:76.68450000000226, x:314.50566483826753, y:1004.4600392487296, frm:2});
Replay.hist.push({time:76.70110000000227, x:314.1569893974626, y:1004.1381661021883, frm:2});
Replay.hist.push({time:76.71710000000228, x:313.83002822967876, y:1003.8308223180666, frm:2});
Replay.hist.push({time:76.73410000000227, x:313.484504701765, y:1003.500120359769, frm:2});
Replay.hist.push({time:76.75100000000226, x:313.1429340565711, y:1003.1671205873249, frm:2});
Replay.hist.push({time:76.76770000000228, x:312.8073027054553, y:1002.8339078308102, frm:2});
Replay.hist.push({time:76.78440000000228, x:312.4735753623954, y:1002.4965703565593, frm:2});
Replay.hist.push({time:76.80110000000228, x:312.14176852887897, y:1002.1551110034636, frm:2});
Replay.hist.push({time:76.81780000000228, x:311.8118986120996, y:1001.8095326385137, frm:2});
Replay.hist.push({time:76.83450000000228, x:311.4839819251499, y:1001.4598381567266, frm:2});
Replay.hist.push({time:76.85060000000229, x:311.1697065813136, y:1001.1188084176417, frm:2});
Replay.hist.push({time:76.86770000000229, x:310.83793968926585, y:1000.7524218564979, frm:2});
Replay.hist.push({time:76.88440000000229, x:310.5159555735206, y:1000.3904458267882, frm:2});
Replay.hist.push({time:76.90110000000229, x:310.1959888125852, y:1000.0243655029461, frm:2});
Replay.hist.push({time:76.91750000000228, x:309.89496298905266, y:999.6740337824945, frm:2});
Replay.hist.push({time:76.93440000000228, x:309.5930393443033, y:999.3167681840656, frm:2});
Replay.hist.push({time:76.95010000000228, x:309.31424505714574, y:998.9815265212795, frm:2});
Replay.hist.push({time:76.96770000000228, x:309.00368464977356, y:998.601916609661, frm:2});
Replay.hist.push({time:76.98450000000227, x:308.7091799961753, y:998.2357963537258, frm:2});
Replay.hist.push({time:77.00110000000228, x:308.42005890789795, y:997.8704304177766, frm:2});
Replay.hist.push({time:77.01770000000229, x:308.1328180089016, y:997.5014866998115, frm:2});
Replay.hist.push({time:77.0344000000023, x:307.84575713695466, y:997.1267143757618, frm:2});
Replay.hist.push({time:77.0511000000023, x:307.5606241727088, y:996.7483275302864, frm:2});
Replay.hist.push({time:77.0677000000023, x:307.27912082906164, y:996.3686266783579, frm:2});
Replay.hist.push({time:77.0844000000023, x:306.99786915429985, y:995.9830422429188, frm:2});
Replay.hist.push({time:77.1006000000023, x:306.7269123240109, y:995.6055532731659, frm:2});
Replay.hist.push({time:77.1176000000023, x:306.44458077130344, y:995.2057864858551, frm:2});
Replay.hist.push({time:77.1344000000023, x:306.16759428048846, y:994.8070580974256, frm:2});
Replay.hist.push({time:77.1511000000023, x:305.9081014066872, y:994.427503867292, frm:1});
Replay.hist.push({time:77.1668000000023, x:305.6685664806239, y:994.0718633458382, frm:1});
Replay.hist.push({time:77.1844000000023, x:305.4019407100118, y:993.669902839875, frm:1});
Replay.hist.push({time:77.2011000000023, x:305.1507958694721, y:993.2852678518032, frm:1});
Replay.hist.push({time:77.2171000000023, x:304.911874710494, y:992.9138101076603, frm:1});
Replay.hist.push({time:77.23440000000231, x:304.65543167794084, y:992.5089517654494, frm:1});
Replay.hist.push({time:77.2500000000023, x:304.4258640940345, y:992.1409853041237, frm:1});
Replay.hist.push({time:77.2668000000023, x:304.1804411198701, y:991.741677730995, frm:1});
Replay.hist.push({time:77.28360000000231, x:303.93689025331906, y:991.3392131890847, frm:1});
Replay.hist.push({time:77.30110000000231, x:303.6851965179168, y:990.9166333421529, frm:1});
Replay.hist.push({time:77.3173000000023, x:303.4540214034497, y:990.5223863168699, frm:1});
Replay.hist.push({time:77.33340000000231, x:303.22602312060764, y:990.1276733205806, frm:1});
Replay.hist.push({time:77.3511000000023, x:302.97739964074816, y:989.6904204706077, frm:1});
Replay.hist.push({time:77.3678000000023, x:302.74476902233744, y:989.2746663838736, frm:1});
Replay.hist.push({time:77.38440000000232, x:302.5154181411127, y:988.8583303529978, frm:1});
Replay.hist.push({time:77.40070000000232, x:302.29123148154537, y:988.4479723644009, frm:1});
Replay.hist.push({time:77.41770000000231, x:302.05865083277035, y:988.0229581544017, frm:0});
Replay.hist.push({time:77.43440000000231, x:301.8314248074241, y:987.6084856429516, frm:0});
Replay.hist.push({time:77.45110000000231, x:301.60541902614096, y:987.1970168862717, frm:0});
Replay.hist.push({time:77.46770000000232, x:301.3819552075903, y:986.7909744401858, frm:0});
Replay.hist.push({time:77.48440000000232, x:301.15832196942733, y:986.3854547905214, frm:0});
Replay.hist.push({time:77.50080000000231, x:300.939833139701, y:985.9901010073717, frm:0});
Replay.hist.push({time:77.51770000000232, x:300.71583571712665, y:985.585675120752, frm:0});
Replay.hist.push({time:77.53340000000232, x:300.5087651405591, y:985.2126496873022, frm:0});
Replay.hist.push({time:77.55110000000231, x:300.27649368458464, y:984.7952242963871, frm:0});
Replay.hist.push({time:77.56780000000231, x:300.05845217011597, y:984.4043697512712, frm:0});
Replay.hist.push({time:77.5836000000023, x:299.8531332154178, y:984.037237925391, frm:0});
Replay.hist.push({time:77.6007000000023, x:299.6211934614456, y:983.6236144401568, frm:0});
Replay.hist.push({time:77.61780000000229, x:299.3843804916791, y:983.2025596725023, frm:0});
Replay.hist.push({time:77.6344000000023, x:299.155635929827, y:982.7971041407292, frm:0});
Replay.hist.push({time:77.6511000000023, x:298.92663004076695, y:982.3924645555819, frm:0});
Replay.hist.push({time:77.66770000000231, x:298.7000807297684, y:981.9934693515002, frm:0});
Replay.hist.push({time:77.68430000000231, x:298.4745906383228, y:981.5976714066098, frm:0});
Replay.hist.push({time:77.70010000000231, x:298.260925948087, y:981.223896430022, frm:0});
Replay.hist.push({time:77.71770000000231, x:298.02401102680074, y:980.8109357976941, frm:0});
Replay.hist.push({time:77.73440000000231, x:297.80023500203157, y:980.4223590143946, frm:0});
Replay.hist.push({time:77.75110000000231, x:297.57743722407974, y:980.0369558629194, frm:0});
Replay.hist.push({time:77.76780000000231, x:297.35559448607984, y:979.6547108768557, frm:0});
Replay.hist.push({time:77.78440000000232, x:297.132738533121, y:979.2722775212907, frm:0});
Replay.hist.push({time:77.80110000000232, x:296.89597972974855, y:978.8677460975318, frm:0});
Replay.hist.push({time:77.81780000000232, x:296.660230203444, y:978.4667931880958, frm:0});
Replay.hist.push({time:77.83440000000233, x:296.4268640657299, y:978.0717684201783, frm:0});
Replay.hist.push({time:77.85110000000233, x:296.1930428867519, y:977.6778961763121, frm:0});
Replay.hist.push({time:77.86780000000233, x:295.96014683223336, y:977.2875474553651, frm:0});
Replay.hist.push({time:77.88390000000234, x:295.7364647523199, y:976.9145364708163, frm:0});
Replay.hist.push({time:77.90110000000234, x:295.49839996801586, y:976.5196322335288, frm:0});
Replay.hist.push({time:77.91770000000236, x:295.269483775184, y:976.1419877607342, frm:0});
Replay.hist.push({time:77.93440000000236, x:295.0400015347976, y:975.7655099982342, frm:0});
Replay.hist.push({time:77.95040000000236, x:294.82087354238377, y:975.4080269214082, frm:0});
Replay.hist.push({time:77.96770000000237, x:294.5774924007345, y:975.0133292993507, frm:0});
Replay.hist.push({time:77.98450000000236, x:294.3317497275745, y:974.6173701130969, frm:0});
Replay.hist.push({time:78.00110000000237, x:294.0897247995427, y:974.2299808394405, frm:0});
Replay.hist.push({time:78.01740000000237, x:293.85280782202244, y:973.853300942696, frm:0});
Replay.hist.push({time:78.03380000000236, x:293.6151425371632, y:973.4780021868055, frm:0});
Replay.hist.push({time:78.05040000000237, x:293.3752680404427, y:973.1018783156285, frm:0});
Replay.hist.push({time:78.06770000000238, x:293.12598472938413, y:972.713892711646, frm:0});
Replay.hist.push({time:78.08450000000236, x:292.8845549133353, y:972.340993283374, frm:0});
Replay.hist.push({time:78.10110000000238, x:292.64659633777813, y:971.9762627853102, frm:1});
Replay.hist.push({time:78.11680000000237, x:292.4220524912834, y:971.6346933075555, frm:1});
Replay.hist.push({time:78.13420000000238, x:292.16838286165574, y:971.2519060105527, frm:1});
Replay.hist.push({time:78.15010000000237, x:291.9254635570544, y:970.8884609031013, frm:1});
Replay.hist.push({time:78.16770000000237, x:291.65714111752595, y:970.4906150322904, frm:1});
Replay.hist.push({time:78.18440000000237, x:291.4030356231988, y:970.11739359321, frm:1});
Replay.hist.push({time:78.20110000000237, x:291.1493792564526, y:969.7483231146683, frm:1});
Replay.hist.push({time:78.21780000000237, x:290.89613344749114, y:969.3833788717177, frm:1});
Replay.hist.push({time:78.23440000000238, x:290.6447723597813, y:969.0246838875744, frm:1});
Replay.hist.push({time:78.25110000000238, x:290.3922308495679, y:968.6678939487947, frm:1});
Replay.hist.push({time:78.26680000000238, x:290.15507636283854, y:968.336156626627, frm:1});
Replay.hist.push({time:78.28440000000238, x:289.88950702612954, y:967.9685240391767, frm:1});
Replay.hist.push({time:78.30010000000237, x:289.6395219333661, y:967.626222286322, frm:1});
Replay.hist.push({time:78.31780000000236, x:289.35455797514095, y:967.2405142739627, frm:1});
Replay.hist.push({time:78.33440000000238, x:289.0874462052398, y:966.8833545682165, frm:1});
Replay.hist.push({time:78.35110000000238, x:288.81882677044047, y:966.5284962759767, frm:1});
Replay.hist.push({time:78.36680000000237, x:288.56633788258523, y:966.1989254486137, frm:1});
Replay.hist.push({time:78.38360000000237, x:288.2961792212125, y:965.850590200347, frm:1});
Replay.hist.push({time:78.40110000000237, x:288.0147354039075, y:965.4924623681344, frm:1});
Replay.hist.push({time:78.41780000000237, x:287.74398923390106, y:965.1525560480295, frm:1});
Replay.hist.push({time:78.43350000000237, x:287.48749209550135, y:964.8347287969846, frm:1});
Replay.hist.push({time:78.45010000000238, x:287.19980157818674, y:964.4831213822521, frm:1});
Replay.hist.push({time:78.46780000000237, x:286.8874579734946, y:964.1072373864852, frm:2});
Replay.hist.push({time:78.48440000000238, x:286.5919803273457, y:963.7572770097038, frm:2});
Replay.hist.push({time:78.50110000000238, x:286.2920989856132, y:963.4077066821479, frm:2});
Replay.hist.push({time:78.51770000000239, x:285.9913472693576, y:963.0628010740227, frm:2});
Replay.hist.push({time:78.53440000000239, x:285.6860171408449, y:962.718465813919, frm:2});
Replay.hist.push({time:78.55110000000239, x:285.37784247771486, y:962.376867653692, frm:2});
Replay.hist.push({time:78.5677000000024, x:285.0686227346259, y:962.0401068730471, frm:2});
Replay.hist.push({time:78.5845000000024, x:284.73925758588183, y:961.6879982047328, frm:2});
Replay.hist.push({time:78.6005000000024, x:284.41967508628056, y:961.3528281137776, frm:2});
Replay.hist.push({time:78.61770000000242, x:284.07255820867806, y:960.9959830045012, frm:2});
Replay.hist.push({time:78.6345000000024, x:283.7299446068654, y:960.651085332453, frm:2});
Replay.hist.push({time:78.65110000000242, x:283.38782769718944, y:960.313898574853, frm:2});
Replay.hist.push({time:78.66760000000241, x:283.0441314740924, y:959.9823610729879, frm:2});
Replay.hist.push({time:78.68440000000241, x:282.6903204736022, y:959.6485519283099, frm:3});
Replay.hist.push({time:78.70110000000241, x:282.3278521095421, y:959.3143799134748, frm:3});
Replay.hist.push({time:78.71780000000241, x:281.95429990903796, y:958.9781720782194, frm:3});
Replay.hist.push({time:78.73440000000242, x:281.5785930743365, y:958.6483099854931, frm:3});
Replay.hist.push({time:78.75100000000242, x:281.19836518536334, y:958.3228349581162, frm:3});
Replay.hist.push({time:78.76770000000243, x:280.8111368813797, y:957.999893609485, frm:3});
Replay.hist.push({time:78.78440000000244, x:280.419045503667, y:957.6815382631175, frm:3});
Replay.hist.push({time:78.80110000000244, x:280.021941256308, y:957.3678403354634, frm:3});
Replay.hist.push({time:78.81780000000244, x:279.61325184471957, y:957.0540112227018, frm:3});
Replay.hist.push({time:78.83360000000243, x:279.21945034503074, y:956.7601217481163, frm:3});
Replay.hist.push({time:78.85020000000245, x:278.8001847431794, y:956.4562373943734, frm:3});
Replay.hist.push({time:78.86780000000243, x:278.3492774519482, y:956.1395835172676, frm:3});
Replay.hist.push({time:78.88450000000243, x:277.9152985035709, y:955.8445556972133, frm:3});
Replay.hist.push({time:78.90110000000244, x:277.4777641266654, y:955.5565656220699, frm:3});
Replay.hist.push({time:78.91780000000244, x:277.03076220843275, y:955.2719236583807, frm:4});
Replay.hist.push({time:78.93440000000246, x:276.5791103040634, y:954.9939202292819, frm:4});
Replay.hist.push({time:78.95110000000246, x:276.11792607784685, y:954.71975830968, frm:4});
Replay.hist.push({time:78.96780000000246, x:275.64971433282017, y:954.451194305741, frm:4});
Replay.hist.push({time:78.98350000000245, x:275.2030149182899, y:954.2039083238044, frm:4});
Replay.hist.push({time:79.00110000000245, x:274.69428637884187, y:953.9326184101246, frm:4});
Replay.hist.push({time:79.01740000000245, x:274.2171445694727, y:953.6878886298198, frm:4});
Replay.hist.push({time:79.03350000000245, x:273.7449948487098, y:953.4547030284278, frm:4});
Replay.hist.push({time:79.05110000000245, x:273.22035999455517, y:953.2057596377015, frm:4});
Replay.hist.push({time:79.06710000000245, x:272.7357317998147, y:952.9850117413739, frm:4});
Replay.hist.push({time:79.08440000000246, x:272.2030252256046, y:952.7522384231929, frm:4});
Replay.hist.push({time:79.10110000000246, x:271.6801308378313, y:952.5334785148514, frm:4});
Replay.hist.push({time:79.11680000000246, x:271.18219044475177, y:952.3338146382932, frm:4});
Replay.hist.push({time:79.13450000000245, x:270.629070485806, y:952.1215856995292, frm:4});
Replay.hist.push({time:79.15110000000246, x:270.1014988693033, y:951.928210989251, frm:3});
Replay.hist.push({time:79.16760000000245, x:269.56834184834895, y:951.7414472947335, frm:3});
Replay.hist.push({time:79.18340000000245, x:269.04945369004145, y:951.5677461401983, frm:3});
Replay.hist.push({time:79.20080000000245, x:268.4681343476101, y:951.3822494923135, frm:3});
Replay.hist.push({time:79.21770000000245, x:267.89487414914186, y:951.2083926860247, frm:3});
Replay.hist.push({time:79.23340000000245, x:267.37896701412285, y:951.0593392822605, frm:3});
Replay.hist.push({time:79.25100000000243, x:266.79070337819337, y:950.8976224776909, frm:3});
Replay.hist.push({time:79.26780000000244, x:266.2193812980058, y:950.7486511082654, frm:3});
Replay.hist.push({time:79.28450000000244, x:265.64169209001113, y:950.6058053128206, frm:3});
Replay.hist.push({time:79.30110000000245, x:265.0575834594953, y:950.4690204374245, frm:3});
Replay.hist.push({time:79.31680000000244, x:264.49593537288166, y:950.3444654524528, frm:3});
Replay.hist.push({time:79.33420000000245, x:263.8927378213671, y:950.218006143674, frm:3});
Replay.hist.push({time:79.35010000000244, x:263.34028458453207, y:950.108570016261, frm:3});
Replay.hist.push({time:79.36770000000244, x:262.71828559409755, y:949.9923704302355, frm:3});
Replay.hist.push({time:79.38450000000243, x:262.1142689708162, y:949.8863552281775, frm:2});
Replay.hist.push({time:79.40110000000244, x:261.50730859348266, y:949.7863189972841, frm:2});
Replay.hist.push({time:79.41780000000244, x:260.88628483965687, y:949.690428223681, frm:2});
Replay.hist.push({time:79.43450000000244, x:260.2717121990393, y:949.6017048540267, frm:2});
Replay.hist.push({time:79.45110000000246, x:259.67754230346014, y:949.5215260901467, frm:2});
Replay.hist.push({time:79.46670000000246, x:259.1104242157665, y:949.4499297987949, frm:2});
Replay.hist.push({time:79.48450000000246, x:258.45250506742906, y:949.3726634174919, frm:2});
Replay.hist.push({time:79.50110000000247, x:257.8286147876747, y:949.3049075791885, frm:2});
Replay.hist.push({time:79.51770000000248, x:257.19446904939974, y:949.2413077746198, frm:2});
Replay.hist.push({time:79.53440000000248, x:256.54594612621383, y:949.1815321182131, frm:2});
Replay.hist.push({time:79.55110000000248, x:255.91925649111232, y:949.1286179867058, frm:2});
Replay.hist.push({time:79.56780000000248, x:255.29935598265044, y:949.0807734844163, frm:2});
Replay.hist.push({time:79.58450000000248, x:254.66967725501036, y:949.036567218099, frm:2});
Replay.hist.push({time:79.60050000000248, x:254.05711090789129, y:948.9976407537365, frm:1});
Replay.hist.push({time:79.61780000000249, x:253.38420689786074, y:948.9593262458733, frm:1});
Replay.hist.push({time:79.63350000000248, x:252.7640851627042, y:948.9279713702288, frm:1});
Replay.hist.push({time:79.6501000000025, x:252.09826537180308, y:948.8983529795719, frm:1});
Replay.hist.push({time:79.6668000000025, x:251.44675861534995, y:948.873262168075, frm:1});
Replay.hist.push({time:79.6844000000025, x:250.77472016473644, y:948.8512445962047, frm:1});
Replay.hist.push({time:79.7001000000025, x:250.16638016580617, y:948.8345607519688, frm:1});
Replay.hist.push({time:79.71780000000248, x:249.47005074530654, y:948.8190948316374, frm:1});
Replay.hist.push({time:79.7344000000025, x:248.80696828413528, y:948.8078231120339, frm:1});
Replay.hist.push({time:79.7511000000025, x:248.13287005583197, y:948.7996747939856, frm:1});
Replay.hist.push({time:79.7678000000025, x:247.4516618399668, y:948.7946907718258, frm:1});
Replay.hist.push({time:79.7845000000025, x:246.77180988370492, y:948.7928385035711, frm:1});
Replay.hist.push({time:79.8011000000025, x:246.13502490074592, y:948.7938169038105, frm:1});
Replay.hist.push({time:79.8178000000025, x:245.48825278777025, y:948.7973900431373, frm:1});
Replay.hist.push({time:79.83440000000252, x:244.83918734896574, y:948.8034868306553, frm:1});
Replay.hist.push({time:79.85110000000252, x:244.17996079850292, y:948.8121522471338, frm:1});
Replay.hist.push({time:79.86770000000253, x:243.5184164544762, y:948.8232544024451, frm:1});
Replay.hist.push({time:79.88450000000252, x:242.84249528310016, y:948.8369878333021, frm:1});
Replay.hist.push({time:79.90110000000253, x:242.16823685137462, y:948.8529975326067, frm:1});
Replay.hist.push({time:79.91780000000253, x:241.4872986507174, y:948.8714127223061, frm:1});
Replay.hist.push({time:79.93440000000254, x:240.85452561340557, y:948.8904694143022, frm:1});
Replay.hist.push({time:79.95110000000254, x:240.21241695793867, y:948.9116474648471, frm:1});
Replay.hist.push({time:79.96730000000254, x:239.5841973745947, y:948.9340938403379, frm:1});
Replay.hist.push({time:79.98410000000254, x:238.92713592887355, y:948.9593281710711, frm:1});
Replay.hist.push({time:80.00110000000254, x:238.25643203474638, y:948.9868682884251, frm:1});
Replay.hist.push({time:80.01780000000254, x:237.5918202249238, y:949.0158644524734, frm:1});
Replay.hist.push({time:80.03450000000254, x:236.92148210060262, y:949.046763203052, frm:1});
Replay.hist.push({time:80.05110000000255, x:236.24944565991828, y:949.0793409169039, frm:1});
Replay.hist.push({time:80.06780000000255, x:235.57021936253187, y:949.1140531333238, frm:1});
Replay.hist.push({time:80.08370000000255, x:234.93068834543658, y:949.1527333133885, frm:2});
Replay.hist.push({time:80.10110000000255, x:234.23413095229762, y:949.2035886741271, frm:2});
Replay.hist.push({time:80.11780000000255, x:233.56883786512117, y:949.260717191901, frm:2});
Replay.hist.push({time:80.13450000000255, x:232.9067457735022, y:949.3259315958974, frm:2});
Replay.hist.push({time:80.15110000000256, x:232.25180667149326, y:949.3987088469454, frm:2});
Replay.hist.push({time:80.16780000000256, x:231.59614924032198, y:949.4798651481211, frm:2});
Replay.hist.push({time:80.18440000000257, x:230.94764041984243, y:949.5683691195244, frm:3});
Replay.hist.push({time:80.20110000000257, x:230.29848619711296, y:949.665226725294, frm:3});
Replay.hist.push({time:80.21750000000256, x:229.66418897576233, y:949.7679208457009, frm:3});
Replay.hist.push({time:80.23450000000255, x:229.010055858993, y:949.882234095627, frm:3});
Replay.hist.push({time:80.25120000000257, x:228.3708135843504, y:950.002264047604, frm:3});
Replay.hist.push({time:80.26780000000257, x:227.73870587671612, y:950.1291119995545, frm:3});
Replay.hist.push({time:80.28440000000258, x:227.10991264275512, y:950.2634153108202, frm:3});
Replay.hist.push({time:80.30110000000258, x:226.48069368256705, y:950.4059913725833, frm:3});
Replay.hist.push({time:80.31780000000258, x:225.85486251193748, y:950.5559947316553, frm:3});
Replay.hist.push({time:80.33440000000259, x:225.2361515719305, y:950.7124025196725, frm:3});
Replay.hist.push({time:80.35110000000259, x:224.61712510796232, y:950.8770400888096, frm:3});
Replay.hist.push({time:80.3671000000026, x:224.0201629667743, y:951.0436073902647, frm:3});
Replay.hist.push({time:80.3844000000026, x:223.36778928248813, y:951.2344822795454, frm:3});
Replay.hist.push({time:80.4012000000026, x:222.73802639497154, y:951.4275995253198, frm:3});
Replay.hist.push({time:80.4178000000026, x:222.11941698707224, y:951.6258628479344, frm:3});
Replay.hist.push({time:80.43440000000261, x:221.50446164838485, y:951.8314641527335, frm:3});
Replay.hist.push({time:80.45110000000261, x:220.88950752364457, y:952.0456459267161, frm:3});
Replay.hist.push({time:80.4668000000026, x:220.3147713361528, y:952.2536665496185, frm:3});
Replay.hist.push({time:80.48440000000261, x:219.67444325093174, y:952.4944623472678, frm:3});
Replay.hist.push({time:80.50120000000261, x:219.06711630411093, y:952.731754419663, frm:3});
Replay.hist.push({time:80.51780000000261, x:218.4707802126287, y:952.9732960194102, frm:3});
Replay.hist.push({time:80.53440000000262, x:217.87820149943198, y:953.2218080730248, frm:3});
Replay.hist.push({time:80.55110000000262, x:217.28586253888312, y:953.4787892340852, frm:3});
Replay.hist.push({time:80.56780000000262, x:216.69736054186475, y:953.7427026251341, frm:3});
Replay.hist.push({time:80.58350000000262, x:216.1476029583541, y:953.9970847158041, frm:3});
Replay.hist.push({time:80.60010000000263, x:215.57006022167587, y:954.2725939051899, frm:3});
Replay.hist.push({time:80.61750000000262, x:214.96881522543976, y:954.5685382629734, frm:3});
Replay.hist.push({time:80.63450000000262, x:214.38548095816762, y:954.8646960585364, frm:3});
Replay.hist.push({time:80.65110000000263, x:213.81978914211845, y:955.1605164377869, frm:3});
Replay.hist.push({time:80.66780000000263, x:213.2540521889023, y:955.4649754630722, frm:3});
Replay.hist.push({time:80.68450000000263, x:212.69224962412306, y:955.7759719103727, frm:3});
Replay.hist.push({time:80.70040000000263, x:212.16104955069707, y:956.0780811083731, frm:3});
Replay.hist.push({time:80.71780000000263, x:211.5839016733889, y:956.4153344907129, frm:3});
Replay.hist.push({time:80.73400000000262, x:211.05046015496163, y:956.735527349939, frm:3});
Replay.hist.push({time:80.75110000000262, x:210.49150381808437, y:957.0799190133936, frm:3});
Replay.hist.push({time:80.76780000000262, x:209.94971098545778, y:957.4225568863588, frm:3});
Replay.hist.push({time:80.78450000000262, x:209.41197839481657, y:957.7713603474602, frm:3});
Replay.hist.push({time:80.80110000000263, x:208.8815045737997, y:958.1241277579893, frm:3});
Replay.hist.push({time:80.81680000000263, x:208.3835036884015, y:958.4632743527084, frm:3});
Replay.hist.push({time:80.83440000000263, x:207.82957018237303, y:958.8497459295367, frm:3});
Replay.hist.push({time:80.85110000000263, x:207.30818913800604, y:959.222552802284, frm:3});
Replay.hist.push({time:80.86680000000263, x:206.82179489859496, y:959.5783993872267, frm:3});
Replay.hist.push({time:80.88370000000263, x:206.30233910320365, y:959.9671819333704, frm:3});
Replay.hist.push({time:80.90010000000262, x:205.8023303071805, y:960.3501053153043, frm:3});
Replay.hist.push({time:80.91730000000263, x:205.28227520077598, y:960.757610036507, frm:3});
Replay.hist.push({time:80.93450000000263, x:204.76667686081953, y:961.1711012856845, frm:2});
Replay.hist.push({time:80.95120000000264, x:204.27294211062602, y:961.5760887709381, frm:2});
Replay.hist.push({time:80.96780000000264, x:203.79213581807835, y:961.9791387546849, frm:2});
Replay.hist.push({time:80.98450000000264, x:203.31252913767915, y:962.3898808974437, frm:2});
Replay.hist.push({time:81.00120000000265, x:202.83704417898366, y:962.8058496306652, frm:2});
Replay.hist.push({time:81.01780000000265, x:202.36850443212043, y:963.2244545864103, frm:2});
Replay.hist.push({time:81.03450000000265, x:201.90128059647554, y:963.6506840939849, frm:2});
Replay.hist.push({time:81.05020000000266, x:201.46582372353157, y:964.0560185784827, frm:2});
Replay.hist.push({time:81.06770000000266, x:200.98480908798754, y:964.513031644094, frm:2});
Replay.hist.push({time:81.08390000000266, x:200.54361910784132, y:964.9409601777055, frm:2});
Replay.hist.push({time:81.10120000000266, x:200.07685073497868, y:965.4030342505832, frm:2});
Replay.hist.push({time:81.11730000000266, x:199.64651767806026, y:965.8377455906974, frm:2});
Replay.hist.push({time:81.13450000000266, x:199.19113878320383, y:966.3070777209917, frm:2});
Replay.hist.push({time:81.15110000000267, x:198.75591224370274, y:966.7648185533686, frm:2});
Replay.hist.push({time:81.16780000000267, x:198.32231224015877, y:967.229996789866, frm:2});
Replay.hist.push({time:81.18390000000268, x:197.9083323370446, y:967.6828628545379, frm:2});
Replay.hist.push({time:81.20110000000268, x:197.47047901906092, y:968.1713747612533, frm:2});
Replay.hist.push({time:81.21680000000268, x:197.07478200726345, y:968.6215003305757, frm:2});
Replay.hist.push({time:81.23450000000267, x:196.63326559443678, y:969.1337047019458, frm:2});
Replay.hist.push({time:81.25090000000267, x:196.22997472564563, y:969.6109927448564, frm:2});
Replay.hist.push({time:81.26780000000267, x:195.82822233544846, y:970.0956650680876, frm:2});
Replay.hist.push({time:81.28450000000267, x:195.43537705525011, y:970.5787252026661, frm:2});
Replay.hist.push({time:81.30120000000268, x:195.04480565869446, y:971.0681974266407, frm:2});
Replay.hist.push({time:81.31780000000268, x:194.65890277177658, y:971.5610976387062, frm:2});
Replay.hist.push({time:81.33440000000269, x:194.27538939511416, y:972.0603533643383, frm:2});
Replay.hist.push({time:81.35110000000269, x:193.8920449729523, y:972.5690447544663, frm:2});
Replay.hist.push({time:81.36730000000269, x:193.5226510257176, y:973.0686372772824, frm:2});
Replay.hist.push({time:81.3834000000027, x:193.15800008070556, y:973.5711580923407, frm:2});
Replay.hist.push({time:81.40110000000269, x:192.7599421320979, y:974.1306578751814, frm:2});
Replay.hist.push({time:81.41780000000269, x:192.38729104971225, y:974.6651440662442, frm:2});
Replay.hist.push({time:81.43450000000269, x:192.01750604784422, y:975.2061010176964, frm:2});
Replay.hist.push({time:81.4511000000027, x:191.65285590524945, y:975.7502279285164, frm:2});
Replay.hist.push({time:81.4678000000027, x:191.28901540769414, y:976.3040955772786, frm:2});
Replay.hist.push({time:81.4845000000027, x:190.92827237356198, y:976.8644399920611, frm:2});
Replay.hist.push({time:81.50120000000271, x:190.5707052261491, y:977.431261168382, frm:2});
Replay.hist.push({time:81.51780000000271, x:190.2185088168916, y:978.0010992494433, frm:2});
Replay.hist.push({time:81.53450000000271, x:189.8675107551139, y:978.5808310419557, frm:2});
Replay.hist.push({time:81.55120000000272, x:189.51992682508526, y:979.167033452169, frm:2});
Replay.hist.push({time:81.56780000000272, x:189.18760732298864, y:979.7392214149199, frm:2});
Replay.hist.push({time:81.58370000000272, x:188.87289575916228, y:980.2920939857174, frm:2});
Replay.hist.push({time:81.60110000000272, x:188.53196780796807, y:980.9036067145865, frm:2});
Replay.hist.push({time:81.61790000000272, x:188.20639985163976, y:981.5003129161636, frm:2});
Replay.hist.push({time:81.63350000000271, x:187.9073468849673, y:982.0598758949577, frm:2});
Replay.hist.push({time:81.65110000000271, x:187.57368348134736, y:982.6977314819925, frm:2});
Replay.hist.push({time:81.66780000000271, x:187.2609241506574, y:983.3091868573266, frm:2});
Replay.hist.push({time:81.68440000000273, x:186.9537973055312, y:983.9230226184097, frm:2});
Replay.hist.push({time:81.70120000000273, x:186.64685655544068, y:984.5503968713763, frm:2});
Replay.hist.push({time:81.71780000000273, x:186.34750283137637, y:985.1763336739947, frm:2});
Replay.hist.push({time:81.73450000000273, x:186.05036126380136, y:985.8120985211514, frm:2});
Replay.hist.push({time:81.75110000000274, x:185.75907707974073, y:986.4500504498585, frm:2});
Replay.hist.push({time:81.76780000000274, x:185.47021602247077, y:987.0978748580899, frm:2});
Replay.hist.push({time:81.78360000000274, x:185.20088282175996, y:987.7162744132256, frm:2});
Replay.hist.push({time:81.80070000000273, x:184.91374332543054, y:988.3917078038477, frm:2});
Replay.hist.push({time:81.81780000000272, x:184.631259432588, y:989.0734230332081, frm:2});
Replay.hist.push({time:81.83380000000273, x:184.3712725403384, y:989.7168821225251, frm:2});
Replay.hist.push({time:81.85110000000273, x:184.09813620275253, y:990.4104938525425, frm:2});
Replay.hist.push({time:81.86780000000273, x:183.84382589544614, y:991.0735607904362, frm:2});
Replay.hist.push({time:81.88440000000274, x:183.59547205438727, y:991.7382307232031, frm:2});
Replay.hist.push({time:81.90110000000274, x:183.35015750187173, y:992.4125052200775, frm:2});
Replay.hist.push({time:81.91780000000274, x:183.1094754749489, y:993.0923689477103, frm:2});
Replay.hist.push({time:81.93410000000274, x:182.87911294002402, y:993.7612901722489, frm:2});
Replay.hist.push({time:81.95010000000275, x:182.65743792937337, y:994.4230168197256, frm:2});
Replay.hist.push({time:81.96780000000274, x:182.4173835481728, y:995.1610801358822, frm:2});
Replay.hist.push({time:81.98440000000275, x:182.1973355424109, y:995.8588082651853, frm:2});
Replay.hist.push({time:82.00110000000275, x:181.9809894881231, y:996.5661979951237, frm:2});
Replay.hist.push({time:82.01780000000275, x:181.76977233641003, y:997.2790282359082, frm:2});
Replay.hist.push({time:82.03450000000275, x:181.56376881375536, y:997.997270564088, frm:2});
Replay.hist.push({time:82.05110000000276, x:181.3642519663266, y:998.7165394778217, frm:2});
Replay.hist.push({time:82.06680000000276, x:181.18046155878793, y:999.4016187966878, frm:2});
Replay.hist.push({time:82.08440000000276, x:180.98013781445988, y:1000.1753382472265, frm:2});
Replay.hist.push({time:82.10010000000275, x:180.80667724029203, y:1000.8703630900051, frm:2});
Replay.hist.push({time:82.11690000000276, x:180.62656537412406, y:1001.6193069347337, frm:2});
Replay.hist.push({time:82.13450000000275, x:180.44596532276657, y:1002.4013087153832, frm:2});
Replay.hist.push({time:82.15110000000276, x:180.28436930880451, y:1003.1304834115334, frm:2});
Replay.hist.push({time:82.16780000000276, x:180.12734265530847, y:1003.8689684961049, frm:2});
Replay.hist.push({time:82.18450000000276, x:179.97596061641121, y:1004.6123449127102, frm:2});
Replay.hist.push({time:82.20020000000277, x:179.83887881742842, y:1005.3155717543543, frm:2});
Replay.hist.push({time:82.21780000000275, x:179.69128764310585, y:1006.1091061443975, frm:2});
Replay.hist.push({time:82.23450000000275, x:179.55731128321437, y:1006.8669118157267, frm:2});
Replay.hist.push({time:82.25020000000276, x:179.436823876121, y:1007.5836016780654, frm:2});
Replay.hist.push({time:82.26780000000275, x:179.30810862133745, y:1008.3921081246573, frm:2});
Replay.hist.push({time:82.28410000000275, x:179.19501140299624, y:1009.1454291436811, frm:2});
Replay.hist.push({time:82.30100000000274, x:179.0840243586155, y:1009.9311729192892, frm:2});
Replay.hist.push({time:82.31780000000275, x:178.98012042551832, y:1010.7169103649369, frm:2});
Replay.hist.push({time:82.33440000000276, x:178.88383547771474, y:1011.4977902872723, frm:2});
Replay.hist.push({time:82.35120000000276, x:178.79293535771643, y:1012.2926122423825, frm:2});
Replay.hist.push({time:82.36780000000276, x:178.70967603012798, y:1013.0823789488649, frm:2});
Replay.hist.push({time:82.38450000000276, x:178.63258069659256, y:1013.8812978867307, frm:2});
Replay.hist.push({time:82.40020000000277, x:178.56628557260825, y:1014.6362825253068, frm:2});
Replay.hist.push({time:82.41780000000276, x:178.5004283060219, y:1015.4703372997326, frm:2});
Replay.hist.push({time:82.43450000000276, x:178.44520153429687, y:1016.2598221118348, frm:2});
Replay.hist.push({time:82.45110000000277, x:178.3969078487344, y:1017.048578213041, frm:2});
Replay.hist.push({time:82.46780000000277, x:178.35505060971548, y:1017.8460749457897, frm:2});
Replay.hist.push({time:82.48450000000277, x:178.3200281610397, y:1018.6475174748671, frm:2});
Replay.hist.push({time:82.50120000000278, x:178.29200878710947, y:1019.4502046230152, frm:2});
Replay.hist.push({time:82.51780000000278, x:178.27111527498096, y:1020.2492597504835, frm:2});
Replay.hist.push({time:82.53450000000278, x:178.25711379584047, y:1021.0542474634133, frm:2});
Replay.hist.push({time:82.55110000000279, x:178.2501896187589, y:1021.8554697942542, frm:2});
Replay.hist.push({time:82.5672000000028, x:178.25015120010363, y:1022.6335119471364, frm:2});
Replay.hist.push({time:82.58450000000279, x:178.2574565868746, y:1023.4705084470705, frm:2});
Replay.hist.push({time:82.6004000000028, x:178.27089919430495, y:1024.240623822641, frm:2});
Replay.hist.push({time:82.61780000000279, x:178.29301312108555, y:1025.0842302388269, frm:2});
Replay.hist.push({time:82.63450000000279, x:178.32153179719555, y:1025.8946875482006, frm:2});
Replay.hist.push({time:82.6512000000028, x:178.3572126152559, y:1026.705849623239, frm:2});
Replay.hist.push({time:82.6678000000028, x:178.399794933486, y:1027.512795400673, frm:2});
Replay.hist.push({time:82.6845000000028, x:178.4494575345334, y:1028.3198588683167, frm:2});
Replay.hist.push({time:82.70120000000281, x:178.5049420397853, y:1029.1100520098798, frm:2});
Replay.hist.push({time:82.71780000000281, x:178.56686420871546, y:1029.8959453668701, frm:2});
Replay.hist.push({time:82.73450000000281, x:178.6359852287679, y:1030.6869516786464, frm:2});
Replay.hist.push({time:82.75120000000283, x:178.71196794980412, y:1031.4782852841881, frm:2});
Replay.hist.push({time:82.76780000000282, x:178.79431108861155, y:1032.265152846889, frm:2});
Replay.hist.push({time:82.78450000000282, x:178.8840216111148, y:1033.0569792850029, frm:2});
Replay.hist.push({time:82.80090000000283, x:178.97884307350708, y:1033.83474775808, frm:2});
Replay.hist.push({time:82.81790000000282, x:179.08417924563372, y:1034.641080856761, frm:2});
Replay.hist.push({time:82.83450000000282, x:179.19397436847882, y:1035.4285075099187, frm:2});
Replay.hist.push({time:82.85020000000283, x:179.30413849653888, y:1036.173260652031, frm:2});
Replay.hist.push({time:82.86780000000282, x:179.43495122114956, y:1037.0080755521756, frm:2});
Replay.hist.push({time:82.88450000000282, x:179.56624569031547, y:1037.800113350204, frm:2});
Replay.hist.push({time:82.90120000000283, x:179.7045365206143, y:1038.5920025309842, frm:2});
Replay.hist.push({time:82.91690000000283, x:179.84094204638117, y:1039.3363015832476, frm:2});
Replay.hist.push({time:82.93380000000282, x:179.9947099259114, y:1040.1372280239211, frm:2});
Replay.hist.push({time:82.95120000000283, x:180.1605582097573, y:1040.9615205010132, frm:2});
Replay.hist.push({time:82.96780000000282, x:180.32529253837157, y:1041.7446381315842, frm:2});
Replay.hist.push({time:82.98410000000283, x:180.48910408753494, y:1042.4922276039167, frm:2});
Replay.hist.push({time:83.00120000000283, x:180.6677611791423, y:1043.2760485612162, frm:2});
Replay.hist.push({time:83.01790000000283, x:180.84898106973662, y:1044.0410458226404, frm:2});
Replay.hist.push({time:83.03450000000282, x:181.03573012325575, y:1044.8009340128444, frm:2});
Replay.hist.push({time:83.05110000000283, x:181.22908574444514, y:1045.560248727039, frm:2});
Replay.hist.push({time:83.06780000000283, x:181.43028467492655, y:1046.3235118546168, frm:2});
Replay.hist.push({time:83.08450000000283, x:181.63819487913852, y:1047.0861023202349, frm:2});
Replay.hist.push({time:83.10110000000284, x:181.85152379353974, y:1047.8434152659684, frm:2});
Replay.hist.push({time:83.11790000000285, x:182.07419861884918, y:1048.6090811183853, frm:2});
Replay.hist.push({time:83.13450000000284, x:182.30093101334614, y:1049.3648286420937, frm:2});
Replay.hist.push({time:83.15110000000286, x:182.53434312692764, y:1050.1197305262228, frm:2});
Replay.hist.push({time:83.16780000000286, x:182.77591325848474, y:1050.8782804086998, frm:2});
Replay.hist.push({time:83.18440000000287, x:183.02276098585918, y:1051.6313512084755, frm:2});
Replay.hist.push({time:83.20120000000287, x:183.27941937576296, y:1052.3924953184226, frm:2});
Replay.hist.push({time:83.21780000000287, x:183.53979085984957, y:1053.143549804923, frm:2});
Replay.hist.push({time:83.23450000000287, x:183.8085305613036, y:1053.898049107327, frm:2});
Replay.hist.push({time:83.25120000000288, x:184.084102612268, y:1054.6514218639954, frm:2});
Replay.hist.push({time:83.26780000000288, x:184.36244689358486, y:1055.3929145470124, frm:2});
Replay.hist.push({time:83.28450000000288, x:184.64275547354603, y:1056.1211730420273, frm:2});
Replay.hist.push({time:83.30100000000287, x:184.92700550282126, y:1056.8419456685194, frm:2});
Replay.hist.push({time:83.31740000000288, x:185.21679637023755, y:1057.559521403758, frm:2});
Replay.hist.push({time:83.33450000000286, x:185.52676770998693, y:1058.3089643744436, frm:2});
Replay.hist.push({time:83.35120000000288, x:185.83723440227612, y:1059.0419712475634, frm:2});
Replay.hist.push({time:83.36790000000288, x:186.15544334931695, y:1059.7760278808016, frm:2});
Replay.hist.push({time:83.38450000000287, x:186.47949344465067, y:1060.5066665882673, frm:2});
Replay.hist.push({time:83.40120000000289, x:186.81337174321118, y:1061.242645064977, frm:2});
Replay.hist.push({time:83.41780000000288, x:187.15315116223582, y:1061.9750789890547, frm:2});
Replay.hist.push({time:83.43360000000288, x:187.4839228890919, y:1062.6729194325073, frm:2});
Replay.hist.push({time:83.45110000000288, x:187.85883033510632, y:1063.4467457122114, frm:2});
Replay.hist.push({time:83.46790000000289, x:188.2272017426983, y:1064.1902963717018, frm:2});
Replay.hist.push({time:83.48450000000288, x:188.5994219397975, y:1064.9256142972986, frm:2});
Replay.hist.push({time:83.5012000000029, x:188.98223209295685, y:1065.6659388118546, frm:2});
Replay.hist.push({time:83.5178000000029, x:189.37112056012705, y:1066.402329418913, frm:2});
Replay.hist.push({time:83.5345000000029, x:189.7708592253149, y:1067.14360678845, frm:2});
Replay.hist.push({time:83.55030000000289, x:190.15695254195748, y:1067.8452365360972, frm:2});
Replay.hist.push({time:83.56780000000289, x:190.58816085455203, y:1068.6129935448223, frm:2});
Replay.hist.push({time:83.58400000000289, x:190.9819307574874, y:1069.3001526424318, frm:2});
Replay.hist.push({time:83.6012000000029, x:191.40839536775047, y:1070.030051138325, frm:2});
Replay.hist.push({time:83.6178000000029, x:191.82819736101254, y:1070.7346524662025, frm:2});
Replay.hist.push({time:83.6345000000029, x:192.258766769906, y:1071.4436394568306, frm:2});
Replay.hist.push({time:83.65120000000292, x:192.69767040582423, y:1072.1527013462069, frm:2});
Replay.hist.push({time:83.66790000000292, x:193.14498304077415, y:1072.86177636383, frm:2});
Replay.hist.push({time:83.68350000000291, x:193.57045817252356, y:1073.5240461630574, frm:2});
Replay.hist.push({time:83.7005000000029, x:194.0426688833852, y:1074.2457193837774, frm:2});
Replay.hist.push({time:83.7168000000029, x:194.5038094946436, y:1074.9374717886408, frm:2});
Replay.hist.push({time:83.7338000000029, x:194.99361627698363, y:1075.6587295643424, frm:2});
Replay.hist.push({time:83.75120000000291, x:195.5043900879313, y:1076.3966553952039, frm:2});
Replay.hist.push({time:83.7675000000029, x:195.991560420313, y:1077.0875092282376, frm:2});
Replay.hist.push({time:83.7845000000029, x:196.50875186825888, y:1077.807626485408, frm:2});
Replay.hist.push({time:83.8011000000029, x:197.02277482359654, y:1078.5102750522474, frm:2});
Replay.hist.push({time:83.81790000000291, x:197.5521473642271, y:1079.2208245887605, frm:2});
Replay.hist.push({time:83.8345000000029, x:198.0843270019193, y:1079.9222683062562, frm:2});
Replay.hist.push({time:83.8503000000029, x:198.59931692935868, y:1080.5892181589843, frm:2});
Replay.hist.push({time:83.8678000000029, x:199.17041049768244, y:1081.3157118897097, frm:2});
Replay.hist.push({time:83.8840000000029, x:199.68546009421726, y:1081.9595016375079, frm:2});
Replay.hist.push({time:83.9011000000029, x:200.23785062265642, y:1082.6383631667582, frm:2});
Replay.hist.push({time:83.9178000000029, x:200.7859893624199, y:1083.3005306105895, frm:2});
Replay.hist.push({time:83.9345000000029, x:201.34278066988614, y:1083.961849649188, frm:2});
Replay.hist.push({time:83.95110000000291, x:201.9048778936124, y:1084.6183009089873, frm:2});
Replay.hist.push({time:83.96780000000291, x:202.47913113699838, y:1085.277739692844, frm:2});
Replay.hist.push({time:83.98450000000291, x:203.06224675181724, y:1085.9361397964713, frm:2});
Replay.hist.push({time:84.00120000000292, x:203.6542953254694, y:1086.593436497567, frm:2});
Replay.hist.push({time:84.01780000000292, x:204.2517174110955, y:1087.245634595829, frm:2});
Replay.hist.push({time:84.03450000000292, x:204.86179008466888, y:1087.9005355824331, frm:2});
Replay.hist.push({time:84.05120000000294, x:205.48100859061847, y:1088.5541358688668, frm:2});
Replay.hist.push({time:84.06790000000294, x:206.1094445409205, y:1089.2063683790084, frm:2});
Replay.hist.push({time:84.08380000000292, x:206.71637337402728, y:1089.8259890446266, frm:2});
Replay.hist.push({time:84.10110000000293, x:207.38644493075037, y:1090.4986856709372, frm:2});
Replay.hist.push({time:84.11680000000293, x:208.00323676694484, y:1091.107642737274, frm:2});
Replay.hist.push({time:84.13350000000293, x:208.66857761777817, y:1091.7538420805968, frm:2});
Replay.hist.push({time:84.15010000000294, x:209.33941793326483, y:1092.3944760676168, frm:2});
Replay.hist.push({time:84.16680000000294, x:210.02392206592165, y:1093.0372016979504, frm:2});
Replay.hist.push({time:84.18450000000293, x:210.7105324404003, y:1093.67110891331, frm:2});
Replay.hist.push({time:84.20120000000294, x:211.36618360424848, y:1094.266625113934, frm:2});
Replay.hist.push({time:84.21780000000294, x:212.0264913097627, y:1094.856950180801, frm:2});
Replay.hist.push({time:84.23450000000294, x:212.6994824296926, y:1095.4491439140334, frm:2});
Replay.hist.push({time:84.25120000000295, x:213.3812638107578, y:1096.0395799452435, frm:2});
Replay.hist.push({time:84.26790000000295, x:214.07189866535137, y:1096.6281965275682, frm:2});
Replay.hist.push({time:84.28410000000295, x:214.75035330105436, y:1097.1973738338454, frm:2});
Replay.hist.push({time:84.30120000000295, x:215.4757095424937, y:1097.7962231893625, frm:2});
Replay.hist.push({time:84.31790000000295, x:216.19323174099003, y:1098.3790183157366, frm:2});
Replay.hist.push({time:84.33450000000295, x:216.91547890548281, y:1098.9562677081478, frm:2});
Replay.hist.push({time:84.35120000000296, x:217.6512261121071, y:1099.5348698347814, frm:2});
Replay.hist.push({time:84.36790000000296, x:218.39620956111452, y:1100.1112725994049, frm:2});
Replay.hist.push({time:84.38460000000296, x:219.1504938832352, y:1100.685410852618, frm:2});
Replay.hist.push({time:84.40120000000297, x:219.9095383091069, y:1101.2537984214712, frm:2});
Replay.hist.push({time:84.41790000000297, x:220.68256236330717, y:1101.823224816732, frm:2});
Replay.hist.push({time:84.43440000000297, x:221.45564429225732, y:1102.3834065304304, frm:2});
Replay.hist.push({time:84.45110000000297, x:222.2476099655978, y:1102.9478715704408, frm:2});
Replay.hist.push({time:84.46780000000297, x:223.04920151228782, y:1103.509740226935, frm:2});
Replay.hist.push({time:84.48350000000296, x:223.799359821056, y:1104.027138392135, frm:2});
Replay.hist.push({time:84.50100000000296, x:224.59053683101166, y:1104.5642085245797, frm:2});
Replay.hist.push({time:84.51790000000297, x:225.36335968724205, y:1105.0804642670005, frm:2});
Replay.hist.push({time:84.53460000000297, x:226.13557700705348, y:1105.5882482278776, frm:2});
Replay.hist.push({time:84.55120000000298, x:226.9116424898624, y:1106.0906113580309, frm:2});
Replay.hist.push({time:84.56790000000298, x:227.70097050918415, y:1106.5935556747816, frm:2});
Replay.hist.push({time:84.58360000000297, x:228.45088761476796, y:1107.0640646109953, frm:2});
Replay.hist.push({time:84.60100000000297, x:229.2910926859167, y:1107.5829211208804, frm:1});
Replay.hist.push({time:84.61780000000297, x:230.11133235711537, y:1108.0811617711247, frm:1});
Replay.hist.push({time:84.63460000000298, x:230.94050708899212, y:1108.5766835260806, frm:1});
Replay.hist.push({time:84.65040000000297, x:231.7284829410935, y:1109.0401478775025, frm:1});
Replay.hist.push({time:84.66790000000297, x:232.6106693890738, y:1109.5506008303469, frm:1});
Replay.hist.push({time:84.68460000000297, x:233.4616986320573, y:1110.034763520658, frm:1});
Replay.hist.push({time:84.70120000000298, x:234.31659818198386, y:1110.513126036848, frm:1});
Replay.hist.push({time:84.71780000000298, x:235.18049744384405, y:1110.988539620244, frm:1});
Replay.hist.push({time:84.73450000000298, x:236.05874329602256, y:1111.4637838544013, frm:1});
Replay.hist.push({time:84.751200000003, x:236.94621038510033, y:1111.9359217844635, frm:1});
Replay.hist.push({time:84.76780000000299, x:237.83755283167375, y:1112.4020904795384, frm:1});
Replay.hist.push({time:84.78360000000299, x:238.69446561629616, y:1112.8428100136712, frm:1});
Replay.hist.push({time:84.80120000000299, x:239.65899703490135, y:1113.3303204734316, frm:1});
Replay.hist.push({time:84.817900000003, x:240.53167648231877, y:1113.7637432864485, frm:1});
Replay.hist.push({time:84.83450000000299, x:241.37699259926302, y:1114.1767648075488, frm:1});
Replay.hist.push({time:84.851200000003, x:242.2354571730391, y:1114.5894717599535, frm:1});
Replay.hist.push({time:84.867400000003, x:243.0759555812678, y:1114.9870751385724, frm:1});
Replay.hist.push({time:84.884600000003, x:243.97679889478133, y:1115.4062443373236, frm:1});
Replay.hist.push({time:84.90120000000302, x:244.8544505960644, y:1115.8077953594834, frm:1});
Replay.hist.push({time:84.91790000000302, x:245.74563009862376, y:1116.2087601214052, frm:1});
Replay.hist.push({time:84.93450000000301, x:246.6397007465444, y:1116.6042813210656, frm:1});
Replay.hist.push({time:84.95120000000303, x:247.5474908847444, y:1116.9990770745171, frm:1});
Replay.hist.push({time:84.96780000000302, x:248.45816504021704, y:1117.3883634971676, frm:1});
Replay.hist.push({time:84.98450000000302, x:249.38275191184715, y:1117.7767829090149, frm:1});
Replay.hist.push({time:85.00120000000304, x:250.3158327214727, y:1118.16192578565, frm:1});
Replay.hist.push({time:85.01790000000304, x:251.2574545931028, y:1118.543739118223, frm:1});
Replay.hist.push({time:85.03460000000304, x:252.20766475696092, y:1118.9221696095005, frm:1});
Replay.hist.push({time:85.05120000000305, x:253.16073803007077, y:1119.2949265444286, frm:1});
Replay.hist.push({time:85.06780000000305, x:254.12239035338533, y:1119.6642352223628, frm:1});
Replay.hist.push({time:85.08390000000306, x:255.0632916121109, y:1120.019066426924, frm:1});
Replay.hist.push({time:85.10120000000306, x:256.0834793665696, y:1120.3966419957915, frm:1});
Replay.hist.push({time:85.11790000000306, x:257.07723710049845, y:1120.7573928496427, frm:1});
Replay.hist.push({time:85.13450000000306, x:258.07383261222043, y:1121.1123069964765, frm:1});
Replay.hist.push({time:85.15120000000307, x:259.0853313402014, y:1121.4656092291464, frm:1});
Replay.hist.push({time:85.16790000000307, x:260.01716972363636, y:1121.7850136643058, frm:1});
Replay.hist.push({time:85.18460000000307, x:260.94674369276873, y:1122.0979250552773, frm:1});
Replay.hist.push({time:85.20020000000308, x:261.8218638298498, y:1122.3873615271716, frm:1});
Replay.hist.push({time:85.21780000000307, x:262.8172358206451, y:1122.710590927457, frm:1});
Replay.hist.push({time:85.23450000000307, x:263.7695014261706, y:1123.0139503827447, frm:1});
Replay.hist.push({time:85.25120000000308, x:264.7294371003163, y:1123.3140253098213, frm:1});
Replay.hist.push({time:85.26760000000307, x:265.6796154353743, y:1123.6054657777502, frm:1});
Replay.hist.push({time:85.28460000000307, x:266.6724718895984, y:1123.9041440366702, frm:1});
Replay.hist.push({time:85.30120000000308, x:267.6497315958716, y:1124.1923708848547, frm:1});
Replay.hist.push({time:85.31790000000308, x:268.64068293765155, y:1124.4788827977832, frm:1});
Replay.hist.push({time:85.33460000000308, x:269.6394956647192, y:1124.7618865158297, frm:1});
Replay.hist.push({time:85.35120000000309, x:270.640151495463, y:1125.0396725665837, frm:1});
Replay.hist.push({time:85.36790000000309, x:271.65475494874147, y:1125.315545161548, frm:1});
Replay.hist.push({time:85.38410000000309, x:272.6465783822897, y:1125.5796693391815, frm:1});
Replay.hist.push({time:85.40120000000309, x:273.7017323208066, y:1125.854712050593, frm:1});
Replay.hist.push({time:85.41780000000308, x:274.7340861752475, y:1126.1179587068052, frm:1});
Replay.hist.push({time:85.43350000000308, x:275.71778274074615, y:1126.3634837661382, frm:1});
Replay.hist.push({time:85.45120000000308, x:276.8355173121907, y:1126.6362547208419, frm:1});
Replay.hist.push({time:85.46790000000308, x:277.89846663266025, y:1126.8896107224164, frm:1});
Replay.hist.push({time:85.48450000000308, x:278.96318207313936, y:1127.1375601793286, frm:1});
Replay.hist.push({time:85.50080000000308, x:280.0165708974064, y:1127.3772081232808, frm:1});
Replay.hist.push({time:85.51760000000309, x:281.1105605064922, y:1127.6202080821176, frm:1});
Replay.hist.push({time:85.53350000000307, x:282.0719986995198, y:1127.8288776051272, frm:1});
Replay.hist.push({time:85.55040000000308, x:283.0756902094943, y:1128.0418993379558, frm:1});
Replay.hist.push({time:85.56780000000307, x:284.1165383041528, y:1128.25767058137, frm:1});
Replay.hist.push({time:85.58450000000308, x:285.1226080429546, y:1128.4613211808278, frm:1});
Replay.hist.push({time:85.60120000000309, x:286.1356902111004, y:1128.6615713053968, frm:1});
Replay.hist.push({time:85.61690000000308, x:287.09448644215877, y:1128.846687377143, frm:1});
Replay.hist.push({time:85.63460000000308, x:288.183019913226, y:1129.0517218440361, frm:1});
Replay.hist.push({time:85.65050000000306, x:289.16757674825703, y:1129.232526825469, frm:1});
Replay.hist.push({time:85.66710000000307, x:290.20241228531484, y:1129.417865053025, frm:1});
Replay.hist.push({time:85.68390000000308, x:291.2569326487695, y:1129.6018299882958, frm:1});
Replay.hist.push({time:85.70120000000308, x:292.3504729464313, y:1129.7874402945042, frm:1});
Replay.hist.push({time:85.71790000000308, x:293.41341481289436, y:1129.9628787823988, frm:1});
Replay.hist.push({time:85.73450000000308, x:294.47717994454916, y:1130.1335967889997, frm:1});
Replay.hist.push({time:85.75070000000309, x:295.52223665558654, y:1130.296635034795, frm:1});
Replay.hist.push({time:85.7679000000031, x:296.63939196406807, y:1130.465853051071, frm:1});
Replay.hist.push({time:85.78450000000309, x:297.72494177055273, y:1130.6253235532902, frm:1});
Replay.hist.push({time:85.8012000000031, x:298.8243957347988, y:1130.7819102500493, frm:1});
Replay.hist.push({time:85.8178000000031, x:299.9246077221337, y:1130.9336979389823, frm:1});
Replay.hist.push({time:85.8345000000031, x:301.0388741483264, y:1131.0824777678972, frm:1});
Replay.hist.push({time:85.85020000000311, x:302.09318805252036, y:1131.2187192890347, frm:1});
Replay.hist.push({time:85.86690000000311, x:303.22198550513474, y:1131.359753377501, frm:1});
Replay.hist.push({time:85.8846000000031, x:304.42665536095495, y:1131.5048162728308, frm:1});
Replay.hist.push({time:85.9011000000031, x:305.55725065831325, y:1131.6358976392048, frm:1});
Replay.hist.push({time:85.9179000000031, x:306.70386660238165, y:1131.7638870299136, frm:1});
Replay.hist.push({time:85.9346000000031, x:307.7434133827907, y:1131.8756629430657, frm:1});
Replay.hist.push({time:85.9513000000031, x:308.7892764453629, y:1131.984069695891, frm:1});
Replay.hist.push({time:85.9671000000031, x:309.7845738142148, y:1132.0835009303878, frm:1});
Replay.hist.push({time:85.9846000000031, x:310.893689462373, y:1132.1900547184844, frm:1});
Replay.hist.push({time:86.00120000000311, x:311.95220747239887, y:1132.287612434585, frm:1});
Replay.hist.push({time:86.0178000000031, x:313.0170614696857, y:1132.3817196322927, frm:1});
Replay.hist.push({time:86.03420000000311, x:314.0753221543152, y:1132.4712739749343, frm:1});
Replay.hist.push({time:86.0512000000031, x:315.17890428212235, y:1132.5604889551626, frm:1});
Replay.hist.push({time:86.0677000000031, x:316.25643204004837, y:1132.6435251338594, frm:1});
Replay.hist.push({time:86.0843000000031, x:317.34690686804146, y:1132.7235021285, frm:1});
Replay.hist.push({time:86.1012000000031, x:318.4637344800295, y:1132.8012225150042, frm:1});
Replay.hist.push({time:86.11700000000309, x:319.51389535498475, y:1132.8704729900167, frm:1});
Replay.hist.push({time:86.13350000000308, x:320.6169023168572, y:1132.9392484843552, frm:1});
Replay.hist.push({time:86.15030000000309, x:321.74660271195614, y:1133.0055225944968, frm:1});
Replay.hist.push({time:86.16790000000309, x:322.9373339385258, y:1133.0708582147258, frm:1});
Replay.hist.push({time:86.18460000000309, x:324.07396833664404, y:1133.1289413029024, frm:1});
Replay.hist.push({time:86.2012000000031, x:325.21041184583106, y:1133.1828716232947, frm:1});
Replay.hist.push({time:86.2179000000031, x:326.3603901829412, y:1133.233267364458, frm:1});
Replay.hist.push({time:86.2345000000031, x:327.51014611304714, y:1133.27949272716, frm:0});
Replay.hist.push({time:86.25120000000311, x:328.6735658249808, y:1133.3220728135263, frm:0});
Replay.hist.push({time:86.2673000000031, x:329.80157542562057, y:1133.3593657704434, frm:0});
Replay.hist.push({time:86.2838000000031, x:330.96418755656333, y:1133.393728760324, frm:0});
Replay.hist.push({time:86.3012000000031, x:332.197477515151, y:1133.4257029657938, frm:0});
Replay.hist.push({time:86.3179000000031, x:333.3881141516485, y:1133.4522393267719, frm:0});
Replay.hist.push({time:86.3346000000031, x:334.5856267010432, y:1133.4746769774233, frm:0});
Replay.hist.push({time:86.35020000000311, x:335.64440220509215, y:1133.4914073616962, frm:0});
Replay.hist.push({time:86.36690000000311, x:336.73402186615886, y:1133.5078195823012, frm:0});
Replay.hist.push({time:86.38370000000312, x:337.84105171568467, y:1133.523986496125, frm:0});
Replay.hist.push({time:86.40120000000312, x:339.00580298962035, y:1133.540461888827, frm:0});
Replay.hist.push({time:86.41740000000311, x:340.09445458818476, y:1133.5553809925425, frm:0});
Replay.hist.push({time:86.4345000000031, x:341.254515748404, y:1133.5707844900783, frm:0});
Replay.hist.push({time:86.45120000000311, x:342.39817719309764, y:1133.5854863886982, frm:0});
Replay.hist.push({time:86.46790000000311, x:343.550567569311, y:1133.5998294868737, frm:0});
Replay.hist.push({time:86.48450000000311, x:344.70462940147104, y:1133.6137337915038, frm:0});
Replay.hist.push({time:86.50120000000312, x:345.8741845794519, y:1133.6273694717522, frm:0});
Replay.hist.push({time:86.51780000000312, x:347.0451493680193, y:1133.640575902002, frm:0});
Replay.hist.push({time:86.53460000000312, x:348.23867772787116, y:1133.6535912581137, frm:0});
Replay.hist.push({time:86.55090000000313, x:349.4047457814188, y:1133.6658851907794, frm:0});
Replay.hist.push({time:86.56790000000312, x:350.6084236609323, y:1133.6781506067393, frm:0});
Replay.hist.push({time:86.58450000000312, x:351.7022348528318, y:1133.6889321853816, frm:0});
Replay.hist.push({time:86.60050000000312, x:352.76274248628124, y:1133.6990628802691, frm:0});
Replay.hist.push({time:86.61790000000313, x:353.9229019679748, y:1133.7097908816388, frm:0});
Replay.hist.push({time:86.63450000000313, x:355.03634395531645, y:1133.7197471146244, frm:0});
Replay.hist.push({time:86.65120000000314, x:356.1629488419534, y:1133.7294908286558, frm:0});
Replay.hist.push({time:86.66780000000314, x:357.2891672838579, y:1133.7389073346033, frm:0});
Replay.hist.push({time:86.68360000000314, x:358.3669533699446, y:1133.7476229071235, frm:0});
Replay.hist.push({time:86.70020000000315, x:359.50537553788087, y:1133.7565218780865, frm:0});
Replay.hist.push({time:86.71690000000315, x:360.65687606703676, y:1133.7652098207461, frm:0});
Replay.hist.push({time:86.73360000000315, x:361.8145572853202, y:1133.7736343320169, frm:0});
Replay.hist.push({time:86.75020000000316, x:362.9713738340966, y:1133.7817493045811, frm:0});
Replay.hist.push({time:86.76790000000315, x:364.2114300856573, y:1133.79011956181, frm:0});
Replay.hist.push({time:86.78460000000315, x:365.3876183191805, y:1133.7977518955295, frm:0});
Replay.hist.push({time:86.80120000000316, x:366.56265155288617, y:1133.8050854382607, frm:0});
Replay.hist.push({time:86.81790000000316, x:367.7144200703387, y:1133.811997582439, frm:0});
Replay.hist.push({time:86.83370000000316, x:368.75884031642534, y:1133.8180341594302, frm:0});
Replay.hist.push({time:86.85120000000316, x:369.92074611443707, y:1133.824496688112, frm:0});
Replay.hist.push({time:86.86690000000316, x:370.9677067567277, y:1133.8300961819905, frm:0});
Replay.hist.push({time:86.88450000000314, x:372.14640937169264, y:1133.836151775521, frm:0});
Replay.hist.push({time:86.90020000000315, x:373.20235833859823, y:1133.841357652815, frm:0});
Replay.hist.push({time:86.91790000000314, x:374.3978064864686, y:1133.847006541448, frm:0});
Replay.hist.push({time:86.93430000000313, x:375.51015797013605, y:1133.8520340101481, frm:0});
Replay.hist.push({time:86.95120000000314, x:376.6610889422123, y:1133.8570084291298, frm:0});
Replay.hist.push({time:86.96790000000314, x:377.80301344688695, y:1133.8617198559893, frm:0});
Replay.hist.push({time:86.98460000000314, x:378.9494757169714, y:1133.8662299243783, frm:0});
Replay.hist.push({time:87.00120000000315, x:380.0935254209365, y:1133.870514970873, frm:0});
Replay.hist.push({time:87.01790000000315, x:381.24889803854796, y:1133.8746281541883, frm:0});
Replay.hist.push({time:87.03360000000315, x:382.33911066288897, y:1133.8783156403872, frm:0});
Replay.hist.push({time:87.05120000000315, x:383.56581199741646, y:1133.8822441863624, frm:0});
Replay.hist.push({time:87.06720000000315, x:384.6851663165708, y:1133.8856289558871, frm:0});
Replay.hist.push({time:87.08390000000315, x:385.83563785259486, y:1133.888912707563, frm:0});
Replay.hist.push({time:87.10070000000316, x:386.94640348473047, y:1133.891898864769, frm:0});
Replay.hist.push({time:87.11790000000316, x:388.08744322800237, y:1133.8947813220605, frm:0});
Replay.hist.push({time:87.13450000000316, x:389.1923238107504, y:1133.8973968413106, frm:0});
Replay.hist.push({time:87.15030000000316, x:390.2472504010956, y:1133.899735660561, frm:0});
Replay.hist.push({time:87.16690000000317, x:391.35899756760364, y:1133.9020357800944, frm:0});
Replay.hist.push({time:87.18450000000315, x:392.54148730404785, y:1133.9042999859284, frm:0});
Replay.hist.push({time:87.20120000000317, x:393.6670784778854, y:1133.9062837324225, frm:0});
Replay.hist.push({time:87.21790000000317, x:394.7960975269367, y:1133.908108389489, frm:0});
Replay.hist.push({time:87.23390000000317, x:395.88098072189126, y:1133.909708513856, frm:0});
Replay.hist.push({time:87.25130000000317, x:397.0642685517811, y:1133.911285473714, frm:0});
Replay.hist.push({time:87.26790000000318, x:398.19651687701815, y:1133.9126327633498, frm:0});
Replay.hist.push({time:87.28460000000318, x:399.33885335787136, y:1133.9138345624785, frm:0});
Replay.hist.push({time:87.30130000000318, x:400.4844310061163, y:1133.9148835438777, frm:0});
Replay.hist.push({time:87.31790000000319, x:401.6263253993809, y:1133.9157760323124, frm:0});
Replay.hist.push({time:87.33430000000318, x:402.7575374176134, y:1133.9165119032982, frm:0});
Replay.hist.push({time:87.35120000000319, x:403.92639008963494, y:1133.9171197772516, frm:0});
Replay.hist.push({time:87.36690000000318, x:405.0106700628068, y:1133.9175473185055, frm:0});
Replay.hist.push({time:87.38460000000318, x:406.1795620634618, y:1133.9178635888056, frm:0});
Replay.hist.push({time:87.40120000000319, x:407.2785881313947, y:1133.9180262132327, frm:0});
Replay.hist.push({time:87.41790000000319, x:408.38690206818154, y:1133.9180600272434, frm:0});
Replay.hist.push({time:87.43460000000319, x:409.49786080808775, y:1133.9179647233768, frm:0});
Replay.hist.push({time:87.4507000000032, x:410.5713858276642, y:1133.91775159255, frm:0});
Replay.hist.push({time:87.4679000000032, x:411.72089606555954, y:1133.9173934813375, frm:0});
Replay.hist.push({time:87.4845000000032, x:412.8328797888596, y:1133.9169211583612, frm:0});
Replay.hist.push({time:87.5013000000032, x:413.9607928904674, y:1133.9163175078595, frm:0});
Replay.hist.push({time:87.51780000000319, x:415.0710188717614, y:1133.9156026440896, frm:0});
Replay.hist.push({time:87.5346000000032, x:416.2038913738519, y:1133.9147516318826, frm:0});
Replay.hist.push({time:87.5502000000032, x:417.258055793955, y:1133.9138510581797, frm:0});
Replay.hist.push({time:87.5669000000032, x:418.3888566024942, y:1133.9127702340445, frm:0});
Replay.hist.push({time:87.58460000000319, x:419.58993919260814, y:1133.911494048017, frm:0});
Replay.hist.push({time:87.6006000000032, x:420.67793800121774, y:1133.9102257257744, frm:0});
Replay.hist.push({time:87.6179000000032, x:421.85669871951, y:1133.9087329337156, frm:0});
Replay.hist.push({time:87.6346000000032, x:422.9968963504764, y:1133.907173273974, frm:0});
Replay.hist.push({time:87.65020000000321, x:424.0640270738423, y:1133.905611980176, frm:0});
Replay.hist.push({time:87.6679000000032, x:425.27711932027455, y:1133.903719584347, frm:0});
Replay.hist.push({time:87.6846000000032, x:426.3819746587381, y:1133.901888767343, frm:0});
Replay.hist.push({time:87.7003000000032, x:427.4186922476318, y:1133.9000791840044, frm:0});
Replay.hist.push({time:87.7179000000032, x:428.582887031564, y:1133.89794276245, frm:0});
Replay.hist.push({time:87.7345000000032, x:429.6828911738422, y:1133.8958241313962, frm:0});
Replay.hist.push({time:87.75120000000321, x:430.7913991544105, y:1133.8935922058618, frm:0});
Replay.hist.push({time:87.7669000000032, x:431.83523413020464, y:1133.8914027596022, frm:0});
Replay.hist.push({time:87.7836000000032, x:432.94732049956053, y:1133.8889777946365, frm:0});
Replay.hist.push({time:87.8012000000032, x:434.1212816961451, y:1133.8863159453915, frm:0});
Replay.hist.push({time:87.8169000000032, x:435.17019472343713, y:1133.883850263475, frm:0});
Replay.hist.push({time:87.8343000000032, x:436.3344715188155, y:1133.8810181831989, frm:0});
Replay.hist.push({time:87.8512000000032, x:437.4670890541188, y:1133.878168302313, frm:0});
Replay.hist.push({time:87.8678000000032, x:438.5812956745176, y:1133.8752747790193, frm:0});
Replay.hist.push({time:87.8846000000032, x:439.7106038210943, y:1133.8722522349144, frm:0});
Replay.hist.push({time:87.9013000000032, x:440.83483897870104, y:1133.8691546951022, frm:0});
Replay.hist.push({time:87.91790000000321, x:441.95394528587826, y:1133.865984713187, frm:0});
Replay.hist.push({time:87.93360000000321, x:443.01383290441925, y:1133.86290389493, frm:0});
Replay.hist.push({time:87.95120000000321, x:444.20360764043033, y:1133.8593556928365, frm:0});
Replay.hist.push({time:87.96790000000321, x:445.3341311663941, y:1133.8558973446984, frm:0});
Replay.hist.push({time:87.98460000000321, x:446.4661656007755, y:1133.8523507470713, frm:0});
Replay.hist.push({time:88.00030000000321, x:447.50536821298886, y:1133.8490222872983, frm:0});
Replay.hist.push({time:88.01790000000321, x:448.66423294178344, y:1133.8452295804716, frm:0});
Replay.hist.push({time:88.03460000000321, x:449.76521836383034, y:1133.8415482394294, frm:0});
Replay.hist.push({time:88.05120000000322, x:450.86091094436824, y:1133.8378100898576, frm:0});
Replay.hist.push({time:88.06790000000322, x:451.9644858459745, y:1133.8339709020895, frm:0});
Replay.hist.push({time:88.08450000000322, x:453.0627053783659, y:1133.8300774393497, frm:0});
Replay.hist.push({time:88.10120000000323, x:454.16877523346915, y:1133.826083598065, frm:0});
Replay.hist.push({time:88.11790000000323, x:455.27606083530964, y:1133.8220133963566, frm:0});
Replay.hist.push({time:88.13460000000323, x:456.3845385472204, y:1133.817867632826, frm:0});
Replay.hist.push({time:88.15120000000324, x:457.4875378889015, y:1133.8136725937957, frm:0});
Replay.hist.push({time:88.16790000000324, x:458.5983223089751, y:1133.8093785369083, frm:0});
Replay.hist.push({time:88.18360000000324, x:459.6436267152718, y:1133.8052748144316, frm:0});
Replay.hist.push({time:88.20130000000323, x:460.82323117121723, y:1133.8005716819298, frm:0});
Replay.hist.push({time:88.21790000000324, x:461.93063696718707, y:1133.7960876823374, frm:0});
Replay.hist.push({time:88.23360000000324, x:462.97896875843094, y:1133.7917823596217, frm:0});
Replay.hist.push({time:88.25080000000324, x:464.12848581609694, y:1133.7869947093236, frm:0});
Replay.hist.push({time:88.26790000000324, x:465.2723783095509, y:1133.7821620585225, frm:0});
Replay.hist.push({time:88.28460000000324, x:466.3905114599256, y:1133.7773731508887, frm:0});
Replay.hist.push({time:88.30120000000325, x:467.50290046214195, y:1133.7725458472023, frm:0});
Replay.hist.push({time:88.31790000000325, x:468.62292227294705, y:1133.7676227754437, frm:0});
Replay.hist.push({time:88.33450000000325, x:469.712810679056, y:1133.762772601949, frm:0});
Replay.hist.push({time:88.35120000000326, x:470.80905227963177, y:1133.7578356737795, frm:0});
Replay.hist.push({time:88.36690000000326, x:471.8404095096061, y:1133.7531380868465, frm:0});
Replay.hist.push({time:88.38460000000325, x:473.0039727416839, y:1133.7477776052915, frm:0});
Replay.hist.push({time:88.40020000000325, x:474.03023289157034, y:1133.7429969180057, frm:0});
Replay.hist.push({time:88.41800000000325, x:475.2020015844748, y:1133.7374787467302, frm:0});
Replay.hist.push({time:88.43460000000326, x:476.2955405710837, y:1133.7322723567283, frm:0});
Replay.hist.push({time:88.45090000000326, x:477.37000604564935, y:1133.72710423944, frm:0});
Replay.hist.push({time:88.46790000000325, x:478.4913087225761, y:1133.7216560378652, frm:0});
Replay.hist.push({time:88.48460000000325, x:479.5935042462829, y:1133.7162468478266, frm:0});
Replay.hist.push({time:88.50120000000327, x:480.6897449669992, y:1133.7108146544097, frm:0});
Replay.hist.push({time:88.51790000000327, x:481.7932153166136, y:1133.7052947321536, frm:0});
Replay.hist.push({time:88.53460000000327, x:482.8972934197869, y:1133.6997203464623, frm:0});
Replay.hist.push({time:88.55120000000328, x:483.99534291123143, y:1133.694126063715, frm:0});
Replay.hist.push({time:88.56790000000328, x:485.1005701335903, y:1133.6884452018232, frm:0});
Replay.hist.push({time:88.58460000000328, x:486.2063422545441, y:1133.682712004742, frm:0});
Replay.hist.push({time:88.60080000000328, x:487.2795138618829, y:1133.6771010927584, frm:0});
Replay.hist.push({time:88.61800000000328, x:488.4194372500265, y:1133.6710914378414, frm:0});
Replay.hist.push({time:88.63460000000329, x:489.52008808714646, y:1133.6652408655539, frm:0});
Replay.hist.push({time:88.6512000000033, x:490.62119504533746, y:1133.6593413691614, frm:0});
Replay.hist.push({time:88.6677000000033, x:491.7079024237372, y:1133.6534740436339, frm:0});
Replay.hist.push({time:88.68410000000328, x:492.7802929329082, y:1133.647640808381, frm:0});
Replay.hist.push({time:88.70130000000329, x:493.9053825352537, y:1133.6414754136476, frm:0});
Replay.hist.push({time:88.7179000000033, x:494.9915968122622, y:1133.635479454116, frm:0});
Replay.hist.push({time:88.7346000000033, x:496.08469564397194, y:1133.6294028551686, frm:0});
Replay.hist.push({time:88.75050000000329, x:497.12574015129843, y:1133.623576408656, frm:0});
Replay.hist.push({time:88.7679000000033, x:498.26529489803045, y:1133.6171553733616, frm:0});
Replay.hist.push({time:88.7846000000033, x:499.35930426891343, y:1133.6109490365343, frm:0});
Replay.hist.push({time:88.8012000000033, x:500.44702655429313, y:1133.6047382234335, frm:0});
Replay.hist.push({time:88.8179000000033, x:501.54154518339243, y:1133.5984487753371, frm:0});
Replay.hist.push({time:88.8345000000033, x:502.62973521245254, y:1133.5921566528737, frm:0});
Replay.hist.push({time:88.85110000000331, x:503.7181297444776, y:1133.5858249699015, frm:0});
Replay.hist.push({time:88.8680000000033, x:504.8263810241461, y:1133.5793389064834, frm:0});
Replay.hist.push({time:88.88460000000332, x:505.91513036812916, y:1133.5729293508914, frm:0});
Replay.hist.push({time:88.90130000000332, x:507.01058580920113, y:1133.5664432335652, frm:0});
Replay.hist.push({time:88.91800000000332, x:508.1061709891559, y:1133.5599196972587, frm:0});
Replay.hist.push({time:88.93460000000333, x:509.19530648763055, y:1133.5533987782146, frm:0});
Replay.hist.push({time:88.95120000000334, x:510.2845323663244, y:1133.5468421695377, frm:0});
Replay.hist.push({time:88.96790000000334, x:511.38039090478736, y:1133.5402107019333, frm:0});
Replay.hist.push({time:88.98450000000334, x:512.4697414696724, y:1133.533584419897, frm:0});
Replay.hist.push({time:89.00130000000334, x:513.5722489916143, y:1133.5268439298811, frm:0});
Replay.hist.push({time:89.01690000000335, x:514.5916687347727, y:1133.520581294548, frm:0});
Replay.hist.push({time:89.03460000000334, x:515.7446149255428, y:1133.5134640283368, frm:0});
Replay.hist.push({time:89.05110000000333, x:516.8193841030256, y:1133.5067970532396, frm:0});
Replay.hist.push({time:89.06800000000332, x:517.920159995693, y:1133.499936969185, frm:0});
Replay.hist.push({time:89.08460000000333, x:519.0013374628519, y:1133.4931682166612, frm:0});
Replay.hist.push({time:89.10100000000332, x:520.0694133721379, y:1133.4864519964494, frm:0});
Replay.hist.push({time:89.11800000000332, x:521.1764582633058, y:1133.4794603079033, frm:0});
Replay.hist.push({time:89.13430000000332, x:522.2378133921497, y:1133.4727285800266, frm:0});
Replay.hist.push({time:89.15130000000332, x:523.3446032620344, y:1133.4656793633708, frm:0});
Replay.hist.push({time:89.16790000000333, x:524.4252025357677, y:1133.458768570919, frm:0});
Replay.hist.push({time:89.18410000000333, x:525.4796046734034, y:1133.4519987476078, frm:0});
Replay.hist.push({time:89.20120000000333, x:526.5923810526616, y:1133.4448261618345, frm:0});
Replay.hist.push({time:89.21800000000331, x:527.6854267343264, y:1133.4377532854364, frm:0});
Replay.hist.push({time:89.23460000000333, x:528.7652379566226, y:1133.4307397993161, frm:0});
Replay.hist.push({time:89.25060000000333, x:529.8057987475254, y:1133.423957008687, frm:0});
Replay.hist.push({time:89.26800000000333, x:530.9371196208306, y:1133.4165561089733, frm:0});
Replay.hist.push({time:89.28460000000334, x:532.0161520765412, y:1133.4094720285357, frm:0});
Replay.hist.push({time:89.30120000000335, x:533.0948905541492, y:1133.4023657145074, frm:0});
Replay.hist.push({time:89.31770000000334, x:534.1668229986516, y:1133.3952807605383, frm:0});
Replay.hist.push({time:89.33460000000333, x:535.26439993493, y:1133.3880025339395, frm:0});
Replay.hist.push({time:89.35120000000335, x:536.342146744589, y:1133.3808328600057, frm:0});
Replay.hist.push({time:89.36790000000335, x:537.4260145214408, y:1133.3736000006443, frm:0});
Replay.hist.push({time:89.38460000000335, x:538.5094913580193, y:1133.3663476937156, frm:0});
Replay.hist.push({time:89.40120000000336, x:539.5860808298851, y:1133.3591201000324, frm:0});
Replay.hist.push({time:89.41800000000335, x:540.675214514375, y:1133.351787059077, frm:0});
Replay.hist.push({time:89.43450000000334, x:541.7444698030713, y:1133.3445675306198, frm:0});
Replay.hist.push({time:89.45120000000335, x:542.8262291217817, y:1133.3372435323558, frm:0});
Replay.hist.push({time:89.46790000000335, x:543.9075138255984, y:1133.3299030515996, frm:0});
Replay.hist.push({time:89.48460000000335, x:544.9883063682183, y:1133.3225466850029, frm:0});
Replay.hist.push({time:89.50120000000337, x:546.0621231626408, y:1133.3152192062594, frm:0});
Replay.hist.push({time:89.51790000000337, x:547.141882100901, y:1133.3078329392647, frm:0});
Replay.hist.push({time:89.53460000000337, x:548.221096566207, y:1133.3004325655063, frm:0});
Replay.hist.push({time:89.55130000000337, x:549.2997491605125, y:1133.2930186767596, frm:0});
Replay.hist.push({time:89.56790000000338, x:550.3713698815089, y:1133.2856363650228, frm:0});
Replay.hist.push({time:89.58460000000338, x:551.4488503112349, y:1133.2781972888467, frm:0});
Replay.hist.push({time:89.60110000000337, x:552.512826337771, y:1133.27083574531, frm:0});
Replay.hist.push({time:89.61800000000336, x:553.6019526470105, y:1133.2632844723246, frm:0});
Replay.hist.push({time:89.63460000000337, x:554.6711025918098, y:1133.2558566726093, frm:0});
Replay.hist.push({time:89.65120000000339, x:555.7395952023477, y:1133.2484189953536, frm:0});
Replay.hist.push({time:89.66720000000339, x:556.7688362557781, y:1133.2412412914746, frm:0});
Replay.hist.push({time:89.6844000000034, x:557.8745406314575, y:1133.2335163019507, frm:0});
Replay.hist.push({time:89.7012000000034, x:558.9538021447948, y:1133.2259624669039, frm:0});
Replay.hist.push({time:89.7179000000034, x:560.0259050427883, y:1133.2184458851366, frm:0});
Replay.hist.push({time:89.73430000000339, x:561.0858409723443, y:1133.211002441476, frm:0});
Replay.hist.push({time:89.75130000000338, x:562.1837939837578, y:1133.2032797153545, frm:0});
Replay.hist.push({time:89.76690000000339, x:563.1906192639872, y:1133.1961873451824, frm:0});
Replay.hist.push({time:89.78460000000338, x:564.3320970678849, y:1133.1881346284697, frm:0});
Replay.hist.push({time:89.80100000000337, x:565.3889225173623, y:1133.1806683475024, frm:0});
Replay.hist.push({time:89.81790000000338, x:566.4771100270602, y:1133.1729701429865, frm:0});
Replay.hist.push({time:89.83460000000338, x:567.5515546738676, y:1133.165359290659, frm:0});
Replay.hist.push({time:89.85120000000339, x:568.6186953580383, y:1133.1577909038401, frm:0});
Replay.hist.push({time:89.86790000000339, x:569.6913699228978, y:1133.1501743920119, frm:0});
Replay.hist.push({time:89.88460000000339, x:570.7631312557666, y:1133.1425559113957, frm:0});
Replay.hist.push({time:89.9012000000034, x:571.8275541194905, y:1133.1349816640713, frm:0});
Replay.hist.push({time:89.9179000000034, x:572.8974437268145, y:1133.1273609852958, frm:0});
Replay.hist.push({time:89.9347000000034, x:573.9727659664564, y:1133.1196944424694, frm:0});
Replay.hist.push({time:89.9513000000034, x:575.034313913175, y:1133.112119488135, frm:0});
Replay.hist.push({time:89.9680000000034, x:576.1012607516678, y:1133.1044998194498, frm:0});
Replay.hist.push({time:89.9836000000034, x:577.0970250647154, y:1133.0973832915076, frm:0});
Replay.hist.push({time:90.00120000000341, x:578.2193475580436, y:1133.0893566976176, frm:0});
Replay.hist.push({time:90.0180000000034, x:579.289579007419, y:1133.0816975889804, frm:0});
Replay.hist.push({time:90.03460000000341, x:580.3460135407875, y:1133.0741328156835, frm:0});
Replay.hist.push({time:90.05130000000341, x:581.4077328779556, y:1133.0665262272223, frm:0});
Replay.hist.push({time:90.06690000000341, x:582.3985386754425, y:1133.0594244839458, frm:0});
Replay.hist.push({time:90.0846000000034, x:583.5313800790695, y:1133.051301351934, frm:0});
Replay.hist.push({time:90.1003000000034, x:584.5415075595679, y:1133.0440555706473, frm:0});
Replay.hist.push({time:90.1179000000034, x:585.6726202415163, y:1133.0359395470723, frm:0});
Replay.hist.push({time:90.1346000000034, x:586.7446759525349, y:1133.0282454009857, frm:0});
Replay.hist.push({time:90.15120000000341, x:587.809112551112, y:1133.0206045957061, frm:0});
Replay.hist.push({time:90.16790000000341, x:588.8787351862976, y:1133.0129256724927, frm:0});
Replay.hist.push({time:90.18460000000341, x:589.9471119386321, y:1133.0052552590541, frm:0});
Replay.hist.push({time:90.20120000000342, x:591.0078407196094, y:1132.9976397783585, frm:0});
Replay.hist.push({time:90.21800000000341, x:592.0800593741644, y:1132.989942292917, frm:0});
Replay.hist.push({time:90.23460000000343, x:593.1382265175616, y:1132.9823466196754, frm:0});
Replay.hist.push({time:90.25130000000343, x:594.2014569264202, y:1132.9747159975998, frm:0});
Replay.hist.push({time:90.26700000000342, x:595.1998180356411, y:1132.9675526169967, frm:0});
Replay.hist.push({time:90.28420000000344, x:596.2921794751602, y:1132.9597171057976, frm:0});
Replay.hist.push({time:90.30100000000343, x:597.357744794071, y:1132.9520766248172, frm:0});
Replay.hist.push({time:90.31800000000342, x:598.4345701913983, y:1132.944358722858, frm:0});
Replay.hist.push({time:90.33460000000343, x:599.484664901865, y:1132.9368360872147, frm:0});
Replay.hist.push({time:90.35020000000344, x:600.470237468165, y:1132.9297793916835, frm:0});
Replay.hist.push({time:90.36790000000343, x:601.5869456312485, y:1132.921788608911, frm:0});
Replay.hist.push({time:90.38410000000343, x:602.6076084690121, y:1132.9144900501449, frm:0});
Replay.hist.push({time:90.40130000000343, x:603.6897549213793, y:1132.9067574978535, frm:0});
Replay.hist.push({time:90.41790000000344, x:604.7326686666888, y:1132.8993112748049, frm:0});
Replay.hist.push({time:90.43460000000344, x:605.7899079609496, y:1132.8917692530895, frm:0});
Replay.hist.push({time:90.45130000000344, x:606.860427268884, y:1132.8841396440955, frm:0});
Replay.hist.push({time:90.46800000000344, x:607.9293517061784, y:1132.876529085778, frm:0});
Replay.hist.push({time:90.48460000000345, x:608.9902784844054, y:1132.8689835673586, frm:0});
Replay.hist.push({time:90.50130000000345, x:610.0559700140601, y:1132.8614127934588, frm:0});
Replay.hist.push({time:90.51700000000345, x:611.056356131752, y:1132.8543142872588, frm:0});
Replay.hist.push({time:90.53360000000346, x:612.1124789501924, y:1132.8468295274758, frm:0});
Replay.hist.push({time:90.55120000000346, x:613.230396570209, y:1132.8389177249685, frm:0});
Replay.hist.push({time:90.56700000000345, x:614.232393362151, y:1132.8318363414965, frm:0});
Replay.hist.push({time:90.58460000000345, x:615.3467104159473, y:1132.8239728409876, frm:0});
Replay.hist.push({time:90.60040000000345, x:616.3454418826299, y:1132.8169359441426, frm:0});
Replay.hist.push({time:90.61790000000344, x:617.4497812997754, y:1132.8091675507126, frm:0});
Replay.hist.push({time:90.63460000000345, x:618.5018342539515, y:1132.8017798106878, frm:0});
Replay.hist.push({time:90.65120000000346, x:619.5458180195473, y:1132.794461661153, frm:0});
Replay.hist.push({time:90.66700000000344, x:620.5378426535427, y:1132.78752014843, frm:0});
Replay.hist.push({time:90.68460000000344, x:621.6409425226127, y:1132.7798161736605, frm:0});
Replay.hist.push({time:90.70120000000345, x:622.6795069748654, y:1132.7725776690593, frm:0});
Replay.hist.push({time:90.71790000000345, x:623.7224771025427, y:1132.7653234123654, frm:0});
Replay.hist.push({time:90.73460000000345, x:624.7635750299621, y:1132.7580976663226, frm:0});
Replay.hist.push({time:90.75130000000345, x:625.8027837246038, y:1132.7509010123374, frm:0});
Replay.hist.push({time:90.76800000000345, x:626.8400861937663, y:1132.7437340305073, frm:0});
Replay.hist.push({time:90.78460000000346, x:627.8856416956422, y:1132.7365272423112, frm:0});
Replay.hist.push({time:90.80120000000348, x:628.9457721839345, y:1132.729238226313, frm:0});
Replay.hist.push({time:90.81710000000346, x:629.959286030087, y:1132.7222874491345, frm:0});
Replay.hist.push({time:90.83460000000346, x:631.0725782212864, y:1132.7146729943647, frm:0});
Replay.hist.push({time:90.85130000000346, x:632.132826230432, y:1132.7074420373433, frm:0});
Replay.hist.push({time:90.86790000000347, x:633.1846174204832, y:1132.7002893361466, frm:0});
Replay.hist.push({time:90.88360000000347, x:634.1793111105126, y:1132.6935443460095, frm:0});
Replay.hist.push({time:90.90130000000346, x:635.3007580362034, y:1132.6859631608338, frm:0});
Replay.hist.push({time:90.91700000000345, x:636.295298623955, y:1132.6792611562505, frm:0});
Replay.hist.push({time:90.93370000000347, x:637.3531092116297, y:1132.6721553662605, frm:0});
Replay.hist.push({time:90.95130000000346, x:638.4677963199822, y:1132.6646934117152, frm:0});
Replay.hist.push({time:90.96790000000347, x:639.5188939990838, y:1132.657682179909, frm:0});
Replay.hist.push({time:90.98460000000347, x:640.5760938053193, y:1132.6506554512484, frm:0});
Replay.hist.push({time:91.00030000000346, x:641.5696952869237, y:1132.6440750900686, frm:0});
Replay.hist.push({time:91.01800000000345, x:642.6896543724736, y:1132.6366861176773, frm:0});
Replay.hist.push({time:91.03460000000346, x:643.7396106588121, y:1132.629786874416, frm:0});
Replay.hist.push({time:91.05130000000347, x:644.7955234328738, y:1132.6228764310433, frm:0});
Replay.hist.push({time:91.06790000000348, x:645.8447023405454, y:1132.6160385248922, frm:0});
Replay.hist.push({time:91.08460000000348, x:646.8997627094543, y:1132.6091916334872, frm:0});
Replay.hist.push({time:91.10120000000349, x:647.9480241299852, y:1132.602418744168, frm:0});
Replay.hist.push({time:91.11800000000348, x:649.0228040424204, y:1132.5955062184612, frm:0});
Replay.hist.push({time:91.13460000000349, x:650.1136149039671, y:1132.5885242351678, frm:0});
Replay.hist.push({time:91.15130000000349, x:651.2102936057479, y:1132.5815397296476, frm:0});
Replay.hist.push({time:91.16800000000349, x:652.3062216213526, y:1132.5745959869175, frm:0});
Replay.hist.push({time:91.1846000000035, x:653.3947982600871, y:1132.5677353228475, frm:0});
Replay.hist.push({time:91.2008000000035, x:654.4563330491156, y:1132.5610809826903, frm:0});
Replay.hist.push({time:91.2179000000035, x:655.5759974754795, y:1132.554101602843, frm:0});
Replay.hist.push({time:91.2347000000035, x:656.6750714547031, y:1132.5472908275442, frm:0});
Replay.hist.push({time:91.2513000000035, x:657.7601025537807, y:1132.5406071515608, frm:0});
Replay.hist.push({time:91.26790000000351, x:658.844149554754, y:1132.5339702603096, frm:0});
Replay.hist.push({time:91.28460000000351, x:659.9336974027078, y:1132.5273416835491, frm:0});
Replay.hist.push({time:91.30130000000351, x:661.022165920155, y:1132.5207627069271, frm:0});
Replay.hist.push({time:91.31800000000351, x:662.1095130287656, y:1132.5142344689912, frm:0});
Replay.hist.push({time:91.33460000000352, x:663.1891911680385, y:1132.5077967672737, frm:0});
Replay.hist.push({time:91.35130000000352, x:664.2741757821398, y:1132.5013731289287, frm:0});
Replay.hist.push({time:91.36770000000352, x:665.3384408614427, y:1132.495117697584, frm:0});
Replay.hist.push({time:91.38410000000351, x:666.4014613048744, y:1132.4889156286745, frm:0});
Replay.hist.push({time:91.40130000000352, x:667.5149931259284, y:1132.482469341719, frm:0});
Replay.hist.push({time:91.41800000000352, x:668.5947336831443, y:1132.476269136626, frm:0});
Replay.hist.push({time:91.43460000000353, x:669.695646132317, y:1132.4699996983065, frm:0});
Replay.hist.push({time:91.45130000000353, x:670.8290956833775, y:1132.4636014703165, frm:0});
Replay.hist.push({time:91.46790000000354, x:671.9540099736894, y:1132.4573094354741, frm:0});
Replay.hist.push({time:91.48370000000354, x:673.023015715715, y:1132.4513849180476, frm:0});
Replay.hist.push({time:91.50130000000352, x:674.2119386263014, y:1132.444860018378, frm:0});
Replay.hist.push({time:91.51700000000352, x:675.2706384037898, y:1132.439108023455, frm:0});
Replay.hist.push({time:91.53360000000353, x:676.3881872351726, y:1132.4330972067032, frm:0});
Replay.hist.push({time:91.55030000000353, x:677.5104699400912, y:1132.4271253277077, frm:0});
Replay.hist.push({time:91.56730000000353, x:678.6508110858509, y:1132.421125011722, frm:0});
Replay.hist.push({time:91.58470000000354, x:679.8157350980313, y:1132.4150674393668, frm:0});
Replay.hist.push({time:91.60080000000353, x:680.8914727438769, y:1132.4095398651557, frm:0});
Replay.hist.push({time:91.61790000000353, x:682.0318157076028, y:1132.4037513954695, frm:0});
Replay.hist.push({time:91.63470000000353, x:683.1498206638518, y:1132.3981489735638, frm:0});
Replay.hist.push({time:91.65130000000353, x:684.2521976441064, y:1132.392696953435, frm:0});
Replay.hist.push({time:91.66800000000353, x:685.3588510155824, y:1132.3872974540843, frm:0});
Replay.hist.push({time:91.68470000000355, x:686.4630754206938, y:1132.3819850807408, frm:0});
Replay.hist.push({time:91.70130000000354, x:687.5582234644301, y:1132.376792361092, frm:0});
Replay.hist.push({time:91.71790000000355, x:688.6579336931259, y:1132.371655921188, frm:0});
Replay.hist.push({time:91.73470000000356, x:689.8332523269614, y:1132.3662546939013, frm:0});
Replay.hist.push({time:91.75130000000355, x:690.9914831750857, y:1132.3610234740759, frm:0});
Replay.hist.push({time:91.76790000000356, x:692.1465830433484, y:1132.3558990739389, frm:0});
Replay.hist.push({time:91.78420000000357, x:693.2776868231756, y:1132.3509730143396, frm:0});
Replay.hist.push({time:91.80110000000356, x:694.4471385091766, y:1132.3459779027203, frm:0});
Replay.hist.push({time:91.81800000000355, x:695.6131485089535, y:1132.3410991067426, frm:0});
Replay.hist.push({time:91.83370000000356, x:696.69317187954, y:1132.3366728378332, frm:0});
Replay.hist.push({time:91.85130000000355, x:697.9003211054353, y:1132.331833764912, frm:0});
Replay.hist.push({time:91.86800000000355, x:699.0420499657339, y:1132.3273646567109, frm:0});
Replay.hist.push({time:91.88470000000356, x:700.180156743401, y:1132.3230166019184, frm:0});
Replay.hist.push({time:91.90090000000356, x:701.2806439581536, y:1132.3189162651952, frm:0});
Replay.hist.push({time:91.91800000000354, x:702.438477467151, y:1132.314715412222, frm:0});
Replay.hist.push({time:91.93470000000356, x:703.5653442011867, y:1132.3107411323522, frm:0});
Replay.hist.push({time:91.95040000000355, x:704.6211501302455, y:1132.3071222677927, frm:0});
Replay.hist.push({time:91.96800000000354, x:705.8006604250405, y:1132.3032023337512, frm:0});
Replay.hist.push({time:91.98470000000356, x:706.9269524103146, y:1132.2995834479677, frm:0});
Replay.hist.push({time:92.00130000000355, x:708.1203253466655, y:1132.2958849433037, frm:0});
Replay.hist.push({time:92.01800000000355, x:709.3159238079032, y:1132.292323587837, frm:0});
Replay.hist.push({time:92.03470000000357, x:710.5064560776904, y:1132.2889245816193, frm:0});
Replay.hist.push({time:92.05130000000356, x:711.684751391889, y:1132.2857092511886, frm:0});
Replay.hist.push({time:92.06790000000358, x:712.8578756096374, y:1132.282659156504, frm:0});
Replay.hist.push({time:92.08380000000356, x:713.9765766983431, y:1132.279894920618, frm:0});
Replay.hist.push({time:92.10100000000357, x:715.1812892442712, y:1132.2770802099374, frm:0});
Replay.hist.push({time:92.11770000000358, x:716.3453921852752, y:1132.2745245750928, frm:0});
Replay.hist.push({time:92.13450000000357, x:717.5108480293696, y:1132.2721323432518, frm:0});
Replay.hist.push({time:92.15130000000357, x:718.670577776415, y:1132.2699219161386, frm:0});
Replay.hist.push({time:92.16800000000357, x:719.8176383479426, y:1132.2679073824847, frm:0});
Replay.hist.push({time:92.18460000000358, x:720.9520472986287, y:1132.266087983343, frm:0});
Replay.hist.push({time:92.2007000000036, x:722.0466830819613, y:1132.2645001365872, frm:0});
Replay.hist.push({time:92.21700000000358, x:723.1492419625127, y:1132.2630721996497, frm:0});
Replay.hist.push({time:92.23460000000358, x:724.4020829763848, y:1132.2616647876616, frm:0});
Replay.hist.push({time:92.25130000000358, x:725.6301618011792, y:1132.2605144709291, frm:0});
Replay.hist.push({time:92.26800000000358, x:726.8504961000045, y:1132.2596038200581, frm:0});
Replay.hist.push({time:92.28460000000359, x:728.0557309772485, y:1132.2589394941765, frm:0});
Replay.hist.push({time:92.3013000000036, x:729.2602819910012, y:1132.2585168731152, frm:0});
Replay.hist.push({time:92.3180000000036, x:730.4567458998904, y:1132.258344113765, frm:0});
Replay.hist.push({time:92.3346000000036, x:731.6379125891035, y:1132.2584234092594, frm:0});
Replay.hist.push({time:92.3513000000036, x:732.8179060075768, y:1132.258759150684, frm:0});
Replay.hist.push({time:92.3670000000036, x:733.9195141158995, y:1132.2593119950245, frm:0});
Replay.hist.push({time:92.3846000000036, x:735.1455365533376, y:1132.260208772575, frm:0});
Replay.hist.push({time:92.4013000000036, x:736.2999302897957, y:1132.261333964276, frm:0});
Replay.hist.push({time:92.4180000000036, x:737.4455394725626, y:1132.2627298302448, frm:0});
Replay.hist.push({time:92.43410000000361, x:738.5415396259903, y:1132.2643350737278, frm:0});



export default Replay