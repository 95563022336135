import PIXI from '../../lib/PixiProjectionExport.js'
import {
    autoSizeBitmapText,
    autoSizeText,
} from '../../helperfunctions/HelperFunctions'
import * as SpriteSheets from '../TexturePackerLoader/TexturePackerLoader.js'

export function createButtonWithText(
    text,
    buttonWidth = 80,
    buttonHeight = 22,
    textColor = 0xffffff,
    buttonColor = 0x4a90e2,
    offset = 1, // -2 because it's somehow not centered in the height.
    sprite = null,
    spriteColor = 0x000000,
    fontSize = 9
) {
    const buttonPadding = 10

    const buttonTexture = SpriteSheets.textureSheet['button-white.png']
    const buttonSprite = new PIXI.Sprite(buttonTexture)
    buttonSprite.tint = buttonColor
    const buttonContainer = new PIXI.Container()
    const maxUntilWrap = buttonWidth - buttonPadding * 2

    // Once font has been loaded, call a function that uses it
    //console.log(text)
    //console.log(text.toUpperCase())
    const buttonText = new PIXI.BitmapText(text.toUpperCase(), {
        fontName: 'RetroGamingRegular',
        fontSize: fontSize,
        tint: textColor,
        align: 'center',
        maxWidth: maxUntilWrap,
    })

    autoSizeBitmapText(buttonText, maxUntilWrap, 1)

    //--- TODO: This doesn't work with bitmapfont. Need to find another solution
    // autoSizeText(text.toUpperCase(), buttonText, textStyle, 1)

    buttonSprite.width = buttonWidth

    buttonContainer.width = buttonWidth
    buttonContainer.height = buttonHeight

    buttonText.x = buttonSprite.width / 2 - buttonText.width / 2
    buttonText.y =
        buttonSprite.height / 2 - buttonText.maxLineHeight / 2 - offset

    buttonContainer.addChild(buttonSprite)
    buttonContainer.addChild(buttonText)

    if (sprite) {
        const offset = 6
        sprite.width = 9
        sprite.height = 8
        sprite.tint = spriteColor
        sprite.x =
            (buttonWidth -
                sprite.width -
                buttonText.width -
                buttonPadding * 2 -
                offset) /
            2
        sprite.y = buttonSprite.height / 2 - sprite.height / 2

        buttonText.x = sprite.x + sprite.width + offset
        buttonContainer.addChild(sprite)
    }

    return buttonContainer
}
