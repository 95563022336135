///Contains Bezier curve logic
import PIXI from '../lib/PixiProjectionExport.js'
import '@pixi/math-extras'
import * as Hf from './HelperFunctions.js'

const segmentCount = 25
const lutResolution = 20

//--Shorthand function, elem1 and 2 are frm RoadPoint
export function getCurvePoint(t, elem1, elem2) {
    let bezierPoint = calcCubicBezier(
        t,
        new PIXI.Point(elem1.x, elem1.y),
        new PIXI.Point(elem1.aX, elem1.aY),
        new PIXI.Point(elem2.bX, elem2.bY),
        new PIXI.Point(elem2.x, elem2.y)
    )

    return bezierPoint
}
//---Get Point on cubic bezier curve
//t is 0-1 range along current curve,
//p1 is first point,
//p2 is first point control point,
//p3 is second point control point,
//p4 is second point
export function calcCubicBezier(t, p1, p2, p3, p4) {
    let u = 1 - t
    let tt = t * t
    let uu = u * u
    let uuu = uu * u
    let ttt = tt * t

    //let result = uuu * p1;
    let result = p1.multiplyScalar(uuu)
    //result += 3 * uu * t * p2;
    result = result.add(p2.multiplyScalar(3 * uu * t))
    //result += 3 * u * tt * p3;
    result = result.add(p3.multiplyScalar(3 * u * tt))
    //result += ttt * p4;
    result = result.add(p4.multiplyScalar(ttt))

    return result
}
//---obsolete
export function getCurveLength(p1, p2, p3, p4) {
    let length = 0

    let stepSize = 1 / segmentCount
    let pA = p1
    for (let i = 1; i < segmentCount + 1; i++) {
        let pB = calcCubicBezier(stepSize * i, p1, p2, p3, p4)
        length += pB.subtract(pA).magnitude()
        pA = pB
    }

    return length
}
//---Get dirivatives 1 and 2 of given cubic bezier curve
export function getDerivs(t, p1, p2, p3, p4) {
    let c1 = new PIXI.Point(),
        c2 = new PIXI.Point(),
        c3 = new PIXI.Point()

    c3.x = 3 * (p2.x - p3.x) + (p4.x - p1.x)
    c2.x = 3 * (p1.x - p2.x + p3.x - p2.x)
    c1.x = 3 * (p2.x - p1.x)
    c3.y = 3 * (p2.y - p3.y) + (p4.y - p1.y)
    c2.y = 3 * (p1.y - p2.y + p3.y - p2.y)
    c1.y = 3 * (p2.y - p1.y)

    let t2 = t * t
    let deriv1 = new PIXI.Point(
        3 * t2 * c3.x + 2 * t * c2.x + c1.x,
        3 * t2 * c3.y + 2 * t * c2.y + c1.y
    )
    let deriv2 = new PIXI.Point(
        6 * t * c3.x + 2 * c2.x,
        6 * t * c3.y + 2 * c2.y
    )

    return { deriv1: deriv1, deriv2: deriv2 }
}
//---Get curvature from derivatives
export function getCurvature(deriv1, deriv2) {
    //---For Cubic? https://pomax.github.io/bezierinfo/#curvature
    let speed = deriv1.magnitude()
    let numerator = deriv1.x * deriv2.y - deriv2.x * deriv1.y
    let denominator = (deriv1.x * deriv1.x + deriv1.y * deriv1.y) ** (3 / 2)
    return numerator / denominator

    let kappa =
        (deriv1.x * deriv2.y - deriv1.y - deriv2.x) /
        Math.pow(deriv1.x * deriv1.x + deriv1.y * deriv1.y, 2 / 3)

    //return 1 / kappa //This will return radius of curvature
    return kappa //This returns "curvature"

    //---For quadratic?
    // let kappa =
    //     (diriv1.x * diriv2.y - diriv1.y - diriv2.x) /
    //     Math.pow(diriv1.x * diriv1.x + diriv1.y * diriv1.y, 2 / 3)

    // return 1 / kappa

    //Get curve using lastTangent and currentTangent, this will mean you are one frame behind with curve instead of on exact point curve
}
//---Get distance to T lookup table for a given segment
export function getDistLut(p1, p2, p3, p4) {
    let tStepSize = 1 / lutResolution
    let currentPos = new PIXI.Point(p1.x, p1.y) //Start at p1
    let nextPos
    let totalDist = 0
    let lut = [0]
    for (let i = 1; i < lutResolution + 1; i++) {
        nextPos = calcCubicBezier(tStepSize * i, p1, p2, p3, p4)

        let dist = nextPos.subtract(currentPos).magnitude()
        totalDist += dist
        lut.push(totalDist)

        currentPos = nextPos
    }
    return lut
}
//Get t value from current distance since start of segment
export function distToT(lut, distance) {
    let arcLength = lut[lut.length - 1]
    let n = lut.length
    let tStepSize = 1 / (n - 1)

    for (let i = 0; i < n - 1; i++) {
        if (distance >= lut[i] && distance <= lut[i + 1]) {
            let t = Hf.mapRange(
                distance,
                lut[i],
                lut[i + 1],
                i * tStepSize,
                (i + 1) * tStepSize
            )
            return {
                t: t,
            }
        }
    }

    return {
        exceededSegment: true,
        remainder: distance - arcLength, //return remainder if the distance wasn't in range of this curve
    }
}
export function tToDist(lut, t) {
    let tStep = 1 / (lut.length - 1) //Lut table consists of distance at equally sized t steps
    let lutIndex = Math.floor(t / tStep)
    let valueA = Hf.getElementAt(lut, lutIndex) //Get value in lut before given T
    let valueB = Hf.getElementAt(lut, lutIndex + 1) //Get value in lut after given T

    //Interpolate between value before and after
    let dist = Hf.mapRange(
        t,
        lutIndex * tStep,
        (lutIndex + 1) * tStep,
        valueA,
        valueB
    )
    return dist
}
//---Get curvature lookup table for entire track
export function getCurveLut(roadPoints, distStep) {
    let curveLut = []

    let currentIndex = 0
    let currentDist = distStep

    //Get array elements
    let lastPointData = Hf.getElementAt(roadPoints, -1)
    let currentPointData = Hf.getElementAt(roadPoints, 0)
    let nextPointData = Hf.getElementAt(roadPoints, 1)

    //Get prev point
    let totalDist = lastPointData.lut[lastPointData.lut.length - 1]
    totalDist -= distStep
    let tResult = distToT(lastPointData.lut, totalDist)
    let lastPoint = getCurvePoint(tResult.t, lastPointData, currentPointData)

    //Get current point
    let currentpoint = new PIXI.Point(currentPointData.x, currentPointData.y)

    //Get next point
    tResult = distToT(currentPointData.lut, distStep)
    let nextPoint = getCurvePoint(tResult.t, currentPointData, nextPointData)

    while (currentIndex < roadPoints.length) {
        //Get angle between line(-1,0) and line(0,1)
        let lineA = new PIXI.Point(
            currentpoint.x - lastPoint.x,
            currentpoint.y - lastPoint.y
        )
        let lineB = new PIXI.Point(
            nextPoint.x - lastPoint.x,
            nextPoint.y - lastPoint.y
        )

        //Get inner product between the 2 combined vectors
        let angle = Hf.getInnerProduct(lastPoint, currentpoint, nextPoint)

        angle = angle * 2 // innerProduct returns in range on 0-90, should be 0-180

        angle = angle / distStep //Scale angle to distStep, angle is per meter this way

        //Set lut
        curveLut.push(angle)

        //Move along by distStep
        currentDist += distStep

        //Get T value for nextPoint
        let result = distToT(roadPoints[currentIndex].lut, currentDist)

        //If passed into new segment
        if (result.exceededSegment) {
            currentIndex++

            //Get t in new segment
            currentDist = result.remainder
            result = distToT(
                Hf.getElementAt(roadPoints, currentIndex).lut,
                currentDist
            )
        }

        //Shift all points along
        lastPoint = currentpoint
        currentpoint = nextPoint
        nextPoint = getCurvePoint(
            result.t,
            Hf.getElementAt(roadPoints, currentIndex),
            Hf.getElementAt(roadPoints, currentIndex + 1)
        )
    }

    let smoothed = curveLut
    const numSmoothSteps = 2
    for (let i = 0; i < numSmoothSteps; i++) {
        smoothed = smoothCurveLut(smoothed)
    }

    return smoothed
}
//---Smooth out curve Lut values by taking average of previous, current and next
function smoothCurveLut(curveLut) {
    let smoothedLut = [curveLut.length]

    let prev = Hf.getElementAt(curveLut, -1)
    let current = Hf.getElementAt(curveLut, 0)
    let next = Hf.getElementAt(curveLut, 1)

    for (let i = 0; i < curveLut.length; i++) {
        let avg = (prev + current + next) / 3
        smoothedLut[i] = avg

        prev = current
        current = next
        next = Hf.getElementAt(curveLut, i + 2)
    }

    return smoothedLut
}

//---Test Collision between curve and given line, give t range and num of subdivisions in that t range
export function getIntersect(
    p1,
    p2,
    p3,
    p4,
    startT,
    endT,
    numSteps,
    lineA,
    lineB,
    nodeIndex
) {
    //---Get line array from curve pieces startT to endT
    let tStepSize = (endT - startT) / numSteps
    let currentT = startT

    let initialPos = calcCubicBezier(startT, p1, p2, p3, p4)
    let lastPos
    let posA = initialPos
    let posB

    for (let i = 1; i < numSteps + 1; i++) {
        currentT = tStepSize * i + startT
        posB = calcCubicBezier(currentT, p1, p2, p3, p4)

        let intersect = Hf.getLinesIntersect(lineA, lineB, posA, posB)

        if (intersect.doesIntersect) {
            //---Map T to position in between point A and B
            let tA = tStepSize * i + startT
            let tB = tStepSize * (i + 1) + startT

            let intersectPos = new PIXI.Point(intersect.x, intersect.y)

            let totalDist = Hf.getPointDist(posA, posB)
            let distToIntersect = Hf.getPointDist(posA, intersectPos)

            let tAtIntersect = Hf.mapRange(
                distToIntersect,
                0,
                totalDist,
                tA,
                tB
            )

            let totalTravelDist = Hf.getPointDist(lineA, lineB)
            let partialTravelDist = Hf.getPointDist(lineA, intersectPos)
            let remainderDist = totalTravelDist - partialTravelDist

            return {
                doesIntersect: true,
                intersectPos: intersectPos,
                t: tAtIntersect,
                nodeIndex: nodeIndex,
                remainderDist: remainderDist,
            }
        }

        posA = posB
        if (i == numSteps) {
            lastPos = posB
        }
    }

    // //---Loop over each piece with given line and check intersect
    // for (let i = 0; i < numSteps; i++) {
    //     let posA = segmentsToCheck[i]
    //     let posB = segmentsToCheck[i + 1]

    //     let intersect = Hf.getLinesIntersect(lineA, lineB, posA, posB)

    //     if (intersect.doesIntersect) {
    //         //---Map T to position in between point A and B
    //         let tA = tStepSize * i + startT
    //         let tB = tStepSize * (i + 1) + startT

    //         let intersectPos = new PIXI.Point(intersect.x, intersect.y)

    //         let totalDist = Hf.getPointDist(posA, posB)
    //         let distToIntersect = Hf.getPointDist(posA, intersectPos)

    //         let tAtIntersect = Hf.mapRange(
    //             distToIntersect,
    //             0,
    //             totalDist,
    //             tA,
    //             tB
    //         )

    //         let totalTravelDist = Hf.getPointDist(lineA, lineB)
    //         let partialTravelDist = Hf.getPointDist(lineA, intersectPos)
    //         let remainderDist = totalTravelDist - partialTravelDist

    //         return {
    //             doesIntersect: true,
    //             intersectPos: intersectPos,
    //             t: tAtIntersect,
    //             nodeIndex: nodeIndex,
    //             remainderDist: remainderDist,
    //         }
    //     }
    // }

    return {
        doesIntersect: false,
        firstPos: initialPos,
        lastPos: lastPos,
    }
}
