import { GameSettings } from '../../settings/GameSettings.js'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import {
    setBrakePressCallback,
    setBrakeReleaseCallback,
    setDRSInteractive,
    setDRSCallback,
    setGasPressCallback,
    setGasReleaseCallback,
} from '../GameUI/GameUI.js'
import {
    send,
    sendInternalEvent,
} from '../../helperfunctions/HelperFunctions.js'
import * as ParticleContainer from '../particleeffects/particleContainer'

//---Input states
export let isGassing = false
export let isBraking = false
export let isDrsActive = false

export let isDrsAllowed = false

let currentSpeed

//---Countdown input states
let isGasHeld = false
let lastGasInputTime = 0

//---DRS vars
let maxSpeed = GameSettings.maxSpeed
let onDrsActivate
let tutorialActive = false
let drsBoostT = 0

let animateBrake = true
let animateGas = true

//---Start boost + burn out vars
export let isStartBoosted = false
let startBoostTime = 0.5
let startBoostAmount = 0
let startBoostT = 0
let isBurntOut = false
let burnOutTime = 1
let burntOutTimer = 0

export function Init() {
    ParticleContainer.setState('idle', 0)
    setGasPressCallback(() => {
        if (animateGas) {
            animateGas = false
            ParticleContainer.setState('gas', currentSpeed)
        }
        isGassing = true

        if (tutorialActive) {
            send('hideTutorial')
        }
    })
    setBrakePressCallback(() => {
        isBraking = true
        if (currentSpeed > 0 && animateBrake) {
            ParticleContainer.setState('brake', currentSpeed)
            animateBrake = false
        }

        if (isDrsActive) {
            deactivateDrs()
        }

        if (tutorialActive) {
            send('hideTutorial')
        }
    })

    setGasReleaseCallback(() => {
        isGassing = false
        animateGas = true
    })

    setBrakeReleaseCallback(() => {
        isBraking = false
        animateBrake = true
        ParticleContainer.setState('brakeEnd', currentSpeed)
    })

    setDRSCallback(() => {
        isDrsActive = true
        disAllowDrs()
        activateDrs()
        maxSpeed += 20

        if (tutorialActive) {
            send('hideTutorial')
        }
    })
}

function activateDrs() {
    ParticleContainer.setState('boost', GameSettings.drsMaxSpeed)
    isDrsActive = true
    //setDRSInteractive(false)
    onDrsActivate()
    maxSpeed = GameSettings.drsMaxSpeed

    drsBoostT = 0
}

export function deactivateDrs() {
    maxSpeed = GameSettings.maxSpeed
    isDrsActive = false
}

export function allowDrs(onActivate) {
    //Only enable UI if isnt paused, but do set drsAllowed and callback, so when game resumes it gets enabled
    if (!GameSettings.isPaused) {
        setDRSInteractive(true)
    }
    onDrsActivate = onActivate
    isDrsAllowed = true
}

export function disAllowDrs() {
    setDRSInteractive(false)
    isDrsAllowed = false
}

export function forceGasPressRelease() {
    isGassing = false
}

export function forceBrakePressRelease() {
    isBraking = false
}

//---Check gas input during countdown, for start boost
export function checkCountdownThrottle(elapsed) {
    if (isGassing && !isGasHeld) {
        isGasHeld = true
        lastGasInputTime = elapsed
    } else if (!isGassing && isGasHeld) {
        isGasHeld = false
    }
}

//---Decides start boost state
export function onGameStart(elapsed) {
    const noBoost = 1
    const smallBoost = 2
    const largeBoost = 2.5

    if (isGasHeld) {
        let dif = elapsed - lastGasInputTime
        if (dif < noBoost) {
            //Nothing
        } else if (dif < smallBoost) {
            //Small boost
            isStartBoosted = true
            startBoostTime = 0.5
            startBoostAmount = 100

            sendInternalEvent('playSample', { sample: 'powerup' })
        } else if (dif < largeBoost) {
            //Big boost
            isStartBoosted = true
            startBoostTime = 0.8
            startBoostAmount = 200

            sendInternalEvent('playSample', { sample: 'powerup' })
        } else {
            //Engine go boom
            isBurntOut = true

            sendInternalEvent('playSample', { sample: 'enginefail' })
        }

        //console.log({ dif: dif })
    }

    isGasHeld = false
    lastGasInputTime = 0
}

//---Updates speed based on current input
export function updateSpeed(speed, deltaTime) {
    //---Handle initial boost
    if (isStartBoosted) {
        startBoostT += deltaTime / startBoostTime
        speed += (startBoostAmount / startBoostTime) * deltaTime
        speed = Math.min(speed, GameSettings.maxSpeed)
        if (startBoostT > 1) {
            startBoostT = 0
            isStartBoosted = false
        }
    } else if (isBurntOut) {
        burntOutTimer += deltaTime
        if (burntOutTimer > burnOutTime) {
            isBurntOut = false
            burntOutTimer = 0
        } else {
            return speed
        }
    }

    //---Handle input states
    if (isBraking) {
        speed = decelerateCar(speed, deltaTime, GameSettings.carBrakeGs)
    } else if (isDrsActive) {
        drsBoostT += deltaTime / GameSettings.drsBoostTime
        speed +=
            (GameSettings.drsSpeedBoost / GameSettings.drsBoostTime) * deltaTime
        speed = Math.min(speed, GameSettings.drsMaxSpeed)

        if (drsBoostT > 1) {
            deactivateDrs()
        }
    } else if (isGassing) {
        speed = accelerateCar(speed, deltaTime)
    } else {
        speed = decelerateCar(speed, deltaTime, GameSettings.carEngineBrakeGs)
    }

    currentSpeed = speed

    return speed
}

//---Called when gas input is pressed, accelerates speed based on the acceleration curve
//---Curve made using: https://planetcalc.com/8735/ with speed data taken from real f1 videos
function accelerateCar(speed, deltaTime) {
    //---If boosted slowly return to regular max speed
    if (speed > GameSettings.maxSpeed) {
        return decelerateCar(speed, deltaTime, GameSettings.carEngineBrakeGs)
    }

    //---Cubic formula constants
    const a = 0.0469
    const b = -2.5639
    const c = 49.541

    //---Get current pos on acceleration curve and increment by deltaTime
    let roots = Hf.solveCubic(a, b, c, -speed)
    let x = roots[0]
    x +=
        deltaTime *
        GameSettings.accelMult *
        (isDrsActive ? GameSettings.drsMult : 1)

    //---Get new speed value from new x pos on curve
    let accel = a * x ** 3 + b * x ** 2 + c * x
    accel = Math.min(accel, maxSpeed)

    return accel
}

//---Decelerate car, given G force. From either brake input or engine brake
function decelerateCar(speed, deltaTime, g) {
    const gravity = 9.8
    let decelAmount = gravity * g * deltaTime * GameSettings.decelMult
    speed = speed - decelAmount
    decelAmount = Math.max(speed, 0) //dont go backwards
    return decelAmount
}

export function setInitial() {
    isGassing = false
    isBraking = false
    isDrsActive = false
    animateBrake = true
    animateGas = true
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'startTutorial') {
        tutorialActive = true
    }
}
