import PIXI from '../../lib/PixiProjectionExport.js'
import { GameSettings } from '../../settings/GameSettings'
import { textData } from '../../../data/TextData'
import * as GameUI from '../GameUI/GameUI'
import * as HelperFunctions from '../../helperfunctions/HelperFunctions'
import { gsap } from 'gsap'

let overlayIndex
let phase = 0

let parentContainer
let overlayContainer
let gasSprite
let brakeSprite
let closeSprite
let drsSprite
let drsNumberSprite
let contentText
let tutorialText

let backgroundGraphics
let app
let progressedTutorial = false
let tutorialActive = false
let tutorialHidden = false
let onPauseCallback
let onResumeCallback
let timeOutFunction
let allowDrsFunction

let pixelAnimation
let showLapFunction

let deltaTime = 0.0

let timeOutCallback

export function show(
    appRef,
    container,
    close,
    gas,
    brake,
    drs,
    drsNumber,
    pauseCallback,
    resumeCallback,
    allowDrs,
    pixelAnimate,
    showLap
) {
    app = appRef
    parentContainer = container
    closeSprite = close
    gasSprite = gas
    brakeSprite = brake
    drsSprite = drs
    drsNumberSprite = drsNumber
    onPauseCallback = pauseCallback
    onResumeCallback = resumeCallback
    allowDrsFunction = allowDrs
    pixelAnimation = pixelAnimate
    showLapFunction = showLap

    HelperFunctions.sendInternalEvent('startTutorial')

    if (overlayContainer) {
        //console.log('Overlay container exists with phase: ', phase)
        overlayContainer.alpha = 1
        GameUI.stopUIAnimations()
        contentText.y = GameSettings.viewportHeight / 2 - contentText.height / 2
        continueProgress()
        return
    }

    backgroundGraphics = new PIXI.Graphics()
    overlayContainer = new PIXI.Container()
    backgroundGraphics.beginFill(0x000000, 0.6)
    backgroundGraphics.drawRect(
        0,
        0,
        GameSettings.viewportWidth,
        GameSettings.viewportHeight
    )
    backgroundGraphics.endFill()

    parentContainer.addChild(overlayContainer)

    tutorialText = new PIXI.BitmapText(
        textData.buttons.tutorial.toUpperCase(),
        HelperFunctions.getTextStyle('RetroGaming', 16, 0xffffff)
    )

    contentText = new PIXI.BitmapText(
        textData.overlays.tutorialGas.toUpperCase(),
        HelperFunctions.getTextStyle('RetroGaming', 20, 0xffffff)
    )

    if (window.innerWidth < window.innerHeight) {
        resetUIPositions(true)
    } else {
        resetUIPositions()
    }

    contentText.y = GameSettings.viewportHeight / 2 - contentText.height / 2
    tutorialText.y = contentText.y / 2

    overlayContainer.addChild(backgroundGraphics)
    overlayContainer.addChild(tutorialText)
    overlayContainer.addChild(contentText)

    parentContainer.setChildIndex(
        closeSprite,
        parentContainer.children.length - 1
    )
    parentContainer.setChildIndex(
        gasSprite,
        parentContainer.children.length - 1
    )

    continueProgress()
}

function continueProgress() {
    const originalContentY =
        GameSettings.viewportHeight / 2 - contentText.height / 2
    const originalTutorialY = contentText.y / 2
    overlayContainer.alpha = 0
    contentText.y = originalContentY + 30
    tutorialText.y = originalTutorialY + 30
    HelperFunctions.sendInternalEvent('showTutorial')
    phase++

    gsap.to(overlayContainer, { alpha: 1, duration: 0.5, ease: 'power2.out' })
    gsap.to(contentText, {
        y: originalContentY,
        duration: 0.5,
        ease: 'power2.out',
    })
    gsap.to(tutorialText, {
        y: originalTutorialY,
        duration: 0.7,
        ease: 'power2.out',
    })
    //console.log('Continue with increase: ', phase)

    //console.log('Phase: ', phase)
    switch (phase) {
        case 1:
            parentContainer.setChildIndex(brakeSprite, 1)
            brakeSprite.interactive = false
            contentText.text = textData.overlays.tutorialGas.toUpperCase()
            parentContainer.setChildIndex(
                gasSprite,
                parentContainer.children.length - 1
            )
            gasSprite.interactive = true
            HelperFunctions.sendInternalEvent('canGas')
            break
        case 2:
            parentContainer.setChildIndex(gasSprite, 1)
            gasSprite.interactive = false
            contentText.text = textData.overlays.tutorialBrake.toUpperCase()
            parentContainer.setChildIndex(
                brakeSprite,
                parentContainer.children.length - 1
            )
            brakeSprite.interactive = true
            HelperFunctions.sendInternalEvent('canBrake')
            break
        case 3:
            parentContainer.setChildIndex(brakeSprite, 1)
            brakeSprite.interactive = false
            contentText.text = textData.overlays.tutorialDRS.toUpperCase()
            parentContainer.setChildIndex(
                drsSprite,
                parentContainer.children.length - 1
            )
            parentContainer.setChildIndex(
                drsNumberSprite,
                parentContainer.children.length - 1
            )
            allowDrsFunction()
            HelperFunctions.sendInternalEvent('canDrs')
            break
        case 4:
            parentContainer.setChildIndex(gasSprite, 1)
            parentContainer.setChildIndex(brakeSprite, 1)
            parentContainer.setChildIndex(drsSprite, 1)
            parentContainer.setChildIndex(drsNumberSprite, 1)
            contentText.text = textData.overlays.tutorialComplete.toUpperCase()
            contentText.y = GameSettings.viewportHeight / 3 // Tutorial text disappears here, so we want to put the content somewhat higher
            gasSprite.interactive = false
            brakeSprite.interactive = false
    }

    GameUI.onTutorialStateUpdate(phase)

    let width

    if (window.innerWidth < window.innerHeight) {
        width = app.renderer.screen.height
    } else {
        width = app.renderer.screen.width
    }
    contentText.x = width / 2 - contentText.width / 2
}

export function hide() {
    gsap.to(overlayContainer, { alpha: 0, duration: 0.5 })
    gasSprite.interactive = true
    brakeSprite.interactive = true
}

export function forceTutorialEnd() {
    phase = 0
    tutorialActive = false
    gsap.to(overlayContainer, { alpha: 0, duration: 0.5 })
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
    }

    if (obj.data.eventName === 'startTutorial') {
        tutorialActive = true
    }

    if (obj.data.eventName === 'continueTutorial') {
        if (!tutorialActive) return
        //console.log('Continue tutorial')
        onPauseCallback()
        overlayContainer.alpha = 1
        continueProgress()
        progressedTutorial = true
    }

    if (obj.data.eventName === 'hideTutorial') {
        if (!tutorialActive) return

        if (!tutorialHidden && overlayContainer.alpha === 1) {
            tutorialHidden = true
            //console.log('Hide tutorial with hidden: ', tutorialHidden)

            if (progressedTutorial) {
                onResumeCallback(false, false)
            }

            gsap.to(overlayContainer, { alpha: 0, duration: 0.5 })

            HelperFunctions.sendInternalEvent('hideTutorial')
            tutorialHidden = false
        }
    }

    if (obj.data.eventName === 'completeTutorial') {
        if (!tutorialActive) return

        tutorialText.alpha = 0
        overlayContainer.alpha = 1
        continueProgress()

        timeOutFunction = setTimeout(() => {
            HelperFunctions.sendInternalEvent('teleportToStart')
            pixelAnimation()
            tutorialActive = false
            showLapFunction()
            gsap.to(overlayContainer, { alpha: 0, duration: 0.5 })
            GameSettings.isPaused = false
        }, 5000)
    }
}

function resetUIPositions(swap = false) {
    if (!contentText) return

    const width = swap ? app.renderer.screen.height : app.renderer.screen.width
    const height = swap ? app.renderer.screen.width : app.renderer.screen.height

    if (contentText) {
        contentText.x = width / 2 - contentText.width / 2
        tutorialText.x = width / 2 - tutorialText.width / 2
        // Add 2 because somehow it doesn't fill the whole screen.
        backgroundGraphics.width = width + 2
        backgroundGraphics.height = height + 2
    }
}

export function removeTimeOut() {
    clearTimeout(timeOutFunction)
}
