<svelte:options accessors />

<script>
    import { init, onMount } from 'svelte/internal'
    import { gsap } from 'gsap'
    import * as Tone from 'tone'
    import * as InputManager from '../../components/GameInstance/InputManager'
    import { speedPercentage } from '../GameUI/GameUI.js'
    import { pause, resume, speed } from '../GameInstance/GameInstance.js'
    import {
        mapRange,
        randomItemFromArray,
        iOS,
        sendInternalEvent,
    } from '../../helperfunctions/HelperFunctions'
    import * as ParticleContainer from '../particleeffects/particleContainer'
    import { GameSettings } from '../../settings/GameSettings'

    let players = {}
    let tweens = {}
    let path = 'assets/sound/'
    let sampler
    let key = 0
    let loaded = false
    let pitch = new Tone.PitchShift(0).toDestination()
    let gassing = false
    let lastGassing = false
    let maxGasPitch = 20
    let maxGasPitch2 = 10
    let minEngine2Rate = 0.3
    let maxEngine2Rate = 1.5
    let breaking = false
    let muted = false
    let drsPlaying = false
    let visible = true
    let paused = []
    let total = 0
    export let percentageLoaded = 0

    onMount(() => {
        window.addEventListener('message', eventReceived)
        window.document.addEventListener('visibilitychange', function (e) {
            if (window.document.visibilityState === 'hidden') {
                visible = false
            } else {
                visible = true
            }
        })
        preload()
    })

    $: changeVisibility(visible)

    function changeVisibility(visible) {
        if (!loaded) {
            return
        }
        if (!visible) {
            pauseAudio()
        } else {
            resumeAudio()
        }
    }

    export function pauseAudio() {
        //console.log('pause')
        for (let i in players) {
            if (players[i].state === 'started') {
                //console.log('stop', i)
                players[i].stop()
                paused.push(i)
            }
        }
    }

    export function muteAudio(doMute = true) {
        for (let i in players) {
            if (players[i].state === 'started') {
                if (doMute) {
                    //console.log('mute', i)
                    players[i].mute = true
                } else {
                    //console.log('unmute', i)
                    players[i].mute = false
                }
            }
        }
        muted = doMute
    }

    export function getMuted() {
        return muted
    }

    export function resumeAudio() {
        //console.log('resume')
        for (let i in paused) {
            players[paused[i]].start()
        }
        paused = []
    }

    function getExt(preferredext) {
        if (iOS) {
            return 'mp3'
        } else {
            return preferredext
        }
    }

    let urls = [
        {
            name: 'music',
            file: path + 'music.mp3',
            loop: true,
            fadeIn: 1,
            fadeOut: 1,
        },
        {
            name: 'endscreen',
            file: path + 'endscreen.' + getExt('ogg'),
            loop: true,
            fadeIn: 1,
            fadeOut: 1,
        },
        { name: 'beep', file: path + 'beep.mp3' },
        { name: 'finish', file: path + 'finish.mp3' },
        { name: 'crash1', file: path + 'crash1.mp3', volume: 5 },
        { name: 'crash2', file: path + 'crash2.mp3', volume: 5 },
        { name: 'crash3', file: path + 'crash3.mp3', volume: 5 },
        { name: 'transition', file: path + 'transition.mp3' },
        { name: 'boost', file: path + 'boost.mp3' },
        { name: 'enginefail', file: path + 'enginefail.mp3' },
        { name: 'shortboost', file: path + 'shortboost.mp3', volume: 5 },
        { name: 'powerup', file: path + 'powerup.' + getExt('ogg') },
        {
            name: 'break1',
            file: path + 'break1.' + getExt('ogg'),
            fadeOut: 0.1,
        },
        {
            name: 'break2',
            file: path + 'break2.' + getExt('ogg'),
            fadeOut: 0.1,
        },
        {
            name: 'break3',
            file: path + 'break3.' + getExt('ogg'),
            fadeOut: 0.1,
        },
        { name: 'gas', file: path + 'gas.mp3' },
        { name: 'beepEnd', file: path + 'beepEnd.mp3' },
        {
            name: 'engine',
            file: path + 'engine.' + getExt('ogg'),
            effect: ['pitch'],
            loop: true,
            volume: -9,
            fadeIn: 1,
            fadeOut: 2,
        },
        {
            name: 'engine2',
            file: path + 'engine2.' + getExt('ogg'),
            effect: ['pitch'],
            loop: true,
            volume: -10,
            fadeIn: 1,
            fadeOut: 2,
        },
    ]

    total = urls.length

    let effects = {}

    $: initSounds(loaded)

    async function eventReceived(data) {
        switch (data.data.eventName) {
            case 'start':
                await Tone.start()
                playSample('music')

                break

            case 'stopSample':
                stopSample(data.data.sample)
                break
            case 'playSample':
                if (data.data.sample == 'crash') {
                    setTimeout(() => {
                        outOfBounds()
                    }, 500)
                    crash()
                } else if (data.data.sample == 'transition') {
                    outOfBounds()
                } else {
                    playSample(data.data.sample)
                }
                break
        }
    }

    function initSounds() {
        if (loaded) {
            sendInternalEvent('soundLoaded')
            //console.log('all sounds loaded,init')
            for (let i = 0; i < urls.length; i++) {
                let url = urls[i]
                players[url.name] = new Tone.Player(
                    urls[i].asset
                ).toDestination()
                if (url.loop) {
                    players[url.name].loop = true
                }
                if (url.volume) {
                    players[url.name].volume.value = url.volume
                }
                if (url.fadeIn) {
                    players[url.name].fadeIn = url.fadeIn
                }
                if (url.fadeOut) {
                    players[url.name].fadeOut = url.fadeOut
                }

                tweens[url.name] = null
                if (url.effect) {
                    for (let j = 0; j < url.effect.length; j++) {
                        let effect = url.effect[j]
                        if (!effects[url.name]) {
                            effects[url.name] = {}
                        }
                        switch (effect) {
                            case 'pitch':
                                effects[url.name][effect] = new Tone.PitchShift(
                                    0
                                ).toDestination()
                                players[url.name].connect(
                                    effects[url.name][effect]
                                )

                                break
                        }
                    }
                }
            }
            setInterval(soundLoop, 100)
        }
    }

    function killTweenOfEngine() {
        if (tweens['engine']) {
            tweens['engine'].kill()
            tweens['engine'] = null
        }

        if (tweens['engine2']) {
            tweens['engine2'].kill()
            tweens['engine2'] = null
        }
    }

    function soundLoop() {
        if (!visible || muted) {
            return
        }

        if (
            InputManager.isDrsActive &&
            drsPlaying == false &&
            players['powerup'].state == 'stopped' &&
            !GameSettings.isPaused
        ) {
            players['powerup'].start()
            players['boost'].start()
            drsPlaying = true
            setTimeout(() => {
                drsPlaying = false
            }, 5000)
        }

        if (InputManager.isBraking && !breaking) {
            let array = ['break1', 'break2', 'break3']
            let sample = randomItemFromArray(array)
            players[sample].start()
            players['boost'].stop()
            breaking = true
        }

        if (!InputManager.isBraking && breaking) {
            players['break1'].stop()
            players['break2'].stop()
            players['break3'].stop()
            breaking = false
        }

        if (InputManager.isGassing && !gassing) {
            playSample('engine')
            playSample('engine2')
            if (speed < 100) {
                playSample('gas')
            }
            gassing = true
        } else if (!InputManager.isGassing && gassing) {
            // stopSample('engine')
            //stopSample('engine2')
            gassing = false
        }

        if (gassing || InputManager.isDrsActive) {
            killTweenOfEngine()

            effects['engine2']['pitch'].pitch =
                (speedPercentage / 100) * maxGasPitch2
            let range = mapRange(speed, 0, 350, minEngine2Rate, maxEngine2Rate)
            players['engine2'].playbackRate = range
            lastGassing = gassing
            players['engine'].volume.value = -9
            players['engine2'].volume.value = -10
        } else if (speed > 0) {
            if (lastGassing) {
                killTweenOfEngine()

                tweens['engine'] = gsap.to(players['engine'].volume, {
                    value: -50,
                    duration: 1,
                })
                tweens['engine2'] = gsap.to(players['engine2'], {
                    playbackRate: minEngine2Rate,
                    duration: 5,
                })
                lastGassing = false
            }
        }

        if (speed == 0) {
            killTweenOfEngine()
            tweens['engine2'] = gsap.to(players['engine2'].volume, {
                value: -50,
                duration: 1,
            })

            tweens['engine'] = gsap.to(players['engine'].volume, {
                value: -50,
                duration: 1,
            })
        }
    }

    function playSample(name) {
        //console.log('play sample', name)
        if (!visible) {
            return
        }
        if (players[name]) {
            if (players[name].state == 'stopped') {
                players[name].start()
                //console.log('play sample', name)
            }
        } else {
            //console.log('no player found with name ', name)
        }
        if (muted) {
            players[name].mute = true
        }
    }

    function stopSample(name) {
        if (players[name]) {
            players[name].stop()
        } else {
            //console.log('no player found with name ', name)
        }
    }

    function preload() {
        let fileToLoad = ''
        percentageLoaded = (key / total) * 100
        if (urls[key]) {
            fileToLoad = urls[key].file
            // console.log(fileToLoad)
            urls[key].asset = new Tone.Buffer(fileToLoad, function () {
                key++
                preload()
            })
        } else {
            percentageLoaded = 100
            loaded = true
        }
        // console.log('loaded = ', percentageLoaded)
    }

    function crash() {
        let array = ['crash1', 'crash2', 'crash3']
        let sample = randomItemFromArray(array)
        playSample(sample)
    }

    function outOfBounds() {
        //setTimeout(() => {
        playSample('transition')
        //}, 500)
    }

    // let samplesToLoad = ['music.mp3']
</script>
