import PIXI from '../../lib/PixiProjectionExport.js'
import {GameSettings} from '../../settings/GameSettings'

export function createOverlayBackground(transparent = true, color = 0x000000, width = GameSettings.viewportWidth, height = GameSettings.viewportHeight) {
    const overlayBackgroundGraphics = new PIXI.Graphics()
    overlayBackgroundGraphics.beginFill(color, transparent ? 0.6 : 1)
    overlayBackgroundGraphics.drawRect(
        0,
        0,
        width,
        height
    )
    overlayBackgroundGraphics.endFill()
    return overlayBackgroundGraphics
}
