import PIXI from '../../lib/PixiProjectionExport.js'
import { worldData } from '../../../public/assets/gamedata/WorldObjectData'
import { GameSettings } from '../../settings/GameSettings'
import * as TexturePackerLoader from '../TexturePackerLoader/TexturePackerLoader'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import * as TrackPath from '../Track/TrackPath.js'
import * as PerformanceManager from '../PerformanceManager/PerformanceManager.js'
import { animateNumber } from '../GameUI/GameUI'
import * as GameUI from '../GameUI/GameUI'

let worldContainer
let worldContainerCopy

let trafficLightInterval

let worldObjectData

let loadedIndices = []

let allSegmentObjectsPool = []

//Ref to carSprite for position
let carSprite

//--- Variables for rendering of world objects
const visibleSegmentsFront = 5
const visibleSegmentsBack = 4
let segmentObjectsQueue = []

const worldObjectIdLut = [
    'Bridge-Oxxo',
    'Dome',
    'Dome-2',
    'Large-Stadium-Updated',
    'Small-Stadium',
    'Stadium-Large-Front',
    'Stadium-Small-Roof',
    'Tent',
    'arrow-left-1',
    'arrow-left-2',
    'arrow-right-1',
    'bigscreen',
    'board-100-1',
    'board-100-2',
    'board-150-1',
    'board-150-2',
    'board-200-1',
    'board-200-2',
    'board-50-1',
    'board-50-2',
    'boards-drs-1',
    'boards-drs-2',
    'dish',
    'lamppost-left',
    'lamppost-right',
    'oxxo-sign-1-v2',
    'oxxo-sign-2-left',
    'oxxo-sign-2-right',
    'oxxo-signwithoutpole',
    'oxxo-signwithoutpole_premia',
    'oxxo-start-v2',
    'screen-post-left',
    'screen-post-right',
    'side-perspective-stand',
    'top-stands',
    'tree',
    'trees',
    'redbullcan',
]

//--- Ran at start of app, loads required spritesheets and world JSON data
export function initSegmentObjects(container, carSpriteArg) {
    worldContainer = container
    worldObjectData = worldData

    carSprite = carSpriteArg

    //--- At the start of the game, so when we begin, we want objects already to be shown in the world
    addToWorld(GameSettings.segmentToStartAt)
}

export function loadSegmentObjects(index) {
    addToWorld(index)
    removeFromWorld()
}

function addToWorld(index) {
    let newIndex = index % worldObjectData.length

    for (let i = 0; i < visibleSegmentsFront; i++) {
        let newAddedSegment = (i + newIndex) % worldObjectData.length

        if (loadedIndices.includes(newAddedSegment)) {
            continue
        }
        loadedIndices.push(newAddedSegment)

        let matchedSegment = worldObjectData.find(
            (a) => a.id === newAddedSegment.toString()
        )

        let segmentObjectSprites = []
        matchedSegment.objects.forEach((object) => {
            let textureId = worldObjectIdLut[object.i]

            //If perf scaling is active, remove lampposts
            if (
                (PerformanceManager.currentPerformanceLevel > 0 &&
                    textureId == 'lamppost-left') ||
                textureId == 'lamppost-right'
            ) {
                //continue
            } else {
                const getFromPoolSprite = getFromPool(
                    allSegmentObjectsPool,
                    textureId,
                    object
                )
                worldContainer.addChild(getFromPoolSprite)

                segmentObjectSprites.push(getFromPoolSprite)
            }
        })

        segmentObjectsQueue.push(segmentObjectSprites)
    }
}

export function removeTrafficLightsAnimation() {
    clearInterval(trafficLightInterval)
}

// This is the traffic light animation for the game (red > orange > green...)
export function doTrafficLightsAnimation(onCompleteCallback) {
    // Only play animation if at start
    if (TrackPath.currentIndex === 0) {
        const trafficLightTextures = [
            TexturePackerLoader.textureSheet['oxxo-start.png'],
            TexturePackerLoader.textureSheet['oxxo-start-1.png'],
            TexturePackerLoader.textureSheet['oxxo-start-2.png'],
            TexturePackerLoader.textureSheet['oxxo-start-3.png'],
            TexturePackerLoader.textureSheet['oxxo-start-4.png'],
            TexturePackerLoader.textureSheet['oxxo-start-5.png'],
        ]

        // This should be the start sign. This is probs not the way to go, but this is easy.
        const trafficLight = segmentObjectsQueue[0][0]

        // This is really bad, I'm sorry..
        const frames = trafficLightTextures.length

        //for (let i = 0; i < frames + 1; i++) {

        let i = 3
        trafficLightInterval = setInterval(() => {
            if (!GameSettings.isPaused) {
                let sample = null
                if (i > 0) {
                    sample = 'beep'
                }
                if (i === frames) {
                    sample = 'beepEnd'
                }
                if (sample) {
                    Hf.sendInternalEvent('playSample', { sample: sample })
                }

                //console.log('traffic light ', trafficLightTextures[i], i)

                // Show the 3, 2, 1, GO
                if (i >= 3) {
                    GameUI.animateNumber(i)
                }

                let sampleIndex = i
                if (i === frames) {
                    sampleIndex = 0
                }

                trafficLight.texture = trafficLightTextures[sampleIndex]
                if (i === frames) {
                    clearInterval(trafficLightInterval)
                    onCompleteCallback()
                }
                i++
            }
        }, 1120)
        //}
    } else {
        setTimeout(() => {
            onCompleteCallback()
        }, 10)
    }
}

function removeFromWorld() {
    // If the queue's length is longer than what is max allowed to be loaded, we will dequeue and unload
    // The segment objects queue is an array with objects which contains a segmentId and the segment's objects
    if (
        segmentObjectsQueue.length >
        1 + visibleSegmentsFront + visibleSegmentsBack
    ) {
        // Remove the first element of the segmentObjectsQueue, but store its length in a variable
        const toBeRemovedArray = segmentObjectsQueue.shift()

        // For the length that has to be removed, keep removing the child from the world container
        for (let i = 0; i < toBeRemovedArray.length; i++) {
            const toBeRemovedSprite = toBeRemovedArray[i]
            returnToPool(
                allSegmentObjectsPool,
                toBeRemovedSprite,
                worldContainer
            )
        }

        loadedIndices.shift()
    }
}

//---Runs each frame, rotates all active billboards to given angle
export function rotateBillboards(angle) {
    setObjectsSorting()

    segmentObjectsQueue.forEach((segment) => {
        segment.forEach((sprite) => {
            if (sprite.isBillboard) {
                sprite.euler.y = angle - Math.PI
            }
        })
    })
}

function setObjectsSorting() {
    worldContainerCopy = worldContainer.children

    //---Add offset to camera pos, to fix render order of objects close to camera
    //---Helps in turn 3, negates effect in turn 9, as stands to right are now more visible
    //---Worth imo turn 3 is worse
    const camDist = 30
    let cameraOffset = Hf.getVectorFromAngle(
        -carSprite.euler.y * Hf.rad2Deg - 90
    )
    let cameraPosition = carSprite.position3d.add(
        cameraOffset.multiplyScalar(camDist)
    )

    setZIndexes(cameraPosition)

    worldContainer.sortChildren()
}
function setZIndexes(cameraPosition) {
    for (let i = 0; i < worldContainerCopy.length; i++) {
        let currentChild = worldContainerCopy[i] // For test take the first item
        let currentChildMagnitude = Hf.getPointDistSquared(
            currentChild.position3d,
            cameraPosition
        )

        let zIndex =
            Number.MAX_SAFE_INTEGER - Math.round(currentChildMagnitude * 3)

        worldContainerCopy[i].zIndex = zIndex
    }
}

function getFromPool(pool, textureId, worldDataObject) {
    if (textureId === 'oxxo-sign-1-v2') {
        textureId = 'oxxo-sign-1'
    } else if (textureId === 'oxxo-start-v2') {
        textureId = 'oxxo-start'
    }
    let newTexture = TexturePackerLoader.textureSheet[`${textureId}.png`]

    if (pool.length > 0) {
        let toReturnObject = pool[pool.length - 1]
        pool.pop() // Remove last object
        toReturnObject.texture = newTexture
        setPosition(toReturnObject, worldDataObject, toReturnObject.texture)
        return toReturnObject
    } else {
        let newSprite = new PIXI.projection.Sprite3d(newTexture)
        setPosition(newSprite, worldDataObject, newTexture)
        return newSprite
    }
}

function returnToPool(pool, sprite, container) {
    pool.push(sprite)
    container.removeChild(sprite)
}

function setPosition(sprite, worldDataObject, texture) {
    sprite.anchor.x = 0.5
    sprite.anchor.y = 1

    sprite.position3d.x = worldDataObject.x
    sprite.position3d.y = worldDataObject.y

    sprite.scale3d.x = (1 / texture.width) * worldDataObject.sX
    sprite.scale3d.y = (1 / texture.height) * worldDataObject.sY

    if (worldDataObject.m) {
        sprite.scale3d.x *= -1
    }

    sprite.euler.x = -Math.PI / 2
    if (!worldDataObject.b) {
        sprite.euler.y = Math.PI / 2 - worldDataObject.r
    }
    sprite.isBillboard = worldDataObject.b

    //---Set custom sprite colliders
    sprite.colliderScale = 1
    sprite.colliderAnchor = 0.5
    sprite.noCol = false

    let stringId = worldObjectIdLut[worldDataObject.i]
    switch (stringId) {
        case 'Bridge-Oxxo':
        case 'oxxo-sign-1-v2':
        case 'oxxo-start-v2':
            sprite.noCol = true
            break
        case 'tree':
            sprite.colliderScale = 0.3 //0.1
            sprite.colliderAnchor = 0.5
            break
        case 'lamppost-left':
            sprite.colliderScale = 0.6 //0.1
            sprite.colliderAnchor = 0.2
            break
        case 'lamppost-right':
            sprite.colliderScale = 0.6 //0.1
            sprite.colliderAnchor = 0.8
            break
        case 'oxxo-sign-2-left':
            sprite.colliderScale = 0.1 //0.1
            sprite.colliderAnchor = 0.1
            break
        case 'oxxo-sign-2-right':
            sprite.colliderScale = 0.1 //0.1
            sprite.colliderAnchor = 0.9
            break
        case 'screen-post-left':
            sprite.colliderScale = 0.1 //0.1
            sprite.colliderAnchor = 0.1
            break
        case 'screen-post-right':
            sprite.colliderScale = 0.1 //0.1
            sprite.colliderAnchor = 0.9
            break
    }
}
export function checkBillboardCollisions(carPosA, carPosB) {
    //Only check prev, current and next segment objects
    let nextIndex = segmentObjectsQueue.length - (visibleSegmentsFront - 1)
    let prevIndex = nextIndex - 2

    for (let segmentI = prevIndex; segmentI < nextIndex + 1; segmentI++) {
        if (segmentI >= segmentObjectsQueue.length || segmentI < 0) {
            continue
        }
        let worldObjects = segmentObjectsQueue[segmentI]

        for (let i = 0; i < worldObjects.length; i++) {
            let sprite = worldObjects[i]

            if (sprite.noCol) {
                continue
            }

            let corners = Hf.getSpriteWorldCorners(sprite)

            let collision = Hf.getLinesIntersect(
                carPosA,
                carPosB,
                corners.left,
                corners.right
            )
            if (collision.doesIntersect) {
                return {
                    doesIntersect: true,
                    x: collision.x,
                    y: collision.y,
                    spriteLeft: corners.left,
                    spriteRight: corners.right,
                    center: corners.center,
                    centerOffset: corners.centerOffset,
                }
            }
        }
    }

    return {
        doesIntersect: false,
    }
}

export function resetWorldObjects() {
    // For every segment's array, remove from world
    for (let i = 0; i < segmentObjectsQueue.length; i++) {
        const toBeRemovedArray = segmentObjectsQueue[i]
        for (let j = 0; j < toBeRemovedArray.length; j++) {
            const toBeRemovedSprite = toBeRemovedArray[j]
            returnToPool(
                allSegmentObjectsPool,
                toBeRemovedSprite,
                worldContainer
            )
        }
    }

    // Clear all the arrays
    loadedIndices = []
    segmentObjectsQueue = []
    allSegmentObjectsPool = []

    // Then add to world
    addToWorld(0)
}
