import PIXI from '../../lib/PixiProjectionExport.js'
import { GameSettings } from '../../settings/GameSettings'
import { createButtonWithText } from '../GameUI/ButtonWithText'
import { createOverlayBackground } from '../GameUI/OverlayBackground'
import * as SpriteSheets from '../TexturePackerLoader/TexturePackerLoader.js'
import { textData } from '../../../data/TextData'

const cardWidth = 272
const cardHeight = 144
const cardPadding = 5
const buttonOffset = 13

let overlayContainer
let cardContainer
let onQuitCallback
let onUnpauseFunction
let overlayBackgroundGraphics
let closeSprite
let app
let resumeButton
let quitButton

let showOverlay = false

export function createUI(
    appRef,
    parentContainer,
    close,
    openCallback,
    quitCallback,
    unpauseFunction
) {
    showOverlay = true
    app = appRef
    closeSprite = close
    onQuitCallback = quitCallback
    onUnpauseFunction = unpauseFunction

    openCallback()

    if (overlayContainer) {
        show()
        return
    }

    overlayContainer = new PIXI.Container()
    cardContainer = new PIXI.Container()
    const contentContainer = new PIXI.Container()
    parentContainer.addChild(overlayContainer)
    parentContainer.setChildIndex(
        overlayContainer,
        parentContainer.children.length - 1
    )

    overlayContainer.width = GameSettings.viewportWidth
    overlayContainer.height = GameSettings.viewportHeight

    overlayBackgroundGraphics = createOverlayBackground()
    overlayContainer.addChild(overlayBackgroundGraphics)
    overlayContainer.addChild(cardContainer)
    overlayContainer.addChild(contentContainer)

    const cardTexture = SpriteSheets.textureSheet['popupcard-white.png']
    const cardSprite = new PIXI.Sprite(cardTexture)

    cardSprite.width = cardWidth
    cardSprite.height = cardHeight

    const contentText = new PIXI.BitmapText(
        textData.overlays.quit.toUpperCase(),
        {
            fontName: 'RetroGamingRegular',
            fontSize: 14,
            tint: 0x000000,
            trim: true,
            align: 'center',
            wordWrap: true,
            wordWrapWidth: cardWidth - cardPadding,
            breakWords: true,
        }
    )

    // autoSizeText(text, contentText, style, 2)

    contentText.x = cardWidth / 2 - contentText.width / 2

    resumeButton = createButtonWithText(textData.buttons.resume, 88)
    quitButton = createButtonWithText(
        textData.buttons.quit,
        88,
        22,
        0xffffff,
        0xe21c2a
    )
    quitButton.x = resumeButton.x + resumeButton.width + cardPadding

    resumeButton.interactive = true
    quitButton.interactive = true

    resumeButton.on('click', onResumeClick)
    quitButton.on('click', onQuitClick)
    resumeButton.on('tap', onResumeClick)
    quitButton.on('tap', onQuitClick)

    const buttonsContainer = new PIXI.Container()
    buttonsContainer.addChild(resumeButton)
    buttonsContainer.addChild(quitButton)

    const contentHeight = buttonsContainer.height + contentText.height
    contentText.y = (cardHeight - contentHeight - buttonOffset) / 2
    buttonsContainer.x = cardWidth / 2 - buttonsContainer.width / 2
    buttonsContainer.y = contentText.y + contentText.height + buttonOffset

    cardContainer.addChild(cardSprite)
    cardContainer.addChild(contentContainer)
    contentContainer.addChild(contentText)
    contentContainer.addChild(buttonsContainer)

    parentContainer.setChildIndex(
        closeSprite,
        parentContainer.children.length - 1
    )

    if (window.innerWidth < window.innerHeight) {
        resetUIPositions(true)
    } else {
        resetUIPositions()
    }
}

function onResumeClick() {
    hide()
    onUnpauseFunction()
}

function onQuitClick() {
    hide()
    onUnpauseFunction()
    onQuitCallback()
}

export function show() {
    overlayContainer.alpha = 1
    closeSprite.alpha = 0
    setInteractive(true)
}

export function hide() {
    overlayContainer.alpha = 0
    closeSprite.alpha = 1
    setInteractive(false)
}

function setInteractive(interactive) {
    if (interactive) {
        resumeButton.interactive = true
        quitButton.interactive = true
        return
    }

    resumeButton.interactive = false
    quitButton.interactive = false
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
    }
}

function resetUIPositions(swap = false) {
    if (!showOverlay) return
    if (!overlayBackgroundGraphics) return
    const width = swap ? app.renderer.screen.height : app.renderer.screen.width
    const height = swap ? app.renderer.screen.width : app.renderer.screen.height

    // Add 2 because somehow it doesn't fill the whole screen.
    overlayBackgroundGraphics.width = width + 2
    overlayBackgroundGraphics.height = height + 2
    cardContainer.x = width / 2 - cardWidth / 2
    cardContainer.y = height / 2 - cardContainer.height / 2
}
