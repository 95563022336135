import PIXI from '../../lib/PixiProjectionExport'
import { createOverlayBackground } from '../GameUI/OverlayBackground'
import { gsap } from 'gsap'
import { textData } from '../../../data/TextData'
import { getTextStyle } from '../../helperfunctions/HelperFunctions'
import { GameSettings } from '../../settings/GameSettings'
import { send } from '../../helperfunctions/HelperFunctions.js'

let loader
let overlayContainer
let backgroundGraphics
let road
let car
let loadingText
let resources
let app
let width
let height
let loaded = false

let startPositionX
let endPositionX

let tl // Gsap time line for animation for text

export function setUI(appRef, parentContainer, callback) {
    send('loading')
    app = appRef

    loadOwnAssets(() => {
        loaded = true

        callback()

        if (overlayContainer) {
            show()
            return
        }

        overlayContainer = new PIXI.Container()

        parentContainer.addChild(overlayContainer)

        // The resources hash provided by the loader:
        resources = loader.resources

        backgroundGraphics = createOverlayBackground(
            0,
            0x000000,
            GameSettings.viewportWidth,
            GameSettings.viewportHeight
        )
        road = new PIXI.Sprite(resources['assets/loader/road.png'].texture)
        car = new PIXI.Sprite(resources['assets/loader/car.png'].texture)
        loadingText = new PIXI.BitmapText(
            textData.overlays.loading.toUpperCase(),
            getTextStyle('RetroGamingRegular', 16, 0xffffff)
        )

        car.anchor.set(1, 0.5)

        overlayContainer.addChild(backgroundGraphics)
        overlayContainer.addChild(road)
        overlayContainer.addChild(car)
        overlayContainer.addChild(loadingText)

        if (window.innerWidth < window.innerHeight) {
            resetUIPositions(true)
        } else {
            resetUIPositions()
        }

        startPositionX = car.width / 3
        const newWidth = width === 0 ? GameSettings.viewportWidth : width
        endPositionX = newWidth + car.width + startPositionX
        car.x = startPositionX
        doTextAnimation()
    })
}

function loadOwnAssets(callback) {
    loader = new PIXI.Loader()

    loader
        .add('assets/loader/car.png')
        .add('assets/loader/road.png')
        .add('RetroGamingRegular', 'assets/fonts/RetroGamingRegular.xml')
        .load(() => {
            callback()
        })
}

export function show() {
    gsap.to(overlayContainer, { alpha: 1, duration: 0.5 })
}

export function hide() {
    gsap.to(overlayContainer, { alpha: 0, duration: 0.5 })

    send('allLoaded')

    // const event = new Event('loading-done')
    // window.dispatchEvent(event)
}

export function resetAnimation() {
    car.x = car.width / 3
    startPositionX = car.x
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
    }
}

export function setPercentage(percentage) {
    const oneDistanceValue = endPositionX / 100
    const oneDistanceDuration = 0.01
    const duration = percentage * oneDistanceDuration
    const valueX = percentage * oneDistanceValue

    // console.log('Percentage: ', percentage)

    if (valueX < startPositionX) return
    gsap.to(car, {
        x: valueX,
        duration: duration,
        ease: 'power2.inOut',
        onComplete: () => {
            if (percentage === 100) {
                hide()
            }
        },
    })
}

function resetUIPositions(swap = false) {
    if (!overlayContainer) return
    if (overlayContainer.alpha === 0) return // Don't calculate and do stuff if it's not visible.

    width = swap ? app.renderer.screen.height : app.renderer.screen.width
    height = swap ? app.renderer.screen.width : app.renderer.screen.height

    if (overlayContainer && loaded) {
        // +2 for Anti-aliasing.
        backgroundGraphics.width = width + 2
        backgroundGraphics.height = height + 2

        car.y = height / 2
        road.y = height / 2 - road.height / 2
        road.width = width + 2

        const bottomDistanceY = height - (road.y + road.height)
        loadingText.y =
            road.y + road.height + bottomDistanceY / 2 - loadingText.height / 2
        loadingText.x = width / 2 - loadingText.width / 2
    }
}

function doTextAnimation() {
    const loadingStates = [
        `${textData.overlays.loading.toUpperCase()}`,
        `${textData.overlays.loading.toUpperCase()}.`,
        `${textData.overlays.loading.toUpperCase()}..`,
        `${textData.overlays.loading.toUpperCase()}...`,
    ]

    tl = gsap.timeline({ repeat: -1 })
    tl.to(loadingText, { text: loadingStates[0], duration: 1 })
    tl.to(loadingText, { text: loadingStates[1], duration: 1 })
    tl.to(loadingText, { text: loadingStates[2], duration: 1 })
    tl.to(loadingText, { text: loadingStates[3], duration: 1 })
}
