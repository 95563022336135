import PIXI from '../../lib/PixiProjectionExport.js'
import * as SpriteSheets from '../TexturePackerLoader/TexturePackerLoader'
import * as QuitOverlay from '../Overlays/QuitOverlay'
import * as StartOverlay from '../Overlays/StartOverlay'
import {GameSettings} from '../../settings/GameSettings'
import {textData} from '../../../data/TextData'
import {
    getTextStyle,
    msToMinutesAndSeconds,
    send,
} from '../../helperfunctions/HelperFunctions'
import * as InputManager from '../GameInstance/InputManager.js'
import * as TutorialOverlay from '../Overlays/TutorialOverlay'
import * as WorldObjectController from '../World/WorldObjectController'
import * as Hf from '../../helperfunctions/HelperFunctions'
import {gsap} from 'gsap'
import {GlobalData} from '../../../data/GlobalData.js'

export let speedPercentage = 0

let gasPressCallback
let brakePressCallback
let gasReleaseCallback
let brakeReleaseCallback
let closeCallback
let drsCallback

const amountOfSpeedBlocks = 9
const offset1 = 15
const offset2 = 10
const offset3 = 40
const offsetTop = 10
const brakeShift = 37
const baseLabelHeight = 16
const startTintBlock = 0xf7f8ff
const greenTintBlock = 0x1fa444
const yellowTintBlock = 0xffc829
const redTintBlock = 0xe21c2a
const drsWidth = 13
const drsHeight = 13
const lapColor = 0x4a90e2
const bestLapColor = 0x1fa444

let muteSprite
let muteTexture
let unmuteTexture
let muted = false

let soundEngine

let app
export let elapsed = 0.0
let pausedTime = 0.0
let drsNumberContainer
let container
let allLapContainer
let lapContainer
let bestLapContainer
let speedContainer
let speedBlockContainer
let drsRoundContainer
let uiContainer

let closeSprite
let brakeSprite
let gasSprite
let speedBlockSprite
let bestLapSprite
let drsSprite
let drsNumberSprite
let lapSprite

let speedBlockImage

let speedBlockTexture
let lapTexture
let drsTexture
let noDrsTexture

let lapText
let bestLapText
let speedText
let kmhText
let timeText
let countDownText

const textOffsetY = 1 // Text is acting weird.
let onStartCallback
let onReplayFunction
let forceGameOverFunction
let resetPauseSpeedFunction
let allowDrs

let pause
let resume
let skipToSegment

let appTickerAdded = false
let uiHidden = false

let uiState = 'intro'

// Resize values
let resizeWidth
let resizeHeight

// For count down animation
let countDownStates
let tl // Timeline for count down animation

export function createUI(
    showStart,
    appRef,
    soundEngineRef,
    parentContainer,
    startCallback,
    pauseFunction,
    resumeFunction,
    skipToSegmentFunction,
    allowDrsFunction,
    pixelAnimation,
    replayFunction,
    forceGameOver,
    resetPauseSpeed
) {
    app = appRef
    soundEngine = soundEngineRef
    uiContainer = parentContainer
    onStartCallback = startCallback
    pause = pauseFunction
    resume = resumeFunction
    skipToSegment = skipToSegmentFunction
    allowDrs = allowDrsFunction
    onReplayFunction = replayFunction
    forceGameOverFunction = forceGameOver
    resetPauseSpeedFunction = resetPauseSpeed

    speedContainer = new PIXI.Container()
    allLapContainer = new PIXI.Container()
    bestLapContainer = new PIXI.Container()
    lapContainer = new PIXI.Container()
    speedBlockContainer = new PIXI.Container()
    container = new PIXI.Container()

    uiContainer.addChild(lapContainer)
    uiContainer.addChild(bestLapContainer)
    uiContainer.addChild(allLapContainer)
    uiContainer.addChild(speedContainer)
    uiContainer.addChild(speedBlockContainer)
    uiContainer.addChild(container)

    addClose()
    addMute()
    addBrakeAndGas()
    addLapCounter()
    addDRS()
    addBestLapCounter()
    addSpeedBlocks()
    addSpeedText()
    addTimerText()

    allLapContainer.addChild(lapContainer)
    allLapContainer.addChild(lapSprite)
    allLapContainer.addChild(lapText)

    if (bestLapSprite) {
        allLapContainer.addChild(bestLapContainer)
        allLapContainer.addChild(bestLapSprite)
        allLapContainer.addChild(bestLapText)
    }

    StartOverlay.createUI(
        uiContainer,
        appRef,
        soundEngineRef,
        container,
        closeSprite,
        gasSprite,
        brakeSprite,
        drsSprite,
        drsNumberContainer,
        hideUI,
        onPlayClick,
        onTutorialClick,
        pause,
        resume,
        allowDrs,
        pixelAnimation,
        showLap,
        resetPauseSpeedFunction
    )

    if (showStart) {
        StartOverlay.show()
    } else {
        StartOverlay.hide()
    }
}

export function showStartOverlay() {
    StartOverlay.show()
    animateMuteAndClose()
}

function onPlayClick() {
    send('game-start', {
        action: 'game-start',
    })
    uiState = 'game'
    showUI()
    setInteractive(false)
    gasSprite.interactive = true // Can gas during countdown
    WorldObjectController.doTrafficLightsAnimation(() => {
        startTimer()
        setInteractive(true)
        onStartCallback()
    })
    setLapText(0)

    // setTimeout(send('game-end'), 5000)
}

function onTutorialClick() {
    uiState = 'tutorial'
    showUI()
    setInteractive(false)
    setDRSInteractive(false)
    skipToSegment(GameSettings.tutorialGasSegment)
    gasSprite.interactive = true
    setLapText(0)

    hideLap()
    hideBestLap()
}

function showLap() {
    lapContainer.alpha = 1
    lapSprite.alpha = 1
    lapText.alpha = 1
}

function hideLap() {
    lapContainer.alpha = 0
    lapSprite.alpha = 0
    lapText.alpha = 0
}

function showBestLap() {
    bestLapContainer.alpha = 1
    bestLapSprite.alpha = 1
    bestLapText.alpha = 1
}

function hideBestLap() {
    bestLapContainer.alpha = 0
    bestLapSprite.alpha = 0
    bestLapText.alpha = 0
}

export function setInteractive(interactive) {
    if (interactive) {
        brakeSprite.interactive = true
        gasSprite.interactive = true
        //setDRSInteractive(true)
        return
    }

    brakeSprite.interactive = false
    gasSprite.interactive = false
    //setDRSInteractive(false)
}

export function setCloseInteractive(interactive) {
    if (interactive) {
        closeSprite.interactive = true
        closeSprite.alpha = 1
        container.setChildIndex(closeSprite, container.length - 1)
        return
    }

    closeSprite.interactive = false
    closeSprite.alpha = 0
}

// For tutorial
export function setInteractiveManual(canBrake = true, canGas = true) {
    brakeSprite.interactive = canBrake
    gasSprite.interactive = canGas

    //console.log('Gas sprite interactive: ', gasSprite.interactive)
}

export function hideUI() {
    uiHidden = true
    speedContainer.alpha = 0
    speedBlockContainer.alpha = 0

    // Set individual elements' alpha to 0, because we need to shuffle with the indices
    speedBlockContainer.alpha = 0

    lapContainer.alpha = 0
    lapSprite.alpha = 0
    lapText.alpha = 0

    if (bestLapSprite) {
        bestLapContainer.alpha = 0
        bestLapSprite.alpha = 0
        bestLapText.alpha = 0
    }

    drsSprite.alpha = 0
    drsNumberSprite.alpha = 0
    brakeSprite.alpha = 0
    gasSprite.alpha = 0

    if (countDownText) {
        countDownText.alpha = 0
    }

    setInteractive(false)
}

export function showUI() {
    uiHidden = false

    animateInSpeed()
    animateLaps()

    animateInBrake()
    animateInDrs()
    animateInGas()

    setInteractive(true)
}

function addMute() {
    unmuteTexture = SpriteSheets.textureSheet['unmute.png']
    muteTexture = SpriteSheets.textureSheet['mute.png']
    muteSprite = new PIXI.Sprite(unmuteTexture)

    muteSprite.texture = soundEngine.getMuted() ? muteTexture : unmuteTexture
    muteSprite.x = app.renderer.screen.width - closeSprite.width - offset3
    muteSprite.y = offsetTop
    muteSprite.interactive = true

    muteSprite.on('click', onMuteClick)
    muteSprite.on('tap', onMuteClick)

    container.addChild(muteSprite)
}

function addClose() {
    let closeTexture = SpriteSheets.textureSheet['close.png']
    closeSprite = new PIXI.Sprite(closeTexture)

    closeSprite.interactive = true
    closeSprite.buttonMode = true

    closeSprite.x = app.renderer.screen.width - closeSprite.width - offset2
    closeSprite.y = offsetTop

    closeSprite.on('tap', onCloseClick)
    closeSprite.on('click', onCloseClick)

    container.addChild(closeSprite)
}

function onMuteClick() {
    muted = !muted

    if (muted) {
        muteSprite.texture = muteTexture
        soundEngine.muteAudio()
        soundEngine.pauseAudio()
    } else {
        muteSprite.texture = unmuteTexture
        soundEngine.muteAudio(false)
        soundEngine.res()
    }
}

function addSpeedText() {
    setSpeedText(0)
}

function addTimerText() {
    lapContainer.addChild(createAndAddTimeText(0))
}

function addBrakeAndGas() {
    let brakeTexture = SpriteSheets.textureSheet['brake-state-1.png']
    let gasTexture = SpriteSheets.textureSheet['gas-state-1.png']

    brakeSprite = new PIXI.Sprite(brakeTexture)
    gasSprite = new PIXI.Sprite(gasTexture)

    //--- These need to be true in order to detect clicks.
    //--- Button mode shows a different cursor on hover.
    brakeSprite.interactive = true
    gasSprite.interactive = true

    brakeSprite.buttonMode = true
    gasSprite.buttonMode = true

    //-- Positions are calculated according to the size of the app.
    gasSprite.x = app.renderer.screen.width - gasSprite.width - offset1
    gasSprite.y = app.renderer.screen.height - gasSprite.height - offset1

    brakeSprite.x = offset1 + brakeShift
    brakeSprite.y = app.renderer.screen.height - brakeSprite.height - offset1

    gasSprite.on('touchstart', onGasPressed)
    gasSprite.on('touchmove', (eventData) =>
        checkTouchInBounds(
            eventData,
            gasSprite,
            gasPressCallback,
            gasReleaseCallback
        )
    )
    gasSprite.on('touchend', () => gasReleaseCallback())

    brakeSprite.on('touchstart', onBrakePressed)
    brakeSprite.on('touchmove', (eventData) =>
        checkTouchInBounds(
            eventData,
            brakeSprite,
            brakePressCallback,
            brakeReleaseCallback
        )
    )
    brakeSprite.on('touchend', () => brakeReleaseCallback())

    //-- Added these events so it works on desktop as well
    gasSprite.on('pointerover', () => gasPressCallback())
    gasSprite.on('pointerout', () => gasReleaseCallback())
    brakeSprite.on('pointerover', () => brakePressCallback())
    brakeSprite.on('pointerout', () => brakeReleaseCallback())

    container.addChild(gasSprite)
    container.addChild(brakeSprite)
}

function onBrakePressed(eventData) {
    brakePressCallback()
}

function onGasPressed(eventData) {
    gasPressCallback()
}

function checkTouchInBounds(eventData, sprite, onInBounds, onOutOfBounds) {
    //--- Checks whether the touch position is within the sprite's bounds.
    let spriteBoundsMinX = sprite.x
    let spriteBoundsMaxX = sprite.x + sprite.width
    let spriteBoundsMinY = sprite.y
    let spriteBoundsMaxY = sprite.y + sprite.height

    let touchX = GameSettings.isPortrait
        ? eventData.data.global.y
        : eventData.data.global.x
    let touchY = GameSettings.isPortrait
        ? GameSettings.currentViewportHeight - eventData.data.global.x
        : eventData.data.global.y

    let withinBoundsX = touchX > spriteBoundsMinX && touchX < spriteBoundsMaxX
    let withinBoundsY = touchY > spriteBoundsMinY && touchY < spriteBoundsMaxY

    let outsideBoundsX = touchX < spriteBoundsMinX || touchX > spriteBoundsMaxX
    let outsideBoundsY = touchY < spriteBoundsMinY || touchY > spriteBoundsMaxY

    if (withinBoundsX && withinBoundsY) {
        onInBounds()
    } else if (outsideBoundsX || outsideBoundsY) {
        onOutOfBounds()
    }
}

function onCloseClick(eventData) {
    if (closeCallback) {
        closeCallback()
    }

    soundEngine.pauseAudio();

    setCloseInteractive(false)
    QuitOverlay.createUI(
        app,
        container,
        closeSprite,
        () => {
            pause()
            if (countDownText) {
                countDownText.alpha = 0
            }
        },
        onQuitCallback,
        onResumeCallback
    )
}

function onResumeCallback() {
    setCloseInteractive(true)
    unpauseFunction()
    soundEngine.resumeAudio();
}

function unpauseFunction() {
    resume(true, true)
}

function onQuitCallback() {
    // Depending on what state the game is in, do stuff.
    switch (uiState) {
        case 'intro':
            resume(true, true)
            send('close', {
                action: 'close',
            })
            break
        case 'tutorial': // If quit on tutorial, make user go to the 'intro' screen.
            resume(true, true, true)
            InputManager.forceGasPressRelease()
            InputManager.forceBrakePressRelease()
            TutorialOverlay.removeTimeOut() // Very important. This is so the delayed callback won't be fired.
            TutorialOverlay.forceTutorialEnd()
            onReplayFunction()
            stopUIAnimations()
            stopTimer()
            resetTimer()
            hideUI()
            StartOverlay.show()
            WorldObjectController.removeTrafficLightsAnimation()
            Hf.sendInternalEvent('stopSample', {sample: 'music'})
            Hf.sendInternalEvent('stopSample', {sample: 'engine2'})
            Hf.sendInternalEvent('stopSample', {sample: 'engine'})
            break
        case 'game':
            resume(true, true)
            WorldObjectController.removeTrafficLightsAnimation()
            forceGameOverFunction()
            break
        case 'gameOver':
            resume(true, true)
            send('close', {
                action: 'close',
            })
            break
        default:
            send('close', {
                action: 'close',
            })
            break
    }
}

let gasAnimState = {
    t: 0,
    isAutoAnimating: false,
    isAutoAnimIncreasing: true,
    baseTexName: 'gas',
}
let brakeAnimState = {
    t: 0,
    isAutoAnimating: false,
    isAutoAnimIncreasing: true,
    baseTexName: 'brake',
}

export function onTutorialStateUpdate(phase) {
    switch (phase) {
        case 1:
            gasAnimState.isAutoAnimating = true
            break
        case 2:
            brakeAnimState.isAutoAnimating = true
            break
    }
}

export function stopUIAnimations() {
    gasAnimState.isAutoAnimating = false
    brakeAnimState.isAutoAnimating = false
}

export function uiUpdateLoop() {
    let deltaTime = app.ticker.deltaMS / 1000
    animatePedal(gasAnimState, InputManager.isGassing, gasSprite, deltaTime)
    animatePedal(brakeAnimState, InputManager.isBraking, brakeSprite, deltaTime)
}

//---Animate one of the gas/brake pedals
function animatePedal(animState, inputState, spriteToChange, deltaTime) {
    //---Only anim if pressed, not at default state, or auto animating
    if (inputState || animState.t != 0 || animState.isAutoAnimating) {
        //---Increase/Decrease T
        let stepSize = deltaTime / GameSettings.gasBrakeAnimTime
        stepSize /= animState.isAutoAnimating ? 3 : 1 //slow down anim if auto anim
        animState.t +=
            stepSize *
            (inputState ||
            (animState.isAutoAnimIncreasing && animState.isAutoAnimating)
                ? 1
                : -1)

        //Clamp values
        animState.t = Math.max(0, animState.t)
        animState.t = Math.min(1, animState.t)

        //---If auto animating keep cycling
        if (animState.isAutoAnimating) {
            if (animState.t == 1) {
                animState.isAutoAnimIncreasing = false
            } else if (animState.t == 0) {
                animState.isAutoAnimIncreasing = true
            }
        }

        //3 total sprites, start at index 1, with a min of 2 or T value 1 returns index 4
        let spriteIndex = Math.min(Math.floor(animState.t * 3), 2) + 1
        let textureName =
            animState.baseTexName + '-state-' + spriteIndex + '.png'

        spriteToChange.texture = SpriteSheets.textureSheet[textureName]
    }

    if (animState.isAutoAnimating && inputState) {
        animState.isAutoAnimating = false
    }
}

export function setSpeedText(speed) {
    if (uiHidden) return

    //Ramp up slower but keep max speed to get a more "proper" value in the corner
    let adjustedVisualSpeed = speed * (speed / GameSettings.maxSpeed)
    let fixedSpeed = adjustedVisualSpeed.toFixed(0)

    if (!speedText) {
        speedText = new PIXI.BitmapText(
            fixedSpeed,
            getTextStyle('RetroGaming', 33, 0xffffff)
        )
    } else {
        speedText.text = fixedSpeed
    }

    if (!kmhText) {
        kmhText = new PIXI.BitmapText(
            ' ' + textData.gameUI.speedUnit.toUpperCase(),
            getTextStyle('RetroGaming', 10, 0xffffff)
        )
    } else {
        kmhText.text = ` ${textData.gameUI.speedUnit.toUpperCase()}`
    }

    speedText.x = offset1 - 4
    speedText.y = speedBlockSprite.y - speedText.height - offset1

    kmhText.x = speedText.x + speedText.width
    kmhText.y = speedText.y + speedText.height - kmhText.height + 5

    speedContainer.addChild(speedText)
    speedContainer.addChild(kmhText)
}

function addSpeedBlocks() {
    speedBlockTexture = SpriteSheets.textureSheet['speedometer-bar-part.png']

    //--- Speed blocks are created individually according to how many are set to be made.
    for (let i = 0; i < amountOfSpeedBlocks; i++) {
        speedBlockSprite = new PIXI.Sprite(speedBlockTexture)
        speedBlockSprite.tint = startTintBlock

        speedBlockSprite.width = 10
        speedBlockSprite.height = 16

        speedBlockSprite.x = offset1 + (i % amountOfSpeedBlocks) * 12
        speedBlockSprite.y = drsSprite.y - speedBlockSprite.height - offset1
        speedBlockContainer.addChild(speedBlockSprite)

        speedBlockImage = speedBlockSprite
    }
}

// Percentage value, but the blocks are always filled whole
export function setSpeedBlockValue(percentage) {
    speedPercentage = percentage
    if (uiHidden) return

    const percentageDecimal = percentage / 100
    const value = percentageDecimal * amountOfSpeedBlocks

    const roundedValue = Math.round(value)

    for (let i = 0; i < speedBlockContainer.children.length; i++) {
        if (roundedValue > i) {
            if (i < 3) {
                speedBlockContainer.children[i].tint = greenTintBlock
            }

            if (i >= 3 && i < 6) {
                speedBlockContainer.children[i].tint = yellowTintBlock
            }

            if (i >= 6 && i < amountOfSpeedBlocks) {
                speedBlockContainer.children[i].tint = redTintBlock
            }
        } else {
            speedBlockContainer.children[i].tint = startTintBlock
        }
    }
}

function addBestLapCounter(show = false) {
    if (uiHidden) return

    if (bestLapSprite) {
        if (!GlobalData.personalRecord <= 0) {
            setBestLapText(GlobalData.personalRecord)
            return
        }
    }

    bestLapSprite = new PIXI.Sprite(lapTexture)
    const yOffset = 2
    bestLapSprite.tint = bestLapColor

    bestLapSprite.x = offset1
    bestLapSprite.y = lapSprite.y + lapSprite.height + yOffset

    bestLapText = new PIXI.BitmapText(
        textData.gameUI.best.toUpperCase(),
        getTextStyle('RetroGaming', 8.5, 0xffffff)
    )

    bestLapSprite.width = bestLapText.width + offset1
    bestLapSprite.height = baseLabelHeight

    bestLapText.x = offset1 + bestLapSprite.width / 2 - bestLapText.width / 2
    bestLapText.y =
        bestLapSprite.y + bestLapSprite.height / 2 - bestLapText.height / 2 - 1 // Font isn't centered in height somehow

    container.addChild(bestLapSprite)
    container.addChild(bestLapText)
    
    hideBestLap()

    if (!GlobalData.personalRecord <= 0) {
        setBestLapText(GlobalData.personalRecord)
        showBestLap()
    }
}

function addLapCounter() {
    if (uiHidden) return

    lapTexture = SpriteSheets.textureSheet['lap-label.png']
    lapSprite = new PIXI.Sprite(lapTexture)
    lapSprite.tint = lapColor

    lapSprite.x = offset1
    lapSprite.y = offset1

    lapText = new PIXI.BitmapText(
        textData.gameUI.lap.toUpperCase(),
        getTextStyle('RetroGaming', 8.5, 0xffffff)
    )

    lapSprite.width = lapText.width + offset1
    lapSprite.height = baseLabelHeight

    lapText.x = lapSprite.x + lapSprite.width / 2 - lapText.width / 2
    lapText.y =
        lapSprite.y + lapSprite.height / 2 - lapText.height / 2 - textOffsetY

    container.addChild(lapSprite)
    container.addChild(lapText)
}

export function startTimer() {
    //---Only add the ticker when it hasn't been added yet
    if (!appTickerAdded) {
        app.ticker.add(updateFunction)
        appTickerAdded = true
    }
}

export function stopTimer() {
    const returnTime = elapsed
    //console.log('Return time: ', elapsed)
    app.ticker.remove(updateFunction)
    appTickerAdded = false
    return returnTime
}

export function pauseTimer() {
    pausedTime = elapsed

    stopTimer()
}

function updateFunction(delta) {
    Update(delta)
}

export function resetTimer() {
    elapsed = 0
    setLapText(0)
}

function Update() {
    //---Set loop vars
    let deltaTime = app.ticker.deltaMS * 1.15 //Fudge the time a little to match round times of irl track better
    elapsed += deltaTime

    setLapText(elapsed)
}

function setLapText(timeInMs) {
    updateTimeText(timeInMs)
}

function createAndAddTimeText(timeInMs, type = 'Lap', isNumber = true) {
    let sprite
    let labelTexture
    let labelSprite

    let convertedTime

    if (isNumber) {
        convertedTime = msToMinutesAndSeconds(timeInMs)
    } else {
        convertedTime = timeInMs
    }

    timeText = new PIXI.BitmapText(
        convertedTime.toString(),
        getTextStyle('RetroGaming', 8, 0xffffff)
    )

    const containerWithLabelAndTime = new PIXI.Container()

    containerWithLabelAndTime.removeChildren()

    labelTexture = SpriteSheets.textureSheet['lap-label.png']
    labelSprite = new PIXI.Sprite(labelTexture)
    labelSprite.tint = lapColor

    if (type === 'Lap') {
        sprite = lapSprite
    }

    if (type === 'BestLap') {
        labelSprite.tint = bestLapColor
        sprite = bestLapSprite

        //console.log('Time in ms: ', timeInMs)
    }

    timeText.x = sprite.x + sprite.width + 2 + offset2 / 2
    timeText.y =
        sprite.y +
        timeText.height / 2 -
        (type === 'lap' ? textOffsetY / 2 : textOffsetY)

    labelSprite.width = timeText.width + offset2
    labelSprite.height = sprite.height

    labelSprite.x = sprite.x + sprite.width + 2
    labelSprite.y = sprite.y

    containerWithLabelAndTime.addChild(labelSprite)
    containerWithLabelAndTime.addChild(timeText)

    return containerWithLabelAndTime
}

function updateTimeText(timeInMs) {
    let subContainer = lapContainer.children[0]
    let timeText = subContainer.children[1]

    let convertedTime = msToMinutesAndSeconds(timeInMs)

    timeText.text = convertedTime
}

export function setBestLapText(timeInMs, isNumber, show = false) {
    bestLapContainer.alpha = 1
    bestLapSprite.alpha = 1
    bestLapText.alpha = 1
    
    bestLapContainer.removeChildren()
    bestLapContainer.addChild(
        createAndAddTimeText(timeInMs, 'BestLap', isNumber)
    )
}


function addDRS() {
    const numberTextures = SpriteSheets.getNumberTexturesFromSpriteSheet(
        0,
        'small'
    )
    drsNumberContainer = new PIXI.Container()

    drsNumberSprite = new PIXI.Sprite(numberTextures[0])
    noDrsTexture = SpriteSheets.textureSheet['drs.png']
    drsTexture = SpriteSheets.textureSheet['drs-red.png']
    drsSprite = new PIXI.Sprite(drsTexture)

    drsSprite.x = offset1 + brakeSprite.width + offset1 + brakeShift
    drsSprite.y = GameSettings.viewportHeight - drsSprite.height - offset1

    drsSprite.interactive = true
    drsSprite.buttonMode = true
    drsSprite.on('tap', onDRSClick)
    drsSprite.on('click', onDRSClick)

    drsRoundContainer = new PIXI.Container()

    drsNumberContainer.addChild(drsRoundContainer)
    drsNumberContainer.width = drsWidth
    drsNumberContainer.height = drsHeight
    drsNumberContainer.x = drsSprite.x + drsSprite.width - drsWidth
    drsNumberContainer.y = drsSprite.y

    drsRoundContainer.addChild(drsNumberSprite)

    setDrsProps(drsNumberSprite)

    container.addChild(drsSprite)
    container.addChild(drsNumberContainer)

    setDRSInteractive(false) //Disable by default
}

function setDrsProps(sprite) {
    sprite.width = 3
    sprite.height = 7
    sprite.x = drsWidth / 2 - sprite.width / 2
    sprite.y = drsHeight / 2 - sprite.height / 2
}

// This doesn't work for numbers above the 9.
export function updateDRS(number) {
    if (number <= 0) {
        number = 0
        drsSprite.texture = noDrsTexture
    } else {
        if (drsSprite.texture !== drsTexture) {
            drsSprite.texture = drsTexture
        }
    }

    drsRoundContainer.removeChildAt(0)
    const numberTextures = SpriteSheets.getNumberTexturesFromSpriteSheet(
        number,
        'small'
    )
    drsNumberSprite = new PIXI.Sprite(numberTextures[0])
    drsRoundContainer.addChild(drsNumberSprite)

    if (uiHidden) {
        drsNumberSprite.alpha = 0
    } else {
        drsNumberSprite.alpha = 0.7
    }

    setDrsProps(drsNumberSprite)
}

export function getCloseSprite() {
    return closeSprite
}

export function getCloseContainer() {
    return container
}

function onDRSClick() {
    drsCallback()
}

export function setDRSInteractive(enabled) {
    if (uiHidden) return

    if (enabled) {
        drsSprite.tint = 0xffffff
        drsSprite.alpha = 1
        drsNumberSprite.alpha = 1
        drsSprite.interactive = true
        container.setChildIndex(
            drsNumberContainer,
            container.children.length - 1
        )
    } else {
        drsSprite.tint = 0xb5b5b5
        drsSprite.alpha = 0.7
        drsNumberSprite.alpha = 0.7
        drsSprite.interactive = false
    }
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
    }
}

function resetUIPositions(swap = false) {
    resizeWidth = swap ? app.renderer.screen.height : app.renderer.screen.width
    resizeHeight = swap ? app.renderer.screen.width : app.renderer.screen.height

    closeSprite.x = resizeWidth - closeSprite.width - offset2

    muteSprite.x = resizeWidth - muteSprite.width - offset3
    gasSprite.x = resizeWidth - gasSprite.width - offset1

    gasSprite.y = resizeHeight - gasSprite.height - offset1
    brakeSprite.y = resizeHeight - brakeSprite.height - offset1
    drsSprite.y = resizeHeight - drsSprite.height - offset1
    drsNumberContainer.y = drsSprite.y

    closeSprite.y = offsetTop;
    muteSprite.y = offsetTop;
    closeSprite.alpha = 1;
    muteSprite.alpha = 1;



    //---Set speed blocks
    for (let i = 0; i < speedBlockContainer.children.length; i++) {
        speedBlockSprite = speedBlockContainer.children[i]

        speedBlockSprite.x = offset1 + (i % amountOfSpeedBlocks) * 12
        speedBlockSprite.y = drsSprite.y - speedBlockSprite.height - offset1
    }

    //---Set speed text
    speedText.y = speedBlockSprite.y - speedText.height - offset1

    kmhText.y = speedText.y + speedText.height - kmhText.height + 5
}

export function setGasPressCallback(callback) {
    gasPressCallback = callback
}

export function setBrakePressCallback(callback) {
    brakePressCallback = callback
}

export function setGasReleaseCallback(callback) {
    gasReleaseCallback = callback
}

export function setBrakeReleaseCallback(callback) {
    brakeReleaseCallback = callback
}

export function setDRSCallback(callback) {
    drsCallback = callback
}

export function setUiState(state) {
    uiState = state
}

export function animateNumber(number) {
    const actualNumber = 6 - number

    //console.log('actualNumber', actualNumber)

    if (countDownText) {
        countDownText.alpha = 1
        countDownText.anchor.set(0.5, 0.5)
        countDownText.fontSize = 120

        if (actualNumber === 0) {
            countDownText.text = textData.gameUI.go.toUpperCase()
        } else {
            countDownText.text = actualNumber
        }
        countDownText.x = resizeWidth / 2
        countDownText.y = resizeHeight / 2
        gsap.to(countDownText, {
            fontSize: 60,
            duration: 0.3,
            ease: 'power2.out',
            onComplete: () => {
                if (countDownText.text === textData.gameUI.go.toUpperCase()) {
                    gsap.to(countDownText, {alpha: 0, duration: 0.6})
                }
            },
        })

        return
    }

    countDownText = new PIXI.BitmapText(
        number,
        getTextStyle('RetroGaming', 110, 0xffffff)
    )
    countDownText.alpha = 1
    countDownText.anchor.set(0.5, 0.5)
    countDownText.scale.set(1.2, 1.2)

    countDownText.x = resizeWidth / 2
    countDownText.y = resizeHeight / 2

    gsap.to(countDownText, {fontSize: 60, duration: 0.3, ease: 'power2.out'})

    uiContainer.addChild(countDownText)
}

function animateMuteAndClose() {

    muteSprite.alpha = 0;
    closeSprite.alpha = 0;
    muteSprite.y = -20;
    closeSprite.y = -20;
    let buttonsTargetY = offsetTop;
    gsap.to(muteSprite, {
        alpha: 1,
        y: buttonsTargetY,
        delay: 0.75,
        ease: 'power2.inOut',
        onComplete: () => {
            muteSprite.y = offsetTop
                resetUIPositions(GameSettings.isPortrait);
            }
    })
    gsap.to(closeSprite, {
        alpha: 1,
        y: buttonsTargetY,
        delay: 0.55,
        ease: 'power2.inOut',
        onComplete: () => {
            
            }
    })
}

function animateLaps() {
    lapContainer.alpha = 1
    lapSprite.alpha = 1
    lapText.alpha = 1

    allLapContainer.x = -allLapContainer.width

    gsap.to(allLapContainer, {x: 0, duration: 0.6, ease: 'power2.inOut'})
}

function animateInSpeed() {
    speedContainer.alpha = 1
    speedBlockContainer.alpha = 1

    speedContainer.x = -speedContainer.width
    speedBlockContainer.x = -speedBlockContainer.width

    gsap.to(speedContainer, {x: 0, duration: 0.7, ease: 'power2.inOut'})
    gsap.to(speedBlockContainer, {x: 0, duration: 0.7, ease: 'power2.inOut'})
}

function animateInGas() {
    gasSprite.alpha = 1

    gasSprite.x = GameSettings.currentViewportWidth + gasSprite.width

    gsap.to(gasSprite, {
        x: GameSettings.currentViewportWidth - gasSprite.width - offset1,
        duration: 0.8,
        ease: 'power2.inOut',
    })
}

function animateInDrs() {
    drsNumberSprite.alpha = 0
    drsNumberContainer.alpha = 0
    drsSprite.alpha = 0.7
    drsSprite.x = -drsSprite.width + brakeShift

    gsap.to(drsSprite, {
        x: offset1 + brakeSprite.width + offset1 + brakeShift,
        duration: 0.8,
        ease: 'power2.inOut',
        onComplete: () => {
            drsNumberSprite.alpha = 0.7
            drsNumberContainer.alpha = 0.7
        },
    })
}

function animateInBrake() {
    brakeSprite.alpha = 1

    brakeSprite.x = -brakeSprite.width + brakeShift

    gsap.to(brakeSprite, {x: offset1 + brakeShift, duration: 0.8, ease: 'power2.inOut'})
}
