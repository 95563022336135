import PIXI from '../../lib/PixiProjectionExport.js'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import { GameSettings } from '../../settings/GameSettings'

//--Refs
let app = {}
let resizeCallback

//---Frametimes
let frametimeHistory = new Array(60 * 3)
let frametimeHistoryIndex = 0

//---Level settings
const frametimeMax = 35 //28.5 fps
const frametimeMin = 20 //50 fps
export let currentPerformanceLevel = 0

export function initPerformanceManager(appArg, resizeCallbackArg) {
    app = appArg
    resizeCallback = resizeCallbackArg
}

export function checkFrametime(deltaTime) {
    frametimeHistory[frametimeHistoryIndex] = deltaTime * 1000
    frametimeHistoryIndex = frametimeHistoryIndex + 1

    if (frametimeHistoryIndex >= frametimeHistory.length) {
        frametimeHistoryIndex = 0
        checkPerformance()
    }
}

function checkPerformance() {
    let avgFrametime = Hf.average(frametimeHistory)

    if (avgFrametime > frametimeMax) {
        currentPerformanceLevel++
        if (currentPerformanceLevel == 3) {
            currentPerformanceLevel = 2
        } else {
            updatePerformanceSettings()
        }
    } else if (avgFrametime < frametimeMin) {
        currentPerformanceLevel--
        if (currentPerformanceLevel == -1) {
            currentPerformanceLevel = 0
        } else {
            updatePerformanceSettings()
        }
    }
}
function updatePerformanceSettings() {
    if (currentPerformanceLevel == 0) {
        app.renderer.resolution = 3
        app.renderer.plugins.interaction.resolution = 3
        resizeCallback()
    } else if (currentPerformanceLevel == 1) {
        app.renderer.resolution = 2
        app.renderer.plugins.interaction.resolution = 2
        resizeCallback()
    } else if (currentPerformanceLevel == 2) {
        app.renderer.resolution = 1
        app.renderer.plugins.interaction.resolution = 1
        resizeCallback()
    }
}
