<svelte:options accessors />

<script>
    import * as GameLogic from './GameInstance.js'

    let pixiContainer
    let pixiWidth
    let pixiHeight

    export function buildGame(soundEngine) {
        //console.log("Pixi width: ", pixiWidth, " pixi height: ", pixiHeight)
        GameLogic.buildGameInstance(pixiContainer, soundEngine)
    }
</script>

<div
    id="pixiContainer"
    class="pixiContainer"
    bind:this={pixiContainer}
    bind:clientWidth={pixiWidth}
    bind:clientHeight={pixiHeight}
/>

<style>
    .pixiContainer {
        /*transform: scale(3);*/
        image-rendering: pixelated;
    }
</style>
