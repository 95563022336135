export const trackRoadPoints = [
    {
        x: 738.9773559570312,
        y: 1132.265048828125,
        aX: 847.6080932617188,
        aY: 1132.7880859375,
        bX: 642.2589721679688,
        bY: 1132.101806640625,
        normal: {
            x: 0.004814761087090949,
            y: -0.9999884089706611,
        },
        lut: [
            0, 17.028345570988574, 35.459684220791054, 55.19786730886977,
            76.14674627337425, 98.21017264743493, 121.2919980751459,
            145.29607432827697, 170.1262533245047, 195.68638714784223,
            221.88032807195415, 248.61192858710837, 275.7850414316482,
            303.3035196290606, 331.071216531966, 358.99198587472625,
            386.96968183682463, 414.908159119829, 442.7112730416217,
            470.2828796527904, 497.52683588176785,
        ],
    },
    {
        x: 1236.494140625,
        y: 1135.3817138671875,
        aX: 1416.8079833984375,
        aY: 1136.93798828125,
        bX: 1056.1802978515625,
        bY: 1133.825439453125,
        normal: {
            x: 0.00863059889222012,
            y: -0.9999627556878115,
        },
        lut: [
            0, 26.41516453128587, 51.59077894423897, 75.56627507322835,
            98.38108442604197, 120.07463814614226, 140.6863669712787,
            160.2557011884029, 178.82207058503525, 196.42490439740925,
            213.10363125606443, 228.89767912996192, 243.84647527077192,
            257.98944615974074, 271.3660174604697, 284.01561398214704,
            295.97765965915295, 307.2915775546004, 317.99678989709975,
            328.13271816172926, 337.7387832075901,
        ],
    },
    {
        x: 1574.227783203125,
        y: 1137.0423583984375,
        aX: 1614.944580078125,
        aY: 1137.0625,
        bX: 1511.865966796875,
        bY: 1137.011474609375,
        normal: {
            x: -0.0004946754323521256,
            y: 0.9999998776481008,
        },
        lut: [
            0, 6.0811215494807955, 12.113243471115517, 18.0994125381051,
            24.043096756208797, 29.948398796455418, 35.82027294236955,
            41.66474240432397, 47.4891125973479, 53.30217451135869,
            59.1143907670748, 64.9380555784746, 70.78741895166232,
            76.67876541062073, 82.63043869944643, 88.66280647928484,
            94.79816296928223, 101.06057238801407, 107.47566122393721,
            114.0703719044766, 120.87269349970306,
        ],
    },
    {
        x: 1659.455322265625,
        y: 1066.7001953125,
        aX: 1662.0042724609375,
        aY: 1050.6588134765625,
        bX: 1652.2186279296875,
        bY: 1112.2430419921875,
        normal: {
            x: 0.9876097865078718,
            y: 0.1569296326188132,
        },
        lut: [
            0, 2.4775275825067546, 5.034793019938899, 7.668764238721172,
            10.377727947318219, 13.161498367844064, 16.021603116688517,
            18.96144098389756, 21.98640408029514, 25.103955870603183,
            28.32365728808111, 31.65713558089858, 35.11799459731343,
            38.721670284169875, 42.48524030859699, 46.427200857956564,
            50.56722597955387, 54.92592491951058, 59.5246110047556,
            64.38509231848235, 69.52949056574585,
        ],
    },
    {
        x: 1699.968505859375,
        y: 1014.055419921875,
        aX: 1746.60595703125,
        aY: 991.271484375,
        bX: 1668.255615234375,
        bY: 1029.5482177734375,
        normal: {
            x: -0.43895205446065233,
            y: -0.8985104862408521,
        },
        lut: [
            0, 7.568217706564882, 14.78022502755594, 21.736126495474082,
            28.52536683433057, 35.223449386473746, 41.88993490485021,
            48.56789225040489, 55.28458950194386, 62.05300832934185,
            68.87375275786557, 75.73702572891986, 82.62447609021864,
            89.51082520208315, 96.36525260616729, 103.15255945507319,
            109.83414723872876, 116.36885735831001, 122.71372143416383,
            128.82467832114622, 134.65732695957925,
        ],
    },
    {
        x: 1723.0477294921875,
        y: 894.69189453125,
        aX: 1707.8895263671875,
        aY: 863.3283081054688,
        bX: 1739.52392578125,
        bY: 928.7824096679688,
        normal: {
            x: 0.9003586665439843,
            y: -0.43514856265319146,
        },
        lut: [
            0, 5.869931290319305, 12.98574598892118, 21.281801201010904,
            30.692354066614392, 41.1516123342129, 52.59375746646691,
            64.95295654377583, 78.16336902297992, 92.15915092319787,
            106.87445764718822, 122.24344605543764, 138.2002761327773,
            154.67911244927072, 171.6141255458149, 188.93949333787387,
            206.58940261284653, 224.49805069029813, 242.59964731625055,
            260.82841687156224, 279.11860099017133,
        ],
    },
    {
        x: 1591.727294921875,
        y: 648.418701171875,
        aX: 1532.359130859375,
        aY: 541.845947265625,
        bX: 1651.095458984375,
        bY: 754.9915161132812,
        normal: {
            x: 0.8735961508592439,
            y: -0.48665158502147443,
        },
        lut: [
            0, 18.176647814603104, 36.0803661233885, 53.66873547768345,
            70.89933443666757, 87.72974132303429, 104.11753616416352,
            120.0203029230235, 135.39563215874654, 150.20112431196225,
            164.39439389577464, 177.93307500933844, 190.77482881198716,
            202.87735396549712, 214.1984016922374, 224.6957982516483,
            234.3274798226604, 243.05154915720593, 250.82637276337584,
            257.6107593128698, 263.36431698029514,
        ],
    },
    {
        x: 1465.6763916015625,
        y: 417.2190246582031,
        aX: 1448.1314697265625,
        aY: 377.9388427734375,
        bX: 1479.8829345703125,
        bY: 449.0250549316406,
        normal: {
            x: -0.9130588976821014,
            y: 0.4078277202000199,
        },
        lut: [
            0, 6.2889053137212905, 12.273189521122951, 17.983944217157255,
            23.45104753046078, 28.702792808855854, 33.76551842015238,
            38.66326445806412, 43.417482143937534, 48.04681783601438,
            52.56698648491788, 56.99074065709798, 61.3279327743535,
            65.58566159984326, 69.7684901611501, 73.87872138424736,
            77.9167192772388, 81.88126682816669, 85.76995613666604,
            89.57961109862688, 93.3067477864343,
        ],
    },
    {
        x: 1488.472412109375,
        y: 336.1001281738281,
        aX: 1516.6724853515625,
        aY: 321.8701171875,
        bX: 1466.535888671875,
        bY: 347.16949462890625,
        normal: {
            x: -0.4505024131852822,
            y: -0.8927752100692745,
        },
        lut: [
            0, 4.688219900535437, 9.285326696983544, 13.803197317646207,
            18.258418010592102, 22.672885774908025, 27.074216798464327,
            31.49585087129121, 35.97675991225013, 40.56072778168793,
            45.29525830689625, 50.23025738822816, 55.41668387571103,
            60.905351369493594, 66.74600043194789, 72.98668112492625,
            79.67342089902958, 86.85011714777566, 94.55858494551268,
            102.838698498723, 111.72857961880895,
        ],
    },
    {
        x: 1485.5904541015625,
        y: 242.1468048095703,
        aX: 1425.0355224609375,
        aY: 176.45632934570312,
        bX: 1527.197509765625,
        bY: 287.282470703125,
        normal: {
            x: 0.7352631167238056,
            y: -0.6777817858172316,
        },
        lut: [
            0, 12.852566066221764, 24.669916775643436, 35.53944471209908,
            45.55200230574188, 54.802155529892666, 63.388162683631876,
            71.41149946831989, 78.97575183031067, 86.18477825760799,
            93.14022534918894, 99.93872458978454, 106.66928932537753,
            113.41144292363481, 120.23441289035871, 127.19742557389294,
            134.3508842853441, 141.73809749380723, 149.39723420698067,
            157.36326160201253, 165.66971019349532,
        ],
    },
    {
        x: 1368.8245849609375,
        y: 265.9388732910156,
        aX: 1370.5279541015625,
        aY: 348.282958984375,
        bX: 1367.65380859375,
        bY: 209.34092712402344,
        normal: {
            x: -0.9997861134974837,
            y: 0.02068156806426671,
        },
        lut: [
            0, 12.291122485039903, 24.44870639053982, 36.46029516727533,
            48.31230330296071, 59.990137479235855, 71.47831383666284,
            82.76057246721432, 93.81999062665217, 104.63909659058669,
            115.19998661272083, 125.48444814207319, 135.4740934018438,
            145.1505087463948, 154.49542707074443, 163.49093321091053,
            172.11971613622714, 180.36538738761206, 188.21289354315783,
            195.64906279013985, 202.66334375480614,
        ],
    },
    {
        x: 1388.8695068359375,
        y: 467.4754638671875,
        aX: 1389.02783203125,
        aY: 498.38922119140625,
        bX: 1388.6373291015625,
        bY: 422.1354064941406,
        normal: {
            x: 0.9999868853127283,
            y: -0.005121445357372956,
        },
        lut: [
            0, 4.69743889611322, 9.520514369458349, 14.471813845854907,
            19.55387643281211, 24.769797127627736, 30.123754002685992,
            35.6214635283074, 41.270566174126, 47.08094155773338,
            53.064950359364175, 59.237599451531096, 65.61662744901433,
            72.22251016095018, 79.07838892674236, 86.20992897131416,
            93.64511897657808, 101.4140262796945, 109.54852389781854,
            118.0820056849046, 127.04910444869573,
        ],
    },
    {
        x: 1300.915771484375,
        y: 542.4971313476562,
        aX: 1236.9320068359375,
        aY: 547.6246948242188,
        bX: 1362.0338134765625,
        bY: 537.5992431640625,
        normal: {
            x: 0.07988240953873568,
            y: 0.996804294054899,
        },
        lut: [
            0, 9.306461930454596, 18.130588033350747, 26.669695432374976,
            35.08048775806077, 43.473873170976276, 51.91650877694255,
            60.437013108017574, 69.03424608546204, 77.68583135245112,
            86.3561179018192, 95.00350723625203, 103.58745848601055,
            112.07563481950865, 120.4516424105009, 128.72361249388294,
            136.93336494775676, 145.16494131713242, 153.55007138981503,
            162.2675221543368, 171.5346405490035,
        ],
    },
    {
        x: 1162.515625,
        y: 634.0171508789062,
        aX: 1131.401123046875,
        aY: 634.4845581054688,
        bX: 1226.697265625,
        bY: 633.052978515625,
        normal: {
            x: 0.015020471719324992,
            y: 0.9998871863512048,
        },
        lut: [
            0, 4.567437697443213, 8.95606702282404, 13.195084890634913,
            17.310981340702064, 21.327411261302192, 25.265226116884033,
            29.142670755773818, 32.97573400674482, 36.77862968949117,
            40.56437775416848, 44.34545251745351, 48.134464390243316,
            51.94484118446382, 55.79147400182583, 59.69129109003972,
            63.6637224532951, 67.7310208419256, 71.91841333895232,
            76.25407298668662, 80.76892006356167,
        ],
    },
    {
        x: 1083.3272705078125,
        y: 620.354736328125,
        aX: 992.575439453125,
        aY: 624.4573364257812,
        bX: 1114.068603515625,
        bY: 618.9650268554688,
        normal: {
            x: -0.04516067956986124,
            y: -0.9989797360411212,
        },
        lut: [
            0, 12.96278121718809, 25.086636760244097, 36.968085659980616,
            49.04993244583815, 61.59520969947947, 74.70979316960866,
            88.38369660939802, 102.52967530771495, 117.01281463288954,
            131.67222548163969, 146.33781928352687, 160.84506778984007,
            175.05035675542698, 188.84943965645616, 202.201361220453,
            215.15909441578594, 227.90398078779654, 240.7720432729836,
            254.25121281442662, 268.9362590999036,
        ],
    },
    {
        x: 906.9741821289062,
        y: 801.2066040039062,
        aX: 783.6323852539062,
        aY: 800.4214477539062,
        bX: 1010.4243774414062,
        bY: 801.8651123046875,
        normal: {
            x: -0.006365565950041864,
            y: 0.9999797395798256,
        },
        lut: [
            0, 18.80313693264243, 38.15246305457123, 57.9616568632613,
            78.1436184662876, 98.61064378209531, 119.27455749841151,
            140.04681697300362, 160.83859561371438, 181.56085193231976,
            202.12438898034628, 222.4399079844881, 242.4180595651699,
            261.96949589237954, 281.0049275360383, 299.43518972698365,
            317.1713245258727, 334.12468851807296, 350.20710110578113,
            365.3310582088427, 379.41005422378834,
        ],
    },
    {
        x: 528.1094970703125,
        y: 781.8873901367188,
        aX: 463.28375244140625,
        aY: 781.162841796875,
        bX: 618.2918090820312,
        bY: 782.8953247070312,
        normal: {
            x: 0.011176162991050126,
            y: -0.999937544740069,
        },
        lut: [
            0, 9.54155537377799, 18.75276285858564, 27.680254309057712,
            36.369090641537696, 44.86212737937686, 53.19940483173859,
            61.417606566219156, 69.54962325363971, 77.62424673551342,
            85.66600411518162, 93.69512708387555, 101.72764021265453,
            109.77554493530086, 117.84707344945231, 125.94698787414492,
            134.07690343111278, 142.23561892629576, 150.41944245487082,
            158.62250445470983, 166.83705371690525,
        ],
    },
    {
        x: 405.9241027832031,
        y: 877.4381713867188,
        aX: 391.9018249511719,
        aY: 919.7847900390625,
        bX: 423.1459655761719,
        bY: 825.428955078125,
        normal: {
            x: -0.9493086425882451,
            y: -0.31434551230654345,
        },
        lut: [
            0, 6.561515446037376, 12.885402756080666, 18.99999324874357,
            24.93032829893683, 30.698130478837978, 36.321907873483994,
            41.817191795054626, 47.19690115226245, 52.47182255204657,
            57.65119375324119, 62.74337849373147, 67.75662155947921,
            72.69987253072634, 77.58366310248968, 82.42101458268617,
            87.22833817524693, 92.02627167722903, 96.84037596166083,
            101.70160073351224, 106.64643221880037,
        ],
    },
    {
        x: 390.4166259765625,
        y: 982.4154052734375,
        aX: 374.0819396972656,
        aY: 1021.4095458984375,
        bX: 403.2985534667969,
        bY: 951.6636962890625,
        normal: {
            x: 0.9223435847529882,
            y: 0.38637069203681507,
        },
        lut: [
            0, 6.232548351185974, 12.269360734712263, 18.133948098536113,
            23.846922447807685, 29.426594417885298, 34.8897370092577,
            40.25249563707557, 45.53141873482676, 50.7445745488464,
            55.91270597269988, 61.06035532466921, 66.21686725278444,
            71.41715794885238, 76.70213514355851, 82.11867945840655,
            87.71915915669165, 93.56053564171297, 99.70319747659254,
            106.2097042640228, 113.14361291321094,
        ],
    },
    {
        x: 302.4969177246094,
        y: 988.824462890625,
        aX: 283.8382568359375,
        aY: 954.645751953125,
        bX: 325.4065246582031,
        bY: 1030.7900390625,
        normal: {
            x: -0.8777257280558468,
            y: 0.47916338164433364,
        },
        lut: [
            0, 5.631945191690931, 10.86422888352412, 15.725476796727436,
            20.246151449737056, 24.459367265520044, 28.40184118267314,
            32.11489346899126, 35.64531700105263, 39.045823788319275,
            42.374725450489194, 45.6946177107349, 49.070166575579904,
            52.565495676945375, 56.24186446548692, 60.156151967197026,
            64.36027467353905, 68.90134826772183, 73.82228560522042,
            79.1625626634877, 84.95898003746746,
        ],
    },
    {
        x: 235.740478515625,
        y: 949.1050415039062,
        aX: 156.28990173339844,
        aY: 953.1844482421875,
        bX: 275.9296875,
        bY: 947.04150390625,
        normal: {
            x: -0.05127766442250679,
            y: -0.9986844352103285,
        },
        lut: [
            0, 11.617250748292406, 22.909492023423248, 34.18987670299716,
            45.66771730156876, 57.46086232211296, 69.6198439561705,
            82.15415868067441, 95.05619470353098, 108.32194129948296,
            121.96889634840156, 136.05142185768483, 150.67299865170642,
            165.9940130890218, 182.23349297341082, 199.66408500687348,
            218.60136155121256, 239.39022848449858, 262.3916599152336,
            287.97205863209126, 316.4960611859217,
        ],
    },
    {
        x: 335.069580078125,
        y: 1133.4825439453125,
        aX: 431.77691650390625,
        aY: 1134.98583984375,
        bX: 134.25514221191406,
        bY: 1130.3609619140625,
        normal: {
            x: -0.015542919060310568,
            y: 0.999879201537408,
        },
        lut: [
            0, 15.332189878445465, 32.19965204163901, 50.432070336481175,
            69.85900096736066, 90.30991951319442, 111.61424831246968,
            133.6013733419269, 156.10065513724544, 178.94143618097195,
            201.9530461295575, 224.96480570132695, 247.80602975118566,
            270.3060299004396, 292.2941170175827, 313.5996038374329,
            334.05180806380605, 353.4800564537849, 371.7136907050802,
            388.5820766418741, 403.9146196643039,
        ],
    },
]

export const trackCurveLut = [
    0.025711631176880176, 0.01601980218150426, 0.007470528440436243,
    0.0001412379724926182, 0.00015113487041616065, 0.00015889415646084455,
    0.00016531560320724107, 0.00017243331644300957, 0.00018044115047992742,
    0.00018880896669752013, 0.00019533258181491463, 0.00019994383124969417,
    0.00020310538850202653, 0.00020737656540862045, 0.00021287011691909822,
    0.0002194371249069928, 0.00022494795802833285, 0.00022874790659582737,
    0.00023077329847169168, 0.00023281616926608582, 0.0002361588393831861,
    0.0002408809080088049, 0.0002461364665764326, 0.0002504192188592933,
    0.000253130244046605, 0.0002542213578612838, 0.00025547051116777596,
    0.0002580408274550482, 0.0002619881998374857, 0.00026645750615280505,
    0.0002705375790413591, 0.0002731910818467587, 0.00027438201225985886,
    0.000274797369907342, 0.00027648211176645594, 0.00027947459018644354,
    0.0002834558909056285, 0.0002874278262314889, 0.00029066409084586744,
    0.0002925891265954001, 0.0002931730570716535, 0.00029384665017347794,
    0.0002957325832567402, 0.00029886231948842915, 0.0003025393588262063,
    0.0003062209147306854, 0.00030924067976129257, 0.00031107075063251804,
    0.00031168685976734514, 0.0003124010520364782, 0.00031424489252298427,
    0.00031724322266142964, 0.00032075487237474116, 0.0003242789564839497,
    0.0003275068630443471, 0.0003296695607396725, 0.00033074728832010075,
    0.00033134014096792164, 0.00033296620188328626, 0.0003356448022064739,
    0.00033908795629334845, 0.0003425489929767848, 0.0003460304284905183,
    0.0003487910060778775, 0.00035059609466748083, 0.00035142785573030657,
    0.00035275213619282174, 0.00035502084350945217, 0.0003582524842613435,
    0.00036172440060052525, 0.0003652209025902299, 0.000368709234036053,
    0.0003713729016319171, 0.0003731983430436006, 0.00037423775627947066,
    0.0003761099894370775, 0.0003788277108976025, 0.00038237363990208784,
    0.00038594719514188793, 0.00038955084289375063, 0.0003930912919314114,
    0.00039593004980394733, 0.00039805596024201955, 0.00039964369859050015,
    0.0004019584327690697, 0.00040501095191740013, 0.0004087215465062987,
    0.0004124651369162842, 0.0004162436739510761, 0.0004200149012533306,
    0.0004232181920704192, 0.00042584496757873495, 0.0004279705443861671,
    0.0004307070685987554, 0.0004340629397647111, 0.0004380068077369654,
    0.0004419889884400531, 0.00044601178849327936, 0.0004500760390891871,
    0.00045376663080563123, 0.0004570305567937577, 0.0004598602274585261,
    0.00046307955425627627, 0.00046678805842561975, 0.0004709945610908736,
    0.0004752924504237532, 0.00047963722017078806, 0.00048402976552167233,
    0.0004882753952586242, 0.0004922626286299425, 0.0004959871691226297,
    0.0004998352665889823, 0.0005040270920676303, 0.0005085682306311237,
    0.0005132695987271264, 0.0005180252789111907, 0.0005228368231894854,
    0.0005276643384973255, 0.0005324265761671061, 0.0005371224419995859,
    0.0005418327013756584, 0.0005467206752949806, 0.0005517892576335403,
    0.0005570008609271088, 0.000562276647763935, 0.000567618190505494,
    0.0005730276538081239, 0.0005785977438349212, 0.0005843310921985464,
    0.0005902290680051004, 0.0005961121548278239, 0.0006019806745783556,
    0.0006078352477665931, 0.0006137669385531364, 0.0006197770407059894,
    0.000625867089981553, 0.0006322826674166174, 0.0006391362550402581,
    0.0006464362245287439, 0.000653703417764288, 0.0006607251584901994,
    0.0006674972095092657, 0.0006742587207329804, 0.000681115459606226,
    0.0006880690452854768, 0.0006954066485292043, 0.0007035179283923858,
    0.0007124175711165012, 0.0007215515664475156, 0.0007301615510625453,
    0.0007382395242362835, 0.0007460596149700258, 0.000753997972306217,
    0.0007620555909933869, 0.0007704871144805569, 0.000780111452242523,
    0.0007909514649329091, 0.000802529565122751, 0.0008132407634299718,
    0.0008230728206001539, 0.0008322585930312173, 0.0008415946000097969,
    0.0008510806688241376, 0.0008586236766976709, 0.0007973373069883059,
    0.000736059534065101, 0.0006781464934500916, 0.0007565205653752142,
    0.0008326357714240454, 0.0009051784338511208, 0.0009080605183797173,
    0.0009109369632740736, 0.0009155816827388011, 0.0009252669898154846,
    0.0009400143326749431, 0.0009563062088442162, 0.0009676279721559884,
    0.0009739664716785045, 0.0009770633263810563, 0.0009801587427105726,
    0.0009832422204012455, 0.0009916165954292667, 0.0010055147526169372,
    0.001024969454238945, 0.001039406743173341, 0.0010483922195285936,
    0.0010518913938324485, 0.0010551721663292614, 0.001058432711032472,
    0.0010675212310085605, 0.0010826410795702886, 0.0011038265653968793,
    0.001119412266712736, 0.001129023150205689, 0.0011326216811942222,
    0.0011360192874033434, 0.0011417778487618744, 0.0011540977155721072,
    0.001173000846769006, 0.0011937313718510675, 0.0012079182397729913,
    0.0012155476251762083, 0.001218973855798693, 0.0012233109983940315,
    0.0012347737736368096, 0.0012533758839382639, 0.0012772472523907286,
    0.0012939581136940336, 0.0013035071880123988, 0.0013068034896119953,
    0.001312078534195913, 0.0013250668946767654, 0.001345779404353129,
    0.0013701602999049338, 0.0013867177030126897, 0.0013954472997487432,
    0.0013983487589582534, 0.0014073749703461707, 0.0014247042969328814,
    0.001450350082081901, 0.0014718607080073571, 0.0014848440287304322,
    0.0014892738846876525, 0.001496344898453621, 0.0015123038617981081,
    0.0015371356857436808, 0.0015608445305364132, 0.0015752673762789635,
    0.0015803956559466252, 0.0015878348561626998, 0.001604696894816555,
    0.001630938929972349, 0.001653255892301563, 0.0016654922764264056,
    0.0016689164220872145, 0.0016791932789450498, 0.001699306772794914,
    0.0017266379005005377, 0.0017430237503619668, 0.0017485067622950853,
    0.0017536845385633236, 0.0017690453604053361, 0.0017944420546103573,
    0.0018110871499020303, 0.0018159554129916083, 0.0018189595339962248,
    0.0018307086666964386, 0.0018525189285986618, 0.0018643990353204066,
    0.001865483940171164, 0.0018654549662997938, 0.0018740194664294489,
    0.0018884977057320753, 0.001889306708266935, 0.0018847072614857055,
    0.0018798427922148203, 0.0018840795482479506, 0.0018806233978934511,
    0.0018681525079301791, 0.0018523111844517815, 0.0018409109126480468,
    0.0018257009537830037, 0.0018003081136856232, 0.0017697980588720552,
    0.001739247660762714, 0.0017029872530685793, 0.0016571096881282858,
    0.0016036953068040982, 0.0015474453464092038, 0.0014801768514657785,
    0.0014027959071432948, 0.00131458523676871, 0.001218975707910826,
    0.001108909782135666, 0.0009845479182446647, 0.0008461558659300143,
    0.011501911683783941, 0.08056652081990828, 0.21412699135895077,
    0.39119000362902306, 0.517230471296514, 0.5807914931996171,
    0.5922281917664893, 0.5985420331299526, 0.6052660331070916,
    0.6123099367814482, 0.6197394963640073, 0.6274382829844087,
    0.635474097109078, 0.6436908972198015, 0.6521586614921465,
    0.6606842269639039, 0.6693225282823643, 0.6778675920575578,
    0.6863206023259106, 0.6945146305273671, 0.7023342762933011,
    0.7097345629784596, 0.7163973593578875, 0.722512001707844,
    0.727454690509567, 0.7317654319116563, 0.7344402278708936,
    0.7364773529382912, 0.7364528696882268, 0.7358785774907647,
    0.732865878246537, 0.7294720258372824, 0.7233313414473882,
    0.717033473070765, 0.7078786307292418, 0.6988358424501518,
    0.6871461134024345, 0.6756624274123424, 0.6620522122029561,
    0.6484643085833725, 0.629203146570955, 0.5671972690284595, 0.50617285451895,
    0.4687460375519108, 0.5432718325746143, 0.6513154452161052,
    0.7695522354855452, 0.8428650858644557, 0.900690001908861,
    0.9437352074313704, 0.9724352415686771, 0.9873533999897184,
    0.9894199884027385, 0.9800180055889934, 0.960935023653564,
    0.9333551540210201, 0.8985482716368769, 0.8582952215185827,
    0.8158245138077537, 0.7720842127779516, 0.728394850944068,
    0.6844292202824733, 0.6423652362680028, 0.602063901990925,
    0.5446813687424205, 0.5352960322632417, 0.5813751965295655,
    0.7275542591146748, 0.8478895820660588, 0.9336398468430952,
    0.9554373850678145, 0.9731342286616792, 0.9832312403004946,
    0.9884320292126811, 0.9853819038714938, 0.9769075098158225,
    0.961889304643004, 0.9419711123768738, 0.9176094412362934,
    0.8897518553397115, 0.8592311637597083, 0.8271256721801526,
    0.7939027162495368, 0.7608052475847246, 0.7279379240188973,
    0.6961691223649451, 0.6655837005064392, 0.6364650917620951,
    0.6091344924285923, 0.5834165149564345, 0.5597618008370568,
    0.5378613209014564, 0.518006354386049, 0.49994609768944676,
    0.4838115249498514, 0.46938134481503907, 0.4569407370542631,
    0.4461346311602041, 0.43749414008870263, 0.4303400499102222,
    0.42537810069143683, 0.4220898226896614, 0.4210733759739525,
    0.42234776260528006, 0.42595509112990815, 0.4328901690448838,
    0.4424848523247947, 0.4569807931544472, 0.47248554638583756,
    0.45817287459357675, 0.3909831284902607, 0.27035594296186255,
    0.15490648883699112, 0.08047071680610478, 0.05190876669892011,
    0.04169201422249429, 0.03500795972093108, 0.029995010705466393,
    0.026280661104781004, 0.02337020957585971, 0.0210264887516874,
    0.019087066038557785, 0.017526148544530343, 0.016221267040097905,
    0.015132433139936757, 0.01415765521266546, 0.0133478803931815,
    0.012655910294886517, 0.011991694327683246, 0.011418978249756314,
    0.010948726885368833, 0.010541856868943253, 0.010120989657205993,
    0.009738659986942587, 0.009444908735097339, 0.009189238087339539,
    0.008925462097950058, 0.008640265061013052, 0.008425849083246938,
    0.008258085905093991, 0.008103587577670922, 0.007914594786696772,
    0.007735261708221562, 0.007608304556769301, 0.007511556917298413,
    0.007407733270279476, 0.007274679333123733, 0.0071544254580673435,
    0.007074670202412149, 0.007021208029352517, 0.006957435137259747,
    0.006869009266216248, 0.006783099268821872, 0.00672945176273539,
    0.006704115725251855, 0.006673280384279078, 0.0066217359754412565,
    0.0065567679156738334, 0.0065180088688011895, 0.0065054259307329105,
    0.006502171675656609, 0.006481654502350667, 0.006443673907200368,
    0.006414141414398867, 0.006406185002685063, 0.006419517907937512,
    0.006425195419967293, 0.006416496099764658, 0.006393898181469292,
    0.006389130318038204, 0.006402277622241812, 0.006429853251024298,
    0.006445883500606831, 0.006450216615334521, 0.0064492079605292406,
    0.0064629542653110855, 0.006491564856395245, 0.006528062410232203,
    0.006555569395560393, 0.006573958739682743, 0.006590785278372942,
    0.006619672790921619, 0.006660741169432328, 0.006707112219686054,
    0.0067470518063854935, 0.006780462548408414, 0.006813571426022556,
    0.006856256649870604, 0.006908635001677114, 0.006965700760345077,
    0.00701900348084103, 0.00706848523948557, 0.007118017717568114,
    0.007174718757372908, 0.007238690544683561, 0.007307296866817647,
    0.007375033780409799, 0.007441894417020413, 0.007509429678215076,
    0.007581688328773022, 0.007658749411596704, 0.007728903130466826,
    0.007333106363007518, 0.006222325615307758, 0.00439867677813724,
    0.00275615258586035, 0.0017723942243234305, 0.0014557386017229037,
    0.0013701234035309446, 0.0012858424582825828, 0.001202016077334697,
    0.0011149271630446313, 0.0010247469158245208, 0.0009322264660225803,
    0.0008402943439323341, 0.0007486183347734067, 0.0006563137428026071,
    0.0005609708812522055, 0.0004628051892219865, 0.0003624507049051083,
    0.000261132197497828, 0.00017005963446933055, 0.00011296262446236536,
    0.00011418734902068975, 0.00017577226504181807, 0.0002754431148780157,
    0.0003907958099676134, 0.0005100023351049297, 0.0006322082807722509,
    0.0007560301313058607, 0.000881057367396086, 0.001011264632714387,
    0.0011480697467879007, 0.0012923457206071665, 0.0014376445693332904,
    0.0015831899890680768, 0.001731724331899726, 0.0018906419258030733,
    0.002061136512752832, 0.0022377213681830156, 0.002413002396767016,
    0.0025884005033301005, 0.002776605025448388, 0.002982342059137981,
    0.0032031487741055035, 0.0034204951381893614, 0.003634713384404672,
    0.003864452812556037, 0.004120396520628814, 0.0044028298824922716,
    0.00467887295848375, 0.004951026031639261, 0.00524376704327793,
    0.005575675432622866, 0.005943553621677215, 0.006301160054746543,
    0.006663123480571932, 0.00705765007903184, 0.00751141923639607,
    0.007998267104648982, 0.008469916507927134, 0.008977591820842906,
    0.009546424536983995, 0.010207079350839319, 0.010862221555403262,
    0.011548577978862133, 0.012305103556029472, 0.01319020379695613,
    0.01411929196268683, 0.015096086969336342, 0.016185624778996554,
    0.01745049177933042, 0.018799937995991726, 0.020281613659695243,
    0.021955699092117417, 0.023918032165843886, 0.026034293170665395,
    0.028469838197834635, 0.03126583504869205, 0.034497136307203295,
    0.03829215675370635, 0.042744744484613906, 0.04812573442595327,
    0.0546219729554834, 0.06247168246669915, 0.07263371201100689,
    0.08538201950807521, 0.1023936275873933, 0.1257000181176506,
    0.16117227858616712, 0.23609931322680774, 0.3499355767535777,
    0.49094563164481736, 0.5998501716703588, 0.6697154414570496,
    0.7044175374704279, 0.7348053850635322, 0.7643261324624687,
    0.7938748995578959, 0.8227792105538789, 0.8502138961889782,
    0.8771926552307474, 0.901433336540104, 0.9242269846555397,
    0.9438559135676442, 0.9612060541846214, 0.975889256076254,
    0.9876459950681539, 0.9969774427689435, 1.0037523740025616,
    1.0087509020200016, 1.012397681363305, 1.0153539977486232,
    1.017986970825981, 1.0212956281188108, 1.0261795691611912,
    1.0333455171590062, 1.0435995215778093, 1.05820702323522,
    1.0787760456527102, 1.0949606476107263, 1.0347974689542279,
    0.9582276891609385, 0.8858577230583041, 0.9611425252301387,
    1.0625011726552105, 1.178548354109522, 1.2353298920035993,
    1.2910743292739963, 1.3435212838376875, 1.3899369193757856,
    1.427180817299938, 1.4526441860507895, 1.4640738610828763,
    1.4599867828730737, 1.4404516460696069, 1.405314845542197,
    1.3578736393229534, 1.2997712064324878, 1.2343351952743884,
    1.1658649281603717, 1.0930455219832662, 1.02362398143436,
    0.9514798049187299, 0.8878639797702254, 0.8228927717252378,
    0.7673743336924534, 0.7111778139463518, 0.663540758280759,
    0.617373122526037, 0.5770945747685885, 0.5396617080742674,
    0.5049220182932158, 0.47375470273354886, 0.4447205318441481,
    0.4188025634632169, 0.3947094343639775, 0.37242749131848135,
    0.35449372102909454, 0.34093648819697814, 0.33333605590100085,
    0.3313264395172586, 0.3372785350304545, 0.3501665610514256,
    0.36779551242506464, 0.38421959318879345, 0.4012056700848777,
    0.41945964902095967, 0.4416486429220717, 0.4629362549916151,
    0.4857400712878556, 0.5100722385922686, 0.5374260168179719,
    0.5656987879960984, 0.5956643082303792, 0.6282988866104998,
    0.662713183544986, 0.6987621968365092, 0.736394722093181,
    0.7767076653453149, 0.8181795488796214, 0.8621998866865498,
    0.9062821280468475, 0.9504307628993143, 0.9953206955310261,
    1.0383949242271466, 1.0811365197690954, 1.119350615188995,
    1.1540653235346852, 1.184821823889432, 1.2090015763369892,
    1.2288143310961963, 1.2397074519646736, 1.2446577553893807,
    1.2416271049358236, 1.2328825796073957, 1.2179135795322114,
    1.1985189605684445, 1.173958599954018, 1.1470073362832167,
    1.1160438325567827, 1.0850618448007074, 1.0524873857231638,
    1.0202776603606076, 0.9883776465304233, 0.9558954412873614,
    0.9252720790541692, 0.8945781402603946, 0.8660505041571613,
    0.8385249157378151, 0.8116941238619954, 0.7865580337656826,
    0.7623717109784631, 0.71817035691594, 0.6111165728092731,
    0.4409106982193251, 0.2545466774859301, 0.13710774487353186,
    0.08965408826505013, 0.08704471045171747, 0.08538218752906923,
    0.08367879301883681, 0.08196877493214727, 0.080328014517495,
    0.07863481645384446, 0.07689579796227879, 0.07514577314428349,
    0.07345625918985682, 0.07171024324569887, 0.06991810140357953,
    0.06810892231575379, 0.06635422296109895, 0.06452646240759347,
    0.06265694178400104, 0.06076228939956651, 0.05891862408502194,
    0.056972507498777795, 0.05499231725812715, 0.052976207106554624,
    0.051008557817002566, 0.04892055159695269, 0.0467842736124399,
    0.04459755766131254, 0.042409751173450534, 0.04012094333526087,
    0.037765273203197336, 0.03536219011431993, 0.03288018429501938,
    0.030308156093667497, 0.027643853512682143, 0.02492732600312716,
    0.022076752560838703, 0.01910454903628243, 0.016003239358910636,
    0.01278616244332127, 0.009411157200480807, 0.0062242852832896855,
    0.004136596217922959, 0.004098337973799754, 0.006397594255058307,
    0.010258911776503806, 0.014885434225048691, 0.01981450985281905,
    0.025070978514789555, 0.030680934024083378, 0.036703769745145935,
    0.04317565913073331, 0.05016094117131951, 0.05772070487307083,
    0.06590926745090993, 0.0748650919397263, 0.08463901447517964,
    0.09531544112926603, 0.10717454667370936, 0.12023101531764653,
    0.13471605065103154, 0.1509878268073641, 0.16907292685901,
    0.18997207616894732, 0.2131810737613766, 0.24258153877611685,
    0.33441198800575994, 0.5024507242189369, 0.7326914946705653,
    0.9019339886491688, 0.9756392283125117, 0.9643450218502303,
    0.9350334133483841, 0.9093345475072541, 0.8861755674803549,
    0.8659454537453412, 0.8465585545102029, 0.8301394605812137,
    0.8141663071160025, 0.8001877687024851, 0.7863248542944574,
    0.7732744231323673, 0.7604950233925315, 0.747742324390208,
    0.7352710485272712, 0.7224326285541801, 0.7097108368776163,
    0.6966380978985756, 0.6832920499907974, 0.6700479060215371,
    0.655852762698289, 0.6424489034655333, 0.6272875297217652,
    0.613311083628351, 0.5975833282842756, 0.5832283435600829,
    0.568474883543876, 0.553755142684356, 0.5391075777698328,
    0.5234683383515377, 0.5089964017667358, 0.49451731321233217,
    0.48043950471721136, 0.4664358146158139, 0.4525329474040785,
    0.43914614899525756, 0.4259318435469923, 0.4129087303107462,
    0.3732393287172589, 0.38286889732623336, 0.44346720738834877,
    0.6162343306920318, 0.7498115722762498, 0.8404817876073928,
    0.8510215974266191, 0.8540588274968571, 0.8497908516569, 0.8380966052572787,
    0.8193617884231936, 0.793964639335932, 0.7627659152153834,
    0.7268482410334046, 0.6867808865488866, 0.6441738717787387,
    0.5997237457457517, 0.5541314366475749, 0.5082868688886705,
    0.4625887173010493, 0.4174939913744908, 0.37325895505803947,
    0.32999709343019684, 0.2878431121380553, 0.2467343197467753,
    0.20661212348550365, 0.16737337575591238, 0.128851343057689,
    0.09090983248604652, 0.058074739771950205, 0.03852192991155354,
    0.04052031169040198, 0.06296129607739374, 0.09769791007680333,
    0.13668267235373563, 0.17658968826126054, 0.2175750721023367,
    0.25987736534909534, 0.3035651195175193, 0.3487202928699836,
    0.3954337041152968, 0.443542758126825, 0.49294859059467416,
    0.5432132816980674, 0.5939131858019548, 0.6445059081306321,
    0.6936839564954048, 0.7408409669908963, 0.7842764483329616,
    0.8230195048322358, 0.8560929383685932, 0.88147371559747,
    0.8988742338081349, 0.907755691417733, 0.9076902862589318,
    0.8989923237107463, 0.8820284567078603, 0.8572191623498671,
    0.812897328547245, 0.7521381714082983, 0.687474891891566,
    0.6460491566565675, 0.623740672513871, 0.6087468092218645,
    0.5841264717649755, 0.5491554556254457, 0.5031696077006741,
    0.4466932419841472, 0.3805663628998155, 0.3063051715733263,
    0.2249918938327307, 0.14810763372819746, 0.09816893953658688,
    0.0973829814356852, 0.14726654386434582, 0.22635943545327988,
    0.31231645080482767, 0.39216761906085235, 0.46403760547302486,
    0.5265437659378867, 0.5784705543211878, 0.6187085779488202,
    0.6469391500512279, 0.6637475065994128, 0.6803067035328216,
    0.7129300275172898, 0.7679390110715566, 0.8317041139947055,
    0.882857184181105, 0.9201442802635293, 0.9485331368541666,
    0.971288323222768, 0.9820919753434012, 0.9816976026909007,
    0.9734876104518858, 0.9591179478797037, 0.9359440347037494,
    0.9040155932792385, 0.8653813534300214, 0.823883690773839,
    0.7806324371152774, 0.7350666824162939, 0.6872598097798212,
    0.639960091822557, 0.5957659462521159, 0.5532263619578539,
    0.5112135666292664, 0.4700546674288158, 0.4332039698939354,
    0.39905519540547485, 0.366637763791466, 0.3343032199582348,
    0.3049906199781121, 0.27842481736244545, 0.2538636394457601,
    0.2297409939223972, 0.20657905109969976, 0.1853255696188648,
    0.16557871371407432, 0.14676429881093037, 0.12831363801635096,
    0.11065191914057264, 0.09390653134268212, 0.07789694268700505,
    0.06229650551870728, 0.04695714869879675, 0.03195791845574461,
    0.019845604706670322, 0.013730302998761964, 0.016681213977179574,
    0.026659842623012114, 0.04047512346154936, 0.0549638060591946,
    0.06968807941346061, 0.08472020646827583, 0.10012976522577603,
    0.11575274135433418, 0.1316914739993485, 0.14831937323954847,
    0.1658351315834645, 0.1841927931963824, 0.20279692202851782,
    0.2220487995305472, 0.24254016353416216, 0.26471593129400145,
    0.2879169791644205, 0.31122756559399806, 0.3358399518562561,
    0.3623796559390475, 0.391450460051699, 0.4206881545315185,
    0.45034108888869523, 0.4813097933221348, 0.514765679995879,
    0.5494477571028108, 0.5832447697620763, 0.6169297536142486,
    0.6508531563741574, 0.6854638315790632, 0.7181461661924792,
    0.7476347795436208, 0.7732986272479473, 0.7956448497531553,
    0.8156159966723427, 0.8305918143074882, 0.8383004789010292,
    0.8377262118361716, 0.8335168105263757, 0.8289855204770951,
    0.8174472668476279, 0.7968033866077407, 0.7667565835615126,
    0.7416418435858706, 0.7199251628397868, 0.6561469622271444,
    0.5223737948147462, 0.32485260204483835, 0.15633090165166086,
    0.061776644874811694, 0.03751934561513986, 0.036440299915016384,
    0.035284758936869276, 0.034056826217263185, 0.03295685353409214,
    0.0319994155095206, 0.031177775829890193, 0.03035311935396634,
    0.02947631241984125, 0.028549731541841452, 0.02764417176972468,
    0.02683714726795274, 0.026124750488962362, 0.025468471791180333,
    0.024785395811267017, 0.024069640140132794, 0.023322983759020158,
    0.02262954745984277, 0.022000984237808118, 0.021434375849727027,
    0.020881646828003333, 0.02030940992594597, 0.019718521426401448,
    0.019118047331423017, 0.01856025634339739, 0.018043607064318814,
    0.017562158479625365, 0.017083188432055915, 0.016594980937218697,
    0.016098056426683886, 0.015603480664905205, 0.015134185050958761,
    0.014689129646938687, 0.01426185421195206, 0.013836854337404203,
    0.013409738904375431, 0.012980681928690722, 0.012556169673473215,
    0.012144474111660466, 0.01174497541151142, 0.011353840115838556,
    0.010965831917120701, 0.010580214516326262, 0.010196849909370365,
    0.009816651182424118, 0.009440445985734569, 0.009067966344976537,
    0.008698413152658867, 0.008332610185405797, 0.007971382102732738,
    0.007614286337873492, 0.007257710884953067, 0.006899146436768311,
    0.006538635607385844, 0.0061778343307634966, 0.005820655540012887,
    0.005467942064765902, 0.005118918737861294, 0.004767156346382827,
    0.004409809991625474, 0.004047228260089406, 0.0036826354577018223,
    0.0033201422115250877, 0.0029594603438960654, 0.0025994032940200116,
    0.0022333779160518884, 0.0018603765784273904, 0.0014811013143599264,
    0.001099013396660183, 0.0007283037812887134, 0.00045711824697814866,
    0.0003744961211657515, 0.0005431914928548434, 0.0008771606536672411,
    0.0012893565422033234, 0.0017080978230955932, 0.0021388507971295137,
    0.0025838343537539455, 0.0030406902510558245, 0.0035011545295520995,
    0.0039640159047249724, 0.004430014775232475, 0.004918376128779364,
    0.005434267714780376, 0.005981138709970267, 0.0065315660719638185,
    0.007079308523872692, 0.00762216629338953, 0.008190917520775333,
    0.008807952084832666, 0.009477873157051475, 0.010170631828565221,
    0.010851981299765336, 0.011519428036799327, 0.012209234834914857,
    0.012979715619173491, 0.013837233375379428, 0.014745707811066086,
    0.015629527642219868, 0.01648563114822338, 0.017376383212956408,
    0.01839923153865947, 0.019564025278083406, 0.020792765608300297,
    0.021977532269019485, 0.023113824455590303, 0.024370316880906337,
    0.025843818891493172, 0.027551879229550214, 0.029254179622311454,
    0.030885526176767708, 0.03255011654045787, 0.03452608694201717,
    0.036870153092243764, 0.0393906528256602, 0.04180873406573508,
    0.044280368006938076, 0.04717497990920483, 0.05067633424137382,
    0.05451612863955697, 0.05821975367035556, 0.07182551634986328,
    0.12195897101573468, 0.21033753655495535, 0.31772444584443815,
    0.391871438365288, 0.42969284829230786, 0.4400595303638238,
    0.44863005729234673, 0.45682955771941636, 0.46475217375794337,
    0.4721808855432931, 0.47908012955627616, 0.4854875716671286,
    0.4912582578650185, 0.49636357260489544, 0.5007883242006527,
    0.5044743304440261, 0.5074061957618287, 0.5095326363635423,
    0.5108794491412337, 0.5115892114461341, 0.51131772287704,
    0.5102954301341524, 0.5084677118190394, 0.505932472191324,
    0.5027396287611748, 0.4988332153594881, 0.49436901342829837,
    0.48939889542351667, 0.48387157403565356, 0.477943193896841,
    0.4716202856639807, 0.46497919941977955, 0.4580974802572602,
    0.45097353303576776, 0.4437190487963452, 0.4363531889283206,
    0.42892775820765744, 0.42149869066728557, 0.41405911741237483,
    0.40671079522497383, 0.3994570954807255, 0.3923213098454554,
    0.38533542310384344, 0.37851206296365114, 0.3718845755079924,
    0.36545958995397104, 0.3592158497011293, 0.3532443015315692,
    0.3475085696859446, 0.34203227112467594, 0.3368077749540452,
    0.33183824003729795, 0.32714806735213836, 0.32272219856690376,
    0.3186717848356229, 0.3214792886016883, 0.33497159384392255,
    0.359961862224947, 0.38297695061230597, 0.3965954786255388,
    0.3979946749867655, 0.39428141624972146, 0.3873630196670472,
    0.3789334309477517, 0.36727723644706356, 0.35370612022323833,
    0.33681080933701496, 0.31780331756362107, 0.2954613045799706,
    0.27084818159593144, 0.2428985408889217, 0.21242048556517737,
    0.1787855861143637, 0.1424780841120938, 0.10322544932650063,
    0.06772812412403585, 0.04719710127429758, 0.05344257108274181,
    0.08576821049955301, 0.13470597603247325, 0.19004558789804338,
    0.24754737787762762, 0.3066305589425038, 0.3668175791207593,
    0.4271991119998129, 0.4868471703532098, 0.5446057255773408,
    0.5988953431570927, 0.6485422692457665, 0.691651709545972,
    0.7300595469021355, 0.7703332614971786, 0.815870356783341,
    0.8643489105446989, 0.9040882881770397, 0.9336344972041183,
    0.9550721129466554, 0.9754846635847606, 0.9959177265927766,
    1.016468673656501, 1.037691528148333, 1.0588812085433668,
    1.0816035864424922, 1.104222015679479, 1.128963687655965,
    1.1534301050682327, 1.1797006351075863, 1.205619931119678,
    1.2323939286695944, 1.258616766654207, 1.2841748588461053,
    1.3080660485928484, 1.3290656900503341, 1.3464925504784733,
    1.3585604095278472, 1.3655200008140553, 1.3643228424220843,
    1.3568240390200386, 1.3392424473904416, 1.3161396796890494,
    1.2836181909528899, 1.2470141707791285, 1.202753889483103,
    1.1564368887889296, 1.1049411866109113, 1.054366474846521,
    1.000083472892675, 0.949416919359809, 0.8646082765158011, 0.711915230830556,
    0.4956534597679056, 0.29442704135028486, 0.19802226124720013,
    0.1992848183572621, 0.25898848496886345, 0.3305450884470083,
    0.4185946024183675, 0.5263375055906833, 0.6547625868934368,
    0.8051363650174159, 0.9751298893827106, 1.156711324191743,
    1.3323524707723677, 1.477607411640947, 1.5685147390706755,
    1.5887700952872545, 1.5364267072861904, 1.4223141566175865,
    1.2677263716590008, 1.0962768419808373, 0.9293854741021267,
    0.7782375571784881, 0.647702654348279, 0.5360888104692971,
    0.4440440647128785, 0.3675853172505417, 0.3196013992252282,
    0.3715500346131742, 0.5379044935634102, 0.7910417489949988,
    0.9824393072866977, 1.0824884233939356, 1.0992054350095815,
    1.099317192532925, 1.0924381332372188, 1.0790129603793426,
    1.0617828562272706, 1.0421000353612604, 1.0188583481065694,
    0.9926642340757112, 0.9646500955379871, 0.9378509987699261,
    0.9107862118286724, 0.8835559514791084, 0.8563316003701941,
    0.8320418780693631, 0.8090942864494863, 0.7867748116408856,
    0.7652134032953112, 0.7470465205402864, 0.7305713455389755,
    0.714769543045434, 0.6994009045206565, 0.6874493633574527,
    0.6769815086867051, 0.6671699509229597, 0.6568418112180514,
    0.6496374195777763, 0.6438614322400058, 0.6388154457802527,
    0.6325709961410982, 0.6283014275792347, 0.6258294519395627,
    0.6239189782820947, 0.6208705001354816, 0.6178516223290639,
    0.6171275314143893, 0.6168049127747609, 0.6157106431908982,
    0.6126008392522037, 0.6110769272431393, 0.6113029546782932,
    0.6111012900779172, 0.6085662127111964, 0.6041351666786241,
    0.602011999413444, 0.6012874772303779, 0.5993227426598774,
    0.5939410015637581, 0.5868704926467209, 0.5824793278343712,
    0.580105754944014, 0.5758632789089334, 0.567541956359591,
    0.5564665623289806, 0.5486281061371763, 0.5440472862264766,
    0.5389186598831525, 0.5293226134129303, 0.5152970407095201,
    0.5031778140568605, 0.4948836062631022, 0.49040260831579374,
    0.4825235616518138, 0.4701627992383248, 0.45341329043713613,
    0.44047674245403473, 0.4317211337794717, 0.42709449277137407,
    0.4198541603068493, 0.4084191577538925, 0.39288984566717655,
    0.37865157713017333, 0.36859668401438966, 0.3626707090087547,
    0.3581566169657801, 0.3509514674210356, 0.3400714047505536,
    0.32561451026045957, 0.31294752245106033, 0.3041406970843103,
    0.29912371061621873, 0.29517696415167044, 0.291072629162405,
    0.28383998384215325, 0.2735430586179825, 0.26058015001946766,
    0.250972514061922, 0.24468830860961202, 0.24149064628819714,
    0.2383306929061778, 0.23518579595111708, 0.23070898457605374,
    0.22370830773347616, 0.21424204029705216, 0.20507530517241687,
    0.19866192477073127, 0.19495397171313703, 0.19253572164989255,
    0.1901472337715325, 0.1877796188295726, 0.1854027012260566,
    0.17366897020154617, 0.14488138001520512, 0.09920048686348974,
    0.055430866548454416, 0.029084164262403598, 0.019895890565813878,
    0.018428281313648102, 0.016902304210577843, 0.015644490093477254,
    0.01466675994279363, 0.01391248841667008, 0.013126880466823622,
    0.012291455819092426, 0.011468548206050944, 0.010800859251875896,
    0.01027779906042539, 0.00984563783965151, 0.009377852183987902,
    0.0088752427283343, 0.008365467770486258, 0.007943435104033095,
    0.007604168794289432, 0.007329199065712392, 0.0070404590720248295,
    0.006728404239040999, 0.006394582654510174, 0.006093239332749012,
    0.005842018937083859, 0.005637915614200423, 0.005451532006693845,
    0.005252131971139687, 0.005038370708670633, 0.004811221102088435,
    0.0046112031297340815, 0.004440929876480411, 0.004298653475138564,
    0.004162887080969517, 0.004020717952522034, 0.003870072649343605,
    0.003711516590629347, 0.003566513026784145, 0.003439309988846401,
    0.0033289294886008334, 0.0032239355171201386, 0.0031180173945601617,
    0.0030075298080220482, 0.00289277156945278, 0.0027811047156295326,
    0.0026798374851070748, 0.0025884507905956414, 0.0025028445522459544,
    0.002418961459697855, 0.002333169356130233, 0.002245526879512635,
    0.0021562049371345276, 0.0020723790644398827, 0.0019939352619001995,
    0.0019205384031310722, 0.0018483576404783536, 0.0017767252985208544,
    0.0017048063116844971, 0.0016326401179457592, 0.0015612556120591656,
    0.0014922508763824825, 0.0014254797060351838, 0.0013602864112919357,
    0.0012957339528534804, 0.0012317596257271895, 0.0011683140671138275,
    0.0011053488019018547, 0.00104281714809459, 0.000980672335652562,
    0.0009188674064227346, 0.0008573553484404938, 0.0007961035997123635,
    0.0007355431618093719, 0.0006755434589166021, 0.0006159296853371278,
    0.0005556011570398502, 0.0004945797325850294, 0.0004329334426693937,
    0.00037119223846294357, 0.000309438570043333, 0.000247651051626284,
    0.00018558758042961287, 0.000124026433866722, 0.00007723041856853905,
    0.00006000473057561545, 0.0000848915165563335, 0.00013785707022823533,
    0.00020544598010444355, 0.0002748835586946839, 0.00034660177791107387,
    0.0004193279354730596, 0.0004927682994417204, 0.0005666594021837972,
    0.0006415800346431041, 0.0007194863321841054, 0.0008021904526401511,
    0.0008903296870255553, 0.0009808767683796287, 0.001071089013310959,
    0.0011606166453288433, 0.0012508798779936837, 0.0013466472112933887,
    0.0014523826840843513, 0.001569102515850669, 0.0016909859825913799,
    0.0018104853096986588, 0.0019271043798289682, 0.0020436326967705897,
    0.002173264610711493, 0.0023220950930852996, 0.002491928107470908,
    0.0026657069436268454, 0.0028336486056000105, 0.0029948922505062202,
    0.0031627097625889205, 0.0033644573000940047, 0.0036029257357828456,
    0.0038720165426677946, 0.0041304749981692325, 0.0043775708137501505,
    0.004621533519593793, 0.004927513938909317, 0.005300544736505379,
    0.0057374737984374315, 0.0061572010964428825, 0.00655916917719429,
    0.006988649219152828, 0.0075394263531879, 0.008223731970477571,
    0.008972064658185584, 0.009698145745076013, 0.016436362397870066,
    0.021791923605430183,
]
