export const data = {
    isDnd: false,
    volume: 1.0,
    parentRoom: null, 
    views: [
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
        {
            inherit: false,
            visible: false,
            xview: 0,
            yview: 0,
            wview: 1366,
            hview: 768,
            xport: 0,
            yport: 0,
            wport: 1366,
            hport: 768,
            hborder: 32,
            vborder: 32,
            hspeed: -1,
            vspeed: -1,
            objectId: null,
        },
    ],
    layers: [
        {
            assets: [
                {
                    spriteId: { name: '28', path: 'sprites/28/28.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1024.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_27',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '29', path: 'sprites/29/29.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1536.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_28',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2048.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2560.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: '',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 3072.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_2',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '30', path: 'sprites/30/30.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 3584.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_31',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '31', path: 'sprites/31/31.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 4096.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_30',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 4608.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_3',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5120.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_4',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5632.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_5',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 6144.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_6',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 6656.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_7',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7168.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_8',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7680.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_9',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8192.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_10',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_11',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_12',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '2', path: 'sprites/2/2.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9728.0,
                    y: 1024.0,
                    resourceVersion: '1.0',
                    name: 'graphic_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '27', path: 'sprites/27/27.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1024.0,
                    y: 1536.0,
                    resourceVersion: '1.0',
                    name: 'graphic_26',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '25', path: 'sprites/25/25.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1536.0,
                    y: 1536.0,
                    resourceVersion: '1.0',
                    name: 'graphic_24',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '24', path: 'sprites/24/24.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2048.0,
                    y: 1536.0,
                    resourceVersion: '1.0',
                    name: 'graphic_23',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '3', path: 'sprites/3/3.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9728.0,
                    y: 1536.0,
                    resourceVersion: '1.0',
                    name: 'graphic_2',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '4', path: 'sprites/4/4.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 10240.0,
                    y: 1536.0,
                    resourceVersion: '1.0',
                    name: 'graphic_3',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '2', path: 'sprites/2/2.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1536.0,
                    y: 2560.0,
                    resourceVersion: '1.0',
                    name: 'graphic_1_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '26', path: 'sprites/26/26.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 1536.0,
                    y: 2048.0,
                    resourceVersion: '1.0',
                    name: 'graphic_25',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '23', path: 'sprites/23/23.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2048.0,
                    y: 2048.0,
                    resourceVersion: '1.0',
                    name: 'graphic_22',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '5', path: 'sprites/5/5.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 10240.0,
                    y: 2048.0,
                    resourceVersion: '1.0',
                    name: 'graphic_4',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '21', path: 'sprites/21/21.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2048.0,
                    y: 2560.0,
                    resourceVersion: '1.0',
                    name: 'graphic_20',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '20', path: 'sprites/20/20.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2560.0,
                    y: 2560.0,
                    resourceVersion: '1.0',
                    name: 'graphic_19',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '6', path: 'sprites/6/6.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 10240.0,
                    y: 2560.0,
                    resourceVersion: '1.0',
                    name: 'graphic_5',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '19', path: 'sprites/19/19.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 2560.0,
                    y: 3072.0,
                    resourceVersion: '1.0',
                    name: 'graphic_18',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 3584.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_13',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 4096.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_14',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 4608.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_15',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5120.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_16',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5632.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_17',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '22', path: 'sprites/22/22.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5632.0,
                    y: 3072.0,
                    resourceVersion: '1.0',
                    name: 'graphic_21',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '8', path: 'sprites/8/8.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9728.0,
                    y: 3072.0,
                    resourceVersion: '1.0',
                    name: 'graphic_7',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '7', path: 'sprites/7/7.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 10240.0,
                    y: 3072.0,
                    resourceVersion: '1.0',
                    name: 'graphic_6',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '21', path: 'sprites/21/21.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 5632.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_20_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '20', path: 'sprites/20/20.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 6144.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_19_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '6', path: 'sprites/6/6.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9728.0,
                    y: 3584.0,
                    resourceVersion: '1.0',
                    name: 'graphic_5_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '19', path: 'sprites/19/19.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 6144.0,
                    y: 4096.0,
                    resourceVersion: '1.0',
                    name: 'graphic_18_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: -1.0,
                    scaleY: -1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7168.0,
                    y: 4608.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_18',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '18', path: 'sprites/18/18.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7168.0,
                    y: 4096.0,
                    resourceVersion: '1.0',
                    name: 'graphic_17',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '8', path: 'sprites/8/8.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 4096.0,
                    resourceVersion: '1.0',
                    name: 'graphic_7_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '7', path: 'sprites/7/7.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9728.0,
                    y: 4096.0,
                    resourceVersion: '1.0',
                    name: 'graphic_6_1',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '17', path: 'sprites/17/17.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7168.0,
                    y: 4608.0,
                    resourceVersion: '1.0',
                    name: 'graphic_16',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '16', path: 'sprites/16/16.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 7680.0,
                    y: 4608.0,
                    resourceVersion: '1.0',
                    name: 'graphic_15',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '15', path: 'sprites/15/15.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8192.0,
                    y: 4608.0,
                    resourceVersion: '1.0',
                    name: 'graphic_14',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '6', path: 'sprites/6/6.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 4608.0,
                    resourceVersion: '1.0',
                    name: 'graphic_5_2',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: -90.0,
                    scaleX: -1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 5632.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_19',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '8', path: 'sprites/8/8.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 5120.0,
                    resourceVersion: '1.0',
                    name: 'graphic_7_2',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '7', path: 'sprites/7/7.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 5120.0,
                    resourceVersion: '1.0',
                    name: 'graphic_6_2',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: -90.0,
                    scaleX: -1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 6144.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_20',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '9', path: 'sprites/9/9.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 5632.0,
                    resourceVersion: '1.0',
                    name: 'graphic_8',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '10', path: 'sprites/10/10.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 5632.0,
                    resourceVersion: '1.0',
                    name: 'graphic_9',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '1', path: 'sprites/1/1.yy' },
                    headPosition: 0.0,
                    rotation: -90.0,
                    scaleX: -1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 6656.0,
                    resourceVersion: '1.0',
                    name: 'graphic_0_21',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '12', path: 'sprites/12/12.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 6144.0,
                    resourceVersion: '1.0',
                    name: 'graphic_11',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '11', path: 'sprites/11/11.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 9216.0,
                    y: 6144.0,
                    resourceVersion: '1.0',
                    name: 'graphic_10',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '14', path: 'sprites/14/14.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8192.0,
                    y: 6656.0,
                    resourceVersion: '1.0',
                    name: 'graphic_13',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
                {
                    spriteId: { name: '13', path: 'sprites/13/13.yy' },
                    headPosition: 0.0,
                    rotation: 0.0,
                    scaleX: 1.0,
                    scaleY: 1.0,
                    animationSpeed: 1.0,
                    colour: 4294967295,
                    inheritedItemId: null,
                    frozen: false,
                    ignore: false,
                    inheritItemSettings: false,
                    x: 8704.0,
                    y: 6656.0,
                    resourceVersion: '1.0',
                    name: 'graphic_12',
                    tags: [],
                    resourceType: 'GMRSpriteGraphic',
                },
            ],
            visible: true,
            depth: 0,
            userdefinedDepth: false,
            inheritLayerDepth: false,
            inheritLayerSettings: false,
            gridX: 512,
            gridY: 512,
            layers: [],
            hierarchyFrozen: false,
            resourceVersion: '1.0',
            name: 'Tile_Layer_1',
            tags: [],
            resourceType: 'GMRAssetLayer',
        },
    ],
    inheritLayers: false,
    creationCodeFile: '',
    inheritCode: false,
    instanceCreationOrder: [],
    inheritCreationOrder: false,
    sequenceId: null,
    roomSettings: {
        inheritRoomSettings: false,
        Width: 11776,
        Height: 8192,
        persistent: false,
    },
    viewSettings: {
        inheritViewSettings: false,
        enableViews: false,
        clearViewBackground: false,
        clearDisplayBuffer: true,
    },
    physicsSettings: {
        inheritPhysicsSettings: false,
        PhysicsWorld: false,
        PhysicsWorldGravityX: 0.0,
        PhysicsWorldGravityY: 10.0,
        PhysicsWorldPixToMetres: 0.1,
    },
    parent: {
        name: 'Rooms',
        path: 'folders/Rooms.yy',
    },
    resourceVersion: '1.0',
    name: '20220901-RC-oxxo-tilemap-v3',
    tags: [],
    resourceType: 'GMRoom',
}
