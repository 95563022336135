import { GlobalData } from '../../../data/GlobalData'
import { send, sendInternalEvent } from '../../helperfunctions/HelperFunctions'
import Replay from './Replay'
import * as GameUI from '../GameUI/GameUI.js'
import { GameSettings } from '../../settings/GameSettings.js'


export const debugPrize = {
    prize: {
        id: '12',
        imageUrl:
            'https://s3.eu-west-1.amazonaws.com/download.robotkittens.nl/tempimages/redbull.png', // Import image in game over screen
        title: 'Red bull sandia edition',
        discount: 'en todos los productos red bull',
        type: 'COUPON',
        issuedOn: 'string',
        descriptions: ['string'],
        stores: ['string'],
        validity: 'string',
        rules: ['string'],
        barCode: {
            code: 'string',
        },
        availableGames: 0,
        drs: 3,
    },
}

export const debugGameInfo = {
    customerId: '',
    availableGames: 0,
    drs: 5,
    gameId: 'gameTest',
    bestTime: 0,
    bestTimeGameReplay: Replay.hist
    // bestTimeGameReplay: []
}

window.addEventListener('message', eventReceived)

function eventReceived(event) {
    const detail = event.data || {}
    // console.log('event received', event.data.eventName, event.data)
    switch (event.data.eventName) {
        case 'game-info':
            if (GameSettings.drsUseLocal) {
                GlobalData.drsAvailable = GameSettings.drsLocalCount;

            } else {
                GlobalData.drsAvailable = detail.drs
            }
            
            GlobalData.gamesAvailable = detail.availableGames
            GlobalData.customerId = detail.customerId
            if (detail.personalRecord != null) {
                GlobalData.personalRecord = detail.personalRecord
            } else if (detail.bestTime && detail != 0) {
                GlobalData.personalRecord = detail.bestTime;
            }
            if (detail.customerId === '' || detail.customerId == null) {
                GlobalData.isUser = false
            } else {
                GlobalData.isUser = true
            }
            GlobalData.bestTime = detail.bestTime ? detail.bestTime : 0;
            GlobalData.bestTimeGameReplay = detail.bestTimeGameReplay ? detail.bestTimeGameReplay : []
            send('dataSet');
            // sendInternalEvent('dataSet');
            
            break
        case 'prize':
            const prize = detail.prize || {}
            GlobalData.drsAvailable = prize.drs
            GlobalData.gamesAvailable = prize.availableGames
            GlobalData.prizeImageUrl = prize.imageUrl // Image used for the prize
            GlobalData.discountText = prize.title // The discounted number (e.g. 20%)
            GlobalData.discountProductText = prize.discount // The discounted product (e.g. Red Bull)
            send('receivedPrize')
            break
        case 'play-again':
            console.log('play again');
            sendInternalEvent('restartGame');
            if (GameSettings.drsUseLocal) {
                GlobalData.drsAvailable = GameSettings.drsLocalCount;
                send('dataSet');
            }
            send('allLoaded');

            break
    }
}
