import PIXI from '../../lib/PixiProjectionExport.js'

const spritesheetFolder = 'assets/spritesheets/'
const spritesheetsNames = [
    'assetsheet.json',
    'tracksheet_1.json',
    'tracksheet_2.json',
]

export let textureSheet = []
export let percentageLoaded = 0

let isLoading = false
let isLoaded = false

let onLoadedCallbacks = []

//---Checks if already innited or if busy inniting, always callbacks when all are loaded
export function initSpriteSheets(onloadedArg) {
    if (!isLoading) {
        isLoading = true
        onLoadedCallbacks.push(onloadedArg)

        let index = 0
        let spritesheetPath = spritesheetFolder + spritesheetsNames[index]
        PIXI.Loader.shared
            .add(spritesheetPath)
            .load(() => setup(spritesheetPath, index + 1))
    } else if (isLoaded) {
        onloadedArg()
    } else {
        onLoadedCallbacks.push(onloadedArg)
    }
}
function setup(spritesheetPath, index) {
    textureSheet = Object.assign(
        textureSheet,
        PIXI.Loader.shared.resources[spritesheetPath].textures
    )

    percentageLoaded = (index / spritesheetsNames.length) * 100

    if (index === spritesheetsNames.length) {
        isLoaded = true

        // console.log('callback', percentageLoaded)
        onLoadedCallbacks.forEach((callback) => {
            callback()
        })
    } else {
        let spritesheetPath = spritesheetFolder + spritesheetsNames[index]
        PIXI.Loader.shared
            .add(spritesheetPath)
            .load(() => setup(spritesheetPath, index + 1))
    }
}

//---Makes it so when you input a number, you will get the correct number sprite for this.
export function getNumberTexturesFromSpriteSheet(number, size = 'default') {
    let numberString = number.toString()

    let additionalString = ''

    if (size === 'small') additionalString = '-small'

    let textureName = numberString[0] + additionalString + '.png'

    return [textureSheet[textureName]]
}
