import PIXI from '../../lib/PixiProjectionExport.js'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import { getTileInfo } from '../World/TileController.js'
import { bitmap_1 } from '../../../public/assets/gamedata/CollisionMap_1.js'
import { bitmap_2 } from '../../../public/assets/gamedata/CollisionMap_2.js'

export let bitmaps = []

export function initCollisionMaps() {
    bitmaps.push(bitmap_1)
    bitmaps.push(bitmap_2)

    let xPos = 370.02
    let xPos2 = 363.02
    let yPos = 982.911
    let yPos2 = 982.911

    worldPosToBitmapValue(xPos, yPos)
    worldPosToBitmapValue(xPos2, yPos2)
}

export function worldPosToBitmapValue(worldX, worldY) {
    //Get current tile
    let tileInfo = getTileInfo(worldX, worldY)

    //if no tile return 0
    if (tileInfo === null) {
        return 0
    }

    //get pos on local sprite in spritesheet
    let spriteId = Number(tileInfo.data.spriteId.name) - 1 //1 based index in data sheet
    let bitmapId = Math.floor(spriteId / 16)
    spriteId = spriteId % 16

    //rotate pos
    let offset = new PIXI.Point(0.5, 0.5) //Center on 0,0
    let basePos = new PIXI.Point(tileInfo.localX, tileInfo.localY)
    let centeredPos = basePos.subtract(offset)
    centeredPos = Hf.rotateVector(
        centeredPos,
        tileInfo.data.rotation * Hf.deg2Rad
    )

    //mirror pos
    centeredPos.x *= tileInfo.data.scaleX
    centeredPos.y *= tileInfo.data.scaleY

    basePos = centeredPos.add(offset)

    //convert to global pos in spritesheet
    let offsetInSpriteSheet = new PIXI.Point(
        spriteId % 4,
        Math.floor(spriteId / 4)
    )
    offsetInSpriteSheet = offsetInSpriteSheet.multiplyScalar(256 / 4)

    basePos = basePos.multiplyScalar(256 / 4)

    let bitmapPos = basePos.add(offsetInSpriteSheet)

    //get value from bitmap
    let toReturn = getBitmapValue(
        bitmaps[bitmapId],
        Math.floor(bitmapPos.x),
        Math.floor(bitmapPos.y)
    )

    return toReturn
    // return {
    //     value: toReturn,
    //     bitmap: bitmaps[bitmapId],
    //     x: Math.floor(bitmapPos.x),
    //     y: Math.floor(bitmapPos.y),
    // }
}

function getBitmapValue(bitmap, x, y) {
    const width = 256
    let arrayIndex = y * (width / 32) + Math.floor(x / 32)
    let encodedInt = bitmap[arrayIndex]

    let intIndex = x % 32
    let currentValue = (encodedInt & (1 << intIndex)) !== 0

    return currentValue
}
