<script>
    // import { isLoading } from 'svelte-i18n'
    import Main from './components/Main.svelte'

    let url = 'BASE_URL'
    let root = document.documentElement
    root.style.setProperty('--base-img-url', url)
</script>

<!-- {#if !$isLoading} -->
<Main />

<!-- {/if} -->
<style type="text/scss">
    @import 'scss/variables';
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

    :global(:root) {
        --base-img-url: '';
        --base-fontsize: 10 !important;
        --base-design-width: 375;
        @media (min-width: $breakpoint-small) {
            --base-fontsize: 10 !important;
            --base-design-width: 1440;
        }
    }

    :global(main) {
        font-size: calc(
            var(--base-fontsize) / var(--base-design-width) * 100vw
        ) !important;
    }

    :global(html, body) {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overscroll-behavior: none;
    }

    :global(*, *:before, *:after) {
        box-sizing: inherit;
    }

    :global(*) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
