const _0xf22c5e = _0x85a2
function _0x747c() {
    const _0x9d3bc9 = [
        '3287720KBqXdh',
        '6lcOFmc',
        '3802617MqZQin',
        'reduce',
        'join',
        'map',
        'substr',
        '5880544QeOTpi',
        '1497CvHwGf',
        'push',
        '297583JyjpuZ',
        '7108iBbnsQ',
        'split',
        '16791930bPvyBJ',
        '2970084fEsmnN',
        '1043097cNbYOi',
    ]
    _0x747c = function () {
        return _0x9d3bc9
    }
    return _0x747c()
}
;(function (_0x4ed514, _0xc3dc46) {
    const _0x585e36 = _0x85a2,
        _0x57b043 = _0x4ed514()
    while (!![]) {
        try {
            const _0x346ecd =
                (parseInt(_0x585e36(0x18d)) / 0x1) *
                    (-parseInt(_0x585e36(0x194)) / 0x2) +
                (parseInt(_0x585e36(0x18b)) / 0x3) *
                    (parseInt(_0x585e36(0x18e)) / 0x4) +
                parseInt(_0x585e36(0x193)) / 0x5 +
                -parseInt(_0x585e36(0x191)) / 0x6 +
                -parseInt(_0x585e36(0x185)) / 0x7 +
                -parseInt(_0x585e36(0x18a)) / 0x8 +
                parseInt(_0x585e36(0x190)) / 0x9
            if (_0x346ecd === _0xc3dc46) break
            else _0x57b043['push'](_0x57b043['shift']())
        } catch (_0x35cfb1) {
            _0x57b043['push'](_0x57b043['shift']())
        }
    }
})(_0x747c, 0xb5a27)
import { elapsed } from '../GameUI/GameUI'
let nodeHistory = []
const key = _0xf22c5e(0x192)
function _0x85a2(_0xd15d00, _0x2b8bfb) {
    const _0x747c25 = _0x747c()
    return (
        (_0x85a2 = function (_0x85a2a9, _0x49fb45) {
            _0x85a2a9 = _0x85a2a9 - 0x185
            let _0x5c767c = _0x747c25[_0x85a2a9]
            return _0x5c767c
        }),
        _0x85a2(_0xd15d00, _0x2b8bfb)
    )
}
export function setSegmentData(_0x5b8ae1) {
    const _0xe369dd = _0xf22c5e
    nodeHistory[_0xe369dd(0x18c)]({ i: _0x5b8ae1, t: elapsed })
}
export function getGameData() {
    let _0x76a7a8 = { time: elapsed, nodeHis: nodeHistory },
        _0x156e54 = JSON['stringify'](_0x76a7a8),
        _0x3376b3 = crypt(key, _0x156e54)
    return _0x3376b3
}
export function resetMetrics() {
    nodeHistory = []
}
const crypt = (_0x55e37b, _0x1ad305) => {
    const _0x545bfd = _0xf22c5e,
        _0x16904a = (_0xec58fd) =>
            _0xec58fd[_0x545bfd(0x18f)]('')[_0x545bfd(0x188)]((_0x50fa73) =>
                _0x50fa73['charCodeAt'](0x0)
            ),
        _0x5364fd = (_0x20c2eb) =>
            ('0' + Number(_0x20c2eb)['toString'](0x10))[_0x545bfd(0x189)](-0x2),
        _0x514344 = (_0x3c7a74) =>
            _0x16904a(_0x55e37b)[_0x545bfd(0x186)](
                (_0x50fffd, _0x550a87) => _0x50fffd ^ _0x550a87,
                _0x3c7a74
            )
    return _0x1ad305[_0x545bfd(0x18f)]('')
        [_0x545bfd(0x188)](_0x16904a)
        ['map'](_0x514344)
        [_0x545bfd(0x188)](_0x5364fd)
        [_0x545bfd(0x187)]('')
}
