import * as GameUI from '../GameUI/GameUI'
import { allowDrs, disAllowDrs } from './InputManager.js'
import { GameSettings } from '../../settings/GameSettings.js'
import { GlobalData } from '../../../data/GlobalData'

let drsLeft = GlobalData.drsAvailable
let drsUsed = 0

export function initDrs() {
    drsUsed = 0
    drsLeft = GlobalData.drsAvailable
    GameUI.updateDRS(drsLeft) // Is actually the DRS UI
}

export function setInitialDrs() {
    drsUsed = 0
    drsLeft = GlobalData.drsAvailable
    //console.log("Drs left: ", drsLeft)
    GameUI.updateDRS(drsLeft) // Is actually the DRS UI
}

export function allowEnableDrs(delay = 0) {
    if (drsLeft === 0) return

    setTimeout(() => allowDrs(onDrsActivated), delay * 1000)
}

export function disAllowEnableDrs() {
    disAllowDrs()
}

function onDrsActivated() {
    drsUsed++
    //console.log("Drs used: ", drsUsed)
    drsLeft--
    GameUI.updateDRS(drsLeft) // Is actually the DRS UI

    if (drsLeft <= 0) {
        drsLeft = 0
        disAllowDrs()
    }

    if (drsLeft > 0) {
        setTimeout(allowEnableDrs, GameSettings.drsCooldown * 1000)
    }
    //TODO write to server
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    // Set drs to 1 for the tutorial
    if (obj.data.eventName === 'startTutorial') {
        drsLeft = 1
        GameUI.updateDRS(drsLeft) // Is actually the DRS UI
    }
}

export function getDrsUsed() {
    //console.log("Get Drs used: ", drsUsed)
    return drsUsed
}
