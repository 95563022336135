export const worldData = [
    {
        id: '21',
        objects: [
            {
                i: 33,
                x: 345.497,
                y: 1165.811,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 383.449,
                y: 1165.821,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 403.449,
                y: 1165.821,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 363.575,
                y: 1165.821,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 353.575,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 393.449,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 413.449,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 373.449,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 335.497,
                y: 1165.798,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 539.997,
                y: 1163.205,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 579.997,
                y: 1163.18,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 619.997,
                y: 1163.152,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 499.997,
                y: 1163.236,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 599.997,
                y: 1163.166,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 559.997,
                y: 1163.194,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 519.997,
                y: 1163.218,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 529.997,
                y: 1163.231,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 569.997,
                y: 1163.207,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 609.997,
                y: 1163.179,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 509.997,
                y: 1163.249,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 629.997,
                y: 1163.165,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 589.997,
                y: 1163.193,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 549.997,
                y: 1163.218,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 640.077,
                y: 1165.504,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 680.077,
                y: 1165.475,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 650.077,
                y: 1165.497,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 690.077,
                y: 1165.468,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 660.077,
                y: 1165.49,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 670.077,
                y: 1165.484,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 23,
                x: 414.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 375.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 335.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 355.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 395.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 454.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 434.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 535.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 495.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 475.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 515.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 355.521,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 335.521,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 375.521,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 395.521,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 435.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 415.521,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 455.521,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 475.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 475.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 535.521,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 495.521,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 515.521,
                y: 1111.872,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 715.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 675.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 695.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 735.515,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 634.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 654.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 595.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 555.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 535.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 575.488,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 614.937,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 715.521,
                y: 1111.872,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 695.521,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 735.521,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 675.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 675.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 655.521,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 615.521,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 635.521,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 595.521,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 575.521,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 535.521,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 555.521,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 32,
                x: 501.064,
                y: 1115.241,
                r: 3.14159,
                sX: 9.927,
                sY: 16.87591,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 708.02,
                y: 1092.058,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 718.02,
                y: 1092.105,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 645.688,
                y: 1091.821,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 655.688,
                y: 1091.868,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 697.154,
                y: 1092.125,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 676.554,
                y: 1091.848,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 687.154,
                y: 1092.078,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 666.554,
                y: 1091.801,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 583.792,
                y: 1091.692,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 604.392,
                y: 1091.969,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 593.792,
                y: 1091.739,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 614.392,
                y: 1092.016,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 572.926,
                y: 1091.759,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 562.926,
                y: 1091.712,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 635.258,
                y: 1091.996,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 625.258,
                y: 1091.949,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 403.792,
                y: 1091.692,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 424.392,
                y: 1091.969,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 413.792,
                y: 1091.739,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 434.392,
                y: 1092.016,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 392.926,
                y: 1091.759,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 455.258,
                y: 1091.996,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 445.258,
                y: 1091.949,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 528.02,
                y: 1092.058,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 538.02,
                y: 1092.105,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 465.688,
                y: 1091.821,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 475.688,
                y: 1091.868,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 517.154,
                y: 1092.125,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 496.554,
                y: 1091.848,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 507.154,
                y: 1092.078,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 486.554,
                y: 1091.801,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 424.294,
                y: 1160.711,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 435.16,
                y: 1160.644,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 445.16,
                y: 1160.691,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 465.59,
                y: 1160.562,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 455.59,
                y: 1160.515,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 476.456,
                y: 1160.495,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 486.456,
                y: 1160.542,
                r: 6.28319,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 11,
                x: 550.084,
                y: 1089.266,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 445.51,
                y: 1143.452,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 445.475,
                y: 1108.484,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 592.048,
                y: 1143.307,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 592.013,
                y: 1108.339,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 449.243,
                y: 1136.248,
                r: -3.1381,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 382.926,
                y: 1091.712,
                r: 3.14159,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 29,
                x: 672.989,
                y: 1108.122,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 672.989,
                y: 1143.75,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 512.585,
                y: 1108.122,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 512.585,
                y: 1143.75,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 392.634,
                y: 1108.122,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 392.634,
                y: 1143.75,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 450.574,
                y: 1108.454,
                r: -3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 725.966,
                y: 1010.23,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 574.632,
                y: 1010.23,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 625.052,
                y: 1010.23,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 673.898,
                y: 1010.23,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 524.73,
                y: 1010.23,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 702.622,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 551.287,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 601.707,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 650.553,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 501.386,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '20',
        objects: [
            {
                i: 35,
                x: 124.389,
                y: 1025.742,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 148.321,
                y: 1108.528,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 123.078,
                y: 1077.56,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 195.376,
                y: 1145.508,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 144.814,
                y: 1083.573,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 170.057,
                y: 1114.541,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 122.986,
                y: 1050.537,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 141.572,
                y: 1035.404,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 188.643,
                y: 1099.408,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 163.4,
                y: 1068.44,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 213.962,
                y: 1130.375,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 192.226,
                y: 1124.361,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 141.664,
                y: 1062.427,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 166.907,
                y: 1093.395,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 142.975,
                y: 1010.609,
                r: 4.02905,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 246.828,
                y: 1150.062,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 284.78,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 324.78,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 304.78,
                y: 1165.808,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 264.906,
                y: 1161.445,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 274.906,
                y: 1162.728,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 314.78,
                y: 1165.821,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 294.78,
                y: 1165.821,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 256.828,
                y: 1155.417,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 31,
                x: 250.001,
                y: 946.717,
                r: 0.0,
                sX: 9.927,
                sY: 16.87591,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 211.863,
                y: 1093.813,
                r: -2.26893,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 125.618,
                y: 963.295,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 150.822,
                y: 963.79,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 156.193,
                y: 925.221,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 186.093,
                y: 889.95,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 204.058,
                y: 884.312,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 172.197,
                y: 909.155,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 205.3,
                y: 892.207,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 183.968,
                y: 929.917,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 163.499,
                y: 944.683,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 137.55,
                y: 984.082,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 193.414,
                y: 909.438,
                r: -3.66268,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 224.274,
                y: 877.325,
                r: -1.83008,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 328.759,
                y: 1108.6,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 328.794,
                y: 1143.568,
                r: -0.00099,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 317.228,
                y: 1116.582,
                r: -3.07723,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 288.755,
                y: 1104.587,
                r: 0.35081,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 248.74,
                y: 1088.636,
                r: 0.55452,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 214.64,
                y: 1054.661,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 275.881,
                y: 1136.825,
                r: 0.35081,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 230.024,
                y: 1119.024,
                r: 0.55452,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 185.806,
                y: 1074.411,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 325.105,
                y: 1089.266,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 202.9,
                y: 1099.064,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 194.353,
                y: 1086.737,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 234.03,
                y: 1077.48,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 223.212,
                y: 1066.728,
                r: 0.96454,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 214.985,
                y: 1108.504,
                r: 0.91768,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 262.507,
                y: 1095.975,
                r: 0.4648,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 246.59,
                y: 1127.917,
                r: 0.4648,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 261.616,
                y: 1133.014,
                r: 0.39943,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 275.412,
                y: 1100.1,
                r: 0.39943,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 291.698,
                y: 1140.742,
                r: 0.29925,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 302.134,
                y: 1106.614,
                r: 0.29925,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 309.082,
                y: 1143.313,
                r: 0.15769,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 314.598,
                y: 1108.053,
                r: 0.15769,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 206.525,
                y: 1041.125,
                r: 1.33446,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 172.554,
                y: 1048.266,
                r: 1.33446,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 163.277,
                y: 1017.949,
                r: 1.44845,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 198.687,
                y: 1013.504,
                r: 1.44845,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 202.403,
                y: 1026.533,
                r: 1.38308,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 167.358,
                y: 1033.281,
                r: 1.38308,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 178.599,
                y: 1061.886,
                r: 1.20307,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 162.739,
                y: 989.99,
                r: 1.74455,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 197.905,
                y: 996.071,
                r: 1.74455,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 201.567,
                y: 983.566,
                r: 2.08037,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 170.369,
                y: 966.236,
                r: 2.08037,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 196.834,
                y: 945.552,
                r: 2.55936,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 217.594,
                y: 976.973,
                r: 3.02905,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 181.44,
                y: 952.846,
                r: 2.34217,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 235.723,
                y: 976.905,
                r: 3.1455,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 235.772,
                y: 941.216,
                r: 3.1455,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 217.08,
                y: 941.143,
                r: 3.1455,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 236.044,
                y: 1036.553,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 217.637,
                y: 1032.591,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 297.376,
                y: 1050.084,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 223.949,
                y: 1002.873,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 250.585,
                y: 997.152,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 281.227,
                y: 1068.444,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 242.006,
                y: 1065.745,
                r: 3.09493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 239.683,
                y: 1081.421,
                r: 4.01426,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 316.623,
                y: 1143.322,
                r: 3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 190.059,
                y: 860.229,
                r: 1.0484,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 143.277,
                y: 887.164,
                r: 1.0484,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 112.411,
                y: 924.832,
                r: 0.30658,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 96.119,
                y: 976.296,
                r: 0.30658,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 93.826,
                y: 1029.891,
                r: -0.30102,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 109.831,
                y: 1081.445,
                r: -0.30102,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 73.99,
                y: 1000.955,
                r: 0.30658,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 90.281,
                y: 949.491,
                r: 0.30658,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 101.501,
                y: 1108.503,
                r: -0.30102,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 85.496,
                y: 1056.949,
                r: -0.30102,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 135.801,
                y: 1128.587,
                r: -0.63524,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 149.089,
                y: 1170.514,
                r: -0.87349,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 185.819,
                y: 1176.91,
                r: -0.99835,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 224.666,
                y: 1204.669,
                r: -1.45863,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 258.033,
                y: 1190.412,
                r: -1.45863,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 0,
                x: 182.447,
                y: 1001.489,
                r: -1.59882,
                sX: 66.96429,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '19',
        objects: [
            {
                i: 3,
                x: 305.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 3,
                x: 285.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 3,
                x: 265.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 3,
                x: 245.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 3,
                x: 225.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 3,
                x: 205.837,
                y: 921.985,
                r: 0.0,
                sX: 41.0,
                sY: 52.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 251.222,
                y: 857.96,
                r: 1.57049,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 310.059,
                y: 1065.882,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 280.15,
                y: 990.995,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 283.562,
                y: 1036.793,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 297.678,
                y: 1014.876,
                r: -0.00493,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 253.627,
                y: 884.631,
                r: -1.83008,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 281.354,
                y: 888.54,
                r: -1.83008,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 309.97,
                y: 887.968,
                r: -1.83008,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 292.401,
                y: 819.338,
                r: 0.79863,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 306.888,
                y: 848.826,
                r: 0.9018,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 326.861,
                y: 974.561,
                r: 1.22173,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 294.717,
                y: 991.359,
                r: 1.10476,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 25,
                x: 267.099,
                y: 959.02,
                r: 0.00131,
                sX: 32.49536,
                sY: 16.85697,
                m: true,
                b: false,
            },
        ],
    },
    {
        id: '18',
        objects: [
            {
                i: 3,
                x: 618.853,
                y: 1003.989,
                r: 0.0,
                sX: 40.50705,
                sY: 0.0,
                m: false,
                b: true,
            },
            {
                i: 1,
                x: 0.0,
                y: 943.215,
                r: 0.0,
                sX: 222.20204,
                sY: 62.624,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 412.842,
                y: 1006.428,
                r: -1.24346,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 396.96,
                y: 1052.28,
                r: -1.24346,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 398.881,
                y: 1027.426,
                r: -1.24346,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 420.666,
                y: 1028.122,
                r: -1.24346,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 412.866,
                y: 1049.283,
                r: -1.24346,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 5,
                x: 345.997,
                y: 1047.003,
                r: 1.5708,
                sX: 21.3082,
                sY: 39.84538,
                m: false,
                b: false,
            },
            {
                i: 5,
                x: 324.499,
                y: 1047.003,
                r: 1.5708,
                sX: 21.3082,
                sY: 39.84538,
                m: false,
                b: false,
            },
            {
                i: 5,
                x: 367.479,
                y: 1047.003,
                r: 1.5708,
                sX: 21.3082,
                sY: 39.84538,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '17',
        objects: [
            {
                i: 7,
                x: 432.941,
                y: 958.142,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 437.209,
                y: 949.098,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 446.009,
                y: 930.179,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 441.741,
                y: 939.222,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 423.983,
                y: 976.694,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 428.252,
                y: 967.651,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 419.452,
                y: 986.57,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 415.183,
                y: 995.614,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 423.983,
                y: 976.694,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 432.941,
                y: 958.142,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 428.252,
                y: 967.651,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 437.209,
                y: 949.098,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 419.452,
                y: 986.57,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 415.183,
                y: 995.614,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 446.009,
                y: 930.179,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 441.741,
                y: 939.222,
                r: 2.00713,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 35,
                x: 354.173,
                y: 965.642,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 335.825,
                y: 931.848,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 371.331,
                y: 918.723,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 338.429,
                y: 907.998,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 361.645,
                y: 943.875,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 323.991,
                y: 951.355,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 25,
                x: 409.711,
                y: 895.924,
                r: -1.19821,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 371.863,
                y: 951.16,
                r: 5.044,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 407.452,
                y: 963.497,
                r: 5.044,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 485.978,
                y: 934.65,
                r: 1.3354,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '16',
        objects: [
            {
                i: 35,
                x: 342.413,
                y: 881.148,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 363.389,
                y: 867.165,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 353.448,
                y: 838.406,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 370.488,
                y: 802.217,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 382.019,
                y: 787.333,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 370.043,
                y: 825.919,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 406.087,
                y: 805.871,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 391.56,
                y: 836.245,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 392.502,
                y: 861.684,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 370.557,
                y: 850.214,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 376.324,
                y: 887.559,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 387.624,
                y: 814.038,
                r: -4.27026,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 465.337,
                y: 867.461,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 468.674,
                y: 844.437,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 460.818,
                y: 914.689,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 460.187,
                y: 892.145,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 480.668,
                y: 884.688,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 481.299,
                y: 907.232,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 493.237,
                y: 860.868,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 499.748,
                y: 843.102,
                r: -0.98121,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 397.331,
                y: 778.751,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 22,
                x: 501.327,
                y: 822.286,
                r: 0.0,
                sX: 15.113,
                sY: 15.367,
                m: false,
                b: true,
            },
            {
                i: 22,
                x: 472.962,
                y: 827.901,
                r: 0.0,
                sX: 15.113,
                sY: 15.367,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 489.573,
                y: 821.219,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 470.22,
                y: 743.725,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 432.037,
                y: 755.645,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 420.762,
                y: 785.058,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 456.536,
                y: 761.652,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 488.728,
                y: 751.79,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 449.575,
                y: 738.823,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 486.086,
                y: 721.524,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 467.324,
                y: 723.11,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 429.151,
                y: 735.059,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 414.708,
                y: 761.842,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 390.218,
                y: 755.863,
                r: -3.44288,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 493.739,
                y: 776.234,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 427.579,
                y: 807.384,
                r: 5.97837,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 454.856,
                y: 778.58,
                r: 5.97837,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 473.161,
                y: 776.209,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 439.193,
                y: 789.116,
                r: 5.97837,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 418.35,
                y: 830.265,
                r: 5.73105,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 445.594,
                y: 848.281,
                r: 5.044,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 410.005,
                y: 835.944,
                r: 5.044,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 513.739,
                y: 776.258,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 533.712,
                y: 776.282,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 495.993,
                y: 809.521,
                r: 0.0,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 496.563,
                y: 778.172,
                r: 0.0,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 362.765,
                y: 762.859,
                r: 0.44082,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 341.252,
                y: 808.46,
                r: 0.44082,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 460.327,
                y: 705.812,
                r: 1.26819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 410.625,
                y: 721.329,
                r: 1.26819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 323.388,
                y: 775.256,
                r: 0.44082,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 414.435,
                y: 697.336,
                r: 1.26819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 361.422,
                y: 724.692,
                r: 0.82118,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '15',
        objects: [
            {
                i: 35,
                x: 847.373,
                y: 737.439,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 767.357,
                y: 749.152,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 877.18,
                y: 737.531,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 780.521,
                y: 762.146,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 828.219,
                y: 750.473,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 862.637,
                y: 750.653,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 902.637,
                y: 750.623,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 829.126,
                y: 726.608,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 850.752,
                y: 716.898,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 810.752,
                y: 716.928,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 748.984,
                y: 738.219,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 788.984,
                y: 738.189,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 767.388,
                y: 725.184,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 734.594,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 574.594,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 654.594,
                y: 821.814,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 694.546,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 774.594,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 814.594,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 854.594,
                y: 821.814,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 894.594,
                y: 821.844,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 934.594,
                y: 821.804,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 916.189,
                y: 834.838,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 876.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 836.189,
                y: 834.848,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 796.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 756.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 596.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 676.142,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 636.189,
                y: 834.848,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 556.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 716.189,
                y: 834.878,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 516.19,
                y: 834.313,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 534.563,
                y: 845.246,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 516.159,
                y: 858.281,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 716.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 556.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 636.158,
                y: 858.816,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 676.111,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 596.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 756.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 796.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 836.158,
                y: 858.816,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 876.158,
                y: 858.846,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 916.158,
                y: 858.806,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 934.562,
                y: 845.771,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 894.562,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 854.562,
                y: 845.781,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 814.562,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 774.562,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 614.563,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 694.515,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 654.562,
                y: 845.781,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 574.563,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 734.562,
                y: 845.811,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 515.527,
                y: 737.985,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 675.542,
                y: 726.221,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 595.527,
                y: 738.016,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 555.574,
                y: 737.985,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 635.527,
                y: 737.985,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 653.931,
                y: 724.951,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 573.978,
                y: 724.951,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 613.931,
                y: 724.981,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 693.931,
                y: 724.951,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 533.931,
                y: 724.951,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 733.931,
                y: 725.516,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 715.542,
                y: 726.787,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 533.9,
                y: 748.918,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 613.9,
                y: 748.948,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 573.947,
                y: 748.918,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 555.543,
                y: 761.953,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 595.496,
                y: 761.983,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 515.496,
                y: 761.953,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 833.712,
                y: 776.452,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 873.16,
                y: 776.5,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 913.16,
                y: 776.548,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 893.16,
                y: 776.524,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 853.16,
                y: 776.476,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 953.739,
                y: 776.597,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 933.739,
                y: 776.573,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 873.119,
                y: 805.918,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 893.119,
                y: 805.956,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 853.119,
                y: 805.907,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 833.119,
                y: 805.804,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 913.119,
                y: 806.071,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 913.119,
                y: 806.071,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 933.119,
                y: 806.109,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 953.119,
                y: 806.12,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 793.739,
                y: 776.403,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 813.739,
                y: 776.428,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 713.16,
                y: 776.306,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 753.16,
                y: 776.354,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 773.16,
                y: 776.379,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 733.16,
                y: 776.33,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 693.712,
                y: 776.283,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 813.119,
                y: 805.95,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 793.119,
                y: 805.939,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 773.119,
                y: 805.902,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 773.119,
                y: 805.902,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 693.119,
                y: 805.635,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 713.119,
                y: 805.738,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 753.119,
                y: 805.786,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 733.119,
                y: 805.748,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 653.739,
                y: 776.234,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 673.739,
                y: 776.258,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 573.16,
                y: 776.136,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 613.161,
                y: 776.185,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 633.161,
                y: 776.209,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 553.712,
                y: 776.113,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 673.119,
                y: 805.781,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 653.119,
                y: 805.77,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 633.119,
                y: 805.732,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 633.119,
                y: 805.732,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 553.119,
                y: 805.465,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 573.119,
                y: 805.568,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 613.119,
                y: 805.616,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 822.315,
                y: 779.43,
                r: 0.0,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 744.758,
                y: 803.047,
                r: 0.0,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 2,
                x: 798.753,
                y: 745.945,
                r: 0.0,
                sX: 45.14849,
                sY: 30.28949,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 667.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 677.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 687.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 697.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 707.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 717.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 737.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 727.064,
                y: 757.424,
                r: 0.0,
                sX: 30.35299,
                sY: 28.575,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 875.748,
                y: 772.704,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 875.415,
                y: 809.855,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 835.417,
                y: 809.486,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 835.749,
                y: 772.335,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 534.594,
                y: 821.278,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 635.921,
                y: 772.343,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 635.842,
                y: 810.01,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 595.726,
                y: 810.01,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 595.805,
                y: 772.343,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 555.652,
                y: 810.01,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 555.73,
                y: 772.343,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 822.686,
                y: 809.693,
                r: 0.0,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 745.993,
                y: 773.019,
                r: 0.0,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 883.595,
                y: 689.349,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 620.743,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 570.323,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 780.564,
                y: 693.119,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 731.718,
                y: 693.119,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 832.632,
                y: 693.119,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 521.477,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 772.077,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 721.657,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 672.811,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 821.979,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 870.825,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 921.245,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 921.245,
                y: 890.356,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 577.278,
                y: 693.441,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 528.432,
                y: 693.441,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 679.767,
                y: 693.441,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 629.346,
                y: 693.441,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 598.706,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 548.286,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 750.041,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 699.62,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 650.775,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 799.942,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 848.788,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 899.208,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 899.208,
                y: 906.03,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 743.059,
                y: 677.549,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 694.213,
                y: 677.549,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 846.091,
                y: 673.779,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 795.128,
                y: 677.549,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 539.774,
                y: 677.871,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 490.928,
                y: 677.871,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 642.262,
                y: 677.871,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 591.842,
                y: 677.871,
                r: 7.85398,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 25,
                x: 593.512,
                y: 791.234,
                r: 0.00131,
                sX: 32.49536,
                sY: 16.85697,
                m: true,
                b: false,
            },
        ],
    },
    {
        id: '14',
        objects: [
            {
                i: 20,
                x: 1144.859,
                y: 602.466,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 21,
                x: 1144.69,
                y: 646.201,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1012.465,
                y: 562.293,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1034.061,
                y: 575.298,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1135.814,
                y: 565.126,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1055.829,
                y: 554.036,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1095.829,
                y: 554.006,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1114.211,
                y: 558.084,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1074.203,
                y: 563.716,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1154.196,
                y: 569.152,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1126.11,
                y: 581.122,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1147.714,
                y: 587.732,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1107.714,
                y: 587.762,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1074.045,
                y: 587.582,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1051.44,
                y: 599.289,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1052.45,
                y: 574.548,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1012.434,
                y: 586.261,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1092.45,
                y: 574.548,
                r: 3.14291,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 958.053,
                y: 644.167,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 980.028,
                y: 600.498,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 978.971,
                y: 624.18,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 996.853,
                y: 612.757,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1102.513,
                y: 734.246,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1084.836,
                y: 739.29,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1015.865,
                y: 827.19,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1088.642,
                y: 776.153,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1051.344,
                y: 801.207,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1047.231,
                y: 825.503,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1071.25,
                y: 787.502,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1003.408,
                y: 841.295,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1006.606,
                y: 810.932,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1024.457,
                y: 805.395,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1050.472,
                y: 775.759,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1051.234,
                y: 750.314,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1075.797,
                y: 757.073,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1081.584,
                y: 722.564,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1022.397,
                y: 781.21,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 974.372,
                y: 844.827,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 983.25,
                y: 861.653,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 955.978,
                y: 849.708,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 956.009,
                y: 825.741,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 987.407,
                y: 816.735,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 974.403,
                y: 820.86,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1083.336,
                y: 609.404,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1063.888,
                y: 609.38,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1083.295,
                y: 638.835,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1063.295,
                y: 638.732,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 972.912,
                y: 660.132,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 955.031,
                y: 671.555,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 938.12,
                y: 683.611,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 981.783,
                y: 642.578,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 916.34,
                y: 773.002,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 915.951,
                y: 809.464,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 955.754,
                y: 772.432,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 955.421,
                y: 809.584,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 8,
                x: 991.951,
                y: 679.391,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 9,
                x: 1026.661,
                y: 695.493,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 8,
                x: 984.982,
                y: 702.618,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 8,
                x: 975.122,
                y: 730.276,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 9,
                x: 1020.096,
                y: 716.413,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 9,
                x: 1011.331,
                y: 741.989,
                r: -1.12944,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 935.791,
                y: 726.971,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 954.911,
                y: 698.898,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 937.03,
                y: 710.322,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 919.559,
                y: 709.579,
                r: 2.0387,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1074.18,
                y: 695.695,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1052.315,
                y: 708.242,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1060.309,
                y: 737.601,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1032.982,
                y: 768.157,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1050.531,
                y: 761.305,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1022.138,
                y: 737.208,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 971.796,
                y: 683.237,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1044.029,
                y: 724.675,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1053.251,
                y: 684.013,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1000.704,
                y: 789.556,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1003.852,
                y: 531.444,
                r: 7.28488,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 958.231,
                y: 742.724,
                r: -1.0472,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1003.965,
                y: 761.779,
                r: -1.5708,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1097.758,
                y: 807.011,
                r: 3.78538,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1067.494,
                y: 847.339,
                r: 3.78538,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1129.011,
                y: 765.366,
                r: 3.78538,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 970.764,
                y: 885.279,
                r: 4.50897,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1016.495,
                y: 869.561,
                r: 4.2337,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1051.184,
                y: 506.883,
                r: 7.65421,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1101.523,
                y: 501.428,
                r: 7.65421,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 946.377,
                y: 619.667,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 968.68,
                y: 574.448,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 923.346,
                y: 666.365,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1002.749,
                y: 898.21,
                r: 4.37795,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 951.696,
                y: 907.01,
                r: 4.62483,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1096.732,
                y: 841.697,
                r: 3.78538,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1056.655,
                y: 879.347,
                r: 4.41261,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1127.985,
                y: 800.051,
                r: 3.78538,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 25,
                x: 1103.027,
                y: 624.342,
                r: 0.0,
                sX: 32.49536,
                sY: 16.85697,
                m: true,
                b: false,
            },
            {
                i: 36,
                x: 908.01,
                y: 645.256,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 953.344,
                y: 553.339,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 931.042,
                y: 598.559,
                r: 0.45819,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 989.604,
                y: 512.034,
                r: 7.28488,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '13',
        objects: [
            {
                i: 35,
                x: 1165.275,
                y: 698.969,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1245.29,
                y: 687.255,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1205.275,
                y: 698.969,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1206.284,
                y: 674.228,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1183.679,
                y: 685.934,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1150.011,
                y: 685.755,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1110.011,
                y: 685.785,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1131.615,
                y: 692.394,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1103.528,
                y: 704.364,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1183.521,
                y: 709.8,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1143.514,
                y: 715.433,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1161.896,
                y: 719.51,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1201.896,
                y: 719.48,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1121.91,
                y: 708.39,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1223.663,
                y: 698.218,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1245.259,
                y: 711.223,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1143.336,
                y: 609.476,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1123.336,
                y: 609.452,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1183.915,
                y: 609.526,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1163.915,
                y: 609.501,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1123.295,
                y: 638.884,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1143.295,
                y: 639.0,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1143.295,
                y: 639.0,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1163.295,
                y: 639.037,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1183.295,
                y: 639.048,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1188.95,
                y: 641.721,
                r: -0.0,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 1189.036,
                y: 609.414,
                r: -4.9796,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1166.923,
                y: 731.968,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1223.547,
                y: 731.968,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1248.33,
                y: 746.573,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1191.706,
                y: 746.573,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '12',
        objects: [
            {
                i: 35,
                x: 1343.999,
                y: 583.94,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1424.015,
                y: 572.227,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1383.999,
                y: 583.94,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1402.419,
                y: 559.222,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1362.404,
                y: 570.905,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1328.735,
                y: 570.726,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1288.735,
                y: 570.756,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1310.339,
                y: 577.366,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1282.253,
                y: 589.335,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1362.246,
                y: 594.771,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1322.238,
                y: 600.404,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1340.62,
                y: 604.481,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1380.62,
                y: 604.451,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1300.635,
                y: 593.362,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1402.388,
                y: 583.19,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1423.984,
                y: 596.195,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1234.333,
                y: 517.102,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1166.0,
                y: 560.348,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1197.791,
                y: 533.372,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1191.018,
                y: 563.445,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1222.822,
                y: 536.496,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1253.652,
                y: 522.966,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1290.182,
                y: 506.669,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1267.757,
                y: 509.418,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1311.949,
                y: 507.578,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1213.259,
                y: 514.629,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1247.516,
                y: 493.211,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1229.065,
                y: 496.963,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1192.536,
                y: 513.26,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1270.117,
                y: 490.858,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1181.298,
                y: 541.537,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1156.279,
                y: 538.44,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1280.448,
                y: 526.151,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1320.448,
                y: 526.199,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1300.448,
                y: 526.175,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1341.027,
                y: 526.224,
                r: 6.2844,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1280.407,
                y: 555.569,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1300.407,
                y: 555.607,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1320.407,
                y: 555.722,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1320.407,
                y: 555.722,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1340.407,
                y: 555.76,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1302.201,
                y: 522.439,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1301.875,
                y: 558.902,
                r: -3.13237,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1211.305,
                y: 601.807,
                r: -4.27767,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1243.727,
                y: 616.852,
                r: -4.27767,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1204.542,
                y: 593.056,
                r: -1.16475,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1208.492,
                y: 583.869,
                r: -1.16475,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1216.392,
                y: 565.495,
                r: -1.16475,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1212.442,
                y: 574.682,
                r: -1.16475,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 902.67,
                y: 724.985,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 925.996,
                y: 744.519,
                r: -1.0617,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1229.173,
                y: 569.576,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1245.274,
                y: 533.564,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1237.111,
                y: 551.822,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1221.01,
                y: 587.835,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1223.639,
                y: 637.529,
                r: -0.30657,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1264.146,
                y: 563.945,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1247.723,
                y: 600.418,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1255.899,
                y: 582.166,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1239.572,
                y: 618.682,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1212.846,
                y: 606.093,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1203.295,
                y: 639.086,
                r: 0.00252,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1241.999,
                y: 634.625,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1230.467,
                y: 653.937,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1257.297,
                y: 615.813,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1274.807,
                y: 610.899,
                r: 2.72403,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1343.308,
                y: 559.405,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1343.308,
                y: 521.738,
                r: 6.28319,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1226.798,
                y: 564.591,
                r: 5.14872,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1260.936,
                y: 580.509,
                r: 5.14872,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1358.74,
                y: 628.26,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1308.32,
                y: 628.26,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1410.808,
                y: 628.26,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1372.847,
                y: 652.906,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1322.427,
                y: 652.906,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1451.324,
                y: 652.906,
                r: 4.71239,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1205.903,
                y: 472.688,
                r: 7.4351,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1255.032,
                y: 459.998,
                r: 7.4351,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1155.677,
                y: 487.895,
                r: 7.4351,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1276.18,
                y: 656.566,
                r: 3.65279,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1265.507,
                y: 694.713,
                r: 3.65279,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '11',
        objects: [
            {
                i: 33,
                x: 1418.126,
                y: 496.988,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.193,
                y: 481.988,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.059,
                y: 511.988,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1417.992,
                y: 526.988,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.026,
                y: 519.488,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.093,
                y: 504.488,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.227,
                y: 474.489,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1418.16,
                y: 489.488,
                r: 6124.53955,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1356.66,
                y: 516.184,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1364.188,
                y: 491.791,
                r: 5.13283,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1365.863,
                y: 549.462,
                r: -0.30657,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1391.495,
                y: 492.683,
                r: -1.55789,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1390.903,
                y: 515.372,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1381.261,
                y: 536.829,
                r: -1.14904,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1360.337,
                y: 507.709,
                r: -1.5708,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1382.194,
                y: 523.964,
                r: -1.5708,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
        ],
    },
    {
        id: '10',
        objects: [
            {
                i: 35,
                x: 1338.422,
                y: 250.062,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1358.415,
                y: 209.902,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1351.603,
                y: 234.32,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1327.318,
                y: 228.522,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1334.448,
                y: 210.061,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1314.455,
                y: 250.221,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1318.064,
                y: 439.812,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1331.244,
                y: 458.112,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1317.816,
                y: 479.815,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1341.784,
                y: 479.656,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1354.964,
                y: 463.914,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1342.031,
                y: 439.653,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1342.031,
                y: 439.653,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1354.964,
                y: 463.914,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1341.784,
                y: 479.656,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1317.816,
                y: 479.815,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1331.244,
                y: 458.112,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1318.064,
                y: 439.812,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 329.816,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 290.368,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 270.368,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 310.368,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 369.816,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 450.395,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 410.395,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 390.395,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 430.395,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1363.95,
                y: 469.843,
                r: 4.71239,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.578,
                y: 270.401,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.592,
                y: 290.401,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.578,
                y: 310.401,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.473,
                y: 350.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.487,
                y: 330.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.486,
                y: 370.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.473,
                y: 390.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.473,
                y: 390.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.368,
                y: 470.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.381,
                y: 450.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.381,
                y: 410.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1393.368,
                y: 430.4,
                r: -1.56948,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1385.935,
                y: 390.541,
                r: -1.5708,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1334.212,
                y: 266.667,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.503,
                y: 304.617,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.821,
                y: 344.616,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.662,
                y: 324.617,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.345,
                y: 284.744,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.411,
                y: 294.744,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.728,
                y: 334.616,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.569,
                y: 314.617,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1334.278,
                y: 276.667,
                r: 4.70445,
                sX: 37.48977,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.2,
                y: 355.786,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.267,
                y: 366.652,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.22,
                y: 376.652,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.349,
                y: 397.082,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.395,
                y: 387.082,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 7,
                x: 1340.415,
                y: 407.948,
                r: 7.85398,
                sX: 21.80675,
                sY: 16.72676,
                m: true,
                b: true,
            },
            {
                i: 31,
                x: 1366.904,
                y: 361.482,
                r: -1.5708,
                sX: 9.927,
                sY: 16.87591,
                m: false,
                b: true,
            },
            {
                i: 13,
                x: 1397.836,
                y: 417.998,
                r: -7.85646,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 15,
                x: 1397.588,
                y: 367.995,
                r: -7.85646,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 19,
                x: 1397.752,
                y: 468.003,
                r: -7.85646,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 17,
                x: 1397.427,
                y: 318.003,
                r: -1.57327,
                sX: 6.01705,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 16,
                x: 1359.511,
                y: 318.022,
                r: -1.57327,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 14,
                x: 1359.781,
                y: 367.941,
                r: -1.57327,
                sX: 6.01705,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 12,
                x: 1359.96,
                y: 417.974,
                r: -1.57327,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 18,
                x: 1360.072,
                y: 467.915,
                r: -1.57327,
                sX: 6.01705,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1397.256,
                y: 328.608,
                r: 4.71239,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1359.589,
                y: 328.608,
                r: 4.71239,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1397.29,
                y: 400.041,
                r: 1.5708,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1360.062,
                y: 400.041,
                r: 1.5708,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1359.589,
                y: 449.955,
                r: 4.71239,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1397.256,
                y: 449.955,
                r: 4.71239,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1360.406,
                y: 383.292,
                r: -1.5708,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1298.969,
                y: 462.4,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1297.783,
                y: 313.08,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1298.183,
                y: 363.498,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1298.571,
                y: 412.343,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1297.381,
                y: 262.472,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1288.603,
                y: 451.263,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '9',
        objects: [
            {
                i: 35,
                x: 1381.976,
                y: 139.616,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1417.945,
                y: 124.887,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1424.506,
                y: 148.145,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1371.041,
                y: 155.981,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1341.613,
                y: 183.631,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1349.054,
                y: 162.341,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1362.922,
                y: 181.89,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1387.77,
                y: 172.139,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1422.806,
                y: 167.177,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1441.309,
                y: 137.699,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1397.325,
                y: 161.253,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1440.451,
                y: 163.944,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1422.806,
                y: 167.177,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1323.699,
                y: 193.118,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1408.0,
                y: 147.953,
                r: -0.61699,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1314.455,
                y: 250.221,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1334.448,
                y: 210.061,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1327.318,
                y: 228.522,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1351.603,
                y: 234.32,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1358.415,
                y: 209.902,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1338.422,
                y: 250.062,
                r: -1.57742,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1505.722,
                y: 241.837,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1499.294,
                y: 234.177,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1486.438,
                y: 218.856,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1492.866,
                y: 226.517,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1473.582,
                y: 203.535,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1480.01,
                y: 211.196,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1467.154,
                y: 195.875,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1460.727,
                y: 188.214,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1413.781,
                y: 177.834,
                r: 0.0,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1413.721,
                y: 218.055,
                r: 1.07241,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1470.271,
                y: 165.756,
                r: 5.55725,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1535.928,
                y: 239.725,
                r: 5.55725,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1503.502,
                y: 203.194,
                r: 5.55725,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1296.98,
                y: 211.939,
                r: 0.0,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1498.897,
                y: 175.392,
                r: -0.72052,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1532.128,
                y: 212.831,
                r: -0.69813,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 25,
                x: 1378.05,
                y: 248.698,
                r: 1.57012,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1320.458,
                y: 144.624,
                r: -1.99855,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1362.754,
                y: 120.37,
                r: -1.99855,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1410.347,
                y: 97.378,
                r: -1.99855,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1404.342,
                y: 117.068,
                r: 1.21053,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1356.749,
                y: 140.06,
                r: 0.95733,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1314.453,
                y: 164.313,
                r: 0.95733,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1457.623,
                y: 110.755,
                r: -1.27669,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '8',
        objects: [
            {
                i: 11,
                x: 1557.579,
                y: 294.107,
                r: -0.71556,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1541.796,
                y: 327.591,
                r: -1.07598,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1479.42,
                y: 278.757,
                r: -2.27058,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1507.198,
                y: 255.369,
                r: -2.27058,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1395.399,
                y: 499.652,
                r: -4.70316,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1360.319,
                y: 499.339,
                r: -4.70316,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1519.184,
                y: 352.98,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1576.734,
                y: 335.979,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1555.795,
                y: 349.86,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1578.255,
                y: 314.653,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1518.577,
                y: 257.158,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 4,
                x: 1512.15,
                y: 249.498,
                r: 0.87266,
                sX: 9.06457,
                sY: 14.20696,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1534.532,
                y: 268.083,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1505.711,
                y: 347.283,
                r: 2.64417,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1487.734,
                y: 314.173,
                r: 2.64417,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1531.452,
                y: 283.93,
                r: 0.95993,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1604.232,
                y: 301.59,
                r: -0.61087,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1569.398,
                y: 277.433,
                r: -0.66267,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1564.554,
                y: 249.362,
                r: -0.72052,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '7',
        objects: [
            {
                i: 35,
                x: 1530.241,
                y: 406.601,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1501.811,
                y: 376.103,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1532.167,
                y: 381.466,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1510.736,
                y: 392.198,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 1452.884,
                y: 447.581,
                r: -2.00585,
                sX: 30.35299,
                sY: 28.575,
                m: true,
                b: true,
            },
            {
                i: 35,
                x: 1420.326,
                y: 392.839,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1414.825,
                y: 438.193,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1430.823,
                y: 400.979,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1421.302,
                y: 363.854,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1416.536,
                y: 416.742,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1458.399,
                y: 326.613,
                r: -0.63605,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 6,
                x: 1448.669,
                y: 438.513,
                r: -2.00585,
                sX: 30.35299,
                sY: 28.575,
                m: true,
                b: true,
            },
            {
                i: 6,
                x: 1440.24,
                y: 420.376,
                r: -2.00585,
                sX: 30.35299,
                sY: 28.575,
                m: true,
                b: true,
            },
            {
                i: 6,
                x: 1444.455,
                y: 429.444,
                r: -2.00585,
                sX: 30.35299,
                sY: 28.575,
                m: true,
                b: true,
            },
            {
                i: 13,
                x: 1505.781,
                y: 514.891,
                r: -5.17659,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 15,
                x: 1528.279,
                y: 559.547,
                r: -5.17659,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 19,
                x: 1483.579,
                y: 470.085,
                r: -5.17659,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 12,
                x: 1536.856,
                y: 499.529,
                r: 1.11003,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 14,
                x: 1559.152,
                y: 544.319,
                r: 1.11003,
                sX: 6.01705,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 18,
                x: 1514.66,
                y: 454.79,
                r: 1.11003,
                sX: 6.01705,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1454.596,
                y: 415.413,
                r: 1.13446,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1488.11,
                y: 399.785,
                r: 1.13446,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1438.109,
                y: 350.982,
                r: 1.8326,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1438.189,
                y: 370.702,
                r: 1.13446,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 1467.044,
                y: 369.466,
                r: -4.9796,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1555.35,
                y: 398.527,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1287.015,
                y: 251.334,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1287.417,
                y: 301.942,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1287.817,
                y: 352.36,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1288.205,
                y: 401.205,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1286.614,
                y: 200.801,
                r: 3.13365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1600.444,
                y: 344.879,
                r: 0.04066,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '6',
        objects: [
            {
                i: 33,
                x: 1551.761,
                y: 469.793,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1533.805,
                y: 434.05,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1542.783,
                y: 451.921,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1560.682,
                y: 487.552,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1556.205,
                y: 478.61,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1538.306,
                y: 442.98,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1547.284,
                y: 460.851,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1565.21,
                y: 496.555,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 35,
                x: 1618.984,
                y: 556.962,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1636.94,
                y: 592.705,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1622.832,
                y: 579.207,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1635.014,
                y: 617.841,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1599.075,
                y: 546.368,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1581.623,
                y: 510.372,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1601.533,
                y: 520.966,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1580.102,
                y: 531.698,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1560.192,
                y: 521.103,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1528.72,
                y: 427.927,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1508.811,
                y: 417.333,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1577.644,
                y: 557.1,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1613.583,
                y: 628.572,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1615.51,
                y: 603.437,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1597.553,
                y: 567.694,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1636.165,
                y: 702.368,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1627.169,
                y: 684.505,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1680.896,
                y: 791.188,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1698.888,
                y: 826.913,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1689.892,
                y: 809.051,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1671.9,
                y: 773.326,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1645.161,
                y: 720.23,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1654.157,
                y: 738.093,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1717.14,
                y: 863.155,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1708.145,
                y: 845.293,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1734.884,
                y: 898.388,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1726.136,
                y: 881.018,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1654.62,
                y: 804.415,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1663.603,
                y: 822.284,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1645.612,
                y: 786.559,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1618.73,
                y: 732.918,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1627.714,
                y: 750.786,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1609.722,
                y: 715.061,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1600.738,
                y: 697.193,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1600.738,
                y: 697.193,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1672.518,
                y: 840.188,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1672.518,
                y: 840.188,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1681.502,
                y: 858.056,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1699.493,
                y: 893.782,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1690.509,
                y: 875.913,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1708.408,
                y: 911.685,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 32,
                x: 1527.915,
                y: 545.229,
                r: 1.13446,
                sX: 9.927,
                sY: 16.87591,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1600.293,
                y: 587.307,
                r: -2.08916,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1467.163,
                y: 473.531,
                r: -5.23599,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1495.475,
                y: 532.569,
                r: -5.23599,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1459.064,
                y: 520.388,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1460.957,
                y: 495.234,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1478.999,
                y: 530.934,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1496.537,
                y: 566.888,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1476.602,
                y: 556.342,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1498.007,
                y: 545.559,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1517.942,
                y: 556.105,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1500.404,
                y: 520.151,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1445.609,
                y: 458.177,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1482.362,
                y: 484.451,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1459.281,
                y: 467.659,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1480.469,
                y: 509.604,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1504.31,
                y: 435.173,
                r: -2.00713,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1471.266,
                y: 450.593,
                r: -2.00713,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1509.387,
                y: 573.519,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1526.496,
                y: 607.395,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1544.538,
                y: 643.095,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1535.517,
                y: 625.245,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1517.532,
                y: 589.658,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1522.031,
                y: 598.589,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1540.016,
                y: 634.176,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1530.995,
                y: 616.326,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1513.886,
                y: 582.45,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1474.133,
                y: 370.932,
                r: -1.8845,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1441.525,
                y: 384.034,
                r: -1.7631,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1492.746,
                y: 417.59,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1483.75,
                y: 399.727,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1519.733,
                y: 471.178,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1537.477,
                y: 506.411,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1555.469,
                y: 542.136,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1546.473,
                y: 524.273,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1528.481,
                y: 488.548,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1501.742,
                y: 435.453,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1510.738,
                y: 453.315,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1573.721,
                y: 578.378,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1591.465,
                y: 613.611,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1609.457,
                y: 649.336,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1582.717,
                y: 596.241,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1511.201,
                y: 519.638,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1520.184,
                y: 537.506,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1502.193,
                y: 501.781,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1493.209,
                y: 483.912,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1475.311,
                y: 448.14,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1484.294,
                y: 466.009,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1466.302,
                y: 430.284,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1457.319,
                y: 412.415,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1457.319,
                y: 412.415,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1529.099,
                y: 555.41,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1529.099,
                y: 555.41,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1556.074,
                y: 609.004,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1547.09,
                y: 591.135,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1564.989,
                y: 626.908,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1582.98,
                y: 662.633,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 25,
                x: 1587.44,
                y: 638.167,
                r: 1.11473,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1564.725,
                y: 560.515,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1538.083,
                y: 573.279,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1490.356,
                y: 486.157,
                r: 1.13446,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1523.87,
                y: 470.53,
                r: 1.13446,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1569.183,
                y: 566.496,
                r: 1.13446,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1547.235,
                y: 580.706,
                r: 1.07241,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1668.393,
                y: 623.543,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1600.457,
                y: 488.314,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1622.384,
                y: 531.961,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1645.019,
                y: 577.016,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1577.985,
                y: 443.582,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1477.756,
                y: 588.991,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1455.724,
                y: 545.396,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1500.335,
                y: 633.669,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1583.422,
                y: 413.5,
                r: -0.46343,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1650.907,
                y: 548.955,
                r: -0.46343,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1629.125,
                y: 505.234,
                r: -0.46343,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1606.641,
                y: 460.105,
                r: -0.46343,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1673.23,
                y: 593.762,
                r: -0.46343,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1523.078,
                y: 678.669,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '5',
        objects: [
            {
                i: 23,
                x: 1618.453,
                y: 667.198,
                r: 7.38744,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1591.964,
                y: 680.502,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1636.639,
                y: 768.684,
                r: 1.10557,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1569.926,
                y: 678.353,
                r: -5.23599,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 1625.322,
                y: 642.313,
                r: 1.07338,
                sX: 21.79439,
                sY: 16.71728,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 1630.093,
                y: 651.101,
                r: 1.07338,
                sX: 21.79439,
                sY: 16.71728,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 1634.865,
                y: 659.889,
                r: 1.07338,
                sX: 21.79439,
                sY: 16.71728,
                m: false,
                b: true,
            },
            {
                i: 7,
                x: 1639.636,
                y: 668.677,
                r: 1.07338,
                sX: 21.79439,
                sY: 16.71728,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1673.116,
                y: 664.715,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1691.073,
                y: 700.458,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1655.665,
                y: 628.718,
                r: -5.17662,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1597.355,
                y: 737.901,
                r: -5.23599,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1591.001,
                y: 729.296,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1548.168,
                y: 670.535,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1560.198,
                y: 709.263,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1549.106,
                y: 694.262,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1574.778,
                y: 726.119,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1589.804,
                y: 756.249,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1604.028,
                y: 769.672,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1596.15,
                y: 715.495,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1615.656,
                y: 750.457,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1590.825,
                y: 697.589,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1549.638,
                y: 649.206,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1569.573,
                y: 659.752,
                r: -2.03743,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 34,
                x: 1725.167,
                y: 833.256,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1740.387,
                y: 863.594,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1717.585,
                y: 817.683,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1732.805,
                y: 848.021,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1729.015,
                y: 840.235,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1713.794,
                y: 809.896,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1721.376,
                y: 825.47,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1736.596,
                y: 855.808,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1706.606,
                y: 794.27,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1691.386,
                y: 763.932,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1683.805,
                y: 748.359,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1699.025,
                y: 778.697,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1702.816,
                y: 786.483,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1687.596,
                y: 756.145,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1695.177,
                y: 771.718,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 1710.397,
                y: 802.056,
                r: 1.11774,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1653.731,
                y: 859.153,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1689.815,
                y: 930.553,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1671.773,
                y: 894.853,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1662.752,
                y: 877.003,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1680.446,
                y: 912.013,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1635.689,
                y: 823.453,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1617.405,
                y: 787.275,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1644.816,
                y: 841.514,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1626.774,
                y: 805.814,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1631.273,
                y: 814.745,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1613.231,
                y: 779.045,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1649.315,
                y: 850.445,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1621.903,
                y: 796.206,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1640.187,
                y: 832.384,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1684.944,
                y: 920.944,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1667.251,
                y: 885.934,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1676.272,
                y: 903.784,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1658.229,
                y: 868.084,
                r: 7.38604,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1663.623,
                y: 702.721,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1654.645,
                y: 684.849,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1672.545,
                y: 720.479,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1677.022,
                y: 729.421,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1659.122,
                y: 693.791,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1650.144,
                y: 675.919,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1668.101,
                y: 711.662,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1681.549,
                y: 738.425,
                r: 1.10525,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 36,
                x: 1636.958,
                y: 913.761,
                r: 3.03807,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1637.435,
                y: 775.171,
                r: 1.13446,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 1660.972,
                y: 762.592,
                r: -5.18471,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1690.32,
                y: 667.19,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1712.955,
                y: 712.245,
                r: -0.46644,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1567.618,
                y: 766.801,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1545.11,
                y: 722.264,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1615.19,
                y: 860.93,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1512.42,
                y: 699.827,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1536.324,
                y: 747.124,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1559.992,
                y: 793.956,
                r: 2.67365,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1781.295,
                y: 866.355,
                r: -0.87218,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '4',
        objects: [
            {
                i: 21,
                x: 1717.939,
                y: 946.533,
                r: 1.57993,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1768.899,
                y: 978.206,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1766.874,
                y: 1001.01,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1765.489,
                y: 1015.389,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1773.017,
                y: 961.163,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1765.007,
                y: 919.343,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1782.369,
                y: 988.799,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1765.033,
                y: 1031.342,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1777.2,
                y: 942.819,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1668.302,
                y: 994.087,
                r: 1.5708,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1701.458,
                y: 969.09,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1695.91,
                y: 950.806,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1681.958,
                y: 973.935,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1655.71,
                y: 982.858,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1659.624,
                y: 968.016,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1676.409,
                y: 955.65,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1769.809,
                y: 932.944,
                r: -4.95086,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 20,
                x: 1758.144,
                y: 946.546,
                r: -4.70326,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1805.146,
                y: 1031.507,
                r: 0.0714,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1629.933,
                y: 968.637,
                r: 3.52548,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1757.093,
                y: 977.615,
                r: 1.61488,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1725.19,
                y: 978.19,
                r: 1.78717,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1797.494,
                y: 1007.337,
                r: -0.01788,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1796.592,
                y: 956.925,
                r: -0.01788,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1795.719,
                y: 908.087,
                r: -0.01788,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1808.743,
                y: 981.215,
                r: 0.0714,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1812.227,
                y: 932.494,
                r: 0.0714,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1790.435,
                y: 1062.811,
                r: 0.0714,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '3',
        objects: [
            {
                i: 28,
                x: 1587.624,
                y: 1144.923,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1587.614,
                y: 1108.458,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.035,
                y: 1067.623,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.016,
                y: 1082.623,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.074,
                y: 1037.623,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.054,
                y: 1052.623,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1592.05,
                y: 1089.752,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1613.653,
                y: 1096.794,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.045,
                y: 1060.123,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.025,
                y: 1075.123,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.064,
                y: 1045.123,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1620.083,
                y: 1030.123,
                r: -161.79073,
                sX: 37.48978,
                sY: 19.97958,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 740.016,
                y: 1072.756,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 939.861,
                y: 1188.824,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1099.861,
                y: 1188.824,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1019.861,
                y: 1188.854,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 979.908,
                y: 1188.824,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1059.861,
                y: 1188.824,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 918.266,
                y: 1177.845,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1078.265,
                y: 1177.845,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 998.313,
                y: 1177.845,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1038.265,
                y: 1177.875,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1118.265,
                y: 1177.845,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 958.266,
                y: 1177.845,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1158.265,
                y: 1178.41,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1139.861,
                y: 1189.389,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1139.892,
                y: 1167.477,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1158.296,
                y: 1154.443,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 958.297,
                y: 1153.877,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1118.296,
                y: 1153.877,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1038.296,
                y: 1153.907,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 998.344,
                y: 1153.877,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1078.296,
                y: 1153.877,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 918.297,
                y: 1153.877,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1059.892,
                y: 1166.912,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 979.939,
                y: 1166.912,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1019.892,
                y: 1166.942,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1099.892,
                y: 1166.912,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 939.892,
                y: 1166.912,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1709.191,
                y: 1077.635,
                r: -155.50755,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1709.21,
                y: 1062.635,
                r: -155.50755,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1709.229,
                y: 1047.635,
                r: -155.50755,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1709.22,
                y: 1055.135,
                r: -155.50755,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1709.2,
                y: 1070.135,
                r: -155.50755,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 35,
                x: 1733.806,
                y: 1051.557,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1749.237,
                y: 1041.92,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
        ],
    },
    {
        id: '2',
        objects: [
            {
                i: 10,
                x: 1550.081,
                y: 1145.388,
                r: 3.15922,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 31,
                x: 1399.81,
                y: 1139.804,
                r: -3.14028,
                sX: 9.927,
                sY: 16.87591,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1237.93,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1217.93,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1198.057,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 35,
                x: 1673.721,
                y: 1122.269,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1093.577,
                y: 669.839,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1075.195,
                y: 665.813,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1119.112,
                y: 673.731,
                r: 0.00131,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1704.909,
                y: 1109.246,
                r: 3.14159,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1686.744,
                y: 1154.746,
                r: 3.92699,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1641.862,
                y: 1156.931,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1619.609,
                y: 1145.652,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1619.609,
                y: 1107.671,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1555.857,
                y: 1106.959,
                r: 3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1699.038,
                y: 1180.492,
                r: 0.37285,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1720.122,
                y: 1138.111,
                r: 0.28357,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1646.495,
                y: 1187.055,
                r: 4.17754,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 35,
                x: 1685.355,
                y: 1106.094,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1664.223,
                y: 1142.331,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
        ],
    },
    {
        id: '1',
        objects: [
            {
                i: 25,
                x: 1253.934,
                y: 1126.864,
                r: 3.14092,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: true,
            },
            {
                i: 10,
                x: 1450.081,
                y: 1145.388,
                r: 3.15922,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 10,
                x: 1500.081,
                y: 1145.388,
                r: 3.15922,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 25,
                x: 1002.521,
                y: 1127.034,
                r: 0.0,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 739.985,
                y: 1096.723,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1772.459,
                y: 895.27,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1749.199,
                y: 901.049,
                r: -4.95588,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1644.91,
                y: 1018.109,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1645.318,
                y: 1003.441,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1624.208,
                y: 1020.811,
                r: -1.1856,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 0,
                x: 1254.784,
                y: 1124.705,
                r: 0.0,
                sX: 66.9591,
                sY: 25.0,
                m: false,
                b: true,
            },
            {
                i: 19,
                x: 1550.004,
                y: 1108.273,
                r: -3.14028,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 13,
                x: 1500.0,
                y: 1108.0,
                r: -3.14028,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1533.668,
                y: 1085.704,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1552.064,
                y: 1079.065,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1580.151,
                y: 1067.095,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1500.158,
                y: 1061.659,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1540.165,
                y: 1056.026,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1521.783,
                y: 1051.949,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1481.783,
                y: 1051.979,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1561.769,
                y: 1063.069,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1340.015,
                y: 1073.241,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1420.015,
                y: 1073.271,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1380.062,
                y: 1073.241,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1460.015,
                y: 1073.241,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1300.015,
                y: 1073.241,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1260.015,
                y: 1073.241,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1278.42,
                y: 1060.206,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1318.42,
                y: 1060.206,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1398.467,
                y: 1060.206,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1438.42,
                y: 1060.236,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1358.42,
                y: 1060.206,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1358.389,
                y: 1084.174,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1518.404,
                y: 1072.49,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1438.389,
                y: 1084.204,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1398.436,
                y: 1084.174,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1478.404,
                y: 1072.49,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1318.389,
                y: 1084.174,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1278.389,
                y: 1084.174,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1259.984,
                y: 1097.208,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1299.984,
                y: 1097.208,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1459.984,
                y: 1097.208,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1380.031,
                y: 1097.208,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1419.984,
                y: 1097.238,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1500.0,
                y: 1085.525,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1339.984,
                y: 1097.208,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1274.089,
                y: 1155.113,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1295.694,
                y: 1160.908,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1255.684,
                y: 1168.147,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1318.011,
                y: 1157.964,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1357.442,
                y: 1154.651,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1375.834,
                y: 1150.903,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1335.826,
                y: 1156.536,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1387.733,
                y: 1173.942,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1369.337,
                y: 1180.581,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1335.668,
                y: 1180.402,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1295.663,
                y: 1184.875,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1314.058,
                y: 1178.415,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1274.057,
                y: 1179.08,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1345.822,
                y: 1167.356,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1430.25,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1470.25,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1409.477,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1450.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1589.864,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1549.24,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1510.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1610.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1530.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1570.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1490.013,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1500.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1580.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1540.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1620.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1520.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1559.24,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1599.864,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1460.013,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1419.477,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1480.25,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1400.25,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1440.25,
                y: 1169.956,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 15,
                x: 1449.997,
                y: 1108.058,
                r: -3.14028,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1304.212,
                y: 1107.671,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1304.212,
                y: 1145.652,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1320.844,
                y: 1144.356,
                r: 3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 1060.032,
                y: 1135.757,
                r: -3.14159,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 1318.819,
                y: 1118.568,
                r: -3.07723,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 26,
                x: 1554.517,
                y: 1140.785,
                r: -3.14159,
                sX: 15.77469,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 1503.663,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1554.083,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1352.329,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1402.749,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1451.595,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1302.427,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1253.581,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1529.444,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1579.864,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1378.11,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1428.53,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1477.376,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1328.208,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1279.362,
                y: 1009.043,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1270.0,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1318.846,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1368.748,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1290.157,
                y: 1223.93,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1339.003,
                y: 1223.93,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1388.904,
                y: 1223.93,
                r: 0.99823,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1440.18,
                y: 1210.022,
                r: 3.83678,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
    {
        id: '0',
        objects: [
            {
                i: 30,
                x: 751.199,
                y: 1126.293,
                r: 3.14291,
                sX: 32.49396,
                sY: 16.85625,
                m: false,
                b: true,
            },
            {
                i: 34,
                x: 749.997,
                y: 1163.083,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 789.997,
                y: 1163.058,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 829.997,
                y: 1163.03,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 869.998,
                y: 1165.348,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 909.998,
                y: 1165.319,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1179.979,
                y: 1169.946,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 24,
                x: 970.0,
                y: 1111.51,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 950.0,
                y: 1111.496,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 930.0,
                y: 1111.51,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 910.0,
                y: 1111.496,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1165.584,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1145.584,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1105.584,
                y: 1111.753,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1125.584,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1045.584,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1025.584,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1065.584,
                y: 1111.648,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1085.584,
                y: 1111.662,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1165.584,
                y: 1111.767,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1205.584,
                y: 1111.872,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1185.584,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1225.584,
                y: 1111.858,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1245.584,
                y: 1111.872,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1285.584,
                y: 1111.977,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1265.584,
                y: 1111.963,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1305.584,
                y: 1111.963,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1325.584,
                y: 1111.977,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1325.584,
                y: 1111.977,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 930.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 910.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 950.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 970.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1045.552,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1025.552,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1065.552,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1105.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1085.552,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1145.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1125.0,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1245.027,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1205.579,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1165.579,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1185.579,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1225.579,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1285.027,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1265.027,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1305.027,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1325.027,
                y: 1141.29,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 34,
                x: 729.997,
                y: 1163.096,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 769.997,
                y: 1163.072,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 809.997,
                y: 1163.044,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 23,
                x: 764.936,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 804.936,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 784.936,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 885.515,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 845.515,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 825.515,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 865.515,
                y: 1141.487,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 785.521,
                y: 1111.964,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 765.521,
                y: 1111.95,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 805.521,
                y: 1111.95,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 825.521,
                y: 1111.964,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 825.521,
                y: 1111.964,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 885.521,
                y: 1112.055,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 845.521,
                y: 1112.055,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 865.521,
                y: 1112.069,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1490.0,
                y: 1142.025,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1470.0,
                y: 1142.025,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1510.0,
                y: 1142.025,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1530.0,
                y: 1142.025,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1425.515,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1385.515,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1405.515,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1445.515,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1344.936,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 23,
                x: 1364.936,
                y: 1142.222,
                r: 3.14159,
                sX: 3.70197,
                sY: 20.03424,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1530.0,
                y: 1112.245,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1510.0,
                y: 1112.232,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1470.0,
                y: 1112.232,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1490.0,
                y: 1112.245,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1425.52,
                y: 1112.804,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1405.52,
                y: 1112.791,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1445.52,
                y: 1112.791,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1385.52,
                y: 1112.699,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1385.52,
                y: 1112.699,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1365.52,
                y: 1112.685,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 24,
                x: 1345.52,
                y: 1112.699,
                r: -3.14028,
                sX: 3.70197,
                sY: 20.0342,
                m: false,
                b: true,
            },
            {
                i: 25,
                x: 1731.372,
                y: 925.718,
                r: 1.11473,
                sX: 32.49536,
                sY: 16.85697,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 896.097,
                y: 1096.27,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1172.286,
                y: 1098.69,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1233.992,
                y: 1097.892,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 11,
                x: 1572.634,
                y: 1094.797,
                r: 0.0,
                sX: 28.044,
                sY: 25.137,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1163.952,
                y: 1147.264,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 27,
                x: 815.558,
                y: 1117.237,
                r: -3.07723,
                sX: 15.77467,
                sY: 16.5995,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1220.015,
                y: 1073.271,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1180.021,
                y: 1068.939,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1140.015,
                y: 1073.281,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1158.42,
                y: 1060.246,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1198.428,
                y: 1054.286,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1238.42,
                y: 1060.236,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 958.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1118.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1038.42,
                y: 1059.711,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 998.467,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1078.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 918.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 878.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 838.42,
                y: 1059.711,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 798.42,
                y: 1059.681,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 758.42,
                y: 1059.721,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 780.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 820.016,
                y: 1072.746,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 860.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 900.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1060.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 980.063,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1020.016,
                y: 1072.746,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1100.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 940.016,
                y: 1072.716,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 28,
                x: 1163.952,
                y: 1107.791,
                r: 0.0,
                sX: 6.01704,
                sY: 3.2492,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1198.389,
                y: 1084.174,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1158.389,
                y: 1084.214,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1139.984,
                y: 1097.248,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1211.591,
                y: 1097.227,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 939.985,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1099.985,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1019.985,
                y: 1096.713,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 980.032,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1059.985,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 926.73,
                y: 1096.718,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 859.985,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 819.985,
                y: 1096.713,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 779.985,
                y: 1096.683,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 758.389,
                y: 1083.688,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 798.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 838.389,
                y: 1083.678,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 878.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 918.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1078.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 998.436,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1038.389,
                y: 1083.678,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 1118.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 35,
                x: 958.389,
                y: 1083.648,
                r: -3.14028,
                sX: 20.82202,
                sY: 34.41647,
                m: false,
                b: true,
            },
            {
                i: 33,
                x: 1188.057,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1227.93,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1247.93,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1207.93,
                y: 1169.943,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 1169.979,
                y: 1169.932,
                r: 3.14159,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 899.998,
                y: 1165.326,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 859.998,
                y: 1165.355,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 889.998,
                y: 1165.333,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 849.998,
                y: 1165.362,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 879.998,
                y: 1165.34,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 33,
                x: 839.998,
                y: 1165.369,
                r: 3.14092,
                sX: 37.48978,
                sY: 19.97958,
                m: true,
                b: true,
            },
            {
                i: 29,
                x: 1084.101,
                y: 1107.671,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 29,
                x: 1084.101,
                y: 1147.315,
                r: 3.14159,
                sX: 6.55778,
                sY: 3.2412,
                m: false,
                b: true,
            },
            {
                i: 34,
                x: 719.997,
                y: 1163.083,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 759.997,
                y: 1163.059,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 799.997,
                y: 1163.031,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 699.997,
                y: 1163.1,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 819.997,
                y: 1163.017,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 779.997,
                y: 1163.045,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 739.997,
                y: 1163.069,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 34,
                x: 709.997,
                y: 1163.114,
                r: 3.14092,
                sX: 37.48976,
                sY: 37.27916,
                m: true,
                b: true,
            },
            {
                i: 37,
                x: 814.561,
                y: 1143.248,
                r: 3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 37,
                x: 1060.156,
                y: 1109.156,
                r: 3.14159,
                sX: 4.8735,
                sY: 12.825,
                m: false,
                b: true,
            },
            {
                i: 36,
                x: 753.333,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 803.754,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 852.599,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1001.767,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 952.921,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 902.501,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1153.102,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1104.256,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1053.835,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1203.161,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 753.333,
                y: 1028.171,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 778.895,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 778.895,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 829.315,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 878.161,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1027.328,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 978.483,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 928.062,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1178.663,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1129.817,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1079.397,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1228.723,
                y: 1009.469,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1219.58,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1070.254,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1120.675,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1169.521,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 918.92,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 969.34,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1018.186,
                y: 1205.142,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1236.716,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1087.39,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1137.811,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1186.656,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 936.056,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 986.476,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
            {
                i: 36,
                x: 1035.322,
                y: 1224.322,
                r: 1.5708,
                sX: 50.0,
                sY: 25.0,
                m: false,
                b: false,
            },
        ],
    },
]
