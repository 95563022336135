<script>
    import GameInstance from './GameInstance/GameInstance.svelte'
    import { GameSettings } from '../settings/GameSettings'
    import SoundEngine from './Sound/SoundEngine.svelte'
    import { onMount } from 'svelte/internal'

    let soundEngine
    let gameInstance

    let oxxoLogo

    let isHidden = false
    let isPortrait = false

    const logoWidth = 541 // Original 1082px
    const logoHeight = 104 // Original 208px

    // Logo position and size is based on this screen size
    const originalWindowHeight = 750
    const originalTop = 75

    // These values are recalculated for each resize
    let newWidth
    let newHeight
    let newTop
    let newRight

    // Based on height
    let scaleFactor

    onMount(() => {
        gameInstance.buildGame(soundEngine)

        if (window.innerWidth < window.innerHeight) {
            resetUIPositions(true)
        } else {
            resetUIPositions()
        }
    })


    window.addEventListener('message', processEvent)

    function resetUIPositions(portrait = false) {
        if (portrait) {
            isPortrait = true
            scaleFactor = window.innerWidth / originalWindowHeight
            newWidth = scaleFactor * logoWidth
            newHeight = scaleFactor * logoHeight
            newTop = window.innerHeight / 2 - newWidth / 2
            newRight = scaleFactor * originalTop
        } else {
            isPortrait = false
            scaleFactor = window.innerHeight / originalWindowHeight
            newWidth = scaleFactor * logoWidth
            newHeight = scaleFactor * logoHeight
            newTop = scaleFactor * originalTop
            newRight = window.innerWidth / 2 - newWidth / 2
        }
    }

    function processEvent(obj) {
        switch (obj.data.eventName) {
            case 'resize':
                resetUIPositions()
                break
            case 'portrait':
                resetUIPositions(true)
                break
            case 'loading':
                isHidden = false
                break
            case 'allLoaded':
                isHidden = true
                break
            default:
                break
        }
    }
</script>

<SoundEngine bind:this={soundEngine} />
<main
    style="width: 100vw; {GameSettings.useDebugCanvas
        ? ''
        : 'height: 100vh'}; position: {GameSettings.useDebugCanvas
        ? 'none'
        : 'fixed'};
"
>

    <div
        id="GameWindow"
        style="position: {GameSettings.useDebugCanvas ? 'none' : 'fixed'};"
    >
        <GameInstance bind:this={gameInstance} />
    </div>
    <!-- <div><h1 id="logA" style="font-size: 1rem;"></h1></div>
    <div
            class="slidecontainer"
            style="visibility: {GameSettings.useDebugCanvas ? 'none' : 'hidden'} "
    >
        <input
                type="range"
                min="1"
                max="5000"
                value="100"
                class="slider"
                id="slider0"
        />
        <p style="font-size: 1rem;"><span id="slider0Tooltip"/></p>
    </div>
    <div
            class="slidecontainer"
            style="visibility: {GameSettings.useDebugCanvas ? 'none' : 'hidden'} "
    >
        <input
                type="range"
                min="0"
                max="1000"
                value="100"
                class="slider"
                id="slider1"
        />
        <p style="font-size: 1rem;"><span id="slider1Tooltip"/></p>
    </div>
    <div
            class="slidecontainer"
            style="visibility: {GameSettings.useDebugCanvas ? 'none' : 'hidden'} "
    >
        <input
                type="range"
                min="0"
                max="1000"
                value="100"
                class="slider"
                id="slider2"
        />
        <p style="font-size: 1rem;"><span id="slider2Tooltip"/></p>
    </div>
    <div
            class="slidecontainer"
            style="visibility: {GameSettings.useDebugCanvas ? 'none' : 'hidden'} "
    >
        <input
                type="range"
                min="0"
                max="1000"
                value="100"
                class="slider"
                id="slider3"
        />
        <p style="font-size: 1rem;"><span id="slider3Tooltip"/></p>
    </div>
    <div
            class="slidecontainer"
            style="visibility: {GameSettings.useDebugCanvas ? 'none' : 'hidden'} "
    >
        <input
                type="range"
                min="0"
                max="1000"
                value="100"
                class="slider"
                id="slider4"
        />
        <p style="font-size: 1rem;"><span id="slider4Tooltip"/></p>
    </div> -->
</main>

<style type="text/scss">
    @import '../scss/variables';

    .OxxoLogo {
        position: absolute;
        z-index: 1;
        transform-origin: top left;
        visibility: hidden;
    }

    //---SLIDER VARS
    .slidecontainer {
        width: 100%; /* Width of the outside container */
    }

    /* The slider itself */
    .slider {
        -webkit-appearance: none; /* Override default CSS styles */
        appearance: none;
        width: 100%; /* Full-width */
        height: 25px; /* Specified height */
        background: #d3d3d3; /* Grey background */
        outline: none; /* Remove outline */
        opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
        transition: opacity 0.2s;
    }

    /* Mouse-over effects */
    .slider:hover {
        opacity: 1; /* Fully shown on mouse-over */
    }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #04aa6d; /* Green background */
        cursor: pointer; /* Cursor on hover */
    }

    .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #04aa6d; /* Green background */
        cursor: pointer; /* Cursor on hover */
    }
</style>
