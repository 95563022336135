import PIXI from '../../lib/PixiProjectionExport.js'
import * as Hf from '../../helperfunctions/HelperFunctions'
import {createOverlayBackground} from '../GameUI/OverlayBackground'
import {createButtonWithText} from '../GameUI/ButtonWithText'
import * as SpriteSheets from '../TexturePackerLoader/TexturePackerLoader'
import * as GameUI from '../GameUI/GameUI'
import {textData} from '../../../data/TextData'
import {GlobalData} from '../../../data/GlobalData'
import ImageFitter from '../../helperfunctions/ImageFitter.js'
import {gsap} from 'gsap'

//--- Manual offsets
const cardOffsetY = 34
const paddingBetweenCards = 23 // Padding between the two cards
const fontCorrectionY = 6 // Font has some padding. Note: may need to change upon font size adjustment.
const paddingBetweenTexts = 10 // Padding for right card text for y-axis
const paddingBetweenGiftAndText = 5
const overlayContentPadding = 20

//--- Retrieved from parent
let onPlayAgainCallback
let onShareCallback
let app
let amountOfGamesLeft = 0
let didNotFinish = false

//--- Container stuff
let overlayContainer // This only includes overlayBackgroundGraphics
let overlayBackgroundGraphics

let overlayContentContainer
let leftCardContentContainer
let rightCardContentContainer
let particleContainer

//--- Sprites
let rightCardSprite
let leftCardSprite
let closeSprite
let rewardSprite

//--- Buttons
let playAgainButton
let shareButton

//--- Texts
let recordText
let recordNumberText
let rewardText1
let rewardText2
let dnfText
let playAgainButtonText

let showOverlay = false

let newWidth
let newHeight
let leftOverSpaceX
let leftOverSpaceY

let tl // Gsap time line for animation for text
let parent

let hasTicker = false

let particleSprites = []

let loader
let loaderOptions

export function show(
    appRef,
    finishTime,
    parentContainer,
    close,
    playAgainCallback,
    shareCallback,
    gamesLeft,
    dnf,
    isRecord
) {
    showOverlay = true
    app = appRef
    parent = parentContainer
    closeSprite = close
    onPlayAgainCallback = playAgainCallback
    onShareCallback = shareCallback
    amountOfGamesLeft = gamesLeft
    didNotFinish = dnf

    GameUI.stopTimer()
    GameUI.hideUI()

    if (!hasTicker) {
        hasTicker = true

        app.ticker.add(update)
    }

    playAgainButtonText = textData.buttons.playAgain

    if (!amountOfGamesLeft || amountOfGamesLeft <= 0) {
        playAgainButtonText = textData.buttons.earnMore

        if (!GlobalData.isUser) {
            playAgainButtonText = textData.buttons.access
        }
    }

    if (!GlobalData.isUser) {
        playAgainButtonText = textData.buttons.access
    }

    loaderOptions = {
        loadType: PIXI.LoaderResource.LOAD_TYPE.IMAGE,
        xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB,
    }

    // Don't create, but just set values when the container is already existing
    if (overlayContainer) {
        overlayContainer.alpha = 1
        overlayContentContainer.alpha = 1
        shareButton.interactive = true
        playAgainButton.interactive = true

        //Update right card content
        rightCardContentContainer.removeChildren()
        rightCardContentContainer.addChild(rightCardSprite)
        if (!didNotFinish) {
            recordNumberText.text = Hf.msToMinutesAndSeconds(finishTime)
            rightCardContentContainer.addChild(recordText)
            rightCardContentContainer.addChild(recordNumberText)
            shareButton.visible = true
        } else {
            rightCardContentContainer.addChild(dnfText)
            shareButton.visible = false
        }

        if (GlobalData.isUser) {
            setLeftCardInteractive(false)
            rewardSprite.alpha = 0
            leftCardContentContainer.alpha = 0

            if (!loader.resources[GlobalData.prizeImageUrl]) {
                loader
                    .add(
                        GlobalData.prizeImageUrl,
                        GlobalData.prizeImageUrl,
                        loaderOptions
                    )
                    .load(() => {
                        rewardSprite.texture =
                            loader.resources[GlobalData.prizeImageUrl].texture

                        rewardSprite.alpha = 1

                        animateRewardContentIn()
                    })
            } else {
                setLeftCardInteractive(true)
                rewardSprite.texture =
                    loader.resources[GlobalData.prizeImageUrl].texture

                rewardSprite.alpha = 1

                animateRewardContentIn()
            }

            resizeRewardsTexts()
        } else {
            rewardSprite = new PIXI.Sprite(
                SpriteSheets.textureSheet['gift.png']
            )
            rewardText1.text = textData.overlays.noUserRewardText.toUpperCase()
        }

        playAgainButton.text = playAgainButtonText

        resetConfetti()

        if (window.innerWidth < window.innerHeight) {
            resetUIPositions(true)
        } else {
            resetUIPositions()
        }

        setContentWithinScreen()

        doAnimate()
        return
    }

    overlayContainer = new PIXI.Container()
    overlayContentContainer = new PIXI.Container()
    leftCardContentContainer = new PIXI.Container()
    rightCardContentContainer = new PIXI.Container()
    particleContainer = new PIXI.Container()
    parent.addChild(overlayContainer)

    createConfetti()

    overlayBackgroundGraphics = createOverlayBackground()

    overlayContainer.addChild(overlayBackgroundGraphics)
    overlayContainer.addChild(overlayContentContainer)
    overlayContainer.addChild(particleContainer)

    overlayContentContainer.addChild(rightCardContentContainer)

    createAndSetRightCard()
    createAndSetRightCardContent(finishTime, isRecord)
    createAndSetButtons()
    createAndSetLeftCard()
    createAndSetLeftCardContent()

    shareButton.on('click', onShareClick)
    shareButton.on('tap', onShareClick)
    playAgainButton.on('click', onPlayAgainClick)
    playAgainButton.on('tap', onPlayAgainClick)

    parent.setChildIndex(overlayContainer, parent.children.length - 3)

    GameUI.setCloseInteractive(true)

    //---Resize and line breaks rewards texts
    if (GlobalData.isUser) {
        resizeRewardsTexts()
    }

    if (window.innerWidth < window.innerHeight) {
        resetUIPositions(true)
    } else {
        resetUIPositions()
    }

    setContentWithinScreen()

    doAnimate()
}

function resizeRewardsTexts() {
    let maxRewardTextWidth = leftCardSprite.width - 15

    let rewardString1 = GlobalData.discountText.toUpperCase()
    Hf.autoLineBreakBitmapText(
        rewardText1,
        rewardString1,
        maxRewardTextWidth,
        2,
        26
    )

    let rewardString2 = GlobalData.discountProductText.toUpperCase()
    Hf.autoLineBreakBitmapText(
        rewardText2,
        rewardString2,
        maxRewardTextWidth,
        3,
        39
    )
}

function getTextStyle(
    fontName = 'RetroGamingRegular',
    fontSize = 16,
    tint = 0xffffff
) {
    return {
        fontName: fontName,
        fontSize: fontSize,
        trim: true,
        tint: tint,
        align: 'center',
        wordWrap: true,
        wordWrapWidth: 200,
        breakWords: true,
    }
}

function createAndSetRightCard() {
    const rightCardTexture = SpriteSheets.textureSheet['popupcard-white.png']
    rightCardSprite = new PIXI.Sprite(rightCardTexture)

    rightCardSprite.width = 217
    rightCardSprite.height = 131
    rightCardSprite.tint = 0xcb1926

    rightCardContentContainer.addChild(rightCardSprite)
}

function createAndSetRightCardContent(finishTime, isRecord) {
    const recordString = isRecord
        ? textData.overlays.recordText.toUpperCase()
        : textData.overlays.noRecordText.toUpperCase()
    const recordTimeString = Hf.msToMinutesAndSeconds(finishTime)

    recordText = new PIXI.BitmapText(
        recordString,
        getTextStyle('RetroGamingRegular', 16, 0xffffff)
    )
    recordNumberText = new PIXI.BitmapText(
        recordTimeString,
        getTextStyle('RetroGaming', 23, 0xffc829)
    )
    dnfText = new PIXI.BitmapText(
        textData.overlays.dnfText.toUpperCase(),
        getTextStyle('RetroGaming', 40, 0xffc829)
    )

    const maxDnfWidth = rightCardSprite.width - 25
    Hf.autoLineBreakBitmapText(
        dnfText,
        textData.overlays.dnfText.toUpperCase(),
        maxDnfWidth,
        2,
        50
    )

    recordText.y =
        rightCardSprite.y +
        (rightCardSprite.height -
            recordText.height -
            recordNumberText.height -
            paddingBetweenTexts) /
        2

    recordNumberText.y = recordText.y + recordText.height + paddingBetweenTexts
    dnfText.y =
        rightCardSprite.y + rightCardSprite.height / 2 - dnfText.height / 2

    if (!didNotFinish) {
        rightCardContentContainer.addChild(recordText)
        rightCardContentContainer.addChild(recordNumberText)
    } else {
        rightCardContentContainer.addChild(dnfText)
    }
}

function createAndSetButtons() {
    const shareTexture = SpriteSheets.textureSheet['share-icon.png']
    const shareSprite = new PIXI.Sprite(shareTexture)

    playAgainButton = createButtonWithText(
        amountOfGamesLeft > 0
            ? textData.buttons.playAgain
            : textData.buttons.earnMore,
        100,
        30,
        0xffffff,
        0x4a90e2,
        1,
        null,
        null,
        7
    )
    shareButton = createButtonWithText(
        textData.buttons.share,
        100,
        30,
        0x000000,
        0xffffff,
        1,
        shareSprite,
        0x000000,
        7
    )

    playAgainButton.x = rightCardSprite.x
    playAgainButton.y = rightCardSprite.y + rightCardSprite.height + 11
    playAgainButton.interactive = true

    // Take remaining space and put this as padding between the two buttons
    const betweenPadding = rightCardSprite.width - shareButton.width * 2
    shareButton.x = playAgainButton.x + playAgainButton.width + betweenPadding
    shareButton.y = playAgainButton.y
    shareButton.interactive = true

    if (didNotFinish) {
        shareButton.visible = false
    }

    overlayContentContainer.addChild(playAgainButton)
    overlayContentContainer.addChild(shareButton)

    playAgainButton.text = playAgainButtonText
}

function createAndSetLeftCard() {
    const leftCardTexture = SpriteSheets.textureSheet['popupcard-white-2.png']
    leftCardSprite = new PIXI.Sprite(leftCardTexture)

    leftCardSprite.width = 140
    leftCardSprite.height = rightCardSprite.height + shareButton.height + 11

    overlayContentContainer.addChild(leftCardSprite)

    if (!GlobalData.isUser) {
        setLeftCardInteractive(true)
    } else {
        setLeftCardInteractive(false)
    }
}

function createAndSetLeftCardContent() {
    let rewardString1 = GlobalData.discountText.toUpperCase()
    let rewardString2 = GlobalData.discountProductText.toUpperCase()

    //Cache values because they change during anim
    let leftCardY = leftCardSprite.y
    let leftCardHeight = leftCardSprite.height

    // Retrieve image from URL
    if (GlobalData.isUser) {
        loader = new PIXI.Loader()

        loader
            .add('url', GlobalData.prizeImageUrl, loaderOptions)
            .load((loader, resources) => {
                rewardSprite = new PIXI.Sprite(resources.url.texture)
                rewardSprite.anchor.set(0.5)

                const size = ImageFitter(
                    'fit',
                    93,
                    60,
                    rewardSprite.texture.width,
                    rewardSprite.texture.height
                )
                rewardSprite.scale.set(size.xScale, size.yScale)
                rewardSprite.y =
                    leftCardY +
                    (leftCardHeight -
                        rewardSprite.height -
                        rewardText1.height -
                        rewardText2.height -
                        10) /
                    2 +
                    rewardSprite.height / 2 // Plus half of its height, because of the anchoring
                rewardText1.y =
                    rewardSprite.y +
                    rewardSprite.height / 2 -
                    paddingBetweenGiftAndText +
                    10
                rewardText2.y =
                    rewardText1.y +
                    rewardText1.height +
                    paddingBetweenGiftAndText

                leftCardContentContainer.addChild(rewardSprite)
                leftCardContentContainer.addChild(rewardText1)
                leftCardContentContainer.addChild(rewardText2)

                overlayContentContainer.addChild(leftCardContentContainer)

                rewardSprite.x = leftCardSprite.x + leftCardSprite.width / 2
                animateRewardContentIn()
            })
    } else {
        rewardString1 = textData.overlays.noUserRewardText.toUpperCase()
        rewardText1 = new PIXI.BitmapText(
            rewardString1,
            getTextStyle('RetroGamingRegular', 10, 0x000000)
        )
        rewardSprite = new PIXI.Sprite(SpriteSheets.textureSheet['gift.png'])
        rewardSprite.anchor.set(0.5)

        rewardSprite.y =
            leftCardY +
            (leftCardHeight -
                rewardSprite.height -
                rewardText1.height -
                paddingBetweenGiftAndText) /
            2 +
            10 // Add 10, because somehow the text has some padding in its height
        rewardText1.y =
            rewardSprite.y + rewardSprite.height / 2 + paddingBetweenGiftAndText

        leftCardContentContainer.addChild(rewardSprite)
        leftCardContentContainer.addChild(rewardText1)

        overlayContentContainer.addChild(leftCardContentContainer)

        animateRewardContentIn()
    }

    //Note: Above you'll see similar code, but because the function to check if the image is loaded is async, I need to solve it this way.

    if (!rewardText1) {
        rewardText1 = new PIXI.BitmapText(
            rewardString1,
            getTextStyle('RetroGamingRegular', 14, 0xe21c2a)
        )
        rewardText2 = new PIXI.BitmapText(
            rewardString2,
            getTextStyle('RetroGamingRegular', 10, 0x000000)
        )
    }
}

export function hide() {
    app.ticker.remove(update)
    hasTicker = false
    overlayContainer.alpha = 0
    overlayContentContainer.alpha = 0
    closeSprite.alpha = 1

    shareButton.interactive = false
    playAgainButton.interactive = false
}

function onShareClick() {
    // hide()
    // GameUI.showUI()
    // TODO: Implement quit here

    if (onShareCallback) {
        onShareCallback()
    }
}

function onPlayAgainClick() {
    if (!GlobalData.isUser) {
        //console.log('Send access')
        Hf.send('access', {
            action: 'access',
        })
        return
    }
    if (!amountOfGamesLeft || amountOfGamesLeft <= 0) {
        amountOfGamesLeft = 0
        // console.log('Send earn-more')
        Hf.send('earn-more', {
            action: 'earn-more',
        })
        return
    }

    hide()
    GameUI.showUI()

    if (onPlayAgainCallback) {
        onPlayAgainCallback()
    }
}

window.addEventListener('message', processEvent)

function processEvent(obj) {
    if (obj.data.eventName === 'resize') {
        resetUIPositions()
        setContentWithinScreen()
    }

    if (obj.data.eventName === 'portrait') {
        resetUIPositions(true)
        setContentWithinScreen()
    }
}

function resetUIPositions(swap = false) {
    if (!showOverlay) return

    newWidth = swap ? app.renderer.screen.height : app.renderer.screen.width
    newHeight = swap ? app.renderer.screen.width : app.renderer.screen.height

    if (overlayBackgroundGraphics) {
        overlayBackgroundGraphics.width = newWidth
        leftOverSpaceX = newWidth - overlayContentContainer.width
        leftOverSpaceY = newHeight - overlayContentContainer.height

        overlayContentContainer.x = leftOverSpaceX / 2
        overlayContentContainer.y = leftOverSpaceY / 2
        if (rewardSprite) {
            rewardSprite.x = leftCardSprite.x + leftCardSprite.width / 2
        }
        rewardText1.x =
            leftCardSprite.x + leftCardSprite.width / 2 - rewardText1.width / 2

        if (rewardText2) {
            rewardText2.x =
                leftCardSprite.x +
                leftCardSprite.width / 2 -
                rewardText2.width / 2
        }

        rightCardSprite.x =
            leftCardSprite.x + leftCardSprite.width + paddingBetweenCards
        recordText.x =
            rightCardSprite.x + rightCardSprite.width / 2 - recordText.width / 2
        recordNumberText.x =
            rightCardSprite.x +
            rightCardSprite.width / 2 -
            recordNumberText.width / 2
        dnfText.x =
            rightCardSprite.x + rightCardSprite.width / 2 - dnfText.width / 2

        // Add 2 because somehow it doesn't fill the whole screen.
        overlayBackgroundGraphics.width = newWidth + 2
        overlayBackgroundGraphics.height = newHeight + 2

        if (didNotFinish) {
            playAgainButton.x =
                rightCardSprite.x +
                (rightCardSprite.width - playAgainButton.width) / 2
        } else {
            playAgainButton.x = rightCardSprite.x
        }

        const betweenPadding = rightCardSprite.width - shareButton.width * 2
        shareButton.x =
            playAgainButton.x + playAgainButton.width + betweenPadding
    }
}

function setContentWithinScreen() {
    if (overlayContentContainer) {
        if (
            overlayContentContainer.width + overlayContentPadding * 2 >
            newWidth
        ) {
            while (
                overlayContentContainer.width + overlayContentPadding * 2 >
                newWidth
                ) {
                overlayContentContainer.scale.x -= 0.01
                overlayContentContainer.scale.y -= 0.01
            }
        } else {
            overlayContentContainer.scale.x += 0.01
            overlayContentContainer.scale.y += 0.01
        }

        overlayContentContainer.x = leftOverSpaceX / 2
        overlayContentContainer.y = leftOverSpaceY / 2

        leftOverSpaceX = newWidth - overlayContentContainer.width
        leftOverSpaceY = newHeight - overlayContentContainer.height

        overlayContentContainer.x = leftOverSpaceX / 2
        overlayContentContainer.y = leftOverSpaceY / 2
    }
}

function doAnimate() {
    const duration = 0.33
    const startXRightCard = rightCardSprite.y
    const startXButtons = rightCardSprite.y + rightCardSprite.height + 11

    tl = gsap.timeline()
    tl.set(rewardSprite, {alpha: 0})
    tl.set([recordText, recordNumberText, dnfText], {alpha: 0})
    tl.set(shareButton, {alpha: 0, y: startXButtons + 20})

    tl.from(leftCardSprite, {
        alpha: 0,
        y: startXRightCard + 20,
        duration: duration,
        delay: duration,
    })
    tl.from(rightCardSprite, {
        alpha: 0,
        y: startXRightCard + 20,
        duration: duration,
    })
    tl.to([recordText, recordNumberText, dnfText], {
        alpha: 1,
        duration: duration,
        delay: -duration * 0.25,
    })
    tl.from(playAgainButton, {
        alpha: 0,
        y: startXButtons + 20,
        duration: duration,
    })
    tl.to(shareButton, {
        alpha: 1,
        y: startXButtons,
        duration: duration,
        delay: -duration * 0.25,
    })
}

function animateRewardContentIn() {
    const duration = 0.33
    const startY = leftCardContentContainer.y

    leftCardContentContainer.alpha = 1
    let contentTimeLine = gsap.timeline()
    contentTimeLine.set(rewardSprite, {alpha: 0})
    contentTimeLine.from(leftCardContentContainer, {
        alpha: 0,
        y: startY + 20,
        duration: duration,
        delay: duration,
    })
    contentTimeLine.to(rewardSprite, {
        alpha: 1,
        duration: duration,
        delay: -duration * 0.25,
    })
}

function update(delta) {
    let deltaTime = app.ticker.deltaMS / 1000

    for (let i = 0; i < particleSprites.length; i++) {
        particleSprites[i].img.x +=
            particleSprites[i].xVelocity * deltaTime * 60
        particleSprites[i].img.y +=
            particleSprites[i].yVelocity * 60 * deltaTime
        particleSprites[i].img.angle += particleSprites[i].angleFactor
        // particleSprites[i].image.x = 200
        // particleSprites[i].img.y = 200

        if (particleSprites[i].yVelocity < 1.5) {
            particleSprites[i].yVelocity += 0.05 * deltaTime * 60
        }

        if (particleSprites[i].img.y > newHeight + 20) {
            particleSprites[i].img.y = -20
            particleSprites[i].img.x = Math.random() * newWidth
        }
        // console.log("x", particleSprites[i].img.x)
    }
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function resetConfetti() {
    particleContainer.removeChildren()
    createConfetti()
}

function createConfetti() {
    let i = 0
    const confettiInterval = setInterval(() => {
        const image = new PIXI.Sprite(
            SpriteSheets.textureSheet[`confetti-${i % 8}.png`]
        )
        particleSprites.push({
            img: image,
            xVelocity: (Math.random() - 0.5) * 3,
            yVelocity: -5,
            angleFactor: randomIntFromInterval(0, 2.5),
        })
        particleContainer.addChild(image)

        image.x = newWidth / 2
        image.y = newHeight
        i++

        if (i === 50) {
            clearInterval(confettiInterval)
        }
    }, 50)
}


function setLeftCardInteractive(interactive = true) {

    if (!interactive) {
        leftCardSprite.interactive = false
        leftCardContentContainer.interactive = false

        return
    }

    leftCardSprite.interactive = true
    leftCardContentContainer.interactive = true

    leftCardSprite.buttonMode = true
    leftCardContentContainer.buttonMode = true

    leftCardSprite.on('click', () => {
        Hf.send('access', {
            action: 'access',
        })
    })

    leftCardSprite.on('tap', () => {
        Hf.send('access', {
            action: 'access',
        })
    })

    leftCardContentContainer.on('click', () => {
        Hf.send('access', {
            action: 'access',
        })
    })

    leftCardContentContainer.on('tap', () => {
        Hf.send('access', {
            action: 'access',
        })
    })

}