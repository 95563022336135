export let textData = {
    buttons: {
        tutorial: 'Tutorial',
        resume: 'Continuar',
        quit: 'Salir',
        playAgain: 'Jugar de nuevo',
        earnMore: 'Ir a Mioxxo',
        share: 'Compartir',
        access: 'Ir a premios',
    },

    overlays: {
        quit: '¿Seguro que quieres\nsalir de la carrera?',
        tutorialGas: 'Recarga Gasolina\ny comienza a acelera',
        tutorialBrake: '¡usa los frenos para\ncontrolar los giros!',
        tutorialDRS: 'Activa el DRS para\naumentar a la\nvelocidad máxima',
        tutorialComplete:
            'Tutorial completo\n¿Listo para acelerar\ny ganar la carrera?',
        endscreenTitle: '¡Bien hecho!',
        recordText: '¡Esa fue una\nincreíble vuelta!',
        noRecordText: '¡Esa fue una\nincreíble vuelta!',
        loading: 'Cargando',
        rewardText: '20%\ndescuento\nen todos\nlos productos\nredbull',
        dnfText:
            '¡Oh no! ¡Si renuncias, perderás la oportunidad de ganar el premio de F1!',
        noUserRewardText: 'Descarga\nla aplicación\npara canjear\npremios',
    },

    gameUI: {
        lap: 'Tiempo',
        best: 'Record',
        speedUnit: 'km/h',
        go: 'Vamos',
    },
}
