export const bitmap_1 = [
    0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0,
    0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 0, 0, 0, 0, 0,
    4294965248, 0, 0, 0, 0, 0, 0, 0, 4294965248, 0, 0, 4294967295, 4294967295,
    4294967295, 63, 0, 4294965248, 0, 0, 4294967295, 4294967295, 4294967295,
    511, 0, 4294965248, 0, 0, 4294967295, 4294967295, 4294967295, 4095, 0,
    4294965248, 0, 0, 4294967295, 4294967295, 4294967295, 16383, 0, 4294965248,
    0, 0, 4294967295, 4294967295, 4294967295, 32767, 0, 4294965248, 0, 0,
    4294967295, 4294967295, 4294967295, 131071, 0, 4294965248, 0, 0, 4294967295,
    4294967295, 4294967295, 262143, 0, 4294965248, 0, 0, 4294967295, 4294967295,
    4294967295, 524287, 0, 4294965248, 0, 0, 4294967295, 4294967295, 4294967295,
    1048575, 0, 4294965248, 0, 0, 4294967295, 4294967295, 4294967295, 2097151,
    0, 4294965248, 0, 0, 4294967295, 4294967295, 4294967295, 4194303, 0,
    4294965248, 1, 0, 4294967295, 4294967295, 4294967295, 8388607, 0,
    4294965248, 1, 0, 4294967295, 4294967295, 4294967295, 16777215, 0,
    4294965248, 1, 0, 4294967295, 4294967295, 4294967295, 33554431, 0,
    4294965248, 3, 0, 4294967295, 4294967295, 4294967295, 33554431, 0,
    4294963200, 7, 0, 4294967295, 4294967295, 4294967295, 67108863, 0,
    4294963200, 7, 0, 4294967295, 4294967295, 4294967295, 134217727, 0,
    4294963200, 15, 0, 4294967295, 4294967295, 4294967295, 134217727, 0,
    4294963200, 63, 0, 4294967295, 4294967295, 4294967295, 268435455, 0,
    4294959104, 255, 0, 4294967295, 4294967295, 4294967295, 268435455, 0,
    4294959104, 1023, 0, 4294967295, 4294967295, 4294967295, 536870911, 0,
    4294950912, 16383, 0, 0, 0, 0, 536870908, 0, 4294950912, 134217727, 0, 0, 0,
    0, 536870896, 0, 4294950912, 4294967295, 0, 0, 0, 0, 1073741792, 0,
    4294934528, 4294967295, 15, 0, 0, 0, 1073741760, 0, 4294901760, 4294967295,
    127, 0, 0, 0, 1073741696, 0, 4294901760, 4294967295, 511, 0, 0, 0,
    2147483392, 0, 4294836224, 4294967295, 2047, 0, 0, 0, 2147483392, 0,
    4294705152, 4294967295, 8191, 0, 0, 0, 2147483136, 0, 4294705152,
    4294967295, 32767, 0, 0, 0, 2147483136, 0, 4294443008, 4294967295, 65535, 0,
    0, 0, 2147483136, 0, 4293918720, 4294967295, 262143, 0, 0, 0, 4294966272, 0,
    4292870144, 4294967295, 524287, 0, 0, 0, 4294966272, 0, 4290772992,
    4294967295, 1048575, 0, 0, 0, 4294966272, 0, 4286578688, 4294967295,
    2097151, 0, 0, 0, 4294966272, 0, 4261412864, 4294967295, 4194303, 0, 0, 0,
    4294965248, 0, 4227858432, 4294967295, 8388607, 0, 0, 0, 4294965248, 0,
    4026531840, 4294967295, 16777215, 0, 0, 0, 4294965248, 0, 3221225472,
    4294967295, 16777215, 0, 0, 0, 4294965248, 0, 0, 4294967295, 33554431, 0, 0,
    0, 4294965248, 0, 0, 4294967288, 67108863, 0, 0, 0, 4294965248, 0, 0,
    4294967232, 134217727, 0, 0, 0, 4294965248, 0, 0, 4294966272, 134217727,
    4286578688, 268435455, 0, 4194303, 4194303, 0, 0, 0, 4026531840, 268435455,
    0, 2097151, 2097151, 0, 0, 0, 2147483648, 536870911, 2147483648, 2097151,
    2097151, 0, 0, 2147483648, 0, 536870910, 2147483648, 1048575, 1048575, 0, 0,
    2147483648, 0, 536870904, 3221225472, 1048575, 1048575, 0, 0, 3221225472, 0,
    1073741792, 3221225472, 524287, 524287, 0, 0, 3221225472, 0, 1073741760,
    3758096384, 524287, 524287, 0, 0, 3758096384, 0, 2147483520, 3758096384,
    262143, 262143, 0, 0, 3758096384, 0, 2147483520, 4026531840, 262143, 262143,
    0, 0, 4026531840, 0, 2147483392, 4026531840, 131071, 131071, 0, 0,
    4026531840, 0, 2147483136, 4160749568, 131071, 131071, 0, 0, 4160749568, 0,
    4294966784, 4160749568, 65535, 65535, 0, 0, 4160749568, 0, 4294966272,
    4227858432, 65535, 65535, 0, 0, 4227858432, 0, 4294966272, 4227858432,
    32767, 32767, 0, 0, 4227858432, 0, 4294966272, 4261412864, 32767, 32767, 0,
    0, 4261412864, 0, 4294965248, 4261412864, 16383, 16383, 0, 0, 4261412864, 0,
    4294965248, 4278190080, 16383, 16383, 0, 0, 4278190080, 0, 4294965248,
    4278190080, 8191, 8191, 0, 0, 4278190080, 0, 4294965248, 4286578688, 8191,
    8191, 0, 0, 4286578688, 0, 4294965248, 4286578688, 4095, 4095, 0, 0,
    4286578688, 0, 4294965248, 4290772992, 4095, 4095, 0, 0, 4290772992, 0,
    4294965248, 4290772992, 2047, 2047, 0, 0, 4290772992, 0, 4294965248,
    4292870144, 2047, 2047, 0, 0, 4292870144, 0, 4294965248, 4292870144, 1023,
    1023, 0, 0, 4292870144, 0, 4294965248, 4293918720, 1023, 1023, 0, 0,
    4293918720, 0, 4294965248, 4293918720, 511, 511, 0, 0, 4293918720, 0,
    4294965248, 4294443008, 511, 511, 0, 0, 4294443008, 0, 4294965248,
    4294443008, 255, 255, 0, 0, 4294443008, 0, 4294965248, 4294705152, 255, 255,
    0, 0, 4294705152, 0, 4294965248, 4294705152, 127, 127, 0, 0, 4294705152, 0,
    4294965248, 4294836224, 127, 127, 0, 0, 4294836224, 0, 4294965248,
    4294836224, 63, 63, 0, 0, 4294836224, 0, 4294966272, 4294901760, 63, 63, 0,
    0, 4294901760, 0, 4294966272, 4294901760, 31, 31, 0, 0, 4294901760, 0,
    4294966272, 4294934528, 31, 31, 0, 0, 4294934528, 0, 4294966272, 4294934528,
    15, 15, 0, 0, 4294934528, 0, 2147482624, 4294950912, 15, 15, 0, 0,
    4294950912, 0, 2147482624, 4294950912, 7, 7, 0, 0, 4294950912, 0,
    2147482624, 4294959104, 7, 7, 0, 0, 4294959104, 0, 2147483136, 4294959104,
    3, 3, 0, 0, 4294959104, 0, 2147483136, 4294963200, 3, 3, 0, 0, 4294963200,
    0, 2147483136, 4294963200, 1, 1, 0, 0, 4294963200, 0, 1073741312,
    4294965248, 1, 1, 0, 0, 4294965248, 0, 1073741568, 4294965248, 0, 0, 0, 0,
    4294965248, 0, 1073741568, 4294966272, 0, 0, 0, 0, 4294966272, 0,
    1073741568, 2147482624, 0, 0, 0, 0, 2147482624, 0, 536870656, 2147483136, 0,
    0, 0, 0, 2147483136, 0, 536870784, 1073741312, 0, 0, 0, 0, 1073741312, 0,
    536870784, 1073741568, 0, 0, 0, 0, 1073741568, 0, 536870784, 536870656, 0,
    0, 0, 0, 536870656, 0, 268435392, 536870784, 0, 0, 0, 0, 536870784, 0,
    268435392, 268435328, 0, 0, 0, 0, 268435328, 0, 134217664, 268435392, 0, 0,
    0, 0, 268435392, 0, 134217696, 134217664, 0, 0, 0, 0, 134217664, 0,
    134217696, 134217696, 0, 0, 0, 0, 134217696, 0, 67108832, 67108832, 0, 0, 0,
    0, 67108832, 0, 67108848, 67108848, 0, 0, 0, 0, 67108848, 0, 33554416,
    33554416, 0, 0, 0, 0, 33554416, 0, 33554424, 33554424, 0, 0, 0, 0, 33554424,
    0, 16777208, 16777208, 0, 0, 0, 0, 16777208, 0, 16777212, 16777212, 0, 0, 0,
    0, 16777212, 0, 8388604, 8388604, 0, 0, 0, 0, 8388604, 0, 8388606, 8388606,
    0, 0, 0, 0, 8388606, 0, 4194302, 4194302, 0, 0, 0, 0, 4194302, 0, 4194303,
    0, 0, 2097151, 0, 3221225472, 4294967295, 0, 2097151, 0, 0, 8388607, 0, 0,
    4294967295, 2147483648, 2097151, 0, 0, 16777215, 0, 0, 4294967292,
    2147483648, 1048575, 0, 0, 67108863, 0, 0, 4294967288, 3221225472, 1048575,
    0, 0, 134217727, 0, 0, 4294967264, 3221225472, 524287, 0, 0, 268435455, 0,
    0, 4294967168, 3758096384, 524287, 0, 0, 268435455, 0, 0, 4294966272,
    3758096384, 262143, 0, 0, 536870911, 0, 0, 4294963200, 4026531840, 262143,
    0, 0, 1073741823, 0, 0, 4294934528, 4160749568, 131071, 0, 0, 1073741823, 0,
    0, 4294836224, 4160749568, 131071, 0, 0, 2147483647, 0, 0, 4293918720,
    4227858432, 65535, 0, 0, 2147483647, 0, 0, 4286578688, 4227858432, 65535, 0,
    0, 2147483647, 0, 0, 4227858432, 4261412864, 32767, 0, 0, 2147483647, 0, 0,
    3758096384, 4261412864, 32767, 0, 0, 2147483647, 0, 0, 0, 4278190080, 16383,
    0, 0, 2147483646, 0, 0, 0, 4278190080, 16383, 0, 0, 2147483640, 0, 0, 0,
    4286578688, 8191, 0, 0, 2147483640, 0, 0, 0, 4286578688, 8191, 0, 0,
    2147483632, 0, 0, 0, 4290772992, 4095, 0, 0, 2147483632, 0, 0, 0,
    4290772992, 4095, 0, 0, 2147483632, 0, 0, 0, 4292870144, 2047, 0, 0,
    1073741816, 0, 0, 0, 4292870144, 2047, 0, 0, 1073741820, 0, 0, 0,
    4292870144, 1023, 0, 0, 536870910, 0, 0, 0, 4293918720, 1023, 0, 0,
    536870910, 0, 0, 0, 4293918720, 1023, 0, 0, 268435455, 0, 0, 0, 4294443008,
    511, 0, 0, 134217727, 0, 0, 2147483648, 4294443008, 511, 0, 0, 67108863, 0,
    0, 3221225472, 4294443008, 511, 0, 0, 33554431, 0, 0, 3758096384,
    4294705152, 255, 0, 0, 16777215, 0, 0, 4026531840, 4294705152, 255, 0, 0,
    16777215, 0, 0, 4160749568, 4294705152, 255, 0, 0, 8388607, 0, 0,
    4227858432, 4294836224, 127, 0, 0, 4194303, 0, 0, 4227858432, 4294836224,
    127, 0, 0, 2097151, 0, 0, 4261412864, 4294836224, 127, 0, 0, 1048575, 0, 0,
    4278190080, 4294836224, 63, 0, 0, 524287, 0, 0, 4286578688, 4294836224, 63,
    0, 0, 262143, 0, 0, 4290772992, 4294901760, 63, 0, 0, 262143, 0, 0,
    4292870144, 4294901760, 63, 0, 0, 131071, 0, 0, 4293918720, 4294901760, 63,
    0, 0, 65535, 0, 0, 4293918720, 4294901760, 63, 0, 0, 32767, 0, 0,
    4294443008, 4294901760, 63, 0, 0, 16383, 0, 0, 4294705152, 4294901760, 63,
    0, 0, 8191, 0, 0, 4294836224, 4294836224, 127, 0, 0, 4095, 0, 0, 4294901760,
    4294836224, 255, 0, 0, 4095, 0, 0, 4294934528, 4294836224, 511, 0, 0, 2047,
    0, 0, 4294950912, 4294836224, 1023, 0, 0, 1023, 0, 0, 4294959104,
    4294836224, 2047, 0, 0, 511, 0, 0, 4294963200, 4294705152, 8191, 0, 0, 255,
    0, 0, 4294963200, 4294705152, 32767, 0, 0, 127, 0, 0, 4294965248,
    4294705152, 131071, 0, 0, 63, 0, 0, 4294966272, 4294443008, 1048575, 0, 0,
    31, 0, 0, 4294966784, 4294443008, 4194303, 0, 0, 31, 0, 0, 4294967040,
    4293918720, 33554431, 0, 0, 15, 0, 0, 4294967168, 4293918720, 268435455, 0,
    0, 7, 0, 0, 4294967232, 4292870144, 2147483647, 0, 0, 3, 0, 0, 4294967264,
    4290772992, 4294967295, 3, 0, 1, 0, 0, 4294967280, 4290772992, 4294967295,
    31, 0, 0, 0, 0, 4294967288, 4286578688, 4294967295, 255, 0, 0, 0, 0,
    2147483640, 4278190080, 4294967295, 1023, 0, 0, 0, 0, 1073741820,
    4261412864, 4294967295, 8191, 0, 0, 0, 0, 1073741822, 4227858432,
    4294967295, 32767, 0, 0, 0, 0, 536870911, 4026531840, 4294967295, 262143, 0,
    0, 0, 2147483648, 268435455, 3758096384, 4294967295, 1048575, 0, 0, 0,
    3221225472, 134217727, 3758096384, 67108863, 4290772992, 2047, 0, 0, 1, 0,
    4026531840, 33554431, 4290772992, 2047, 0, 0, 1, 0, 4160749568, 16777215,
    4290772992, 2047, 0, 0, 1, 0, 4227858432, 8388607, 4290772992, 2047, 0, 0,
    3, 0, 4261412864, 4194303, 4290772992, 2047, 0, 0, 3, 0, 4278190080,
    2097151, 4290772992, 2047, 0, 0, 7, 0, 4286578688, 2097151, 4290772992,
    4095, 0, 0, 7, 0, 4290772992, 1048575, 4290772992, 4095, 0, 0, 15, 0,
    4292870144, 524287, 4290772992, 4095, 0, 0, 15, 0, 4293918720, 262143,
    4290772992, 4095, 0, 0, 31, 0, 4294443008, 131071, 4286578688, 4095, 0, 0,
    31, 0, 4294705152, 65535, 4286578688, 4095, 0, 0, 63, 0, 4294836224, 32767,
    4286578688, 8191, 0, 0, 63, 0, 4294901760, 16383, 4286578688, 8191, 0, 0,
    127, 0, 4294934528, 8191, 4286578688, 8191, 0, 0, 127, 0, 4294950912, 4095,
    4286578688, 8191, 0, 0, 255, 0, 4294959104, 2047, 4278190080, 16383, 0, 0,
    511, 0, 4294963200, 1023, 4278190080, 16383, 0, 0, 1023, 0, 4294965248, 511,
    4278190080, 16383, 0, 0, 2047, 0, 4294966272, 255, 4278190080, 32767, 0, 0,
    4095, 0, 4294967040, 127, 4278190080, 32767, 0, 0, 8191, 0, 4294967168, 63,
    4261412864, 32767, 0, 0, 32767, 0, 4294967232, 31, 4261412864, 65535, 255,
    0, 4294967295, 4294967295, 4294967264, 15, 4261412864, 65535, 4095, 0,
    4294967295, 4294967295, 4294967280, 7, 4227858432, 131071, 65535, 0,
    4294967295, 4294967295, 4294967292, 3, 4227858432, 262143, 262143, 0,
    4294967295, 4294967295, 4294967294, 1, 4227858432, 262143, 2097151, 0,
    4294967295, 4294967295, 4294967295, 0, 4160749568, 524287, 8388607, 0,
    4294967295, 4294967295, 2147483647, 0, 4160749568, 3222274047, 16777215, 0,
    4294967295, 4294967295, 1073741823, 0, 4026531840, 4030726143, 67108863, 0,
    4294967295, 4294967295, 536870911, 0, 4026531840, 4294967295, 134217727, 0,
    4294967295, 4294967295, 268435455, 0, 4026531840, 4294967295, 536870911, 0,
    4294967295, 4294967295, 134217727, 0, 3758096384, 4294967295, 1073741823, 0,
    4294967295, 4294967295, 67108863, 0, 3758096384, 4294967295, 2147483647, 0,
    4294967295, 4294967295, 33554431, 0, 3221225472, 4294967295, 4294967295, 0,
    4294967295, 4294967295, 16777215, 0, 2147483648, 4294967295, 4294967295, 1,
    4294967295, 4294967295, 8388607, 0, 2147483648, 4294967295, 4294967295, 3,
    4294967295, 4294967295, 4194303, 0, 0, 4294967295, 4294967295, 7,
    4294967295, 4294967295, 1048575, 0, 0, 4294967294, 4294967295, 7,
    4294967294, 4294967295, 524287, 0, 0, 4294967294, 4294967295, 15,
    4294967288, 4294967295, 262143, 0, 0, 4294967292, 4294967295, 31,
    4294967264, 4294967295, 131071, 0, 0, 4294967288, 4294967295, 31,
    4294967040, 4294967295, 32767, 0, 0, 4294967280, 4294967295, 63, 4294965248,
    4294967295, 16383, 0, 0, 4294967264, 4294967280, 127, 0, 0, 8191, 0, 0,
    4294967232, 4294967040, 127, 0, 0, 2047, 0, 0, 4294967168, 4294965248, 127,
    0, 0, 1023, 0, 0, 4294966784, 4294959104, 255, 0, 0, 255, 0, 0, 4294966272,
    4294950912, 255, 0, 0, 63, 0, 0, 4294963200, 4294901760, 511, 0, 0, 15, 0,
    0, 4294950912, 4294836224, 511, 0, 0, 1, 0, 0, 4294836224, 4294705152, 511,
    0, 0, 0, 0, 0, 1069547520, 4294705152, 1023, 0, 0, 0, 0, 0, 0, 4294443008,
    1023, 0, 0, 0, 0, 0, 0, 4293918720, 1023, 0, 0, 0, 0, 0, 0, 4293918720,
    1023, 0, 0, 0, 0, 0, 0, 4293918720, 1023, 0, 0, 0, 0, 0, 0, 4292870144,
    2047, 0, 0, 0, 0, 0, 0, 4292870144, 2047, 0, 0, 0, 0, 0, 0, 4292870144,
    2047, 0, 0, 0, 0, 0, 0, 4292870144, 2047, 0, 0, 0, 0, 0, 0, 4290772992,
    2047, 0, 0, 0, 0, 0, 0, 4290772992, 2047, 0, 0, 0, 0, 0, 0, 4290772992,
    2047, 0, 0, 0, 0, 0, 0, 4290772992, 2047, 0, 0,
]
