import PIXI from '../../lib/PixiProjectionExport.js'
import { data } from '../../../public/assets/gamedata/TileData.js'
import { textureSheet } from '../TexturePackerLoader/TexturePackerLoader.js'
import * as Hf from '../../helperfunctions/HelperFunctions.js'
import { initSegmentObjects } from './WorldObjectController.js'

// parameters
const tilesShown = 6

const numTilesX = 22,
    numTilesY = 16
const worldWidth = 1838,
    worldHeight = 1335.46
const refImageHeight = 896

const tileRes = 512

const tileWorldSize = worldWidth / numTilesX

let world
let activeTiles = []

export async function initTiles(worldContainer, objectContainer, carSprite) {
    world = worldContainer

    await initSegmentObjects(objectContainer, carSprite)
    loadAllTiles()
}

export function loadAllTiles() {
    const bigWhiteTexture = new PIXI.Texture(PIXI.Texture.WHITE.baseTexture)
    bigWhiteTexture.orig.width = 25
    bigWhiteTexture.orig.height = 25

    //---Load ground plane
    let groundPlane = new PIXI.projection.Sprite3d(bigWhiteTexture)
    groundPlane.anchor.y = 1
    groundPlane.width = 2050000
    groundPlane.height = 1337000
    groundPlane.tint = 0xBE8434
    groundPlane.euler.x = Math.PI //Flat on ground

    world.addChild(groundPlane)

    let tiles = data['layers'][0]['assets']

    // For every segment's tiles' sprites, add these to the world.
    for (let i = 0; i < tiles.length; i++) {
        addToWorld(tiles[i])
    }

    // for (let i = 0; i < tiles2.length; i++) {
    //     WorldObjectController.loadSegmentObjects(tiles2[i].index)
    // }
    //
    // setTimeout(()=> {
    //     removeSegmentObjects()
    // }, 2000)
}

function addToWorld(tile) {
    const spriteName = tile['spriteId'].name
    // create sprite to project on ground plane
    let sprite = new PIXI.projection.Sprite3d(
        textureSheet[`${spriteName}_track.png`]
    )

    sprite.anchor.x = 0
    sprite.anchor.y = 0

    sprite.position3d.x = (tile.x / tileRes) * tileWorldSize
    sprite.position3d.y = worldHeight - (tile.y / tileRes) * tileWorldSize

    sprite.scale3d.x = tileWorldSize / tileRes
    sprite.scale3d.y = -tileWorldSize / tileRes

    sprite.scale3d.x = sprite.scale3d.x * tile.scaleX
    sprite.scale3d.y = sprite.scale3d.y * tile.scaleY

    if (tile.rotation !== 0) {
        // debugger
    }

    sprite.euler.z = tile.rotation * Hf.deg2Rad
    world.addChild(sprite)

    return sprite
}

export function getTileInfo(worldX, worldY) {
    let tilesData = data['layers'][0]['assets']

    for (let i = 0; i < tilesData.length; i++) {
        let tileX = (tilesData[i].x / tileRes) * tileWorldSize
        let tileY = worldHeight - (tilesData[i].y / tileRes) * tileWorldSize

        //Rotation is only ever 0 or -90, if -90 pivot is bottom left, not top left
        if (tilesData[i].rotation == -90) {
            tileY += tileWorldSize
        }
        if (tilesData[i].scaleX == -1) {
            tileX -= tileWorldSize
        }
        if (tilesData[i].scaleY == -1) {
            tileY += tileWorldSize
        }

        if (
            worldX > tileX &&
            worldX < tileX + tileWorldSize &&
            worldY < tileY &&
            worldY > tileY - tileWorldSize
        ) {
            return {
                data: tilesData[i],
                localX: (worldX - tileX) / tileWorldSize,
                localY: (tileY - worldY) / tileWorldSize,
            }
        }
    }
    return null
}
